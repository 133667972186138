import { ReactNode, SyntheticEvent, useRef, useState } from 'react'

import {
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'

import { LogoutOutlined, UserOutlined } from '@ant-design/icons'

import { Icon } from '@components/icons'
import { Profile as ProfileIcon } from '@components/icons/profile'

import Transitions from 'components/@extended/Transitions'
import WidgetCard from 'components/cards/WidgetCard'
import useAuth from 'hooks/useAuth'

import ProfileTab from './ProfileTab'

interface TabPanelProps {
  children?: ReactNode
  dir?: string
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`
  }
}

interface ProfileProps {
  iconColor?: any
  hoverColor?: string
}

const Profile = ({ iconColor = '', hoverColor = '' }: ProfileProps) => {
  const theme = useTheme()

  if (!iconColor) {
    iconColor = theme.palette.primary.main
  }

  const { logout, user } = useAuth()
  const handleLogout = async () => {
    try {
      await logout()
    } catch (err) {
      console.error(err)
    } finally {
      setOpen(false)
    }
  }

  const anchorRef = useRef<any>(null)
  const [open, setOpen] = useState(false)
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }

  const [value, setValue] = useState(0)

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300'

  return (
    <Box sx={{ flexShrink: 0 }}>
      <ButtonBase
        sx={{
          px: 2,
          py: 1,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          '&:hover': {
            bgcolor:
              hoverColor ||
              (theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter')
          },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2
          }
        }}
        aria-label='open profile'
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
      >
        <Icon icon={<ProfileIcon />} className='text-primary' />
      </ButtonBase>
      <Popper
        placement='bottom-end'
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
        style={{ zIndex: theme.zIndex.tooltip + 1 }}
      >
        {({ TransitionProps }) => (
          <Transitions type='fade' in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250
                  }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <WidgetCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                      <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item>
                          <Stack direction='row' spacing={1.25} alignItems='center'>
                            <Stack>
                              <Typography variant='h6'>{user?.name}</Typography>
                              <Typography variant='body2' color='textSecondary'>
                                {user?.roles && user.roles[0]}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item>
                          <Tooltip title='Logout'>
                            <IconButton size='large' onClick={handleLogout}>
                              <LogoutOutlined />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </CardContent>
                    {open && (
                      <>
                        <Box className='border border-grey'>
                          <Tabs
                            variant='fullWidth'
                            value={value}
                            onChange={handleChange}
                            aria-label='profile tabs'
                          >
                            <Tab
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'capitalize'
                              }}
                              icon={
                                <UserOutlined style={{ marginBottom: 0, marginRight: '10px' }} />
                              }
                              label='Profile'
                              {...a11yProps(0)}
                            />
                          </Tabs>
                        </Box>
                        <TabPanel value={value} index={0} dir={theme.direction}>
                          <ProfileTab handleLogout={handleLogout} onClose={() => setOpen(false)} />
                        </TabPanel>
                      </>
                    )}
                  </WidgetCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  )
}

export default Profile

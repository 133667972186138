// types
import { DefaultConfigProps } from 'types/config'

export const drawerWidth = '15.188rem'
export const rightDrawerWidth = '29.875rem'

export const twitterColor = '#1DA1F2'
export const facebookColor = '#3b5998'
export const linkedInColor = '#0e76a8'

export const borderColor = 'rgba(255, 255, 255, 0.2)'

export const AWS_API = {
  poolId: 'us-east-1_AOfOTXLvD',
  appClientId: '3eau2osduslvb7vks3vsh9t7b0'
}

export const JWT_API = {
  secret: 'SECRET-KEY',
  timeout: '1 days'
}

export const AUTH0_API = {
  client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
  domain: 'dev-w0-vxep3.us.auth0.com'
}

// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
  defaultPath: '/dashboard',
  fontFamily: `'Public Sans', sans-serif`,
  // fontFamily: `'space grotesk', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  mode: 'light',
  presetColor: 'theme1',
  themeDirection: 'ltr'
}

export default config

export const CHARGER_MONOSPACE_CLASS = 'charger-monospace'

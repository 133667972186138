import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import MultiSelect from 'react-select'

import { isBlank, isNotEqual } from '@utils/lodash'
import { flattenObject } from '@utils/obj-utils'

import { Card, CardContent, CardHeader } from '@components/core/card'
import { PasswordInput } from '@components/core/password-input'

import { Button, ButtonProps } from 'components/core/button'
import { Input } from 'components/core/input'
import { Label } from 'components/core/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
  SelectValue
} from 'components/core/select'
import { Separator } from 'components/core/separator'
import { Text } from 'components/core/text'
import { FieldMetaProps, FormikHelpers, FormikProps, useFormik } from 'formik'
import { addToast, removeToast } from 'store/slices/toast'
import { cn } from 'utils/style-utils'

import {
  BasicFieldConfig,
  ChargerFormFieldConfig,
  ChargerFormProps,
  ChargerFormViewProps,
  FieldTypes,
  FormIconPlacementType,
  FormLayoutTypes,
  OneOfFieldConfig,
  UseChargerFormProps,
  isFieldTypeOneOf,
  isFormLayoutTypeInline,
  isFormLayoutTypePopover
} from './types'
import { disguiseFieldNames } from './utils/disguise-field-names'
import { getInitialValues } from './utils/get-initial-values'
import { getPreventAutofillProps } from './utils/get-prevent-autofill-props'
import { getValidationSchema } from './utils/get-validation-schema'
import { transformDataBeforeSubmit } from './utils/transform-data-before-submit'

export const useChargerForm = ({
  fieldsConfig: originalFieldsConfig,
  onSubmit
}: UseChargerFormProps) => {
  const fieldsConfig = useMemo(
    () => disguiseFieldNames(originalFieldsConfig),
    [originalFieldsConfig]
  )
  const validationSchema = useMemo(() => getValidationSchema(fieldsConfig), [fieldsConfig])

  const initialValues = useMemo(() => getInitialValues(fieldsConfig), [fieldsConfig])

  const onSubmitWithDataTransform = (
    values: Record<string, any>,
    formikHelpers: FormikHelpers<Record<string, any>>
  ) => {
    const transformedValues = transformDataBeforeSubmit(values, fieldsConfig)
    onSubmit(transformedValues, formikHelpers)
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema,
    onSubmit: onSubmitWithDataTransform
  })

  return formik
}

export const ChargerFormView = ({
  title,
  formId,
  noSubmit,
  fieldsConfig: originalFieldsConfig,
  onCancel,
  submitLabel,
  layout = FormLayoutTypes.DEFAULT,
  showLabels = true,
  formik,
  allowCopyToClipboard,
  trailingIconPlacement
}: ChargerFormViewProps) => {
  const fieldsConfig = useMemo(
    () => disguiseFieldNames(originalFieldsConfig),
    [originalFieldsConfig]
  )
  const isPopover = isFormLayoutTypePopover(layout)
  const isInline = isFormLayoutTypeInline(layout)

  const formClass = useMemo(() => {
    switch (layout) {
      case FormLayoutTypes.INLINE:
        return 'flex flex-row items-center justify-between gap-2'
      case FormLayoutTypes.FULL_WIDTH:
        return 'w-full'
      case FormLayoutTypes.POPOVER:
        return 'w-full'
      default:
        return ''
    }
  }, [layout])

  const fieldContainerClass = useMemo(() => {
    switch (layout) {
      case FormLayoutTypes.INLINE:
        return 'flex flex-row items-center justify-start flex-auto gap-2 px-4 py-2 bg-grey-lighter rounded-md'
      case FormLayoutTypes.FULL_WIDTH:
      case FormLayoutTypes.POPOVER:
        return 'flex flex-col items-stretch'
      default:
        return 'flex flex-col items-stretch'
    }
  }, [layout])

  const buttonSize = useMemo(() => {
    switch (layout) {
      case FormLayoutTypes.INLINE:
        return 'small'
      default:
        return 'default'
    }
  }, [layout])

  const renderCancelButton = (
    cancelLabel: string | undefined,
    buttonSize: ButtonProps['size'],
    onCancel: (() => void) | undefined,
    className?: string | undefined
  ) => {
    if (!onCancel) return null // Don't render the button if no handler is provided
    return (
      <Button
        onClick={onCancel}
        type='button'
        variant='outline'
        size={buttonSize}
        className={className}
      >
        {cancelLabel || 'Cancel'}
      </Button>
    )
  }

  const renderSubmitButton = (
    submitLabel: string | undefined,
    buttonSize: ButtonProps['size'],
    handleSubmit: (() => void) | undefined,
    className?: string | undefined
  ) => {
    if (!handleSubmit) return null // Don't render the button if no handler is provided
    return (
      <Button
        onClick={handleSubmit}
        type='button'
        variant='primary'
        size={buttonSize}
        className={className}
      >
        {submitLabel || 'Submit'}
      </Button>
    )
  }

  const renderPopoverButtons = () => (
    <>
      {renderCancelButton('Cancel', buttonSize, onCancel, 'mr-auto')}
      {renderSubmitButton(submitLabel, buttonSize, formik.handleSubmit, 'ml-auto')}
    </>
  )

  const renderDefaultButtons = () => (
    <>
      {renderSubmitButton(submitLabel, buttonSize, formik.handleSubmit)}
      {renderCancelButton('Cancel', buttonSize, onCancel)}
    </>
  )

  return (
    <form
      id={formId}
      onSubmit={formik.handleSubmit}
      className={cn('', formClass)}
      autoComplete='new-password'
    >
      <div className='flex items-center justify-between'>
        {title && (
          <Text variant='h5' weight='semibold'>
            {title}
          </Text>
        )}
        {isPopover && (
          <div className={cn('flex items-center gap-2', !title && 'w-full justify-between')}>
            {renderPopoverButtons()}
          </div>
        )}
      </div>

      {(title || isPopover) && <Separator className='my-2' />}

      <div className={cn(fieldContainerClass)}>
        <RenderFields
          fieldsConfig={fieldsConfig}
          {...{
            formik,
            layout,
            showLabels,
            allowCopyToClipboard,
            trailingIconPlacement
          }}
        />

        {!isPopover && !noSubmit && (
          <div className={cn('flex justify-start gap-2', !isInline && 'mt-4')}>
            {renderDefaultButtons()}
          </div>
        )}
      </div>
    </form>
  )
}

export const ChargerForm = ({ fieldsConfig, onSubmit, ...rest }: ChargerFormProps) => {
  const formik = useChargerForm({ fieldsConfig, onSubmit })

  return <ChargerFormView formik={formik} fieldsConfig={fieldsConfig} {...rest} />
}

const formikName = (prefix: string | null, name: string) => (prefix ? `${prefix}.${name}` : name)

const RenderFields = ({
  fieldsConfig,
  formik,
  layout,
  showLabels,
  prefix = null,
  allowCopyToClipboard,
  trailingIconPlacement
}: {
  fieldsConfig: ChargerFormFieldConfig[]
  formik: FormikProps<Record<string, any>>
  layout: FormLayoutTypes
  showLabels: boolean
  prefix?: string | null
  allowCopyToClipboard?: boolean
  trailingIconPlacement?: FormIconPlacementType
}) => {
  return (
    <>
      {fieldsConfig.map((field) => {
        if (isFieldTypeOneOf(field.type)) {
          const oneOfField = field as OneOfFieldConfig
          const fieldName = formikName(prefix, oneOfField.name)
          const isInline = isFormLayoutTypeInline(layout)

          let cardClasses: string

          switch (layout) {
            case FormLayoutTypes.INLINE:
              cardClasses = 'flex flex-row items-center justify-center'
              break
            case FormLayoutTypes.DEFAULT:
              cardClasses = 'mb-4 w-96'
              break
            default:
              cardClasses = 'mb-4 w-full'
              break
          }

          return (
            <Card className={cn(cardClasses)} key={field.name}>
              <CardHeader className={cn('', !isInline && 'pb-0')}>
                <RenderField
                  fieldConfig={{
                    name: `${fieldName}.value`,
                    type: FieldTypes.SELECT,
                    label: oneOfField.label,
                    options: oneOfField.options,
                    defaultValue: oneOfField.defaultValue,
                    readOnly: oneOfField.readOnly
                  }}
                  formik={formik}
                  layout={isInline ? FormLayoutTypes.INLINE : FormLayoutTypes.FULL_WIDTH}
                  showLabels={isInline ? false : showLabels}
                  allowCopyToClipboard={allowCopyToClipboard}
                  trailingIconPlacement={trailingIconPlacement}
                />
              </CardHeader>
              <CardContent className={cn(isInline ? 'pt-4' : 'pb-0')}>
                {oneOfField.sections.map((section) => {
                  if (isNotEqual(_.get(formik.values, `${fieldName}.value`), section.value))
                    return null
                  return (
                    <RenderFields
                      key={section.value}
                      fieldsConfig={section.formFields}
                      formik={formik}
                      layout={isInline ? FormLayoutTypes.INLINE : FormLayoutTypes.FULL_WIDTH}
                      showLabels={isInline ? false : showLabels}
                      prefix={`${fieldName}.data`}
                      allowCopyToClipboard={allowCopyToClipboard}
                      trailingIconPlacement={trailingIconPlacement}
                    />
                  )
                })}
              </CardContent>
            </Card>
          )
        }
        const basicField = field as BasicFieldConfig
        return (
          <React.Fragment key={field.name}>
            <RenderField
              fieldConfig={basicField}
              formik={formik}
              layout={layout}
              showLabels={showLabels}
              prefix={prefix}
              allowCopyToClipboard={allowCopyToClipboard}
              trailingIconPlacement={trailingIconPlacement}
            />
          </React.Fragment>
        )
      })}
    </>
  )
}

const FieldContainer = ({
  divRef,
  children,
  fieldMeta,
  fieldConfig,
  layout,
  showLabels,
  prefix = null
}: {
  children: React.ReactNode
  divRef?: any
  fieldMeta: FieldMetaProps<any>
  fieldConfig: BasicFieldConfig
  layout: FormLayoutTypes
  showLabels?: boolean
  prefix?: string | null
}) => {
  const { name, hidden, helperText, label, isDisabled } = fieldConfig
  const fieldName = formikName(prefix, name)
  const isInline = isFormLayoutTypeInline(layout)

  const fieldClasses = useMemo(() => {
    switch (layout) {
      case FormLayoutTypes.INLINE:
        return 'flex-initial w-48'
      case FormLayoutTypes.FULL_WIDTH:
        return 'flex flex-col'
      case FormLayoutTypes.POPOVER:
        return 'flex flex-col w-96'
      default:
        return 'flex flex-col w-96'
    }
  }, [layout])

  const renderLabel = () => {
    if (hidden || isInline || !showLabels || isBlank(label)) return null

    return (
      <Label
        htmlFor={fieldName}
        className={cn('mb-1 w-full text-details font-normal text-grey-dark')}
      >
        {label}
      </Label>
    )
  }

  const renderHelperText = () => {
    if (isBlank(helperText)) return null

    return (
      <Text variant='tableValue' className='mt-1 text-grey-dark'>
        {helperText}
      </Text>
    )
  }

  function fieldErrorClasses(layout: string) {
    switch (layout) {
      case FormLayoutTypes.POPOVER:
        return 'text-error-popover'
      default:
        return 'text-error-popover'
    }
  }

  const renderFieldError = () => {
    if (hidden || isInline || !fieldMeta.touched || !fieldMeta.error || isInline) return null
    return (
      <span className={cn('text-error', fieldErrorClasses(layout))}>
        {fieldMeta.error || '\u00A0'}
      </span>
    )
  }

  return (
    <>
      <div ref={divRef} className={hidden ? '' : cn(fieldClasses, isDisabled && 'opacity-50')}>
        {renderLabel()}
        {children}
        {renderHelperText()}
      </div>
      {renderFieldError()}
    </>
  )
}

const RenderField = ({
  fieldConfig,
  formik,
  layout,
  showLabels,
  prefix = null,
  allowCopyToClipboard = false,
  trailingIconPlacement
}: {
  formik: FormikProps<Record<string, any>>
  fieldConfig: BasicFieldConfig
  layout: FormLayoutTypes
  showLabels?: boolean
  prefix?: string | null
  allowCopyToClipboard?: boolean
  trailingIconPlacement?: FormIconPlacementType
}) => {
  const {
    name,
    type,
    label,
    options,
    placeholder,
    isDisabled,
    required,
    hidden,
    defaultValue,
    multiSelectDefaultValue,
    readOnly = false
  } = fieldConfig
  const fieldName = formikName(prefix, name)
  const selectRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const [selectState, setSelectState] = useState<boolean>(false)
  const fieldProps = formik.getFieldProps(fieldName)
  const fieldMeta = formik.getFieldMeta(fieldName)
  const failedValidation = !!(fieldMeta.touched && fieldMeta.error)
  const isInline = isFormLayoutTypeInline(layout)
  const isFieldDisabled = readOnly || isDisabled

  // Close select if click is outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setSelectState(false)
      }
    }

    if (selectState) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [selectState, fieldProps.value])

  const inlinePlaceholder = useMemo(
    () => (_.isEqual(layout, 'inline') || _.isEqual(layout, 'popover') ? label : placeholder),
    [layout, label, placeholder]
  )

  const findLabelByValue = (value: any) => {
    const option = options?.find((opt) => opt.value === value)
    return option ? option.label : ''
  }

  // Function to toggle select open/close
  const toggleSelect = () => {
    setSelectState(!selectState)
  }

  const handleSelectChange = async (value: any) => {
    await formik.setFieldValue(fieldName, value)
    await formik.setFieldTouched(fieldName, true)
    await formik.validateField(fieldName)
    setSelectState(false)
  }

  const clearSelect = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    await formik.setFieldValue(fieldName, null)
    await formik.setFieldTouched(fieldName, false)
    await formik.validateField(fieldName) // Only validate the cleared field
    handleSelectChange(null) // Clear the selected value to trigger re-render
  }

  const optionItems = (options ?? []).map((option) => (
    <SelectItem
      key={option.value}
      value={option.value}
      onMouseDown={(e) => {
        e.preventDefault()
        handleSelectChange(option.value)
      }}
    >
      {option.label}
    </SelectItem>
  ))

  const inputType = useMemo(() => {
    if (hidden) {
      return 'hidden'
    }

    switch (type) {
      case FieldTypes.PASSWORD:
        return 'password'
      case FieldTypes.NUMBER:
        return 'number'
      default:
        return 'text'
    }
  }, [hidden, type])

  useEffect(() => {
    if (!isInline) {
      return
    }
    if (failedValidation) {
      const errorMessages = _.values(
        _.pickBy(flattenObject(formik.errors), (error, key) => flattenObject(formik.touched)[key])
      )
      if (errorMessages.length > 0) {
        dispatch(
          addToast({
            description: errorMessages as string[],
            variant: 'error'
          })
        )
      }
    } else if (_.isEmpty(formik.errors)) {
      dispatch(removeToast())
    }
  }, [dispatch, formik.touched, formik.errors, fieldName, layout, isInline, failedValidation])

  const fieldContainerProps = {
    fieldMeta,
    fieldConfig,
    layout,
    isInline,
    showLabels,
    prefix
  }

  switch (type) {
    case FieldTypes.PASSWORD:
      return (
        <FieldContainer {...fieldContainerProps}>
          <PasswordInput
            {...fieldProps}
            id={fieldName}
            disabled={isFieldDisabled}
            required={required}
            placeholder={inlinePlaceholder}
            layout={layout}
            onBlur={formik.handleBlur}
            failedValidation={failedValidation}
            {...getPreventAutofillProps()}
            readOnly={readOnly}
            allowMultiline={true}
            allowCopyToClipboard={allowCopyToClipboard}
            trailingIconPlacement={trailingIconPlacement}
          />
        </FieldContainer>
      )
    case FieldTypes.TEXT_FIELD:
      return (
        <FieldContainer {...fieldContainerProps}>
          <Input
            {...fieldProps}
            id={fieldName}
            type={inputType}
            disabled={isFieldDisabled}
            required={required}
            placeholder={inlinePlaceholder}
            layout={layout}
            onBlur={formik.handleBlur}
            failedValidation={failedValidation}
            {...getPreventAutofillProps()}
            readOnly={readOnly}
            allowMultiline={true}
            allowCopyToClipboard={allowCopyToClipboard}
            trailingIconPlacement={trailingIconPlacement}
          />
        </FieldContainer>
      )
    case FieldTypes.NUMBER:
      return (
        <FieldContainer {...fieldContainerProps}>
          <Input
            {...fieldProps}
            id={fieldName}
            type={inputType}
            min={fieldConfig.minimumValue}
            max={fieldConfig.maximumValue}
            disabled={isFieldDisabled}
            required={required}
            placeholder={inlinePlaceholder}
            layout={layout}
            onBlur={formik.handleBlur}
            failedValidation={failedValidation}
            {...getPreventAutofillProps()}
            readOnly={readOnly}
            allowMultiline={true}
            allowCopyToClipboard={allowCopyToClipboard}
            trailingIconPlacement={trailingIconPlacement}
          />
        </FieldContainer>
      )
    case FieldTypes.SELECT:
    case FieldTypes.SELECT_WITH_CLEAR:
      return (
        <FieldContainer divRef={selectRef} {...fieldContainerProps}>
          <Select
            onValueChange={(value) => handleSelectChange(value)}
            value={fieldProps.value}
            disabled={isFieldDisabled}
            open={selectState}
            required={required}
          >
            <SelectTrigger
              onClick={toggleSelect}
              aria-label={label}
              layout={layout}
              failedValidation={failedValidation}
            >
              <SelectValue placeholder={inlinePlaceholder || 'Select an option'}>
                {findLabelByValue(fieldProps.value) || defaultValue}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {optionItems}
              {type === FieldTypes.SELECT_WITH_CLEAR && fieldProps.value && (
                <>
                  <SelectSeparator />
                  <Button
                    className='w-full px-2'
                    variant='ghost'
                    size='small'
                    onMouseDown={clearSelect}
                  >
                    Clear
                  </Button>
                </>
              )}
            </SelectContent>
          </Select>
        </FieldContainer>
      )

    case FieldTypes.MULTISELECT:
      return (
        <FieldContainer {...fieldContainerProps}>
          <MultiSelect
            options={options as any}
            isMulti={true}
            name={fieldName}
            defaultValue={multiSelectDefaultValue || []}
            closeMenuOnSelect={false}
            onChange={(selectedOptions) => {
              formik.setFieldValue(
                fieldName,
                selectedOptions ? selectedOptions.map((option: any) => option.value) : []
              )
            }}
            className={cn(isInline && 'flex-1')}
            isDisabled={readOnly}
          />
        </FieldContainer>
      )

    default:
      return null
  }
}

export const closePopover = (triggerEl: HTMLButtonElement | null) => {
  if (!triggerEl) return

  triggerEl.setAttribute('data-state', 'closed')
  triggerEl.click() // Ensure the click event to toggle the popover closed
}

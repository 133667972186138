import { useCallback } from 'react'

import { useMeasure } from '@uidotdev/usehooks'
import { humanizeFieldName } from '@utils/string-utils'
import { cn } from '@utils/style-utils'

import { Separator } from '@components/core/separator'
import { Text } from '@components/core/text'

import { useScrollPosition } from '@hooks/use-scroll-position'

const MINIMUM_TABLE_WIDTH_TO_PIN = 500
const TABLE_HEADER_PIN_SHADOW = 'shadow-[0px_5px_0px_0px_rgba(0,0,0,0.03)]'
const TABLE_COLUMN_PIN_SHADOW = 'shadow-[5px_0px_0px_0px_rgba(0,0,0,0.03)]'

export function SettingsTable<T>({
  data,
  titleColumnLabel,
  renderHeaderName,
  idKey = 'id',
  children
}: {
  data: T[]
  titleColumnLabel: string
  renderHeaderName: (item: T) => string
  idKey?: string
  children: ({
    data,
    columnsCount,
    allowPinning,
    scrolledHorizontally,
    idKey
  }: {
    data: T[]
    columnsCount: number
    allowPinning: boolean
    scrolledHorizontally: boolean
    idKey?: string
  }) => React.ReactNode
}) {
  const [scrollRef, { scrollX, scrollY }] = useScrollPosition<HTMLDivElement>()
  const [measureRef, { width }] = useMeasure()

  const captureRef = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        measureRef(node)
        scrollRef(node)
      }
    },
    [measureRef, scrollRef]
  )

  const allowPinning = (width || 0) > MINIMUM_TABLE_WIDTH_TO_PIN
  const scrolledHorizontally = scrollX > 0
  const scrolledVertically = scrollY > 0

  const columnsCount = _.size(data) + 1

  const rowProps = {
    data,
    columnsCount,
    allowPinning,
    scrolledHorizontally,
    idKey
  }

  return (
    <div className='flex h-full flex-col'>
      <div className='mt-2' />
      <div className='h-0 flex-1 overflow-auto' ref={captureRef}>
        <div
          className='grid min-w-max'
          style={{ gridTemplateColumns: `11rem repeat(${columnsCount - 1}, 13rem)` }}
        >
          <div
            className={cn(
              'sticky top-0 z-20 flex flex-col justify-between bg-white',
              allowPinning && scrolledHorizontally && TABLE_COLUMN_PIN_SHADOW,
              allowPinning && 'left-0',
              scrolledVertically && TABLE_HEADER_PIN_SHADOW
            )}
          >
            <Separator />
            <div className='py-2'>
              <Text variant='button' weight='semibold'>
                {titleColumnLabel}
              </Text>
            </div>
            <Separator />
          </div>
          {data.map((item) => (
            <div
              key={_.get(item, idKey)}
              className={cn(
                'sticky top-0 z-10 flex flex-col justify-between bg-white',
                scrolledVertically && TABLE_HEADER_PIN_SHADOW
              )}
            >
              <Separator />
              <div className='mx-3 py-2'>
                <Text variant='button' weight='semibold'>
                  {humanizeFieldName(renderHeaderName(item))}
                </Text>
              </div>
              <Separator />
            </div>
          ))}

          {children(rowProps)}
        </div>
      </div>
    </div>
  )
}

export function SettingsRow<T>({
  title,
  subtitle,
  data,
  columnsCount,
  hideSeparator = false,
  renderField,
  allowPinning,
  scrolledHorizontally,
  idKey = 'id'
}: {
  title?: string
  subtitle?: string
  data: T[]
  columnsCount: number
  hideSeparator?: boolean
  renderField: (p: { item: T }) => React.ReactNode
  allowPinning: boolean
  scrolledHorizontally: boolean
  idKey?: string
}) {
  return (
    <>
      <div
        className={cn(
          'sticky z-10 bg-white',
          allowPinning && scrolledHorizontally && TABLE_COLUMN_PIN_SHADOW,
          allowPinning && 'left-0'
        )}
      >
        <div className={cn('flex h-full items-center', hideSeparator ? 'pt-2' : 'py-2')}>
          {!!title && (
            <Text variant='button' weight='semibold' className='text-grey-dark'>
              {title}
            </Text>
          )}
          {!!subtitle && (
            <Text variant='button' weight='normal' className='pl-2 text-grey-dark'>
              {subtitle}
            </Text>
          )}
        </div>
      </div>
      {data.map((item) => (
        <div className={cn('mx-3', hideSeparator ? 'mt-2' : 'my-2')} key={_.get(item, idKey)}>
          {renderField({ item })}
        </div>
      ))}

      {!hideSeparator && (
        <div style={{ gridColumn: `span ${columnsCount}` }}>
          <Separator />
        </div>
      )}
    </>
  )
}

import { useParams } from 'react-router-dom'

import { Formik } from 'formik'
import * as Yup from 'yup'

import MainCard from '../../../components/MainCard'
import { dispatch } from '../../../store'
import { openSnackbar } from '../../../store/reducers/snackbar'
import axiosServices from '../../../utils/axios'
import { LayoutViewProps } from '../../layout-view/types'
import FeatureForm from './feature-form'

const AddUpdateFeature = ({ pageSettings }: LayoutViewProps) => {
  const { id } = useParams()

  return (
    <MainCard
      pageSettings={pageSettings}
      content={false}
      title={id ? 'Edit Feature' : 'New Feature'}
      sx={{ '& .MuiInputLabel-root': { fontSize: '0.875rem' } }}
    >
      <Formik
        initialValues={{
          id: '',
          name: '',
          description: '',
          allowed_users: '',
          enabled: false,
          submit: null
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(150).required('Name is required.'),
          description: Yup.string().max(255).nullable()
        })}
        onReset={() => console.log('reset')}
        onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
          if (id) {
            axiosServices
              .put(`features/${id}`, { feature: values })
              .then((response) => {
                dispatch(
                  openSnackbar({
                    open: true,
                    message: response.data.message,
                    variant: 'alert',
                    alert: {
                      color: 'success'
                    },
                    close: false
                  })
                )
                setStatus({ success: false })
                setSubmitting(false)
              })
              .catch((error: any) => {
                setStatus({ success: false })
                setErrors(error.errors)
                setSubmitting(false)
              })
          } else {
            axiosServices
              .post(`features`, { feature: values })
              .then((response) => {
                dispatch(
                  openSnackbar({
                    open: true,
                    message: response.data.message,
                    variant: 'alert',
                    alert: {
                      color: 'success'
                    },
                    close: false
                  })
                )
                setStatus({ success: false })
                setSubmitting(false)
              })
              .catch((error: any) => {
                setStatus({ success: false })
                setErrors(error.errors)
                setSubmitting(false)
              })
          }
        }}
      >
        {(props) => <FeatureForm {...props} />}
      </Formik>
    </MainCard>
  )
}

export default AddUpdateFeature

import { ForwardRefExoticComponent, RefAttributes, forwardRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery
} from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'

import {
  closeDrawer,
  selectCurrentMenuItem,
  selectDrawerOpen,
  updateCurrentMenuItem
} from 'store/slices/navigation'
import {
  LinkTarget,
  MenuItemType,
  NavItemType,
  isMenuItemTypeCollapse,
  isMenuItemTypeGroup
} from 'types/menu'

interface Props {
  parent: MenuItemType
  item: NavItemType
  level: number
  searchTerm?: string
  isLastItem?: boolean
}

const NavItem = ({ parent, item, level, searchTerm, isLastItem = false }: Props) => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const drawerOpen = useSelector(selectDrawerOpen)
  const currentMenuItem = useSelector(selectCurrentMenuItem)

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  let itemTarget: LinkTarget = '_self'
  if (item.target) {
    itemTarget = '_blank'
  }

  let listItemProps: {
    component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string
    href?: string
    target?: LinkTarget
  } = {
    component: forwardRef((props, ref) => <Link {...props} to={item.url!} target={itemTarget} />)
  }
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget }
  }

  const Icon = item.icon!
  const itemIcon = item.icon ? (
    <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} />
  ) : (
    false
  )

  const isSelected = currentMenuItem.id === item.id

  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname === item.url) {
      dispatch(updateCurrentMenuItem(item))
    }
    // eslint-disable-next-line
  }, [pathname])

  const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary'
  const iconSelectedColor =
    theme.palette.mode === 'dark' && drawerOpen ? 'text.primary' : 'primary.main'

  let plMultiplier = 6
  if (isMenuItemTypeGroup(parent) && level === 1) {
    plMultiplier = 11
  } else if (isMenuItemTypeGroup(parent)) {
    plMultiplier = 4
  } else if (isMenuItemTypeCollapse(parent) && level === 2) {
    plMultiplier = 8
  } else if (isMenuItemTypeCollapse(parent) && level === 3) {
    plMultiplier = 5
  } else if (isMenuItemTypeCollapse(parent)) {
    plMultiplier = 4
  }

  const onNavItemClick = () => {
    if (isMobile) {
      dispatch(closeDrawer())
    }
  }

  return (
    <ListItemButton
      className='nav-item-box'
      {...listItemProps}
      disabled={item.disabled}
      selected={isSelected}
      onClick={onNavItemClick}
      sx={{
        height: '28px',
        zIndex: 1201,
        ml: '10px',
        mb: isLastItem ? '8px' : '0',
        pl: drawerOpen ? `${level * plMultiplier}px` : 1.5,
        py: !drawerOpen && level === 1 ? 1.25 : 1,
        borderLeft: isSelected
          ? '2px solid rgba(255, 255, 255)'
          : '1px solid rgba(255, 255, 255, 0.25)',
        ...(drawerOpen && {
          '&:hover': {
            bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'primary.lighter'
          },
          '&.Mui-selected': {
            bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'primary.lighter',
            color: iconSelectedColor,
            '&:hover': {
              color: iconSelectedColor,
              bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'primary.lighter'
            }
          }
        }),
        ...(!drawerOpen && {
          '&:hover': {
            bgcolor: 'transparent'
          },
          '&.Mui-selected': {
            '&:hover': {
              bgcolor: 'transparent'
            },
            bgcolor: 'transparent'
          }
        })
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            color: isSelected ? iconSelectedColor : textColor,
            ...(!drawerOpen && {
              borderRadius: 1.5,
              width: 36,
              height: 36,
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter'
              }
            }),
            ...(!drawerOpen &&
              isSelected && {
                bgcolor: theme.palette.mode === 'dark' ? 'primary.900' : 'primary.lighter',
                '&:hover': {
                  bgcolor: theme.palette.mode === 'dark' ? 'primary.darker' : 'primary.lighter'
                }
              })
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && (
        <ListItemText
          primary={
            <Typography
              className='nav-item-title text-[.8rem]'
              variant='menuPage'
              sx={{
                color: isSelected ? iconSelectedColor : textColor,
                '& .highlight': {
                  color: 'white',
                  fontWeight: 'bold'
                }
              }}
              {...(item.title && typeof item.title === 'string' && searchTerm
                ? {
                    dangerouslySetInnerHTML: {
                      __html: item.title.replace(
                        new RegExp(searchTerm, 'gi'),
                        (match: string) => `<span class="highlight">${match}</span>`
                      )
                    }
                  }
                : {
                    children: item.title
                  })}
            />
          }
        />
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  )
}

export default NavItem

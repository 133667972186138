import { useMemo } from 'react'

import { cn } from '@utils/style-utils'

import Button from '@components/core/button'
import { Input } from '@components/core/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@components/core/select'
import { Switch } from '@components/core/switch'
import Tooltip from '@components/core/tooltip'
import { Cancel, Check, Icon } from '@components/icons'

import {
  ActionType,
  ActionTypeEnum,
  TreeItem,
  TreeSource,
  TreeSourceEnum,
  TreeSourceEnumTypes
} from 'components/tree/types'
import { useFormik } from 'formik'

export const TreeItemForm = ({
  source,
  item,
  action,
  onCancel,
  onEdit,
  onCreate,
  afterSubmit
}: {
  source?: TreeSource
  item?: TreeItem
  action: ActionType
  onEdit?: (nodeId: number, item: TreeItem) => void
  onCreate?: ({ parentNodeId, item }: { parentNodeId?: number; item: TreeItem }) => void
  onCancel: () => void
  afterSubmit: () => void
}) => {
  const types = useMemo(() => {
    if (source === TreeSourceEnum.Page) {
      return ['page', 'static_page', 'page_group', 'collection']
    } else if (source === TreeSourceEnum.FinancialReport) {
      return ['heading', 'pointer', 'spacer', 'formula']
    } else {
      return []
    }
  }, [source])

  const initialValues = useMemo(() => {
    if (action === ActionTypeEnum.editNode) {
      return {
        label: item?.label,
        identifier: item?.identifier,
        referencedNodeId: item?.referencedNodeId,
        showOnReport: item?.showOnReport
      }
    } else if (action === ActionTypeEnum.createChildNode) {
      // NOTE: When creating child node id will be passed as parent Id
      return { id: item?.id, label: '', identifier: types?.[0], showOnReport: true }
    } else {
      // NOTE: when creating root node id will be undefined
      return { id: '', label: '', identifier: types?.[0], showOnReport: true }
    }
  }, [action, item, types])

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values, { resetForm }) => {
      if (action === ActionTypeEnum.editNode && onEdit) {
        onEdit(item?.id as number, { ...values, children: [] } as TreeItem)
      } else {
        onCreate &&
          onCreate({
            parentNodeId: item?.id as number,
            item: { ...values, children: [] } as TreeItem
          })
      }

      resetForm()
      afterSubmit()
    }
  })

  const isPointerIdentifier = useMemo(() => {
    return source === TreeSourceEnum.FinancialReport && formik.values.identifier === 'pointer'
  }, [formik.values.identifier, source])

  const showTypeSelector = useMemo(() => {
    if (types.length === 0) return false

    if (action === ActionTypeEnum.editNode && TreeSourceEnumTypes.includes(source || ''))
      return false

    return true
  }, [action, source, types.length])

  return (
    <form onSubmit={formik.handleSubmit} className='flex-1'>
      <div className='my-1 flex flex-1 items-center gap-1'>
        <Input
          className='hidden'
          id='id'
          name='id'
          value={formik.values.id}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <Input
          autoFocus
          layout='fullWidth'
          className={cn(
            'w-full flex-1',
            formik.touched.label && Boolean(formik.errors.label) && 'error'
          )}
          id='label'
          name='label'
          placeholder={formik.values.label}
          required={true}
          value={formik.values.label}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        {isPointerIdentifier && (
          <Input
            id='referencedNodeId'
            name='referencedNodeId'
            value={formik.values.referencedNodeId}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required={true}
            placeholder='Referenced Node ID'
            min={1}
            step={1}
            type='number'
          />
        )}

        {isPointerIdentifier && (
          <Tooltip title='Toggle Pointer Node Visibility In Reports'>
            <Switch
              id='showOnReport'
              name='showOnReport'
              checked={formik.values.showOnReport}
              onCheckedChange={(value) => formik.setFieldValue('showOnReport', value)}
              onBlur={formik.handleBlur}
            />
          </Tooltip>
        )}

        {showTypeSelector && (
          <Select
            id='identifier'
            value={formik.values.identifier}
            className={cn(
              formik.touched.identifier && Boolean(formik.errors.identifier) && 'error'
            )}
            onValueChange={(value) => formik.setFieldValue('identifier', value)}
          >
            <SelectTrigger className='w-auto px-4 focus:ring-0 [&>svg]:text-primary'>
              <SelectValue placeholder='Type' />
            </SelectTrigger>
            <SelectContent>
              {types?.map((type) => (
                <SelectItem key={type} value={type}>
                  {type}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}

        <Button variant='ghost' type='submit' className='rounded-sm p-0'>
          <Icon icon={<Check />} />
        </Button>
        <Button variant='ghost' onClick={onCancel} className='rounded-sm p-0'>
          <Icon icon={<Cancel />} />
        </Button>
      </div>
    </form>
  )
}

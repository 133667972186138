import { getYearEndFrequencyInfo, getYearStartFrequencyInfo } from '@utils/date-frequency-utils'

import { minInfo as minDayInfo } from '@components/control-panel/calendar-filter-popover/day-picker/utils'
import { maxInfo as maxDayInfo } from '@components/control-panel/calendar-filter-popover/day-picker/utils'
import { maxInfo as maxMonthInfo } from '@components/control-panel/calendar-filter-popover/monthly-picker/utils'
import { minInfo as minMonthInfo } from '@components/control-panel/calendar-filter-popover/monthly-picker/utils'
import { minInfo as minQuarterInfo } from '@components/control-panel/calendar-filter-popover/quarter-picker/utils'
import { maxInfo as maxQuarterInfo } from '@components/control-panel/calendar-filter-popover/quarter-picker/utils'
import { minInfo as minWeekInfo } from '@components/control-panel/calendar-filter-popover/weekly-picker/utils'
import { maxInfo as maxWeekInfo } from '@components/control-panel/calendar-filter-popover/weekly-picker/utils'
import { getYears } from '@components/control-panel/calendar-filter-popover/year-picker'

import { Month } from '../types/date'
import {
  DateFilterInfo,
  DateInfo,
  DateRangeType,
  ExpandedDateFilterInfo,
  Frequency,
  FrequencyInfo,
  YearInfo,
  YearMonthDayInfo,
  YearMonthInfo,
  YearQuarterInfo,
  YearWeekInfo
} from '../types/filter'
import {
  convertFreqDateRangeToCalendarRange,
  formatYearMonth,
  getTrailing12Months
} from './date-frequency-utils'
import { formatYearMonthDay, getMonthFromIndex } from './date-utils'

export const convertFrequencyDateToString = <T extends Frequency>(date: DateInfo, frequency: T) => {
  switch (frequency) {
    case Frequency.Daily: {
      const dateT = date as YearMonthDayInfo
      return formatYearMonthDay(dateT.year, dateT.month, dateT.day)
    }
    case Frequency.Weekly: {
      const dateT = date as YearWeekInfo
      return `${dateT.year}-W${dateT.week}`
    }
    case Frequency.Monthly: {
      const dateT = date as YearMonthInfo
      return formatYearMonth(dateT.year, dateT.month)
    }
    case Frequency.Quarterly: {
      const dateT = date as YearQuarterInfo
      return `${dateT.year}-${dateT.quarter}`
    }
    case Frequency.Yearly: {
      const dateT = date as YearInfo
      return `${dateT.year}`
    }
  }
  return ''
}

export const getInitialDateFilterInfo = (fiscalYearStart?: Month): DateFilterInfo => {
  const frequency = Frequency.Monthly
  return {
    frequency,
    fiscalYearStart: fiscalYearStart,
    rangeType: DateRangeType.Range,
    ...getTrailing12Months(fiscalYearStart)
  }
}

export const yearMonthDayInfoToDateString = (date: YearMonthDayInfo) =>
  formatYearMonthDay(date.year, date.month, date.day)

export const getRangeInfoFromDateFilterInfo = (
  dateFilter: DateFilterInfo
): ExpandedDateFilterInfo => {
  const fiscalYearStart = dateFilter.fiscalYearStart ?? Month.Jan
  if (dateFilter.rangeType === DateRangeType.AsOfDate) {
    return {
      rangeType: dateFilter.rangeType,
      endDate: yearMonthDayInfoToDateString(
        convertFreqDateRangeToCalendarRange(
          dateFilter.endDate,
          dateFilter.endDate,
          dateFilter.frequency,
          fiscalYearStart
        ).endDate
      ),
      frequency: dateFilter.frequency,
      originalInfo: dateFilter
    }
  }

  const result = convertFreqDateRangeToCalendarRange(
    dateFilter.startDate,
    dateFilter.endDate,
    dateFilter.frequency,
    fiscalYearStart
  )

  return {
    rangeType: dateFilter.rangeType,
    startDate: yearMonthDayInfoToDateString(result.startDate),
    endDate: yearMonthDayInfoToDateString(result.endDate),
    frequency: dateFilter.frequency,
    originalInfo: dateFilter
  }
}

export const toDateInfo = (date: Date): DateInfo => {
  return {
    year: date.getFullYear(),
    month: getMonthFromIndex(date.getMonth()),
    day: date.getDate()
  }
}

export const getAllowedRange = <T extends Frequency, Result = FrequencyInfo[T]>(
  fiscalYearStart: Month | undefined,
  frequency: T
): {
  minAllowed?: Result
  maxAllowed?: Result
} => {
  const years = getYears(fiscalYearStart)
  if (years.length === 0) {
    return {}
  }
  return {
    minAllowed: getYearStartFrequencyInfo(years[0].year, frequency, fiscalYearStart) as Result,
    maxAllowed: getYearEndFrequencyInfo(years.at(-1)!.year, frequency, fiscalYearStart) as Result
  }
}

export const getMinByFrequency = (
  value1: DateInfo,
  value2: DateInfo,
  frequency: Frequency,
  fiscalYearStart: Month | undefined
) => {
  switch (frequency) {
    case Frequency.Daily:
      return minDayInfo(value1 as YearMonthDayInfo, value2 as YearMonthDayInfo, fiscalYearStart)
    case Frequency.Weekly:
      return minWeekInfo(value1 as YearWeekInfo, value2 as YearWeekInfo)
    case Frequency.Monthly:
      return minMonthInfo(value1 as YearMonthInfo, value2 as YearMonthInfo, fiscalYearStart)
    case Frequency.Quarterly:
      return minQuarterInfo(value1 as YearQuarterInfo, value2 as YearQuarterInfo)
    case Frequency.Yearly:
      return value1.year < value2.year ? value1 : value2
    default:
      throw new Error('Unsupported')
  }
}

export const getMaxByFrequency = (
  value1: DateInfo,
  value2: DateInfo,
  frequency: Frequency,
  fiscalYearStart: Month | undefined
) => {
  switch (frequency) {
    case Frequency.Daily:
      return maxDayInfo(value1 as YearMonthDayInfo, value2 as YearMonthDayInfo, fiscalYearStart)
    case Frequency.Weekly:
      return maxWeekInfo(value1 as YearWeekInfo, value2 as YearWeekInfo)
    case Frequency.Monthly:
      return maxMonthInfo(value1 as YearMonthInfo, value2 as YearMonthInfo, fiscalYearStart)
    case Frequency.Quarterly:
      return maxQuarterInfo(value1 as YearQuarterInfo, value2 as YearQuarterInfo)
    case Frequency.Yearly:
      return value1.year > value2.year ? value1 : value2
    default:
      throw new Error('Unsupported')
  }
}

import React, { useCallback, useEffect, useState } from 'react'

import { getDisplayName } from '@utils/schema-renaming'

import { ColumnSelector } from '@components/component-management/ColumnSelector'
import { CustomFieldPanel } from '@components/component-management/CustomFieldPanel'
import { DataSelectionPanel } from '@components/component-management/DataSelectionPanel'
import { DataTabs } from '@components/component-management/DataTabs'
import { ModelSelector } from '@components/component-management/ModelSelector'
import { ResizablePanel } from '@components/component-management/ResizablePanel'

import { useLatestRef } from '@hooks/useLatestRef'

import { useDispatch, useSelector } from '@store/index'
import { selectPending } from '@store/slices/component/basic-config'
import {
  chooseField,
  chooseModel,
  initQueryData,
  reset,
  selectCurrentSelectedModel,
  selectQueryData,
  selectSearchText,
  setQueryData,
  setSearchText
} from '@store/slices/component/query-config'
import { selectSchemaRenamings } from '@store/slices/schema-renamings'

import { useFetchCustomField } from 'pages/component-management/queries/fetch-custom-field'
import { useFetchModels } from 'pages/component-management/queries/fetch-models'
import { useFetchModelsDetail } from 'pages/component-management/queries/fetch-models-detail'

import { Wrapper } from '../common/wrapper'
import { IColumnData, ICustomField, IDataTab } from '../types/query-builder-types'
import { selectDataTabsFromModel } from '../utils/select-data-tabs-from-model'
import { ModelPreview } from './model-preview'

export enum SelectionMode {
  Normal,
  Custom
}

function DataSelection() {
  const [selectionMode, setSelectionMode] = useState<SelectionMode>(SelectionMode.Normal)

  const { data: models, isLoading } = useFetchModels()
  const isComponentLoading = useSelector(selectPending)

  const dispatch = useDispatch()
  const selectedModelName = useSelector(selectCurrentSelectedModel())
  const schemaRenamings = useSelector(selectSchemaRenamings)
  const searchText = useSelector(selectSearchText)

  let filteredModels = models
  if (models && searchText) {
    filteredModels = _.filter(
      models,
      (model) =>
        model.name === selectedModelName ||
        model.name.toLowerCase().includes(searchText.toLowerCase())
    )
  }

  if (isLoading || !models || isComponentLoading) {
    return <div>Loading...</div>
  }

  if (!selectedModelName) {
    return (
      <div className='h-full p-4'>
        <ModelSelector
          models={filteredModels!}
          renderName={(name) => getDisplayName(schemaRenamings, name)}
          onSelect={(model) => {
            dispatch(chooseModel({ modelName: model.name, modelType: model.type }))
            dispatch(setSearchText(''))
          }}
        />
      </div>
    )
  }

  return (
    <ColumnSelectorView
      modelName={selectedModelName}
      renderName={(modelName: string, columnName?: string) =>
        getDisplayName(schemaRenamings, modelName, columnName)
      }
      onPressBack={() => dispatch(chooseModel({ modelName: '', modelType: undefined }))}
      selectionMode={selectionMode}
      setSelectionMode={setSelectionMode}
      searchText={searchText}
    />
  )
}

function ColumnSelectorView({
  modelName,
  renderName,
  onPressBack,
  selectionMode,
  setSelectionMode,
  searchText = ''
}: {
  modelName: string
  renderName?: (modelName: string, columnName?: string) => string | undefined
  onPressBack?: () => void
  selectionMode: SelectionMode
  setSelectionMode: (mode: SelectionMode) => void
  searchText?: string
}) {
  const [showForeignKeys, setShowForeignKeys] = useState<boolean>(false)
  const [hideUnusedTables, setHideUnusedTables] = useState<boolean>(false)
  const [customTargetModel, setCustomTargetModel] = useState<string>('')
  const [selectedCustomFieldId, setSelectedCustomFieldId] = useState<number | undefined>()

  // tabs
  const [tabs, setTabs] = useState<IDataTab[]>([{ modelName, associationName: modelName }])
  const [pinnedTab, setPinnedTab] = useState<IDataTab>({ modelName, associationName: modelName })
  const [selectedTab, setSelectedTab] = useState<IDataTab | undefined>()

  const onTableExpand = useCallback((tab: IDataTab | undefined) => {
    setTabs((tabs) => _.compact(_.uniqBy([...tabs, tab], (curr) => curr?.associationName)))
    setSelectedTab(tab)
  }, [])

  const isCustomMode = selectionMode === SelectionMode.Custom

  const { data, isLoading } = useFetchModelsDetail(modelName)

  const dispatch = useDispatch()
  const selectedData = useSelector(selectQueryData())
  const selectedDataRef = useLatestRef(selectedData)
  const selectedCustomData = useSelector(selectQueryData('custom-field'))

  const onSelect = (column: IColumnData, path: string) =>
    dispatch(
      chooseField({
        field: { ...column, configs: [{}] },
        path,
        placement: isCustomMode ? 'custom-field' : undefined
      })
    )

  const dismissCustomFieldPanel = () => {
    setSelectedCustomFieldId(undefined)
    dispatch(initQueryData({ placement: 'custom-field' })) // clear working query data state
    setSelectionMode(SelectionMode.Normal) // then close the CustomFieldPanel
  }

  useEffect(() => {
    if (!data) return

    setTabs((tabs) =>
      _.uniqBy(
        _.concat(tabs, selectDataTabsFromModel(data, selectedDataRef.current)),
        (tab) => tab.associationName
      )
    )
  }, [data, selectedDataRef])

  return (
    <ResizablePanel
      direction='vertical'
      primaryContent={
        <div className='relative flex size-full justify-between p-4'>
          <div className='-my-4 -ml-4 min-w-0 flex-1'>
            <div className='h-full overflow-auto p-4'>
              <ColumnSelector
                model={data}
                modelName={modelName}
                renderName={renderName}
                onPressBack={onPressBack}
                showForeignKeys={showForeignKeys}
                setShowForeignKeys={setShowForeignKeys}
                hideUnusedTables={hideUnusedTables}
                loading={isLoading}
                onTableExpand={onTableExpand}
                onSelect={onSelect}
                onColumnEdit={(modelName: string, columnId?: number) => {
                  setSelectedCustomFieldId(columnId)
                  setCustomTargetModel(modelName)
                  setSelectionMode(SelectionMode.Custom)
                }}
                selectedData={selectedData}
                selectedCustomData={selectedCustomData}
                selectionMode={selectionMode}
                customTargetModel={customTargetModel}
                selectedCustomFieldId={selectedCustomFieldId}
                searchText={searchText}
              />
            </div>
          </div>

          {isCustomMode ? (
            <QueryCustomField customFieldId={selectedCustomFieldId}>
              {({ data, isLoading }) => (
                <CustomFieldPanel
                  modelName={customTargetModel}
                  data={data}
                  isLoading={isLoading}
                  selectedCustomData={selectedCustomData}
                  onSelect={onSelect}
                  renderName={renderName}
                  onCancel={dismissCustomFieldPanel}
                  onSave={dismissCustomFieldPanel}
                />
              )}
            </QueryCustomField>
          ) : (
            <DataSelectionPanel
              selectedData={selectedData}
              hideUnusedTables={hideUnusedTables}
              setHideUnusedTables={setHideUnusedTables}
              onClear={() => dispatch(reset())}
              onSelect={onSelect}
              renderName={renderName}
            />
          )}

          <div className='absolute bottom-0 left-0'>
            <DataTabs
              className='ml-1'
              tabs={tabs}
              pinnedTab={pinnedTab}
              selectedTab={selectedTab}
              onPin={setPinnedTab}
              onChange={onTableExpand}
            />
          </div>
        </div>
      }
      secondaryContent={
        <ResizablePanel
          direction='horizontal'
          primaryContent={<ModelPreview modelName={pinnedTab.modelName} />}
          secondaryContent={
            _.isEqual(pinnedTab, selectedTab) ? undefined : selectedTab?.modelName ? ( // no need to show duplicate panels for same tab
              <ModelPreview modelName={selectedTab.modelName} />
            ) : undefined
          }
        />
      }
      secondaryDefaultSize={20}
    />
  )
}

function QueryCustomField({
  customFieldId,
  children
}: {
  customFieldId?: number
  children: (props: { data?: ICustomField; isLoading: boolean }) => React.ReactNode
}) {
  const dispatch = useDispatch()

  const { data, isLoading } = useFetchCustomField(customFieldId)

  useEffect(() => {
    if (data?.path) {
      // load data from the server into the working query data redux state
      dispatch(setQueryData({ queryData: data.path, placement: 'custom-field' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return <>{children({ data, isLoading })}</>
}

const withWrapper =
  <T extends React.ComponentType<any>>(WrappedComponent: T) =>
  (props: React.ComponentProps<T>) => (
    <Wrapper scrollable noPadding>
      <WrappedComponent {...props} />
    </Wrapper>
  )

export default withWrapper(DataSelection)

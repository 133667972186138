import { AccountTreeOutlined } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

export const PageGroupIcon = (props: any) => {
  const theme = useTheme()
  return (
    <AccountTreeOutlined
      style={{ fontSize: '1.2rem', color: props.color ? props.color : theme.palette.primary.main }}
    />
  )
}

import { Checkbox } from '@components/core/checkbox'
import { Label } from '@components/core/label'
import { Separator } from '@components/core/separator'

import { useDispatch } from '@store/index'
import { useSelector } from '@store/index'
import {
  selectSettings,
  updateSettingsData,
  updateYAxisData
} from '@store/slices/component/chart-config'

import { Description } from '../common/description'
import { Title } from '../common/title'
import { IAxis, IChartSettings } from '../types/chart-builder-types'

export function DefaultDataLabelsSettings() {
  const dispatch = useDispatch()
  const settings = useSelector(selectSettings)

  return (
    <>
      <div className='mt-4'>
        <Title>Data Labels</Title>
        <Description>Select Data Labels Settings</Description>
      </div>
      <Separator className='my-2' />
      <div className='flex items-center gap-8'>
        <SharedDataLabelsForm
          show_data_labels={settings.show_data_labels}
          data_labels_hook={settings.data_labels_hook}
          handleChange={(payload) => dispatch(updateSettingsData(payload))}
        />
      </div>
    </>
  )
}

export function AxisDataLabelsSettings({ item: data }: { item: IAxis }) {
  const dispatch = useDispatch()

  const { id, data_labels_override = false } = data

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex items-center gap-2'>
        <Label
          className='whitespace-normal text-submenu font-normal'
          htmlFor={`override-data-labels-${data.id}`}
        >
          Override Data Labels Settings
        </Label>
        <Checkbox
          id={`override-data-labels-${data.id}`}
          className='m-2'
          checked={data_labels_override}
          onCheckedChange={(value) =>
            dispatch(updateYAxisData({ id, data_labels_override: value as boolean }))
          }
        />
      </div>
      {data_labels_override && (
        <SharedDataLabelsForm
          uid={data.id}
          show_data_labels={data.show_data_labels}
          data_labels_hook={data.data_labels_hook}
          handleChange={(payload) => dispatch(updateYAxisData(_.assign({ id }, payload)))}
        />
      )}
    </div>
  )
}

function SharedDataLabelsForm({
  uid = 'default',
  show_data_labels = false,
  data_labels_hook = false,
  handleChange
}: {
  uid?: string
  show_data_labels?: boolean
  data_labels_hook?: boolean
  handleChange: (p: Partial<IChartSettings | IAxis>) => void
}) {
  return (
    <>
      <div className='flex items-center gap-2'>
        <Label className='text-submenu font-normal' htmlFor={`show-data-labels-${uid}`}>
          Show Data Labels
        </Label>
        <Checkbox
          id={`show-data-labels-${uid}`}
          className='m-2'
          checked={show_data_labels || false}
          onCheckedChange={(value) => handleChange({ show_data_labels: value as boolean })}
        />
      </div>

      <div className='flex items-center gap-2'>
        <Label className='text-submenu font-normal' htmlFor={`data-labels-hook-${uid}`}>
          Data Labels Hook
        </Label>
        <Checkbox
          id={`data-labels-hook-${uid}`}
          className='m-2'
          checked={data_labels_hook || false}
          onCheckedChange={(value) => handleChange({ data_labels_hook: value as boolean })}
        />
      </div>
    </>
  )
}

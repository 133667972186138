import InfoIcon from '@mui/icons-material/Info'
import { Divider, Grid, Link, SelectChangeEvent, TextField, Typography } from '@mui/material'

import { enumToOptions } from '@utils/obj-utils'

import useDelayedUpdate from '@hooks/use-delayed-update'

import { useDispatch } from '@store/index'
import { updateYAxisData } from '@store/slices/component/chart-config'

import MuiSelect from 'components/form/mui-select'

import { IAxis, ValueFormattingType } from '../types/chart-builder-types'

export default function NumberFormatting({ selected }: { selected: IAxis }) {
  const dispatch = useDispatch()

  const { id, custom_value_short, custom_value_long, value_formatting_type } = selected
  const valueFormattingOptions = enumToOptions(ValueFormattingType)

  const [customShort, setCustomShort] = useDelayedUpdate(custom_value_short, (value) =>
    dispatch(updateYAxisData({ custom_value_short: value, id }))
  )
  const [customLong, setCustomLong] = useDelayedUpdate(custom_value_long, (value) =>
    dispatch(updateYAxisData({ custom_value_long: value, id }))
  )

  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h5' display='flex' justifyContent='flex-start' gap={1}>
          Number Formatting
          <Link
            href='https://www.amcharts.com/docs/v5/concepts/formatters/formatting-numbers/#Format_codes'
            target='_blank'
            rel='noopener noreferrer'
            display='flex'
            alignItems='center'
          >
            <InfoIcon fontSize='small' />
          </Link>
        </Typography>

        <Typography variant='details' gutterBottom>
          Add formatting to the numbers
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <MuiSelect
          options={valueFormattingOptions}
          value={value_formatting_type || ''}
          onChange={(e: SelectChangeEvent<string>) => {
            dispatch(
              updateYAxisData({ id, value_formatting_type: e.target.value as ValueFormattingType })
            )
          }}
          label='Formatting Type'
        />
      </Grid>
      {value_formatting_type === ValueFormattingType.Custom && (
        <>
          <Grid item xs={12} sm={6}>
            <TextField
              value={customShort}
              onChange={(e) => setCustomShort(e.target.value)}
              fullWidth
              variant='outlined'
              label='Short'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              value={customLong}
              onChange={(e) => setCustomLong(e.target.value)}
              fullWidth
              variant='outlined'
              label='Long'
            />
          </Grid>
        </>
      )}
    </>
  )
}

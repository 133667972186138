import React from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { CircularProgress } from '@mui/material'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'

import { useLayoutContext } from 'contexts/LayoutContext'

import useFetchPage from '../../queries/fetch-page'
import { ViewMode } from '../../types'
import FreeStyleSection from '../container_components/freestyle-section'

interface GeneralPagePreviewProps {
  parent_page_id: number
  onCancelGeneralPageClick: () => void
  devMode: boolean
  gridLine: boolean
  onComponentRemove: (path: string, layout_item: any) => void
  onLayoutChange: (newLayout: any, layouts: any, props: any) => void
  layoutStructure: any[] // Replace with the actual type of layoutStructure
  onComponentsManuallySelected: (components: any[]) => void
}

const GeneralPagePreview: React.FC<GeneralPagePreviewProps> = ({
  parent_page_id,
  onCancelGeneralPageClick,
  devMode,
  gridLine,
  onComponentRemove,
  onLayoutChange,
  layoutStructure,
  onComponentsManuallySelected
}) => {
  const { isLoading, data: pageData } = useFetchPage(parent_page_id.toString())

  const { selectedWidgets, setSelectedWidgets } = useLayoutContext()

  if (isLoading) {
    return <CircularProgress />
  }

  const handleApplyClick = () => {
    onComponentsManuallySelected && onComponentsManuallySelected(selectedWidgets)
    setSelectedWidgets([])
    onCancelGeneralPageClick()
  }

  return (
    <div style={{ flex: 1 }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={onCancelGeneralPageClick}>
            <ArrowBackIcon />
          </IconButton>
          <h3 style={{ marginLeft: '10px' }}>Previewing General Layout</h3>
        </div>
      </div>

      <div className='col-config-container'>
        {devMode && <label className='dev-info-text'>DropContainer | Droppable</label>}
        <div className='drop-container'>
          {pageData.layout_structure.map((row: any, index: any) => {
            const currentPath = `${index}`
            return (
              <React.Fragment key={row.id}>
                <FreeStyleSection
                  mode={ViewMode.parentView}
                  gridLine={gridLine}
                  devMode={devMode}
                  onComponentRemove={onComponentRemove}
                  onLayoutChange={onLayoutChange}
                  path={currentPath}
                  key={row.id}
                  id={row.id}
                  section={row}
                  relative_layout_structure={layoutStructure}
                />
              </React.Fragment>
            )
          })}
        </div>
      </div>

      <div style={{ display: 'flex', gap: '8px', marginTop: '10px' }}>
        <Button variant='contained' color='primary' onClick={handleApplyClick}>
          Apply ({selectedWidgets.length})
        </Button>
      </div>
    </div>
  )
}

export default GeneralPagePreview

import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

import { restrictToVerticalAxis } from '@dnd-kit/modifiers'

import { restrictToCustomParentElement } from '@utils/dnd-utils'
import { isNotEqual } from '@utils/lodash'
import { cn } from '@utils/style-utils'

import Button from '@components/core/button'
import { IconButton } from '@components/core/icon-button'
import { Input } from '@components/core/input'
import { Separator } from '@components/core/separator'
import { Text } from '@components/core/text'
import { Select } from '@components/form/select'
import { Cancel, DragIndicator, Icon } from '@components/icons'
import SettingIcon from '@components/icons/setting.svg?react'
import { RenderItemParams, SortableList } from '@components/sortable-list'

import useDelayedUpdate from '@hooks/use-delayed-update'

import {
  AggFuncStringType,
  ITableColumn,
  PivotComparatorConfigRow,
  PivotComparatorOrder,
  PivotComparatorType,
  isPivotComparatorTypeFixed,
  isPivotComparatorTypeRest
} from '../../types/table-builder-types'
import { isContainerColumnLabels, isContainerValues } from './types'

export const WithContainerColumnsSettings = ({
  column,
  section,
  columns,
  setColumns,
  onChangeColumns,
  children,
  isOverContainer,
  columnLabelsControlSelected,
  setColumnLabelsControlSelected
}: {
  column: ITableColumn
  section: string
  columns: ITableColumn[]
  setColumns: Dispatch<SetStateAction<ITableColumn[]>>
  onChangeColumns: (columns: ITableColumn[]) => void
  children: React.ReactNode
  isOverContainer: boolean
  columnLabelsControlSelected: string | null
  setColumnLabelsControlSelected: Dispatch<SetStateAction<string | null>>
}) => {
  if (isContainerValues(section)) {
    return (
      <div className='flex items-center justify-between'>
        {children}
        <div className={cn(isOverContainer && 'opacity-20')}>
          <Select
            className='w-24'
            value={(column.aggFunc as string) || AggFuncStringType.SUM}
            onChange={(value) => {
              const newColumns = columns.map((col) => {
                if (col.field === column.field) {
                  return { ...col, aggFunc: value }
                }
                return col
              })
              setColumns(newColumns)
              onChangeColumns(newColumns)
            }}
            options={[
              { label: 'Sum', value: AggFuncStringType.SUM },
              { label: 'Average', value: AggFuncStringType.AVG },
              { label: 'Count', value: AggFuncStringType.COUNT },
              { label: 'Min', value: AggFuncStringType.MIN },
              { label: 'Max', value: AggFuncStringType.MAX },
              { label: 'First', value: AggFuncStringType.FIRST },
              { label: 'Last', value: AggFuncStringType.LAST }
            ]}
          />
        </div>
      </div>
    )
  }

  if (isContainerColumnLabels(section)) {
    const isColumnLabelsControlSelected = columnLabelsControlSelected === column.field

    return (
      <div className='flex flex-col gap-2'>
        <div className='flex items-center justify-between'>
          {children}
          <div className={cn(isOverContainer && 'opacity-20')}>
            <IconButton
              variant={isColumnLabelsControlSelected ? 'primary' : 'outline'}
              onClick={() =>
                setColumnLabelsControlSelected(isColumnLabelsControlSelected ? null : column.field)
              }
            >
              <SettingIcon />
            </IconButton>
          </div>
        </div>
        {isColumnLabelsControlSelected && (
          <PivotColumnOrdering {...{ column, columns, setColumns, onChangeColumns }} />
        )}
      </div>
    )
  }

  return <>{children}</>
}

type PivotComparatorConfigRowWithIndex = PivotComparatorConfigRow & { index: string }

const PivotColumnOrdering = ({
  column,
  columns,
  setColumns,
  onChangeColumns
}: {
  column: ITableColumn
  columns: ITableColumn[]
  setColumns: Dispatch<SetStateAction<ITableColumn[]>>
  onChangeColumns: (columns: ITableColumn[]) => void
}) => {
  const parentRef = useRef<HTMLDivElement>(null)

  const pivotComparatorConfig = _.get(
    column,
    ['customData', 'pivotComparatorConfig'],
    [{ type: PivotComparatorType.REST, order: PivotComparatorOrder.ASC }]
  ) as PivotComparatorConfigRow[]

  const pivotComparatorConfigWithIndex = _.map(pivotComparatorConfig, (row, index) =>
    _.assign({ index: `${index + 1}` }, row)
  ) as PivotComparatorConfigRowWithIndex[]

  const currColIndex = _.findIndex(columns, { field: column.field })

  const modifyColumn = (changes: Partial<ITableColumn>) => {
    const newColumns = [...columns]
    newColumns[currColIndex] = _.assign({}, newColumns[currColIndex], changes)
    setColumns(newColumns)
    setTimeout(() => onChangeColumns(newColumns), 0) // to fix input value issue
  }

  const addPivotComparatorConfig = () => {
    modifyColumn({
      customData: {
        pivotComparatorConfig: _.concat(pivotComparatorConfig, {
          type: PivotComparatorType.FIXED,
          value: ''
        })
      }
    })
  }

  const removePivotComparatorConfig = (index: string) => {
    modifyColumn({
      customData: {
        pivotComparatorConfig: _.map(
          _.filter(pivotComparatorConfigWithIndex, (row) => isNotEqual(row.index, index)),
          (row) => _.omit(row, 'index')
        ) as PivotComparatorConfigRow[]
      }
    })
  }

  const updatePivotComparatorConfig = (
    index: string,
    values: Partial<PivotComparatorConfigRow>
  ) => {
    modifyColumn({
      customData: {
        pivotComparatorConfig: _.map(
          _.map(pivotComparatorConfigWithIndex, (row) =>
            row.index === index ? _.assign({}, row, values) : row
          ),
          (row) => _.omit(row, 'index')
        ) as PivotComparatorConfigRow[]
      }
    })
  }

  const [inputFocus, setInputFocus] = useState<string | null>(null)

  return (
    <div
      ref={parentRef}
      className='flex flex-col rounded border border-solid border-grey p-4 pb-2 shadow-brand'
    >
      <div className='flex items-center justify-between'>
        <Text variant='submenu' className='text-primary-darker' weight='semibold'>
          Pivot Column Ordering
        </Text>
        <Button variant='outline' onClick={() => addPivotComparatorConfig()}>
          Add Pin
        </Button>
      </div>
      <Separator className='my-2' />
      <SortableList<PivotComparatorConfigRowWithIndex>
        items={pivotComparatorConfigWithIndex}
        idKey='index'
        dndContextModifers={[restrictToCustomParentElement(parentRef), restrictToVerticalAxis]}
        renderItem={(props) => (
          <ConfigRenderItem
            {...props}
            remove={removePivotComparatorConfig}
            update={updatePivotComparatorConfig}
            setInputFocus={setInputFocus}
            inputFocus={inputFocus}
          />
        )}
        WrapperComponent={({ children }) => <div className='w-full'>{children}</div>}
        setItems={(values) => {
          modifyColumn(
            _.assign({}, column, {
              customData: {
                pivotComparatorConfig: _.map(values, (row) =>
                  _.omit(row, 'index')
                ) as PivotComparatorConfigRow[]
              }
            })
          )
        }}
      />
    </div>
  )
}

const ConfigRenderItem: (
  props: RenderItemParams<PivotComparatorConfigRowWithIndex> & {
    remove: (index: string) => void
    update: (index: string, values: Partial<PivotComparatorConfigRow>) => void
    setInputFocus: (index: string | null) => void
    inputFocus: string | null
  }
) => React.JSX.Element = ({
  draggableProps,
  setNodeRef,
  style,
  item,
  remove,
  update,
  setInputFocus,
  inputFocus
}) => {
  const [value, setValue] = useDelayedUpdate(
    item.value || '',
    (value) => update(item.index, { value }),
    500
  )

  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (isNotEqual(inputFocus, item.index) || !inputRef.current) return

    inputRef.current.focus()
  }, [inputFocus, item.index])

  return (
    <div ref={setNodeRef} style={style} className='w-full'>
      <div className='flex w-full items-center justify-between rounded-md bg-white'>
        <div className='flex items-center gap-2'>
          <IconButton {...draggableProps} className='border-0 p-0'>
            <Icon icon={<DragIndicator />} />
          </IconButton>
          <Text variant='submenu' className='text-primary-darker' weight='semibold'>
            {isPivotComparatorTypeRest(item.type) && 'Remaining Columns'}
            {isPivotComparatorTypeFixed(item.type) && 'Pinned'}
          </Text>
        </div>
        {isPivotComparatorTypeRest(item.type) && (
          <Select
            className='w-28'
            options={[
              { label: 'Ascending', value: PivotComparatorOrder.ASC },
              { label: 'Descending', value: PivotComparatorOrder.DESC }
            ]}
            value={item.order as PivotComparatorOrder}
            onChange={(value) => update(item.index, { order: value as PivotComparatorOrder })}
          />
        )}
        {isPivotComparatorTypeFixed(item.type) && (
          <div className='flex items-center gap-1'>
            <Text variant='details' className='text-primary-darker'>
              Column Name
            </Text>
            <Input
              className='w-24'
              ref={inputRef}
              value={value}
              onChange={(event) => setValue(event.target.value)}
              onFocus={() => setInputFocus(item.index)}
              onBlur={() => setInputFocus(null)}
            />
          </div>
        )}
        {!isPivotComparatorTypeRest(item.type) && (
          <div className='flex gap-2'>
            <IconButton onClick={() => remove(item.index)} className='border-0 p-0'>
              <Icon icon={<Cancel />} />
            </IconButton>
          </div>
        )}
      </div>
      <Separator className='my-2' />
    </div>
  )
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material'

import ErrorBoundary from 'components/ErrorBoundary'

import ControlPanels from '../control-panels'
import { ComponentGenre } from '../types/component-types'
import Viewer from '../viewer'

const PreviewComponent = ({
  open,
  setOpen,
  id,
  genre,
  label
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  id: number
  genre: ComponentGenre
  label: string
}) => {
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='preview-component'
      aria-describedby='preview-component'
    >
      <DialogTitle id='alert-dialog-title'>{label}</DialogTitle>
      <DialogContent>
        <ErrorBoundary
          fallback={<Typography color='textSecondary'>Broken Component, Please Edit</Typography>}
        >
          <ControlPanels />
          <div className='h-[270px] w-[720px] max-w-full'>
            <Viewer id={id} genre={genre} />
          </div>
        </ErrorBoundary>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PreviewComponent

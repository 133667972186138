import { Box } from '@mui/material'

import LogoIcon from 'components/logo/LogoIcon'

const AuthBackground = () => {
  return (
    <Box sx={{ position: 'fixed', left: '-3rem', bottom: '-6rem', opacity: 0.2 }}>
      <LogoIcon primaryColor='#FFF' style={{ width: '46.125rem', height: '50.4375rem' }} />
    </Box>
  )
}

export default AuthBackground

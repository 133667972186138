// material-ui
import { Theme } from '@mui/material/styles'

// project import
import Accordion from './Accordion'
import AccordionDetails from './AccordionDetails'
import AccordionSummary from './AccordionSummary'
import Alert from './Alert'
import AlertTitle from './AlertTitle'
import AppBar from './AppBar'
import Autocomplete from './Autocomplete'
import Badge from './Badge'
import Button from './Button'
import ButtonBase from './ButtonBase'
import ButtonGroup from './ButtonGroup'
import CardContent from './CardContent'
import Checkbox from './Checkbox'
import Chip from './Chip'
import Dialog from './Dialog'
import DialogContentText from './DialogContentText'
import DialogTitle from './DialogTitle'
import Drawer from './Drawer'
import Fab from './Fab'
import FilledInput from './FilledInput'
import FormControlLabel from './FormControlLabel'
import FormHelperText from './FormHelperText'
import IconButton from './IconButton'
import InputBase from './InputBase'
import InputLabel from './InputLabel'
import LinearProgress from './LinearProgress'
import Link from './Link'
import ListItemButton from './ListItemButton'
import ListItemIcon from './ListItemIcon'
import Menu from './Menu'
import OutlinedInput from './OutlinedInput'
import Pagination from './Pagination'
import PaginationItem from './PaginationItem'
import Popover from './Popover'
import Radio from './Radio'
import Select from './Select'
import Slider from './Slider'
import Stack from './Stack'
import SvgIcon from './SvgIcon'
import Switch from './Switch'
import Tab from './Tab'
import TableBody from './TableBody'
import TableCell from './TableCell'
import TableFooter from './TableFooter'
import TableHead from './TableHead'
import TablePagination from './TablePagination'
import TableRow from './TableRow'
import Tabs from './Tabs'
import TextField from './TextField'
import ToggleButton from './ToggleButton'
import ToggleButtonGroup from './ToggleButtonGroup'
import Toolbar from './Toolbar'
import TreeItem from './TreeItem'
import Typography from './Typography'

export default function ComponentsOverrides(theme: Theme) {
  return _.merge(
    Accordion(theme),
    AccordionDetails(theme),
    AccordionSummary(theme),
    Alert(theme),
    AlertTitle(),
    AppBar(theme),
    Autocomplete(),
    Badge(theme),
    Button(theme),
    ButtonBase(),
    ButtonGroup(),
    CardContent(),
    Checkbox(theme),
    Chip(theme),
    Dialog(),
    DialogContentText(theme),
    DialogTitle(),
    Drawer(),
    Fab(theme),
    FilledInput(theme),
    FormHelperText(theme),
    IconButton(theme),
    InputBase(theme),
    InputLabel(theme),
    LinearProgress(),
    Link(),
    ListItemButton(theme),
    ListItemIcon(theme),
    Menu(theme),
    FormControlLabel(theme),
    OutlinedInput(theme),
    Pagination(),
    PaginationItem(theme),
    Popover(theme),
    Radio(theme),
    Select(theme),
    Stack(theme),
    Slider(theme),
    Stack(theme),
    SvgIcon(theme),
    Switch(theme),
    Tab(theme),
    TableBody(theme),
    TableCell(theme),
    TableFooter(theme),
    TableHead(theme),
    TablePagination(),
    TableRow(),
    Tabs(),
    TextField(theme),
    ToggleButton(theme),
    ToggleButtonGroup(theme),
    Toolbar(theme),
    TreeItem(),
    Typography()
  )
}

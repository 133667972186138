/**
 * Recursively converts the keys of an object to camelCase, with options to ignore specified paths.
 *
 * @param obj - A plain object or array whose keys need to be converted to camelCase. If it is any other type, it is returned as is.
 * @param ignorePaths - An array of string paths that should be ignored during the conversion. Defaults to an empty array.
 * @param parent - A string used to track the current path of recursion. This helps in identifying the correct paths to ignore. Defaults to an empty string.
 * @returns The new object with camelCased keys, retaining the original structure of arrays and nested objects.
 */
export const camelCaseKeys = (obj: any, ignorePaths: string[] = [], parent: string = ''): any => {
  if (_.isArray(obj)) {
    if (_.includes(ignorePaths, parent)) {
      return obj
    } else {
      return obj.map((item: any): any => camelCaseKeys(item, ignorePaths, parent))
    }
  } else if (_.isPlainObject(obj)) {
    return _.transform(obj, (result: any, value, key: any) => {
      const camelKey = _.camelCase(key)
      const currentKey = `${parent}${parent ? '.' : ''}${key}`
      if (_.includes(ignorePaths, currentKey)) {
        result[camelKey] = value
      } else {
        result[camelKey] =
          _.isPlainObject(value) || _.isArray(value)
            ? camelCaseKeys(value, ignorePaths, currentKey)
            : value
      }
    })
  } else {
    return obj
  }
}

/**
 * Recursively converts the keys of an object to snake_case, with options to ignore specified paths.
 *
 * @param obj - A plain object or array whose keys need to be converted to snake_case. If it is any other type, it is returned as is.
 * @param ignorePaths - An array of string paths that should be ignored during the conversion. Defaults to an empty array.
 * @param parent - A string used to track the current path of recursion. This helps in identifying the correct paths to ignore. Defaults to an empty string.
 * @returns The new object with snake_cased keys, retaining the original structure of arrays and nested objects.
 */
export const snakeCaseKeys = (obj: any, ignorePaths: string[] = [], parent: string = ''): any => {
  if (_.isArray(obj)) {
    if (_.includes(ignorePaths, parent)) {
      return obj
    } else {
      return obj.map((item: any) => snakeCaseKeys(item, ignorePaths, parent))
    }
  } else if (_.isPlainObject(obj)) {
    return _.transform(obj, (result: any, value, key: any) => {
      const snakeKey = _.snakeCase(key)
      const currentKey = `${parent}${parent ? '.' : ''}${key}`
      if (_.includes(ignorePaths, currentKey)) {
        result[snakeKey] = value
      } else {
        result[snakeKey] =
          _.isPlainObject(value) || _.isArray(value)
            ? snakeCaseKeys(value, ignorePaths, currentKey)
            : value
      }
    })
  } else {
    return obj
  }
}

import MainCard from '../components/MainCard'
import DataConnections from '../layout-components/general/data-connections'
import { LayoutViewProps } from './layout-view/types'

const LinkServices = ({ pageSettings }: LayoutViewProps) => {
  return (
    <MainCard pageSettings={pageSettings}>
      <DataConnections />
    </MainCard>
  )
}

export default LinkServices

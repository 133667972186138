export const Clock = () => (
  <g>
    <path
      d='M12 6C10.8133 6 9.65328 6.35189 8.66658 7.01118C7.67989 7.67047 6.91085 8.60754 6.45673 9.7039C6.0026 10.8003 5.88378 12.0067 6.11529 13.1705C6.3468 14.3344 6.91825 15.4035 7.75736 16.2426C8.59648 17.0818 9.66557 17.6532 10.8295 17.8847C11.9933 18.1162 13.1997 17.9974 14.2961 17.5433C15.3925 17.0891 16.3295 16.3201 16.9888 15.3334C17.6481 14.3467 18 13.1867 18 12C18 10.4087 17.3679 8.88258 16.2426 7.75736C15.1174 6.63214 13.5913 6 12 6ZM12 16.8C11.0507 16.8 10.1226 16.5185 9.33327 15.9911C8.54391 15.4636 7.92868 14.714 7.56538 13.8369C7.20208 12.9598 7.10702 11.9947 7.29223 11.0636C7.47744 10.1325 7.9346 9.27718 8.60589 8.60589C9.27718 7.93459 10.1325 7.47744 11.0636 7.29223C11.9947 7.10702 12.9598 7.20208 13.8369 7.56538C14.714 7.92868 15.4636 8.54391 15.9911 9.33326C16.5185 10.1226 16.8 11.0506 16.8 12C16.7984 13.2726 16.2922 14.4925 15.3924 15.3924C14.4925 16.2922 13.2726 16.7984 12 16.8Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.3672 14.8567C14.1901 15.0478 13.9029 15.0478 13.7258 14.8567L11.5 12.4553L11.5 8.48925C11.5 8.21904 11.703 8 11.9535 8C12.2039 8 12.407 8.21904 12.407 8.48925L12.407 12.05L14.3672 14.1648C14.5443 14.3559 14.5443 14.6656 14.3672 14.8567Z'
      fill='currentColor'
    />
  </g>
)

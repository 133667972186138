import { cn } from '@utils/style-utils'

import { Label } from '@components/core/label'
import {
  SelectContent,
  Select as SelectCore,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@components/core/select'
import { Text } from '@components/core/text'

export function Select({
  id,
  options,
  value,
  onChange,
  label,
  fullWidth = true,
  placeholder = 'Select',
  size = 'default',
  selectItemClasses = '',
  selectOptionLabelSuffix,
  className = ''
}: {
  options: { value: string; label: string; description?: string }[]
  value: string
  onChange: (value: string) => void
  id?: string
  label?: string
  fullWidth?: boolean
  placeholder?: string
  size?: 'small' | 'default'
  selectOptionLabelSuffix?: string
  selectItemClasses?: string
  className?: string
}) {
  return (
    <>
      {label && (
        <Label className='text-submenu font-normal' htmlFor={id}>
          {label}
        </Label>
      )}
      <SelectCore onValueChange={onChange} value={value}>
        <SelectTrigger id={id} size={size} className={cn(fullWidth && 'w-full', className)}>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          {options.map((option) => (
            <SelectItem key={option.value} value={option.value} className={cn(selectItemClasses)}>
              <div>
                {option.label} <span className='font-normal'>{selectOptionLabelSuffix}</span>
                {option.description && (
                  <Text variant='details' className='text-primary-darker'>
                    {option.description}
                  </Text>
                )}
              </div>
            </SelectItem>
          ))}
        </SelectContent>
      </SelectCore>
    </>
  )
}

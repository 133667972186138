import { Grid } from '@mui/material'

import { CheckboxWithTitle } from '@components/core/checkbox-with-title'
import { Separator } from '@components/core/separator'
import { Text } from '@components/core/text'
import { Select } from '@components/form/select'

import { useDispatch } from '@store/index'
import { useSelector } from '@store/index'
import { selectSettings, updateSettingsData } from '@store/slices/component/chart-config'

import { StackingType } from '../types/chart-builder-types'

export function SeriesArrangement() {
  const dispatch = useDispatch()
  const settings = useSelector(selectSettings)

  return (
    <>
      <Grid item xs={12}>
        <Text variant='h5'>Series Arrangement</Text>
        <Text variant='details'>
          The following arrangement config only applies to bar/column series
        </Text>
        <Separator />
      </Grid>
      <Grid item xs={6}>
        <Select
          id='series-stacking'
          label='Stacking'
          value={_.get(settings, 'seriesStacking', StackingType.NONE)}
          onChange={(value) => {
            dispatch(updateSettingsData({ seriesStacking: value as StackingType }))
          }}
          options={[
            { value: StackingType.NONE, label: 'None' },
            { value: StackingType.STACKED, label: 'Stacked' },
            { value: StackingType.LAYERED, label: 'Layered' }
          ]}
        />
      </Grid>
      <Grid item xs={6}>
        <div className='flex size-full'>
          <div className='flex flex-1 flex-col justify-center pt-5'>
            <CheckboxWithTitle
              title='Reverse Stacking'
              checked={_.get(settings, 'reverseStackingOrder', false)}
              onCheckedChange={(reverseStackingOrder) =>
                dispatch(updateSettingsData({ reverseStackingOrder }))
              }
            />
          </div>
        </div>
      </Grid>
    </>
  )
}

import React, { useState } from 'react'

import { getAllowedRange } from '@utils/date-filter-utils'
import { cn } from '@utils/style-utils'

import Button from '@components/core/button'
import { IconButton } from '@components/core/icon-button'
import { Popover, PopoverContent, PopoverTrigger } from '@components/core/popover'
import { Text } from '@components/core/text'
import { ChevronLeft, ChevronRight, Icon } from '@components/icons'

import { Frequency, Quarter, YearQuarterInfo } from 'types/filter'
import { Month, Quarters, getQuarterHeading } from 'utils/date-utils'

import GridMenuRenderHelper from '../grid-render-helper'
import YearSelectView from '../year-select-view'
import { maxInfo, minInfo, navigationClamp, outOfBounds } from './utils'

interface Props {
  fiscalYearStart?: Month
  value?: YearQuarterInfo
  minAllowed?: YearQuarterInfo
  maxAllowed?: YearQuarterInfo
  onChange: (value: YearQuarterInfo) => void
  render?: (value: YearQuarterInfo) => React.ReactNode
}

const QuarterPicker = (props: Props) => {
  const { fiscalYearStart, value, onChange, render } = props

  const [view, setView] = useState<'year' | 'quarter'>('quarter')
  const [popoverOpen, setPopoverOpen] = useState(false)

  const allowedRange = getAllowedRange(fiscalYearStart, Frequency.Quarterly)
  const minAllowed = maxInfo(props.minAllowed, allowedRange.minAllowed)
  const maxAllowed = minInfo(props.maxAllowed, allowedRange.maxAllowed)

  const prevVal = !value
    ? null
    : navigationClamp(
        { year: value.year - 1, quarter: value.quarter },
        'year',
        minAllowed,
        maxAllowed
      )
  const nextVal = !value
    ? null
    : navigationClamp(
        { year: value.year + 1, quarter: value.quarter },
        'year',
        minAllowed,
        maxAllowed
      )

  const toggleView = () => setView(view === 'quarter' ? 'year' : 'quarter')

  const shouldDisable = (val: YearQuarterInfo) => outOfBounds(val, minAllowed, maxAllowed)

  const yearSelectVal = (year: number) =>
    navigationClamp({ year, quarter: value!.quarter }, 'year', minAllowed, maxAllowed)
  const quarterSelectVal = (quarter: Quarter) =>
    navigationClamp({ year: value!.year, quarter }, 'quarter', minAllowed, maxAllowed)

  return (
    <div>
      <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
        <PopoverTrigger asChild>
          <Button variant='outline'>
            {value
              ? render
                ? render(value)
                : getQuarterHeading(value.year, value.quarter, fiscalYearStart)
              : ''}
          </Button>
        </PopoverTrigger>

        <PopoverContent className='flex min-w-48 flex-col'>
          <div className='mb-4 flex flex-1 items-center justify-between'>
            <IconButton
              disabled={!prevVal || shouldDisable(prevVal)}
              onClick={() => onChange(prevVal!)}
              variant='outline'
            >
              <Icon icon={<ChevronLeft />} />
            </IconButton>
            <div className='flex' onClick={toggleView}>
              <Text variant='button' className='cursor-pointer font-medium'>
                {fiscalYearStart != null ? <span>FY</span> : void 0}
                <span>{value?.year}</span>
              </Text>
            </div>

            <IconButton
              disabled={!nextVal || shouldDisable(nextVal)}
              onClick={() => onChange(nextVal!)}
            >
              <Icon icon={<ChevronRight />} />
            </IconButton>
          </div>

          {view === 'quarter' ? (
            <GridMenuRenderHelper
              selectedItem={value?.quarter}
              disabled={(quarter) => !value || shouldDisable(quarterSelectVal(quarter))}
              keySelector={(value) => value}
              data={Quarters}
              numColumns={2}
              className='overflow-hidden rounded-md border border-solid border-grey-light p-0'
              cellClassNames={cn(
                'h-16 hover:bg-primary hover:text-white rounded-none border-[0.5px]'
              )}
              onChange={(quarter) => {
                onChange(quarterSelectVal(quarter))
                setPopoverOpen(false)
              }}
            />
          ) : (
            <YearSelectView
              fiscalYearStart={fiscalYearStart}
              year={value?.year}
              disabled={(year) => !value || shouldDisable(yearSelectVal(year))}
              onChange={(year) => {
                onChange(yearSelectVal(year))
                setView('quarter')
              }}
            />
          )}
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default QuarterPicker

import { useMemo, useState } from 'react'

import { isNotEmpty } from '@utils/lodash'
import { cn } from '@utils/style-utils'

import Button from '@core/button'
import { Input } from '@core/input'
import { Label } from '@core/label'
import { Separator } from '@core/separator'
import { Tabs, TabsList, TabsTrigger } from '@core/tab'

import { FormIconPlacement, FormLayoutTypes } from '@components/form/types'

import { ChargerFormView, useChargerForm } from 'components/form/charger-form'

import { CheckConnectionButton } from './check-connection-button'
import { useUpdateConnectedSourceSystem } from './queries/update-connected-source-system'
import { DataConnectionMode, IConnectedSourceSystem } from './types'
import { airbyteSpecToChargerFormFieldConfig } from './utils/airbyte-spec-to-charger-form-field-config'
import { chargerToAirbytePayload } from './utils/charger-to-airbyte-payload'

const EDIT_APPLICATION_CONNECTION_FORM_ID = 'EDIT_APPLICATION_CONNECTION_FORM'

enum FormDisplay {
  VIEW = 'VIEW',
  EDIT = 'EDIT'
}

const isFormDisplayView = (formDisplay: FormDisplay) => _.isEqual(formDisplay, FormDisplay.VIEW)
const isFormDisplayEdit = (formDisplay: FormDisplay) => _.isEqual(formDisplay, FormDisplay.EDIT)

export const EditConfiguration = ({
  connectedSourceSystem,
  className,
  onSuccess,
  onCancel,
  businessId,
  mode
}: {
  connectedSourceSystem: IConnectedSourceSystem
  className?: string
  onSuccess?: () => void
  onCancel?: () => void
  businessId?: number
  mode: DataConnectionMode
}) => {
  const [formDisplay, setFormDisplay] = useState(FormDisplay.VIEW)
  const [shouldInvalidateQuery, setShouldInvalidateQuery] = useState(true)

  const fieldsConfig = useMemo(
    () =>
      airbyteSpecToChargerFormFieldConfig(
        _.get(connectedSourceSystem, 'connectorSpec.connectionSpecification'),
        _.assign(
          { connectionName: connectedSourceSystem.name },
          _.get(connectedSourceSystem, 'connector.connectorConfig')
        ),
        isFormDisplayView(formDisplay)
      ),
    [connectedSourceSystem, formDisplay]
  )

  const handleSave = (payload: any) => {
    const connectorConfig = _.assign(
      {},
      _.get(connectedSourceSystem, 'connector.connectorConfig'),
      chargerToAirbytePayload(payload, fieldsConfig)
    )
    updateConnection({
      connectionName: connectionName,
      connectionId: connectedSourceSystem.id,
      connector: _.assign({}, connectedSourceSystem.connector, { connectorConfig }),
      shouldInvalidate: shouldInvalidateQuery
    })
  }

  const formik = useChargerForm({
    fieldsConfig,
    onSubmit: (values) => handleSave(values)
  })

  const { isPending, mutate: updateConnection } = useUpdateConnectedSourceSystem({
    onSuccess: () => onSuccess?.(),
    businessId
  })

  const testConnectionPreCheck = async () => {
    setShouldInvalidateQuery(false)

    if (formik && formik.dirty && formik.isValid) {
      await formik.submitForm()

      setShouldInvalidateQuery(true)
    }
  }

  const [connectionName, setConnectionName] = useState(connectedSourceSystem.name)

  const isFieldDisabled = isFormDisplayView(formDisplay)

  return (
    <div className={cn('mt-2 flex flex-col', className)}>
      <Tabs
        id='configFormDisplayType'
        value={formDisplay}
        onValueChange={(value) => setFormDisplay(value as FormDisplay)}
      >
        <div className='flex items-center justify-start gap-4 pb-4'>
          <TabsList className='grid w-48 grid-cols-2 gap-2'>
            <TabsTrigger value={FormDisplay.VIEW}>View</TabsTrigger>
            <TabsTrigger value={FormDisplay.EDIT}>Edit</TabsTrigger>
          </TabsList>
          {isFormDisplayEdit(formDisplay) && (
            <div className='flex items-center gap-2'>
              <Button variant='outline' onClick={onCancel} className='h-7 w-[5.5rem]'>
                Cancel
              </Button>
              <Button
                type='submit'
                form={EDIT_APPLICATION_CONNECTION_FORM_ID}
                disabled={isPending}
                className='h-7 w-[5.5rem]'
              >
                {isPending ? 'Saving...' : 'Save'}
              </Button>
            </div>
          )}
        </div>
      </Tabs>

      <div className='justify-left flex w-full items-start'>
        <div className='w-150 flex-none space-y-4'>
          <div className='w-96'>
            <Label htmlFor='connection-name' className='text-details font-normal text-grey-dark'>
              Connection Name
            </Label>
            <Input
              className='w-full'
              id='connection-name'
              placeholder='Connection Name'
              value={connectionName}
              onChange={(e) => setConnectionName(e.target.value)}
              allowMultiline={true}
              readOnly={isFieldDisabled}
              disabled={isFieldDisabled}
              trailingIconPlacement={FormIconPlacement.OUTSIDE}
              allowCopyToClipboard={true}
            />
          </div>

          <Separator className='mt-4 w-96' />

          <ChargerFormView
            layout={FormLayoutTypes.DEFAULT}
            formik={formik}
            formId={EDIT_APPLICATION_CONNECTION_FORM_ID}
            noSubmit
            fieldsConfig={fieldsConfig}
            onCancel={onCancel}
            trailingIconPlacement={FormIconPlacement.OUTSIDE}
            allowCopyToClipboard={true}
          />
        </div>

        <Separator orientation={'vertical'} className='m-4 mx-10 h-full' />

        {isNotEmpty(fieldsConfig) && (
          <div className='ml-4 mt-1 flex-1'>
            <CheckConnectionButton
              handlePreCheck={testConnectionPreCheck}
              businessId={businessId}
              cssId={connectedSourceSystem.id}
              key={connectedSourceSystem.id}
              isValid={formik.isValid}
              businessFriendlyName={connectedSourceSystem.businessFriendlyName}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export const ALL_COLUMNS_PREFIX = '$$ALL-COLUMNS$$'

export type SectionLayoutType = Record<string, string[]>

export enum Containers {
  ALL_COLUMNS = 'All Columns',
  ROW_GROUPS = 'Row Groups',
  VALUES = 'Values',
  COLUMN_LABELS = 'Column Labels'
}

export const isContainerAllColumns = (container?: string | null) =>
  _.isEqual(container, Containers.ALL_COLUMNS)
export const isContainerRowGroups = (container?: string | null) =>
  _.isEqual(container, Containers.ROW_GROUPS)
export const isContainerValues = (container?: string | null) =>
  _.isEqual(container, Containers.VALUES)
export const isContainerColumnLabels = (container?: string | null) =>
  _.isEqual(container, Containers.COLUMN_LABELS)
export const isWithinOrganizableContainers = (container?: string | null) =>
  isContainerRowGroups(container) ||
  isContainerValues(container) ||
  isContainerColumnLabels(container)

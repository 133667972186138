import { Divider, Grid, TextField, Typography } from '@mui/material'

import useDelayedUpdate from '@hooks/use-delayed-update'

import { useDispatch } from '@store/index'
import { updateYAxisData } from '@store/slices/component/chart-config'

import { IAxis } from '../types/chart-builder-types'

export default function AxisLimit({ selected }: { selected: IAxis }) {
  const { id, axis_limit_min = '', axis_limit_max = '' } = selected
  const dispatch = useDispatch()

  const [min, setMin] = useDelayedUpdate(axis_limit_min, (value) =>
    dispatch(updateYAxisData({ axis_limit_min: value as number, id }))
  )
  const [max, setMax] = useDelayedUpdate(axis_limit_max, (value) =>
    dispatch(updateYAxisData({ axis_limit_max: value as number, id }))
  )

  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h5'>Axis Limit</Typography>
        <Typography variant='details' gutterBottom>
          Add Strict Limit to the axis
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          value={min}
          onChange={(e) => setMin(e.target.value)}
          type='number'
          fullWidth
          variant='outlined'
          label='Min'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          value={max}
          onChange={(e) => setMax(e.target.value)}
          type='number'
          fullWidth
          variant='outlined'
          label='Max'
        />
      </Grid>
    </>
  )
}

import { treeMapAttributes } from 'components/chart/modules/treemap'

import { IChartActions, IChartAdditionalMetadata } from '../../types/chart-builder-types'
import { IMetadata } from '../../types/component-types'
import ChartModules from './chart-modules'

export interface ITreemapModulesProps {
  metadata: IMetadata & IChartAdditionalMetadata
  actions?: IChartActions
}

export default class TreemapModules extends ChartModules {
  static create({ metadata, actions }: ITreemapModulesProps) {
    const chart = new TreemapModules(metadata, actions)
    return chart.create()
  }

  constructor(metadata: IMetadata & IChartAdditionalMetadata, actions?: IChartActions) {
    super(metadata, actions)

    this.addToModules(treeMapAttributes())
  }

  create() {
    return { modules: this.modules, options: {} }
  }
}

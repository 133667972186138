import { createContext, useCallback, useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { IResultRow } from 'pages/component-management/types/shared-types'
import { useDispatch } from 'store'
import {
  ActiveDrilldown,
  addDrilldown,
  getActiveDrilldownDimensions,
  getDrilldown,
  getFilteredDrilldownDimensions,
  getFocusedDrilldownDimension,
  removeDrilldown,
  setActiveDimensions,
  setFocusedDimensions
} from 'store/slices/component/drilldowns'

// Context for drilldown id
const DrilldownContext = createContext<{
  componentId: string
  contextMenuTriggerRef: React.RefObject<HTMLDivElement>
} | null>(null)

export function DrilldownProvider({
  children,
  componentId
}: {
  children: React.ReactNode
  componentId: string
}) {
  const dispatch = useDispatch()
  const contextMenuTriggerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    dispatch(addDrilldown({ componentId, activeDimensions: [] }))

    return () => {
      dispatch(removeDrilldown(componentId))
    }
  }, [dispatch, componentId])

  return (
    <DrilldownContext.Provider value={{ componentId, contextMenuTriggerRef }}>
      {children}
    </DrilldownContext.Provider>
  )
}

export const useDrilldownContext = () => {
  const context = useContext(DrilldownContext)

  if (!context) {
    throw new Error('useDrilldownContext must be used within DrilldownProvider')
  }

  return context
}

export const useOpenChartNodeContextMenu = () => {
  const { contextMenuTriggerRef, componentId } = useDrilldownContext()
  const dispatch = useDispatch()

  return useCallback(
    (key: string, data: IResultRow, mousePosition: { clientX: number; clientY: number }) => {
      dispatch(
        setFocusedDimensions({ componentId, focusedDimension: { key, filter: _.get(data, key) } })
      )
      if (!contextMenuTriggerRef.current) return

      contextMenuTriggerRef.current.dispatchEvent(
        new PointerEvent('contextmenu', {
          bubbles: true,
          clientX: mousePosition.clientX,
          clientY: mousePosition.clientY
        })
      )
    },
    [componentId, contextMenuTriggerRef, dispatch]
  )
}

export const useActiveDrilldownDimensions = () => {
  const { componentId } = useDrilldownContext()

  return useSelector(getActiveDrilldownDimensions(componentId))
}

export const useFilteredDrilldownDimensions = (availableDimensions: string[]) => {
  const { componentId } = useDrilldownContext()

  return useSelector(getFilteredDrilldownDimensions(componentId, availableDimensions))
}

const useDrilldown = () => {
  const { componentId } = useDrilldownContext()

  return useSelector(getDrilldown(componentId))
}

const useFocusedDrilldownDimension = () => {
  const { componentId } = useDrilldownContext()

  return useSelector(getFocusedDrilldownDimension(componentId))
}

export const useCollapseDrilldownDimension = () => {
  const { componentId } = useDrilldownContext()
  const dispatch = useDispatch()
  const drilldown = useDrilldown()
  const activeDrilldownDimensions = useActiveDrilldownDimensions()

  return useCallback(
    (dimensionName: string) => {
      if (!drilldown) return
      const selectedDrilldownIndex = _.findIndex(activeDrilldownDimensions, ['key', dimensionName])
      if (_.isEqual(selectedDrilldownIndex, -1)) return
      const selectedDrilldown = activeDrilldownDimensions[selectedDrilldownIndex]
      const newActiveDimensions = _.concat(
        _.slice(activeDrilldownDimensions, 0, selectedDrilldownIndex),
        [_.assign({}, selectedDrilldown, { filter: null })]
      )

      dispatch(setActiveDimensions({ componentId, activeDimensions: newActiveDimensions }))
    },
    [componentId, drilldown, dispatch, activeDrilldownDimensions]
  )
}

export const useExpandDrilldownDimension = () => {
  const { componentId } = useDrilldownContext()
  const dispatch = useDispatch()
  const drilldown = useDrilldown()
  const focusedDrilldownDimension = useFocusedDrilldownDimension()
  const activeDrilldownDimensions = useActiveDrilldownDimensions()

  return useCallback(
    (dimensionName: string) => {
      if (!drilldown || !focusedDrilldownDimension) return

      const focusedDimensionIndex = _.findIndex(activeDrilldownDimensions, [
        'key',
        focusedDrilldownDimension.key
      ])

      let newActiveDimensions: ActiveDrilldown[] = []

      if (_.isEqual(focusedDimensionIndex, -1)) {
        newActiveDimensions = _.concat(activeDrilldownDimensions, [focusedDrilldownDimension])
      } else {
        newActiveDimensions = _.concat(
          _.slice(activeDrilldownDimensions, 0, focusedDimensionIndex),
          [focusedDrilldownDimension],
          _.slice(activeDrilldownDimensions, focusedDimensionIndex + 1)
        )
      }
      newActiveDimensions.push({ key: dimensionName, filter: null })

      dispatch(setActiveDimensions({ componentId, activeDimensions: newActiveDimensions }))
    },
    [componentId, dispatch, drilldown, focusedDrilldownDimension, activeDrilldownDimensions]
  )
}

import { Grid } from '@mui/material'

import { Input } from '@components/core/input'
import { Label } from '@components/core/label'
import { Select } from '@components/form/select'

import useDelayedUpdate from '@hooks/use-delayed-update'

import { useDispatch } from '@store/index'
import { updateYAxisData } from '@store/slices/component/chart-config'

import { IAxis, LegendType } from '../types/chart-builder-types'

export function LegendSettings({ selected }: { selected: IAxis }) {
  const dispatch = useDispatch()

  const legendTypeValue = _.get(selected, 'legendType', LegendType.FromSeries)
  const [legendNameCustomValue, setLegendNameCustomValue] = useDelayedUpdate(
    _.get(selected, 'legendNameCustom', ''),
    (value) => {
      dispatch(updateYAxisData({ id: selected.id, legendNameCustom: value as string }))
    }
  )

  return (
    <>
      <Grid item xs={12}>
        <Select
          id='legend-type'
          label='Legend Type'
          value={legendTypeValue}
          onChange={(value) => {
            dispatch(updateYAxisData({ id: selected.id, legendType: value as LegendType }))
          }}
          options={[
            { value: LegendType.FromSeries, label: 'From Series' },
            { value: LegendType.FullPeriod, label: 'Full Period' },
            { value: LegendType.FuturePeriod, label: 'Future Period' },
            { value: LegendType.PastPeriod, label: 'Past Period' },
            { value: LegendType.Custom, label: 'Custom' }
          ]}
        />
      </Grid>
      {legendTypeValue === LegendType.Custom && (
        <Grid item xs={12}>
          <Label htmlFor='custom-legend-name'>Custom Legend Name</Label>
          <Input
            id='custom-legend-name'
            value={legendNameCustomValue}
            onChange={(e) => setLegendNameCustomValue(e.target.value)}
          />
        </Grid>
      )}
    </>
  )
}

import { useQuery } from '@tanstack/react-query'

import { GeneralLedgerAccount } from 'components/financial/types'
import useAuth from 'hooks/useAuth'
import { QUERY_STALE_TIME_DEFAULT } from 'queries/constants'
import axiosServices from 'utils/axios'

const fetchUnmapped = async (
  businessId: number | undefined,
  hierarchyId: number
): Promise<GeneralLedgerAccount[]> => {
  const response = await axiosServices.get(
    `/businesses/${businessId}/general_ledger_account_hierarchies/${hierarchyId}/unmapped_gl_accounts`
  )
  return response.data
}

export const useFetchUnmapped = (hierarchyId: number) => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-unmapped', user?.business_id, hierarchyId],
    queryFn: () => fetchUnmapped(user?.business_id, hierarchyId),
    enabled: !!hierarchyId,
    staleTime: QUERY_STALE_TIME_DEFAULT
  })
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import { dispatch } from 'store'
import { openSnackbar } from 'store/reducers/snackbar'
import axiosServices from 'utils/axios'

export const useRemoveMappings = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      hierarchyId,
      nodeIds,
      selectedNodeId,
      markAsNonPosting = false
    }: {
      hierarchyId: number
      nodeIds: Array<number>
      selectedNodeId?: number
      markAsNonPosting: boolean
    }) => {
      return axiosServices.post(
        `/businesses/${user?.business_id}/general_ledger_account_hierarchy_nodes/remove_mappings`,
        {
          general_ledger_account_hierarchy_node: {
            hierarchy_id: hierarchyId,
            remove_mapping_node_ids: nodeIds
          },
          mark_as_non_posting: markAsNonPosting
        }
      )
    },
    onSuccess: (data, variables, context) => {
      variables.selectedNodeId &&
        queryClient.invalidateQueries({
          queryKey: ['fetch-gl-account-nodes', user?.business_id, variables.selectedNodeId]
        })
      queryClient.invalidateQueries({
        queryKey: ['fetch-unmapped', user?.business_id, variables.hierarchyId]
      })
      queryClient.invalidateQueries({ queryKey: ['fetch-auto-mapped', user?.business_id] })
      queryClient.invalidateQueries({
        queryKey: ['fetch-gl-accounts-in-hierarchy', user?.business_id, variables.hierarchyId]
      })
      queryClient.invalidateQueries({
        queryKey: ['fetch-all-hierarchies-with-nodes', user?.business_id]
      })
    },
    onError: (error, variables) => {
      dispatch(
        openSnackbar({
          open: true,
          message: `Error Removing GL Accounts Mapping. ${error?.message}`,
          variant: 'alert',
          alert: { color: 'error' },
          close: false
        })
      )
    }
  })
}

import { enumToOptions } from '@utils/obj-utils'

import { Input } from '@components/core/input'
import { Select } from '@components/form/select'

import useDelayedUpdate from '@hooks/use-delayed-update'

import { useDispatch } from '@store/index'
import { updateYAxisData } from '@store/slices/component/chart-config'

import { IAxis, ValueFormattingType } from '../types/chart-builder-types'

export default function NumberFormatting({ item: data }: { item: IAxis }) {
  const dispatch = useDispatch()

  const { id, custom_value_short, custom_value_long, value_formatting_type } = data
  const valueFormattingOptions = enumToOptions(ValueFormattingType)

  const [customShort, setCustomShort] = useDelayedUpdate(custom_value_short, (value) =>
    dispatch(updateYAxisData({ custom_value_short: value, id }))
  )
  const [customLong, setCustomLong] = useDelayedUpdate(custom_value_long, (value) =>
    dispatch(updateYAxisData({ custom_value_long: value, id }))
  )

  return (
    <div>
      <Select
        options={valueFormattingOptions}
        value={value_formatting_type || ''}
        onChange={(value) => {
          dispatch(updateYAxisData({ id, value_formatting_type: value as ValueFormattingType }))
        }}
      />
      {value_formatting_type === ValueFormattingType.Custom && (
        <>
          <div className='mt-2 w-full'>
            <Input
              value={customShort}
              onChange={(e) => setCustomShort(e.target.value)}
              placeholder='Short'
            />
          </div>
          <div className='mt-2 w-full'>
            <Input
              value={customLong}
              onChange={(e) => setCustomLong(e.target.value)}
              placeholder='Long'
            />
          </div>
        </>
      )}
    </div>
  )
}

import { useParams } from 'react-router';

import { useFetchConnectedSourceSystem } from 'layout-components/general/data-connections/queries/fetch-connected-source-system';
import { DataConnectionMode } from 'layout-components/general/data-connections/types';
import { AlertsList } from 'layout-components/general/data-connections/alerts-list';

export function AlertsTab({ mode }: { mode: DataConnectionMode }) {
  const { id } = useParams<{ id: string }>();
  const { isPending, data: connectedSourceSystem } = useFetchConnectedSourceSystem({ id });

  if (isPending) return <div>Loading...</div>;

  return <AlertsList isPending={isPending} connectedSourceSystem={connectedSourceSystem} />;
}

import { IAssociations } from '../types/query-builder-types'

export default function cleanupEmptyFieldsAndAssociations(associations: IAssociations) {
  if (_.isEmpty(associations)) return

  for (const associationName of Object.keys(associations)) {
    const association = associations[associationName]
    if (association['associations']) {
      cleanupEmptyFieldsAndAssociations(association['associations'])
    }

    if (association['fields']) {
      association['fields'] = _.filter(association['fields'], (field) => !!field['configs']?.length)
    }

    const noFields = association['fields'] ? !association['fields'].length : true
    const noAssociations = association['associations']
      ? !Object.keys(association['associations']).length
      : true

    if (noFields && noAssociations) {
      delete associations[associationName]
    }
  }
}

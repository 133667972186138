'use client'

import * as React from 'react'

import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'
import { cn } from '@utils/style-utils'

type Variant = keyof typeof variantClasses
type Size = keyof typeof sizeClasses

const variantClasses = {
  primary: 'bg-white text-primary-darker',
  secondary: 'bg-secondary'
}

const sizeClasses = {
  default: 'h-6 px-[0.38rem]',
  button: 'py-1.5 px-2.5'
}

const selectedClasses = {
  selected:
    'bg-primary text-white border border-solid border-primary border-r-0 border-b-primary active:border-primary',
  notSelected: [
    'border-grey-lighter',
    'hover:border-grey-lighter',
    'hover:border-l-grey-lighter',
    'hover:bg-grey',
    'border-solid',
    'border border-r-0',
    'active:bg-primary-darker active:text-white active:border-primary-darker',
    'active:border-l-primary-darker'
  ].join(' ')
}

const ToggleGroupItemContext = React.createContext({ isFirst: false, isLast: false })

const getVariantSelectedClasses = (variant?: Variant, selected?: boolean) => {
  const baseStyles =
    selected || variant === undefined || !(variant in variantClasses) ? '' : variantClasses[variant]
  const selectedStyles = selected ? selectedClasses.selected : selectedClasses.notSelected
  return `${baseStyles} ${selectedStyles}`.trim()
}

const ToggleGroup = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root> & { children: React.ReactNode }
>(({ className, children, ...props }, ref) => {
  const childArray = React.Children.toArray(children)
  return (
    <ToggleGroupPrimitive.Root
      ref={ref}
      className={cn('flex items-center justify-center', className)}
      {...props}
    >
      {childArray.map((child, index) => (
        <ToggleGroupItemContext.Provider
          value={{
            isFirst: index === 0,
            isLast: index === childArray.length - 1
          }}
          key={index}
        >
          {child}
        </ToggleGroupItemContext.Provider>
      ))}
    </ToggleGroupPrimitive.Root>
  )
})

ToggleGroup.displayName = ToggleGroupPrimitive.Root.displayName

interface ToggleGroupItemProps
  extends React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item> {
  variant?: Variant // Optional variant prop
  selected?: boolean
  size?: Size
}

const ToggleGroupItem = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Item>,
  ToggleGroupItemProps
>(({ className, variant = 'primary', size = 'default', selected, children, ...props }, ref) => {
  const { isFirst, isLast } = React.useContext(ToggleGroupItemContext)
  const variantSelectedClasses = getVariantSelectedClasses(variant, selected)

  const positionClasses = cn([isFirst && 'rounded-l border-l', isLast && 'rounded-r border-r'])

  return (
    <ToggleGroupPrimitive.Item
      ref={ref}
      className={cn(
        variantSelectedClasses,
        'twp cursor-pointer text-button font-medium',
        positionClasses,
        sizeClasses[size],
        className
      )}
      {...props}
    >
      {children}
    </ToggleGroupPrimitive.Item>
  )
})

ToggleGroupItem.displayName = 'ToggleGroupItem'

export { ToggleGroup, ToggleGroupItem }

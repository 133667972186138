import { BasicSelectorOption } from '../components/selection/basic-selector'
import { MenuItemType, NavItemType } from '../types/menu'

export type ValueOf<T> = T[keyof T]

export type KeyedStringArrays<T> = {
  [key: string]: T[]
}

/**
 * Counts the number of items in selectedItems that are not present in selection.
 * @param selectedItems An object representing selected items with string keys mapping to arrays of values.
 * @param selection
 * @returns The count of items in selectedItems that are not present in selection.
 *
 * Example:
 * const selectedFilters = {
 *   department_name_ids: ["69", "70", "71", "73", "72"],
 *   vertical_name_ids: [],
 *   subsidiary_name_ids: [],
 *   project_name_ids: [],
 *   business_location_name_ids: [],
 *   general_ledger_classification_name_ids: []
 * };
 *
 * const selection = {
 *   department_name_ids: ["69", "70", "71"],
 *   vertical_name_ids: [],
 *   subsidiary_name_ids: [],
 *   project_name_ids: [],
 *   business_location_name_ids: [],
 *   general_ledger_classification_name_ids: []
 * };
 *
 * // Call countItemsNotInSelection with string type for item values
 * const count = countItemsNotInSelection<string>(selectedFilters, selection);
 * console.log('Count of items not in dimensions:', count); // Output: 2
 */
export function countItemsNotInSelection<T>(
  selectedItems: KeyedStringArrays<T>,
  selection: KeyedStringArrays<T>
): number {
  return _.reduce(
    selectedItems,
    (count, itemValues, itemKey) => {
      const selectedValues = selection[itemKey] || []
      const itemsNotInSelection = _.difference(itemValues, selectedValues)
      return count + itemsNotInSelection.length
    },
    0
  )
}

export function countTotalSelectedItems<T>(selectedItems: KeyedStringArrays<T>): number {
  return _.sumBy(_.values(selectedItems), (arr) => arr.length)
}

/**
 * Calculates the sum of lengths of arrays contained in the values of an object.
 * Each property value of the object is expected to be an array.
 * @param obj The object containing arrays as property values.
 * @returns The total length of all arrays contained in the object.
 *
 * @example
 * // Usage example with an object
 * const myObject = {
 *   prop1: ['value1', 'value2'],
 *   prop2: ['value3', 'value4', 'value5']
 * };
 * const totalLength = sumOfValues(myObject);
 * console.log(totalLength); // Output: 5 (sum of lengths of all arrays in myObject)
 */
export function sumOfValues<T extends { [key: string]: any[] }>(obj: T): number {
  return _.sumBy(_.values(obj), (value: any) => value.length) || 0
}

// Input: obj = {a: {b: {c: 1}}, d: 2, e: {f: 3}}
// Output: ['a-b-c', 'd', 'e-f']
// Function to extract nested keys with separator
export const getNestedKeysWithSeparator = (
  obj: Record<string, any>,
  parentKey: string = '',
  sep: string = '-'
): string[] => {
  return _.flatMapDeep(obj, (value, key) => {
    // If the key is 'DEFAULT', use the parent key without separator
    const newKey = key === 'DEFAULT' ? parentKey : parentKey ? `${parentKey}${sep}${key}` : key
    if (_.isObject(value)) {
      return getNestedKeysWithSeparator(value, newKey, sep)
    } else {
      return newKey
    }
  })
}

export const mergeObjects = <T extends object, U extends object>(
  primaryObj: T,
  optionalObj?: U
) => {
  if (optionalObj) {
    return { ...primaryObj, ...optionalObj }
  }

  return primaryObj
}

// Sorts the search filters object based on the 'order' property of each filter.
// Example:
// Input:
// {
//   filter1: { order: 2 },
//   filter2: { order: 1 },
//   filter3: { order: 3 }
// }
// Output:
// {
//   filter2: { order: 1 },
//   filter1: { order: 2 },
//   filter3: { order: 3 }
// }
export const sortSearchFilters = (searchFilters: any) => {
  if (!searchFilters) {
    return {}
  }

  return _.chain(searchFilters)
    .toPairs()
    .sortBy(([, value]) => value.order || Number.MAX_SAFE_INTEGER)
    .fromPairs()
    .value()
}

export const calculateAverage = (data: any, keys: string[]) => {
  return keys.reduce((sum, curr) => sum + (data[curr] || 0), 0) / keys.length
}

export const sortDataByAverageOfLastTwoRecentData = (data: any, headers: string[]) => {
  const filteredHeaders = headers.filter((x) => x.startsWith('2') && x.includes('total_spend'))
  filteredHeaders.sort((a, b) => (a > b ? -1 : 0))

  if (!filteredHeaders.length) return data

  const dataCopy = [...data]
  dataCopy.sort((a: any, b: any) => {
    const aValue = calculateAverage(a, filteredHeaders.slice(0, 2))
    const bValue = calculateAverage(b, filteredHeaders.slice(0, 2))
    return bValue - aValue
  })

  return dataCopy
}

/**
 * Sorts an array of objects by a specified property using locale-aware string comparison.
 * @param arr The array of objects to sort.
 * @param property The property name to sort by.
 * @returns A new array of objects sorted by the specified property.
 *
 * Example:
 * const users = [
 *   { name: 'John', age: 30 },
 *   { name: 'Alice', age: 25 },
 *   { name: 'Bob', age: 35 }
 * ];
 *
 * // Sort users by name
 * const sortedUsersByName = sortArrayByProperty(users, 'name');
 * console.log('Sorted by name:', sortedUsersByName);
 * // Output: [
 * //   { name: 'Alice', age: 25 },
 * //   { name: 'Bob', age: 35 },
 * //   { name: 'John', age: 30 }
 * // ]
 *
 * // Sort users by age
 * const sortedUsersByAge = sortArrayByProperty(users, 'age');
 * console.log('Sorted by age:', sortedUsersByAge);
 * // Output: [
 * //   { name: 'Alice', age: 25 },
 * //   { name: 'John', age: 30 },
 * //   { name: 'Bob', age: 35 }
 * // ]
 */
export const sortArrayByProperty = (arr: any[] = [], property: string = '') => {
  return _.sortBy(arr, (obj) => _.get(obj, property, '').toLowerCase())
}

export const removeDuplicateColumnsByProperty = (columns: any = [], property: any = '') => {
  return _.uniqBy(columns, property)
}

export const getSelectedPropertyValues = (arr: any = [], propertyName: any = '') => {
  return _.map(_.filter(arr, { selected: true }), propertyName)
}

export const numberRound = (value: number) => {
  return (Math.round(value * 100) / 100).toFixed(2)
}

export const findAndUpdate = <T>(
  items: T[],
  predicate: (item: T) => boolean,
  updater: (matchItem: T) => T
) => items.map((item) => (predicate(item) ? updater(item) : item))

export const upsertItemInArray = <T>(items: T[], item: T, key: (item: T) => number | string) => {
  const prevIdx = items.findIndex((r) => key(r) === key(item))
  const newItems = [...items]
  if (prevIdx === -1) {
    newItems.push(item)
  } else {
    newItems[prevIdx] = item
  }
  return newItems
}

/**
 * EnumValueUnion takes an object type T representing an enum and returns the union of its values.
 * For example, if we have the following enum:
 *
 * enum ExampleEnum {
 *   A = 'apple',
 *   B = 'banana',
 *   C = 'cherry'
 * }
 *
 * Then EnumValueUnion<ExampleEnum> will be equivalent to 'apple' | 'banana' | 'cherry'.
 * Implementation: EnumValueUnion<typeof ExampleEnum>;
 * This is similar to doing : (typeof ExampleEnum)[keyof typeof ExampleEnum];
 */
export type EnumValueUnion<T extends Record<string, string>> = T[keyof T]

export interface EnumItemToOption {
  label: string
  value: string
}

/**
 * Converts an enum object to an array of options, excluding specified keys.
 * @param enumObject The enum object to convert to options.
 * @param exclusions An array of keys to exclude from the conversion (optional).
 * @returns An array of options with 'value' and 'label' properties.
 *
 * Example:
 * enumObject = {
 *   option1: 'Value 1',
 *   option2: 'Value 2',
 *   option3: 'Value 3'
 * }
 * exclusions = ['option2']
 *
 * Output:
 * [
 *   { value: 'Value 1', label: 'Option1' },
 *   { value: 'Value 3', label: 'Option3' }
 * ]
 */
export const enumToOptions = <T extends Record<string, string>>(
  enumObject: T,
  exclusions: string[] = []
): EnumItemToOption[] => {
  return _(enumObject)
    .omit(exclusions)
    .map((value, key) => ({
      value,
      label: key === '$' ? key : _.startCase(key)
    }))
    .value()
}

// Filter options from the second options based on match values from the first options
// Input options1: [{"value":"quarter","label":"Q"},{"value":"month","label":"M"},{"value":"week","label":"W"}]
// Input options2: [{"label":"YEAR","value":"year"},{"label":"QUARTER","value":"quarter"},{"label":"MONTH","value":"month"},{"label":"WEEK","value":"week"},{"label":"DAY","value":"day"}]
// Output: [{"label":"QUARTER","value":"quarter"},{"label":"MONTH","value":"month"},{"label":"WEEK","value":"week"}]
export const filterOptionsByMatchingValues = (
  options1: BasicSelectorOption[],
  options2: BasicSelectorOption[]
) => {
  return _.intersectionBy(options2, options1, 'value') // For larger data set
  // return _.filter(options2, (option) => _.map(options1, 'value').includes(option.value)); // For smaller data set
}

export const capitalizeValueAtPath = (obj: any, path: string) => {
  const value = _.get(obj, path)
  if (value) {
    return _.capitalize(value)
  }
  return value
}

export const arrayOfStringsToFilterOptions = (strings: string[]): BasicSelectorOption[] => {
  return _.map(strings, (str) => ({
    label: str,
    value: str
  }))
}

export const intersperse = <RowType, Seperator extends string>(
  array: RowType[],
  separator: Seperator
) => array.flatMap((x, i) => (i === 0 ? [x] : [separator, x]))

export const isLastOrUniqueNavItem = (
  menuItem: NavItemType,
  index: number,
  siblings: NavItemType[]
) => {
  if (!siblings) {
    return true
  }

  const isItem = menuItem.type === MenuItemType.item

  const nextSiblingType = _.get(siblings[index + 1], 'type')
  const isUniqueItem =
    isItem && index < siblings.length - 1 && !_.isEqual(nextSiblingType, MenuItemType.item)

  return index === siblings.length - 1 || isUniqueItem
}

/**
 * Checks if two arrays of objects are equal based on a specified key.
 * @param arr1 Array of objects
 * @param arr2 Array of objects
 * @param key1 key to compare in arr1
 * @param key2 key to compare in arr2 [optional]
 * @returns boolean
 *
 * Example:
 * const arr1 = [{ id: 1, name: 'John' }, { id: 2, name: 'Alice' }];
 * const arr2 = [{ id: 1, employee: 'John' }, { id: 2, employee: 'Alice' }];
 * const isEqual = isEqualArrayBy(arr1, arr2, 'name', 'employee');
 * console.log(isEqual); // Output: true
 *
 * Example 2:
 * const arr1 = [{ id: 1, name: 'John' }, { id: 2, name: 'Alice' }];
 * const arr2 = [{ id: 1, name: 'John' }, { id: 2, name: 'Alice' }];
 * const isEqual = isEqualArrayBy(arr1, arr2, 'name');
 * console.log(isEqual); // Output: true
 */
export function isEqualArrayBy<T, U>(arr1: T[], arr2: U[], key1: keyof T, key2?: keyof U) {
  if (!key2) {
    key2 = key1 as unknown as keyof U
  }

  return _.xor(_.map(arr1, key1) as string[], _.map(arr2, key2) as string[]).length === 0
}

/**
 * Returns a new array of objects with unique values based on a specified key.
 * @param arr Array of objects
 * @param keyField Field to use as key
 * @param valueField Field to use as value
 * @returns object with keyField as key and valueField as value
 *
 * Example:
 * const arr = [{ colId: 'revenue', aggFunc: 'sum' }, { colId: 'cost', aggFunc: 'avg' }];
 * const keyValues = pairKeyValues(arr, 'colId', 'aggFunc');
 * console.log(keyValues); // Output: { revenue: 'sum', cost: 'avg' }
 */
export function pairKeyValues<T>(arr: T[], keyField: keyof T, valueField: keyof T) {
  const pairs = _.map(arr, (item) => [item[keyField], item[valueField]])
  return _.fromPairs(pairs)
}

export const objectify = (obj: any) => JSON.parse(JSON.stringify(obj))

/**
 * Flattens an object into a single-level object with dot-separated keys.
 * @param obj any data structure
 * @param parentKey intermediate key
 * @returns flattened object
 */
export const flattenObject = (obj: any, parentKey = ''): Record<string, any> => {
  if (!obj) return {}
  if (_.isArray(obj))
    return _.reduce(
      obj,
      (acc, item, index) => _.assign({}, acc, flattenObject(item, `${parentKey}[${index}]`)),
      {}
    )
  if (_.isPlainObject(obj)) {
    return Object.keys(obj).reduce((acc, key) => {
      const newKey = parentKey ? `${parentKey}.${key}` : key
      return _.assign({}, acc, flattenObject(obj[key], newKey))
    }, {})
  }
  if (parentKey) return { [parentKey]: obj }
  return obj
}

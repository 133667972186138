import { CheckboxWithTitle } from '@components/core/checkbox-with-title'
import { Label } from '@components/core/label'
import { Text } from '@components/core/text'
import { Select } from '@components/form/select'

import { useDispatch, useSelector } from '@store/index'
import { selectTableSettings, updateTableSettings } from '@store/slices/component/table-config'

import { PivotRowTotalsType } from '../types/table-builder-types'

export function GrandTotals() {
  const dispatch = useDispatch()
  const settings = useSelector(selectTableSettings)

  return (
    <div className='flex flex-col gap-2'>
      <Text variant='h5'>Grand Totals</Text>
      <div className='grid grid-cols-2 gap-2'>
        <div className='col-span-1'>
          <Select
            label='Pivot Row Totals'
            value={settings.pivotRowTotals || PivotRowTotalsType.NONE}
            options={[
              { label: 'None', value: PivotRowTotalsType.NONE },
              { label: 'Before', value: PivotRowTotalsType.BEFORE },
              { label: 'After', value: PivotRowTotalsType.AFTER }
            ]}
            id='pivotRowTotals'
            onChange={(value) =>
              dispatch(updateTableSettings({ pivotRowTotals: value as PivotRowTotalsType }))
            }
          />
        </div>
        <div className='col-span-1'>
          <div className='flex size-full flex-col'>
            <Label>Group Row Totals</Label>
            <div className='flex flex-1 items-center'>
              <div className='grid grid-cols-2 gap-2'>
                <CheckboxWithTitle
                  title='Show'
                  checked={settings.showTotalFooter}
                  onCheckedChange={(checked) =>
                    dispatch(updateTableSettings({ showTotalFooter: checked }))
                  }
                />
                <CheckboxWithTitle
                  title='Pin to Bottom'
                  checked={settings.pinTotalFooter}
                  onCheckedChange={(checked) =>
                    dispatch(updateTableSettings({ pinTotalFooter: checked }))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

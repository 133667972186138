import Button from '@core/button'
import { Input } from '@core/input'
import { Label } from '@core/label'
import { Popover, PopoverContent, PopoverTrigger } from '@core/popover'
import { Text } from '@core/text'

import { useDispatch, useSelector } from '@store/index'
import { selectLimit, setLimit } from '@store/slices/component/query-config'

import { Formik } from 'formik'
import * as Yup from 'yup'

export default function RunQueryButton({ handleRunQuery }: { handleRunQuery: () => void }) {
  const dispatch = useDispatch()
  const limit = useSelector(selectLimit)

  return (
    <>
      <Button onClick={handleRunQuery}>Run Query</Button>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant='outline'>Limit ({limit})</Button>
        </PopoverTrigger>
        <PopoverContent>
          <div>
            <Formik
              initialValues={{
                totalRows: limit
              }}
              validationSchema={Yup.object().shape({
                totalRows: Yup.number()
                  .min(1, 'Must be at least 1 row')
                  .max(5_000, 'Cannot exceed 5,000 rows')
                  .required('Total rows is required')
              })}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(setLimit(_.toNumber(values.totalRows)))
                setSubmitting(false)
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <div className='p-4'>
                  <form onSubmit={handleSubmit}>
                    <Label htmlFor='total-rows-limit'>Total Rows *</Label>
                    <Input
                      id='total-rows-limit'
                      name='totalRows'
                      type='number'
                      value={values.totalRows}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.totalRows && Boolean(errors.totalRows) && (
                      <Text variant='tableValue' className='mt-1 text-error'>
                        {touched.totalRows && errors.totalRows}
                      </Text>
                    )}
                    <Button className='mt-2' type='submit' disabled={isSubmitting}>
                      Apply
                    </Button>
                  </form>
                </div>
              )}
            </Formik>
          </div>
        </PopoverContent>
      </Popover>
    </>
  )
}

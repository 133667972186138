import { getTooltipContainerId } from './get-container-id'

export function initTooltipOverlay({ selector }: { selector: string }) {
  const chartEl = document.getElementById(selector)

  // Initial offsets
  let originalCanvasTop: number
  let originalCanvasLeft: number
  let positionedTooltip = false

  function positionTooltip() {
    if (!chartEl) return

    // Select elements by class name
    const tooltipCanvas = chartEl.getElementsByClassName('am5-layer-35')[0] as HTMLElement
    const tooltipHtml = chartEl.getElementsByClassName('am5-html-container')[0] as HTMLElement

    if (!tooltipCanvas || !tooltipHtml) return

    // Append elements to body and capture initial offsets if they exist
    const tooltipsContainer = document.createElement('div')
    tooltipsContainer.id = getTooltipContainerId(selector)
    tooltipsContainer.style.position = 'relative'
    // mui modal z-index value is set to 1300. So, tooltipCanvas z-index value should be greater than 1300
    tooltipsContainer.style.zIndex = '2000'
    document.body.appendChild(tooltipsContainer)

    tooltipsContainer.appendChild(tooltipCanvas)
    tooltipsContainer.appendChild(tooltipHtml)

    originalCanvasTop = parseFloat(tooltipCanvas.style.top.replace('px', '')) || 0
    originalCanvasLeft = parseFloat(tooltipCanvas.style.left.replace('px', '')) || 0

    // positioning done
    positionedTooltip = true
  }

  function onMouseMove() {
    if (!chartEl) return
    // Select elements by class name
    if (!positionedTooltip) positionTooltip()

    const tooltipsContainer = document.getElementById(getTooltipContainerId(selector))

    if (!tooltipsContainer) return

    const tooltipCanvas = tooltipsContainer.getElementsByClassName('am5-layer-35')[0] as HTMLElement
    const tooltipHtml = tooltipsContainer.getElementsByClassName(
      'am5-html-container'
    )[0] as HTMLElement

    const rect = chartEl.getBoundingClientRect()
    const { x, y } = rect
    // Calculate new positions based on initial offsets and current mouse position
    const newTopCanvas = originalCanvasTop + y + 'px'
    const newLeftCanvas = originalCanvasLeft + x + 'px'

    tooltipCanvas.style.position = 'fixed'
    tooltipCanvas.style.top = newTopCanvas
    tooltipCanvas.style.left = newLeftCanvas
    tooltipCanvas.style.zIndex = '2001'

    tooltipHtml.style.position = 'fixed'
    tooltipHtml.style.top = y + 'px'
    tooltipHtml.style.left = x + 'px'
    tooltipHtml.style.zIndex = '2002'
  }

  // Add mousemove listener to the chart element
  chartEl?.addEventListener('mousemove', onMouseMove)
}

export function disposeTooltipOverlay({ selector }: { selector: string }) {
  const tooltipsContainer = document.getElementById(getTooltipContainerId(selector))

  if (tooltipsContainer) {
    tooltipsContainer.remove()
  }
}

export function manuallyUpdateTooltipHtml({ selector, html }: { selector: string; html: string }) {
  const tooltipContainer = document.getElementById(getTooltipContainerId(selector)) as HTMLElement
  if (!tooltipContainer) return

  const tooltipHtmlDiv = tooltipContainer.querySelector('.am5-html-container > div') as HTMLElement
  if (!tooltipHtmlDiv) return

  tooltipHtmlDiv.innerHTML = html
}

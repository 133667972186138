import { useEffect, useMemo, useState } from 'react'

import { useDebounce } from '@uidotdev/usehooks'
import { humanizeFieldName } from '@utils/string-utils'
import { cn } from '@utils/style-utils'

import { DataPill, getIconForDataType } from '@components/component-management/DataPill/DataPill'
import Button from '@components/core/button'
import { Input } from '@components/core/input'
import { Separator } from '@components/core/separator'
import { Switch } from '@components/core/switch'
import { Text } from '@components/core/text'
import { Select } from '@components/form/select'
import { Icon } from '@components/icons'

import { useLatestRef } from '@hooks/useLatestRef'

import { useDispatch } from '@store/index'
import { updateParameterConfigOnHeaderNameChange } from '@store/slices/component/query-config'

import { findPreviousPeriodHeader } from '@pages/component-management/utils/header-utils'
import { validateFieldName } from '@pages/component-management/utils/validate-field-name'

import { useQueryResultContext } from '../../contexts/query-result-context'
import { IHeader } from '../../types/component-types'
import {
  AggregateType,
  CutoffDateOptions,
  CutoffDateType,
  ExpandLevelType,
  IFieldAttribute,
  IModelFieldDataType,
  NoneType,
  TruncateOptions,
  TruncateType,
  isDataTypeBoolean,
  isDataTypeDate,
  isDataTypeKpi,
  isDataTypeKpiHeading,
  isDataTypeNumeric,
  isDataTypeString
} from '../../types/query-builder-types'
import {
  aggregateOptionsForField,
  clearableFields,
  fieldSelected
} from '../../utils/field-config-utils'
import generateFieldAlias from '../../utils/generate-field-alias'
import { OverflowMenu } from '../overflow-menu'
import { ParameterizedFieldConfig } from './parameterized-field-config'
import { PreviousPeriodConnection } from './previous-period-connection'

export function ColumnAliasSection({
  fieldName,
  renamedFieldName,
  fieldType,
  config,
  addConfig,
  removeConfig,
  updateConfig
}: {
  fieldName: string
  renamedFieldName?: string
  fieldType: IModelFieldDataType
  config: Partial<IFieldAttribute>
  addConfig: () => void
  removeConfig: () => void
  updateConfig: (config: Partial<IFieldAttribute>) => void
}) {
  const dispatch = useDispatch()

  const {
    aggregate = NoneType.NONE,
    truncate = NoneType.NONE,
    is_hidden = false,
    is_date_hook = false,
    is_year_over_year = false,
    as_of_date = false,
    name_alias = '',
    expand_level = '0',
    show_total = false,
    drilldown_field = false,
    cutoffDate = NoneType.NONE,
    presence = false
  } = config

  const { headers, isFetching } = useQueryResultContext()
  const [cachedHeaders, setCachedHeaders] = useState<IHeader[]>([])
  useEffect(() => {
    if (isFetching) return

    setCachedHeaders(headers)
  }, [headers, isFetching])

  const title = humanizeFieldName(name_alias)
  const nameAliasRef = useLatestRef(name_alias)

  const [alias, setAlias] = useState(() => name_alias || fieldName)
  const [draftAlias, setDraftAlias] = useState(alias)
  const [isDraftAliasValid, setIsDraftAliasValid] = useState(true)
  useEffect(() => {
    const isValid = !validateFieldName(draftAlias)
    setIsDraftAliasValid(isValid)
    if (!isValid) return

    setAlias(draftAlias)
  }, [draftAlias])
  const debouncedName = useDebounce(alias, 500)

  useEffect(
    () => {
      if (nameAliasRef.current === debouncedName) return

      updateConfig({ name_alias: debouncedName })
      dispatch(
        updateParameterConfigOnHeaderNameChange({
          headerName: nameAliasRef.current,
          newHeaderName: debouncedName
        })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- updateConfig is not memoized
    [debouncedName]
  )

  const handleChange = <T extends keyof IFieldAttribute>(key: T, value: IFieldAttribute[T]) => {
    const changes = { [key]: value } as Partial<IFieldAttribute>
    if (_.includes(_.keys(clearableFields), key)) {
      _.forEach(clearableFields[key], (field: keyof IFieldAttribute) => {
        changes[field] = undefined
      })
    }
    if (_.includes(['aggregate', 'truncate', 'cutoffDate', 'presence'], key)) {
      changes.name_alias = generateFieldAlias({
        fieldName,
        previousAlias: alias,
        aggregate,
        truncate,
        cutoffDate,
        presence,
        [key]: value
      })
      setAlias(changes.name_alias)
      dispatch(
        updateParameterConfigOnHeaderNameChange({
          headerName: nameAliasRef.current,
          newHeaderName: changes.name_alias
        })
      )
    }

    updateConfig(changes)
  }

  const cutoffDateSelected = fieldSelected(cutoffDate)
  const truncateSelected = fieldSelected(truncate)

  const previousPeriodHeader = useMemo(
    () => findPreviousPeriodHeader(name_alias, cachedHeaders),
    [name_alias, cachedHeaders]
  )

  const currentHeader = useMemo(
    () => cachedHeaders.find((header) => header.name === name_alias),
    [cachedHeaders, name_alias]
  )

  return (
    <div>
      <div className='flex items-center justify-between'>
        <DataPill
          label={title}
          dataType={currentHeader?.data_type || fieldType}
          disabled={is_hidden}
        />
        <div className='flex gap-2'>
          {/* Aggregate Field */}
          {!presence &&
            !drilldown_field &&
            (isDataTypeNumeric(fieldType) ||
              isDataTypeBoolean(fieldType) ||
              isDataTypeString(fieldType)) && (
              <Select
                className='w-20'
                options={aggregateOptionsForField(fieldType)}
                value={aggregate}
                onChange={(value) => handleChange('aggregate', value as AggregateType)}
                fullWidth={false}
              />
            )}
          <OverflowMenu>
            <div className='flex items-center gap-1'>
              <Icon icon={getIconForDataType(fieldType)} />
              <Text variant='submenu' weight='semibold' className='text-blue-dark'>
                {renamedFieldName || humanizeFieldName(fieldName)}
              </Text>
            </div>
            <Separator className='my-2' />

            {/* Name */}
            <Input
              value={draftAlias}
              onChange={(e) => setDraftAlias(e.target.value)}
              failedValidation={!isDraftAliasValid}
              className={cn(!isDraftAliasValid && 'focus:outline-red')}
            />
            <Separator className='my-2' />

            {/* Hide */}
            {!isDataTypeKpi(fieldType) && !drilldown_field && (
              <>
                <div className='flex items-center justify-between'>
                  <Text variant='submenu' weight='semibold' className='text-grey-dark'>
                    Hide Field
                  </Text>
                  <Switch
                    checked={is_hidden}
                    onCheckedChange={(value) => handleChange('is_hidden', value)}
                  />
                </div>
                <Separator className='my-2' />
              </>
            )}

            {/* Drilldown Field */}
            {!presence && isDataTypeString(fieldType) && (
              <>
                <div className='flex items-center justify-between'>
                  <Text variant='submenu' weight='semibold' className='text-grey-dark'>
                    Drilldown Field
                  </Text>
                  <Switch
                    checked={drilldown_field}
                    onCheckedChange={(value) => handleChange('drilldown_field', value)}
                  />
                </div>
                <Separator className='my-2' />
              </>
            )}

            {/* Date Hook */}
            {!presence && isDataTypeDate(fieldType) && !cutoffDateSelected && (
              <>
                <div className='flex items-center justify-between'>
                  <Text variant='submenu' weight='semibold' className='text-grey-dark'>
                    Date Hook
                  </Text>
                  <Switch
                    checked={is_date_hook}
                    onCheckedChange={(value) => handleChange('is_date_hook', value)}
                  />
                </div>
                <Separator className='my-2' />
              </>
            )}

            {/* Truncate */}
            {!presence && isDataTypeDate(fieldType) && !is_date_hook && !cutoffDateSelected && (
              <>
                <div className='mb-2 flex items-center justify-between'>
                  <Text variant='submenu' weight='semibold' className='text-grey-dark'>
                    Truncate
                  </Text>
                  <Switch
                    checked={truncateSelected}
                    onCheckedChange={(value) =>
                      handleChange('truncate', value ? TruncateOptions.MONTH : NoneType.NONE)
                    }
                  />
                </div>
                {truncateSelected && (
                  <Select
                    options={[
                      {
                        value: TruncateOptions.DATE_FREQUENCY_HOOK,
                        label: 'Date Frequency Hook'
                      },
                      { value: TruncateOptions.DAY, label: 'Day' },
                      { value: TruncateOptions.MONTH, label: 'Month' },
                      { value: TruncateOptions.QUARTER, label: 'Quarter' },
                      { value: TruncateOptions.YEAR, label: 'Year' }
                    ]}
                    value={truncate}
                    onChange={(value) => handleChange('truncate', value as TruncateType)}
                    fullWidth
                  />
                )}
                <Separator className='my-2' />
              </>
            )}

            {/* Cutoff Date */}
            {!presence && isDataTypeDate(fieldType) && !is_date_hook && (
              <>
                <div className='mb-2 flex items-center justify-between'>
                  <Text variant='submenu' weight='semibold' className='text-grey-dark'>
                    Cutoff Date
                  </Text>
                  <Switch
                    checked={cutoffDateSelected}
                    onCheckedChange={(value) =>
                      handleChange(
                        'cutoffDate',
                        value ? CutoffDateOptions.CURRENT_DATE : NoneType.NONE
                      )
                    }
                  />
                </div>
                {cutoffDateSelected && (
                  <Select
                    options={[
                      { value: CutoffDateOptions.CURRENT_DATE, label: 'Current Date' },
                      { value: CutoffDateOptions.START_DATE, label: 'Start Date' },
                      { value: CutoffDateOptions.END_DATE, label: 'End Date' }
                    ]}
                    value={cutoffDate}
                    onChange={(value) => handleChange('cutoffDate', value as CutoffDateType)}
                    fullWidth
                  />
                )}
                <Separator className='my-2' />
              </>
            )}

            {/* Is Year Over Year */}
            {((!presence && isDataTypeDate(fieldType) && !!is_date_hook && !cutoffDateSelected) ||
              isDataTypeKpi(fieldType)) && (
              <>
                <div className='flex items-center justify-between'>
                  <Text variant='submenu' weight='semibold' className='text-grey-dark'>
                    Is Year Over Year
                  </Text>
                  <Switch
                    checked={is_year_over_year}
                    onCheckedChange={(value) => handleChange('is_year_over_year', value)}
                  />
                </div>
                <Separator className='my-2' />
              </>
            )}

            {/* As of Date */}
            {!presence && isDataTypeDate(fieldType) && is_date_hook && !cutoffDateSelected && (
              <>
                <div className='flex items-center justify-between'>
                  <Text variant='submenu' weight='semibold' className='text-grey-dark'>
                    As of Date
                  </Text>
                  <Switch
                    checked={as_of_date}
                    onCheckedChange={(value) => handleChange('as_of_date', value)}
                  />
                </div>
                <Separator className='my-2' />
              </>
            )}

            {/* Presence of Field */}
            {!isDataTypeKpi(fieldType) && (
              <>
                <div className='flex items-center justify-between'>
                  <Text variant='submenu' weight='semibold' className='text-grey-dark'>
                    Presence
                  </Text>
                  <Switch
                    checked={presence}
                    onCheckedChange={(value) => handleChange('presence', value)}
                  />
                </div>
                <Separator className='my-2' />
              </>
            )}

            {/* Show Total */}
            {isDataTypeKpi(fieldType) && (
              <>
                <div className='flex items-center justify-between'>
                  <Text variant='submenu' weight='semibold' className='text-grey-dark'>
                    Show Total
                  </Text>
                  <Switch
                    checked={show_total}
                    onCheckedChange={(value) => handleChange('show_total', value)}
                  />
                </div>
                <Separator className='my-2' />
              </>
            )}

            {/* Expand Level */}
            {isDataTypeKpiHeading(fieldType) && (
              <>
                <div className='flex items-center justify-between'>
                  <Text variant='submenu' weight='semibold' className='text-grey-dark'>
                    Expand Level
                  </Text>
                  <Select
                    size='small'
                    className='w-12'
                    options={[
                      { value: ExpandLevelType.ZERO, label: '0' },
                      { value: ExpandLevelType.ONE, label: '1' },
                      { value: ExpandLevelType.TWO, label: '2' }
                    ]}
                    value={expand_level}
                    onChange={(value) => handleChange('expand_level', value as ExpandLevelType)}
                    fullWidth={false}
                  />
                </div>
                <Separator className='my-2' />
              </>
            )}

            {/* Parameterize Field */}
            {!is_hidden && !drilldown_field && currentHeader && (
              <>
                <ParameterizedFieldConfig headerName={name_alias} />
                <Separator className='my-2' />
              </>
            )}

            <div className='grid grid-cols-2 gap-2'>
              {/* Duplicate Field */}
              <Button className='w-full' variant='outline' onClick={() => addConfig()}>
                Duplicate
              </Button>

              {/* Remove Field */}
              <Button className='w-full' variant='outline' onClick={() => removeConfig()}>
                Remove
              </Button>
            </div>
          </OverflowMenu>
        </div>
      </div>
      {previousPeriodHeader && (
        <PreviousPeriodConnection hidden={is_hidden}>
          <div className='ml-5 flex items-center justify-between'>
            <DataPill
              label={humanizeFieldName(previousPeriodHeader.name)}
              dataType={previousPeriodHeader.data_type}
              disabled={is_hidden}
            />
            {!is_hidden && (
              <OverflowMenu>
                <>
                  <div className='flex items-center gap-1'>
                    <Icon icon={getIconForDataType(fieldType)} />
                    <Text variant='submenu' weight='semibold' className='text-blue-dark'>
                      {renamedFieldName || humanizeFieldName(fieldName)}
                    </Text>
                  </div>
                  <Separator className='my-2' />

                  {/* Parameterize Field */}
                  {!is_hidden && (
                    <>
                      <ParameterizedFieldConfig headerName={previousPeriodHeader.name} />
                      <Separator className='mt-2' />
                    </>
                  )}
                </>
              </OverflowMenu>
            )}
          </div>
        </PreviousPeriodConnection>
      )}
    </div>
  )
}

import { useEffect, useRef } from 'react'
import { useParams } from 'react-router'

import ErrorBoundary from '@components/ErrorBoundary'
import { ComponentErrorWidget } from '@components/cards/component-widget'
import Button from '@components/core/button'
import { Text } from '@components/core/text'

import useChangeDetection from '@hooks/use-change-detection'

import { useSelector } from '@store/index'
import { selectTitle } from '@store/slices/component/basic-config'
import { selectTableConfig } from '@store/slices/component/table-config'

import { useQueryTransformedResultContext } from '../contexts/query-transformed-result-context'
import TableWidget from '../table-builder/table-widget'

const ErrorFallback = ({
  message,
  triggerReset,
  setErrored
}: {
  message: string
  triggerReset: () => void
  setErrored: () => void
}) => {
  setErrored()
  return (
    <div className='size-full'>
      <ComponentErrorWidget
        errorMessage={
          <div className='flex size-full flex-col items-center justify-center gap-2'>
            <Text variant='body'>{message}</Text>
            <Button variant='outline' onClick={() => triggerReset()}>
              Refresh
            </Button>
          </div>
        }
        title='Dynamic Table'
      />
    </div>
  )
}

export const TablePreview = () => {
  const [changeKey, triggerChange] = useChangeDetection()
  const erroredRef = useRef(false)
  const config = useSelector(selectTableConfig)

  useEffect(() => {
    if (!erroredRef.current) return

    erroredRef.current = false
    triggerChange()
  }, [config, triggerChange])

  return (
    <>
      <ErrorBoundary
        key={changeKey}
        fallback={(message) => (
          <ErrorFallback
            message={message}
            triggerReset={triggerChange}
            setErrored={() => (erroredRef.current = true)}
          />
        )}
      >
        <DynamicTable />
      </ErrorBoundary>
    </>
  )
}

const DynamicTable = () => {
  const { data, headers, metadata, isFetching } = useQueryTransformedResultContext()
  const componentId = useParams<{ id: string }>().id as string
  const title = useSelector(selectTitle)
  const config = useSelector(selectTableConfig)
  const [changeKey, triggerChange] = useChangeDetection()

  useEffect(() => triggerChange(), [config, triggerChange]) // refresh the grid when the config changes

  return (
    <TableWidget
      key={changeKey}
      componentId={+componentId}
      title={title}
      errorMessage=''
      loading={isFetching}
      isFetching={isFetching}
      data={{
        title,
        headers,
        config,
        metadata,
        results: data,
        drilldowns: []
      }}
    />
  )
}

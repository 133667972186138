import { useQuery } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'

import type { IComponentData } from '../types'

export const fetchComponent = async (id: number, business_id: number): Promise<IComponentData> => {
  const response = await axiosServices.get(`/businesses/${business_id}/components/${id}`)
  return response.data
}

export const useFetchComponent = (id?: number | string) => {
  const { user } = useAuth()
  const business_id = user?.business_id!
  const idNum = _.toNumber(id)

  return useQuery({
    queryKey: ['fetch-component', business_id, id],
    queryFn: () => fetchComponent(idNum, business_id),
    enabled: !!business_id && !!idNum
  })
}

import * as am5 from '@amcharts/amcharts5'

import { CHART_FONT_FAMILY, CHART_LEGEND_HEIGHT } from '../types/data-types'
import { IModule, IModuleAttributes, ModuleName } from '../types/module-types'
import { getLegendId, getLegendPlaceholderId } from '../utils/get-container-id'
import { assignRef, getSelfRef } from '../utils/module-utils'

const moduleName = ModuleName.externalLegend
const externalLegendModule: IModule<ModuleName.externalLegend> = {
  name: moduleName
}

externalLegendModule.init = (props) => {
  const { selector } = props

  const legendRoot = am5.Root.new(getLegendId(selector))

  const legendDiv = legendRoot.dom

  const placeholderLegendDiv = document.getElementById(getLegendPlaceholderId(selector))

  if (placeholderLegendDiv) {
    placeholderLegendDiv.style.height = `${CHART_LEGEND_HEIGHT}px`
  }

  const legend = legendRoot.container.children.push(
    am5.Legend.new(legendRoot, {
      layout: legendRoot.gridLayout,
      useDefaultMarker: true,
      x: am5.percent(100),
      centerX: am5.percent(100)
    })
  )

  legend.markers.template.setAll({
    width: 8,
    height: 8
  })

  legend.markerRectangles.template.setAll({
    cornerRadiusTL: 4,
    cornerRadiusTR: 4,
    cornerRadiusBL: 4,
    cornerRadiusBR: 4
  })

  legend.labels.template.setAll({
    fontSize: 11,
    ellipsis: '...',
    oversizedBehavior: 'truncate',
    fontFamily: CHART_FONT_FAMILY
  })

  legend.valueLabels.template.setAll({
    forceHidden: true
  })

  legend.events.on('boundschanged', function () {
    legendDiv.style.height = Math.max(legend.height(), CHART_LEGEND_HEIGHT) + 'px'
  })

  assignRef({ ...props, moduleName, item: legend })
  assignRef({ ...props, moduleName: ModuleName.externalLegendRoot, item: legendRoot })
}

externalLegendModule.dispose = (props) => {
  const legendRoot = getSelfRef({ ...props, moduleName: ModuleName.externalLegendRoot })
  legendRoot.dispose()
}

export const externalLegendAttributes: IModuleAttributes<ModuleName.legend> = (p) => ({
  module: externalLegendModule,
  ...p
})

export default externalLegendModule

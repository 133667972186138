import { useParams } from 'react-router'

import { JobHistory } from 'layout-components/general/data-connections/job-history'
import { useFetchConnectedSourceSystem } from 'layout-components/general/data-connections/queries/fetch-connected-source-system'
import { DataConnectionMode } from 'layout-components/general/data-connections/types'

export function DataMovementJobsTab({ mode }: { mode: DataConnectionMode }) {
  const { id } = useParams<{ id: string }>()
  const { isPending, data } = useFetchConnectedSourceSystem({ id })

  return <JobHistory isPending={isPending} connectedSourceSystem={data} />
}

import { Theme } from '@mui/material/styles'

export default function SvgIcon(theme: Theme) {
  return {
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeExtraLarge: {
          fontSize: '3.75rem'
        }
      }
    }
  }
}

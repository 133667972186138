import { useMutation, useQueryClient } from '@tanstack/react-query'

import { TreeItemIdentifier } from 'components/tree/types'
import useAuth from 'hooks/useAuth'
import { dispatch } from 'store'
import { openSnackbar } from 'store/reducers/snackbar'
import axiosServices from 'utils/axios'

import { MINIMAL_LAYOUT } from '../data/constants'

export const useCreatePage = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      parentPageId,
      title,
      type
    }: {
      parentPageId?: number
      title: string
      type?: TreeItemIdentifier
    }) => {
      return axiosServices.post(`/businesses/${user?.business_id}/pages`, {
        page: {
          title: title,
          layout_type: type,
          layout_structure: MINIMAL_LAYOUT
        },
        parent_node_id: parentPageId
      })
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['fetch-pages-tree', user?.business_id] })
    },
    onError: (error, variables) => {
      console.error('create page error', error)
      let errorMessage = 'Error Creating Page'
      if (error && error.message) {
        errorMessage = error.message
      }
      dispatch(
        openSnackbar({
          open: true,
          message: errorMessage,
          variant: 'alert',
          alert: { color: 'error' },
          close: false
        })
      )
    }
  })
}

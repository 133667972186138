import { useState } from 'react'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Collapse, IconButton, Typography } from '@mui/material'

const ExpandCollapse = ({ header, children }: { header: string; children: any }) => {
  const [isExpanded, setIsExpanded] = useState(true)

  const handleExpandToggle = () => setIsExpanded((prev) => !prev)

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #ddd',
          padding: '10px',
          cursor: 'pointer'
        }}
        onClick={handleExpandToggle}
      >
        <Typography variant='h5'>{header}</Typography>
        <IconButton>{isExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}</IconButton>
      </div>
      <Collapse in={isExpanded}>
        <div style={{ padding: '10px' }}>{children}</div>
      </Collapse>
    </div>
  )
}

export default ExpandCollapse

import { useParams } from 'react-router'

import { DataIntegrityJobsList } from 'layout-components/general/data-connections/data-integrity-jobs-list'
import { useFetchConnectedSourceSystem } from 'layout-components/general/data-connections/queries/fetch-connected-source-system'
import { DataConnectionMode } from 'layout-components/general/data-connections/types'

export function DataIntegrityJobsTab({ mode }: { mode: DataConnectionMode }) {
  const { id } = useParams<{ id: string }>()
  const { isPending, data: connectedSourceSystem } = useFetchConnectedSourceSystem({ id })

  if (isPending) return <div>Loading...</div>

  return <DataIntegrityJobsList connectedSourceSystem={connectedSourceSystem} />
}

import { UserProfile } from '../../types/auth'
import axiosServices from '../axios'

export const REFRESH_SESSION_URL = '/refresh_session'

export const refreshAccessToken = async () => {
  return await axiosServices.post(REFRESH_SESSION_URL, null, {})
}

export interface RefreshInfo {
  user: UserProfile
  accessToken: string
}

export const refreshSessionBase = async () => {
  const response = await refreshAccessToken()
  if (response.status !== 200) {
    throw new Error('Refresh token load failed')
  }

  const newAccessToken = response.headers['authorization']

  const { user } = response.data

  return {
    user,
    accessToken: newAccessToken
  } as RefreshInfo
}

import { useState } from 'react'

import { Collapse, Tooltip } from '@mui/material'

import { IconButton } from '@components/core/icon-button'
import { Text } from '@components/core/text'
import { Icon } from '@components/icons'
import { Cancel } from '@components/icons/cancel'
import { ChevronDown } from '@components/icons/chevron-down'
import { ChevronUp } from '@components/icons/chevron-up'

const ExpandCollapseClear = ({
  name,
  header,
  children,
  onClear
}: {
  name: string
  header: string
  children: any
  onClear?: (name: string) => void
}) => {
  const [isExpanded, setIsExpanded] = useState(true)

  const handleExpandToggle = () => setIsExpanded((prev) => !prev)

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: `1px solid #F2F2F2`,
          padding: '0.5rem 0',
          cursor: 'pointer'
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Tooltip title={header}>
          <Text variant='body' className='max-w-[18.75rem] truncate capitalize text-primary'>
            {header}
          </Text>
        </Tooltip>

        <div style={{ display: 'flex', gap: '5px' }}>
          {onClear && (
            <IconButton onClick={() => onClear(name)} className='p-0'>
              <Icon icon={<Cancel />} />
            </IconButton>
          )}
          <IconButton onClick={handleExpandToggle} className='p-0'>
            <Icon icon={isExpanded ? <ChevronUp /> : <ChevronDown />} />
          </IconButton>
        </div>
      </div>
      <Collapse in={isExpanded}>
        <div style={{ paddingTop: '0.5rem' }}>{children}</div>
      </Collapse>
    </div>
  )
}

export default ExpandCollapseClear

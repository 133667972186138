import { associationsPath } from '@store/slices/component/query-config'

import { IAssociationData, IDataTab, IModelDetail, IQueryData } from '../types/query-builder-types'

export function selectDataTabsFromModel(model: IModelDetail, queryData: IQueryData): IDataTab[] {
  const tabs: IDataTab[] = []

  selectDataTabsFromAssociations(model.associations, queryData, tabs)

  return tabs
}

function selectDataTabsFromAssociations(
  associations: IAssociationData[],
  queryData: IQueryData,
  tabs: IDataTab[],
  parent = ''
) {
  _.forEach(associations, (association) => {
    const currentPath = `${associationsPath(parent)}.${association.name}`
    if (_.has(queryData, currentPath)) {
      tabs.push({
        modelName: association.model,
        associationName: association.name
      })

      selectDataTabsFromAssociations(association.associations, queryData, tabs, currentPath)
    }
  })
}

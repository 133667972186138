import { useState } from 'react'

import { Box, Grid, Typography } from '@mui/material'

import { useSelector } from '@store/index'
import { selectXAxes } from '@store/slices/component/chart-config'

import { ItemSelection } from '../common/item-selection'
import WaterfallBucket from './waterfall-bucket'

export default function ChartXAxis() {
  const xAxes = useSelector(selectXAxes)
  const [selectedId, setSelectedId] = useState<null | string>(xAxes.length ? xAxes[0].id : null)

  const selected = xAxes.find((axis) => axis.id === selectedId) || null

  if (!selected) {
    return <Typography variant='body1'>No axis selected</Typography>
  }

  return (
    <Box>
      <ItemSelection
        options={xAxes}
        selected={selected}
        setSelected={setSelectedId}
        description='Select which dimension to edit'
      />

      <Grid container spacing={2}>
        <WaterfallBucket selected={selected} />
      </Grid>
    </Box>
  )
}

import { humanizeFieldName } from '@utils/string-utils'
import { cn } from '@utils/style-utils'

import { Button } from '@core/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@core/dialog'
import { IconButton } from '@core/icon-button'
import Tooltip from '@core/tooltip'

import { DataPanel } from '@components/component-management/DataPanel'
import { DataPill } from '@components/component-management/DataPill'
import { DataLabelVisibility, DataLabelVisibilityOff, Icon } from '@components/icons'

import { associationsPath } from '@store/slices/component/query-config'

import {
  IAssociation,
  IColumnData,
  IField,
  IQueryData
} from 'pages/component-management/types/query-builder-types'

interface DataSelectionPanelProps {
  selectedData?: IQueryData
  hideUnusedTables?: boolean
  setHideUnusedTables?: (hide: boolean) => void
  onClear?: () => void
  onSelect?: (column: IColumnData, parent: string) => void
  renderName?: (modelName: string, columnName?: string) => string | undefined // render prop for schema renaming
}

export function DataSelectionPanel(props: DataSelectionPanelProps) {
  const {
    selectedData,
    hideUnusedTables = false,
    setHideUnusedTables = _.noop,
    onClear = _.noop,
    onSelect = _.noop,
    renderName
  } = props

  const isEmpty = _.isEmpty(selectedData?.fields) && _.isEmpty(selectedData?.associations)

  const controls = (
    <>
      <Tooltip
        title={hideUnusedTables ? 'Show unused tables' : 'Hide unused tables'}
        side='bottom'
        delay={0}
      >
        <IconButton
          onClick={() => setHideUnusedTables(!hideUnusedTables)}
          variant={isEmpty ? 'disabled' : 'outline'}
          disabled={isEmpty}
        >
          <Icon icon={hideUnusedTables ? <DataLabelVisibility /> : <DataLabelVisibilityOff />} />
        </IconButton>
      </Tooltip>
      <ClearButton onClick={() => onClear()} disabled={isEmpty} />
    </>
  )

  return (
    <DataPanel controls={controls}>
      {isEmpty ? (
        <Empty />
      ) : (
        <div className='-mt-2'>
          <FieldAndAssociations
            data={selectedData}
            baseModelName={_.get(selectedData, 'model', '')}
            onSelect={onSelect}
            renderName={renderName}
          />
        </div>
      )}
    </DataPanel>
  )
}

function ClearButton({ onClick, disabled = false }: { onClick: () => void; disabled?: boolean }) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button type='button' variant={disabled ? 'disabled' : 'outline'} disabled={disabled}>
          Clear
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className='mb-2 text-description'>
            Are you sure you want to clear your selection?
          </DialogTitle>
          <DialogDescription className='text-details text-grey-details'>
            Clearing your selected fields will remove all work associated to this component;
            settings, filters, and component customizations.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className='border-t border-solid border-grey-lighter pt-2 sm:flex-row-reverse sm:justify-between sm:space-x-0'>
          <DialogClose asChild>
            <Button type='button' variant='danger' onClick={onClick}>
              Clear
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button type='button' variant='outline'>
              Cancel
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

function Empty() {
  return (
    <div className='flex items-center justify-center rounded border border-dashed border-grey px-6 py-12 text-center text-body italic text-grey-dark'>
      Select fields from the tables on the left to begin building out the data source for your
      component.
    </div>
  )
}

export function FieldAndAssociations({
  data = {},
  baseModelName = '',
  parent = '',
  onSelect = _.noop,
  renderName
}: {
  data: IAssociation | IQueryData | undefined
  baseModelName?: string
  parent?: string
  onSelect?: (column: IColumnData, parent: string) => void
  renderName?: (modelName: string, columnName?: string) => string | undefined
}) {
  if (_.isEmpty(data)) return null

  const { fields, associations } = data
  const associationNames = Object.keys(associations || {})

  const pathSplit = _.split(parent, '.')
  const isBaseModel = pathSplit.length <= 2
  const modelName = _.last(pathSplit) || baseModelName

  return (
    <>
      {!_.isEmpty(fields) && (
        <>
          <div
            className={cn(
              isBaseModel
                ? 'my-2 border-x-0 border-b border-t-0 border-solid border-grey-lighter py-1 text-inline-form-title'
                : 'mb-1 text-inline-form-title'
            )}
          >
            {renderName?.(modelName) || humanizeFieldName(modelName)}
          </div>
          <div className='flex flex-col gap-2'>
            {_.map(fields, (field) => (
              <Field
                key={field.name}
                field={field}
                onDelete={() => onSelect(field, parent)}
                displayName={modelName ? renderName?.(modelName, field.name) : undefined}
              />
            ))}
          </div>
        </>
      )}
      {associationNames.map((name) => {
        return (
          <div key={name} className={cn(parent && 'ml-2 mt-2')}>
            <FieldAndAssociations
              data={associations?.[name]}
              parent={`${associationsPath(parent)}.${name}`}
              onSelect={onSelect}
              renderName={renderName}
            />
          </div>
        )
      })}
    </>
  )
}

function Field({
  field,
  onDelete = _.noop,
  displayName
}: {
  field: IField
  onDelete?: () => void
  displayName?: string
}) {
  const label = displayName || humanizeFieldName(field.name)
  return (
    <div className='flex items-center justify-between'>
      <DataPill
        label={label}
        dataType={field.field_type}
        isForeignKey={!!field.association_name}
        isId={field.name === 'id'}
        isCustomField={!!field.custom}
        onDelete={onDelete}
      />
    </div>
  )
}

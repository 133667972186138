import { useState } from 'react'

import { AgGridReact } from 'ag-grid-react'

import { isCellValueVisible } from 'utils/ag_grid/is-cell-value-visible'

export const useFirstRowWithNumbersId = (
  gridRef: React.RefObject<AgGridReact<any>>,
  isEnabled: boolean
) => {
  const [firstRowWithNumbersId, setFirstRowWithNumbersId] = useState<string | null>(null)

  const computeFirstRowWithNumbersId = () => {
    if (!isEnabled) return

    const gridApi = gridRef.current?.api
    if (!gridApi) {
      setFirstRowWithNumbersId(null)
      return
    }

    const displayedRowCount = gridApi.getDisplayedRowCount()
    for (let i = 0; i < displayedRowCount; i++) {
      const rowNode = gridApi.getDisplayedRowAtIndex(i)
      const columns = gridApi.getColumns() || []

      for (const col of columns) {
        if (col.getColId().startsWith('data.')) {
          if (rowNode) {
            if (!isCellValueVisible(rowNode)) {
              continue // Skip this row
            }
            if (rowNode.id !== undefined) {
              setFirstRowWithNumbersId(rowNode.id)
            }
            return
          }
        }
      }
    }
    setFirstRowWithNumbersId(null)
  }

  return { firstRowWithNumbersId, computeFirstRowWithNumbersId }
}

import { CellClassParams, ColDef, ICellRendererParams } from 'ag-grid-community'

import { localTimezone } from '@utils/date-utils'
import { compareStringsCaseInsensitive, toCommaSeparated } from '@utils/string-utils'
import { cn } from '@utils/style-utils'

import { DateCellRenderer } from '@components/ag-grid/custom-cell-renderer/date-cell-renderer'
import { AG_RIGHT_ALIGNED_CELL } from '@components/ag-grid/types'
import { Switch } from '@components/core/switch'

import { format } from 'date-fns'

import { SyncStatusCellRenderer } from '../sync-status-cell-renderer'
import { LAST_SYNC_STATUS } from '../types'

export const cellClass = (params: CellClassParams) =>
  params.node.isSelected() ? 'text-primary' : 'text-primary-darker'

export const multiNumericColumnDef = (
  field: string,
  headerName: string,
  options: Partial<ColDef> = {}
): ColDef => ({
  colId: field,
  field,
  headerName,
  filter: 'agMultiColumnFilter',
  headerTooltip: headerName,
  ...options,
  cellClass: (params) =>
    `${cellClass(params)} ${options.type === 'rightAligned' ? AG_RIGHT_ALIGNED_CELL : ''}`
})

export const numericColumnDef = (
  field: string,
  headerName: string,
  options: Partial<ColDef> = {}
): ColDef => ({
  colId: field,
  field,
  headerName,
  headerTooltip: headerName,
  ...options,
  cellClass: (params) =>
    `${cellClass(params)} ${options.type === 'rightAligned' ? AG_RIGHT_ALIGNED_CELL : ''}`
})

export const numericCommaSeparatedColumnDef = (
  field: string,
  headerName: string,
  options: Partial<ColDef> = {}
): ColDef => ({
  colId: field,
  field,
  headerName,
  initialWidth: 100,
  valueFormatter: ({ value }) => toCommaSeparated(value),
  headerTooltip: headerName,
  ...options
})

export const multiTextColumnDef = (
  field: string,
  headerName: string,
  options: Partial<ColDef> = {}
): ColDef => ({
  colId: field,
  field,
  headerName,
  filter: 'agMultiColumnFilter',
  cellClass,
  comparator: compareStringsCaseInsensitive,
  headerTooltip: headerName,
  ...options
})

export const textSetColumnDef = (
  field: string,
  headerName: string,
  options: Partial<ColDef> = {}
): ColDef => ({
  colId: field,
  field,
  headerName,
  filter: 'agSetColumnFilter',
  cellClass,
  comparator: compareStringsCaseInsensitive,
  headerTooltip: headerName,
  ...options
})

export const textSearchColumnDef = (
  field: string,
  headerName: string,
  options: Partial<ColDef> = {}
): ColDef =>
  _.assign(
    textSetColumnDef(field, headerName),
    {
      filter: 'agTextColumnFilter'
      // filterParams: {
      //   filterOptions: ['contains'],
      //   maxNumConditions: 1,
      //   debounceMs: 200
      // }
    },
    options
  )

export const dateColumnDef = (
  field: string,
  headerName: string,
  options: Partial<ColDef> = {}
): ColDef => ({
  colId: field,
  field,
  headerName: `${headerName} (${localTimezone()})`,
  filter: 'agDateColumnFilter',
  cellRenderer: DateCellRenderer,
  filterParams: {
    comparator: (filterDate: any, cellValue: any) => {
      if (cellValue == null) return 0

      // eslint-disable-next-line no-date-parsing/no-date-parsing -- The provided date string is ISO String
      const cellDateStr = format(new Date(cellValue), 'yyyy-MM-dd')
      const filterDateStr = format(filterDate, 'yyyy-MM-dd')
      if (cellDateStr < filterDateStr) return -1
      else if (cellDateStr > filterDateStr) return 1
      else return 0
    }
  },
  ...options
})

export const lastSyncStatusColumnDef = (options: Partial<ColDef> = {}): ColDef => ({
  colId: 'lastSyncStatus',
  field: 'lastSyncStatus',
  headerName: 'Last Sync Status',
  cellRenderer: SyncStatusCellRenderer,
  getQuickFilterText: () => '',
  filter: true,
  filterParams: {
    values: [LAST_SYNC_STATUS.complete, LAST_SYNC_STATUS.failed, LAST_SYNC_STATUS.in_progress, null]
  },
  minWidth: 100,
  valueGetter: (params) => LAST_SYNC_STATUS[params.data.lastSyncStatus] || null,
  filterValueGetter: (params) => LAST_SYNC_STATUS[params.data.lastSyncStatus] || null,
  ...options
})

export const staticSwitchColumnDef = (
  field: string,
  headerName: string,
  checkedState: any,
  options: Partial<ColDef> = {}
): ColDef => ({
  colId: field,
  field,
  headerName,
  type: 'rightAligned',
  cellRenderer: (params: ICellRendererParams) => (
    <div className='flex size-full items-center justify-end'>
      <Switch
        size='small'
        disabled={true}
        checked={params.value === checkedState}
        className={cn(
          '!opacity-100',
          params.value === checkedState
            ? 'bg-blue-700 border-primary'
            : 'bg-gray-300 border-grey-light'
        )}
      />
    </div>
  ),
  getQuickFilterText: () => '',
  filter: true,
  floatingFilter: true,
  maxWidth: 150,
  minWidth: 150,
  ...options
})

import { useMemo } from 'react'

import { ICellRendererParams } from 'ag-grid-community'

import { Popover, PopoverContent, PopoverTrigger } from '@core/popover'

import { DEFAULT_EDITOR_MAX_HEIGHT, JSONEditor } from '@components/code-editors'

export const JSONCellRenderer = (
  params: ICellRendererParams & { showFullData: boolean; hideEmpty: boolean }
) => {
  const fullJSON = useMemo(() => {
    const data = params.showFullData ? params.data : params.value
    return JSON.stringify(data, null, 2)
  }, [params.showFullData, params.data, params.value])

  const isEmpty = fullJSON === '{}' || fullJSON === '[]'
  if (isEmpty && params.hideEmpty) {
    return null
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className='cursor-pointer truncate'>{fullJSON}</div>
      </PopoverTrigger>

      <PopoverContent className='w-128'>
        <JSONEditor value={fullJSON} readOnly maxHeight={DEFAULT_EDITOR_MAX_HEIGHT} />
      </PopoverContent>
    </Popover>
  )
}

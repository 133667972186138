import * as React from 'react'
import { useEffect, useImperativeHandle } from 'react'

import { cn } from '@utils/style-utils'

// Source:
// https://shadcnui-expansions.typeart.cc/docs/autosize-textarea
// https://github.com/hsuanyi-chou/shadcn-ui-expansions/blob/main/components/ui/autosize-textarea.tsx

interface UseTextareaAutosizeProps {
  textAreaRef: HTMLTextAreaElement | null
  minHeight?: number
  maxHeight?: number
  triggerAutoSize: string
  dynamicMaxHeight?: boolean
  initialHeight?: number | string
}

export const useTextareaAutosize = ({
  textAreaRef,
  triggerAutoSize,
  maxHeight = Number.MAX_SAFE_INTEGER,
  minHeight = 0,
  dynamicMaxHeight = false,
  initialHeight
}: UseTextareaAutosizeProps) => {
  const [init, setInit] = React.useState(true)

  useEffect(() => {
    const offsetBorder = 2
    if (textAreaRef) {
      const scrollHeight = textAreaRef.scrollHeight

      if (init) {
        textAreaRef.style.minHeight = `${minHeight + offsetBorder}px`

        if (initialHeight) {
          textAreaRef.style.height =
            typeof initialHeight === 'string' ? initialHeight : `${initialHeight}px`
        } else {
          textAreaRef.style.height = `${Math.min(scrollHeight + offsetBorder, maxHeight)}px`
        }
        setInit(false)
      }

      if (dynamicMaxHeight) {
        textAreaRef.style.maxHeight = `${scrollHeight + offsetBorder}px`
      } else {
        textAreaRef.style.maxHeight = `${dynamicMaxHeight ? scrollHeight + offsetBorder : maxHeight}px`
      }
    }
  }, [textAreaRef, triggerAutoSize, minHeight, maxHeight, init, dynamicMaxHeight, initialHeight])
}

export type TextareaAutosizeRef = {
  textArea: HTMLTextAreaElement
  maxHeight: number
  minHeight: number
}

type TextareaAutosizeProps = {
  maxRows?: number
  minHeight?: number
  dynamicMaxHeight?: boolean
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>

export const TextareaAutosize = React.forwardRef<TextareaAutosizeRef, TextareaAutosizeProps>(
  (
    {
      maxRows,
      minHeight = 52,
      className,
      onChange,
      value,
      style,
      dynamicMaxHeight,
      ...props
    }: TextareaAutosizeProps,
    ref: React.Ref<TextareaAutosizeRef>
  ) => {
    const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null)
    const [triggerAutoSize, setTriggerAutoSize] = React.useState('')

    const maxHeight = maxRows ? 24 * maxRows : Number.MAX_SAFE_INTEGER
    const initialHeight = style?.height

    useTextareaAutosize({
      textAreaRef: textAreaRef.current,
      triggerAutoSize: triggerAutoSize,
      maxHeight,
      minHeight,
      dynamicMaxHeight,
      initialHeight
    })

    useImperativeHandle(ref, () => ({
      textArea: textAreaRef.current as HTMLTextAreaElement,
      focus: () => textAreaRef?.current?.focus(),
      maxHeight,
      minHeight
    }))

    React.useEffect(() => {
      setTriggerAutoSize(value as string)
    }, [value])

    return (
      <textarea
        {...props}
        value={value}
        ref={textAreaRef}
        className={cn(
          'flex h-9 w-full rounded-md border border-grey bg-transparent p-3 text-input file:border-0 file:bg-transparent file:font-medium disabled:cursor-not-allowed',
          className
        )}
        style={{ ...style, minHeight: `${minHeight}px` }}
        onChange={(e) => {
          setTriggerAutoSize(e.target.value)
          onChange?.(e)
        }}
      />
    )
  }
)

TextareaAutosize.displayName = 'TextareaAutosize'

import { Checkbox } from '@components/core/checkbox'
import { Label } from '@components/core/label'
import { Separator } from '@components/core/separator'
import { Select } from '@components/form/select'

import { useDispatch } from '@store/index'
import { useSelector } from '@store/index'
import { selectSettings, updateSettingsData } from '@store/slices/component/chart-config'

import { Description } from '../common/description'
import { Title } from '../common/title'
import { StackingType } from '../types/chart-builder-types'

export function SeriesArrangement() {
  const dispatch = useDispatch()
  const settings = useSelector(selectSettings)

  return (
    <>
      <div className='mt-4'>
        <Title>Series Arrangement</Title>
        <Description>
          The following arrangement config only applies to bar/column series
        </Description>
      </div>
      <Separator className='my-2' />
      <div className='flex items-center gap-4'>
        <div className='flex items-center gap-2'>
          <Select
            id='series-stacking'
            label='Stacking'
            value={_.get(settings, 'seriesStacking', StackingType.NONE)}
            onChange={(value) => {
              dispatch(updateSettingsData({ seriesStacking: value as StackingType }))
            }}
            options={[
              { value: StackingType.NONE, label: 'None' },
              { value: StackingType.STACKED, label: 'Stacked' },
              { value: StackingType.LAYERED, label: 'Layered' }
            ]}
            fullWidth={false}
            className='w-36'
          />
        </div>
        <div className='flex items-center gap-2'>
          <Label className='text-submenu font-normal' htmlFor='reverse-stacking-order'>
            Reverse Stacking
          </Label>
          <Checkbox
            id='reverse-stacking-order'
            className='m-2'
            checked={_.get(settings, 'reverseStackingOrder', false)}
            onCheckedChange={(value) =>
              dispatch(updateSettingsData({ reverseStackingOrder: value as boolean }))
            }
          />
        </div>
      </div>
    </>
  )
}

// material-ui
import { Theme } from '@mui/material/styles'

import { ColorProps } from 'types/extended'
// project import
import getColors from 'utils/getColors'

// types
interface Props {
  variant: ColorProps
  theme: Theme
}

// ==============================|| OVERRIDES - INPUT BORDER & SHADOWS ||============================== //

function getColor({ variant, theme }: Props) {
  const colors = getColors(theme, variant)
  const { main } = colors

  return {
    '&:hover:not(.Mui-disabled):not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
      borderColor: main
    },
    '&.Mui-focused': {
      boxShadow: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${main}`
      }
    }
  }
}

// ==============================|| OVERRIDES - OUTLINED INPUT ||============================== //

export default function OutlinedInput(theme: Theme) {
  return {
    MuiOutlinedInput: {
      defaultProps: {},
      styleOverrides: {
        input: {
          padding: '1rem',
          fontSize: '1rem',
          lineHeight: '1.375rem'
        },
        notchedOutline: {
          borderColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.grey[300]
        },
        root: {
          ...getColor({ variant: 'primary', theme }),
          '&.Mui-error': {
            ...getColor({ variant: 'error', theme })
          }
        },
        inputSizeSmall: {
          padding: '0.5rem 1.5rem 0.5rem 1rem'
        },
        inputMultiline: {
          padding: 0
        },
        colorSecondary: getColor({ variant: 'secondary', theme }),
        colorError: getColor({ variant: 'error', theme }),
        colorWarning: getColor({ variant: 'warning', theme }),
        colorInfo: getColor({ variant: 'info', theme }),
        colorSuccess: getColor({ variant: 'success', theme })
      }
    }
  }
}

import { useEffect, useMemo, useRef, useState } from 'react'

import { restrictToCustomParentElement } from '@utils/dnd-utils'
import { _excludes } from '@utils/lodash'
import { humanizeFieldName } from '@utils/string-utils'
import { cn } from '@utils/style-utils'

import Button from '@components/core/button'
import { IconButton } from '@components/core/icon-button'
import { Input } from '@components/core/input'
import { Label } from '@components/core/label'
import { Popover, PopoverContent, PopoverTrigger } from '@components/core/popover'
import { Separator } from '@components/core/separator'
import { Text } from '@components/core/text'
import { Select } from '@components/form/select'
import { Cancel, DragIndicator, Icon, Plus } from '@components/icons'
import SettingIcon from '@components/icons/setting.svg?react'
import { SortableList } from '@components/sortable-list'

import { useDispatch, useSelector } from '@store/index'
import {
  disableExternalFilter,
  selectColumns,
  updateExternalFilter
} from '@store/slices/component/table-config'

import {
  SelectContent,
  Select as SelectCore,
  SelectItem,
  SelectTrigger,
  SelectValue
} from 'components/core/select'
import { nanoid } from 'nanoid'

import {
  FilterType,
  IExternalFilters,
  IFilter,
  ISelectFilterConfigOption
} from '../types/table-builder-types'

function FilterSettingsContent({
  columnField: initialColumnField,
  filter: initialFilter,
  existingColumnFields = [],
  onClose
}: {
  columnField?: string
  filter?: IFilter
  existingColumnFields?: string[]
  onClose: () => void
}) {
  const dispatch = useDispatch()
  const columns = useSelector(selectColumns)
  const [columnField, setColumnField] = useState(initialColumnField || '')
  const [filterOptions, setFilterOptions] = useState(
    initialFilter?.options || [
      { id: nanoid(), label: 'Option 1', value: 'Option 1' },
      { id: nanoid(), label: 'Option 2', value: 'Option 2' }
    ]
  )
  const parentRef = useRef<HTMLDivElement>(null)
  const addValueClickedRef = useRef(false)

  useEffect(() => {
    if (!addValueClickedRef.current) return

    parentRef.current?.scrollTo({
      top: parentRef.current.scrollHeight,
      behavior: 'smooth'
    })

    addValueClickedRef.current = false
  }, [filterOptions])

  const WrapperComponent = useMemo(
    () =>
      ({ children }: { children: React.ReactNode }) => (
        <div className='max-h-52 overflow-scroll' ref={parentRef}>
          {children}
        </div>
      ),
    []
  )

  const saveDisabled = _.isEmpty(columnField) || _.isEmpty(filterOptions)

  return (
    <PopoverContent
      align='start'
      className='w-96 p-4'
      onPointerDownOutside={(e) => e.preventDefault()}
      onEscapeKeyDown={(e) => e.preventDefault()}
    >
      <div className='flex justify-between'>
        {initialColumnField ? (
          <Button variant={'outline'} onClick={handleRemove}>
            Remove
          </Button>
        ) : (
          <div className='flex items-center gap-2'>
            <Text variant='details'>Preview</Text>
            <Select
              value={_.first(filterOptions)?.value || ''}
              onChange={_.noop}
              options={_.map(filterOptions, ({ value = '', label = '' }) => ({ value, label }))}
            />
          </div>
        )}
        <div className='flex gap-2'>
          <Button variant='outline' onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant={saveDisabled ? 'disabled' : 'primary'}
            onClick={handleSave}
            disabled={saveDisabled}
          >
            Save
          </Button>
        </div>
      </div>
      <Separator className='my-2' />
      <div className='flex items-center justify-between'>
        <Text variant='submenu' className='text-primary-darker' weight='semibold'>
          Field to filter
        </Text>
        <Select
          className='w-36'
          value={columnField}
          onChange={(value) => setColumnField(value)}
          options={_.map(
            _.filter(columns, ({ field }) => _excludes(existingColumnFields, field)),
            ({ field }) => ({
              value: field,
              label: humanizeFieldName(field)
            })
          )}
        />
      </div>
      <Separator className='my-2' />
      <SortableList<ISelectFilterConfigOption>
        items={filterOptions}
        setItems={(newOptions) => setFilterOptions(newOptions)}
        dndContextModifers={[restrictToCustomParentElement(parentRef)]}
        renderItem={({ draggableProps, setNodeRef, style, item, id }) => (
          <div ref={setNodeRef} style={style} key={id}>
            <div className='flex items-center gap-2'>
              <IconButton className='border-none p-0' {...draggableProps}>
                <Icon icon={<DragIndicator />} />
              </IconButton>
              <div>
                <Label className='text-details text-grey-dark' htmlFor={`${id}-value`}>
                  Value
                </Label>
                <Input
                  id={`${id}-value`}
                  value={item.value}
                  onChange={(e) =>
                    setFilterOptions((prevOptions) =>
                      _.map(prevOptions, (option) =>
                        option.id === id ? { ...option, value: e.target.value } : option
                      )
                    )
                  }
                />
              </div>
              <div>
                <Label className='text-details text-grey-dark' htmlFor={`${id}-label`}>
                  Label
                </Label>
                <Input
                  id={`${id}-label`}
                  value={item.label}
                  onChange={(e) =>
                    setFilterOptions((prevOptions) =>
                      _.map(prevOptions, (option) =>
                        option.id === id ? { ...option, label: e.target.value } : option
                      )
                    )
                  }
                />
              </div>
              <IconButton
                className={cn(
                  'border-none p-0',
                  _.lt(_.size(filterOptions), 3) && 'cursor-not-allowed'
                )}
                disabled={_.lt(_.size(filterOptions), 3)}
                onClick={() =>
                  setFilterOptions(
                    (prevOptions) => _.reject(prevOptions, { id }) as ISelectFilterConfigOption[]
                  )
                }
              >
                <Icon icon={<Cancel />} />
              </IconButton>
            </div>
            <Separator className='mb-1 mt-2' />
          </div>
        )}
        WrapperComponent={WrapperComponent}
      />
      <div className='mt-2 flex justify-end'>
        <Button
          variant='outline'
          onClick={() => {
            setFilterOptions((prevOptions) => [
              ...prevOptions,
              { id: nanoid(), label: 'New Option', value: 'New Option' }
            ])

            addValueClickedRef.current = true
          }}
        >
          Add Value
        </Button>
      </div>
    </PopoverContent>
  )

  function handleSave() {
    dispatch(
      updateExternalFilter({
        field: columnField,
        filterConfig: {
          filterType: FilterType.SELECT,
          options: filterOptions,
          defaultValue: _.first(filterOptions)?.value
        }
      })
    )
  }

  function handleCancel() {
    onClose()
  }

  function handleRemove() {
    dispatch(disableExternalFilter(columnField))
    onClose()
  }
}

function FilterSettings({
  children: trigger,
  ...rest
}: {
  columnField?: string
  filter?: IFilter
  existingColumnFields: string[]
  children: React.ReactNode
}) {
  const [open, setOpen] = useState(false)

  const onOpenChange = (value: boolean) => setOpen(value)

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>{trigger}</PopoverTrigger>
      {open && <FilterSettingsContent {...rest} onClose={() => setOpen(false)} />}
    </Popover>
  )
}

export function FilterDropdown({
  externalFilters,
  activeFilters,
  setActiveFilters,
  showSettings = false
}: {
  externalFilters: IExternalFilters
  activeFilters: Record<string, string>
  setActiveFilters: React.Dispatch<React.SetStateAction<Record<string, string>>>
  showSettings?: boolean
}) {
  return (
    <div className='flex items-center gap-2'>
      {_.map(_.toPairs(externalFilters), ([columnField, filter]) => {
        return (
          <div key={columnField} className='relative inline-block'>
            {showSettings && (
              <FilterSettings
                columnField={columnField}
                filter={filter}
                existingColumnFields={_.difference(_.keys(externalFilters), [columnField])}
              >
                <IconButton
                  variant='outline'
                  className='absolute left-2 top-0.5 z-10 -ml-1 border-none p-0'
                >
                  <SettingIcon />
                </IconButton>
              </FilterSettings>
            )}
            <SelectCore
              defaultValue={filter.defaultValue}
              value={activeFilters[columnField] || ''}
              onValueChange={(value) =>
                setActiveFilters((prev) => ({ ...prev, [columnField]: value }))
              }
            >
              <SelectTrigger size='small' variant='outline'>
                {showSettings && (
                  <div className='-ml-1'>
                    <SettingIcon />
                  </div>
                )}
                <SelectValue placeholder='option' />
              </SelectTrigger>
              <SelectContent>
                {filter.options.map((option) => (
                  <SelectItem key={option.value} value={option.value || ''}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </SelectCore>
          </div>
        )
      })}
      {showSettings && (
        <FilterSettings existingColumnFields={_.keys(externalFilters)}>
          <Button variant='outline' size='small' className='gap-0 py-0 pr-1'>
            Add Dropdown Filter <Icon icon={<Plus />} />
          </Button>
        </FilterSettings>
      )}
    </div>
  )
}

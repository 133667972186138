export const ExpandMore = () => (
  <>
    <path
      d='M15.8499 6.49882L11.9699 10.3788L8.08991 6.49882C7.89861 6.33499 7.65253 6.24939 7.40086 6.25911C7.14918 6.26883 6.91044 6.37316 6.73235 6.55126C6.55425 6.72935 6.44992 6.96809 6.4402 7.21977C6.43048 7.47144 6.51608 7.71752 6.67991 7.90882L11.2699 12.4988C11.4573 12.6851 11.7107 12.7896 11.9749 12.7896C12.2391 12.7896 12.4925 12.6851 12.6799 12.4988L17.2699 7.90882C17.4562 7.72146 17.5607 7.46801 17.5607 7.20382C17.5607 6.93963 17.4562 6.68618 17.2699 6.49882C17.08 6.31362 16.8252 6.20996 16.5599 6.20996C16.2946 6.20996 16.0398 6.31362 15.8499 6.49882Z'
      fill='currentColor'
    />
    <path
      d='M15.8499 11.4988L11.9699 15.3788L8.08991 11.4988C7.89861 11.335 7.65253 11.2494 7.40086 11.2591C7.14918 11.2688 6.91044 11.3732 6.73235 11.5513C6.55425 11.7294 6.44992 11.9681 6.4402 12.2198C6.43048 12.4714 6.51608 12.7175 6.67991 12.9088L11.2699 17.4988C11.4573 17.6851 11.7107 17.7896 11.9749 17.7896C12.2391 17.7896 12.4925 17.6851 12.6799 17.4988L17.2699 12.9088C17.4562 12.7215 17.5607 12.468 17.5607 12.2038C17.5607 11.9396 17.4562 11.6862 17.2699 11.4988C17.08 11.3136 16.8252 11.21 16.5599 11.21C16.2946 11.21 16.0398 11.3136 15.8499 11.4988Z'
      fill='currentColor'
    />
  </>
)

import { useQuery } from '@tanstack/react-query';
import { camelCaseKeys } from '@utils/case-conversion';
import axiosServices from 'utils/axios';

export interface IUserGroup {
  id: number | undefined;
  label: string;
  description: string;
}

const fetchUserGroups = async (businessId: number): Promise<IUserGroup[]> => {
  const response = await axiosServices.get(`/businesses/${businessId}/access_profiles/user_groups`);
  return camelCaseKeys(response.data);
};

export const useFetchUserGroups = ({ businessId }: { businessId?: number }) => {
  return useQuery<IUserGroup[]>({
    queryKey: ['fetch-user-groups', businessId],
    enabled: !!businessId,
    queryFn: () => fetchUserGroups(businessId!!)
  });
};

import { useRef } from 'react'

import { useSelector } from '@store/index'
import { selectGenre } from '@store/slices/component/basic-config'
import { selectChartConfig } from '@store/slices/component/chart-config'
import { selectTableConfig } from '@store/slices/component/table-config'
import { selectTreemapConfig } from '@store/slices/component/treemap-config'

import { IChartConfig } from '../types/chart-builder-types'
import { ComponentGenre } from '../types/component-types'
import { ITableConfig } from '../types/table-builder-types'
import { ITreemapConfig } from '../types/treemap-builder-types'

export default function useActiveConfig() {
  const genre = useSelector(selectGenre)
  const chartConfig = useSelector(selectChartConfig)
  const tableConfig = useSelector(selectTableConfig)
  const treemapConfig = useSelector(selectTreemapConfig)

  const configRef = useRef<IChartConfig | ITreemapConfig | ITableConfig>()
  switch (genre) {
    case ComponentGenre.chart:
      configRef.current = chartConfig
      break
    case ComponentGenre.table:
      configRef.current = tableConfig
      break
    case ComponentGenre.treemap:
      configRef.current = treemapConfig
      break
    default:
      configRef.current = undefined
      break
  }

  return configRef
}

import { useRef } from 'react'

import { Button } from '@components/core/button'
import { closePopover } from '@components/form/charger-form'

import { useSelector } from '@store/index'
import { selectBasicConfig } from '@store/slices/component/basic-config'
import { selectChartConfig } from '@store/slices/component/chart-config'
import { selectLimit } from '@store/slices/component/query-config'
import { selectTableConfig } from '@store/slices/component/table-config'
import { selectTreemapConfig } from '@store/slices/component/treemap-config'

import { useComponentAttrRef } from '../hooks/use-component-attr-refs'
import { useUpdateComponent } from '../queries/update-component'

export function SaveComponent() {
  const popoverTriggerRef = useRef<HTMLButtonElement>(null)

  const { title, id, genre, description } = useSelector(selectBasicConfig)
  const { mutateAsync, isPending } = useUpdateComponent(id)
  const {
    queryDataRef: dataRef,
    filtersRef,
    sortsRef,
    hiddenFiltersRef,
    formulasRef,
    parameterizedConfigRef
  } = useComponentAttrRef()
  const limit = useSelector(selectLimit)
  const chartConfig = useSelector(selectChartConfig)
  const tableConfig = useSelector(selectTableConfig)
  const treemapConfig = useSelector(selectTreemapConfig)

  const handleSave = () => {
    mutateAsync({
      body: {
        title,
        description,
        genre,
        component_attributes: {
          query: dataRef.current,
          filters: filtersRef.current,
          hidden_filters: hiddenFiltersRef.current,
          sorts: sortsRef.current,
          limit,
          formulas: formulasRef.current,
          parameterized_config: parameterizedConfigRef.current,
          chart_config: chartConfig,
          table_config: tableConfig,
          treemap_config: treemapConfig
        }
      }
    }).then(() => {
      closePopover(popoverTriggerRef.current)
    })
  }

  return (
    <Button
      variant={isPending ? 'disabled' : 'primary'}
      onClick={() => handleSave()}
      size='small'
      disabled={isPending}
    >
      {isPending ? 'Saving Component...' : 'Save Component'}
    </Button>
  )
}

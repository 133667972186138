import { enumToOptions } from '@utils/obj-utils'

import { Select } from '@components/form/select'

import { useDispatch } from '@store/index'
import { updateYAxisData } from '@store/slices/component/chart-config'

import { IAxis } from '../types/chart-builder-types'
import { AggregateType } from '../types/component-types'

export function Aggregation({ item: data, className = '' }: { item: IAxis; className?: string }) {
  const dispatch = useDispatch()

  return (
    <Select
      className={className}
      options={enumToOptions(AggregateType)}
      value={data.aggregate || AggregateType.first}
      onChange={(value) => {
        dispatch(updateYAxisData({ id: data.id, aggregate: value as AggregateType }))
      }}
    />
  )
}

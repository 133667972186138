import { useQuery } from '@tanstack/react-query'

import { camelCaseKeys } from '@utils/case-conversion'

import useAuth from 'hooks/useAuth'
import { QUERY_STALE_TIME_DEFAULT } from 'queries/constants'
import axiosServices from 'utils/axios'

import { IConnectedSourceSystem } from '../types'

const fetchConnectedSourceSystem = async (id: number): Promise<IConnectedSourceSystem> => {
  const response = await axiosServices.get(`/connected_source_systems/${id}`)

  return camelCaseKeys(response.data, [
    'connector.connector_config',
    'connector_spec'
  ]) as IConnectedSourceSystem
}

export const useFetchConnectedSourceSystem = ({ id }: { id?: string }) => {
  const { user } = useAuth()
  const idNum = _.toNumber(id)

  return useQuery({
    queryKey: ['fetch-connected-source-system', idNum],
    enabled: !!user?.business_id && !!idNum,
    queryFn: () => fetchConnectedSourceSystem(idNum),
    staleTime: QUERY_STALE_TIME_DEFAULT,
    retry: 1
  })
}

import BuilderLayout from '../common/builder-layout'
import DataPreview from '../common/data-preview'
import TreemapConfig from './treemap-config'
import TreemapPreview from './treemap-preview'

export default function ChartBuilder() {
  return (
    <BuilderLayout
      Preview={<TreemapPreview />}
      Config={<TreemapConfig />}
      DataPreview={<DataPreview />}
    />
  )
}

import { useQuery } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'

import { IModel } from '../types/query-builder-types'

const fetchModels = async (business_id: number, depth?: number): Promise<IModel[]> => {
  const params = depth ? { depth } : {}
  const response = await axiosServices.get(`/businesses/${business_id}/models`, { params })
  const { data } = response as { data: IModel[] }
  data.sort((a, b) => a.name.localeCompare(b.name))

  return response.data
}

export const useFetchModels = (depth?: number) => {
  const { user } = useAuth()
  const business_id = user?.business_id!
  return useQuery({
    queryKey: ['fetch-models', business_id, depth],
    queryFn: () => fetchModels(business_id, depth),
    enabled: !!business_id
  })
}

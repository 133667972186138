import { useQuery } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import { PageTreeItem } from 'types/page'
import axiosServices from 'utils/axios'
import { camelCaseKeys } from 'utils/case-conversion'

const fetchNavigationTree = async (businessId: number | undefined): Promise<PageTreeItem[]> => {
  const response = await axiosServices.get(`/businesses/${businessId}/pages/navigation_tree`)
  return camelCaseKeys(response.data)
}

export const useFetchNavigationTree = () => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-navigation-tree', user?.business_id],
    enabled: !!user?.business_id,
    queryFn: () => fetchNavigationTree(user?.business_id),
    staleTime: 600_000
  })
}

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

import { useLatestRef } from 'hooks/useLatestRef'

import { useSetAxisName, useSetInstanceData } from '../hooks'
import useToggleDataLabels from '../hooks/useToggleDataLabels'
import { DATA_LABEL_BULLETS, Display, Position } from '../types/data-types'
import { IModule, IModuleAttributes, IModuleUseInstance, ModuleName } from '../types/module-types'
import { getDefaultPrimaryColor } from '../utils/color-utils'
import { layeredSeriesThickness } from '../utils/layered-series-thickness'
import { assignRef, getRef } from '../utils/module-utils'

const moduleName = ModuleName.columnSeries
const columnSeriesModule: IModule<ModuleName.columnSeries> = {
  name: moduleName
}

columnSeriesModule.init = (props) => {
  const root: am5.Root = getRef({ ...props, moduleName: ModuleName.root })
  const chart: am5xy.XYChart = getRef({ ...props, moduleName: ModuleName.xyChart })
  const xAxis:
    | am5xy.CategoryAxis<am5xy.AxisRenderer>
    | am5xy.ValueAxis<am5xy.AxisRenderer>
    | am5xy.DateAxis<am5xy.AxisRenderer> = getRef({
    ...props,
    moduleName: [ModuleName.categoryXAxis, ModuleName.valueXAxis, ModuleName.dateXAxis]
  })
  const yAxis: am5xy.ValueAxis<am5xy.AxisRenderer> = getRef({
    ...props,
    moduleName: ModuleName.valueYAxis
  })
  const legend: am5.Legend = getRef({ ...props, moduleName: ModuleName.legend })
  const externalLegend: am5.Legend = getRef({ ...props, moduleName: ModuleName.externalLegend })
  const dataProcessor: am5.DataProcessor = getRef({
    ...props,
    moduleName: ModuleName.dataProcessor
  })

  const {
    options: {
      yAxisKey = 'category',
      xAxisKey,
      name = '',
      stacked = false,
      layered = false,
      color,
      // global options
      clusteredSeries = false
    }
  } = props

  const categoryXAxis = xAxis instanceof am5xy.CategoryAxis

  const seriesSettings: am5xy.IColumnSeriesSettings = {
    name,
    xAxis: xAxis,
    yAxis: yAxis,
    valueYField: yAxisKey,
    [categoryXAxis ? 'categoryXField' : 'valueXField']: xAxisKey,
    fill: am5.color(color || getDefaultPrimaryColor()),
    stacked
  }

  if (layered) {
    seriesSettings.clustered = false
  }

  const series = chart.series.push(am5xy.ColumnSeries.new(root, seriesSettings))

  const borderRadius = stacked ? 0 : 4

  series.columns.template.setAll({
    strokeWidth: 2,
    cornerRadiusTL: borderRadius,
    cornerRadiusTR: borderRadius
  })

  if (clusteredSeries) {
    series.columns.template.set('width', am5.p100)
  }

  if (layered) {
    series.columns.template.set(
      'width',
      am5.percent(layeredSeriesThickness(_.size(chart.series) - 1))
    )
  }

  if (dataProcessor) {
    series.data.processor = dataProcessor
  }

  if (legend) {
    legend.data.push(series)
  } else if (externalLegend) {
    externalLegend.data.push(series)
  }

  series.columns.template.onPrivate('height', function (height, target) {
    if (!height) return

    _.each(_.get(target, 'dataItem.bullets', []), function (bullet) {
      if (!_.isEqual(bullet.get('userData'), DATA_LABEL_BULLETS)) return

      if (height > 50) {
        bullet.get('sprite').show()
      } else {
        bullet.get('sprite').hide()
      }
    })
  })

  assignRef({ ...props, moduleName, item: series })
}

const useInstance: IModuleUseInstance<ModuleName.columnSeries> = (instance, props) => {
  const propsRef = useLatestRef(props)

  const {
    options: { yAxisKey, stacked = false, showDataLabels = false }
  } = props

  useSetInstanceData({ propsRef, moduleName, instance })
  useSetAxisName({ propsRef, moduleName, props })
  useToggleDataLabels({
    propsRef,
    moduleName,
    props,
    axisKey: yAxisKey,
    showDataLabels: !stacked && showDataLabels,
    display: Display.VERTICAL,
    position: Position.INSIDE,
    color: '#fff'
  })
}

columnSeriesModule.useInstance = useInstance

export const columnSeriesAttributes: IModuleAttributes<ModuleName.columnSeries> = (p) => ({
  module: columnSeriesModule,
  ...p
})

export default columnSeriesModule

import { useQuery } from '@tanstack/react-query'

import { FinancialHierarchy } from 'components/financial/types'
import useAuth from 'hooks/useAuth'
import { QUERY_STALE_TIME_DEFAULT } from 'queries/constants'
import axiosServices from 'utils/axios'

const fetchFinancialHierarchies = async (
  businessId: number | undefined
): Promise<FinancialHierarchy[]> => {
  const response = await axiosServices.get(
    `/businesses/${businessId}/general_ledger_account_hierarchies`
  )
  return response.data
}

export const useFetchHierarchies = () => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-hierarchies', user?.business_id],
    queryFn: () => fetchFinancialHierarchies(user?.business_id),
    enabled: !!user?.business_id,
    staleTime: QUERY_STALE_TIME_DEFAULT
  })
}

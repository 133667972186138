import React, { useCallback, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { GetDataPath } from 'ag-grid-community'
import { BaseColDefParams } from 'ag-grid-community/dist/types/core/entities/colDef'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react'

import { formatFinancialAmount } from '@utils/number-utils'

import { useAutoFitOnDataLoaded } from '@components/ag-grid/hooks/use-auto-fit-on-data-loaded'
import Button from '@components/core/button'
import Tooltip from '@components/core/tooltip'
import { getDivideBy } from '@components/financial/utils/formatting-utils'
import { nodeLabelGroupColDef } from '@components/financial/utils/grid/node-label-group-col-def'
import { sourceSystemAccountIdColDef } from '@components/financial/utils/grid/source-system-account-id-col-def'
import { Cancel, Icon } from '@components/icons'

import { usePageSelector } from '@store/index'
import { selectDollarUnit } from '@store/slices/action-bar'
import { selectDimensions } from '@store/slices/dimension'

import { FinancialHierarchyNode } from 'components/financial/types'
import { AgGridRef } from 'contexts/grid-ref-context'
import { useMaxRows } from 'hooks/grid/useMaxRows'
import { useFetchDimensions } from 'queries/fetch-dimensions'

import AgGrid, { getMainMenuItems } from '../../../components/ag-grid'
import { ExpandedDateFilterInfo } from '../../../types/filter'
import { generateExportFileName } from '../../../utils/string-utils'
import { useGlTransactionDimensionFilterPayload } from '../hooks/gl-transaction-dimension-filter'
import { useRowNumberColumnDefinition } from '../hooks/row-number-column-definition'
import StatusBar from '../status-bar'
import { FinancialReport } from '../types'
import { ShowListOfSelectedDimensions } from './selected-dimensions'

import '../financial-statement.css'
import './financial-grid.css'

interface FinancialsGridProps extends AgGridReactProps {
  fileName: string
  rowData: FinancialReport[] | FinancialHierarchyNode[] | undefined
  dataColumnDefinition: any
  dateFilter: ExpandedDateFilterInfo
  isLoading?: boolean
}

export const FinancialsGrid = React.forwardRef<AgGridReact<any>, FinancialsGridProps>(
  (props, ref) => {
    const { isLoading, rowData, dataColumnDefinition, fileName, dateFilter } = props

    const dollarUnit = usePageSelector(selectDollarUnit)
    const dividedBy = getDivideBy(dollarUnit)

    const dispatch = useDispatch()

    useAutoFitOnDataLoaded({ isLoading: isLoading, gridRef: ref as AgGridRef })

    const dimensionFilters = usePageSelector(selectDimensions)
    const { data: dimensions } = useFetchDimensions()

    const dimensionPayload = useGlTransactionDimensionFilterPayload(dimensionFilters, dimensions)

    const rowNumberColDef = useRowNumberColumnDefinition()

    const defaultColDef = useMemo(() => {
      return {
        resizable: false,
        sortable: false,
        filter: false,
        floatingFilter: true
      }
    }, [])

    const getDataPath = useCallback<GetDataPath>((data: any) => {
      return data.path.split('.')
    }, [])

    const columnDefs = useMemo(() => {
      return [
        {
          ...rowNumberColDef,
          headerComponent: (params: BaseColDefParams) => {
            const filterModel = params?.api?.getFilterModel() ?? {}
            const isFilterActive = filterModel && Object.keys(filterModel).length > 0

            if (!isFilterActive) return null

            return (
              <div className='absolute left-1/2 top-1/2 z-50 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center'>
                <Tooltip title='Clear Filters'>
                  <Button
                    variant='text'
                    className='z-50 size-8 rounded-lg p-1 outline-none focus:border-none focus:outline-none'
                    size='extra-small'
                    onClick={() => {
                      params?.api?.setFilterModel(null)
                      params?.api?.onFilterChanged()
                    }}
                  >
                    <Icon icon={<Cancel />}></Icon>
                  </Button>
                </Tooltip>
              </div>
            )
          }
        },
        nodeLabelGroupColDef,
        {
          ...sourceSystemAccountIdColDef,
          cellRendererParams: {
            dispatch
          }
        },
        ...dataColumnDefinition
      ]
    }, [dataColumnDefinition, rowNumberColDef, dispatch])

    const wrapperRef = useRef<HTMLDivElement | null>(null)

    const { refetch } = useMaxRows(wrapperRef)

    return (
      <div className='h-[calc(100%-24px)]'>
        <div>
          <ShowListOfSelectedDimensions />
        </div>
        <AgGrid
          wrapperRef={wrapperRef}
          className='ag-financial-statement ag-header-filter'
          style={{
            height: '100%',
            width: '100%'
          }}
          statusBar={{
            statusPanels: [
              {
                statusPanel: StatusBar,
                statusPanelParams: {
                  aggFuncs: ['sum', 'avg', 'count']
                }
              }
            ]
          }}
          ref={ref}
          onGridReady={() => refetch()}
          rowData={rowData}
          columnDefs={columnDefs}
          groupDisplayType={'custom'}
          treeData
          animateRows={false}
          context={{
            dateFilter: dateFilter,
            dimensionFilters: dimensionPayload,
            dispatch
          }}
          getMainMenuItems={(params) => {
            const columnNames = ['label', 'source_system_account_id']
            const baseMenuItems = getMainMenuItems(params)
            if (columnNames.includes(params.column.getId())) {
              return [
                {
                  name: 'Reset size',
                  action: () => params.api.autoSizeColumns([params.column.getId()])
                }
              ]
            }
            return baseMenuItems
          }}
          autoSizeStrategy={{
            type: 'fitCellContents'
          }}
          getDataPath={getDataPath}
          defaultColDef={defaultColDef}
          onExpandOrCollapseAll={(event) => event.api.autoSizeColumns(['label'])}
          suppressMovableColumns={true}
          defaultCsvExportParams={{
            fileName: generateExportFileName(fileName, 'csv')
          }}
          defaultExcelExportParams={{
            skipColumnHeaders: true,
            fileName: generateExportFileName(fileName, 'xlsx')
          }}
          persistExpandedGroups
          persistFilterState
          refreshCellsOnRowGroupOpened
          enableDollarSignOnFirstRow
          loading={isLoading}
          onFilterModified={(e) => {
            e.api.refreshHeader()
          }}
          onFirstDataRendered={() => {
            const wrapper = wrapperRef.current
            if (!wrapper) {
              return
            }

            // Set placeholder text for floating filter fields
            const headerFields = wrapper?.querySelectorAll('.ag-header-cell.ag-floating-filter')

            const descriptionField = headerFields[1]?.getElementsByClassName(
              'ag-input-field-input ag-text-field-input'
            )[0] as HTMLInputElement
            const accountIdField = headerFields[2]?.getElementsByClassName(
              'ag-input-field-input ag-text-field-input'
            )[0] as HTMLInputElement

            if (descriptionField) descriptionField.placeholder = 'Description'
            if (accountIdField) accountIdField.placeholder = 'Acct ID'
          }}
          loadingOverlayComponentParams={{
            showHeader: true,
            topOffset: '25px'
          }}
          processCellForClipboard={(params) => {
            const field = params?.column?.getColDef()?.field

            if (
              typeof params.value === 'string' ||
              field === 'source_system_account_id' ||
              field === 'label' ||
              field === 'rowNumber'
            ) {
              return params.value
            }

            const parsedValue = params.value * dividedBy

            return formatFinancialAmount(parsedValue, 2)
          }}
        />
      </div>
    )
  }
)

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { RenderItem } from '.'

interface SortableItemProps<T> {
  item: T
  renderItem: RenderItem<T>
  id: string
}

export default function SortableItem<T extends { [key in keyof T]: any }>({
  item,
  renderItem,
  id
}: SortableItemProps<T>) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return renderItem({
    draggableProps: { ...attributes, ...listeners },
    setNodeRef,
    style,
    id,
    item
  })
}

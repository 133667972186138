import React from 'react'

import { useToggle } from '@uidotdev/usehooks'

import Button from '@core/button'
import { Text } from '@core/text'

import { ChevronLeft, ChevronRight, Icon } from '@components/icons'

import { Panel } from 'pages/component-management/common/panel'

interface DataPanelProps {
  children: React.ReactNode
  controls?: React.ReactNode // extra controls to add next to the "Hide" button
  defaultHidden?: boolean
}

export function DataPanel(props: DataPanelProps) {
  const { children, controls, defaultHidden = false } = props

  const [visible, toggleVisible] = useToggle(!defaultHidden)

  if (!visible) {
    return (
      <Panel className='h-full w-10 px-2 pb-2 pt-4'>
        <div className='flex cursor-pointer flex-col items-center' onClick={() => toggleVisible()}>
          <Text
            className='cursor-pointer [writing-mode:vertical-lr]'
            variant='button'
            weight='semibold'
          >
            Show Data Panel
          </Text>
          <Icon icon={<ChevronLeft />} className='mt-1 cursor-pointer' />
        </div>
      </Panel>
    )
  }

  return (
    <Panel
      className='h-full min-w-[26rem] max-w-[26rem]'
      title='Data Panel'
      secondary={
        <div className='flex items-center gap-1'>
          {controls}
          <Button className='pr-1' variant='outline' size='small' onClick={() => toggleVisible()}>
            Hide <Icon icon={<ChevronRight />} />
          </Button>
        </div>
      }
    >
      {children}
    </Panel>
  )
}

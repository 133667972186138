import { cn } from '@utils/style-utils'

function Skeleton({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn('origin-[0%_55%] scale-y-[0.65] animate-pulse rounded-md bg-muted', className)}
      {...props}
    />
  )
}

export { Skeleton }

import { ToggleDimensionFilterButton } from '@components/control-panel/toggle-dimension-filters'
import { Separator } from '@components/core/separator'

import { ControlPanel } from 'components/control-panel'
import ChartGridToggle from 'components/control-panel/chart-grid-toggle'
import DataLabelsToggle from 'components/control-panel/data-labels-toggle'
import DateFilterControl from 'components/control-panel/date-filter-control'

export function ActionBarControls({
  dimensionFilterOpen,
  onToggleDimensionFilters
}: {
  dimensionFilterOpen: boolean
  onToggleDimensionFilters: () => void
}) {
  return (
    <div className='mb-2'>
      <div className='flex items-center justify-between'>
        <ControlPanel>
          <DateFilterControl />
          <DataLabelsToggle />
          <ChartGridToggle />
        </ControlPanel>
        <ToggleDimensionFilterButton
          dimensionFilterOpen={dimensionFilterOpen}
          onToggleDimensionFilter={onToggleDimensionFilters}
        />
      </div>
      <Separator className='bg-grey' />
    </div>
  )
}

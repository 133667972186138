import { ITimeInterval } from '@amcharts/amcharts5/.internal/core/util/Time'

import { Frequency } from 'types/filter'

export function dateIntervalsAndFormattings(frequency: Frequency = Frequency.Monthly): {
  interval: ITimeInterval
  dateFormats: Record<string, string>
  periodChangeDateFormats: Record<string, string>
  gridIntervals?: ITimeInterval[]
  dateFormat: string
} {
  switch (frequency) {
    case Frequency.Weekly:
      return {
        interval: { timeUnit: 'day', count: 7 },
        gridIntervals: [
          { timeUnit: 'day', count: 7 },
          { timeUnit: 'month', count: 1 }
        ],
        dateFormats: { day: 'dd', month: 'MMM\nyy' },
        periodChangeDateFormats: { day: 'dd\nMMM', month: 'MMM\nyy' },
        dateFormat: 'dd MMM yyyy'
      }
    case Frequency.Quarterly:
      return {
        interval: { timeUnit: 'month', count: 3 },
        gridIntervals: [{ timeUnit: 'month', count: 3 }],
        dateFormats: { month: "'Q'q\nyy" },
        periodChangeDateFormats: { month: "'Q'q\nyy" },
        dateFormat: "'Q'q\nyy"
      }
    case Frequency.Yearly:
      return {
        interval: { timeUnit: 'year', count: 1 },
        gridIntervals: [{ timeUnit: 'year', count: 1 }],
        dateFormats: { year: 'yyyy' },
        periodChangeDateFormats: { year: 'yyyy' },
        dateFormat: 'yyyy'
      }
    case Frequency.Daily:
      return {
        interval: { timeUnit: 'day', count: 1 },
        gridIntervals: [{ timeUnit: 'day', count: 1 }],
        dateFormats: { day: 'dd MMM' },
        periodChangeDateFormats: { day: 'dd MMM' },
        dateFormat: 'dd MMM'
      }
    case Frequency.Monthly:
    default:
      return {
        interval: { timeUnit: 'month', count: 1 },
        gridIntervals: [{ timeUnit: 'month', count: 1 }],
        dateFormats: { month: `MMM\nyy` },
        periodChangeDateFormats: { month: `MMM\nyy` },
        dateFormat: `MMM\nyy`
      }
  }
}

import { IChartSettings, ValueFormattingType } from '../../types/chart-builder-types'

export function getValueFormatString({
  type,
  customShort,
  customLong
}: {
  type?: ValueFormattingType
  customShort?: string
  customLong?: string
}): { short: string; long: string } {
  switch (type) {
    case ValueFormattingType.Percent:
      return { short: '#.%', long: '#.%' }
    case ValueFormattingType.Custom:
      return { short: customShort || '#.#a', long: customLong || '#,###.##' }
    case ValueFormattingType.Amount:
      return { short: '$#.0a|($#.0sa)|$#.0a', long: '$#,###.00|($#,###.00s)|$#,###.00' }
    default:
      return { short: '#.#a', long: '#,###.##' }
  }
}

export function areAxesStacked(settings: IChartSettings) {
  return _.get(settings, 'stackAxes', false)
}

import { useQuery } from '@tanstack/react-query'

import { financialHierarchySorterToFixAgGridRegression } from '@components/ag-grid/utils'
import { DEFAULT_LANGUAGE_ID } from '@components/control-panel/language-selector'

import useAuth from 'hooks/useAuth'
import { ExpandedDateFilterInfo, Frequency } from 'types/filter'
import axiosServices from 'utils/axios'
import { dateFilterFrequencyForPayload } from 'utils/date-utils'

import { SelectedDimensions } from '../../../components/control-panel/types'
import { FinancialReport, FinancialStatementType } from '../types'

const fetchFinancialStatement = async ({
  type,
  businessId,
  dateFilter,
  hierarchyId,
  showDuplicateAccounts,
  showAllZeroRows,
  selectedDimensionFilters,
  languageId
}: {
  type: FinancialStatementType
  businessId: number | undefined
  dateFilter: ExpandedDateFilterInfo
  hierarchyId?: number
  showDuplicateAccounts?: boolean
  showAllZeroRows?: boolean
  selectedDimensionFilters?: Partial<SelectedDimensions>
  languageId?: string
}): Promise<FinancialReport[]> => {
  const params = _.omitBy(
    {
      start_date: dateFilter.startDate,
      end_date: dateFilter.endDate,
      mode: dateFilterFrequencyForPayload(dateFilter.frequency || Frequency.Monthly),
      show_dupe_gl_accounts: showDuplicateAccounts,
      show_all_zero_rows: showAllZeroRows,
      language_id: languageId,
      ...selectedDimensionFilters
    },
    _.isNil
  )

  let url = ''
  if (hierarchyId) {
    url = `/businesses/${businessId}/financial_reports/${hierarchyId}/show`
  } else {
    url = `/businesses/${businessId}/financial_reports/${type.split('-').join('_')}`
  }
  const response = await axiosServices.post(url, {
    ...params
  })

  return financialHierarchySorterToFixAgGridRegression(response.data as FinancialReport[])
}

export const useFetchFinancialStatement = ({
  type,
  dateFilter,
  hierarchyId,
  showDuplicateAccounts,
  showAllZeroRows,
  selectedDimensionFilters,
  languageId
}: {
  type: FinancialStatementType
  dateFilter: ExpandedDateFilterInfo
  hierarchyId?: number
  showDuplicateAccounts?: boolean
  showAllZeroRows?: boolean
  selectedDimensionFilters?: Partial<SelectedDimensions>
  languageId?: string
}) => {
  const { user } = useAuth()

  return useQuery({
    queryKey: [
      'fetch-financial-statement',
      type,
      user?.business_id,
      dateFilter,
      hierarchyId,
      showDuplicateAccounts,
      showAllZeroRows,
      selectedDimensionFilters,
      languageId
    ],
    queryFn: () =>
      fetchFinancialStatement({
        type,
        businessId: user?.business_id,
        dateFilter,
        hierarchyId,
        showDuplicateAccounts,
        showAllZeroRows,
        selectedDimensionFilters,
        languageId: _.isEqual(languageId, DEFAULT_LANGUAGE_ID) ? undefined : languageId
      }),
    retry: 2
  })
}

import { ChargerFormFieldConfig, OneOfFieldConfig, isFieldTypeOneOf } from '@components/form/types'

export function chargerToAirbytePayload(
  payload: Record<string, any>,
  fieldsConfig: ChargerFormFieldConfig[]
) {
  return _.reduce(
    fieldsConfig,
    (acc, field) => {
      if (isFieldTypeOneOf(field.type)) {
        const oneOfField = field as OneOfFieldConfig
        const currentPayload = payload[oneOfField.name]
        const selectedSection = oneOfField.sections.find((option) =>
          _.isEqual(option.value, currentPayload.value)
        )
        if (selectedSection) {
          acc[field.name] = _.assign(
            {
              [_.get(oneOfField.userData, 'valueKey')]: currentPayload.value
            },
            chargerToAirbytePayload(currentPayload.data, selectedSection.formFields)
          )
        }
      } else {
        acc[field.name] = payload[field.name]
      }
      return acc
    },
    {} as Record<string, any>
  )
}

import * as Tabs from '@radix-ui/react-tabs'
import { humanizeFieldName } from '@utils/string-utils'
import { cn } from '@utils/style-utils'

import Tooltip from '@core/tooltip'

import PinIcon from '@components/icons/pin.svg?react'

import { IDataTab } from '@pages/component-management/types/query-builder-types'

interface DataTabsProps {
  tabs?: IDataTab[]
  pinnedTab?: IDataTab
  selectedTab?: IDataTab
  onPin?: (tab: IDataTab) => void
  onChange?: (value: IDataTab) => void
  className?: string
}

export function DataTabs(props: DataTabsProps) {
  const { tabs = [], pinnedTab, selectedTab, onPin = _.noop, onChange = _.noop, className } = props

  if (_.isEmpty(tabs)) return null

  return (
    <Tabs.Root
      className={className}
      value={selectedTab?.associationName || ''}
      onValueChange={(value) => onChange(_.find(tabs, { associationName: value }))}
    >
      <Tabs.List className='flex flex-row items-end'>
        {tabs.map((tab) => (
          <Tooltip key={tab.associationName} title='Double-click to pin this tab' side='top'>
            <Tabs.Trigger
              value={tab.associationName}
              onDoubleClick={() => onPin(tab)}
              className={cn(
                // general styling
                'cursor-pointer bg-grey-lighter px-4 py-2 font-semibold hover:bg-white',
                // borders
                'rounded-t border border-b-0 border-l border-r border-t border-solid border-grey',
                // pinned & selected tabs
                _.isEqual(tab, pinnedTab)
                  ? 'border-x-[2px] border-t-[2px] border-warning bg-white' // pinned tab
                  : 'data-[state=active]:border-x-[2px] data-[state=active]:border-t-[2px] data-[state=active]:border-primary-light data-[state=active]:bg-white' // selected tab
              )}
            >
              <div className='flex items-center'>
                {humanizeFieldName(tab.associationName)}
                {_.isEqual(tab, pinnedTab) && (
                  <div className='-my-2 -mr-3 ml-1 flex items-center'>
                    <PinIcon />
                  </div>
                )}
              </div>
            </Tabs.Trigger>
          </Tooltip>
        ))}
      </Tabs.List>
    </Tabs.Root>
  )
}

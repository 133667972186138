import { Stack, Typography, useTheme } from '@mui/material'

import { useDroppable } from '@dnd-kit/core'

const RootDropZone = () => {
  const theme = useTheme()
  const { isOver, setNodeRef } = useDroppable({ id: 'root' })

  return (
    <div ref={setNodeRef}>
      <Stack
        border='dashed'
        borderColor={isOver ? 'green' : theme.palette.primary.dark}
        width='100%'
        height='40px'
        my={1}
        justifyContent='center'
        alignItems='center'
      >
        <Typography variant='h5'>Root Heading Drop Zone</Typography>
      </Stack>
    </div>
  )
}

export default RootDropZone

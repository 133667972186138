import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Month, getMonthsForFiscalYear, todayYMD } from '@utils/date-utils'

import { ACTUALS } from '@components/financial/model-plus-actual/const'
import {
  IModelSelection,
  IModelSelectionValue
} from '@components/financial/model-plus-actual/types'

import { rehydrateStore } from '@store/actions/rehydrate-store'

import { YearInfo } from 'types/filter'

import { RootState } from '..'

interface ModelPlusActualView {
  yearModelSelections: Record<number, IModelSelection>
  yearInfo: YearInfo
  latestMonthSelection: string[]
}

const defaultModelSelections = _.zipObject(
  getMonthsForFiscalYear(),
  new Array(12).fill(ACTUALS)
) as IModelSelection

const initialState: ModelPlusActualView = {
  yearModelSelections: {},
  yearInfo: todayYMD(undefined),
  latestMonthSelection: []
}

const modelPlusActualSlice = createSlice({
  name: '@MODEL_PLUS_ACTUAL',
  initialState,
  reducers: {
    changeModelSelections: (
      state,
      action: PayloadAction<{
        month: Month | Month[]
        modelId: IModelSelectionValue
      }>
    ) => {
      const { month, modelId } = action.payload
      const year = state.yearInfo.year
      const modelSelections = _.get(
        state.yearModelSelections,
        year,
        _.clone(defaultModelSelections)
      ) as IModelSelection

      const months = Array.isArray(month) ? month : [month]
      months.forEach((m) => {
        modelSelections[m] = modelId
        state.latestMonthSelection.push(m)
      })

      state.yearModelSelections[year] = modelSelections
    },
    setYear: (state, action: PayloadAction<YearInfo>) => {
      state.yearInfo = action.payload
    },
    resetLatestMonthSelection: (state) => {
      state.latestMonthSelection = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrateStore, (_state, action) => {
      return { ...initialState, ...action.payload.modelPlusActual }
    })
  }
})

export const { changeModelSelections, setYear, resetLatestMonthSelection } =
  modelPlusActualSlice.actions

export const modelPlusActualReducer = modelPlusActualSlice.reducer

export const selectModelSelections = (state: RootState) =>
  _.get(
    state.modelPlusActual.yearModelSelections,
    state.modelPlusActual.yearInfo.year,
    defaultModelSelections
  )

export const selectYear = (state: RootState) =>
  _.get(state, ['modelPlusActual', 'yearInfo'], initialState.yearInfo)

export const selectLatestMonthSelection = (state: RootState) =>
  state.modelPlusActual.latestMonthSelection

import React, { useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Link, Typography } from '@mui/material'

import ErrorBoundary from 'components/ErrorBoundary'
import LazyComponent from 'components/core/LazyComponent'
import ExternalComponentViewer from 'pages/component-management/viewer'

import { COMP, isWidgetSourceExternal, isWidgetSourceStatic } from '../../data'
import { ViewMode, isViewModeEdit, isViewModeParentView } from '../../types'
import { LayoutItem } from './freestyle-section'
import Chart from './widgets/chart'
import Divider from './widgets/divider'
import Kpi from './widgets/kpi'
import Table from './widgets/table'

interface LayoutComponentProps {
  layout_item: LayoutItem
  widget: any
  item_index: number
  rowHeight?: number
  mode: ViewMode
  onRemoveItem: any
  parentHasComponent?: boolean
  onWidgetClick?: (widget: any, mode: ViewMode) => void
  selected?: boolean
}

const LayoutComponent: React.FC<LayoutComponentProps> = ({
  layout_item,
  widget,
  item_index,
  rowHeight,
  mode,
  onRemoveItem,
  parentHasComponent,
  onWidgetClick,
  selected
}) => {
  const intermediateComponent = useMemo(() => {
    // TODO: only load the required files
    const modules: Record<string, any> = import.meta.glob(
      '../../../../../layout-components/**/*.tsx'
    )

    if (isWidgetSourceStatic(widget.source)) {
      const minimalProps = {
        rowHeight: rowHeight,
        layout_item: layout_item,
        widget: widget,
        item_index: item_index
      }

      const configPath = `../../../../../layout-components/${widget.scope}/${widget.name}/index.tsx`

      const Component = LazyComponent(modules[configPath])
      return <Component {...minimalProps} />
    }

    if (isWidgetSourceExternal(widget.source)) {
      return <ExternalComponentViewer id={widget.component_id} genre={widget.genre} />
    }

    switch (widget.genre) {
      case COMP.WIDGET.SPECIFIC.TABLE.GENRE:
        return (
          <Table
            rowHeight={rowHeight}
            layout_item={layout_item}
            widget={widget}
            item_index={item_index}
          />
        )
      case COMP.WIDGET.SPECIFIC.CHART.GENRE:
        return <Chart layout_item={layout_item} widget={widget} item_index={item_index} />
      case COMP.WIDGET.SPECIFIC.KPI.GENRE:
        return (
          <Kpi
            layout_item={layout_item}
            widget={{ label: 'KPI', value: '23', unit: 'KG' }}
            item_index={item_index}
          />
        )
      case COMP.WIDGET.SPECIFIC.DIVIDER.GENRE:
        return <Divider layout_item={layout_item} widget={widget} item_index={item_index} />
      default:
        return <div>Unknown</div>
    }
  }, [item_index, layout_item, rowHeight, widget])

  const onComponentClick = () => {
    if (isViewModeParentView(mode) && parentHasComponent) {
      return
    }

    onWidgetClick && onWidgetClick(widget, mode)
  }

  return (
    <div
      onClick={onComponentClick}
      className={`layout-widget flex flex-col rounded-md shadow-brand`}
    >
      {isViewModeEdit(mode) && (
        <div className='layout-title-bar'>
          <Link
            component={RouterLink}
            to={`/admin/component-management/${widget.component_id}`}
            target='_blank'
            onClick={(e) => e.stopPropagation()}
          >
            {widget.name}
          </Link>{' '}
          | {layout_item.i} | {widget.source}
        </div>
      )}

      <div
        className={`nonDraggable ${isViewModeParentView(mode) && (parentHasComponent ? 'matching-comp' : 'non-matching-comp')} ${selected ? 'comp-selected' : 'comp-not-selected'}`}
        style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column' }}
      >
        <ErrorBoundary
          fallback={
            <Typography color='textSecondary'>
              Some error occurred while rendering the widget
            </Typography>
          }
        >
          {intermediateComponent}
        </ErrorBoundary>
      </div>

      {isViewModeEdit(mode) && (
        <IconButton
          className='remove'
          aria-label='close'
          onClick={() => onRemoveItem(layout_item)}
          sx={{
            zIndex: 9,
            position: 'absolute',
            right: 12,
            top: 5
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  )
}

export default LayoutComponent

export const shouldRender = (
  renderer: string,
  pageFilters: any,
  currentFilter: any,
  layoutStructure: any[],
  key: string
) => {
  // console.log('current_renderer', renderer, 'current_key', key, 'current_filter', currentFilter, 'current_layout_structure', layoutStructure);

  switch (renderer) {
    case 'component':
      return shouldRenderComponentFilter(pageFilters, currentFilter, layoutStructure, key)
    default:
      return shouldRenderPageFilter(pageFilters, currentFilter, layoutStructure, key)
  }
}

export const shouldRenderPageFilter = (
  pageFilters: any,
  currentFilter: any,
  layoutStructure: any[],
  key: string
) => {
  // console.log('page', 'current_key', key, 'page_filters', pageFilters, 'current_filter', currentFilter, 'current_layout_structure', layoutStructure);

  // const keysList = Object.keys(pageFilters);
  // const pageTypeCounts = getTypeCounts(pageFilters);
  // console.log(keysList, pageTypeCounts);
  // WIP
  return true
}

export const shouldRenderComponentFilter = (
  pageFilters: any,
  currentFilter: any,
  layoutStructure: any[],
  key: string
) => {
  return true
}

export const getTypeCounts = (filterObj: any) => {
  const typeCounts: any = {}

  for (const key in filterObj) {
    const filter = filterObj[key]
    const type = filter.type || 'default'
    typeCounts[type] = (typeCounts[type] || 0) + 1
  }

  return typeCounts
}

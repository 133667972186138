import * as am5 from '@amcharts/amcharts5'

import {
  DATA_LABEL_BULLETS,
  Display,
  Position,
  isDisplayHorizontal,
  isPositionInside
} from '../types/data-types'

export default function dataLabelsBulletsFactory({
  axisKey,
  numberFormat = '#.#a',
  position = Position.OUTSIDE,
  display = Display.VERTICAL,
  color = '#000000'
}: {
  axisKey: string
  numberFormat?: string
  position?: Position
  display?: Display
  color?: string
}) {
  const bulletProps: Partial<am5.IBulletSettings> = {}
  const spriteProps: Partial<am5.ILabelSettings> = {}

  if (isDisplayHorizontal(display)) {
    bulletProps.locationX = 1
    bulletProps.locationY = 0.5

    if (isPositionInside(position)) {
      spriteProps.centerX = am5.p100
      spriteProps.centerY = am5.p50
    } else {
      spriteProps.centerY = am5.p50
    }
  } else {
    bulletProps.locationY = 1
    bulletProps.locationX = 0.5

    if (isPositionInside(position)) {
      spriteProps.centerX = am5.p50
    } else {
      spriteProps.centerX = am5.p50
      spriteProps.centerY = am5.p100
    }
  }

  const dataLabelBulletsFnWithName = {
    [DATA_LABEL_BULLETS]: function (root: am5.Root, _series: am5.Series, dataItem: any) {
      return am5.Bullet.new(root, {
        userData: DATA_LABEL_BULLETS,
        ...bulletProps,
        sprite: am5.Label.new(root, {
          ...spriteProps,
          text: root.numberFormatter.format(dataItem.dataContext[axisKey], numberFormat),
          fontSize: '9px',
          fill: am5.color(color)
        })
      })
    }
  }[DATA_LABEL_BULLETS]

  return dataLabelBulletsFnWithName
}

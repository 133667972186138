'use client'

import * as React from 'react'

import * as TogglePrimitive from '@radix-ui/react-toggle'

import { cn } from 'utils/style-utils'

type Variant = keyof typeof variantClasses

const variantClasses = {
  primary: 'bg-white text-primary-darker'
}

interface ToggleProps extends React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> {
  variant?: Variant
}

const Toggle = React.forwardRef<React.ElementRef<typeof TogglePrimitive.Root>, ToggleProps>(
  ({ className, variant = 'primary', ...props }, ref) => {
    return (
      <TogglePrimitive.Root
        ref={ref}
        className={cn(
          variantClasses[variant],
          'h-6',
          'p-0',
          'inline-flex cursor-pointer items-center justify-center',
          'rounded-[0.250rem] border-none',
          'hover:border-primary-darker hover:bg-grey',
          // 'focus:border-black-light focus:border-[2px]', // Not implemented right now
          'disabled:pointer-events-none disabled:bg-grey-lighter disabled:text-grey-dark',
          'data-[state=on]:bg-primary-darker data-[state=on]:text-white',
          className
        )}
        {...props}
      />
    )
  }
)

Toggle.displayName = TogglePrimitive.Root.displayName

export { Toggle, type Variant }

import { useMutation, useQueryClient } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'
import { errorSnack, successSnack } from 'utils/snackbar-utils'

import { ICustomField } from '../types/query-builder-types'

export const useAddCustomField = () => {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const business_id = user?.business_id!

  return useMutation({
    mutationFn: ({ body }: { body: Omit<ICustomField, 'id'> }) => {
      return axiosServices.post(`/businesses/${business_id}/custom_fields`, body)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['fetch-models-detail', business_id] })
      successSnack('Custom Field Created Successfully')
    },
    onError() {
      errorSnack('Error Creating Custom Field')
    }
  })
}

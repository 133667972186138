import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { nanoid } from 'nanoid'
import {
  AxisPosition,
  ChartSubtitleType,
  IAxis,
  IAxisConfig,
  IChartConfig,
  IChartSettings,
  SeriesType,
  TooltipHeadingType,
  TooltipTitleType
} from 'pages/component-management/types/chart-builder-types'
import { IMetadata } from 'pages/component-management/types/component-types'
import { IResultRow } from 'pages/component-management/types/shared-types'
import { RootState } from 'store'
import { initComponentManagement } from 'store/actions/component-management'

const initialState: IChartConfig = {
  xAxes: [],
  yAxes: [],
  flipAxis: false,
  settings: {
    tooltip_heading_type: TooltipHeadingType.FromBaseAxis,
    tooltip_title_type: TooltipTitleType.FromSeries,
    chart_subtitle_type: ChartSubtitleType.UsePeriodLabel
  }
}

const defaultAxisConfig: IAxisConfig = {
  seriesType: '',
  axisPosition: AxisPosition.Normal
}

const updateAxisById = (axes: IAxis[], id: string, updateCallback: (axis: IAxis) => void) => {
  const index = _.findIndex(axes, (axis) => axis.id === id)
  if (index === -1) return

  updateCallback(axes[index])
}

const chartConfigSlice = createSlice({
  name: '@COMPONENT/CHART_CONFIG',
  initialState: initialState,
  reducers: {
    addXAxis: (state, action: PayloadAction<IAxis>) => {
      state.xAxes.push(_.assign({ id: nanoid() }, defaultAxisConfig, action.payload))
    },
    addYAxis: (state, action: PayloadAction<IAxis>) => {
      state.yAxes.push(_.assign({ id: nanoid() }, defaultAxisConfig, action.payload))
    },
    toggleFlipAxis: (state) => {
      state.flipAxis = !state.flipAxis
    },
    replaceXAxes: (state, action: PayloadAction<IAxis[]>) => {
      state.xAxes = action.payload
    },
    replaceYAxes: (state, action: PayloadAction<IAxis[]>) => {
      state.yAxes = action.payload
    },
    removeXAxis: (state, action: PayloadAction<string>) => {
      state.xAxes = state.xAxes.filter((axis) => axis.id !== action.payload)
    },
    removeYAxis: (state, action: PayloadAction<string>) => {
      state.yAxes = state.yAxes.filter((axis) => axis.id !== action.payload)
    },
    // Y-axis actions
    changeSeriesType: (state, action: PayloadAction<{ id: string; seriesType: SeriesType }>) => {
      const { id, seriesType } = action.payload
      updateAxisById(state.yAxes, id, (axis) => {
        axis.seriesType = seriesType
      })
    },
    changeAxisPosition: (
      state,
      action: PayloadAction<{ id: string; axisPosition: AxisPosition }>
    ) => {
      const { id, axisPosition } = action.payload
      updateAxisById(state.yAxes, id, (axis) => {
        axis.axisPosition = axisPosition
      })
    },
    updateYAxisData: (state, action: PayloadAction<Partial<IAxis> & { id: string }>) => {
      const { id, ...rest } = action.payload
      updateAxisById(state.yAxes, id, (axis) => _.assign(axis, rest))
    },
    syncXAxisLabels: (
      state,
      action: PayloadAction<{ id: string; data: IResultRow[]; metadata: IMetadata }>
    ) => {
      const { id, data, metadata } = action.payload

      const tagsMapping = metadata.kpi_mapping

      updateAxisById(state.xAxes, id, (axis) => {
        const nodes = axis.intermediate_nodes || []
        const nodesFromData = _.uniq(_.map(data, axis.name!))

        for (const label of nodesFromData) {
          const value = _.get(tagsMapping, 'label', '') as string
          const found = nodes.find((l) => l.name === label)
          if (found) {
            found.value = value
            continue
          }
          nodes.push({ name: label as string, value })
        }

        axis.intermediate_nodes = nodes
      })
    },
    clearXAxisLabels: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload
      updateAxisById(state.xAxes, id, (axis) => {
        axis.intermediate_nodes = []
      })
    },
    updateXAxisData: (state, action: PayloadAction<Partial<IAxis> & { id: string }>) => {
      const { id, ...rest } = action.payload
      updateAxisById(state.xAxes, id, (axis) => _.assign(axis, rest))
    },
    updateSettingsData: (state, action: PayloadAction<Partial<IChartSettings>>) => {
      _.assign(state.settings, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(initComponentManagement, (_state, action) => {
      const tableConfig = _.get(action.payload, 'component_attributes.chart_config', initialState)
      if (!tableConfig.settings) {
        tableConfig.settings = initialState.settings
      }
      return _.assign({}, initialState, tableConfig)
    })
  }
})

// Actions
export const {
  addXAxis,
  addYAxis,
  toggleFlipAxis,
  replaceXAxes,
  replaceYAxes,
  removeXAxis,
  removeYAxis,
  changeSeriesType,
  changeAxisPosition,
  updateYAxisData,
  syncXAxisLabels,
  clearXAxisLabels,
  updateXAxisData,
  updateSettingsData
} = chartConfigSlice.actions

// Reducer
export const chartConfigReducer = chartConfigSlice.reducer

// Selectors
export const selectChartConfig = (state: RootState) => state.component.chartConfig

export const waterfallSeriesPresent = (state: RootState) => {
  const config = selectChartConfig(state)
  return _.some(config.yAxes, (axis) => axis.seriesType === SeriesType.Waterfall)
}

export const selectXAxes = (state: RootState) => selectChartConfig(state).xAxes

export const selectYAxes = (state: RootState) => selectChartConfig(state).yAxes

export const selectFlipAxis = (state: RootState) => selectChartConfig(state).flipAxis

export const selectSettings = (state: RootState) => selectChartConfig(state).settings

import { useMutation, useQueryClient } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import { queryErrorToast, querySuccessToast } from 'queries/query-toasts'
import axiosServices from 'utils/axios'

export const useDeactivatePage = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ pageId }: { pageId?: number }) => {
      return axiosServices.post(`/businesses/${user?.business_id}/pages/${pageId}/toggle_status`, {
        page: {
          status: 'deactivate'
        }
      })
    },
    onSuccess: (data, variables, context) => {
      querySuccessToast('Page Deactivated Successfully!')

      queryClient.invalidateQueries({ queryKey: ['fetch-pages-tree', user?.business_id] })
      queryClient.invalidateQueries({
        queryKey: ['fetch-page', user?.business_id, variables.pageId]
      })
      queryClient.invalidateQueries({ queryKey: ['fetch-browsable-pages', user?.business_id] })
      queryClient.invalidateQueries({ queryKey: ['fetch-navigation-tree', user?.business_id] })
    },
    onError: (error, variables) => {
      queryErrorToast(['Error Deactivating Page', error.message])
    }
  })
}

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography
} from '@mui/material'

import { IconButton } from '@components/core/icon-button'
import { Cancel, DragIndicator, Icon } from '@components/icons'

import { useDispatch, useSelector } from '@store/index'
import {
  addXAxis,
  addYAxis,
  removeXAxis,
  removeYAxis,
  replaceXAxes,
  replaceYAxes,
  selectFlipAxis,
  selectSettings,
  selectXAxes,
  selectYAxes,
  toggleFlipAxis,
  updateSettingsData
} from '@store/slices/component/chart-config'

import DropdownList from 'components/dropdown-list'
import { SortableList } from 'components/sortable-list'

import GenreDropdown from '../common/genre-dropdown'
import { useQueryResultContext } from '../contexts/query-result-context'
import { IAxis } from '../types/chart-builder-types'
import ChartSubtitle from './chart-subtitle'
import { DefaultDataLabelsSettings } from './data-labels-settings'
import { SeriesArrangement } from './series-arrangement'
import { DefaultTooltipSettings } from './tooltip-settings'

function SortableAxisItems({
  axes,
  onChangeAxes,
  onRemoveAxis
}: {
  axes: IAxis[]
  onChangeAxes: (axes: IAxis[]) => void
  onRemoveAxis: (id: string) => void
}) {
  return (
    <SortableList
      items={axes}
      setItems={onChangeAxes}
      renderItem={({ draggableProps, setNodeRef, style, item, id }) => (
        <ListItem
          ref={setNodeRef}
          divider={true}
          secondaryAction={
            <IconButton onClick={() => onRemoveAxis(id as string)}>
              <Icon icon={<Cancel />} />
            </IconButton>
          }
          disablePadding
          sx={{ ...style }}
        >
          <ListItemButton>
            <IconButton className='px-0' {...draggableProps}>
              <Icon icon={<DragIndicator />} />
            </IconButton>
            <ListItemText sx={{ pl: 1 }} id={id as string} primary={item.label} />
          </ListItemButton>
        </ListItem>
      )}
      WrapperComponent={({ children }) => <List sx={{ width: '100%' }}>{children}</List>}
    />
  )
}

export default function ChartSettings() {
  const { dimensions, measures, dates } = useQueryResultContext()

  const dispatch = useDispatch()
  const flipAxis = useSelector(selectFlipAxis)
  const settings = useSelector(selectSettings)
  const yAxes = useSelector(selectYAxes)
  const xAxes = useSelector(selectXAxes)

  return (
    <Box>
      <Typography variant='h5'>Component Type And Data</Typography>
      <Typography variant='body1'>Arrange values to modify the layer order</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GenreDropdown />
        </Grid>
        <Grid item xs={12}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='h5'>{flipAxis ? 'X-Axis' : 'Y-Axis'}</Typography>
            <Stack direction='row' spacing={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={settings.stackAxes || false}
                    onChange={(e) => {
                      dispatch(updateSettingsData({ stackAxes: e.target.checked }))
                    }}
                  />
                }
                label='Stack'
              />
              <Button variant='outlined' onClick={() => dispatch(toggleFlipAxis())}>
                Flip Axis
              </Button>
              <DropdownList
                label='Add'
                options={[
                  ...measures.map((data) => ({ ...data, label: data.name, id: data.name })),
                  { name: 'placeholder_num', label: 'placeholder_num', id: 'placeholder_num' }
                ]}
                onChange={({ id, ...rest }) => dispatch(addYAxis(rest as IAxis))}
              />
            </Stack>
          </Stack>

          <SortableAxisItems
            axes={yAxes}
            onChangeAxes={(axes: IAxis[]) => dispatch(replaceYAxes(axes))}
            onRemoveAxis={(id: string) => dispatch(removeYAxis(id))}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='h5'>{flipAxis ? 'Y-Axis' : 'X-Axis'}</Typography>
            <Stack direction='row' spacing={1}>
              <DropdownList
                label='Add'
                options={[
                  ...dates.map((data) => ({ ...data, label: data.name, id: data.name })),
                  ...dimensions.map((data) => ({ ...data, label: data.name, id: data.name })),
                  { name: 'placeholder_date', label: 'placeholder_date', id: 'placeholder_date' },
                  { name: 'placeholder_text', label: 'placeholder_text', id: 'placeholder_text' }
                ]}
                onChange={({ id, ...rest }) => dispatch(addXAxis(rest as IAxis))}
              />
            </Stack>
          </Stack>

          <SortableAxisItems
            axes={xAxes}
            onChangeAxes={(axes: IAxis[]) => dispatch(replaceXAxes(axes))}
            onRemoveAxis={(id: string) => dispatch(removeXAxis(id))}
          />
        </Grid>
        <ChartSubtitle />
        <DefaultTooltipSettings />
        <DefaultDataLabelsSettings />
        <SeriesArrangement />
      </Grid>
    </Box>
  )
}

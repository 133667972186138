import { useEffect, useState } from 'react'

import { Checkbox } from '@mui/material'

import { Badge } from '@core/badge'
import Button from '@core/button'

import { useDispatch, useSelector } from '@store/index'
import {
  chooseField,
  selectIsFieldSelected,
  selectShowForeignKeys
} from '@store/slices/component/query-config'

import { IColumnData, QueryBuilderPlacement } from '../../types/query-builder-types'
import FieldConfigs from './field-configs'

export default function NormalField({
  name,
  newName,
  field_type,
  association_name,
  parent,
  custom,
  configureField,
  id: fieldId,
  placement
}: {
  name: string
  newName?: string
  field_type: string
  association_name?: string
  parent: string
  custom: boolean | undefined
  id?: number
  configureField: (data?: IColumnData) => void
  placement?: QueryBuilderPlacement
}) {
  const dispatch = useDispatch()
  const isPlacedOnMain = !placement
  const showForeignKeys = useSelector(selectShowForeignKeys)
  const isFieldSelected = useSelector(selectIsFieldSelected(name, parent, placement))
  const [checked, setChecked] = useState(isFieldSelected)

  useEffect(() => setChecked(isFieldSelected), [isFieldSelected])

  const labelId = `${parent}-${name}`

  const handleChange = () => {
    dispatch(
      chooseField({
        field: { name, field_type, custom, id: fieldId, configs: [{}] },
        path: parent,
        placement
      })
    )
    setChecked((x) => !x)
  }

  const displayName = newName ? `${newName} / ${name}` : name

  if (!checked && association_name && !showForeignKeys) {
    return null
  }

  return (
    <>
      <div className='flex items-center justify-between p-2'>
        <div className='flex items-start space-x-2'>
          <div className='flex-1'>
            <span id={labelId} className='block text-button font-medium text-gray-darker'>
              {displayName} ({field_type})
              {custom && (
                <Badge className='ml-2 mt-1 xl:inline' variant='outline'>
                  Custom
                </Badge>
              )}
            </span>
          </div>
        </div>
        <div className='flex items-center space-x-2'>
          {custom && isPlacedOnMain && (
            <Button
              size='extra-small'
              onClick={() => configureField({ id: fieldId!, name, field_type, custom })}
            >
              Edit
            </Button>
          )}
          <Checkbox
            onChange={handleChange}
            checked={checked}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </div>
      </div>
      {checked && isPlacedOnMain && (
        <FieldConfigs {...{ labelId, field_type, parent, name, placement }} />
      )}
    </>
  )
}

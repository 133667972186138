const Separator = ({
  height = '1.5rem',
  width = '0.063rem',
  color = '#F2F2F2',
  marginTop = '0.25rem'
}) => {
  return <div className='separator' style={{ height, width, backgroundColor: color, marginTop }} />
}

export default Separator

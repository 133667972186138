import { PayloadAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'

import { rehydrateStore } from '@store/actions/rehydrate-store'
import { RootState } from '@store/index'
import { WithID, createPageAction } from '@store/page-util'

import { IParameterizedFiltersParameters } from 'pages/component-management/types/component-types'

interface ParameterizedFilters {
  parameterizedValues: {
    [key: string]: {
      value: string | number | boolean
    }
  }
}

const defaultValue: ParameterizedFilters = { parameterizedValues: {} }

const parameterizedFiltersAdapter = createEntityAdapter<WithID<ParameterizedFilters>>()

const initialState = parameterizedFiltersAdapter.getInitialState()

const findOrCreateEntity = (state: typeof initialState, id: string) => {
  let entity = state.entities[id]
  if (!entity) {
    parameterizedFiltersAdapter.addOne(state, _.assign({ id }, _.cloneDeep(defaultValue)))
    entity = state.entities[id]
  }
  return entity
}

const parameterizedFiltersSlice = createSlice({
  name: '@COMPONENT/PARAMETERIZED_FILTERS',
  initialState,
  reducers: {
    setParameterizedFilters: (
      state,
      action: PayloadAction<WithID<{ parameterValues: IParameterizedFiltersParameters }>>
    ) => {
      const { id, parameterValues } = action.payload
      const entity = findOrCreateEntity(state, id)

      _.assign(
        entity.parameterizedValues,
        _.transform(
          parameterValues,
          (result, value, key) => {
            result[key] = { value }
          },
          {} as ParameterizedFilters['parameterizedValues']
        )
      )
    },
    removeParameterizedFilters: (state, action: PayloadAction<WithID<{ keys: string[] }>>) => {
      const { id, keys } = action.payload
      const entity = findOrCreateEntity(state, id)

      _.forEach(keys, (key) => {
        if (_.includes(_.keys(entity.parameterizedValues), key))
          delete entity.parameterizedValues[key]
      })
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrateStore, (_state, action) => {
      return { ...initialState, ...action.payload.component?.parameterizedFilters }
    })
  }
})

// Actions
export const setParameterizedFilters = createPageAction(
  parameterizedFiltersSlice.actions.setParameterizedFilters
)
export const removeParameterizedFilters = createPageAction(
  parameterizedFiltersSlice.actions.removeParameterizedFilters
)

// Reducer
export const parameterizedFiltersReducer = parameterizedFiltersSlice.reducer

// Selectors
const getParameterizedFiltersState = (id: string) => (state: RootState) =>
  _.get(state, ['component', 'parameterizedFilters', 'entities', id], defaultValue)

export const selectParameterizedFilters = (id: string) =>
  createSelector(getParameterizedFiltersState(id), (parameterizedFilters: ParameterizedFilters) =>
    _.transform(
      _.get(parameterizedFilters, 'parameterizedValues'),
      (result, value, key) => {
        result[key] = value.value
      },
      {} as IParameterizedFiltersParameters
    )
  )

import {
  BasicFieldConfig,
  ChargerFormFieldConfig,
  OneOfFieldConfig,
  isFieldTypeOneOf
} from '../types'

export const transformDataBeforeSubmit = (
  data: Record<string, any>,
  fieldsConfig: ChargerFormFieldConfig[]
) => {
  return _.reduce(
    fieldsConfig,
    (acc, field) => {
      if (isFieldTypeOneOf(field.type)) {
        const oneOfField = field as OneOfFieldConfig
        const selectedSection = _.find(
          oneOfField.sections,
          (section) => section.value === data[oneOfField.name].value
        )
        if (!selectedSection) return acc

        const transformedSectionData = transformDataBeforeSubmit(
          data[oneOfField.name].data,
          selectedSection.formFields
        )

        acc = _.assign({}, acc, {
          [oneOfField.name]: { value: data[oneOfField.name].value, data: transformedSectionData }
        })
        return acc
      } else {
        const basicField = field as BasicFieldConfig
        acc[basicField.actualName || basicField.name] = data[basicField.name]
        return acc
      }
    },
    {} as Record<string, any>
  )
}

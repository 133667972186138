import { CheckboxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

import { ExtendedStyleProps } from 'types/extended'
import getColors from 'utils/getColors'

function getColorStyle({ color, theme }: ExtendedStyleProps) {
  const colors = getColors(theme, color)
  const { main, dark } = colors

  return {
    '&:hover': {
      borderRadius: '50%',
      backgroundColor: theme.palette.grey['100'],
      '& .icon': {
        borderColor: main
      }
    },
    '&.Mui-focusVisible': {
      outline: `2px solid ${dark}`,
      outlineOffset: -4
    }
  }
}

// ==============================|| CHECKBOX - SIZE STYLE ||============================== //

interface CheckboxSizeProps {
  size: number | string
  fontSize: number
  position: number
}

function getSizeStyle(size?: CheckboxProps['size']): CheckboxSizeProps {
  switch (size) {
    case 'small':
      return { size: '1rem', fontSize: 1, position: 1 }
    case 'large':
      return { size: 24, fontSize: 1.6, position: 2 }
    case 'medium':
    default:
      return { size: 20, fontSize: 1.4, position: 2 }
  }
}

function getPadding(size?: CheckboxProps['size']) {
  switch (size) {
    case 'small':
      return 2
    case 'large':
      return 9
    case 'medium':
    default:
      return 4
  }
}

// ==============================|| CHECKBOX - STYLE ||============================== //

function checkboxStyle(size?: CheckboxProps['size']) {
  const sizes: CheckboxSizeProps = getSizeStyle(size)

  return {
    '& svg': {
      width: sizes.size,
      height: sizes.size,
      '& .filled': {
        fontSize: `${sizes.fontSize}rem`,
        top: -sizes.position,
        left: -sizes.position
      }
    },
    padding: getPadding(size)
  }
}

// ==============================|| OVERRIDES - CHECKBOX ||============================== //

export default function Checkbox(theme: Theme) {
  const { palette } = theme

  return {
    MuiCheckbox: {
      defaultProps: {
        icon: (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
          >
            <rect x='1' y='1' width='16' height='16' rx='1' stroke='#C6C6C6' strokeWidth='2' />
          </svg>
        ),
        checkedIcon: (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
          >
            <rect width='18' height='18' rx='2' fill='#2170BA' />
            <path d='M3.5 8.5L7.5 12.5L15.5 4.5' stroke='white' strokeWidth='2' />
          </svg>
        ),
        indeterminateIcon: (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
          >
            <path
              d='M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM14 10H4V8H14V10Z'
              fill='#2170BA'
            />
          </svg>
        )
      },
      styleOverrides: {
        root: {
          borderColor: palette.primary.main,
          borderRadius: 0,
          color: palette.secondary[300],
          '&.MuiCheckbox-sizeSmall': {
            ...checkboxStyle('small')
          },
          '&.MuiCheckbox-sizeMedium': {
            ...checkboxStyle('medium')
          },
          '&.MuiCheckbox-sizeLarge': {
            ...checkboxStyle('large')
          }
        },
        colorPrimary: getColorStyle({ color: 'primary', theme }),
        colorSecondary: getColorStyle({ color: 'secondary', theme }),
        colorSuccess: getColorStyle({ color: 'success', theme }),
        colorWarning: getColorStyle({ color: 'warning', theme }),
        colorInfo: getColorStyle({ color: 'info', theme }),
        colorError: getColorStyle({ color: 'error', theme })
      }
    }
  }
}

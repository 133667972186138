import { ICellRendererParams } from 'ag-grid-community'

import { Cancel, Check, Icon } from '@components/icons'

/*
 mismatch found is true -> pk invalid
 mismatch found is false -> pk valid
 mismatch found is null -> pk mismatch check not run
*/
export const PkValidCellRenderer = (params: ICellRendererParams) => (
  <div className='flex size-full items-center justify-center'>
    {params.value === true && <Icon icon={<Cancel />} className='text-error-darker' />}
    {params.value === false && <Icon icon={<Check />} className='text-success-darker' />}
    {params.value === null && <></>}
  </div>
)

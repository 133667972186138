import { FilterModel, IServerSideGetRowsParams } from 'ag-grid-community'

import { QueryClient } from '@tanstack/react-query'

import { fetchComponents } from '../queries/fetch-components'

// TODO: Make ServerSideDatasource Fetching Generic
export default class ServerSideDatasource {
  client: QueryClient
  initialFilterModel: FilterModel
  constructor(client: QueryClient, initialFilterModel: FilterModel) {
    this.client = client
    this.initialFilterModel = initialFilterModel
  }

  getRows(params: IServerSideGetRowsParams) {
    const { startRow, endRow, filterModel, sortModel } = params.request
    const items = endRow! - startRow!
    const page = startRow! / items + 1

    const { businessId: businessIdModel, ...filterModelRest } = _.assign(
      {},
      this.initialFilterModel,
      filterModel as FilterModel
    )

    const filterParams = Object.keys(filterModelRest).reduce(
      (acc, key) => {
        const filterModel = filterModelRest[key]

        if (filterModel.filterType === 'set') {
          acc[key] = filterModel.values.join(',')
        } else {
          acc[key] = filterModel.filter
        }
        return acc
      },
      {} as Record<string, string>
    )

    const businessId = businessIdModel.filter

    const sort = sortModel.reduce((acc, { colId, sort }) => {
      if (acc) acc += ','
      acc += `${colId}:${sort}`
      return acc
    }, '')

    this.client
      .fetchQuery({
        queryKey: ['fetch-components', businessId, { ...filterParams, items, page, sort }],
        queryFn: () => {
          return fetchComponents(businessId, { ...filterParams, items, page, sort })
        }
      })
      .then((response) => {
        const { results: rows, count: lastRow } = response
        params.success({ rowData: rows, rowCount: lastRow })
      })
      .catch(() => {
        params.fail()
      })
  }
}

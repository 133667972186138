import { useEffect } from 'react'
import { useNavigate } from 'react-router'

export const RedirectTo = ({ path }: { path: string }) => {
  const navigate = useNavigate()

  useEffect(() => {
    // Note: Triggering navigate after completion of current event loop
    // because simply calling navigate does not seems to work with dynamically created routes.
    setTimeout(() => navigate(path, { replace: true }), 0)
  }, [navigate, path])

  return null
}

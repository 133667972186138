import { Theme } from '@mui/material/styles'

export default function FormHelperText(theme: Theme) {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontWeight: 400,
          '&.Mui-error': {
            color: theme.palette.error.dark
          }
        }
      }
    }
  }
}

import { enumToOptions } from '@utils/obj-utils'
import { humanizeFieldName } from '@utils/string-utils'

import { DataPill } from '@components/component-management/DataPill'
import Button from '@components/core/button'
import { Checkbox } from '@components/core/checkbox'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger
} from '@components/core/dropdown-menu'
import { Label } from '@components/core/label'
import { Separator } from '@components/core/separator'
import { Select } from '@components/form/select'
import { Icon, Plus } from '@components/icons'

import { useDispatch, useSelector } from '@store/index'
import {
  addXAxis,
  addYAxis,
  changeSeriesType,
  removeXAxis,
  removeYAxis,
  replaceXAxes,
  replaceYAxes,
  selectFlipAxis,
  selectSettings,
  selectXAxes,
  selectYAxes,
  toggleFlipAxis,
  updateSettingsData
} from '@store/slices/component/chart-config'

import { IModelFieldDataType } from 'pages/component-management/types/query-builder-types'

import { GenreDropdownWithLabel } from '../common/genre-dropdown-with-label'
import { Title } from '../common/title'
import { useQueryResultContext } from '../contexts/query-result-context'
import { IAxis, SeriesType } from '../types/chart-builder-types'
import { convertHeaderToAxis } from '../utils/convert-header-to-axis'
import { DefaultDataLabelsSettings } from './data-labels-settings'
import { SeriesArrangement } from './series-arrangement'
import { SortableAxisItems } from './sortable-axis-items'
import { DefaultTooltipSettings } from './tooltip-settings'

const seriesTypes = enumToOptions(SeriesType)

export const ChartSettings = () => {
  const { dimensions, dates, measures } = useQueryResultContext()

  const dispatch = useDispatch()
  const flipAxis = useSelector(selectFlipAxis)
  const settings = useSelector(selectSettings)
  const yAxes = useSelector(selectYAxes)
  const xAxes = useSelector(selectXAxes)

  return (
    <div>
      <GenreDropdownWithLabel />
      <Separator className='my-2' />
      <div className='my-2 flex items-center justify-between gap-4'>
        <Title>{flipAxis ? 'X-Axis' : 'Y-Axis'}</Title>
        <div className='flex gap-2'>
          <div className='flex items-center gap-2'>
            <Label className='text-submenu font-normal' htmlFor='chart-split-axis'>
              Split Graphs
            </Label>
            <Checkbox
              id='chart-split-axis'
              className='m-2'
              checked={settings.stackAxes || false}
              onCheckedChange={(value) =>
                dispatch(updateSettingsData({ stackAxes: value as boolean }))
              }
            />
          </div>
          <Button variant='outline' onClick={() => dispatch(toggleFlipAxis())}>
            Flip Axis
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant='outline' className='pr-1'>
                Add <Icon icon={<Plus />} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align='end'>
              {_.map(_.map(measures, convertHeaderToAxis), (axis) => {
                const selected = _.find(yAxes, (yAxis) => _.isEqual(yAxis.name, axis.label))
                return (
                  <DropdownMenuCheckboxItem
                    key={axis.label}
                    checked={!!selected}
                    onCheckedChange={() =>
                      selected
                        ? dispatch(removeYAxis(selected.id))
                        : dispatch(addYAxis(_.omit(axis, 'id') as unknown as IAxis))
                    }
                  >
                    {humanizeFieldName(axis.label)}
                  </DropdownMenuCheckboxItem>
                )
              })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <Separator className='my-2' />
      {yAxes.length ? (
        <SortableAxisItems
          axes={yAxes}
          onChangeAxes={(axes: IAxis[]) => dispatch(replaceYAxes(axes))}
          onRemoveAxis={(id: string) => dispatch(removeYAxis(id))}
          secondary={({ item }) => (
            <Select
              className='w-20'
              options={seriesTypes}
              value={item.seriesType || ''}
              onChange={(value) =>
                dispatch(changeSeriesType({ id: item.id, seriesType: value as SeriesType }))
              }
              fullWidth={false}
            />
          )}
        />
      ) : (
        <DataPill label='Example' dataType={IModelFieldDataType.INTEGER} disabled />
      )}
      <Separator className='my-2' />
      <div className='my-2 flex items-center justify-between'>
        <Title>{flipAxis ? 'Y-Axis' : 'X-Axis'}</Title>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant='outline' className='pr-1'>
              Add <Icon icon={<Plus />} />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align='end'>
            {_.map(_.map(_.concat(dates, dimensions), convertHeaderToAxis), (axis) => {
              const selected = _.find(xAxes, (xAxis) => _.isEqual(xAxis.name, axis.name))
              return (
                <DropdownMenuCheckboxItem
                  key={axis.label}
                  checked={!!selected}
                  onCheckedChange={() =>
                    selected
                      ? dispatch(removeXAxis(selected.id))
                      : dispatch(addXAxis(_.omit(axis, 'id') as unknown as IAxis))
                  }
                >
                  {humanizeFieldName(axis.label)}
                </DropdownMenuCheckboxItem>
              )
            })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <Separator className='my-2' />
      {xAxes.length ? (
        <SortableAxisItems
          axes={xAxes}
          onChangeAxes={(axes: IAxis[]) => dispatch(replaceXAxes(axes))}
          onRemoveAxis={(id: string) => dispatch(removeXAxis(id))}
        />
      ) : (
        <DataPill label='Example' dataType={IModelFieldDataType.INTEGER} disabled />
      )}
      <DefaultTooltipSettings />
      <DefaultDataLabelsSettings />
      <SeriesArrangement />
    </div>
  )
}

import { Theme } from '@mui/material/styles'

export default function Toolbar(theme: Theme) {
  return {
    MuiToolbar: {
      styleOverrides: {
        root: {}
      }
    }
  }
}

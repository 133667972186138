import { ElementType } from 'react'

import { Box, ButtonBase } from '@mui/material'
import { useTheme } from '@mui/material/styles'

interface ActionItemProps {
  icon: ElementType
  onClick?: () => void
  iconColor?: any
  hoverColor?: string
}

const ActionItem = ({
  iconColor = '',
  hoverColor = '',
  icon: IconComponent,
  onClick
}: ActionItemProps) => {
  const theme = useTheme()

  if (!iconColor) {
    iconColor = theme.palette.primary.main
  }

  const handleClick = () => {
    if (onClick) onClick()
  }

  return (
    <Box>
      <ButtonBase
        sx={{
          px: 2,
          py: 1,
          bgcolor: 'transparent',
          '&:hover': {
            bgcolor:
              hoverColor ||
              (theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter')
          },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2
          }
        }}
        aria-label='open profile'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <IconComponent className='text-primary' />
      </ButtonBase>
    </Box>
  )
}

export default ActionItem

import { useMemo } from 'react'

import ChartFactory from '../chart-builder/chart-modules-creator'
import { ComponentGenre, IMetadata } from '../types/component-types'
import { IResultRow } from '../types/shared-types'
import { ITreemapConfig } from '../types/treemap-builder-types'
import dataTransformer from './data-transformer'

export default function useChartPropsRetriever({
  data,
  metadata,
  config
}: {
  data: IResultRow[]
  metadata: IMetadata
  config: ITreemapConfig
}) {
  const chartProps = useMemo(() => {
    const { dimensions, measures, aggregate } = config

    // TODO: Move transformation logic to the backend
    const { transformedData } = dataTransformer({ data, dimensions, measures, aggregate, metadata })

    const factoryProps = ChartFactory.create({
      chartType: ComponentGenre.treemap,
      metadata: { ...metadata }
    })

    return _.assign({}, factoryProps, { data: transformedData })
  }, [data, metadata, config])

  return chartProps
}

import { ReactNode, useState } from 'react'

import ComponentWidget, {
  ComponentErrorWidget,
  ComponentWidgetProps
} from 'components/cards/component-widget'
import { nanoid } from 'nanoid'

import SkeletonChart from './SkeletonChart'
import { getLegendId, getLegendPlaceholderId } from './utils/get-container-id'

export function useChartSelector(defaultSelector?: string) {
  const [selector] = useState(() => defaultSelector || `chart-${nanoid()}`)

  return selector
}

interface ChartContainerProps {
  selector?: string
}

type ChartWidgetProps = ChartContainerProps & ComponentWidgetProps

function ChartContainer({ selector = 'chartdiv' }: ChartContainerProps) {
  return (
    <div className='relative flex h-full flex-col'>
      <div className='absolute z-20 h-0 w-full'>
        <div className='max-h-[20px] overflow-hidden hover:max-h-none' id={getLegendId(selector)} />
      </div>
      <div id={getLegendPlaceholderId(selector)} className='h-0' />
      <div className='min-h-[150px] w-full flex-1'>
        <div className='size-full' id={selector} />
      </div>
    </div>
  )
}

export function ChartWidget({ selector, ...rest }: ChartWidgetProps) {
  return (
    <ComponentWidget {...rest}>
      <ChartContainer selector={selector} />
    </ComponentWidget>
  )
}

export function ChartLoadingWidget({ title }: { title: string }) {
  return (
    <ComponentWidget title={title} loading>
      <SkeletonChart />
    </ComponentWidget>
  )
}

export function ChartNoDataWidget({ title }: { title: string }) {
  return (
    <ComponentWidget title={title}>
      <div className='flex size-full flex-1 items-center justify-center'>No Data</div>
    </ComponentWidget>
  )
}

export function ChartErrorWidget({
  title,
  errorMessage
}: {
  title: string
  errorMessage: ReactNode | string
}) {
  return <ComponentErrorWidget title={title} errorMessage={errorMessage} />
}

export default ChartContainer

import { ColDef, SortModelItem } from 'ag-grid-community'
import { IRowNode } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

export const allSiblingsExceptDummyNodes = (node: any) => {
  let children = null

  if (node.parent && node.parent.childrenAfterGroup) {
    children = node.parent.childrenAfterGroup
  }

  if (node.parent.parent && node.parent.parent.childrenAfterGroup) {
    children = node.parent.parent.childrenAfterGroup
  }

  if (!children) {
    return false
  }

  return children.filter((childNode: any) => {
    return !childNode.key.includes('$$$$')
  })
}

export const isLastRow = (node: any) => {
  const all_nodes = allSiblingsExceptDummyNodes(node)
  return all_nodes[all_nodes.length - 1].key === node.key
}

export const secondaryGroupElementHasSingleElement = (node: any, headerNames: string[]) => {
  return (
    node &&
    headerNames.includes(node.field) &&
    node.childrenAfterGroup.length === 1 &&
    node.childrenAfterGroup[0].key === ''
  )
}

export const findCurrentIndex = (parent: any, key: string) => {
  return parent.childrenAfterGroup.findIndex((item: any) => item.key === key)
}

export const dummyNode = (node: any) => {
  return node.key.includes('$$$$')
}

export const isHeaderNameAndSubHeaderKeySame = (
  node: any,
  header_key: string,
  headerName: string
) => {
  return (
    node &&
    node.field === header_key &&
    node.childrenAfterGroup.length === 1 &&
    headerName === node.childrenAfterGroup[0].key
  )
}

export const isLastItemInAGroup = (node: any, allowFooterNode: boolean = true) => {
  if (!node) {
    return false
  }

  const isLastItem =
    node.parent &&
    node.parent.childrenAfterGroup.length > 1 &&
    node.parent.childrenAfterGroup.length === node.childIndex + 1

  if (isLastItem && allowFooterNode) {
    return isLastItem
  } else {
    return isLastItem && !node.footer
  }
}

export const getCurrentSort = (grid: AgGridReact) => {
  const columnState = grid.api.getColumnState()

  const sortedColumn = columnState.find((column) => column.sort != null)

  if (!sortedColumn) {
    return null
  }

  return { colId: sortedColumn.colId, sort: sortedColumn.sort! } as SortModelItem
}

export const DEFAULT_COL_DEF: ColDef = {
  suppressMovable: true,
  suppressHeaderMenuButton: true,
  sortable: false
}

export const getNodesExpandedState = (gridRefs: any) => {
  const expandedStates: { node_id: number; node_label: string; expanded: boolean }[] = []
  gridRefs?.forEach((ref: any) => {
    ref.current?.api?.forEachNode((node: IRowNode) => {
      if (node.data) {
        expandedStates.push({
          node_id: node.data.id,
          node_label: node.data.label,
          expanded: node.expanded ?? false
        })
      }
    })
  })

  return expandedStates
}

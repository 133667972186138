import { FilterModel } from 'ag-grid-community'

import { IFieldAttribute } from '../types/query-builder-types'

export function updatedHiddenFiltersAfterFieldsDeletion(
  oldFieldConfigs: Partial<IFieldAttribute>[],
  newFieldConfigs: Partial<IFieldAttribute>[],
  hiddenFilters: FilterModel | null
) {
  const fieldAliasesToDelete = _.difference(
    oldFieldConfigs.filter((config) => config.is_hidden).map((config) => config.name_alias),
    newFieldConfigs.map((config) => config.name_alias)
  ) as string[]

  if (fieldAliasesToDelete.length) {
    return _.omit(hiddenFilters, fieldAliasesToDelete)
  }

  return hiddenFilters
}

import { useRef } from 'react'
import { useSelector } from 'react-redux'

import { DataPanel } from '@components/component-management/DataPanel'
import { Skeleton } from '@components/core/skeleton'

import { useMaxRows } from '@hooks/grid/useMaxRows'

import { selectGenre, selectPending } from '@store/slices/component/basic-config'

import { ChartVisualize } from './chart-visualize'
import GenreDropdown from './common/genre-dropdown'
import { Panel } from './common/panel'
import { Wrapper } from './common/wrapper'
import ChartPanelBody from './data-panel/chart-panel-body'
import { TablePanelBody } from './data-panel/table-panel-body'
import { UnderlyingDataPanel } from './data-preview/underlying-data-panel'
import { TableVisualize } from './table-visualize'
import { ComponentGenre } from './types/component-types'

export function Visualize() {
  const genre = useSelector(selectGenre)
  const isPending = useSelector(selectPending)
  const loadingWrapperRef = useRef<HTMLDivElement>(null)
  const { maxRows } = useMaxRows(loadingWrapperRef)

  const renderGenreVisualize = () => {
    switch (genre) {
      case ComponentGenre.chart:
        return <ChartVisualize />
      case ComponentGenre.table:
        return <TableVisualize />
      default:
        return (
          <div>
            <div>TODO: Not implemented for {genre}</div>
            <GenreDropdown />
          </div>
        )
    }
  }

  const renderGenreDataPanel = () => {
    switch (genre) {
      case ComponentGenre.chart:
        return <ChartPanelBody />
      case ComponentGenre.table:
        return <TablePanelBody />
      default:
        return null
    }
  }

  return (
    <Wrapper>
      <div className='flex size-full gap-4'>
        <div className='flex grow flex-col gap-4 overflow-hidden'>
          <Panel className='size-full flex-1 overflow-hidden' title='Component Settings'>
            {isPending ? (
              <div ref={loadingWrapperRef} className='size-full'>
                {_.times(maxRows, (n) => (
                  <Skeleton key={n} className='h-6 w-full' />
                ))}
              </div>
            ) : (
              renderGenreVisualize()
            )}
          </Panel>
          <UnderlyingDataPanel />
        </div>

        <DataPanel>{renderGenreDataPanel()}</DataPanel>
      </div>
    </Wrapper>
  )
}

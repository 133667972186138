import { useQuery } from '@tanstack/react-query'

import { FinancialHierarchyGlAccountNode, FinancialHierarchyNode } from 'components/financial/types'
import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'

const fetchGlAccountNodes = async (
  businessId: number | undefined,
  nodeId: number
): Promise<FinancialHierarchyGlAccountNode[]> => {
  const response = await axiosServices.get(
    `/businesses/${businessId}/general_ledger_account_hierarchy_nodes/${nodeId}/general_ledger_account_nodes`
  )
  return response.data
}

export const useFetchGlAccountNodes = (node: FinancialHierarchyNode) => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-gl-account-nodes', user?.business_id, node.id],
    queryFn: () => fetchGlAccountNodes(user?.business_id, node.id),
    enabled: !!node.id && node.identifier !== 'formula',
    staleTime: 600_000
  })
}

import { Box } from '@mui/material'

import { useToggle } from '@uidotdev/usehooks'

import { useSelector } from '@store/index'
import { selectQueryData } from '@store/slices/component/query-config'

import { Toggle } from 'components/core/toggle'

export function TogglePath() {
  const data = useSelector(selectQueryData())

  const [showJson, toggleJson] = useToggle()

  return (
    <Box>
      <Toggle
        className='mb-4 mt-1 font-semibold text-primary'
        pressed={showJson}
        onPressedChange={toggleJson}
      >
        {showJson ? 'Hide' : 'Show'} JSON
      </Toggle>
      {showJson && <pre>{!!data && JSON.stringify(data, null, 2)}</pre>}
    </Box>
  )
}

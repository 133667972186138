import {
  CutoffDateOptions,
  CutoffDateType,
  NoneType,
  TruncateOptions
} from '../types/query-builder-types'
import { fieldSelected } from './field-config-utils'

const presenceSuffix = 'presence'

export default function generateFieldAlias({
  fieldName,
  previousAlias,
  aggregate,
  truncate,
  cutoffDate,
  presence
}: {
  fieldName: string
  previousAlias: string
  aggregate: string
  truncate: string
  cutoffDate: CutoffDateType
  presence: boolean
}) {
  let baseName = previousAlias || fieldName

  const [, field] = baseName.split('_of_')

  if (field) {
    baseName = field
  }

  const suffixOptions = _.concat(
    _.values(TruncateOptions) as string[],
    _.values(CutoffDateOptions) as string[]
  )

  suffixOptions.forEach((option) => {
    if (baseName.endsWith(`_${option}`)) {
      baseName = baseName.replace(`_${option}`, '')
    }
  })
  if (baseName.endsWith(`_${presenceSuffix}`)) {
    baseName = baseName.replace(`_${presenceSuffix}`, '')
  }

  let fieldAlias

  if (!aggregate || aggregate === NoneType.NONE) {
    fieldAlias = baseName
  } else {
    fieldAlias = `${aggregate}_of_${baseName}`
  }

  if (presence) {
    fieldAlias = `${fieldAlias}_${presenceSuffix}`
  } else if (fieldSelected(cutoffDate)) {
    // when cutoffDate is selected, truncate is not selected
    fieldAlias = `${fieldAlias}_${cutoffDate}`
  } else if (fieldSelected(truncate)) {
    fieldAlias = `${fieldAlias}_${truncate}`
  }

  return fieldAlias
}

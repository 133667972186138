import { useQuery } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'

const fetchPage = async (businessId: number | undefined, pageID: string) => {
  const response = await axiosServices.get(`/businesses/${businessId}/pages/${pageID}`)
  return response.data
}

const useFetchPage = (pageID: string, defaultPageData = []) => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-page', user?.business_id, pageID],
    queryFn: () => fetchPage(user?.business_id, pageID),
    initialData: { layout_structure: defaultPageData },
    refetchOnWindowFocus: false,
    enabled: !!pageID
  })
}

export default useFetchPage

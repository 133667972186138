import { useState } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material'

import grid1Col from 'assets/images/layout-builder/grid-1-cols.png'
import { useFetchComponents } from 'pages/component-management/queries/fetch-components'

import { COMP, GROUP, SIDEBAR_GROUPED_ITEMS_FILTERED, SIDEBAR_ITEM } from '../../data'
import ComponentSearchControl from './component-search-control'
import DraggableComponent from './draggable-component'

const transformToSideBarFormat = (componentsData: any) => {
  const transformedData = componentsData.results.map((component: any) => ({
    id: `sidebar-item-${component.name}-${component.uuid}`,
    group: GROUP.WIDGET,
    category: SIDEBAR_ITEM,
    component: {
      id: `sidebar-comp-${component.name}-${component.uuid}`,
      component_id: component.id,
      uuid: component.uuid,
      scope: component.scope,
      category: COMP.WIDGET.CATEGORY,
      genre: component.genre,
      source: component.source,
      name: component.name,
      title: component.title,
      imgSrc: grid1Col
    },
    enabled: component.enabled
  }))

  return { Widgets: transformedData }
}

const DragPanel = ({
  onComponentsManuallySelected,
  devMode = false
}: {
  onComponentsManuallySelected: (components: any[]) => void
  devMode: boolean
}) => {
  const [isDialogOpen, setDialogOpen] = useState(false)
  const {
    data: componentsData,
    isLoading,
    isError
  } = useFetchComponents({
    page: 1, // set your desired page number
    items: 100 // set your desired items per page
  })

  if (isLoading) {
    return <p>Loading...</p>
  }

  if (isError) {
    return <p>Error loading components.</p>
  }

  // const sidebarItems = transformToSideBarFormat(componentsData) || []; // SIDEBAR_GROUPED_ITEMS_FILTERED;
  const sidebarItems = {
    Widgets: [
      ...(transformToSideBarFormat(componentsData)?.Widgets || []),
      // @ts-ignore
      ...(SIDEBAR_GROUPED_ITEMS_FILTERED?.Widgets || [])
    ]
  }

  const handleDialogOpen = (e: any) => {
    e.stopPropagation()
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const onApply = (components: []) => {
    onComponentsManuallySelected && onComponentsManuallySelected(components)
    setDialogOpen(false)
  }

  return (
    <div className='drag-panel col-config-container'>
      {devMode && <label className='dev-info-text'>DragPanel | Draggables</label>}
      <h3>Components</h3>
      {Object.entries(sidebarItems).map(([groupName, items]) => (
        <Accordion key={groupName}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Grid item>
                <Typography variant='h6'>{groupName}</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleDialogOpen} color='primary'>
                  <FullscreenIcon />
                </IconButton>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails style={{ maxHeight: 500, overflowY: 'auto' }}>
            {/*@ts-ignore*/}
            {items.map((item: any) => (
              <DraggableComponent key={item.id} item={item} component={item.component} />
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
      <Dialog maxWidth='md' open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Select Widgets</DialogTitle>
        <DialogContent>
          <ComponentSearchControl onClose={handleDialogClose} onApply={onApply} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default DragPanel

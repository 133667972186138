import { createPortal } from 'react-dom'

import { DragOverlay, useDndContext, useDraggable } from '@dnd-kit/core'

import { cn } from '@utils/style-utils'

import Button from '@components/core/button'
import { Text } from '@components/core/text'
import { DragIndicator, Icon } from '@components/icons'

import { GeneralLedgerAccount } from 'components/financial/types'

export const DraggableGeneralLedgerAccounts = ({
  glAccounts
}: {
  glAccounts: GeneralLedgerAccount[]
}) => {
  return (
    <div>
      <DraggableItem glAccounts={glAccounts} />

      <DraggableOverlay glAccounts={glAccounts} />
    </div>
  )
}

const DraggableOverlay = ({ glAccounts, dropAnimation = {} }: any) => {
  const { active } = useDndContext()
  return createPortal(
    <DragOverlay dropAnimation={dropAnimation}>
      {active && active?.data?.current?.type !== 'sort' ? (
        <DraggableItem glAccounts={glAccounts} dragging dragOverlay />
      ) : null}
    </DragOverlay>,
    document.body
  )
}

const DraggableItem = ({ glAccounts, dragging, dragOverlay, ...props }: any) => {
  const { setNodeRef, listeners } = useDraggable({
    id: 'draggable-selected-accounts',
    data: { movingGlAccounts: glAccounts }
  })

  return (
    <div
      ref={setNodeRef}
      className={cn(
        'my-1 flex min-h-8 items-center rounded-[4px] border border-solid px-2',
        glAccounts.length > 0 ? 'border-primary bg-primary' : 'border-grey-lighter'
      )}
    >
      <Button
        variant='text'
        size='extra-small'
        {...(glAccounts.length > 0 && listeners)}
        className='p-0 focus:border-hidden'
      >
        <Icon
          icon={<DragIndicator />}
          className={cn(glAccounts.length > 0 ? 'text-white' : 'text-primary-darker')}
        />
      </Button>

      <Text
        variant='body'
        className={cn(
          'ml-1 align-middle text-[14px]',
          glAccounts.length > 0 && 'font-semibold text-white'
        )}
      >
        {glAccounts.length > 0 ? (
          <>
            <b>{glAccounts.length}</b> Items Selected. Drag This To A Chart Of Accounts Heading
          </>
        ) : (
          <>Select GL Accounts below and drag this button to the hierarchy on the left.</>
        )}
      </Text>
    </div>
  )
}

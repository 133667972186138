import { useQuery } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import { Page } from 'types/page'
import axiosServices from 'utils/axios'
import { camelCaseKeys } from 'utils/case-conversion'

const fetchBrowsablePages = async (businessId: number | undefined): Promise<Page[]> => {
  const response = await axiosServices.get(`/businesses/${businessId}/pages/browsable`)
  return camelCaseKeys(response.data)
}

export const useFetchBrowsablePages = () => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-browsable-pages', user?.business_id],
    enabled: !!user?.business_id,
    queryFn: () => fetchBrowsablePages(user?.business_id),
    staleTime: 600_000
  })
}

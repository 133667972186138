import React from 'react'

import { Box, Menu, MenuItem, Typography } from '@mui/material'

import { cn } from '@utils/style-utils'

import { useAnchor } from '@components/DropDownButton'
import { IconButton } from '@components/core/icon-button'
import { ChevronDown, Icon } from '@components/icons'

export function ItemSelection<T>({
  options,
  selected,
  setSelected,
  description,
  labelKey = 'label' as keyof T,
  idKey = 'id' as keyof T
}: {
  options: T[]
  idKey?: keyof T
  labelKey?: keyof T
  selected: T
  setSelected: React.Dispatch<React.SetStateAction<string | null>>
  description?: string
}) {
  const { anchorEl, open, onClick, onClose, anchorRef } = useAnchor()

  return (
    <Box>
      <Box
        ref={anchorRef}
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ py: 2 }}
      >
        <Box>
          <Typography variant='h4'>{selected[labelKey] as string}</Typography>
          <Typography variant='details'>{description}</Typography>
        </Box>
        <IconButton onClick={onClick}>
          <Icon
            icon={<ChevronDown />}
            className={cn('transition-transform duration-300', open ? 'rotate-180' : 'rotate-0')}
          />
        </IconButton>
      </Box>
      <Menu
        MenuListProps={{
          sx: {
            width: anchorEl && anchorEl.offsetWidth
          }
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        {options.map((option) => (
          <MenuItem
            key={option[idKey] as string}
            selected={selected[idKey] === option[idKey]}
            onClick={() => {
              setSelected(option[idKey] as string)
              onClose()
            }}
          >
            {option[labelKey] as string}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

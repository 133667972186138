import { getAllowedRange, getMaxByFrequency, getMinByFrequency } from '@utils/date-filter-utils'

import { clampInfo } from '@components/control-panel/calendar-filter-popover/day-picker/utils'

import { Month } from '../types/date'
import {
  DateFilterInfo,
  DateInfo,
  DateRange,
  DateRangeType,
  Frequency,
  FrequencyInfo,
  Quarter,
  RangeType,
  YearInfo,
  YearMonthDayInfo,
  YearMonthInfo,
  YearQuarterInfo,
  YearWeekInfo
} from '../types/filter'
import {
  addDaysToDate,
  convertCalendarInfoToFiscalInfo,
  convertYearMonthDayInfoToCalendarDate,
  getDaysInMonth,
  getIndexFromMonth,
  getPreviousMonth,
  getPreviousMonthInfo,
  getQuarter,
  getQuarterEndDate,
  getQuarterStartDate,
  todayYMD,
  yearEndYMD,
  yearStartYMD
} from './date-utils'
import {
  convertUTCDateToYMD,
  convertYMDToUTC,
  getMaxWeek,
  getWeekNumber,
  isoWeekToCalendarDate,
  isoWeekToCalendarEndDate
} from './date-week-utils'

export const getFrequencyStartYMD = (
  date_: DateInfo,
  frequency: Frequency,
  fiscalYearStart: Month | undefined
) => {
  switch (frequency) {
    case Frequency.Yearly: {
      const date = date_ as YearInfo
      return yearStartYMD(date.year, fiscalYearStart)
    }
    case Frequency.Monthly: {
      const date = date_ as YearMonthInfo
      return { year: date.year, month: date.month, day: 1 }
    }
    case Frequency.Weekly: {
      const date = date_ as YearWeekInfo
      return convertCalendarInfoToFiscalInfo(
        isoWeekToCalendarDate(date.year, date.week, fiscalYearStart),
        fiscalYearStart
      )
    }
    case Frequency.Quarterly: {
      const date = date_ as YearQuarterInfo
      return convertCalendarInfoToFiscalInfo(
        getQuarterStartDate(date.year, date.quarter, fiscalYearStart),
        fiscalYearStart
      )
    }
    case Frequency.Daily: {
      const date = date_ as YearMonthDayInfo
      return date
    }
    default:
      throw new Error('Unsupported')
  }
}

export const getFrequencyEndYMD = (
  date_: DateInfo,
  frequency: Frequency,
  fiscalYearStart: Month | undefined
) => {
  switch (frequency) {
    case Frequency.Yearly: {
      const date = date_ as YearInfo
      return yearEndYMD(date.year, fiscalYearStart)
    }
    case Frequency.Monthly: {
      const date = date_ as YearMonthInfo
      return { year: date.year, month: date.month, day: getDaysInMonth(date.year, date.month) }
    }
    case Frequency.Weekly: {
      const date = date_ as YearWeekInfo
      const result = convertCalendarInfoToFiscalInfo(
        isoWeekToCalendarEndDate(date.year, date.week, fiscalYearStart),
        fiscalYearStart
      )
      // do not let weekly bounds cross year
      return clampInfo(
        result,
        yearStartYMD(date.year, fiscalYearStart),
        yearEndYMD(date.year, fiscalYearStart),
        fiscalYearStart
      )!
    }
    case Frequency.Quarterly: {
      const date = date_ as YearQuarterInfo
      return convertCalendarInfoToFiscalInfo(
        getQuarterEndDate(date.year, date.quarter, fiscalYearStart),
        fiscalYearStart
      )
    }
    case Frequency.Daily: {
      const date = date_ as YearMonthDayInfo
      return date
    }
    default:
      throw new Error('Unsupported')
  }
}

export const convertYMDRangeToFrequencyRange = (
  startDate_: YearMonthDayInfo,
  endDate_: YearMonthDayInfo,
  frequency: Frequency,
  fiscalYearStart: Month | undefined
): DateRange => {
  return {
    startDate: convertYMDToFrequencyInfo(startDate_, frequency, fiscalYearStart),
    endDate: convertYMDToFrequencyInfo(endDate_, frequency, fiscalYearStart)
  }
}

export const transformFrequencyRange = (
  startDate: DateInfo,
  endDate: DateInfo,
  oldFrequency: Frequency,
  newFrequency: Frequency,
  fiscalYearStart: Month | undefined
): DateRange => {
  const prevRange = convertFrequencyRangeToYMDRange(
    startDate,
    endDate,
    oldFrequency,
    fiscalYearStart
  )
  return convertYMDRangeToFrequencyRange(
    prevRange.startDate,
    prevRange.endDate,
    newFrequency,
    fiscalYearStart
  )
}

export const convertFrequencyRangeToYMDRange = (
  startDate_: DateInfo,
  endDate_: DateInfo,
  frequency: Frequency,
  fiscalYearStart: Month | undefined
): {
  startDate: YearMonthDayInfo
  endDate: YearMonthDayInfo
} => {
  return {
    startDate: getFrequencyStartYMD(startDate_, frequency, fiscalYearStart),
    endDate: getFrequencyEndYMD(endDate_, frequency, fiscalYearStart)
  }
}

export const convertYMDToFrequencyInfo = <T extends Frequency, Result = FrequencyInfo[T]>(
  date: YearMonthDayInfo,
  frequency: T,
  fiscalYearStart: Month | undefined
) => {
  const currentYear = date.year
  switch (frequency) {
    case Frequency.Yearly: {
      return { year: currentYear } as Result
    }
    case Frequency.Monthly: {
      return { year: currentYear, month: date.month } as Result
    }
    case Frequency.Weekly: {
      return getWeekNumber(currentYear, date.month, date.day, fiscalYearStart) as Result
    }
    case Frequency.Quarterly: {
      const currentQuarter = getQuarter(date.month, fiscalYearStart) as Result
      return { year: currentYear, quarter: currentQuarter } as Result
    }
    case Frequency.Daily: {
      return date as Result
    }
    default:
      throw new Error('Unsupported')
  }
}

export const getYearStartFrequencyInfo = (
  year: number,
  frequency: Frequency,
  fiscalYearStart: Month | undefined
): DateInfo => {
  switch (frequency) {
    case Frequency.Yearly:
      return { year }
    case Frequency.Monthly:
      return { year: year, month: fiscalYearStart || Month.Jan }
    case Frequency.Weekly:
      return { year: year, week: 1 }
    case Frequency.Quarterly:
      return { year: year, quarter: Quarter.Q1 }
    case Frequency.Daily:
      return yearStartYMD(year, fiscalYearStart)
    default:
      throw new Error('Unsupported')
  }
}

export const getYearEndFrequencyInfo = (
  year: number,
  frequency: Frequency,
  fiscalYearStart: Month | undefined
): DateInfo => {
  switch (frequency) {
    case Frequency.Yearly:
      return { year }
    case Frequency.Monthly:
      return { year: year, month: getPreviousMonth(fiscalYearStart || Month.Jan) }
    case Frequency.Weekly:
      return getMaxWeek(year, fiscalYearStart)
    case Frequency.Quarterly:
      return { year: year, quarter: Quarter.Q4 }
    case Frequency.Daily:
      return yearEndYMD(year, fiscalYearStart)
    default:
      throw new Error('Unsupported')
  }
}

export const getYearToDateRange = <T extends Frequency>(
  frequency: T,
  fiscalYearStart: Month | undefined
): RangeType<FrequencyInfo[T]> => {
  const today = todayYMD(fiscalYearStart)
  const endDate = convertYMDToFrequencyInfo(todayYMD(fiscalYearStart), frequency, fiscalYearStart)
  return {
    startDate: getYearStartFrequencyInfo(today.year, frequency, fiscalYearStart),
    endDate
  } as RangeType<FrequencyInfo[T]>
}

export const getPriorYear = (
  frequency: Frequency,
  fiscalYearStart: Month | undefined
): DateRange => {
  const today = todayYMD(fiscalYearStart)
  const priorYear = today.year - 1
  return {
    startDate: getYearStartFrequencyInfo(priorYear, frequency, fiscalYearStart),
    endDate: getYearEndFrequencyInfo(priorYear, frequency, fiscalYearStart)
  }
}

// includes the current month as the end month
export const getTrailing12Months = (fiscalYearStart: Month | undefined): DateRange => {
  const today = convertYMDToFrequencyInfo(
    todayYMD(fiscalYearStart),
    Frequency.Monthly,
    fiscalYearStart
  )
  let start = today
  for (let i = 1; i < 12; ++i) {
    start = getPreviousMonthInfo(start.year, start.month, fiscalYearStart)
  }
  return {
    startDate: start,
    endDate: {
      year: today.year,
      month: today.month
    }
  }
}

export const getLastMonth = (fiscalYearStart: Month | undefined): DateRange => {
  const today = todayYMD(fiscalYearStart)
  return {
    startDate: {
      year: today.year,
      month: today.month,
      day: 1
    },
    endDate: {
      year: today.year,
      month: today.month,
      day: getDaysInMonth(today.year, today.month)
    }
  }
}

export const last90DaysDateRange = (fiscalYearStart: Month | undefined) => {
  const today = todayYMD(undefined)
  const start = addDaysToDate(convertYMDToUTC(today.year, today.month, today.day), -89)
  const startYMD = convertCalendarInfoToFiscalInfo(convertUTCDateToYMD(start), fiscalYearStart)
  const frequencyInfo: DateFilterInfo = {
    frequency: Frequency.Daily,
    fiscalYearStart: fiscalYearStart,
    rangeType: DateRangeType.Range,
    startDate: startYMD,
    endDate: todayYMD(fiscalYearStart)
  }

  return frequencyInfo
}

export const getFrequencyDateRange = (
  fiscalYearStart: Month | undefined,
  frequency: Frequency
): DateRange => {
  const today = todayYMD(fiscalYearStart)
  const currentYear = today.year

  return {
    startDate: getYearStartFrequencyInfo(currentYear, frequency, fiscalYearStart),
    endDate: getYearEndFrequencyInfo(currentYear, frequency, fiscalYearStart)
  }
}

export const convertFreqDateRangeToCalendarRange = (
  startDate: DateInfo,
  endDate: DateInfo,
  frequency: Frequency,
  fiscalYearStart: Month
) => {
  const range = convertFrequencyRangeToYMDRange(startDate, endDate, frequency, fiscalYearStart)
  return {
    startDate: convertYearMonthDayInfoToCalendarDate(range.startDate, fiscalYearStart),
    endDate: convertYearMonthDayInfoToCalendarDate(range.endDate, fiscalYearStart)
  }
}

const padNum = (num: number) => `${num}`.padStart(2, '0')
export const formatYearMonth = (year: number, month: Month) =>
  `${year}-${padNum(getIndexFromMonth(month) + 1)}`

export const handleFrequencyChange = (
  oldRange: { startDate: DateInfo; endDate: DateInfo; frequency: Frequency },
  newFrequency: Frequency,
  propsRange: { minAllowed?: DateInfo; maxAllowed?: DateInfo },
  fiscalYearStart?: Month
): { startDate: DateInfo; endDate: DateInfo } => {
  const rangeInfo = transformFrequencyRange(
    oldRange.startDate,
    oldRange.endDate,
    oldRange.frequency,
    newFrequency,
    fiscalYearStart
  )
  fiscalYearStart = fiscalYearStart ?? Month.Jan
  const allowedRange = getAllowedRange(fiscalYearStart, newFrequency)

  const minAllowed: DateInfo | undefined = propsRange.minAllowed ?? allowedRange.minAllowed
  const maxAllowed: DateInfo | undefined = propsRange.maxAllowed ?? allowedRange.maxAllowed

  const clampedStart = minAllowed
    ? getMaxByFrequency(rangeInfo.startDate, minAllowed, newFrequency, fiscalYearStart)!
    : rangeInfo.startDate

  const clampedEnd = maxAllowed
    ? getMinByFrequency(rangeInfo.endDate, maxAllowed, newFrequency, fiscalYearStart)!
    : rangeInfo.endDate

  return { startDate: clampedStart, endDate: clampedEnd }
}

function isYearMonthDayInfo(dateInfo: DateInfo): dateInfo is YearMonthDayInfo {
  return (
    (dateInfo as YearMonthDayInfo).day !== undefined &&
    (dateInfo as YearMonthDayInfo).month !== undefined
  )
}

export const getPropsRange = (
  frequency: Frequency,
  minAllowed?: DateInfo,
  maxAllowed?: DateInfo,
  fiscalYearStart?: Month
): { minAllowed?: DateInfo; maxAllowed?: DateInfo } => ({
  minAllowed:
    minAllowed && isYearMonthDayInfo(minAllowed)
      ? convertYMDToFrequencyInfo(minAllowed, frequency, fiscalYearStart)
      : void 0,
  maxAllowed:
    maxAllowed && isYearMonthDayInfo(maxAllowed)
      ? convertYMDToFrequencyInfo(maxAllowed, frequency, fiscalYearStart)
      : void 0
})

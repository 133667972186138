import { YearWeekInfo } from 'types/filter'
import { Month } from 'utils/date-utils'
import { cmpInfo, getMaxWeek } from 'utils/date-week-utils'

export const minInfo = (info1?: YearWeekInfo, info2?: YearWeekInfo) => {
  if (!info1) return info2
  if (!info2) return info1
  return cmpInfo(info1, info2) < 0 ? info1 : info2
}

export const maxInfo = (info1?: YearWeekInfo, info2?: YearWeekInfo) => {
  if (!info1) return info2
  if (!info2) return info1
  return cmpInfo(info1, info2) > 0 ? info1 : info2
}

export const clampYearMax = (value: YearWeekInfo, fiscalYearStart?: Month) => {
  const lastDayWeekNumber = getMaxWeek(value.year, fiscalYearStart)
  return minInfo(value, lastDayWeekNumber)!
}

export const outOfBounds = <T extends YearWeekInfo>(info: T, minAllowed?: T, maxAllowed?: T) => {
  if (!minAllowed && !maxAllowed) return false

  return (
    (minAllowed != null && cmpInfo(info, minAllowed) < 0) ||
    (maxAllowed != null && cmpInfo(info, maxAllowed) > 0)
  )
}

// navigationMode is the mode of the picker that is being navigated,
// selecting a month or selecting a year, prev and next button work on months,
// year select view works on year
export const navigationClamp = <T extends YearWeekInfo>(
  info: T,
  navigationMode: 'month' | 'week' | 'year',
  minAllowed?: T,
  maxAllowed?: T
) => {
  if (minAllowed && info.year === minAllowed.year) {
    const cmpVal = cmpInfo(info, minAllowed)
    if (navigationMode === 'year' && cmpVal < 0) {
      return minAllowed
    }
    if (navigationMode === 'month' && cmpVal < 0) {
      return minAllowed
    }
  }

  if (maxAllowed && info.year === maxAllowed.year) {
    const cmpVal = cmpInfo(info, maxAllowed)
    if (navigationMode === 'year' && cmpVal > 0) {
      return maxAllowed
    }
    if (navigationMode === 'month' && cmpVal > 0) {
      return maxAllowed
    }
  }
  return info
}

import { refreshSessionBase } from './refresh-token'
import { RefreshInfo } from './refresh-token'

export const createSessionRefresher = () => {
  let currentRequest: Promise<RefreshInfo> | undefined
  let lastFinishedRequest: { result: RefreshInfo; timestamp: number } | undefined

  const refreshSession = async () => {
    // If there's an ongoing request, return the same promise
    if (currentRequest) {
      return currentRequest
    }

    // Check if the last request finished within the last 5 seconds
    if (lastFinishedRequest && Date.now() - lastFinishedRequest.timestamp <= 5000) {
      return lastFinishedRequest.result
    }

    try {
      currentRequest = refreshSessionBase()
      const result = await currentRequest
      lastFinishedRequest = { result, timestamp: Date.now() }
      return result
    } finally {
      // Reset the current request to allow future refreshes
      currentRequest = undefined
    }
  }

  return refreshSession
}

export const refreshSession = createSessionRefresher()

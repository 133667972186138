import React, { useEffect, useState } from 'react'

import InfoIcon from '@mui/icons-material/Info'
import { Link } from '@mui/material'

import { ColDef } from 'ag-grid-community'

import { awsCronExpressionValidator, humanizeCron } from '@utils/cron-utils'

import { Text } from '@core/text'

import AgGrid from '@components/ag-grid'
import { Button } from '@components/core/button'
import { Input } from '@components/core/input'

import { IJobSchedule } from '@layout-components/general/data-connections/queries/fetch-job-schedules'
import { useUpdateJobSchedules } from '@layout-components/general/data-connections/queries/update-job-schedules'
import { textSearchColumnDef } from '@layout-components/general/data-connections/utils/col-defs'

import useAuth from '@hooks/useAuth'

interface UpdateMultipleSchedulesFormProps {
  data?: IJobSchedule[]
  onSuccess: () => void
  onCancel: () => void
}

export const UpdateMultipleSchedulesForm: React.FC<UpdateMultipleSchedulesFormProps> = ({
  onSuccess,
  onCancel,
  data
}) => {
  const { user } = useAuth()
  const [cronVal, setCronVal] = useState<string>('')
  const [error, setError] = useState<string>('')

  useEffect(() => {
    if (data && data.length > 0) {
      const lastSchedule = data[data.length - 1]
      const cronPart = lastSchedule.scheduleExpression.match(/cron\((.*)\)/)?.[1] || ''
      setCronVal(cronPart)
    }
  }, [data])

  const { mutate: updateJobSchedules } = useUpdateJobSchedules({ onSuccess })

  const handleCronChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setCronVal(value)

    try {
      awsCronExpressionValidator(value)
      setError('')
    } catch (err) {
      setError((err as Error).message)
    }
  }

  const handleSave = () => {
    if (!data) {
      return
    }

    const payload =
      data?.map((schedule) => ({
        id: schedule.id,
        scheduleExpression: `cron(${cronVal})`,
        timezone: user?.business_local_timezone || 'UTC'
      })) || []

    updateJobSchedules(payload)
  }

  const columnDefs: ColDef[] = [
    textSearchColumnDef('name', 'Job Schedule Name', { minWidth: 100 }),
    {
      colId: 'scheduleExpression',
      field: 'scheduleExpression',
      headerName: 'Schedule Expression',
      width: 140
    },
    {
      colId: 'scheduleExpressionTimezone',
      field: 'scheduleExpressionTimezone',
      headerName: 'Timezone',
      width: 150
    }
  ]

  return (
    <div>
      <div className='flex w-full items-center justify-between gap-2'>
        <Button onClick={onCancel} type='button' variant='outline' size={'default'}>
          {'Cancel'}
        </Button>
        {data && data.length > 0 && (
          <Button
            onClick={handleSave}
            disabled={!!error}
            type='button'
            variant={error ? 'disabled' : 'primary'}
            size='default'
          >
            Save
          </Button>
        )}
      </div>
      <div className='mt-4 flex flex-col items-stretch'>
        <div className='flex items-center justify-between'>
          <Text variant='h4'>Bulk Update - Job Schedules</Text>
        </div>

        <div className='flex items-center justify-between'>
          <Text variant='description'>Job Schedules</Text>
        </div>
        <div className='h-[calc(40vh-12rem)] w-full'>
          <AgGrid
            style={{ height: '100%' }}
            autoSizeStrategy={{ type: 'fitGridWidth' }}
            rowData={data}
            columnDefs={columnDefs}
            defaultColDef={{
              resizable: true
            }}
          />
        </div>

        <div className='mt-2 flex items-center justify-between'>
          <Text variant='description'>New Timezone</Text>
        </div>
        <Input value={user?.business_local_timezone} type='text' className={'w-96'} disabled />

        <div className='mt-2 flex items-center justify-between'>
          <Text variant='description'>New Schedule Expression</Text>
          <Link
            href='https://docs.aws.amazon.com/eventbridge/latest/userguide/eb-scheduled-rule-pattern.html'
            target='_blank'
            rel='noopener noreferrer'
            display='flex'
            alignItems='center'
          >
            <InfoIcon fontSize='small' />
          </Link>
        </div>
        <Input
          value={cronVal}
          type='text'
          placeholder='0 23 * * ? *'
          className={'w-96'}
          onChange={handleCronChange}
        />

        {error && (
          <Text variant='details' className='mt-2 text-error-darker'>
            Error: {error}
          </Text>
        )}
        <Text variant='details' className='mt-2'>
          {humanizeCron(cronVal, '')}
        </Text>
      </div>
    </div>
  )
}

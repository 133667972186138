import { useState } from 'react'

import { Badge } from '@components/core/badge'
import { CheckboxWithTitle } from '@components/core/checkbox-with-title'
import { Text } from '@components/core/text'
import { Select } from '@components/form/select'

import { useDispatch, useSelector } from '@store/index'
import {
  disableExternalFilter,
  enableExternalFilter,
  selectColumns,
  selectExternalFilter,
  updateColumn
} from '@store/slices/component/table-config'

import { ItemSelection } from '../common/item-selection'
import { ColumnPinType, NoneType } from '../types/table-builder-types'
import { numberHeader } from '../utils/header-utils'
import { ColumnBroadcastSettings } from './column-broadcast-settings'
import { ColumnPivotSettings } from './column-pivot-settings'
import { ConfigureFilter } from './filters'

export function ColumnSettings() {
  const dispatch = useDispatch()
  const columns = useSelector(selectColumns)
  const [selectedField, setSelectedField] = useState<null | string>(
    columns.length ? columns[0].field! : null
  )
  const filter = useSelector(selectExternalFilter(selectedField))

  const selected = columns.find((column) => column.field === selectedField) || null

  if (!selected) {
    return <Text variant='description'>No column selected</Text>
  }

  return (
    <div className='flex flex-col gap-2'>
      <div className='size-full'>
        <ItemSelection
          options={columns}
          selected={selected}
          setSelected={setSelectedField}
          labelKey='field'
          idKey='field'
          description='Select which columns property to edit'
        />
      </div>
      <div className='flex flex-row gap-2'>
        {selected.hide && <Badge variant='outline'>Hidden</Badge>}
        {selected.pivot && <Badge variant='outline'>Pivot</Badge>}
        {selected.rowGroup && <Badge variant='outline'>Row Group</Badge>}
        {!!filter && <Badge variant='outline'>External Filter</Badge>}
      </div>
      <div className='mt-2'>
        <Text variant='h5'>Column Settings</Text>
        <Text variant='details'>Choose settings to modify the column</Text>
      </div>
      <div>
        <Select
          label='Pinned'
          options={[
            { label: 'None', value: NoneType.NONE },
            { label: 'Left', value: ColumnPinType.LEFT },
            { label: 'Right', value: ColumnPinType.RIGHT }
          ]}
          value={(selected.pinned as ColumnPinType) || 'none'}
          onChange={(value) => {
            const pinned = (value === NoneType.NONE ? null : value) as ColumnPinType
            dispatch(updateColumn({ field: selected.field, pinned }))
          }}
          id='pinned-settings'
        />
      </div>
      {selected.pivot && <ColumnPivotSettings selected={selected} />}
      {selected.hide && (
        <>
          <div className='mt-4'>
            <Text variant='h5'>External Filters</Text>
            <Text variant='details'>Choose settings to make this column an external filter</Text>
          </div>
          <div className='flex justify-between'>
            <CheckboxWithTitle
              title='Enable External Filter'
              checked={!!filter}
              onCheckedChange={(value) =>
                value
                  ? dispatch(enableExternalFilter(selected.field!))
                  : dispatch(disableExternalFilter(selected.field!))
              }
            />
            {filter && <ConfigureFilter filter={filter} columnField={selected.field!} />}
          </div>
        </>
      )}
      {numberHeader(selected) && (
        <>
          <div className='mt-4'>
            <Text variant='h5'>Heatmap</Text>
            <Text variant='details'>Choose heatmap settings</Text>
          </div>
          <CheckboxWithTitle
            title='Heatmap'
            checked={!!selected.customData?.heatmap}
            onCheckedChange={(value) =>
              dispatch(updateColumn({ field: selected.field, customData: { heatmap: value } }))
            }
          />
        </>
      )}
      {!selected.hide && !selected.pivot && <ColumnBroadcastSettings selected={selected} />}
    </div>
  )
}

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

import { useLatestRef } from 'hooks/useLatestRef'

import { useSetAxisGranularity } from '../hooks'
import { DATE_CELL_WIDTH_BREAKPOINTS } from '../types/data-types'
import { IModule, IModuleAttributes, IModuleUseInstance, ModuleName } from '../types/module-types'
import { setAxisCellStartEndLocation } from '../utils/clustered-series-utils'
import { dateIntervalsAndFormattings } from '../utils/date-axis-utils'
import { assignRef, getRef } from '../utils/module-utils'

const moduleName = ModuleName.dateXAxis

const dateXAxisModule: IModule<ModuleName.dateXAxis> = {
  name: moduleName
}

dateXAxisModule.init = (props) => {
  const root: am5.Root = getRef({ ...props, moduleName: ModuleName.root })
  const chart: am5xy.XYChart = getRef({ ...props, moduleName: ModuleName.xyChart })
  const tooltip: am5.Tooltip = getRef({ ...props, moduleName: ModuleName.tooltip })

  const {
    options: {
      xAxisLabel = '',
      frequency,
      // global options
      clusteredSeries = false
    }
  } = props

  const configs = dateIntervalsAndFormattings(frequency)

  const xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 })
  xRenderer.labels.template.setAll({
    fontSize: '11px',
    textAlign: 'center'
  })

  xRenderer.grid.template.set('visible', false)

  if (clusteredSeries) {
    setAxisCellStartEndLocation(xRenderer)
  }

  const xAxis = chart.xAxes.push(
    am5xy.DateAxis.new(root, {
      maxDeviation: 0.2,
      baseInterval: configs.interval,
      gridIntervals: configs.gridIntervals,
      renderer: xRenderer,
      tooltip
    })
  )

  xAxis.onPrivate('cellWidth', function (cellWidth) {
    if (!cellWidth) return
    xRenderer.labels.template.set('maxWidth', cellWidth)

    if (cellWidth < DATE_CELL_WIDTH_BREAKPOINTS.small) {
      xRenderer.labels.template.setAll({
        rotation: -90,
        centerY: am5.p50,
        centerX: am5.p100,
        oversizedBehavior: 'none'
      })
    } else if (cellWidth < DATE_CELL_WIDTH_BREAKPOINTS.medium) {
      xRenderer.labels.template.setAll({
        rotation: -45,
        centerY: am5.p100,
        centerX: am5.p50,
        oversizedBehavior: 'none'
      })
    } else {
      xRenderer.labels.template.setAll({
        rotation: 0,
        centerY: am5.p0,
        centerX: am5.p50,
        oversizedBehavior: 'wrap'
      })
    }
    return cellWidth
  })

  if (xAxisLabel) {
    const label = am5.Label.new(root, {
      rotation: -90,
      text: xAxisLabel,
      y: am5.p50,
      centerX: am5.p50
    })

    xAxis.children.unshift(label)
  }

  assignRef({ ...props, moduleName, item: xAxis })
}

const useInstance: IModuleUseInstance<ModuleName.dateXAxis> = (instance, props) => {
  const propsRef = useLatestRef(props)

  useSetAxisGranularity({ propsRef, moduleName, props })
}

dateXAxisModule.useInstance = useInstance

export const dateXAxisAttributes: IModuleAttributes<ModuleName.dateXAxis> = (p) => ({
  module: dateXAxisModule,
  ...p
})

export default dateXAxisModule

export const ExpandLess = () => (
  <>
    <path
      d='M8.08979 17.5012L11.9698 13.6212L15.8498 17.5012C16.0411 17.665 16.2872 17.7506 16.5388 17.7409C16.7905 17.7312 17.0293 17.6268 17.2074 17.4487C17.3854 17.2706 17.4898 17.0319 17.4995 16.7802C17.5092 16.5286 17.4236 16.2825 17.2598 16.0912L12.6698 11.5012C12.4824 11.3149 12.229 11.2104 11.9648 11.2104C11.7006 11.2104 11.4471 11.3149 11.2598 11.5012L6.66979 16.0912C6.48354 16.2785 6.37899 16.532 6.37899 16.7962C6.37899 17.0604 6.48354 17.3138 6.66979 17.5012C6.85972 17.6864 7.1145 17.79 7.37979 17.79C7.64507 17.79 7.89985 17.6864 8.08979 17.5012Z'
      fill='currentColor'
    />
    <path
      d='M8.21064 12.5012L12.0906 8.62118L15.9706 12.5012C16.1619 12.665 16.408 12.7506 16.6597 12.7409C16.9114 12.7312 17.1501 12.6268 17.3282 12.4487C17.5063 12.2706 17.6106 12.0319 17.6203 11.7802C17.6301 11.5286 17.5445 11.2825 17.3806 11.0912L12.7906 6.50118C12.6033 6.31493 12.3498 6.21039 12.0856 6.21039C11.8215 6.21039 11.568 6.31493 11.3806 6.50118L6.79064 11.0912C6.60439 11.2785 6.49984 11.532 6.49984 11.7962C6.49984 12.0604 6.60439 12.3138 6.79064 12.5012C6.98057 12.6864 7.23535 12.79 7.50064 12.79C7.76592 12.79 8.0207 12.6864 8.21064 12.5012Z'
      fill='currentColor'
    />
  </>
)

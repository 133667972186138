import { Box, Typography } from '@mui/material'

export default function SidebarContainer({
  modelName,
  children
}: {
  modelName: string
  children: React.ReactNode
}) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ position: 'sticky', top: '10px' }}>
        <Typography variant='h5' component='h2' gutterBottom sx={{ pt: 2 }}>
          Choose fields for {modelName}
        </Typography>
        <Box sx={{ width: '100%', pr: 2, height: 'calc(100vh - 350px)', overflowY: 'auto' }}>
          {children}
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  )
}

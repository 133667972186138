import React from 'react'
import { ComponentType, ReactElement, lazy, useEffect } from 'react'

import { Button, CircularProgress, Typography } from '@mui/material'
import { Stack } from '@mui/system'

import { useFrontEndServerStatus } from '@utils/server-status'

import Loadable from '../Loadable'
import Loader from '../Loader'
import { ComponentCrashed } from '../fallback/component-crashed'

const ResourcesUpdated = () => {
  useEffect(() => {
    const timeout = setTimeout(() => window.location.reload(), 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <Stack alignItems='center' textAlign='center' color='text.primary'>
      <div>Resources were updated on the server.</div>
      <div>Refreshing page.</div>
      <CircularProgress color='inherit' />
    </Stack>
  )
}

const ServerOffline = () => {
  return (
    <>
      <Typography variant='h4' color='error'>
        Frontend server is offline.
      </Typography>
      <Button onClick={() => window.location.reload()}>Reload</Button>
    </>
  )
}

const Fallback = () => {
  const { loading, online } = useFrontEndServerStatus()

  return (
    <Stack alignItems='center' p={4}>
      {loading ? <Loader /> : online ? <ResourcesUpdated /> : <ServerOffline />}
    </Stack>
  )
}

const LazyComponent = <T extends Record<string, any>>(
  arg: Promise<{ default: ComponentType<T> }> | (() => Promise<{ default: ComponentType<T> }>),
  CustomLoader?: () => React.JSX.Element,
  CustomFallback?: () => React.JSX.Element
) => {
  const Component = lazy(() =>
    (typeof arg === 'function' ? arg() : arg)
      .then((module) => ({ default: module.default }))
      .catch(() => ({ default: Fallback }))
  )

  const FallbackComponent = CustomFallback || ComponentCrashed

  return Loadable(
    Component as unknown as (props: T) => ReactElement,
    CustomLoader,
    FallbackComponent
  )
}

export default LazyComponent

import { Checkbox } from '@components/core/checkbox'
import { Label } from '@components/core/label'
import { Separator } from '@components/core/separator'
import { Select } from '@components/form/select'

import { useDispatch, useSelector } from '@store/index'
import { selectTableSettings, updateTableSettings } from '@store/slices/component/table-config'

import { Description } from '../common/description'
import { Title } from '../common/title'
import {
  GrandTotalRowType,
  GroupTotalRowType,
  PivotRowTotalsType
} from '../types/table-builder-types'

const NoneSelect = () => (
  <Select
    className='w-24'
    value={PivotRowTotalsType.NONE}
    options={[{ label: 'None', value: PivotRowTotalsType.NONE }]}
    onChange={() => {}}
  />
)

export function GrandTotals() {
  const dispatch = useDispatch()
  const settings = useSelector(selectTableSettings)

  return (
    <>
      <Title>Grand Totals</Title>
      <Description>Grand total will sum Columns and/or Rows</Description>
      <Separator className='my-2' />

      <div className='flex flex-col gap-2'>
        <div className='flex items-center justify-between'>
          <div className='flex w-44 items-center justify-between'>
            <Label className='text-details font-normal' htmlFor='table-show-grand-total-column'>
              Show Grand Total Column
            </Label>
            <Checkbox
              id='table-show-grand-total-column'
              checked={!!settings.pivotRowTotals}
              onCheckedChange={(checked) =>
                dispatch(
                  updateTableSettings({
                    pivotRowTotals: (checked as boolean) ? PivotRowTotalsType.BEFORE : undefined
                  })
                )
              }
            />
          </div>
          <div className='flex items-center gap-2'>
            <Label className='text-details font-normal' htmlFor='table-pivot-row-totals-settings'>
              Column Location
            </Label>
            {settings.pivotRowTotals ? (
              <Select
                className='w-24'
                value={settings.pivotRowTotals || PivotRowTotalsType.BEFORE}
                options={[
                  { label: 'Before', value: PivotRowTotalsType.BEFORE },
                  { label: 'After', value: PivotRowTotalsType.AFTER }
                ]}
                id='table-pivot-row-totals-settings'
                onChange={(value) =>
                  dispatch(updateTableSettings({ pivotRowTotals: value as PivotRowTotalsType }))
                }
              />
            ) : (
              <NoneSelect />
            )}
          </div>
        </div>

        <div className='flex items-center justify-between'>
          <div className='flex w-44 items-center justify-between'>
            <Label className='text-details font-normal' htmlFor='table-show-grand-total-row'>
              Show Grand Total Row
            </Label>
            <Checkbox
              id='table-show-grand-total-row'
              checked={settings.showTotalFooter}
              onCheckedChange={(checked) =>
                dispatch(updateTableSettings({ showTotalFooter: checked as boolean }))
              }
            />
          </div>
          <div className='flex items-center gap-2'>
            <Label className='text-details font-normal' htmlFor='table-pin-row-totals-settings'>
              Row Location
            </Label>
            {settings.showTotalFooter ? (
              <Select
                className='w-24'
                value={settings.grandTotalRow || GrandTotalRowType.BOTTOM}
                options={[
                  { label: 'Bottom', value: GrandTotalRowType.BOTTOM },
                  { label: 'Top', value: GrandTotalRowType.TOP },
                  { label: 'Pinned', value: GrandTotalRowType.PINNED }
                ]}
                id='table-pin-row-totals-settings'
                onChange={(value) =>
                  dispatch(updateTableSettings({ grandTotalRow: value as GrandTotalRowType }))
                }
              />
            ) : (
              <NoneSelect />
            )}
          </div>
        </div>

        <div className='flex items-center justify-between'>
          <div className='flex w-44 items-center justify-between'>
            <Label className='text-details font-normal' htmlFor='table-show-group-total-row'>
              Show Group Total Row
            </Label>
            <Checkbox
              id='table-show-group-total-row'
              checked={!!settings.groupTotalRow}
              onCheckedChange={(checked) =>
                dispatch(
                  updateTableSettings({
                    groupTotalRow: (checked as boolean) ? GroupTotalRowType.BOTTOM : undefined
                  })
                )
              }
            />
          </div>
          <div className='flex items-center gap-2'>
            <Label className='text-details font-normal' htmlFor='table-group-total-row'>
              Group Total Location
            </Label>
            {settings.groupTotalRow ? (
              <Select
                className='w-24'
                value={settings.groupTotalRow || GroupTotalRowType.BOTTOM}
                options={[
                  { label: 'Bottom', value: GroupTotalRowType.BOTTOM },
                  { label: 'Top', value: GroupTotalRowType.TOP }
                ]}
                id='table-group-total-row'
                onChange={(value) =>
                  dispatch(updateTableSettings({ groupTotalRow: value as GroupTotalRowType }))
                }
              />
            ) : (
              <NoneSelect />
            )}
          </div>
        </div>
      </div>
      <Separator className='my-2' />
    </>
  )
}

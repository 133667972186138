import { Ref, forwardRef } from 'react'

import Button, { ButtonProps } from 'components/core/button'
import { cn } from 'utils/style-utils'

interface Props extends ButtonProps {
  open?: boolean
}

const DropDownIconButton = forwardRef((props: Props, ref: Ref<HTMLButtonElement>) => {
  return (
    <Button
      ref={ref}
      aria-expanded={!!props.open}
      variant='ghost'
      className={cn('aria-expanded:bg-inherit aria-expanded:text-inherit', props.className)}
      {...props}
    >
      {props.children}
    </Button>
  )
})

export default DropDownIconButton

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { TreeItem } from 'components/tree/types'
import useAuth from 'hooks/useAuth'
import { dispatch } from 'store'
import { openSnackbar } from 'store/reducers/snackbar'
import axiosServices from 'utils/axios'

export const useCreateNode = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      hierarchyId,
      parentNodeId,
      item
    }: {
      hierarchyId: number
      parentNodeId?: number
      item: TreeItem
    }) => {
      return axiosServices.post(
        `/businesses/${user?.business_id}/general_ledger_account_hierarchy_nodes`,
        {
          general_ledger_account_hierarchy_node: {
            label: item.label,
            hierarchy_id: hierarchyId,
            parent_node_id: parentNodeId,
            identifier: item.identifier,
            show_on_report: item.showOnReport,
            referenced_node_id: item.referencedNodeId
          }
        }
      )
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: ['fetch-hierarchy-builder-nodes', user?.business_id, variables.hierarchyId]
      })
      queryClient.invalidateQueries({
        queryKey: ['fetch-all-hierarchies-with-nodes', user?.business_id]
      })
      queryClient.invalidateQueries({
        queryKey: ['fetch-report-tree', user?.business_id, variables.hierarchyId]
      })
    },
    onError: (error, variables) => {
      dispatch(
        openSnackbar({
          open: true,
          message: `Error Creating GL Hierarchy Node. ${error?.message}`,
          variant: 'alert',
          alert: { color: 'error' },
          close: false
        })
      )
    }
  })
}

export const ChevronRight = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8.9998 8.15009L12.8798 12.0301L8.9998 15.9101C8.83597 16.1014 8.75036 16.3475 8.76009 16.5991C8.76981 16.8508 8.87414 17.0896 9.05223 17.2677C9.23033 17.4457 9.46907 17.5501 9.72074 17.5598C9.97242 17.5695 10.2185 17.4839 10.4098 17.3201L14.9998 12.7301C15.186 12.5427 15.2906 12.2893 15.2906 12.0251C15.2906 11.7609 15.186 11.5075 14.9998 11.3201L10.4098 6.73009C10.2224 6.54384 9.96898 6.4393 9.7048 6.4393C9.44061 6.4393 9.18716 6.54384 8.9998 6.73009C8.81459 6.92002 8.71094 7.17481 8.71094 7.44009C8.71094 7.70537 8.81459 7.96015 8.9998 8.15009Z'
      fill='#303D4E'
    />
  </svg>
)

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { initComponentManagement } from '@store/actions/component-management'
import { RootState } from '@store/index'

import { ComponentGenre } from 'pages/component-management/types/component-types'

interface IBasicComponentConfig {
  pending: boolean
  title: string
  description: string
  updated_at: string
  id: number
  genre: ComponentGenre
}

const initialState: IBasicComponentConfig = {
  pending: true,
  title: '',
  description: '',
  updated_at: '',
  id: 0,
  genre: ComponentGenre.chart
}

const basicConfigSlice = createSlice({
  name: '@COMPONENT/BASIC_CONFIG',
  initialState,
  reducers: {
    updateTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload
    },
    updateDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload
    },
    updateGenre: (state, action: PayloadAction<ComponentGenre>) => {
      state.genre = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(initComponentManagement, (_state, action) => {
      if (!action.payload) return _.cloneDeep(initialState)
      return _.merge(
        _.pick(action.payload, ['title', 'description', 'updated_at', 'id', 'genre']),
        { pending: false }
      )
    })
  }
})

// Actions
export const { updateTitle, updateDescription, updateGenre } = basicConfigSlice.actions

// Reducer
export const basicConfigReducer = basicConfigSlice.reducer

// Selectors
export const selectPending = (state: RootState) => state.component.basicConfig.pending
export const selectBasicConfig = (state: RootState) => state.component.basicConfig
export const selectTitle = (state: RootState) => selectBasicConfig(state).title
export const selectDescription = (state: RootState) => selectBasicConfig(state).description
export const selectGenre = (state: RootState) => selectBasicConfig(state).genre
export const selectUpdatedAt = (state: RootState) => selectBasicConfig(state).updated_at

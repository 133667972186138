import { useQuery } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'

import type { IComponentData } from '../types'
import type { ComponentGenre, ComponentScope, ComponentStatus } from '../types/component-types'

type Params = Partial<{
  page: number
  items: number
  search: string
  status: ComponentStatus
  genre: ComponentGenre
  sort: string
  scope: ComponentScope
}>

export const fetchComponents = async (
  business_id: number,
  params: Params
): Promise<{ results: IComponentData[]; count: number }> => {
  const response = await axiosServices.get(`/businesses/${business_id}/components`, { params })
  return response.data
}

export const useFetchComponents = (params: Params) => {
  const { user } = useAuth()
  const business_id = user?.business_id!
  return useQuery({
    queryKey: ['fetch-components', business_id, params],
    queryFn: () => fetchComponents(business_id, params),
    enabled: !!business_id
  })
}

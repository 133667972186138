export const DragIndicator = () => (
  <g>
    <path
      d='M9.5 9C9.79667 9 10.0867 8.91203 10.3334 8.7472C10.58 8.58238 10.7723 8.34811 10.8858 8.07402C10.9994 7.79994 11.0291 7.49834 10.9712 7.20736C10.9133 6.91639 10.7704 6.64912 10.5607 6.43934C10.3509 6.22956 10.0836 6.0867 9.79264 6.02882C9.50166 5.97094 9.20006 6.00065 8.92598 6.11418C8.65189 6.22771 8.41762 6.41997 8.2528 6.66665C8.08797 6.91332 8 7.20333 8 7.5C8.00119 7.89746 8.1596 8.2783 8.44065 8.55935C8.7217 8.8404 9.10254 8.99881 9.5 9ZM9.5 10.5C9.20333 10.5 8.91332 10.588 8.66665 10.7528C8.41997 10.9176 8.22771 11.1519 8.11418 11.426C8.00065 11.7001 7.97094 12.0017 8.02882 12.2926C8.0867 12.5836 8.22956 12.8509 8.43934 13.0607C8.64912 13.2704 8.91639 13.4133 9.20736 13.4712C9.49834 13.5291 9.79994 13.4993 10.074 13.3858C10.3481 13.2723 10.5824 13.08 10.7472 12.8334C10.912 12.5867 11 12.2967 11 12C10.9988 11.6025 10.8404 11.2217 10.5593 10.9406C10.2783 10.6596 9.89746 10.5012 9.5 10.5ZM9.5 15C9.20333 15 8.91332 15.088 8.66665 15.2528C8.41997 15.4176 8.22771 15.6519 8.11418 15.926C8.00065 16.2001 7.97094 16.5017 8.02882 16.7926C8.0867 17.0836 8.22956 17.3509 8.43934 17.5607C8.64912 17.7704 8.91639 17.9133 9.20736 17.9712C9.49834 18.029 9.79994 17.9993 10.074 17.8858C10.3481 17.7723 10.5824 17.58 10.7472 17.3334C10.912 17.0867 11 16.7967 11 16.5C10.9988 16.1025 10.8404 15.7217 10.5593 15.4406C10.2783 15.1596 9.89746 15.0012 9.5 15Z'
      fill='currentColor'
    />
    <path
      d='M14.5 9C14.7967 9 15.0867 8.91203 15.3334 8.7472C15.58 8.58238 15.7723 8.34811 15.8858 8.07402C15.9994 7.79994 16.0291 7.49834 15.9712 7.20736C15.9133 6.91639 15.7704 6.64912 15.5607 6.43934C15.3509 6.22956 15.0836 6.0867 14.7926 6.02882C14.5017 5.97094 14.2001 6.00065 13.926 6.11418C13.6519 6.22771 13.4176 6.41997 13.2528 6.66665C13.088 6.91332 13 7.20333 13 7.5C13.0012 7.89746 13.1596 8.2783 13.4407 8.55935C13.7217 8.8404 14.1025 8.99881 14.5 9ZM14.5 10.5C14.2033 10.5 13.9133 10.588 13.6666 10.7528C13.42 10.9176 13.2277 11.1519 13.1142 11.426C13.0006 11.7001 12.9709 12.0017 13.0288 12.2926C13.0867 12.5836 13.2296 12.8509 13.4393 13.0607C13.6491 13.2704 13.9164 13.4133 14.2074 13.4712C14.4983 13.5291 14.7999 13.4993 15.074 13.3858C15.3481 13.2723 15.5824 13.08 15.7472 12.8334C15.912 12.5867 16 12.2967 16 12C15.9988 11.6025 15.8404 11.2217 15.5593 10.9406C15.2783 10.6596 14.8975 10.5012 14.5 10.5ZM14.5 15C14.2033 15 13.9133 15.088 13.6666 15.2528C13.42 15.4176 13.2277 15.6519 13.1142 15.926C13.0006 16.2001 12.9709 16.5017 13.0288 16.7926C13.0867 17.0836 13.2296 17.3509 13.4393 17.5607C13.6491 17.7704 13.9164 17.9133 14.2074 17.9712C14.4983 18.029 14.7999 17.9993 15.074 17.8858C15.3481 17.7723 15.5824 17.58 15.7472 17.3334C15.912 17.0867 16 16.7967 16 16.5C15.9988 16.1025 15.8404 15.7217 15.5593 15.4406C15.2783 15.1596 14.8975 15.0012 14.5 15Z'
      fill='currentColor'
    />
  </g>
)

import {
  ChartErrorWidget,
  ChartLoadingWidget,
  ChartNoDataWidget
} from 'components/chart/chart-container'
import useChangeDetection from 'hooks/use-change-detection'

import DynamicChart from '../common/dynamic-chart'
import useTreemapPropsRetriever from '../treemap-builder/useTreemapPropsRetriever'
import { ITreemapConfig } from '../types/treemap-builder-types'
import { ComponentPreviewProps } from './types'

function TreemapContent(props: Pick<Required<ComponentPreviewProps>, 'isFetching' | 'data'>) {
  const { data, isFetching: refetching } = props
  const { title, config, metadata, results, drilldowns } = data
  const chartProps = useTreemapPropsRetriever({
    data: results,
    metadata,
    config: config as ITreemapConfig
  })!
  const [chartKey, triggerReinit] = useChangeDetection()

  return (
    <DynamicChart
      key={chartKey}
      {...{
        ...chartProps,
        title,
        refetching,
        triggerReinit,
        drilldowns
      }}
    />
  )
}

const Treemap = ({ loading, errorMessage, isFetching, title, data }: ComponentPreviewProps) => {
  const noResults = _.isEqual(_.size(_.get(data, 'results', [])), 0)

  if (loading) {
    return <ChartLoadingWidget title={title} />
  }

  if (errorMessage) {
    return <ChartErrorWidget title={title} errorMessage={errorMessage} />
  }

  if (!data || noResults) {
    return <ChartNoDataWidget title={title} />
  }

  return <TreemapContent {...{ data, isFetching }} />
}

export default Treemap

export const HiddenNorthEast = () => (
  <>
    <g id='Icon'>
      <g id='Union'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.4124 18.8378L12.5369 20.1029L13.2092 19.5052L5.33691 10.6489L4.6646 11.2465L5.9229 12.6621C5.02203 13.3372 4.31381 14.2679 3.89844 15.35C4.76344 17.6035 6.89844 19.2 9.39844 19.2C10.1058 19.2 10.784 19.0722 11.4124 18.8378ZM10.4021 17.7012L9.66033 16.8667C9.57531 16.882 9.4878 16.89 9.39844 16.89C8.56844 16.89 7.89844 16.2021 7.89844 15.35C7.89844 15.2093 7.91671 15.0731 7.95094 14.9437L7.20955 14.1096C7.01131 14.4773 6.89844 14.9002 6.89844 15.35C6.89844 16.7668 8.01844 17.9167 9.39844 17.9167C9.75534 17.9167 10.0948 17.8398 10.4021 17.7012Z'
          fill='currentColor'
        />
        <path
          d='M14.8984 15.35C14.4864 16.4235 13.7861 17.3479 12.8955 18.0216L11.6008 16.5651C11.7907 16.2033 11.8984 15.7895 11.8984 15.35C11.8984 13.9332 10.7784 12.7833 9.39844 12.7833C9.0515 12.7833 8.721 12.856 8.4206 12.9873L7.41154 11.8521C8.03236 11.6242 8.70121 11.5 9.39844 11.5C11.8984 11.5 14.0334 13.0965 14.8984 15.35Z'
          fill='currentColor'
        />
        <path
          d='M10.8984 15.35C10.8984 15.4793 10.883 15.6049 10.8539 15.7248L9.16785 13.828C9.24299 13.8161 9.32 13.81 9.39844 13.81C10.2284 13.81 10.8984 14.4979 10.8984 15.35Z'
          fill='currentColor'
        />
      </g>
      <path
        id='Subtract'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.6484 7C10.6484 6.44772 11.0962 6 11.6484 6H18.8984V13.25C18.8984 13.8023 18.4507 14.25 17.8984 14.25C17.3462 14.25 16.8984 13.8023 16.8984 13.25V9.41421L14.0073 12.3054C13.5065 11.8455 12.9393 11.4587 12.3215 11.1627L15.4842 8H11.6484C11.0962 8 10.6484 7.55228 10.6484 7ZM10.4589 13.0254L11.5633 11.921C12.2045 12.1808 12.7908 12.5527 13.2996 13.013L11.7716 14.541C11.5546 13.8705 11.0753 13.3225 10.4589 13.0254ZM10.8972 15.4154L10.7287 15.5839L9.39768 14.0865L9.65225 13.832C10.3609 13.9553 10.8985 14.5868 10.8985 15.3502L10.8985 15.3594C10.8984 15.3729 10.8982 15.3863 10.8978 15.3997C10.8976 15.405 10.8974 15.4102 10.8972 15.4154ZM8.84822 14.636L10.1792 16.1334L9.42269 16.89C9.41465 16.8901 9.4066 16.8902 9.39853 16.8902C8.64143 16.8902 8.01746 16.3178 7.91369 15.5705L8.84822 14.636ZM7.11011 16.3846C6.803 16.777 6.83007 17.3458 7.19133 17.7071C7.56221 18.078 8.15192 18.0966 8.54482 17.7631C7.90332 17.5236 7.38406 17.022 7.11011 16.3846Z'
        fill='currentColor'
      />
    </g>
  </>
)

import * as am5 from '@amcharts/amcharts5'

import { IModule, IModuleAttributes, ModuleName } from '../types/module-types'
import { assignRef, getRef } from '../utils/module-utils'

const moduleName = ModuleName.dataProcessor
const dataProcessorModule: IModule<ModuleName.dataProcessor> = {
  name: moduleName
}

dataProcessorModule.init = (props) => {
  const root: am5.Root = getRef({ ...props, moduleName: ModuleName.root })

  const {
    options: { dateFields = [], numericFields = [] }
  } = props

  let dataProcessor

  if (!!dateFields.length || !!numericFields.length) {
    dataProcessor = am5.DataProcessor.new(root, {
      dateFields,
      numericFields,
      emptyAs: 0,
      dateFormat: 'yyyy-MM-dd'
    })
  }

  assignRef({ ...props, moduleName, item: dataProcessor })
}

export const dataProcessorAttributes: IModuleAttributes<ModuleName.dataProcessor> = (p) => ({
  module: dataProcessorModule,
  ...p
})

export default dataProcessorModule

import React from 'react'

import { ICellRendererParams } from 'ag-grid-community'

import { Button } from '@components/core/button'
import NewWindow from '@components/icons/new-window.svg?react'

interface OpenInNewTabCellRendererProps extends ICellRendererParams {
  onActionButtonClick?: (params: ICellRendererParams) => void
}

const OpenInNewTabCellRenderer: React.FC<OpenInNewTabCellRendererProps> = (params) => {
  const onActionButtonClick = () => {
    if (params.onActionButtonClick) {
      params.onActionButtonClick(params)
    } else {
      window.open(`${window.location.href}/${params.node.data.id}`, '_blank')
      params.api.deselectAll()
    }
  }

  return (
    <div id='new-tab-cell-renderer' className='flex size-full items-center'>
      <Button
        onClick={onActionButtonClick}
        variant={params.node.isSelected() ? 'primary' : 'text'}
        className='cursor-pointer p-0'
      >
        <NewWindow />
      </Button>
    </div>
  )
}

export default OpenInNewTabCellRenderer

import { Backdrop, CircularProgress } from '@mui/material'

import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import Snackbar from 'components/@extended/Snackbar'
import Locales from 'components/Locales'
import ScrollTop from 'components/ScrollTop'
import { JWTProvider as AuthProvider } from 'contexts/JWTContext'
import { AgGridRefProvider } from 'contexts/grid-ref-context'
import usePreventContextMenuOnHtml from 'hooks/use-prevent-context-menu-on-html'
import { useFetchBrowsablePages } from 'queries/pages/fetch-browsable-pages'
import { queryClient } from 'queries/query-client'
import Routes from 'routes'
import ChargerThemeProvider from 'themes'
import 'utils/logrocket-setup'

import ActivityLogger from './components/activity-logger'
import RedirectionController from './utils/route-guard/RedirectionController'

import './index.css'

const App = () => {
  usePreventContextMenuOnHtml()

  return (
    <AgGridRefProvider>
      <ChargerThemeProvider>
        <Locales>
          <ScrollTop>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} buttonPosition='bottom-left' />
              <AuthProvider>
                <ActivityLogger>
                  <>
                    <RoutesWrapper />
                    <Snackbar />
                  </>
                </ActivityLogger>
              </AuthProvider>
            </QueryClientProvider>
          </ScrollTop>
        </Locales>
      </ChargerThemeProvider>
    </AgGridRefProvider>
  )
}

const RoutesWrapper = () => {
  const { isLoading: fetchingRoutes, data: pages } = useFetchBrowsablePages()

  return (
    <RedirectionController enabled={!fetchingRoutes}>
      <>
        {fetchingRoutes && (
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
            <CircularProgress color='inherit' />
          </Backdrop>
        )}
        <Routes isFetchingPages={fetchingRoutes} pages={pages} />
      </>
    </RedirectionController>
  )
}

export default App

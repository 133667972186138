import { RefObject, useState } from 'react'

const ROW_HEIGHT = 21

// maximum rows in an ag grid body before scrolling starts
export const useMaxRows = (wrapperRef?: RefObject<HTMLDivElement>) => {
  const getMaxRows = () => {
    const body = wrapperRef?.current
      ? wrapperRef.current.getElementsByClassName('ag-body')[0]
      : null

    // wrapperRef can be undefined during initialization, in that case we use window viewport height as an estimation
    const rows = body
      ? Math.floor(body.clientHeight / ROW_HEIGHT)
      : Math.floor(window.innerHeight / ROW_HEIGHT)
    return rows
  }

  const [maxRows, setMaxRows] = useState(getMaxRows())
  const refetch = () => setMaxRows(getMaxRows())

  return {
    maxRows,
    refetch
  }
}

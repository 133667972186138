// import PageUnLocked from '../../../assets/images/layout-builder/2-page_dynamic.png';
// import PageUnLocked from '../../../assets/images/layout-builder/page_unlocked_2.png';
// import PageUnLocked from '../../../assets/images/layout-builder/1-page_unlocked.png';
import { GridOnOutlined } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

export const DynamicPageIcon = (props: any) => {
  const theme = useTheme()
  return (
    <GridOnOutlined
      style={{ fontSize: '1.2rem', color: props.color ? props.color : theme.palette.primary.main }}
    />
  )

  // return <img src={PageUnLocked} alt="PageLocked" width="25px" height="25px" />;
}
// <Chip
//   label="Dynamic"
//   color="primary"
//   size="small"
//   variant="outlined"
//   style={{ borderRadius: '12px', marginLeft: '8px' }}
// />

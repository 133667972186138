import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

import { useLatestRef } from 'hooks/useLatestRef'

import { useSetInstanceData } from '../hooks'
import { CATEGORY_AXIS_CHART_RATIO } from '../types/data-types'
import { IModule, IModuleAttributes, IModuleUseInstance, ModuleName } from '../types/module-types'
import { setAxisCellStartEndLocation } from '../utils/clustered-series-utils'
import convertCategoryLabelsToString from '../utils/convert-category-labels-to-string'
import { assignRef, getRef } from '../utils/module-utils'
import { titlePositionAdjustment } from '../utils/title-position-adjustment'

const moduleName = ModuleName.categoryYAxis

const categoryYAxisModule: IModule<ModuleName.categoryYAxis> = {
  name: moduleName
}

categoryYAxisModule.init = (props) => {
  const root: am5.Root = getRef({ ...props, moduleName: ModuleName.root })
  const chart: am5xy.XYChart = getRef({ ...props, moduleName: ModuleName.xyChart })
  const tooltip: am5.Tooltip = getRef({ ...props, moduleName: ModuleName.tooltip })
  const headingContainer: am5.Container = getRef({
    ...props,
    moduleName: ModuleName.headingContainer
  })

  const {
    options: {
      yAxisKey = 'category',
      inversed = false,
      opposite = false,
      // global options
      clusteredSeries = false
    }
  } = props

  const yRenderer = am5xy.AxisRendererY.new(root, {
    inversed,
    minorGridEnabled: true,
    minGridDistance: 1,
    opposite
  })

  if (clusteredSeries) {
    setAxisCellStartEndLocation(yRenderer)
  }

  yRenderer.labels.template.setAll({
    oversizedBehavior: 'truncate',
    ellipsis: '',
    textAlign: 'right',
    fontSize: '11px'
  })
  yRenderer.grid.template.set('visible', false)

  const yAxis = chart.yAxes.push(
    am5xy.CategoryAxis.new(root, {
      maxDeviation: 0,
      categoryField: yAxisKey,
      renderer: yRenderer,
      tooltip
    })
  )

  convertCategoryLabelsToString({ renderer: yRenderer, axisKey: yAxisKey })

  if (!opposite && headingContainer) {
    titlePositionAdjustment({ headingContainer, yAxis, chart })
  }

  chart.yAxesAndPlotContainer.events.on('boundschanged', function () {
    const chartContainerWidth = chart.yAxesAndPlotContainer.width()
    const yLabelWidth = _.round(CATEGORY_AXIS_CHART_RATIO * chartContainerWidth)
    yRenderer.labels.template.set('width', yLabelWidth)
    yRenderer.labels.template.set('maxWidth', yLabelWidth)
  })

  assignRef({ ...props, moduleName, item: yAxis })
}

const useInstance: IModuleUseInstance<ModuleName.categoryYAxis> = (instance, props) => {
  const propsRef = useLatestRef(props)

  useSetInstanceData({ instance, moduleName, propsRef })
}

categoryYAxisModule.useInstance = useInstance

export const categoryYAxisAttributes: IModuleAttributes<ModuleName.categoryYAxis> = (p) => ({
  module: categoryYAxisModule,
  ...p
})

export default categoryYAxisModule

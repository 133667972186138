import { Theme } from '@mui/material/styles'

export default function Switch(theme: Theme) {
  return {
    MuiSwitch: {
      styleOverrides: {
        track: {
          padding: 0,
          opacity: 1,
          backgroundColor: theme.palette.secondary[400],
          boxSizing: 'border-box'
        },
        thumb: {
          padding: 0,
          borderRadius: '50%',
          transition: theme.transitions.create(['width'], {
            duration: 200
          })
        },
        switchBase: {
          '&.Mui-checked': {
            color: theme.palette.primary.main,
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.primary.light,
              opacity: 1
            },
            '&.Mui-disabled': {
              color: theme.palette.background.paper
            }
          },
          '&.Mui-disabled': {
            color: theme.palette.background.paper,
            '+.MuiSwitch-track': {
              opacity: 0.3
            }
          }
        },
        sizeActionBar: {
          height: '1.75rem'
        }
      }
    }
  }
}

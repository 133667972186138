import { useEffect, useMemo, useRef, useState } from 'react'

import { ColDef, RowClassParams, RowStyle } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { displayDuration } from '@utils/date-utils'
import { formatNumber } from '@utils/number-utils'
import { FAILURE_ROW_COLOR } from '@utils/style-utils'

import Button from '@core/button'
import { Separator } from '@core/separator'

import AgGrid from '@components/ag-grid'
import { PythonCellRenderer } from '@components/ag-grid/custom-cell-renderer/python-cell-renderer'
import { AG_RIGHT_ALIGNED_CELL } from '@components/ag-grid/types'
import AgGridSearchInput from '@components/control-panel/ag-grid-search-input'

import JobDetail from '@layout-components/general/data-connections/shared/data-movement-jobs/job-detail'
import PendingStreams from '@layout-components/general/data-connections/shared/data-movement-jobs/pending-streams'
import {
  dateColumnDef,
  multiTextColumnDef
} from '@layout-components/general/data-connections/utils/col-defs'

import { CHARGER_MONOSPACE_CLASS } from 'config'

import { AgGridRef, useRegisterAgGridRefEffect } from '../../../contexts/grid-ref-context'
import { useFetchJobHistory } from './queries/fetch-job-history'
import { SyncStatusCellRenderer } from './sync-status-cell-renderer'
import { IConnectedSourceSystem, JobData, LAST_SYNC_STATUS } from './types'

const ClickableCellRenderer = (params: any) => {
  return (
    <div className='cursor-pointer text-blue underline hover:text-primary-darker'>
      {params.value}
    </div>
  )
}

export function JobHistory({
  connectedSourceSystem,
  isPending
}: {
  connectedSourceSystem?: IConnectedSourceSystem
  isPending: boolean
}) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const gridRef = useRef<AgGridReact>(null)
  const [groupByStreamStatus, setGroupByStreamStatus] = useState(false)
  const [streamStatusData, setStreamStatusData] = useState<JobData | null>(null)

  const { isPending: isPendingJobs, data } = useFetchJobHistory({
    id: connectedSourceSystem?.id,
    businessId: connectedSourceSystem?.businessId
  })

  useRegisterAgGridRefEffect(gridRef as AgGridRef)

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        field: 'jobId',
        headerName: 'Job ID',
        initialWidth: 100,
        maxWidth: 350
      },
      multiTextColumnDef('streamStatusesId', 'Stream Status ID', {
        initialWidth: 80,
        maxWidth: 140,
        rowGroup: groupByStreamStatus,
        cellRenderer: ClickableCellRenderer,
        hide: groupByStreamStatus
      }),
      dateColumnDef('startTime', 'Start Time', { initialWidth: 150 }),
      dateColumnDef('endTime', 'End Time', { initialWidth: 150 }),
      {
        colId: 'duration',
        headerName: 'Duration',
        valueGetter: (params) => {
          if (params.data && params.data.startTime && params.data.endTime) {
            return displayDuration(params.data.startTime, params.data.endTime)
          }
          return ''
        },
        maxWidth: 150,
        initialWidth: 100
      },
      {
        field: 'numOfRecordsRead',
        headerName: 'Records Read',
        type: 'rightAligned',
        cellClass: `${CHARGER_MONOSPACE_CLASS} ${AG_RIGHT_ALIGNED_CELL}`,
        valueFormatter: (params) => (params.value ? formatNumber(params.value) : ''),
        initialWidth: 150,
        maxWidth: 150
      },
      multiTextColumnDef('status', 'Status', {
        cellRenderer: SyncStatusCellRenderer,
        maxWidth: 80,
        valueGetter: (params) => {
          if (params.data && params.data.status) {
            return LAST_SYNC_STATUS[params.data.status] || null
          }
          return null
        },
        initialWidth: 80
      }),
      {
        field: 'failureReason',
        headerName: 'Reason',
        cellRenderer: PythonCellRenderer,
        initialWidth: 100,
        maxWidth: 400
      }
    ],
    [groupByStreamStatus]
  )

  const isLoading = isPending || isPendingJobs

  const getRowStyle = (params: RowClassParams<any, any>): RowStyle | undefined => {
    if (params.data?.status === 'failed') {
      return { backgroundColor: FAILURE_ROW_COLOR }
    }
    return undefined
  }

  const handleGroupByStreamStatus = () => {
    const gridApi = gridRef.current?.api
    if (!gridApi) return

    const currentColumnState = gridApi.getColumnState()

    setGroupByStreamStatus((prevState) => {
      const newGroupByStreamStatus = !prevState

      const newState = currentColumnState.map((state) => {
        if (state.colId === 'streamStatusesId') {
          return {
            ...state,
            rowGroup: newGroupByStreamStatus,
            hide: !newGroupByStreamStatus
          }
        }
        return state
      })

      gridApi.applyColumnState({ state: newState })

      if (newGroupByStreamStatus) {
        gridApi.expandAll()
      }

      gridApi.onGroupExpandedOrCollapsed()

      return newGroupByStreamStatus
    })
  }

  useEffect(() => {
    const gridApi = gridRef.current?.api
    if (gridApi) {
      gridApi.expandAll()
    }
  }, [data])

  const getGridWidths = () => {
    if (!streamStatusData) {
      return {
        firstGridWidth: '100%',
        secondGridWidth: '0%',
        thirdGridWidth: '0%'
      }
    }

    const streamStatusesStreamStates = streamStatusData.streamStatusesStreamStates || '{}'
    const streamStatusesPendingStreams = streamStatusData.streamStatusesPendingStreams || '[]'

    const hasStreamStates = Object.keys(JSON.parse(streamStatusesStreamStates)).length > 0
    const hasPendingStreams = JSON.parse(streamStatusesPendingStreams).length > 0

    if (hasStreamStates && hasPendingStreams) {
      return {
        firstGridWidth: '60%',
        secondGridWidth: '30%',
        thirdGridWidth: '10%'
      }
    } else if (hasStreamStates && !hasPendingStreams) {
      return {
        firstGridWidth: '60%',
        secondGridWidth: '40%',
        thirdGridWidth: null
      }
    } else if (!hasStreamStates && hasPendingStreams) {
      return {
        firstGridWidth: '60%',
        secondGridWidth: null,
        thirdGridWidth: '15%'
      }
    } else {
      return {
        firstGridWidth: '100%',
        secondGridWidth: null,
        thirdGridWidth: null
      }
    }
  }

  const { firstGridWidth, secondGridWidth, thirdGridWidth } = getGridWidths()

  return (
    <div className='h-[calc(100vh-16rem)] w-full' ref={wrapperRef}>
      <div className='mb-2 flex min-h-11 w-full items-center space-x-2'>
        <AgGridSearchInput />
        <Separator orientation='vertical' className='h-10 text-grey-lighter' />
        <Button variant='outline' onClick={handleGroupByStreamStatus} className='h-7'>
          {groupByStreamStatus ? 'Show All Jobs' : 'Organize by Parallel Jobs'}
        </Button>
      </div>
      <Separator className='w-full' />

      <div className='mt-4 flex' style={{ height: '100%', width: '100%' }}>
        <AgGrid
          style={{ height: '100%', width: firstGridWidth }}
          ref={gridRef}
          rowData={data}
          columnDefs={columnDefs}
          defaultColDef={{
            minWidth: 50,
            resizable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            filterParams: {
              buttons: ['clear']
            }
          }}
          autoGroupColumnDef={multiTextColumnDef('streamStatusesId', 'Stream', {
            maxWidth: 150,
            initialWidth: 80,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
              innerRenderer: (params: any) => {
                if (params.node.group) {
                  return <ClickableCellRenderer {...params} />
                }
                return ''
              }
            }
          })}
          autoSizeStrategy={{ type: 'fitCellContents' }}
          loading={isLoading}
          getRowStyle={getRowStyle}
          groupDisplayType='singleColumn'
          groupRemoveSingleChildren={false}
          groupRemoveLowestSingleChildren={false}
          groupDefaultExpanded={-1}
          suppressAggFuncInHeader={true}
          onCellClicked={(params) => {
            if (params.colDef.field === 'streamStatusesId') {
              if (params.data) {
                setStreamStatusData(params.data as JobData)
              } else if (params.node?.allLeafChildren && params.node.allLeafChildren.length > 0) {
                setStreamStatusData(params.node.allLeafChildren[0].data as JobData)
              } else {
                setStreamStatusData(null)
              }
            }
          }}
        />

        {streamStatusData?.streamStatusesStreamStates && secondGridWidth && (
          <JobDetail
            streamStatusesId={streamStatusData.streamStatusesId}
            gridWidth={secondGridWidth}
            streamStatusesStreamStates={streamStatusData.streamStatusesStreamStates}
          />
        )}

        {streamStatusData?.streamStatusesPendingStreams && thirdGridWidth && (
          <PendingStreams
            streamStatusesId={streamStatusData.streamStatusesId}
            gridWidth={thirdGridWidth}
            streamStatusesPendingStreams={streamStatusData.streamStatusesPendingStreams}
          />
        )}
      </div>
    </div>
  )
}

import { useEffect } from 'react'

/**
 * Custom hook to prevent the default context menu from appearing in the <html> element.
 *
 * Background:
 * - Amcharts uses canvas to render charts, so to show context menu we need to trigger them manually.
 * - Amcharts detects right-click on the canvas and provides a callback to trigger the context menu.
 *
 * Problem:
 * - In Windows, the initial trigger event is sent to the root <html> element, which causes default browser context menu to appear on the top of our context menu.
 *
 * Solution:
 * - This hook intercepts the right-click event on the root <html> element to prevent the browser's default context menu from appearing.
 */
export default function usePreventContextMenuOnHtml() {
  useEffect(() => {
    function preventContextMenuOnHtml(event: MouseEvent) {
      const element = event.target as HTMLElement
      if (!element) return
      if (element.nodeName === 'HTML') {
        event.preventDefault()
      }
      return false
    }
    document.addEventListener('contextmenu', preventContextMenuOnHtml, false)

    return () => {
      document.removeEventListener('contextmenu', preventContextMenuOnHtml, false)
    }
  }, [])
}

import { IRowNode } from 'ag-grid-community'

import { FinancialHierarchyNode, GlAccountNodeIdentifierEnum } from '../types'

interface NodeIdentifier {
  identifier: string
}

export function isPointerNode<T extends NodeIdentifier>(node?: T): boolean {
  return node?.identifier === GlAccountNodeIdentifierEnum.pointer
}

export function isTotal<T extends NodeIdentifier>(node?: T): boolean {
  return node?.identifier === GlAccountNodeIdentifierEnum.total
}

export function isTotalNode(node: IRowNode<FinancialHierarchyNode>): boolean {
  return isTotal(node.data)
}

export function isFormulaNode<T extends NodeIdentifier>(node?: T): boolean {
  return node?.identifier === GlAccountNodeIdentifierEnum.formula
}

export function isHeadingNode<T extends NodeIdentifier>(node?: T): boolean {
  return node?.identifier === GlAccountNodeIdentifierEnum.heading
}

export function isHeadingOrPointerNode<T extends NodeIdentifier>(node?: T): boolean {
  return isHeadingNode(node) || isPointerNode(node)
}

export function isHeadingPointerOrTotalNode<T extends NodeIdentifier>(node?: T): boolean {
  return isHeadingOrPointerNode(node) || isTotal(node)
}

export function isFirstLevelNode(path: string): boolean {
  return _.split(path, '.').length <= 1
}

export function isSpacer<T extends NodeIdentifier>(node?: T): boolean {
  return _.isEqual(node?.identifier, GlAccountNodeIdentifierEnum.spacer)
}

export function isSpacerNode(node: IRowNode<FinancialHierarchyNode>) {
  return isSpacer(node.data)
}

export function isGeneralLedgerAccountNode<T extends NodeIdentifier>(node?: T): boolean {
  return _.isEqual(node?.identifier, GlAccountNodeIdentifierEnum.general_ledger_account)
}

import { useQuery } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'

const fetchPages = async (businessId: number | undefined, activeStatus?: 'active' | 'inactive') => {
  const response = await axiosServices.get(`/businesses/${businessId}/pages`, {
    params: { activeStatus }
  })
  return response.data
}

export const useFetchPagesTree = (activeStatus?: 'active' | 'inactive') => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-pages-tree', user?.business_id, activeStatus],
    queryFn: () => fetchPages(user?.business_id, activeStatus),
    staleTime: 600_000
  })
}

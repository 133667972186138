import { ComponentGenre } from '../types/component-types'
import Chart from './chart'
import Table from './table'
import Treemap from './treemap'

export const getComponentForGenre = (genre: string) => {
  switch (genre) {
    case ComponentGenre.chart:
      return Chart
    case ComponentGenre.table:
      return Table
    case ComponentGenre.treemap:
      return Treemap
    default:
      throw new Error(`Unsupported genre: ${genre}`)
  }
}

import { Box } from '@mui/material'

export interface CustomTabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  uniqueId?: string
  padding?: number
}

export default function CustomTabPanel(props: CustomTabPanelProps) {
  const { children, value, index, uniqueId = 'config', padding = 2, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`${uniqueId}-tabpanel-${index}`}
      aria-labelledby={`${uniqueId}-tab-${index}`}
      {...other}
    >
      {value === index && <Box padding={padding}>{children}</Box>}
    </div>
  )
}

// TODO: @RTK Refactor this to use the slices pattern
// types
import { createAction } from '@reduxjs/toolkit'

import { AuthActionProps, AuthProps } from 'types/auth'

import {
  HANDLE_AUTOMAP_UPDATE,
  HANDLE_BUSINESS_SELECTOR_CHANGE,
  REVERSE_MASQUARADE
} from './actions'
// action - state management
import { LOGIN, LOGOUT, MASQUARADE, REGISTER } from './actions'

// initial state
export const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  isMasquarade: false,
  features: [],
  user: { roles: [''], businesses: [] }
}

const auth = (state = initialState, action: AuthActionProps) => {
  switch (action.type) {
    case REGISTER: {
      const { user } = action.payload!
      return {
        ...state,
        user
      }
    }
    case LOGIN: {
      const { user, features } = action.payload!

      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        isMasquarade: false,
        features,
        user
      }
    }
    case LOGOUT: {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null,
        features: []
      }
    }
    case MASQUARADE: {
      const { user } = action.payload!
      return {
        ...state,
        isLoggedIn: true,
        isMasquarade: true,
        user: user
      }
    }
    case REVERSE_MASQUARADE: {
      const { user } = action.payload!
      return {
        ...state,
        isLoggedIn: true,
        isMasquarade: false,
        user: user
      }
    }
    case HANDLE_BUSINESS_SELECTOR_CHANGE: {
      const { user } = action.payload!

      return {
        ...state,
        user
      }
    }
    case HANDLE_AUTOMAP_UPDATE: {
      const { user } = action.payload!
      return {
        ...state,
        user: {
          ...user,
          businesses: user?.businesses
        }
      }
    }
    default: {
      return { ...state }
    }
  }
}

export const handleAutomapUpdate = createAction<{ user: any }>(HANDLE_AUTOMAP_UPDATE)

export default auth

import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import {
  FormHelperText,
  List,
  ListItem,
  ListItemButton,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import { UniqueIdentifier } from '@dnd-kit/core'

import DialogTitle from '@components/DialogTitle'
import { IconButton } from '@components/core/icon-button'
import { Cancel, Icon, Plus } from '@components/icons'
import { SortableList } from '@components/sortable-list'

import { useDispatch } from '@store/index'
import { updateExternalFilter } from '@store/slices/component/table-config'

import { ErrorMessage, Field, Form, Formik } from 'formik'
import { nanoid } from 'nanoid'
import * as Yup from 'yup'

import { IFilter, ISelectFilterConfigOption } from '../../types/table-builder-types'

interface ChooserProps {
  item?: IFilter
  columnField: string
  onSubmit: () => void
  onClose: () => void
}

interface ExtendedListItem {
  id: UniqueIdentifier
  label: string
  value: string | undefined
}

const validationSchema = Yup.object({
  options: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().required(),
      value: Yup.string().optional(),
      label: Yup.string().optional()
    })
  ),
  defaultValue: Yup.string().nullable().optional()
})

const WrapperComponent = ({ children }: { children?: React.ReactNode }) => (
  <List sx={{ width: '100%' }}>{children}</List>
)

const SortableItems = ({
  values,
  onChange,
  onRemove
}: {
  values: ISelectFilterConfigOption[]
  onChange: (values: ISelectFilterConfigOption[]) => void
  onRemove: (id: string) => void
}) => {
  const valueToListItem = (value: ISelectFilterConfigOption): ExtendedListItem => ({
    id: value.id as string,
    value: value.value,
    label: value.label
  })

  const listItemToValue = (listItem: ExtendedListItem): ISelectFilterConfigOption =>
    values.find((v) => v.id === listItem.id)!

  const valuesForList = values.map((v) => valueToListItem(v))

  const handleOptionChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    itemId: UniqueIdentifier
  ) => {
    onChange(values.map((v) => (v.id === itemId ? { ...v, [e.target.name]: e.target.value } : v)))
  }

  return (
    <SortableList
      items={valuesForList}
      setItems={(listItems) => onChange(listItems.map((listItem) => listItemToValue(listItem)))}
      renderItem={({ draggableProps, setNodeRef, style, item, id }) => (
        <ListItem ref={setNodeRef} divider disablePadding sx={{ ...style }}>
          <ListItemButton>
            <Stack direction='row' width='100%' alignItems='center'>
              <IconButton className='px-0' {...draggableProps}>
                <DragIndicatorIcon />
              </IconButton>
              <TextField
                name='label'
                label='Label'
                style={{ flex: 1 }}
                value={item.label}
                onChange={(e) => handleOptionChange(e, item.id)}
              />
              <TextField
                name='value'
                label='Value'
                style={{ flex: 1 }}
                value={item.value}
                onChange={(e) => handleOptionChange(e, item.id)}
              />
              <IconButton onClick={() => onRemove(id as string)}>
                <Icon icon={<Cancel />} />
              </IconButton>
            </Stack>
          </ListItemButton>
        </ListItem>
      )}
      WrapperComponent={WrapperComponent}
    />
  )
}

export const Chooser = (props: ChooserProps) => {
  const { item, columnField, onClose, onSubmit } = props
  const dispatch = useDispatch()

  return (
    <Formik
      initialValues={item as IFilter}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        const config = {
          filterType: values.filterType,
          options: values.options,
          defaultValue: values.defaultValue
        }
        dispatch(updateExternalFilter({ field: columnField, filterConfig: config }))
        onSubmit()
        setSubmitting(false)
      }}
    >
      {({ values, touched, errors, setFieldValue, handleChange }) => (
        <Form id='filter-chooser-form'>
          <Dialog open onClose={onClose}>
            <DialogTitle onClose={onClose}>Choose Filter Type</DialogTitle>
            <DialogContent>
              <Stack>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Stack spacing={0}>
                    <Typography variant='h5'>Options</Typography>
                    <Typography variant='details'>Rearrange options order</Typography>
                  </Stack>
                  <Stack direction='row' spacing={1}>
                    <Button
                      variant='outlined'
                      endIcon={<Icon icon={<Plus />} />}
                      onClick={() =>
                        setFieldValue(
                          'options',
                          values.options.concat({
                            id: nanoid(),
                            value: 'value',
                            label: 'New Option'
                          })
                        )
                      }
                    >
                      ADD
                    </Button>
                  </Stack>
                </Stack>
                <SortableItems
                  values={values.options}
                  onChange={(newOptions) => setFieldValue('options', newOptions)}
                  onRemove={(id: string) =>
                    setFieldValue(
                      'options',
                      values.options.filter((option: ISelectFilterConfigOption) => option.id !== id)
                    )
                  }
                />
                <FormControl fullWidth error={touched.defaultValue && Boolean(errors.defaultValue)}>
                  <InputLabel shrink id='default-value-label'>
                    Default Value
                  </InputLabel>
                  <Field
                    name='defaultValue'
                    as={Select}
                    labelId='default-value-label'
                    value={values.defaultValue || ''}
                    onChange={handleChange}
                  >
                    {values.options.map((option: ISelectFilterConfigOption) => (
                      <MenuItem key={option.value} value={option.value || ''}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                  <ErrorMessage
                    name='defaultValue'
                    render={(msg) => (
                      <FormHelperText style={{ marginLeft: 0 }} error>
                        {msg}
                      </FormHelperText>
                    )}
                  />
                </FormControl>
              </Stack>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
              <Button color='secondary' variant='outlined' onClick={onClose}>
                Cancel
              </Button>
              <Button form='filter-chooser-form' type='submit'>
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  )
}

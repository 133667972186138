import {
  AgGridEvent,
  ColDef,
  Column,
  ColumnEvent,
  GridApi,
  IRowNode,
  RowGroupOpenedEvent,
  RowModelType,
  SizeColumnsToContentStrategy
} from 'ag-grid-community'
import { NavigateToNextCellParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { isNotEmpty } from '@utils/lodash'

import { Skeleton } from '@components/core/skeleton'

import { quantileSorted } from 'simple-statistics'

import skeletonCellRenderer from './skeleton-cell-renderer'

const HEADER_PADDING = 35
const MENU_BUTTON_PADDING = 10
const DATE_FILTER_PADDING = 80
const GENERIC_FILTER_PADDING = 50
const PADDING_PER_LEVEL = 30
const DATA_PADDING = 40
const PADDING = 25

const BACKSTOP_WIDTH = 40

// Calculated from 'Proxima-Nova, "Proxima Nova", "Public Sans", Roboto, sans-serif', 0.7 * 16 pixels, weight 400
const FILTER_CHAR_WIDTH = 5.02
// Calculated from 'Proxima-Nova, "Proxima Nova", "Public Sans", Roboto, sans-serif', 0.7 * 16 + 1 pixels, weight 400
const CONTENT_CHAR_WIDTH = 5.28
// Calculated from 'Proxima-Nova, "Proxima Nova", "Public Sans", Roboto, sans-serif', 0.7 * 16 + 1 pixels, weight 700
const CONTENT_CHAR_GROUP_WIDTH = 5.47

const CONTENT_PERCENTILE_DEFAULT = 0.9
const CONTENT_PERCENTILE_GROUP = 0.95
const CONTENT_PERCENTILE_NUMBER = 1.0

const NUMBER_PERCENTILE_THRESHOLD = 0.8

const DISPLAY_ROW_LIMIT = 1000

const COLUMNS_WITH_FULL_WIDTH = ['source_system_account_id']

const COLORED_COLUMNS_CLASS_NAME = 'colored-columns' // Defined the constant here

interface ColumnWidthInfo {
  newWidth: number
  maxContentWidth?: number
}

interface GridContext {
  hasPersistedColumnState?: boolean
  gridContainerRef?: React.RefObject<HTMLDivElement>
}

/*
Adds or removes pinned filter icon to the column if present
*/
export const addPinnedFilterIcon = (
  agGridWrapperElement: HTMLDivElement,
  agGrid: AgGridReact,
  pinnedColumn?: string
) => {
  const elements = agGridWrapperElement
    .querySelector('.ag-header')!
    .querySelectorAll<HTMLElement>('.ag-filter-icon[ref=eFilter]')

  elements.forEach((element) => {
    processFilterIconElement(element, agGrid, pinnedColumn)
  })
}

const processFilterIconElement = (
  element: HTMLElement,
  agGrid: AgGridReact,
  pinnedColumn?: string
) => {
  const closestHeader = element.closest('.ag-header-cell') as HTMLElement | null
  const colId = closestHeader?.getAttribute('col-id')
  if (!colId) return

  element.classList.remove('pinned-red-icon')
  if (_.eq(colId, pinnedColumn)) {
    element.classList.remove('ag-hidden')
    element.classList.add('pinned-red-icon')
  } else if (!agGrid.api.getColumn(colId)?.isFilterActive()) {
    element.classList.add('ag-hidden')
  }
  element.onclick = function (e: MouseEvent) {
    e.stopPropagation() // prevent sort trigger
    agGrid.api.showColumnFilter(colId)
  }
}

export const autoFitColumnsWithAPI = (api: GridApi) => {
  autoFitAllColumns(undefined, api)
}

export const autoFitAllColumns = (
  event?: ColumnEvent | RowGroupOpenedEvent | AgGridEvent,
  api?: GridApi
) => {
  api = api ? api : event?.api
  if (!api) {
    return
  }

  const context = api.getGridOption('context') as GridContext
  const hasPersistedColumnState = context?.hasPersistedColumnState

  if (hasPersistedColumnState) {
    return
  }

  const autoSizeStrategy = api?.getGridOption('autoSizeStrategy')
  switch (autoSizeStrategy?.type) {
    case 'fitGridWidth':
      api.sizeColumnsToFit()
      break
    case 'fitCellContents': {
      const fitCellContentsStrategy = autoSizeStrategy as SizeColumnsToContentStrategy
      const skipHeader = fitCellContentsStrategy?.skipHeader || false
      const colIds = fitCellContentsStrategy?.colIds || []

      // Calculate widths manually by considering filter icons, etc.
      const allColumns = api.getColumns() || []
      const columnWidthsMap: Record<string, ColumnWidthInfo> = {}

      if (isNotEmpty(colIds)) {
        api.autoSizeColumns(colIds, skipHeader)
        colIds.forEach((colId) => {
          calculateWidthWithFiltersAndTypes(colId, api, context, skipHeader, columnWidthsMap)
        })
      } else {
        api.autoSizeAllColumns(skipHeader)
        allColumns.forEach((col) =>
          calculateWidthWithFiltersAndTypes(
            col.getColId(),
            api,
            context,
            skipHeader,
            columnWidthsMap
          )
        )
      }

      // Now, sum up the desired columns widths
      const totalDesiredWidth = Object.values(columnWidthsMap).reduce(
        (sum, widths) => sum + widths.newWidth,
        0
      )

      // Get the grid's available width from gridContainerRef
      const gridContainerRef = context?.gridContainerRef
      const gridWidth =
        gridContainerRef && gridContainerRef.current ? gridContainerRef.current.clientWidth : 0

      if (gridWidth > 0 && totalDesiredWidth < gridWidth) {
        // There is extra space, so adjust specific columns to use maxWidth
        adjustColumnsWithExtraSpace(columnWidthsMap)
      }

      // Adjust 'newWidth' to respect 'minWidth' and 'maxWidth' before setting column widths
      adjustColumnWidthsToConstraints(api, columnWidthsMap)

      // Set all column widths at once
      const columnWidths = Object.entries(columnWidthsMap).map(([key, { newWidth }]) => ({
        key,
        newWidth
      }))
      if (columnWidths.length > 0) {
        api.setColumnWidths(columnWidths, true)
      }

      break
    }
    default:
      break
  }
}

const isNumberColumnBasedOnValues = (column: Column, colId: string, api: GridApi): boolean => {
  let numberCount = 0
  let totalCount = 0

  api.forEachNodeAfterFilterAndSort((node: IRowNode) => {
    if (!node.data) return

    // Use _.get to access nested properties
    const value = _.get(node.data, colId)

    if (value !== null && value !== undefined) {
      totalCount++
      if (typeof value === 'number' || (!isNaN(value) && value !== '')) {
        numberCount++
      }
    }
  })

  // If more than 80% of the values are numbers, treat it as a number column
  return totalCount > 0 && numberCount / totalCount >= NUMBER_PERCENTILE_THRESHOLD
}

const calculateWidthWithFiltersAndTypes = (
  colId: string,
  api: GridApi,
  context: GridContext,
  skipHeader: boolean,
  columnWidthsMap: Record<string, ColumnWidthInfo>
) => {
  const column = api.getColumn(colId)
  if (!column) return

  // Determine if the column is a number column
  const isNumberColumn = isNumberColumnBasedOnValues(column, colId, api)

  // For number columns, use the maximum content width
  const contentPercentile = isNumberColumn
    ? CONTENT_PERCENTILE_NUMBER // Use 100th percentile for number columns
    : getContentPercentile(column)

  // Calculate filter width based on filter font
  const filterWidth = calculateFilterWidth(column)

  // Calculate header width if skipHeader is false
  const headerWidth = skipHeader ? 0 : calculateHeaderWidth(column, api)

  // Collect content widths and max content width for specific data types
  const { contentWidths, maxContentWidth } = getContentWidths(column, colId, api)

  // Calculate the percentile width based on contentPercentile
  const percentileWidth = calculatePercentileWidth(contentWidths, contentPercentile)

  // For date and boolean types, use maxContentWidth
  const contentWidth = Math.max(maxContentWidth, percentileWidth)

  // Set the final column width based on the max of filterWidth, headerWidth, and contentWidth
  const newWidth = Math.round(Math.max(filterWidth, headerWidth, contentWidth, BACKSTOP_WIDTH))

  // For number columns or columns in COLUMNS_WITH_FULL_WIDTH, use the maximum content width
  const maxContentWidthFromData = getMaxContentWidthFromData(contentWidths, colId, isNumberColumn)

  // Save the widths in the map for later adjustment
  columnWidthsMap[colId] = {
    newWidth: newWidth,
    maxContentWidth: maxContentWidthFromData || newWidth
  }
}

const getContentPercentile = (column: Column): number => {
  const isGroupColumn = column.getUserProvidedColDef()?.cellRenderer === 'agGroupCellRenderer'
  return isGroupColumn ? CONTENT_PERCENTILE_GROUP : CONTENT_PERCENTILE_DEFAULT
}

const calculateFilterWidth = (column: Column): number => {
  const filterInstance = column.getColDef()?.filter
  if (!filterInstance) return 0

  if (column.getColDef().filter === 'agDateColumnFilter') {
    // Assuming average 7 characters for date filter width
    return FILTER_CHAR_WIDTH * 10 + DATE_FILTER_PADDING
  } else {
    // Set min width for generic filter
    return GENERIC_FILTER_PADDING
  }
}

const calculateHeaderWidth = (column: Column, api: GridApi): number => {
  let headerText = api.getDisplayNameForColumn(column, 'header') || ''
  const parent = column.getParent()
  if (headerText === '' && parent) {
    headerText = api.getDisplayNameForColumnGroup(parent, 'header') || ''
  }

  // Check if wrapHeaderText is true and remove the last word if it is
  if (column.getColDef()?.wrapHeaderText) {
    headerText = removeLastWord(headerText) // Remove the last word to better approximate the width
  }

  // Calculate header width based on header text length
  let headerWidth = FILTER_CHAR_WIDTH * headerText.length

  // Add padding for dropdown arrow if applicable
  if (String(column.getColDef()?.cellClass).includes(COLORED_COLUMNS_CLASS_NAME)) {
    headerWidth += MENU_BUTTON_PADDING
  }

  // Add padding for header menu button
  headerWidth += HEADER_PADDING
  if (!column.getColDef()?.suppressHeaderMenuButton) {
    headerWidth += MENU_BUTTON_PADDING
  }
  // Add padding for sort button (the one with the arrows)
  if (column.getColDef()?.sortable || column.getColDef()?.sortable === undefined) {
    headerWidth += MENU_BUTTON_PADDING
  }
  return headerWidth
}

const removeLastWord = (text: string): string => {
  const lastSpaceIndex = text.lastIndexOf(' ')
  return lastSpaceIndex !== -1 ? text.substring(0, lastSpaceIndex) : text
}

const getContentWidths = (
  column: Column,
  colId: string,
  api: GridApi
): { contentWidths: number[]; maxContentWidth: number } => {
  const dataType = column.getUserProvidedColDef()?.type
  let maxContentWidth = 0
  const contentWidths: number[] = []

  if (dataType === 'date') {
    // Handle date field (e.g., "YYYY-MM-DD")
    maxContentWidth = CONTENT_CHAR_WIDTH * 10 + PADDING
  } else if (dataType === 'boolean') {
    // Handle boolean field ("True/False")
    maxContentWidth = CONTENT_CHAR_WIDTH * 5 + PADDING
  } else if (isGroupColumn(column)) {
    // Handle group cell renderer
    collectGroupColumnContentWidths(api, contentWidths)
  } else {
    // For other columns, collect all cell content widths
    collectContentWidthsForColumn(column, colId, api, contentWidths)
  }
  return { contentWidths, maxContentWidth }
}

const isGroupColumn = (column: Column): boolean =>
  column.getUserProvidedColDef()?.cellRenderer === 'agGroupCellRenderer'

const collectGroupColumnContentWidths = (api: GridApi, contentWidths: number[]) => {
  api.forEachNodeAfterFilter((node: IRowNode) => {
    const labelLength = node.data?.label?.length ?? 0

    // Calculate the width for the current node's label
    let currentNodeContentWidth =
      (node.group ? CONTENT_CHAR_GROUP_WIDTH : CONTENT_CHAR_WIDTH) * labelLength
    // Add padding based on the node's level
    currentNodeContentWidth += node.level * PADDING_PER_LEVEL

    // Add to content widths array
    contentWidths.push(currentNodeContentWidth)
  })
}

const collectContentWidthsForColumn = (
  column: Column,
  colId: string,
  api: GridApi,
  contentWidths: number[]
) => {
  const model = api.getGridOption('rowModelType') as RowModelType

  if (model === 'serverSide') {
    collectServerSideContentWidths(colId, api, contentWidths)
  } else {
    collectClientSideContentWidths(column, colId, api, contentWidths)
  }
}

const collectServerSideContentWidths = (colId: string, api: GridApi, contentWidths: number[]) => {
  // Instead, loop over displayed rows using indices
  let displayedRowCount = api.getDisplayedRowCount() || 0

  if (displayedRowCount > DISPLAY_ROW_LIMIT) {
    displayedRowCount = DISPLAY_ROW_LIMIT
  }
  for (let i = 0; i < displayedRowCount; i++) {
    const rowNode = api.getDisplayedRowAtIndex(i)

    if (rowNode && rowNode.data) {
      const label = extractLabelFromRowData(rowNode.data, colId)

      // Ensure label is a string to prevent TypeError when accessing label.length
      const labelStr = label != null ? String(label) : ''

      // Safely compute label length
      const labelLength = labelStr.length

      let currentNodeContentWidth =
        (rowNode.group ? CONTENT_CHAR_GROUP_WIDTH : CONTENT_CHAR_WIDTH) * labelLength

      // Add padding based on the row's level
      currentNodeContentWidth += (rowNode.level + 1) * PADDING_PER_LEVEL

      // Add to content widths array
      contentWidths.push(currentNodeContentWidth)
    }
  }
}

const collectClientSideContentWidths = (
  column: Column,
  colId: string,
  api: GridApi,
  contentWidths: number[]
) => {
  api.forEachNodeAfterFilter((node: IRowNode) => {
    if (!node.displayed) {
      return
    }
    // Get the formatted value of the cell in this column
    const valueStr = getFormattedCellValue(api, column, node)
    const valueLength = valueStr.length

    // Calculate the width for this cell's value
    const currentNodeContentWidth = CONTENT_CHAR_WIDTH * valueLength + DATA_PADDING
    // Add to content widths array if the value is not empty
    if (valueStr !== '') {
      contentWidths.push(currentNodeContentWidth)
    }
  })
}

const getFormattedCellValue = (api: GridApi, column: Column, node: IRowNode): string => {
  const value = api.getCellValue({ colKey: column, rowNode: node })
  let valueStr =
    api.getCellValue({
      colKey: column,
      rowNode: node,
      useFormatter: true
    }) ?? ''

  // If the value is a number, check if the key contains 'percent', then multiply by 100, set precision, and format with commas
  if (!isNaN(value as any) && typeof value === 'number') {
    valueStr = formatNumericValue(value as number, node.data, column.getColId())
  }
  return valueStr
}

const formatNumericValue = (value: number, data: any, colId: string): string => {
  let finalValue = value
  // Check if the node key contains 'percent'
  if (colId.toLowerCase().includes('percent')) {
    finalValue = value * 100
    // Round to one decimal place and format with commas
    return `${finalValue.toFixed(1)}%`
  } else {
    // For non-percent values, round the value and format it with commas
    return Math.round(finalValue).toLocaleString()
  }
}

const extractLabelFromRowData = (data: any, colId: string): string => {
  let label = data[colId] || ''

  if (label === '') {
    // Remove any '.label' suffix from colId
    const colIdWithoutLabel = colId.endsWith('.label') ? colId.slice(0, -6) : colId

    label = getLabelFromDataProperties(data, colIdWithoutLabel)
  }
  return label
}

const getLabelFromDataProperties = (data: any, colIdWithoutLabel: string): string => {
  let label = ''
  if (colIdWithoutLabel.endsWith('_coalesce')) {
    // Handle 'coalesce' columns
    label = getCoalesceLabel(data, colIdWithoutLabel)
  } else if (colIdWithoutLabel.endsWith('_confirmed')) {
    // Handle 'confirmed' columns
    label = getConfirmedOrSourceLabel(data, colIdWithoutLabel, 'confirmed')
  } else if (colIdWithoutLabel.endsWith('_source')) {
    // Handle 'source' columns
    label = getConfirmedOrSourceLabel(data, colIdWithoutLabel, 'source')
  } else {
    // For other columns, try to get the value directly
    label = _.get(data, colIdWithoutLabel, '')
  }
  return label
}

const getCoalesceLabel = (data: any, colIdWithoutLabel: string): string => {
  const property = colIdWithoutLabel.slice(0, -'_coalesce'.length)
  let label = _.get(data, `properties.${property}.confirmed`, '')

  // If confirmed is empty or null, use the source label
  if (_.isEmpty(label)) {
    label = _.get(data, `properties.${property}.source`, '')
  }

  // If still empty, try 'standaloneProperties'
  if (_.isEmpty(label)) {
    label = _.get(data, `standaloneProperties.${property}.confirmed`, '')
    if (_.isEmpty(label)) {
      label = _.get(data, `standaloneProperties.${property}.source`, '')
    }
  }

  // If still empty, try 'additionalFields'
  if (_.isEmpty(label)) {
    label = _.get(data, `additionalFields.${property}`, '')
  }
  return label
}

const getConfirmedOrSourceLabel = (
  data: any,
  colIdWithoutLabel: string,
  sourceOrConfirmed: string
): string => {
  const property = colIdWithoutLabel.slice(0, -`_${sourceOrConfirmed}`.length)
  let label = _.get(data, `properties.${property}.${sourceOrConfirmed}`, '')

  // If empty, try 'standaloneProperties'
  if (_.isEmpty(label)) {
    label = _.get(data, `standaloneProperties.${property}.${sourceOrConfirmed}`, '')
  }

  // If still empty, try 'additionalFields'
  if (_.isEmpty(label)) {
    label = _.get(data, `additionalFields.${property}`, '')
  }
  return label
}

const calculatePercentileWidth = (contentWidths: number[], contentPercentile: number): number => {
  if (contentWidths.length > 0) {
    // Sort the widths array
    contentWidths.sort((a, b) => a - b)
    // Use simple-statistics to calculate the specified percentile
    return quantileSorted(contentWidths, contentPercentile)
  }
  return 0
}

const getMaxContentWidthFromData = (
  contentWidths: number[],
  colId: string,
  isNumberColumn: boolean
): number => {
  if ((COLUMNS_WITH_FULL_WIDTH.includes(colId) || isNumberColumn) && contentWidths.length > 0) {
    return Math.max(...contentWidths)
  }
  return 0
}

const adjustColumnsWithExtraSpace = (columnWidthsMap: Record<string, ColumnWidthInfo>) => {
  COLUMNS_WITH_FULL_WIDTH.forEach((colId) => {
    const widths = columnWidthsMap[colId]
    if (widths && widths.maxContentWidth) {
      columnWidthsMap[colId].newWidth = widths.maxContentWidth
    }
  })
}

const adjustColumnWidthsToConstraints = (
  api: GridApi,
  columnWidthsMap: Record<string, ColumnWidthInfo>
) => {
  Object.entries(columnWidthsMap).forEach(([colId, widthInfo]) => {
    const column = api.getColumn(colId)
    if (column) {
      const minWidth = column.getMinWidth()
      const maxWidth = column.getMaxWidth()

      // Ensure 'newWidth' is at least 'minWidth'
      if (minWidth != null && widthInfo.newWidth < minWidth) {
        widthInfo.newWidth = minWidth
      }

      // Ensure 'newWidth' does not exceed 'maxWidth'
      if (maxWidth != null && widthInfo.newWidth > maxWidth) {
        widthInfo.newWidth = maxWidth
      }
    }
  })
}

export const fixedColumnWidths = (
  columnDefs: ColDef[],
  fieldWidthMapping: Record<string, number>
) => {
  return _.map(columnDefs, (colDef) =>
    _.assign(
      {},
      colDef,
      colDef.field && fieldWidthMapping[colDef.field]
        ? { minWidth: fieldWidthMapping[colDef.field], maxWidth: fieldWidthMapping[colDef.field] }
        : {}
    )
  )
}

export const autoselectRowOnNavigateToNextCell = (params: NavigateToNextCellParams) => {
  const suggestedNextCell = params.nextCellPosition

  if (!suggestedNextCell) return null

  const KEY_UP = 'ArrowUp'
  const KEY_DOWN = 'ArrowDown'

  if (![KEY_DOWN, KEY_UP].includes(params.key)) {
    return suggestedNextCell
  }

  params.api.forEachNode((node) => {
    if (_.eq(node.rowIndex, suggestedNextCell.rowIndex)) {
      node.setSelected(true)
    }
  })

  return suggestedNextCell
}

/**
 * This function a temporary solution to fix the regression in ag-grid.
 * After version 32.x ag-grid has a regression where,
 *   - if we provide child data before parent data, the expand/collapse feature will not work.
 *   - grid option groupDefaultExpanded also does not work as expected.
 * Ag-grid is tracking this issue under AG-12198
 */
export const financialHierarchySorterToFixAgGridRegression = <
  T extends { path: string; sort_order: number }
>(
  data: T[]
): T[] => {
  const sorted = _.sortBy(data, [(record) => _.split(record.path, '.').length, 'sort_order'])
  return sorted
}

export const getSkeletonColumns = (n = 5) =>
  _.times(n, () => ({
    field: 'skeleton',
    headerComponent: () => <Skeleton className='h-4 w-full min-w-20' />,
    cellRenderer: skeletonCellRenderer
  }))

import { useMutation, useQueryClient } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import { dispatch } from 'store'
import { openSnackbar } from 'store/reducers/snackbar'
import axiosServices from 'utils/axios'

export const useDeletePage = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ pageNodeId }: { pageNodeId: number }) => {
      return axiosServices.delete(`/businesses/${user?.business_id}/pages/${pageNodeId}`)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['fetch-pages-tree', user?.business_id] })
    },
    onError: (error, variables) => {
      dispatch(
        openSnackbar({
          open: true,
          message: 'Failed to Delete Page.',
          variant: 'alert',
          alert: { color: 'error' },
          close: false
        })
      )
    }
  })
}

import React from 'react'

import { CheckedState } from '@radix-ui/react-checkbox'
import { cn } from '@utils/style-utils'

import { Checkbox } from 'components/core/checkbox'

interface CheckboxWithTitleProps {
  checked?: boolean
  title: string
  onCheckedChange?: (checked: boolean) => void
  containerClassName?: string
}

export const CheckboxWithTitle: React.FC<CheckboxWithTitleProps> = ({
  checked = false,
  title,
  onCheckedChange,
  containerClassName
}) => {
  const handleCheckedChange = (checkedState: CheckedState) => {
    onCheckedChange?.(checkedState === 'indeterminate' ? false : checkedState)
  }

  return (
    <div className={cn('flex items-center space-x-2', containerClassName)}>
      <Checkbox id={title} checked={checked} onCheckedChange={handleCheckedChange} />
      <div className='grid gap-2 leading-none'>
        <label
          htmlFor={title}
          className='font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
        >
          {title}
        </label>
      </div>
    </div>
  )
}

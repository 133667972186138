import { useEffect, useState } from 'react'

import ErrorBoundary from 'components/ErrorBoundary'
import { ComponentErrorWidget } from 'components/cards/component-widget'
import { Button } from 'components/core/button'
import { Text } from 'components/core/text'

import ResultTable from './result-table'

const ErrorFallback = ({
  message,
  refreshChart
}: {
  message: string
  refreshChart: () => void
}) => (
  <div className='h-[500px]'>
    <ComponentErrorWidget
      errorMessage={
        <div>
          <Text variant='body'>{message}</Text>
          <Button variant='outline' onClick={refreshChart}>
            Refresh Chart
          </Button>
        </div>
      }
      title='Dynamic Table'
    />
  </div>
)

export default function TablePreview() {
  const [hasError, setHasError] = useState(false)
  const refreshView = () => setHasError(false)
  useEffect(() => refreshView(), [])

  return (
    <>
      <ErrorBoundary
        key={hasError ? 'error' : 'no-error'}
        fallback={(message) => {
          setHasError(true)
          return <ErrorFallback message={message} refreshChart={refreshView} />
        }}
      >
        <ResultTable />
      </ErrorBoundary>
    </>
  )
}

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

import { useLatestRef } from 'hooks/useLatestRef'

import { useSetAxisGranularity } from '../hooks'
import { IModule, IModuleAttributes, IModuleUseInstance, ModuleName } from '../types/module-types'
import { setAxisCellStartEndLocation } from '../utils/clustered-series-utils'
import { dateIntervalsAndFormattings } from '../utils/date-axis-utils'
import { assignRef, getRef } from '../utils/module-utils'
import { titlePositionAdjustment } from '../utils/title-position-adjustment'

const moduleName = ModuleName.dateYAxis

const dateYAxisModule: IModule<ModuleName.dateYAxis> = {
  name: moduleName
}

dateYAxisModule.init = (props) => {
  const root: am5.Root = getRef({ ...props, moduleName: ModuleName.root })
  const chart: am5xy.XYChart = getRef({ ...props, moduleName: ModuleName.xyChart })
  const tooltip: am5.Tooltip = getRef({ ...props, moduleName: ModuleName.tooltip })
  const headingContainer: am5.Container = getRef({
    ...props,
    moduleName: ModuleName.headingContainer
  })

  const {
    options: {
      yAxisLabel = '',
      frequency,
      opposite = false,
      // global options
      clusteredSeries = false
    }
  } = props

  const configs = dateIntervalsAndFormattings(frequency)

  const yRenderer = am5xy.AxisRendererY.new(root, { opposite, minGridDistance: 10 })
  yRenderer.labels.template.setAll({
    fontSize: '11px',
    // oversizedBehavior: 'wrap',
    textAlign: 'center'
  })

  yRenderer.grid.template.set('visible', false)

  if (clusteredSeries) {
    setAxisCellStartEndLocation(yRenderer)
  }

  const yAxis = chart.yAxes.push(
    am5xy.DateAxis.new(root, {
      maxDeviation: 0.2,
      baseInterval: configs.interval,
      gridIntervals: [configs.interval],
      renderer: yRenderer,
      tooltip
    })
  )

  yAxis.onPrivate('cellWidth', function (cellWidth) {
    yRenderer.labels.template.set('maxWidth', cellWidth)
  })

  if (yAxisLabel) {
    const label = am5.Label.new(root, {
      rotation: -90,
      text: yAxisLabel,
      y: am5.p50,
      centerX: am5.p50
    })

    yAxis.children.unshift(label)
  }

  if (!opposite && headingContainer) {
    titlePositionAdjustment({ headingContainer, yAxis, chart })
  }

  assignRef({ ...props, moduleName, item: yAxis })
}

const useInstance: IModuleUseInstance<ModuleName.dateYAxis> = (instance, props) => {
  const propsRef = useLatestRef(props)

  useSetAxisGranularity({ propsRef, moduleName, props })
}

dateYAxisModule.useInstance = useInstance

export const dateYAxisAttributes: IModuleAttributes<ModuleName.dateYAxis> = (p) => ({
  module: dateYAxisModule,
  ...p
})

export default dateYAxisModule

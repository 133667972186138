import { humanizeFieldName } from '@utils/string-utils'

import { Separator } from '@components/core/separator'
import { Text } from '@components/core/text'

import { useSelector } from '@store/index'
import {
  associationsPath,
  selectAreFieldsSelectedInAssociation,
  selectIsAssociationSelected
} from '@store/slices/component/query-config'

import { ModelSection } from './model-section'

export function AssociationSection({ association, parent }: { association: any; parent: string }) {
  const isAssociationSelected = useSelector(selectIsAssociationSelected(association.name, parent))
  const areFieldsSelected = useSelector(
    selectAreFieldsSelectedInAssociation(association.name, parent)
  )

  if (!isAssociationSelected) return null

  const name = humanizeFieldName(association.name)

  return (
    <>
      <div>
        <Text variant='button' weight='semibold'>
          {name}
        </Text>
        {areFieldsSelected && <Separator className='mt-2' />}
      </div>
      <ModelSection
        modelName={association.model}
        columns={association.columns}
        associations={association.associations}
        parent={`${associationsPath(parent)}.${association.name}`}
      />
    </>
  )
}

import { Grid, SelectChangeEvent, TextField, Typography } from '@mui/material'

import useDelayedUpdate from '@hooks/use-delayed-update'

import { useDispatch } from '@store/index'
import { useSelector } from '@store/index'
import { selectSettings, updateSettingsData } from '@store/slices/component/chart-config'

import MuiSelect from 'components/form/mui-select'

import { ChartSubtitleType } from '../types/chart-builder-types'

export default function ChartSubtitle() {
  const dispatch = useDispatch()
  const settings = useSelector(selectSettings)

  const [subtitleCustom, setSubtitleCustom] = useDelayedUpdate(
    settings.chart_subtitle_custom || '',
    (value) => dispatch(updateSettingsData({ chart_subtitle_custom: value }))
  )
  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h5'>Sub-title</Typography>
      </Grid>
      <Grid item xs={12}>
        <MuiSelect
          options={[
            { label: 'Use Period Label', value: ChartSubtitleType.UsePeriodLabel },
            { label: 'Use Two Period Label', value: ChartSubtitleType.UseTwoPeriodLabel },
            { label: 'Hidden', value: ChartSubtitleType.Hidden },
            { label: 'Custom', value: ChartSubtitleType.Custom }
          ]}
          value={settings.chart_subtitle_type || ChartSubtitleType.UsePeriodLabel}
          onChange={(e: SelectChangeEvent<string>) => {
            dispatch(
              updateSettingsData({ chart_subtitle_type: e.target.value as ChartSubtitleType })
            )
          }}
          label='Subtitle Type'
        />
      </Grid>
      {settings.chart_subtitle_type === ChartSubtitleType.Custom && (
        <Grid item xs={12}>
          <TextField
            value={subtitleCustom}
            onChange={(e) => setSubtitleCustom(e.target.value)}
            fullWidth
            variant='outlined'
            label='Custom Subtitle'
          />
        </Grid>
      )}
    </>
  )
}

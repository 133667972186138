import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

import { useLatestRef } from 'hooks/useLatestRef'

import { useSetAxisName, useSetInstanceData } from '../hooks'
import useToggleDataLabels from '../hooks/useToggleDataLabels'
import { Display, Position, RECTANGULAR_BULLETS } from '../types/data-types'
import { IModule, IModuleAttributes, IModuleUseInstance, ModuleName } from '../types/module-types'
import { getDefaultPrimaryColor } from '../utils/color-utils'
import { assignRef, getRef } from '../utils/module-utils'

const moduleName = ModuleName.lineSeriesVertical
const lineSeriesVerticalModule: IModule<ModuleName.lineSeriesVertical> = {
  name: moduleName
}

lineSeriesVerticalModule.init = (props) => {
  const root: am5.Root = getRef({ ...props, moduleName: ModuleName.root })
  const chart: am5xy.XYChart = getRef({ ...props, moduleName: ModuleName.xyChart })
  const xAxis: am5xy.ValueAxis<am5xy.AxisRenderer> = getRef({
    ...props,
    moduleName: ModuleName.valueXAxis
  })
  const yAxis: am5xy.CategoryAxis<am5xy.AxisRenderer> | am5xy.DateAxis<am5xy.AxisRenderer> = getRef(
    {
      ...props,
      moduleName: [ModuleName.categoryYAxis, ModuleName.dateYAxis]
    }
  )
  const legend: am5.Legend = getRef({ ...props, moduleName: ModuleName.legend })
  const externalLegend: am5.Legend = getRef({ ...props, moduleName: ModuleName.externalLegend })
  const dataProcessor: am5.DataProcessor = getRef({
    ...props,
    moduleName: ModuleName.dataProcessor
  })

  const {
    options: { xAxisKey = 'category', yAxisKey, name, color }
  } = props

  const categoryYAxis = yAxis instanceof am5xy.CategoryAxis

  const series = chart.series.push(
    am5xy.LineSeries.new(root, {
      name,
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: xAxisKey,
      [categoryYAxis ? 'categoryYField' : 'valueYField']: yAxisKey,
      fill: am5.color(color || getDefaultPrimaryColor())
    })
  )
  series.strokes.template.setAll({
    strokeWidth: 0,
    strokeOpacity: 0
    // strokeDasharray: [10,5]
  })
  series.fills.template.setAll({
    opacity: 0,
    visible: false
  })

  if (dataProcessor) {
    series.data.processor = dataProcessor
  }

  ;(yAxis as am5xy.CategoryAxis<am5xy.AxisRenderer>).onPrivate('cellWidth', function (cellWidth) {
    const idx = _.findIndex(series.bullets.values, ['name', RECTANGULAR_BULLETS])
    // using amcharts' removeIndex because it does some internal work
    if (idx !== -1) series.bullets.removeIndex(idx)

    series.bullets.push(
      {
        [RECTANGULAR_BULLETS]: function () {
          const rectangle = am5.Rectangle.new(root, {
            width: 2.5,
            height: cellWidth! * 0.85,
            dy: (cellWidth! * 0.85) / -2,
            fill: am5.color(color || getDefaultPrimaryColor())
          })

          return am5.Bullet.new(root, {
            userData: RECTANGULAR_BULLETS,
            sprite: rectangle
          })
        }
      }[RECTANGULAR_BULLETS]
    )
  })

  if (legend) {
    legend.data.push(series)
  } else if (externalLegend) {
    externalLegend.data.push(series)
  }

  assignRef({ ...props, moduleName, item: series })
}

const useInstance: IModuleUseInstance<ModuleName.lineSeriesVertical> = (instance, props) => {
  const propsRef = useLatestRef(props)

  const {
    options: { xAxisKey, showDataLabels = false }
  } = props

  useSetInstanceData({ propsRef, moduleName, instance })
  useSetAxisName({ propsRef, moduleName, props })
  useToggleDataLabels({
    propsRef,
    moduleName,
    props,
    axisKey: xAxisKey,
    showDataLabels,
    display: Display.HORIZONTAL,
    position: Position.OUTSIDE
  })
}

lineSeriesVerticalModule.useInstance = useInstance

export const lineSeriesVerticalAttributes: IModuleAttributes<ModuleName.lineSeriesVertical> = (
  p
) => ({
  module: lineSeriesVerticalModule,
  ...p
})

export default lineSeriesVerticalModule

import { useMutation, useQueryClient } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import { dispatch } from 'store'
import { openSnackbar } from 'store/reducers/snackbar'
import axiosServices from 'utils/axios'

export const useUpdateMapping = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      hierarchyId,
      destinationNodeId,
      sourceNodeId,
      movingGlAccountIds
    }: {
      hierarchyId: number
      destinationNodeId: number
      sourceNodeId?: number
      movingGlAccountIds: number[]
    }) => {
      return axiosServices.post(
        `/businesses/${user?.business_id}/general_ledger_account_hierarchy_nodes/${destinationNodeId}/update_gl_account_mapping`,
        {
          general_ledger_account_hierarchy_node: {
            hierarchy_id: hierarchyId,
            moving_gl_account_ids: movingGlAccountIds
          }
        }
      )
    },
    onSuccess: (data, variables, context) => {
      const queryKeyDestination = [
        'fetch-gl-account-nodes',
        user?.business_id,
        variables.destinationNodeId
      ]
      const queryKeySource = ['fetch-gl-account-nodes', user?.business_id, variables.sourceNodeId]

      queryClient.invalidateQueries({
        queryKey: ['fetch-gl-accounts-in-hierarchy', user?.business_id, variables.hierarchyId]
      })
      queryClient.invalidateQueries({ queryKey: ['fetch-unmapped'] })
      queryClient.invalidateQueries({ queryKey: ['fetch-auto-mapped'] })
      queryClient.invalidateQueries({ queryKey: queryKeySource })
      queryClient.invalidateQueries({ queryKey: queryKeyDestination })
      queryClient.invalidateQueries({
        queryKey: ['fetch-all-hierarchies-with-nodes', user?.business_id]
      })
      queryClient.invalidateQueries({
        queryKey: ['fetch-hierarchy-builder-nodes', user?.business_id, variables.hierarchyId]
      })
    },
    onError: (error, variables) => {
      dispatch(
        openSnackbar({
          open: true,
          message: `Error Updating GL Hierarchy Mapping. ${error?.message}`,
          variant: 'alert',
          alert: { color: 'error' },
          close: false
        })
      )
    }
  })
}

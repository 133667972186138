import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

import { useLatestRef } from 'hooks/useLatestRef'

import useSetTooltipLabel from '../hooks/useSetTooltipLabel'
import { IModule, IModuleAttributes, IModuleUseInstance, ModuleName } from '../types/module-types'
import { assignRef, getRef } from '../utils/module-utils'

const moduleName = ModuleName.freeFloatingTooltip
const freeFloatingTooltipModule: IModule<ModuleName.freeFloatingTooltip> = {
  name: moduleName
}

freeFloatingTooltipModule.init = (props) => {
  const root: am5.Root = getRef({ ...props, moduleName: ModuleName.root })
  const chart: am5xy.XYChart = getRef({ ...props, moduleName: ModuleName.xyChart })

  const tooltip = am5.Tooltip.new(root, {
    paddingBottom: 4,
    paddingTop: 4,
    paddingRight: 4,
    paddingLeft: 4
  })

  chart.plotContainer.set('tooltipPosition', 'pointer')
  chart.plotContainer.set('tooltipHTML', 'a')
  chart.plotContainer.set('tooltip', tooltip)

  tooltip.adapters.add('pointerOrientation', function (_orientation, _target) {
    return 'up' as const
  })

  tooltip.adapters.add('bounds', function () {
    const plotGlobal = chart.plotContainer.toGlobal({
      x: chart.plotContainer.width(),
      y: chart.plotContainer.height()
    }) // pointer control
    const x = plotGlobal.x
    const y = plotGlobal.y - 5 // - 5 is for for pointer control
    return { top: 0, bottom: y + 20, left: 0, right: x }
  })
  ;(tooltip.get('background') as am5.PointedRectangle)?.setAll({
    fill: am5.color(0xffffff),
    fillOpacity: 1,
    stroke: am5.color(0x000000),
    strokeWidth: 1,
    strokeOpacity: 0.05,
    shadowColor: am5.color(0x000000),
    shadowOpacity: 0.05,
    shadowOffsetX: 4,
    shadowOffsetY: 4,
    cornerRadius: 4
  })

  assignRef({ ...props, moduleName, item: tooltip })
}

const useInstance: IModuleUseInstance<ModuleName.freeFloatingTooltip> = (instance, props) => {
  const propsRef = useLatestRef(props)

  useSetTooltipLabel({ propsRef, moduleName, props })
}

freeFloatingTooltipModule.useInstance = useInstance

export const freeFloatingTooltipAttributes: IModuleAttributes<ModuleName.freeFloatingTooltip> = (
  p
) => ({
  module: freeFloatingTooltipModule,
  ...p
})

export default freeFloatingTooltipModule

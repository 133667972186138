import { Box } from '@mui/material'

import { Column, ITooltipParams } from 'ag-grid-community'

const TooltipComponent = (params: ITooltipParams) => {
  let value = params.value

  if (_.isEqual(params.location, 'header') && params.column) {
    const column = params.column as Column
    const colDef = column.getColDef()
    const headerValueGetter = colDef.headerValueGetter
    if (typeof headerValueGetter === 'function') {
      const api = params.api
      const context = api?.getGridOption('context')

      value = headerValueGetter({ api, colDef, column, location: 'header', context } as any)
    }
  }

  return (
    <Box
      p={'0.25rem 0.5rem'}
      sx={{
        color: '#fff',
        background: (theme) => theme.palette.grey['700'],
        borderRadius: '0.25rem'
      }}
    >
      {value}
    </Box>
  )
}

export default TooltipComponent

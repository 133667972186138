import * as am5 from '@amcharts/amcharts5'

import { CHART_FONT_FAMILY } from '../types/data-types'

export default function getTheme(root: am5.Root) {
  const myTheme = am5.Theme.new(root)

  myTheme.rule('AxisLabel').setAll({
    fontFamily: CHART_FONT_FAMILY
  })

  myTheme.rule('Label').setAll({
    fontFamily: CHART_FONT_FAMILY
  })

  return myTheme
}

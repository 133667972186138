import { forwardRef } from 'react'
import { useSelector } from 'react-redux'

import { Box, Drawer, DrawerProps, useMediaQuery } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'

import { selectHeaderCollapsed } from 'store/slices/navigation'
import { RightDrawerTypeEnum, isRightDrawerTypeDimensions } from 'types/menu'

import DimensionExplorer from '../../../components/control-panel/dimensions/dimension-explorer'
import { rightDrawerStyles } from '../Drawer/MiniDrawerStyled'

interface Props {
  open: boolean
  window?: () => Window
  handleDrawerClose: () => void
  type?: RightDrawerTypeEnum
}

const RightDrawer = forwardRef<HTMLDivElement | undefined, Props>((props, ref) => {
  const { open, handleDrawerClose, type } = props
  const theme = useTheme()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const headerCollapsed = useSelector(selectHeaderCollapsed)

  if (!type) return <></>

  return (
    <Box
      ref={ref}
      tabIndex={0}
      component='nav'
      sx={{
        display: 'block',
        position: 'relative',
        flexShrink: { md: 0 },
        '&:focus': {
          outline: 'none'
        }
      }}
      aria-label='user settings'
    >
      <Drawer
        className='right-drawer-container'
        anchor='right'
        open={open}
        variant='permanent'
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        sx={rightDrawerStyles(theme, open, isMobile, headerCollapsed) as DrawerProps['sx']}
      >
        <div>
          {isRightDrawerTypeDimensions(type) && <DimensionExplorer onClose={handleDrawerClose} />}
        </div>
      </Drawer>
    </Box>
  )
})

export default RightDrawer

import * as React from 'react'

import { cn } from '@utils/style-utils'

const badgeStyles = {
  default: 'border-transparent bg-primary text-white hover:bg-primary/80',
  outline: 'hover:text-grey-darker border-solid border-grey',
  squareGrey:
    'bg-grey-lighter rounded text-primary-darker not-italic text-details border-none font-normal'
}

function getBadgeClasses(variant: keyof typeof badgeStyles = 'default') {
  return cn(
    'inline-flex items-center rounded-full border px-2.5 font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2',
    badgeStyles[variant]
  )
}

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: keyof typeof badgeStyles
}

function Badge({ className, variant = 'default', ...props }: BadgeProps) {
  return <div className={cn(getBadgeClasses(variant), className)} {...props} />
}

export { Badge }

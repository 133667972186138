import React, { useState } from 'react'

import { UniqueIdentifier } from '@dnd-kit/core'

import Button from '@components/core/button'
import { Popover, PopoverContent, PopoverTrigger } from '@components/core/popover'
import Tooltip from '@components/core/tooltip'
import { Icon, Trash } from '@components/icons'

interface TreeDeletePopoverProps {
  nodeId: UniqueIdentifier
  label: string
  onConfirm: (id: number, setErrorMessage?: (message: string) => void) => void
}

export const TreeDeletePopover: React.FC<TreeDeletePopoverProps> = ({ onConfirm, nodeId }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <div className='relative'>
      <Popover
        open={isPopoverOpen}
        onOpenChange={(value) => {
          setIsPopoverOpen(value)
        }}
      >
        <PopoverTrigger asChild>
          <Button
            onClick={(e) => {
              e.preventDefault()
              setIsPopoverOpen(true)
            }}
            variant='ghost'
            className='rounded-[4px] p-0 focus:border-hidden'
            size='extra-small'
          >
            <Tooltip title='Delete Node'>
              <Icon icon={<Trash />} className='size-6' />
            </Tooltip>
          </Button>
        </PopoverTrigger>

        <PopoverContent forceMount>
          <div
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            <div className='flex justify-between gap-4'>
              <Button variant='outline' onClick={() => setIsPopoverOpen(false)}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setIsPopoverOpen(false)
                  onConfirm(nodeId as number)
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}

import {
  KeyboardCoordinateGetter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'

const useDefaultSensors = (coordinateGetter = {}) => {
  return useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: coordinateGetter as KeyboardCoordinateGetter
    })
  )
}

export default useDefaultSensors

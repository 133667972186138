import _ from 'lodash'

;(globalThis as any)._ = _ // assign lodash to the global scope

export const isNotIn = <T>(list: T[]) => _.negate((value) => _.includes(list, value))

export const isNotEqual = _.negate(_.isEqual)

export const isNotEmpty = _.negate(_.isEmpty)

export const _excludes = _.negate(_.includes)

export const isBlank = <T>(value: T) => _.isNil(value) || _.isEmpty(value)
export const isNotBlank = _.negate(isBlank)

export const isNotNil = _.negate(_.isNil)

export const doesNotStartWith = _.negate(_.startsWith)

export const hasArrayChanged = (
  existing: Array<number | string>,
  updated: Array<number | string>
): boolean => {
  const removed = _.difference(existing, updated)
  const added = _.difference(updated, existing)

  return removed.length > 0 || added.length > 0
}

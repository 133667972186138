import { ControlPanel } from 'components/control-panel'
import ChartGridToggle from 'components/control-panel/chart-grid-toggle'
import DataLabelsToggle from 'components/control-panel/data-labels-toggle'
import DateFilterControl from 'components/control-panel/date-filter-control'

export default function ControlPanels() {
  return (
    <ControlPanel>
      <DateFilterControl />
      <DataLabelsToggle />
      <ChartGridToggle />
    </ControlPanel>
  )
}

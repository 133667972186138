import { useState } from 'react'

import { CheckboxWithTitle } from '@core/checkbox-with-title'
import { Text } from '@core/text'

import { Input } from '@components/core/input'

import useDelayedUpdate from '@hooks/use-delayed-update'

import { useDispatch } from '@store/index'
import { updateColumn } from '@store/slices/component/table-config'

import { ITableColumn } from '../types/table-builder-types'

export function ColumnBroadcastSettings({ selected }: { selected: ITableColumn }) {
  const [enabled, setEnabled] = useState(!!selected.customData?.broadcastValue)
  const dispatch = useDispatch()

  const [broadcastValue, setBroadcastValue] = useDelayedUpdate(
    selected.customData?.broadcastValue || '',
    (value) => dispatch(updateColumn({ ...selected, customData: { broadcastValue: value } })),
    500
  )

  return (
    <>
      <div className='mt-4'>
        <Text variant='h5'>Broadcast</Text>
        <Text variant='details'>
          Choose settings to broadcast the value to specific paramter key when a row is selected
        </Text>
      </div>
      <div className='flex min-h-10 items-center justify-between'>
        <CheckboxWithTitle
          title='Enable'
          checked={enabled}
          onCheckedChange={(value) => {
            setEnabled(value)
            if (!value) {
              dispatch(updateColumn({ ...selected, customData: { broadcastValue: '' } }))
            }
          }}
        />
        {enabled && (
          <Input
            className='w-56'
            placeholder='Enter Parameter Key'
            value={broadcastValue}
            onChange={(e) => setBroadcastValue(e.target.value)}
          />
        )}
      </div>
    </>
  )
}

import {
  BasicFieldConfig,
  ChargerFormFieldConfig,
  OneOfFieldConfig,
  isFieldTypeOneOf
} from '../types'

const POSSIBLE_AUTOFILL_FIELDS = ['user', 'username', 'login', 'email', 'password']

const matchesAutofillField = (fieldName: string): boolean => {
  return _.some(POSSIBLE_AUTOFILL_FIELDS, (keyword) => _.lowerCase(fieldName).includes(keyword))
}

export const ZWJ_CHAR = '\u200D'

const disguiseFieldNames = (fieldsConfig: ChargerFormFieldConfig[]): ChargerFormFieldConfig[] => {
  return _.map(fieldsConfig, (field) => {
    if (isFieldTypeOneOf(field.type)) {
      const oneOfField = field as OneOfFieldConfig

      return _.assign({}, oneOfField, {
        sections: _.map(oneOfField.sections, (section) =>
          _.assign({}, section, {
            formFields: disguiseFieldNames(section.formFields)
          })
        )
      })
    } else {
      const basicField = field as BasicFieldConfig

      return _.assign({}, basicField, {
        name: matchesAutofillField(basicField.name)
          ? basicField.name.split('').join('-')
          : basicField.name,
        actualName: basicField.name,
        label: matchesAutofillField(basicField.label)
          ? basicField.label.split('').join(ZWJ_CHAR)
          : basicField.label
      })
    }
  })
}

export { disguiseFieldNames }

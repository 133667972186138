import { useQuery } from '@tanstack/react-query'

import { Dimensions } from 'components/control-panel/types'
import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'

import { QUERY_STALE_TIME_DEFAULT } from './constants'

const fetchDimensions = async (businessId: number | undefined): Promise<Dimensions> => {
  const response = await axiosServices.get(`/businesses/${businessId}/dimensions`)
  return response.data
}

export const useFetchDimensions = () => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-dimensions', user?.business_id],
    queryFn: () => fetchDimensions(user?.business_id),
    staleTime: QUERY_STALE_TIME_DEFAULT
  })
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import { dispatch } from 'store'
import { openSnackbar } from 'store/reducers/snackbar'
import axiosServices from 'utils/axios'

export const useBulkTogglePosting = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      glAccountIds,
      isPostingConfirmed
    }: {
      glAccountIds: number[]
      isPostingConfirmed: boolean
    }) => {
      return axiosServices.post(
        `/businesses/${user?.business_id}/general_ledger_accounts/bulk_toggle_posting`,
        {
          general_ledger_account: {
            is_posting_confirmed: isPostingConfirmed
          },
          toggle_posting_gl_account_ids: glAccountIds
        }
      )
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['fetch-unmapped'] })
    },
    onError: (error, variables) => {
      dispatch(
        openSnackbar({
          open: true,
          message: `Error Toggling Posting/Non-Posting GL Accounts. ${error?.message}`,
          variant: 'alert',
          alert: { color: 'error' },
          close: false
        })
      )
    }
  })
}

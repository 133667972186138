import { dispatch } from 'store'
import { addToast } from 'store/slices/toast'

export const querySuccessToast = (message: string | Array<string>, duration: number = 2000) => {
  return dispatch(
    addToast({
      description: message,
      variant: 'success',
      duration
    })
  )
}

export const queryErrorToast = (message: string | Array<string>, duration: number = 2000) => {
  return dispatch(
    addToast({
      description: message,
      variant: 'error',
      duration
    })
  )
}

export const infoToast = (message: string) => {
  return dispatch(
    addToast({
      description: message,
      variant: 'info'
    })
  )
}

import { useQuery } from '@tanstack/react-query'

import { camelCaseKeys } from '@utils/case-conversion'

import axiosServices from 'utils/axios'

import { JobData } from '../types'

const fetchJobHistory = async (businessId: number, id: number): Promise<JobData[]> => {
  const response = await axiosServices.get(
    `/businesses/${businessId}/connected_source_systems/${id}/job_history`
  )
  return _.map(camelCaseKeys(response.data), (item) => {
    const stats = item.stats ? JSON.parse(item.stats) : undefined
    return { ...item, stats, numOfRecordsRead: _.get(stats, 'num_records') }
  }) as JobData[]
}

export const useFetchJobHistory = ({ businessId, id }: { businessId?: number; id?: number }) => {
  return useQuery({
    queryKey: ['fetch-css-job-history', id],
    enabled: !!businessId && !!id,
    queryFn: () => fetchJobHistory(businessId!, id!)
  })
}

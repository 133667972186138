import { ICellRendererParams } from 'ag-grid-community'

import { Cancel, Check, Clock, Icon } from '@components/icons'

import {
  isLastSyncStatusComplete,
  isLastSyncStatusFailed,
  isLastSyncStatusInProgress
} from './types'

export const SyncStatusCellRenderer = (params: ICellRendererParams) => (
  <div className='flex size-full items-center justify-center'>
    {isLastSyncStatusComplete(params.value) && (
      <Icon icon={<Check />} className='text-success-darker' />
    )}
    {isLastSyncStatusFailed(params.value) && (
      <Icon icon={<Cancel />} className='text-error-darker' />
    )}
    {isLastSyncStatusInProgress(params.value) && <Icon icon={<Clock />} className='text-warning' />}
  </div>
)

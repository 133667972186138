import React from 'react'

import { ColDef, ICellRendererParams } from 'ag-grid-community'

export interface HighlightCellRendererProps {
  value: string
  searchTerm: string
}

// Implementation:
// const columnDefs = useMemo(() => applyHighlightRenderer(baseColumnDefs, searchTerm, excludeColIds), [baseColumnDefs, searchTerm]);
export const applyHighlightRenderer = (
  columnDefs: ColDef[],
  searchTerm: string | undefined,
  excludeColIds: string[] = []
) => {
  return columnDefs.map((colDef) => {
    const hasCustomRenderer = Boolean(colDef.cellRenderer)

    if (typeof colDef.colId === 'string' && colDef.field && !excludeColIds.includes(colDef.colId)) {
      return {
        ...colDef,
        // Here, If there is an existing renderer, we preserve it and wrap it with the HighlightCellRenderer
        cellRenderer: (params: ICellRendererParams) => {
          const originalRenderer = hasCustomRenderer ? colDef.cellRenderer : null

          return (
            <>
              {originalRenderer ? (
                originalRenderer(params)
              ) : (
                <HighlightCellRenderer value={params.value} searchTerm={searchTerm || ''} />
              )}
            </>
          )
        }
      }
    }
    return colDef
  })
}

export const HighlightCellRenderer: React.FC<HighlightCellRendererProps> = React.memo(
  ({ value, searchTerm }) => {
    const isValueStringOrNumber = typeof value === 'string' || typeof value === 'number'

    const valueAsString = value != null ? value.toString() : ''

    if (!isValueStringOrNumber || !searchTerm) {
      return <span>{valueAsString}</span>
    }

    const regex = new RegExp(`(${searchTerm})`, 'gi')
    const parts = valueAsString.split(regex)

    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === searchTerm.toLowerCase() ? (
            <span key={index} style={{ backgroundColor: 'yellow' }}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    )
  }
)

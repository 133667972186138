import { isNotBlank, isNotEmpty } from '@utils/lodash'

import {
  ChargerFormFieldConfig,
  FieldOption,
  FieldTypes,
  OneOfFieldConfig
} from '@components/form/types'

import * as Yup from 'yup'

import { IntegrationSpecification, Property } from '../airbyte-type'

interface IItem {
  field: string
  params: Property
}

const itemType = (item: IItem) => {
  if (item.params.airbyte_secret) return FieldTypes.PASSWORD
  if (item.params.type === 'integer') {
    return FieldTypes.NUMBER
  }
  return FieldTypes.TEXT_FIELD
}

export const airbyteSpecToChargerFormFieldConfig = (
  connectionSpecification: IntegrationSpecification | undefined | null,
  initialValues: Record<string, any>,
  readOnly = false
): ChargerFormFieldConfig[] => {
  if (!connectionSpecification) return []

  const properties = connectionSpecification.properties
  const required = connectionSpecification.required

  const sorted: IItem[] = _(properties)
    .toPairs()
    .sortBy((pair) => pair[1].order)
    .map(([field, params]) => ({ field, params }))
    .filter((obj) => required.includes(obj.field))
    .value()

  const fieldsConfig: ChargerFormFieldConfig[] = _.compact(
    sorted.map((item) => {
      // const in airbyte is used to handle which oneOf section to show, not a field
      if (isNotBlank(item.params.const)) return null

      // oneOf is used to show different sections based on the value of a field
      if (isNotEmpty(item.params.oneOf)) {
        const constAttr = _.find(
          _.entries(_.get(item.params, 'oneOf[0].properties')),
          ([_key, prop]) => isNotEmpty(prop.const)
        )?.[0]
        if (!constAttr) return null
        const options: FieldOption[] = _.map(item.params.oneOf, (section) => {
          const constOption = _.get(section, ['properties', constAttr])
          return {
            label: _.get(section, 'title', ''),
            value: _.get(constOption, 'const', '')
          }
        })

        return {
          label: _.get(item.params, 'title', ''),
          name: item.field,
          type: FieldTypes.ONE_OF,
          readOnly,
          options,
          defaultValue: _.get(
            initialValues,
            [item.field, constAttr],
            _.get(options, [0, 'value'], '')
          ),
          validationSchema: Yup.string().required('Required'),
          sections: _.map(item.params.oneOf, (section, idx) => ({
            label: section.title,
            value: options[idx].value,
            formFields: airbyteSpecToChargerFormFieldConfig(
              section as IntegrationSpecification,
              initialValues[item.field] || {},
              readOnly
            )
          })) as OneOfFieldConfig['sections'],
          userData: {
            valueKey: constAttr
          }
        }
      }

      // enum contains a list of options to choose from
      if (isNotEmpty(item.params.enum)) {
        const options: FieldOption[] = _.map(item.params.enum, (value) => ({
          label: value,
          value
        }))

        return {
          label: _.get(item.params, 'title', ''),
          name: item.field,
          type: FieldTypes.SELECT,
          readOnly,
          options,
          defaultValue: initialValues[item.field] || '',
          validationSchema: Yup.string().required('Required')
        }
      }

      return {
        label: _.get(item.params, 'title', ''),
        name: item.field,
        type: itemType(item),
        readOnly,
        defaultValue: initialValues[item.field] || '',
        minimumValue: item.params.minimum,
        maximumValue: item.params.maximum,
        validationSchema: (() => {
          const fieldType = itemType(item)
          if (fieldType === FieldTypes.NUMBER) {
            return Yup.number().required('Required')
          } else if (item.params.type === 'array') {
            return Yup.array().of(Yup.string().required('Required'))
          } else {
            return Yup.string().trim().required('Required')
          }
        })()
      }
    })
  )

  return fieldsConfig
}

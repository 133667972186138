import React, { useState } from 'react'

import { ButtonProps } from '@mui/material/Button'

import { getAllowedRange } from '@utils/date-filter-utils'

import Button from '@components/core/button'
import { IconButton } from '@components/core/icon-button'
import { Popover, PopoverContent, PopoverTrigger } from '@components/core/popover'
import { Text } from '@components/core/text'
import { ChevronLeft, ChevronRight, Icon } from '@components/icons'

import { Frequency, YearMonthInfo } from 'types/filter'
import { Month, getMonthHeading, getMonthsForFiscalYear } from 'utils/date-utils'

import GridMenuRenderHelper from '../grid-render-helper'
import YearSelectView from '../year-select-view'
import { maxInfo, minInfo, navigationClamp, outOfBounds } from './utils'

interface Props {
  value?: YearMonthInfo
  fiscalYearStart?: Month
  minAllowed?: YearMonthInfo
  maxAllowed?: YearMonthInfo
  buttonProps?: ButtonProps
  noRangeLimit?: boolean
  onChange: (value: YearMonthInfo) => void
  render?: (value: YearMonthInfo) => React.ReactNode
  allowFuture?: boolean
}

const MonthlyPicker = (props: Props) => {
  const { fiscalYearStart, value, onChange, render, allowFuture = true } = props

  const monthOptions = getMonthsForFiscalYear(fiscalYearStart)

  const [view, setView] = useState<'year' | 'month'>('month')
  const [displayYear, setDisplayYear] = useState<number>(
    props.value?.year || new Date().getFullYear()
  )
  const [popoverOpen, setPopoverOpen] = useState(false)

  const toggleView = () => setView(view === 'month' ? 'year' : 'month')

  const allowedRange = props.noRangeLimit
    ? undefined
    : getAllowedRange(fiscalYearStart, Frequency.Monthly)
  const minAllowed = maxInfo(props.minAllowed, allowedRange?.minAllowed, fiscalYearStart)
  const maxAllowed = minInfo(props.maxAllowed, allowedRange?.maxAllowed, fiscalYearStart)

  const shouldDisable = (val: YearMonthInfo) => {
    if (outOfBounds(val, minAllowed, maxAllowed, fiscalYearStart)) return true

    if (!allowFuture && val.year > new Date().getFullYear()) return true

    return false
  }

  const shouldDisableArrows = (year: number) => {
    if (minAllowed && minAllowed.year > year) return true

    if (maxAllowed && maxAllowed.year < year) return true

    if (!allowFuture && year > new Date().getFullYear()) return true

    return false
  }

  const monthSelectVal = (month: Month) =>
    navigationClamp(
      { year: displayYear, month: month },
      'month',
      fiscalYearStart,
      minAllowed,
      maxAllowed
    )

  const selectedMonth = value?.year === displayYear ? value.month : undefined
  return (
    <div>
      <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
        <PopoverTrigger asChild>
          <Button variant='outline'>
            {!value
              ? ''
              : render
                ? render(value)
                : getMonthHeading(value.year, value.month, fiscalYearStart)}
          </Button>
        </PopoverTrigger>
        <PopoverContent className='flex min-w-64 flex-col'>
          <div className='mb-4 flex flex-1 items-center justify-between'>
            <IconButton
              disabled={shouldDisableArrows(displayYear - 1)}
              onClick={() => setDisplayYear(displayYear - 1)}
              variant={shouldDisableArrows(displayYear - 1) ? 'disabled' : 'outline'}
            >
              <Icon icon={<ChevronLeft />} />
            </IconButton>
            <div className='flex' onClick={toggleView}>
              <Text variant='button' className='cursor-pointer font-medium'>
                {fiscalYearStart != null ? <span>FY</span> : void 0}
                <span>{displayYear}</span>
              </Text>
            </div>
            <IconButton
              disabled={shouldDisableArrows(displayYear + 1)}
              onClick={() => setDisplayYear(displayYear + 1)}
              variant={shouldDisableArrows(displayYear + 1) ? 'disabled' : 'outline'}
            >
              <Icon icon={<ChevronRight />} />
            </IconButton>
          </div>

          {view === 'month' ? (
            <GridMenuRenderHelper
              selectedItem={selectedMonth}
              disabled={(month) => !value || shouldDisable({ year: displayYear, month })}
              keySelector={(value) => value}
              data={monthOptions}
              numColumns={4}
              className='overflow-hidden rounded-md border border-solid border-grey-light'
              cellClassNames='h-14 hover:bg-primary hover:text-white rounded-none m-[-1px]'
              onChange={(month: Month) => {
                onChange(monthSelectVal(month))
                setPopoverOpen(false)
              }}
              render={
                fiscalYearStart != null
                  ? (value, idx) => (
                      <div>
                        <div>{value}</div>
                        <Text variant='details'>P.{idx + 1}</Text>
                      </div>
                    )
                  : void 0
              }
            />
          ) : (
            <YearSelectView
              fiscalYearStart={fiscalYearStart}
              year={value?.year}
              disabled={(year) => {
                if (!value) return true

                const { minAllowed, maxAllowed } = props

                if (minAllowed && year < minAllowed.year) return true
                if (maxAllowed && year > maxAllowed.year) return true

                return false
              }}
              onChange={(year) => {
                setDisplayYear(year)
                setView('month')
              }}
            />
          )}
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default MonthlyPicker

import React, { useMemo } from 'react'

import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { CHANGE_KEY } from '@hooks/use-change-detection'

import { AG_RIGHT_ALIGNED_CELL } from 'components/ag-grid/types'
import { CHARGER_MONOSPACE_CLASS } from 'config'
import { formatNumber } from 'utils/number-utils'

import { ITableColumn } from '../../types/table-builder-types'
import { booleanHeader, dateHeader, numberHeader } from '../../utils/header-utils'
import { getPivotComparator } from '../utils'
import useHeatmap from './use-heatmap'

interface Props {
  columns: ITableColumn[]
  gridRef: React.MutableRefObject<AgGridReact | null>
  externalFiltersChanged: CHANGE_KEY
  showFilters?: boolean
}

export const useColDefs = (props: Props) => {
  const { columns, gridRef, externalFiltersChanged, showFilters = false } = props
  const { getColorClass } = useHeatmap({ columns, gridRef, externalFiltersChanged })

  const columnDefs = useMemo<ColDef[]>(() => {
    return columns.map((item) => {
      const header: ColDef = _.omit(item, 'customData')

      if (numberHeader(header)) {
        header.type = 'rightAligned'
        header.valueGetter = (params) => {
          if (!params.data) return null
          const value = params.data[header.field!]
          if ([null, undefined, ''].includes(value)) {
            return null
          }
          return +value
        }
        header.valueFormatter = (params) =>
          params.value === null ? (header.aggFunc ? '' : '-') : formatNumber(params.value)
      }

      if (dateHeader(header)) {
        header.cellRenderer = (params: ICellRendererParams) => params.value
      }

      if (showFilters) {
        if (numberHeader(header)) {
          header.filter = 'agNumberColumnFilter'
        } else if (booleanHeader(header)) {
          header.filter = 'agSetColumnFilter'
          header.filterParams = {
            values: ['true', 'false', 'blank'],
            suppressSorting: true
          }
          header.cellDataType = 'text'
        } else if (dateHeader(header)) {
          header.filter = 'agDateColumnFilter'
        } else {
          header.filter = 'agTextColumnFilter'
        }
      }

      header.cellClass = (params) => {
        const heatmapClass = getColorClass(params, item)
        const alignClass = numberHeader(header)
          ? `${AG_RIGHT_ALIGNED_CELL} ${CHARGER_MONOSPACE_CLASS}`
          : ''
        return _.filter([heatmapClass, alignClass], Boolean).join(' ')
      }

      const pivotComparatorConfig = _.get(item, ['customData', 'pivotComparatorConfig'])
      if (pivotComparatorConfig) {
        header.pivotComparator = getPivotComparator(pivotComparatorConfig)
      }

      return header
    })
  }, [columns, showFilters, getColorClass])

  return columnDefs
}

import { CSSProperties, ReactNode, Ref, forwardRef } from 'react'

import {
  Card,
  CardContent,
  CardContentProps,
  CardHeader,
  CardHeaderProps,
  CardProps,
  Divider,
  LinearProgress,
  Typography
} from '@mui/material'
// material-ui
import { useTheme } from '@mui/material/styles'

// types
import { KeyedObject } from 'types/root'

// header style
const headerSX = (actionAlign: 'left' | 'right') => ({
  p: 2.5,
  gap: '1rem',
  '& .MuiCardHeader-action': {
    m: 0,
    display: 'flex',
    alignSelf: 'center',
    flex: 1,
    overflow: 'hidden',
    justifyContent: actionAlign === 'left' ? 'flex-start' : 'flex-end'
  },
  '& .MuiCardHeader-content': {
    flex: 'none'
  }
})

// ==============================|| CUSTOM - MAIN CARD ||============================== //

export interface WidgetCardProps extends KeyedObject {
  border?: boolean
  boxShadow?: boolean
  children?: ReactNode | string
  subheader?: ReactNode | string
  secondaryAlign?: 'left' | 'right'
  style?: CSSProperties
  content?: boolean
  contentSX?: CardContentProps['sx']
  darkTitle?: boolean
  divider?: boolean
  sx?: CardProps['sx']
  secondary?: CardHeaderProps['action']
  shadow?: string
  elevation?: number
  title?: ReactNode | string
  modal?: boolean
  refetching?: boolean
}

const WidgetCard = forwardRef<HTMLDivElement, WidgetCardProps>(
  (
    {
      border = true,
      boxShadow,
      children,
      subheader,
      content = true,
      secondaryAlign = 'left',
      contentSX = {},
      darkTitle,
      divider = true,
      elevation,
      secondary,
      shadow,
      sx = {},
      title,
      modal = false,
      refetching,
      ...others
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const theme = useTheme()
    boxShadow = theme.palette.mode === 'dark' ? boxShadow || true : boxShadow

    return (
      <Card
        elevation={elevation || 0}
        ref={ref}
        {...others}
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          border: border ? '1px solid' : 'none',
          borderRadius: 1,
          borderColor:
            theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.grey.A800,
          boxShadow:
            boxShadow && (!border || theme.palette.mode === 'dark')
              ? shadow || theme.customShadows.z1
              : 'inherit',
          ':hover': {
            boxShadow: boxShadow ? shadow || theme.customShadows.z1 : 'inherit'
          },
          ...(modal && {
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: `calc( 100% - 50px)`, sm: 'auto' },
            '& .MuiCardContent-root': {
              overflowY: 'auto',
              minHeight: 'auto',
              maxHeight: `calc(100vh - 200px)`
            }
          }),
          ...sx
        }}
      >
        {/* card header and action */}
        {!darkTitle && title && (
          <CardHeader
            sx={headerSX(secondaryAlign)}
            titleTypographyProps={{ variant: 'h4' }}
            title={title}
            action={secondary}
            subheader={subheader}
          />
        )}
        {darkTitle && title && (
          <CardHeader
            sx={headerSX(secondaryAlign)}
            title={<Typography variant='h4'>{title}</Typography>}
            action={secondary}
          />
        )}

        {refetching && <LinearProgress color='primary' />}

        {/* content & header divider */}
        {title && divider && content && <Divider />}

        {/* card content */}
        {content && (
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: (contentSX as any)?.height != null ? undefined : 1,
              ...contentSX
            }}
          >
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    )
  }
)

export default WidgetCard

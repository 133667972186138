import { ReactNode, createContext } from 'react'

import { useLocalStorage } from '@uidotdev/usehooks'

import config from 'config'
import {
  CustomizationProps,
  FontFamily,
  PresetColor,
  ThemeDirection,
  ThemeMode
} from 'types/config'

const initialState: CustomizationProps = {
  ...config,
  onChangeMode: (mode: ThemeMode) => {},
  onChangePresetColor: (theme: PresetColor) => {},
  onChangeDirection: (direction: ThemeDirection) => {},
  onChangeMiniDrawer: (miniDrawer: boolean) => {},
  onChangeFontFamily: (fontFamily: FontFamily) => {}
}

const ConfigContext = createContext(initialState)

type ConfigProviderProps = {
  children: ReactNode
}

function ConfigProvider({ children }: ConfigProviderProps) {
  const [config, setConfig] = useLocalStorage('mantis-react-ts-config', initialState)

  const onChangeMode = (mode: ThemeMode) => {
    setConfig({
      ...config,
      mode
    })
  }

  const onChangePresetColor = (theme: PresetColor) => {
    setConfig({
      ...config,
      presetColor: theme
    })
  }

  const onChangeDirection = (direction: ThemeDirection) => {
    setConfig({
      ...config,
      themeDirection: direction
    })
  }

  const onChangeMiniDrawer = (miniDrawer: boolean) => {
    setConfig({
      ...config,
      miniDrawer
    })
  }

  const onChangeFontFamily = (fontFamily: FontFamily) => {
    setConfig({
      ...config,
      fontFamily
    })
  }

  return (
    <ConfigContext.Provider
      value={{
        ...config,
        onChangeMode,
        onChangePresetColor,
        onChangeDirection,
        onChangeMiniDrawer,
        onChangeFontFamily
      }}
    >
      {children}
    </ConfigContext.Provider>
  )
}

export { ConfigProvider, ConfigContext }

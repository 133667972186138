import { Skeleton, Stack } from '@mui/material'

const SkeletonChart = () => {
  return (
    <Stack flex={1} justifyContent='space-around' p={1}>
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='column' spacing={1}>
          <Skeleton variant='rectangular' width={80} />
        </Stack>
        <Stack direction='row' spacing={2} justifyContent='flex-end'>
          <Stack direction='row' spacing={1}>
            <Skeleton variant='circular' width={20} height={20} />
            <Skeleton variant='rectangular' width={80} />
          </Stack>

          <Stack direction='row' spacing={1}>
            <Skeleton variant='circular' width={20} height={20} />
            <Skeleton variant='rectangular' width={80} />
          </Stack>
        </Stack>
      </Stack>
      {[...Array(7)].map((_, index) => (
        <Stack key={index} direction='row' spacing={2}>
          <Skeleton variant='rectangular' width={40} />
          <Skeleton variant='rectangular' style={{ flex: 1 }} height={7} />
        </Stack>
      ))}
      <Stack direction='row' justifyContent='space-around'>
        <Skeleton variant='rectangular' width={40} height={0} />

        {[...Array(7)].map((_, index) => (
          <Skeleton key={index} variant='rectangular' width={40} />
        ))}
      </Stack>
    </Stack>
  )
}

export default SkeletonChart

import { useMutation, useQueryClient } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'
import { errorSnack, successSnack } from 'utils/snackbar-utils'

export const useCloneComponent = (id?: number) => {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const business_id = user?.business_id!

  return useMutation({
    mutationFn: ({ id: idArg }: { id?: number }) => {
      return axiosServices.post(
        `/businesses/${business_id}/components/${idArg || id}/clone_component`,
        {}
      )
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(['fetch-component', business_id, data.id], data)
      successSnack('Component Cloned Successfully')
    },
    onError() {
      errorSnack('Error Cloning Component')
    }
  })
}

import React from 'react'
import { ReactNode } from 'react'

import { Separator } from 'components/core/separator'
import { intersperse } from 'utils/obj-utils'

export const ControlPanel = ({ children }: { children: ReactNode }) => {
  if (!children) return null

  const childrenArray = React.Children.toArray(children)

  return (
    <div className='control-panel flex items-center gap-2'>
      {intersperse(childrenArray, '-').map((child: any, index: number) =>
        child === '-' ? (
          <Separator key={'sep-' + index} orientation='vertical' className='h-[38px]' />
        ) : (
          <div key={index}>{child}</div>
        )
      )}
    </div>
  )
}

import { ICellRendererParams } from 'ag-grid-community'

import { Popover, PopoverContent, PopoverTrigger } from '@core/popover'

import { DEFAULT_EDITOR_MAX_HEIGHT, PythonEditor } from '@components/code-editors'

export const PythonCellRenderer = (params: ICellRendererParams) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className='cursor-pointer truncate'>{params.value}</div>
      </PopoverTrigger>

      <PopoverContent className='w-128'>
        <PythonEditor value={params.value} readOnly maxHeight={DEFAULT_EDITOR_MAX_HEIGHT} />
      </PopoverContent>
    </Popover>
  )
}

// sort-imports-ignore

import 'regenerator-runtime/runtime'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

// third-party
import { Provider as ReduxProvider } from 'react-redux'
import _ from 'lodash'

// scroll bar
import 'simplebar/src/simplebar.css'

// react date picker
import 'react-datepicker/dist/react-datepicker.css'

// ag-grid
import 'ag-grid-enterprise'
import { LicenseManager } from 'ag-grid-enterprise'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'text-security/text-security.css'

import 'assets/css/ag-grid.css'
import 'assets/css/fonts.css'
import 'assets/css/chart.css'

// project import
import './monkey_patches'
import App from './App'
import { store } from 'store'
import { ConfigProvider } from 'contexts/ConfigContext'
import reportWebVitals from './reportWebVitals'
import { enableMapSet } from 'immer'
import AppsignalErrorBoundary from 'components/AppsignalErrorBoundary'
import { Toaster } from 'components/core/toaster'

// assign lodash to the global scope
;(globalThis as any)._ = _

// enter your license key here to suppress console message and watermark
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-064420}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Charger_Inc}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{OverlayAnalytics}_only_for_{3}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{OverlayAnalytics}_need_to_be_licensed___{OverlayAnalytics}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{31_August_2025}____[v3]_[01]_MTc1NjU5NDgwMDAwMA==c5a687581e66909a41367f7b30aa4615'
)

enableMapSet()

const container = document.getElementById('root')
const root = createRoot(container!)

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

root.render(
  <AppsignalErrorBoundary>
    <ReduxProvider store={store}>
      <ConfigProvider>
        <BrowserRouter>
          <App />
          <Toaster />
        </BrowserRouter>
      </ConfigProvider>
    </ReduxProvider>
  </AppsignalErrorBoundary>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

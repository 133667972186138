// material-ui
import { Theme } from '@mui/material/styles'

// ==============================|| OVERRIDES - TAB ||============================== //

export default function Tab(theme: Theme) {
  return {
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 46,
          color: theme.palette.text.primary,
          borderRadius: 4,
          '&:hover': {
            color: theme.palette.primary.main
          },
          '&:not(.Mui-selected)': {
            color: theme.palette.grey['600'],
            '&:hover': {
              color: theme.palette.secondary.main
            }
          },
          '&:focus-visible': {
            borderRadius: 4,
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: -3
          }
        }
      }
    }
  }
}

import * as React from 'react'
import ReactDOM from 'react-dom'

import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { cn } from '@utils/style-utils'

const TooltipProvider = TooltipPrimitive.Provider
const TooltipRoot = TooltipPrimitive.Root
const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => {
  const content = (
    <div className='twp'>
      <TooltipPrimitive.Content
        ref={ref}
        sideOffset={sideOffset}
        side={'bottom'}
        className={cn(
          'z-50 overflow-visible rounded-md border border-black bg-white px-2 py-1.5 animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
          className
        )}
        {...props}
      />
    </div>
  )
  return ReactDOM.createPortal(content, document.body)
})
TooltipContent.displayName = TooltipPrimitive.Content.displayName

export { TooltipRoot, TooltipTrigger, TooltipContent, TooltipProvider }

interface TooltipProps {
  children: React.ReactNode
  title: string | React.ReactNode
  side?: 'top' | 'left' | 'right' | 'bottom'
  delay?: number // in ms
  className?: string
  open?: boolean
  disabled?: boolean // don't show tooltip; just render children content
}

const Tooltip: React.FC<TooltipProps> = ({
  delay = 400,
  side,
  children,
  title,
  className,
  open,
  disabled = false
}) => {
  if (disabled) {
    return <>{children}</>
  }
  return (
    <TooltipProvider delayDuration={delay}>
      <TooltipRoot open={open}>
        <TooltipTrigger asChild>
          <div>{children}</div>
        </TooltipTrigger>
        <TooltipContent side={side ?? 'bottom'} className={className}>
          <p>{title}</p>
        </TooltipContent>
      </TooltipRoot>
    </TooltipProvider>
  )
}

export default Tooltip

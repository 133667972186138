import { useState } from 'react'

import { Box, Tab, Tabs } from '@mui/material'

import CustomTabPanel from '../common/custom-tab-panel'
import a11yProps from '../utils/a11y-props'
import { ColumnSettings } from './column-settings'
import { useSyncColsOnInit } from './hooks/use-sync-cols-on-init'
import TableSettings from './table-settings'

enum TabIndex {
  Settings,
  Columns
}

export default function TableConfig() {
  const [value, setValue] = useState(TabIndex.Settings)

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  useSyncColsOnInit()

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label='table config' variant='fullWidth'>
          <Tab label='Settings' {...a11yProps(TabIndex.Settings)} />
          <Tab label='Columns' {...a11yProps(TabIndex.Columns)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={TabIndex.Settings}>
        <TableSettings />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={TabIndex.Columns}>
        <ColumnSettings />
      </CustomTabPanel>
    </Box>
  )
}

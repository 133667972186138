import React from 'react'

import { Theme, TypographyVariantsOptions } from '@mui/material/styles'

import { FontFamily, ThemeMode } from 'types/config'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    cardLargeValue: React.CSSProperties
    details: React.CSSProperties
    websiteDetails: React.CSSProperties
    formHeader: React.CSSProperties
    menuSubtitle: React.CSSProperties
    body: React.CSSProperties
    dashboardReportHeader: React.CSSProperties
    websiteSubtitle: React.CSSProperties
    cardGraphValue: React.CSSProperties
    cardHeader: React.CSSProperties
    tableValue: React.CSSProperties
    subText: React.CSSProperties
    menuPageGroup: React.CSSProperties
    menuPageGroupBold: React.CSSProperties
    menuCategory: React.CSSProperties
    menuPage: React.CSSProperties
  }

  interface TypographyVariantsOptions extends Partial<TypographyVariants> {}
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    cardLargeValue: true
    details: true
    websiteDetails: true
    formHeader: true
    menuSubtitle: true
    body: true
    dashboardReportHeader: true
    websiteSubtitle: true
    cardGraphValue: true
    cardHeader: true
    tableValue: true
    subText: true
    menuPageGroup: true
    menuPageGroupBold: true
    menuCategory: true
    menuPage: true
  }
}

const Typography = (
  mode: ThemeMode,
  fontFamily: FontFamily,
  theme: Theme
): TypographyVariantsOptions => {
  const { breakpoints } = theme

  // https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
  // specify default variants
  return {
    htmlFontSize: 16,
    fontFamily,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 800,
    h1: {
      fontWeight: 800,
      fontSize: '3.375rem',
      lineHeight: '3.125rem',
      fontStyle: 'normal',
      [breakpoints.down('sm')]: {
        fontSize: '2rem',
        lineHeight: '1.875rem'
      }
    },
    h2: {
      fontWeight: 800,
      fontSize: '2.5rem',
      lineHeight: 'normal'
    },
    h3: {
      fontWeight: 800,
      fontSize: '2rem',
      lineHeight: '2.5rem'
    },
    h4: {
      color: theme.palette.primary.main,
      fontSize: '1.5rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '2rem'
    },
    h5: {
      color: theme.palette.primary.darker,
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '1.5rem',
      [breakpoints.down('sm')]: {
        fontSize: '1rem',
        lineHeight: 'normal'
      }
    },
    h6: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.57
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66
    },
    body1: {
      fontSize: '0.875rem',
      lineHeight: 1.57
    },
    body2: {
      fontSize: '0.75rem',
      lineHeight: 1.66
    },
    subtitle1: {
      color: theme.palette.primary.main,
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.57
    },
    subtitle2: {
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: 1.66
    },
    overline: {
      lineHeight: 1.66
    },
    button: {
      fontSize: '0.875rem',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '1.0625rem'
    },
    cardLargeValue: {
      fontSize: '1.625rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal'
    },
    details: {
      color: '#757575',
      fontSize: '0.75rem',
      fontStyle: 'italic',
      fontWeight: '400',
      lineHeight: 'normal'
    },
    subText: {
      color: '#757575',
      /* C-D-Subtext/Light */
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: '300',
      lineHeight: '2rem' /* 177.778% */
    },
    formHeader: {
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal'
    },
    dashboardReportHeader: {
      fontSize: '1.875rem',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal'
    },
    websiteSubtitle: {
      fontSize: '1.1875rem',
      fontStyle: 'normal',
      fontWeight: '300',
      lineHeight: '140%'
    },
    menuSubtitle: {
      fontSize: '0.8125rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal'
    },
    body: {
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '1.375rem'
    },
    websiteDetails: {
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal'
    },
    cardGraphValue: {
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal'
    },
    cardHeader: {
      fontFamily: 'Proxima-Nova',
      fontSize: '1.125rem',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 'normal'
    },
    tableValue: {
      fontFamily: 'Roboto, monospace',
      textAlign: 'right',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal'
    },
    menuPage: {
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal'
    },
    menuPageGroup: {
      fontSize: '0.8125rem',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal'
    },
    menuPageGroupBold: {
      fontSize: '0.8125rem',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal'
    },
    menuCategory: {
      fontSize: '0.75rem',
      fontWeight: '400',
      color: 'rgba(255, 255, 255, 0.5)'
    }
  }
}

export default Typography

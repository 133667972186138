import { useMutation, useQueryClient } from '@tanstack/react-query'

import { queryErrorToast, querySuccessToast } from 'queries/query-toasts'
import axiosServices from 'utils/axios'

export interface IAddNewSourceApplicationBody {
  applicationName: string
  sourceSystemId: number
  destinationSchema: string
}

export const useCreateSourceApplication = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body: IAddNewSourceApplicationBody) => {
      return axiosServices.post(`/source_applications`, {
        source_application: {
          name: body.applicationName,
          source_system_id: body.sourceSystemId,
          destination_schema: body.destinationSchema
        }
      })
    },
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['fetch-application-types'] })
      querySuccessToast('Source Application added successfully')
    },
    onError: (error: any, variables) => {
      const message = _.get(error, 'errors[0]') || error.message || 'Something went wrong'
      const finalMessage = message.toLowerCase().includes('record not unique')
        ? 'Please choose a unique source application name'
        : message
      queryErrorToast(finalMessage)
    }
  })
}

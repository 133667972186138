import { useEffect } from 'react'

import * as am5xy from '@amcharts/amcharts5/xy'

import { replaceNewLineWithSpace } from 'utils/string-utils'

import { DATE_CELL_WIDTH_BREAKPOINTS } from '../types/data-types'
import { IPropsEffectProps } from '../types/module-types'
import { dateIntervalsAndFormattings } from '../utils/date-axis-utils'
import { getSelfRef } from '../utils/module-utils'

export default function useSetAxisGranularity({ propsRef, moduleName, props }: IPropsEffectProps) {
  const frequency = _.get(props, 'options.frequency')

  useEffect(() => {
    const configs = dateIntervalsAndFormattings(frequency)
    const axis: am5xy.DateAxis<am5xy.AxisRenderer> = getSelfRef({ ...propsRef.current, moduleName })
    axis.setAll({
      baseInterval: configs.interval,
      gridIntervals: configs.gridIntervals
    })

    function setDateFormat(multiLine = false) {
      const dateFormats = axis.get('dateFormats')
      const periodChangeDateFormats = axis.get('periodChangeDateFormats')
      if (!dateFormats || !periodChangeDateFormats) {
        return
      }
      _.forEach(configs.dateFormats, (value, key) => {
        dateFormats[key] = multiLine ? value : replaceNewLineWithSpace(value as string)
      })
      _.forEach(configs.periodChangeDateFormats, (value, key) => {
        periodChangeDateFormats[key] = multiLine ? value : replaceNewLineWithSpace(value as string)
      })
    }

    const responsiveDateFormattingEvent = axis.onPrivate('cellWidth', function (cellWidth) {
      if (!cellWidth) return
      if (cellWidth < DATE_CELL_WIDTH_BREAKPOINTS.medium) {
        setDateFormat()
      } else {
        setDateFormat(true)
      }
    })

    axis.get('renderer').labels.template.set('multiLocation', configs.interval.count > 1 ? 0.5 : 0)

    return () => {
      responsiveDateFormattingEvent.dispose()
    }
  }, [frequency, propsRef, moduleName])
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { AccessProfile } from 'types/access-profile'
import { User } from 'types/user'

import { RootState } from '..'

interface AccessManagementState {
  selectedUser?: User
  selectedInternalAccessProfile?: AccessProfile
  selectedSharedAccessProfile?: AccessProfile
  hideDeactivatedUsers?: boolean
  hideArchivedAccessProfiles?: boolean
}

const initialState: AccessManagementState = {
  selectedUser: undefined,
  selectedInternalAccessProfile: undefined,
  selectedSharedAccessProfile: undefined,
  hideDeactivatedUsers: false,
  hideArchivedAccessProfiles: false
}

const accessManagementSlice = createSlice({
  name: '@ACCESS_MANAGEMENT',
  initialState,
  reducers: {
    updateSelectedUser: (state, action: PayloadAction<User>) => {
      state.selectedUser = action.payload
    },
    updateSelectedInternalAccessProfile: (state, action: PayloadAction<AccessProfile>) => {
      state.selectedInternalAccessProfile = action.payload
    },
    updateSelectedSharedAccessProfile: (state, action: PayloadAction<AccessProfile>) => {
      state.selectedSharedAccessProfile = action.payload
    },
    toggleHideDeactivatedUsers: (state) => {
      state.hideDeactivatedUsers = !state.hideDeactivatedUsers
    },
    toggleHideArchivedAccessProfiles: (state) => {
      state.hideArchivedAccessProfiles = !state.hideArchivedAccessProfiles
    }
  }
})

export const {
  updateSelectedInternalAccessProfile,
  updateSelectedSharedAccessProfile,
  updateSelectedUser,
  toggleHideDeactivatedUsers,
  toggleHideArchivedAccessProfiles
} = accessManagementSlice.actions

export const accessManagementReducer = accessManagementSlice.reducer

export const selectSelectedUser = (state: RootState) => state.accessManagement.selectedUser
export const selectSelectedInternalAccessProfile = (state: RootState) =>
  state.accessManagement.selectedInternalAccessProfile
export const selectSelectedSharedAccessProfile = (state: RootState) =>
  state.accessManagement.selectedSharedAccessProfile
export const selectHideDeactivatedUsers = (state: RootState) =>
  state.accessManagement.hideDeactivatedUsers
export const selectHideArchivedAccessProfiles = (state: RootState) =>
  state.accessManagement.hideArchivedAccessProfiles

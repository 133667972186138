import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { Button, IconButton, Stack, Switch } from '@mui/material'

import { DeleteTwoTone, EditTwoTone } from '@ant-design/icons'

import AlertDialog from 'pages/dialog/alert'

import MainCard from '../../../components/MainCard'
import ScrollX from '../../../components/ScrollX'
import { CustomReactTable } from '../../../components/table/custom-react-table'
import { dispatch } from '../../../store'
import { openSnackbar } from '../../../store/reducers/snackbar'
import axiosServices from '../../../utils/axios'
import { LayoutViewProps } from '../../layout-view/types'

const Feature = ({ pageSettings }: LayoutViewProps) => {
  const [features, setFeatures] = useState<any>([])
  const striped = true

  const handleFeatureSwitchToggle = useCallback(
    (row: any) => {
      const updatedData = features.map((feature: any) =>
        feature.id === row.id ? { ...feature, enabled: !row.enabled } : feature
      )

      axiosServices
        .patch(`/features/${row.id}`, { enabled: !row.enabled })
        .then((response) => {
          showMessage('primary', response.data.message)
          setFeatures(updatedData)
        })
        .catch((error) => {
          showMessage('error', 'Failed to update.')
        })
    },
    [features]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Feature Name',
        accessor: 'name'
      },
      {
        Header: 'Description',
        accessor: 'description'
      },
      {
        Header: 'Enable / Disable',
        accessor: 'enabled',
        Cell: ({ row }: any) => {
          return (
            <>
              <Switch
                checked={row.original.enabled}
                onChange={() => handleFeatureSwitchToggle(row.original)}
                name='enable'
                color='primary'
              />
            </>
          )
        }
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({ row }: any) => {
          const [open, setOpen] = useState(false)
          const deleteFeature = () => {
            const featureId = row.original.id
            axiosServices
              .delete(`features/${featureId}`)
              .then((response) => {
                setFeatures(features.filter((feature: { id: number }) => feature.id !== featureId))
                dispatch(
                  openSnackbar({
                    open: true,
                    message: 'Feature deleted successfully.',
                    variant: 'alert',
                    alert: {
                      color: 'warning'
                    },
                    close: false
                  })
                )
              })
              .catch((error) => {
                showMessage('error', 'Failed to delete the feature.')
              })
          }

          return (
            <>
              <Link to={`/feature-flags/${row.original.id}/edit`}>
                <EditTwoTone />
              </Link>
              <IconButton onClick={() => setOpen(true)}>
                <DeleteTwoTone />
              </IconButton>
              <AlertDialog
                open={open}
                setOpen={setOpen}
                handleOk={deleteFeature}
                text={'Are you sure you want to delete this feature?'}
                title={'Delete feature?'}
              ></AlertDialog>
            </>
          )
        }
      }
    ],
    [features, handleFeatureSwitchToggle]
  )

  const showMessage = (type: string, message: string): void => {
    dispatch(
      openSnackbar({
        open: true,
        message: message,
        variant: 'alert',
        alert: {
          color: type
        },
        close: true
      })
    )
  }

  useEffect(() => {
    axiosServices
      .get(`/features.json`)
      .then((response) => {
        setFeatures(response.data)
      })
      .catch((error) => {
        showMessage('error', 'Failed to load features.')
      })
  }, [])

  return (
    <MainCard
      pageSettings={pageSettings}
      content={false}
      title={' '}
      secondary={
        <Button variant='outlined' href='/feature-flags/new'>
          New Feature
        </Button>
      }
    >
      <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2}></Stack>
      <ScrollX>
        <CustomReactTable columns={columns} data={features} striped={striped} />
      </ScrollX>
    </MainCard>
  )
}

export default Feature

import { useMemo } from 'react'

import { ColDef, ColGroupDef } from 'ag-grid-community'

import skeletonCellRenderer from '../skeleton-cell-renderer'

type Column = ColDef | ColGroupDef

type OriginalColumnDefs<T> = Column[] | ((skeletonData: T) => Column[])

interface ISkeletonProps<T> {
  skeletonData: T
  columnDefs: OriginalColumnDefs<T>
  excludeColGroups?: string[] // some colGroups should not be skeletonized for e.g. blank column groups in budget to actual
  forDataTable?: boolean
}

const applyCellRenderer = (
  columnDefs: Column[],
  excludeColGroups: string[] | undefined,
  forDataTable: boolean
): Column[] =>
  columnDefs.map((colDef) => {
    if ((colDef as ColGroupDef).children) {
      return excludeColGroups?.includes((colDef as ColGroupDef).groupId!)
        ? colDef
        : _.assign(
            { ...colDef },
            {
              children: applyCellRenderer(
                (colDef as ColGroupDef).children,
                excludeColGroups,
                forDataTable
              )
            }
          )
    }
    return _.assign(
      { ...colDef },
      { cellRenderer: skeletonCellRenderer },
      forDataTable && { cellRendererParams: { heightClass: 'h-[40px]' } }
    )
  })

const getSkeletonColumnDefs = <T,>(
  columnDefs: OriginalColumnDefs<T>,
  skeletonData: T,
  excludeColGroups: string[] | undefined,
  forDataTable: boolean
) => {
  const colDefs = typeof columnDefs === 'function' ? columnDefs(skeletonData) : columnDefs
  return applyCellRenderer(colDefs, excludeColGroups, forDataTable)
}

export const useSkeleton = <T,>(props: ISkeletonProps<T>) => {
  const { skeletonData, columnDefs, excludeColGroups, forDataTable = false } = props

  const skeletonColDefs = useMemo(
    () => getSkeletonColumnDefs(columnDefs, skeletonData, excludeColGroups, forDataTable),
    [columnDefs, skeletonData, excludeColGroups, forDataTable]
  )

  return {
    skeletonData: skeletonData,
    columnDefs: skeletonColDefs
  }
}

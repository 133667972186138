import { useParams } from 'react-router'

import { RedirectTo } from '@components/redirect-to'

import { ActiveConnectionsTab } from 'layout-components/general/data-connections/active-connections-tab'
import { useFetchConnectedSourceSystem } from 'layout-components/general/data-connections/queries/fetch-connected-source-system'
import { DataConnectionMode } from 'layout-components/general/data-connections/types'

export function LiveConnectionsTab({ mode }: { mode: DataConnectionMode }) {
  const { id } = useParams<{ id: string }>()
  const { isPending, data } = useFetchConnectedSourceSystem({ id })

  if (!isPending && !data?.pulseSupported) return <RedirectTo path='..' />

  return <ActiveConnectionsTab isPending={isPending} connectedSourceSystem={data} mode={mode} />
}

import { useMemo, useRef } from 'react'

import { ChargerForm, closePopover } from '@components/form/charger-form'
import { ChargerFormFieldConfig, FieldTypes, FormLayoutTypes } from '@components/form/types'

import useAuth from '@hooks/useAuth'

import Button from 'components/core/button'
import { Popover, PopoverContent, PopoverTrigger } from 'components/core/popover'
import { Business } from 'types/auth'
import * as Yup from 'yup'

import { useCreateConnectedSourceSystem } from './queries/create-connected-source-system'
import { useFetchSourceApplications } from './queries/fetch-source-applications'

export const AddApplicationConnectionForm = () => {
  const { user } = useAuth()
  const businesses: Business[] = useMemo(() => user?.businesses || [], [user])
  const { data: sourceApplications } = useFetchSourceApplications()

  const popoverTriggerRef = useRef<HTMLButtonElement>(null)

  const { mutate: addNewConnection } = useCreateConnectedSourceSystem()

  const fieldsConfig = useMemo(
    () =>
      [
        {
          name: 'businessId',
          label: 'Business',
          type: FieldTypes.SELECT,
          options: _.map(_.sortBy(businesses, 'name'), (business) => ({
            label: business.name,
            value: `${business.id}`
          })),
          required: true,
          placeholder: 'Select a Business',
          validationSchema: Yup.number().required('Required')
        },
        {
          name: 'applicationName',
          label: 'Connection Name',
          type: FieldTypes.TEXT_FIELD,
          required: true,
          placeholder: 'Connection Name',
          validationSchema: Yup.string().required('Required')
        },
        {
          name: 'applicationIdType',
          label: 'Application',
          type: FieldTypes.SELECT,
          options: _.map(_.sortBy(sourceApplications, 'name'), (app) => ({
            label: app.name,
            value: `${app.id}`
          })),
          required: true,
          placeholder: 'Select an Application',
          validationSchema: Yup.number().required('Required')
        }
      ] as ChargerFormFieldConfig[],
    [businesses, sourceApplications]
  )

  return (
    <div className='flex items-center'>
      <Popover>
        <PopoverTrigger asChild>
          <Button ref={popoverTriggerRef} variant='popoverTrigger'>
            Add New Application Connection
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <ChargerForm
            submitLabel='Add Connection'
            onCancel={() => closePopover(popoverTriggerRef.current)}
            layout={FormLayoutTypes.POPOVER}
            onSubmit={(data) => {
              addNewConnection({ ...data })
              closePopover(popoverTriggerRef.current)
            }}
            fieldsConfig={fieldsConfig}
            showLabels={false}
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}

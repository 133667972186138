import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'

import { getDisplayName } from '@utils/schema-renaming'

import { IModelRenamingDetail } from 'pages/component-management/types/query-builder-types'

import { rehydrateStore } from '../actions/rehydrate-store'
import { RootState } from '../index'

export type DisplayNameSchema = Record<string, { newName?: string; columns?: DisplayName }>
export type DisplayName = Record<string, string>

interface SchemaRenamingsState {
  renamings: DisplayNameSchema
  loading: boolean
  error: string | null
}

const initialState: SchemaRenamingsState = {
  renamings: {},
  loading: false,
  error: null
}

export const RENAMING_DEFAULTS: Record<string, { default?: string; columns?: DisplayName }> = {
  Department: { default: 'Department' },
  Vertical: { default: 'Vertical' },
  BusinessLocation: { default: 'Business Location' },
  GeneralLedgerAccount: {
    columns: {
      classification: 'Classification'
    }
  },
  GeneralLedgerClassification: { default: 'GL Classification' },
  Subsidiary: { default: 'Subsidiary' },
  Project: { default: 'Project' }
}

const schemaRenamingsSlice = createSlice({
  name: '@SCHEMA_RENAMINGS',
  initialState,
  reducers: {
    fetchRenamingsStart(state) {
      state.loading = true
      state.error = null
    },
    fetchRenamingsSuccess(state, action: PayloadAction<IModelRenamingDetail[]>) {
      state.loading = false
      state.renamings = action.payload.reduce((acc, curr) => {
        acc[curr.modelName] = {
          ...(curr.newName ? { newName: curr.newName } : {}),
          columns: curr.schemaColumnRenamings.reduce((columnsAcc: DisplayName, column) => {
            columnsAcc[column.columnName] = column.newName
            return columnsAcc
          }, {})
        }
        return acc
      }, {} as DisplayNameSchema)
    },
    fetchRenamingsFailure(state, action: PayloadAction<string>) {
      state.loading = false
      state.error = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrateStore, (state, action) => {
      return { ...initialState, ...action.payload.schemaRenamings }
    })
  }
})

export const { fetchRenamingsStart, fetchRenamingsSuccess, fetchRenamingsFailure } =
  schemaRenamingsSlice.actions

export const schemaRenamingsReducer = schemaRenamingsSlice.reducer

// Selectors
export const selectSchemaRenamings = (state: RootState) => state.schemaRenamings.renamings
export const selectSchemaRenamingsLoading = (state: RootState) => state.schemaRenamings.loading
export const selectSchemaRenamingsError = (state: RootState) => state.schemaRenamings.error

export const selectRenaming = (modelName: string, columnName?: string, includeDefaults = true) =>
  createSelector(selectSchemaRenamings, (renamings) =>
    getDisplayName(renamings, modelName, columnName, includeDefaults)
  )

export const findHighestValues = (layouts: any, key: string) => {
  return layouts.reduce((acc: any, layout: any) => {
    return Math.max(acc, layout[key])
  }, -Infinity)
}

// Extracts and returns an array of layoutAttributes from a given section
export const extractLayoutAttributes = (obj: any) => {
  if (obj && typeof obj === 'object') {
    if (obj.component_attributes && obj.component_attributes.layoutAttributes) {
      return [obj.component_attributes.layoutAttributes]
    } else if (obj.children) {
      return obj.children.flatMap(extractLayoutAttributes)
    }
  }
  return []
}

// @ts-ignore ignore
export const addWidgetToFreeStyleSection = (layout, splitDropZonePath, widget) => {
  const updatedLayout = [...layout]

  // Get the row index
  const rowIndex = Number(splitDropZonePath[0])

  // Check if the row has children (columns)
  if (
    updatedLayout[rowIndex] &&
    updatedLayout[rowIndex].children &&
    updatedLayout[rowIndex].children.length > 0
  ) {
    // Assume we're adding the widget to the first column of the row for this example
    const columnIndex = 0

    // Check if the column has children (widgets)
    if (
      updatedLayout[rowIndex].children[columnIndex] &&
      updatedLayout[rowIndex].children[columnIndex].children &&
      updatedLayout[rowIndex].children[columnIndex].children.length > 0
    ) {
      // Add the new widget to the column's children
      updatedLayout[rowIndex].children[columnIndex].children.push(widget)
    } else {
      // If the column doesn't have children yet, create a new array with the widget
      updatedLayout[rowIndex].children[columnIndex].children = [widget]
    }
  }

  return updatedLayout
}

export const updateFreeStyleLayoutWidgetPosition = (
  layoutToUpdate: any,
  prop: any,
  newLayout: any
) => {
  const { id, path } = prop

  const sectionIndex = layoutToUpdate.findIndex((item: any) => item.id === id)

  if (sectionIndex !== -1) {
    const column = layoutToUpdate[sectionIndex].children[path]

    if (column && column.children) {
      column.children.forEach((widget: any, index: any) => {
        const updatedWidget = newLayout.find((layoutItem: any) => layoutItem.i === widget.id)
        if (updatedWidget) {
          layoutToUpdate[sectionIndex].children[path].children[
            index
          ].component_attributes.layoutAttributes = {
            ...widget.component_attributes.layoutAttributes,
            x: updatedWidget.x,
            y: updatedWidget.y,
            h: updatedWidget.h,
            w: updatedWidget.w
          }
        }
      })
    }
  }

  return layoutToUpdate
}

// NOTE: For FreeStyle row we always assume The child will be found inside: row.children[0].children[0]
export const handleRemoveItemFromFreeStyleRow = (
  layout: any[],
  path: string,
  item_identifier: any
) => {
  return layout.map((row: any) => ({
    ...row,
    children: row.children.map((column: any) => ({
      ...column,
      children: column.children.filter((widget: any) => widget.id !== item_identifier)
    }))
  }))
}

import { useMemo } from 'react'

import { isBlank } from '@utils/lodash'

import { Select } from '@components/form/select'

import useAuth from '@hooks/useAuth'

import { usePageDispatch, usePageSelector } from '@store/index'
import { selectLanguageId, updateLanguageId } from '@store/slices/action-bar'

import { Language } from 'types/language'

export const DEFAULT_LANGUAGE_ID = 'default'

const LanguageSelector = () => {
  const pageDispatch = usePageDispatch()
  const languageId = usePageSelector(selectLanguageId)

  const { user } = useAuth()

  const currentBusinessLanguages: Language[] = useMemo(() => {
    return user?.business_languages || []
  }, [user?.business_languages])

  const options = useMemo(() => {
    const availableTranslations = _.map(currentBusinessLanguages, (language) => ({
      value: language.id as string,
      label: language.name
    }))

    return [...availableTranslations, { value: DEFAULT_LANGUAGE_ID, label: 'Default Language' }]
  }, [currentBusinessLanguages])

  if (isBlank(currentBusinessLanguages)) {
    return <></>
  }

  return (
    <Select
      size='small'
      options={options}
      value={languageId || DEFAULT_LANGUAGE_ID}
      onChange={(value: string) => {
        pageDispatch(updateLanguageId({ languageId: value }))
      }}
    />
  )
}

export default LanguageSelector

import {
  AggFuncStringType,
  ITableColumn,
  isCellDataTypeNumber
} from 'pages/component-management/types/table-builder-types'

import { ALL_COLUMNS_PREFIX, Containers, SectionLayoutType } from './types'

export const fieldIdForAllColumnsContainer = (field: string) => `${ALL_COLUMNS_PREFIX}${field}`

export const isIdInAllColumnsContainer = (id?: string | null) =>
  id ? _.startsWith(`${id}`, ALL_COLUMNS_PREFIX) : false

export const getColumnField = (id: string) =>
  isIdInAllColumnsContainer(id) ? `${id}`.slice(ALL_COLUMNS_PREFIX.length) : id

export const createSectionLayoutFromColumns = (columns: ITableColumn[]): SectionLayoutType => {
  const rowGroups = _.chain(columns)
    .filter({ rowGroup: true })
    .sortBy('rowGroupIndex')
    .map('field')
    .value()

  const values = _.chain(columns)
    .filter((column) => !!column.aggFunc)
    .map('field')
    .value()

  const columnLabels = _.chain(columns)
    .filter({ pivot: true })
    .sortBy('pivotIndex')
    .map('field')
    .value()

  return {
    [Containers.ROW_GROUPS]: rowGroups,
    [Containers.VALUES]: values,
    [Containers.COLUMN_LABELS]: columnLabels
  }
}

// TODO: Write tests for these functions
export const onSectionLayoutAndColumnOrderChange = (
  sectionLayout: SectionLayoutType,
  columns: ITableColumn[]
) => {
  const oldColumnsMap = _.keyBy(columns, 'field')
  let newColumns = _.cloneDeep(columns)

  const pivotMode = isPivotMode(sectionLayout)

  newColumns = _.map(newColumns, (column) => {
    const currColumn: ITableColumn = _.omit(
      column,
      'rowGroup',
      'rowGroupIndex',
      'aggFunc',
      'pivot',
      'pivotIndex'
    )
    if (pivotMode) currColumn.hide = true

    if (_.includes(sectionLayout[Containers.ROW_GROUPS], column.field)) {
      currColumn.hide = false
      currColumn.rowGroup = true
      currColumn.rowGroupIndex = _.indexOf(sectionLayout[Containers.ROW_GROUPS], column.field)
    }

    if (_.includes(sectionLayout[Containers.VALUES], column.field)) {
      currColumn.hide = false
      currColumn.aggFunc = oldColumnsMap[column.field].aggFunc || AggFuncStringType.SUM
    }

    if (_.includes(sectionLayout[Containers.COLUMN_LABELS], column.field)) {
      currColumn.hide = false
      currColumn.pivot = true
      currColumn.pivotIndex = _.indexOf(sectionLayout[Containers.COLUMN_LABELS], column.field)
    }

    return currColumn
  })

  if (_.gt(_.size(sectionLayout[Containers.VALUES]), 1)) {
    newColumns = organizeColumnsBySection(newColumns, sectionLayout[Containers.VALUES])
  }

  return newColumns
}

const organizeColumnsBySection = (columns: ITableColumn[], ordered: string[]) => {
  const clonedColumns = _.cloneDeep(columns)

  let count = -1
  return _.map(clonedColumns, (column) => {
    if (!_.includes(ordered, column.field)) return column

    count += 1
    const index = _.findIndex(columns, { field: ordered[count] })
    if (index === -1) return column

    return columns[index]
  })
}

const isPivotMode = (sectionLayout: SectionLayoutType) =>
  sectionLayout[Containers.COLUMN_LABELS].length > 0

const resetSectionLayoutProps = (column: ITableColumn) => {
  column.rowGroup = false
  column.rowGroupIndex = undefined
  column.aggFunc = undefined
  column.pivot = false
  column.pivotIndex = undefined
}

export const onColumnVisibilityChange = (
  field: string,
  checked: boolean,
  columns: ITableColumn[],
  sectionLayout: SectionLayoutType
) => {
  const pivotMode = isPivotMode(sectionLayout)

  const newColumns = _.cloneDeep(columns)

  const currentColumn = _.find(newColumns, { field })
  if (!currentColumn) return newColumns

  if (pivotMode) {
    if (checked) {
      if (isCellDataTypeNumber(currentColumn.cellDataType)) {
        currentColumn.hide = false
        currentColumn.aggFunc = AggFuncStringType.SUM
      } else {
        currentColumn.hide = false
        currentColumn.rowGroup = true
        currentColumn.rowGroupIndex = _.size(sectionLayout[Containers.ROW_GROUPS])
      }
    } else {
      currentColumn.hide = true

      if (!currentColumn.rowGroup) {
        resetSectionLayoutProps(currentColumn)
      }
    }
  } else {
    if (checked) {
      currentColumn.hide = false
    } else {
      currentColumn.hide = true

      if (!currentColumn.rowGroup) {
        resetSectionLayoutProps(currentColumn)
      }
    }
  }

  return newColumns
}

export const onColumnRemovedFromContainers = (
  field: string,
  _container: string,
  columns: ITableColumn[],
  sectionLayout: SectionLayoutType
) => {
  const pivotMode = isPivotMode(sectionLayout)

  let newColumns = _.cloneDeep(columns)

  const currentColumn = _.find(newColumns, { field })
  if (!currentColumn) return newColumns

  if (pivotMode) {
    newColumns = onColumnVisibilityChange(field, false, newColumns, sectionLayout)
  } else {
    resetSectionLayoutProps(currentColumn)
  }

  return newColumns
}

import { cn } from '@utils/style-utils'

import { useSelector } from '@store/index'
import { selectAreAssociationsSelected } from '@store/slices/component/query-config'

import { IAssociationData, IColumnData } from '../../types/query-builder-types'
import { AssociationSection } from './association-section'
import { ColumnSection } from './column-section'

export function ModelSection({
  modelName,
  columns = [],
  associations = [],
  parent = ''
}: {
  modelName: string
  columns?: IColumnData[]
  associations?: IAssociationData[]
  parent?: string
}) {
  const areAssociationsSelected = useSelector(selectAreAssociationsSelected(parent))

  return (
    <>
      {columns.map((column) => (
        <ColumnSection key={column.name} field={column} modelName={modelName} parent={parent} />
      ))}
      {areAssociationsSelected && (
        <div className={cn('flex flex-col gap-2', parent && 'ml-2')}>
          {associations.map((association) => (
            <AssociationSection key={association.name} association={association} parent={parent} />
          ))}
        </div>
      )}
    </>
  )
}

import { Typography } from '@mui/material'

import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'

// @ts-ignore
const DraggableComponent = (props) => {
  const { item, component } = props

  const { attributes, isDragging, listeners, setNodeRef, transform } = useDraggable({
    id: component.id,
    data: item
  })

  const style = transform
    ? {
        transform: CSS.Translate.toString(transform),
        cursor: isDragging ? 'grabbing' : 'grab'
      }
    : undefined

  return (
    <div className='draggable-component'>
      <Typography variant='caption'>{component.title}</Typography>
      <div
        className='draggable-component-grid'
        style={{ borderColor: isDragging ? 'blue' : '#ccc', opacity: isDragging ? 0.5 : 1 }}
      >
        <img
          style={style}
          ref={setNodeRef}
          {...attributes}
          {...listeners}
          className='draggable-component-grid-img'
          src={component.imgSrc}
          alt={component.name}
        />
      </div>
    </div>
  )
}

export default DraggableComponent

import { useMemo, useState } from 'react'
import { useLocation } from 'react-router'

import { ArrowLeft } from '@mui/icons-material'
import { Box, Divider, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'

import { capitalize } from '@utils/string-utils'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from 'components/core/select'
import { Text } from 'components/core/text'
import useAuth from 'hooks/useAuth'
import { Business, UserProfile } from 'types/auth'
import { isBlank } from 'utils/lodash'

import { borderColor } from '../config'
import { capitalizeValueAtPath } from '../utils/obj-utils'
import { SelectControlModeEnum } from './control-panel/types'
import { List } from './core/list'
import { ChevronLeft, ChevronRight, Icon } from './icons'
import SearchBox from './search-box'

interface Props {
  fullWidth?: boolean
  mode?: SelectControlModeEnum
  onSelectItem?: () => void
}

export const BusinessSelector = (props: Props) => {
  const location = useLocation()

  const { user, onBusinessSelectorChange } = useAuth()
  const [searchTerm, setSearchTerm] = useState('')

  const businesses: Business[] = useMemo(() => user?.businesses || [], [user])

  const currentBusinessName = useMemo(() => {
    if (businesses && user?.business_id) {
      const currentBusiness = businesses.find((business: any) => business.id === user?.business_id)
      return currentBusiness?.name
    }
  }, [businesses, user?.business_id])

  const handleSearchChange = (value: string) => {
    setSearchTerm(value)
  }

  const handleListItemClick = () => {
    if (props.onSelectItem) {
      props.onSelectItem()
    }
  }

  const handleBusinessClick = (item: any) => {
    handleListItemClick()
    onBusinessSelectorChange(user as UserProfile, Number(item?.id))
  }

  if (isBlank(businesses) || _.isEqual(businesses.length, 1)) return <></>

  const lineDivider = <Divider sx={{ '&.MuiDivider-root': { borderColor: borderColor } }} />

  const filteredBusinesses = businesses.filter((business: any) =>
    capitalizeValueAtPath(business, 'name').includes(capitalize(searchTerm))
  )

  if (props.mode === SelectControlModeEnum.item) {
    return (
      <Box>
        <ListItemButton
          onClick={handleListItemClick}
          sx={{ pl: 1, py: 0.5, borderBottom: `1px solid ${borderColor}` }}
        >
          <ListItemText
            primary={
              <Typography variant='h5' color='text.primary'>
                {currentBusinessName}
              </Typography>
            }
          />
          <ListItemIcon>
            <Icon icon={<ChevronRight />} className='my-1 mr-2 text-white' />
          </ListItemIcon>
        </ListItemButton>
      </Box>
    )
  } else if (props.mode === SelectControlModeEnum.list) {
    return (
      <Box>
        <ListItemButton onClick={handleListItemClick} sx={{ p: 0, py: 0.5 }}>
          <ListItemIcon>
            <Icon icon={<ChevronLeft />} className='my-1 mr-2 text-white' />
          </ListItemIcon>

          <ListItemText
            primary={
              <Typography variant='body' color='text.primary'>
                BACK
              </Typography>
            }
          />
        </ListItemButton>

        {lineDivider}

        <SearchBox
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          onClearSearch={() => setSearchTerm('')}
        />

        {filteredBusinesses.map((item: any) => (
          <>
            {lineDivider}
            <ListItemButton
              key={item.id}
              onClick={() => handleBusinessClick(item)}
              sx={{ pl: 1, py: 0.5 }}
            >
              <ListItemText primary={capitalizeValueAtPath(item, 'name')} />
              <ListItemIcon>
                <Icon icon={<ChevronRight />} className='my-1 mr-2 text-white' />
              </ListItemIcon>
            </ListItemButton>
          </>
        ))}
      </Box>
    )
  } else {
    return (
      <div className='flex w-full items-center'>
        <Select
          className='min-w-0 flex-1'
          value={user?.business_id?.toString()}
          onValueChange={(value: any) =>
            onBusinessSelectorChange(user as UserProfile, Number(value))
          }
        >
          <SelectTrigger className='border-none px-4 shadow-none focus:ring-0 [&>svg]:text-primary'>
            <SelectValue placeholder='Select Business' />
          </SelectTrigger>
          <SelectContent className='screen max-h-[80vh] rounded-t-none shadow-[2px_4px_0px_0px_rgba(0,0,0,0.03)]'>
            <List>
              {businesses.map((item) => (
                <SelectItem
                  className={'h-10 w-80 text-primary-darker'}
                  key={item.id}
                  value={item.id.toString()}
                  checkIconClassName='text-primary'
                >
                  {_.get(item, 'name')}
                </SelectItem>
              ))}
            </List>
          </SelectContent>
        </Select>

        {location.pathname === '/welcome' && (
          <div className='flex shrink-0 items-center justify-center rounded-full bg-primary py-1 pr-2'>
            <ArrowLeft className='text-white' />
            <Text variant='button' weight='semibold' className='text-white'>
              Business Selector
            </Text>
          </div>
        )}
      </div>
    )
  }
}

import { useEffect } from 'react'
import { useParams } from 'react-router'

import { Separator } from '@components/core/separator'
import { Skeleton } from '@components/core/skeleton'
import { Text } from '@components/core/text'

import useChangeDetection from '@hooks/use-change-detection'

import { EditConfiguration } from 'layout-components/general/data-connections/edit-configuration'
import { useFetchConnectedSourceSystem } from 'layout-components/general/data-connections/queries/fetch-connected-source-system'
import { DataConnectionMode } from 'layout-components/general/data-connections/types'

export function ConfigurationTab({ mode }: { mode: DataConnectionMode }) {
  const { id } = useParams<{ id: string }>()
  const {
    isPending,
    data: connectedSourceSystem,
    isError,
    error
  } = useFetchConnectedSourceSystem({ id })
  const [formResetKey, triggerFormReset] = useChangeDetection()

  useEffect(() => triggerFormReset(), [connectedSourceSystem, triggerFormReset])

  if (isError) return <Text variant='body'>{error?.message || 'Failed to load connection'}</Text>

  if (isPending) {
    return (
      <div className='mt-2 flex flex-1 flex-col gap-2'>
        <div className='w-96 space-y-4'>
          <div>
            <Skeleton className='h-4 w-24' />
            <Skeleton className='h-12 w-48' />
          </div>
          <div className='flex gap-2'>
            <Skeleton className='h-8 w-12' />
            <Skeleton className='h-8 w-12' />
          </div>
        </div>
        <Separator className='w-full' />
        {[...Array(5)].map((_, index) => (
          <div key={index}>
            <Skeleton className='h-4 w-24' />
            <Skeleton className='h-12 w-96' />
          </div>
        ))}
        <Skeleton className='h-8 w-96' />
      </div>
    )
  }

  return (
    <EditConfiguration
      key={formResetKey}
      className='flex-1'
      mode={mode}
      businessId={connectedSourceSystem?.businessId}
      connectedSourceSystem={connectedSourceSystem!}
      onCancel={triggerFormReset}
    />
  )
}

import { useEffect, useMemo, useState } from 'react'

import { Box, List, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material'

import { useDebounce } from '@uidotdev/usehooks'

import { useDispatch, useSelector } from '@store/index'
import {
  selectShowSelectedQueryDataAttrs,
  toggleShowSelectedQueryDataAttrs
} from '@store/slices/component/query-config'

import ErrorBoundary from 'components/ErrorBoundary'

import { useComponentAttrRef } from '../../hooks/use-component-attr-refs'
import { IModel, IModelDetail } from '../../types/query-builder-types'
import filterModelData from '../../utils/filter-model-data'
import FieldsChooser from './fields-chooser'
import ModelChooser from './model-chooser'

export function FieldsPanel({
  models,
  detailData,
  currentSelectedModel,
  rootSelected,
  modelSelected,
  navigateToModel,
  navigateToRoot
}: {
  models?: IModel[]
  detailData?: IModelDetail
  currentSelectedModel: string | null
  rootSelected: boolean
  modelSelected: boolean
  navigateToModel: () => void
  navigateToRoot: () => void
}) {
  const dispatch = useDispatch()

  const [searchText, setSearchText] = useState<string>('')
  const debouncedSearchText = useDebounce(searchText, 300)
  const showSelected = useSelector(selectShowSelectedQueryDataAttrs)
  const { queryDataRef: dataRef } = useComponentAttrRef()

  useEffect(() => {
    currentSelectedModel ? navigateToModel() : navigateToRoot()
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on mount
  }, [])

  const onModelSelected = () => {
    navigateToModel()
    setSearchText('')
  }

  const processedData = useMemo(() => {
    if (!rootSelected) return models
    if (!debouncedSearchText) return models

    return _.filter(
      models,
      (model) =>
        model.name === currentSelectedModel ||
        model.name.toLowerCase().includes(debouncedSearchText.toLowerCase())
    )
  }, [models, debouncedSearchText, currentSelectedModel, rootSelected])

  const processedDetailData = useMemo(() => {
    if (!modelSelected) return detailData

    return filterModelData(debouncedSearchText, showSelected, dataRef.current, detailData)
  }, [modelSelected, detailData, debouncedSearchText, showSelected, dataRef])

  return (
    <div className='size-full'>
      <Stack direction='row' alignItems='center' pr={2} margin='16px 0 0'>
        <TextField
          fullWidth
          placeholder='Start typing your search request...'
          variant='outlined'
          type='search'
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Tooltip title='Toggle Selected' placement='top'>
          <Switch
            checked={showSelected}
            onChange={() => dispatch(toggleShowSelectedQueryDataAttrs())}
          />
        </Tooltip>
      </Stack>
      <Box sx={{ width: '100%', pr: 2, height: 'calc(100vh - 220px)', overflowY: 'auto' }}>
        {rootSelected && (
          <List sx={{ width: '100%' }}>
            {processedData?.map((model) => (
              <ModelChooser
                key={model.name}
                modelName={model.name}
                modelType={model.type}
                onSelected={onModelSelected}
              />
            ))}
            {showSelected && !currentSelectedModel && (
              <Typography variant='subtitle2'>No model selected</Typography>
            )}
          </List>
        )}
        {modelSelected && currentSelectedModel && (
          <ErrorBoundary fallback={<p>error</p>}>
            <FieldsChooser
              modelName={currentSelectedModel}
              columns={processedDetailData?.columns}
              associations={processedDetailData?.associations}
            />
          </ErrorBoundary>
        )}
      </Box>
    </div>
  )
}

import { useRef } from 'react'

import { Spinner } from '@core/spinner'

import { Button } from '@components/core/button'
import { Popover, PopoverContent, PopoverTrigger } from '@components/core/popover'
import { Text } from '@components/core/text'
import { closePopover } from '@components/form/charger-form'

import { useFlushConnections } from './queries/flush-connections'
import { IConnectedSourceSystem } from './types'

export const FlushConnections = ({
  connectedSourceSystem,
  isPending
}: {
  connectedSourceSystem?: IConnectedSourceSystem
  isPending: boolean
}) => {
  const popoverTriggerRef = useRef<HTMLButtonElement>(null)

  const { mutate: flushConnections, isPending: isFlushing } = useFlushConnections({
    onSuccess: () => closePopover(popoverTriggerRef.current)
  })

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button ref={popoverTriggerRef} variant='primary' size='small' disabled={isPending}>
          Flush Connections
        </Button>
      </PopoverTrigger>

      <PopoverContent className='mt-1'>
        <div className='flex flex-col gap-2'>
          <Text variant='body' className='text-blue-dark'>
            Are you Sure?
          </Text>

          <div className='flex justify-end gap-2'>
            <Button
              variant='text'
              className='mt-2'
              onClick={() => closePopover(popoverTriggerRef.current)}
            >
              Cancel
            </Button>
            <Button
              variant={isFlushing ? 'disabled' : 'primary'}
              className='mt-2'
              disabled={isFlushing}
              onClick={() => {
                flushConnections({
                  businessId: connectedSourceSystem?.businessId,
                  id: connectedSourceSystem?.id
                })
              }}
            >
              {isFlushing && <Spinner variant='button' />}
              Confirm
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}

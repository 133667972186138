import { useEffect } from 'react'

import { useDispatch } from '@store/index'
import { syncColumnsFromHeaders } from '@store/slices/component/table-config'

import { useQueryResultContext } from '../../contexts/query-result-context'

export function useSyncColsOnInit() {
  const { visibleHeaders, isFetching } = useQueryResultContext()
  const dispatch = useDispatch()

  useEffect(() => {
    if (isFetching) return

    dispatch(syncColumnsFromHeaders(visibleHeaders))
  }, [visibleHeaders, isFetching, dispatch])
}

import { useQuery } from '@tanstack/react-query'

import { FinancialHierarchyTree } from 'components/financial/types'
import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'

const fetchHierarchyBuilderNodes = async (
  businessId: number | undefined,
  hierarchyId?: number
): Promise<FinancialHierarchyTree[]> => {
  const response = await axiosServices.get(
    `/businesses/${businessId}/general_ledger_account_hierarchies/${hierarchyId}/hierarchy_builder_nodes`
  )
  return response.data
}

export const useFetchHierarchyBuilderNodes = (hierarchyId?: number) => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-hierarchy-builder-nodes', user?.business_id, hierarchyId],
    queryFn: () => fetchHierarchyBuilderNodes(user?.business_id, hierarchyId),
    enabled: !!hierarchyId
  })
}

import React, { useState } from 'react'

import { humanizeFieldName } from '@utils/string-utils'
import { cn } from '@utils/style-utils'

import { Button } from '@core/button'
import { IconButton } from '@core/icon-button'
import { Skeleton } from '@core/skeleton'
import Tooltip from '@core/tooltip'

import { AssociationSelector } from '@components/component-management/AssociationSelector'
import { DataPill } from '@components/component-management/DataPill'
import { Check, DataLabelVisibility, DataLabelVisibilityOff, Icon, Pencil } from '@components/icons'
import ArrowLeft from '@components/icons/arrow-left.svg?react'
import ForeignKeyHiddenIcon from '@components/icons/foreign-key-hidden.svg?react'
import ForeignKeyIcon from '@components/icons/foreign-key.svg?react'

import { fieldsPath } from '@store/slices/component/query-config'

import { SelectionMode } from 'pages/component-management/data-selection'
import {
  CustomFieldType,
  IAssociationData,
  IColumnData,
  IDataTab,
  IField,
  IModelDetail,
  IQueryData
} from 'pages/component-management/types/query-builder-types'

const EXCLUDED_TABLES = ['business', 'connected_source_system'] // CHAR-3356

interface ColumnSelectorProps {
  model: IModelDetail | IAssociationData | undefined
  modelName: string
  renderName?: (modelName: string, columnName?: string) => string | undefined // render prop for schema renaming
  onPressBack?: () => void
  showForeignKeys?: boolean
  setShowForeignKeys?: (show: boolean) => void
  hideUnusedTables?: boolean
  onClick?: () => void
  loading?: boolean
  parent?: string
  onTableExpand?: (dataTab: IDataTab) => void
  onSelect?: (column: IColumnData, parent: string) => void
  onColumnEdit?: (modelName: string, columnId?: number) => void
  selectedData?: IQueryData
  selectedCustomData?: IQueryData
  selectionMode?: SelectionMode
  customTargetModel?: string
  selectedCustomFieldId?: number
  searchText?: string
}

export function ColumnSelector(props: ColumnSelectorProps) {
  const {
    model,
    modelName,
    renderName,
    onPressBack = _.noop,
    showForeignKeys = false,
    setShowForeignKeys = _.noop,
    hideUnusedTables = false,
    onClick,
    loading = false,
    parent = '',
    onTableExpand = _.noop,
    onSelect = _.noop,
    onColumnEdit = _.noop,
    selectedData,
    selectedCustomData,
    selectionMode = SelectionMode.Normal,
    customTargetModel,
    selectedCustomFieldId,
    searchText = ''
  } = props
  const [hideUnusedColumns, setHideUnusedColumns] = useState<boolean>(false)

  const associationName = (model as IAssociationData)?.name
  const modelDisplayName = associationName
    ? humanizeFieldName(associationName)
    : renderName?.(modelName) || humanizeFieldName(modelName)
  const columns = _.sortBy(
    _.filter(model?.columns, (column) => !_.includes(EXCLUDED_TABLES, column.association_name)),
    (column) => _.toLower(column.name) // alphabetically sort columns
  )
  const associations = _.sortBy(
    _.filter(model?.associations, (association) => !_.includes(EXCLUDED_TABLES, association.name)),
    (association) => _.toLower(association.name) // alphabetically sort associations
  )
  const foreignKeys = _.compact(_.map(columns, 'association_name'))

  // for handling UI states in custom field selection mode
  const isCustomMode = selectionMode === SelectionMode.Custom
  const isDifferentModel = selectedData?.model !== customTargetModel
  const isWithinSameModel = _.includes(parent, `.${customTargetModel}`)
  const shouldDisableFields = isCustomMode && isDifferentModel && !isWithinSameModel

  const isSelected = (column: IColumnData) => {
    const fields = _.get(
      isCustomMode ? selectedCustomData : selectedData,
      fieldsPath(parent),
      [] as IField[]
    )
    return _.some(fields, (field) => _.isEqual(field.name, column.name))
  }

  return (
    <div className='flex'>
      <div className='mr-4 grid h-full w-fit min-w-[372px] shrink-0 gap-y-2 rounded border border-solid border-grey bg-white p-2 shadow-brand'>
        {/* header */}
        <div className='flex items-center justify-between border-x-0 border-b border-t-0 border-solid border-grey-lighter pb-2 text-inline-form-title'>
          <div
            onClick={onClick || _.noop}
            className={cn(
              'ml-0.5 flex items-center whitespace-nowrap',
              onClick && 'cursor-pointer'
            )}
          >
            {!parent && <BackButton onClick={onPressBack} disabled={isCustomMode} />}
            {modelDisplayName}
          </div>
          <div className='ml-4 flex gap-1'>
            <Tooltip
              title={hideUnusedColumns ? 'Show unused columns' : 'Hide unused columns'}
              side='bottom'
              delay={0}
            >
              <IconButton
                onClick={() => setHideUnusedColumns(!hideUnusedColumns)}
                variant={isCustomMode ? 'disabled' : 'outline'}
                disabled={isCustomMode}
              >
                <Icon
                  icon={hideUnusedColumns ? <DataLabelVisibility /> : <DataLabelVisibilityOff />}
                />
              </IconButton>
            </Tooltip>

            {!parent && (
              <Tooltip
                title={showForeignKeys ? 'Hide foreign keys' : 'Show foreign keys'}
                side='bottom'
                delay={0}
              >
                <IconButton onClick={() => setShowForeignKeys(!showForeignKeys)}>
                  {showForeignKeys ? <ForeignKeyIcon /> : <ForeignKeyHiddenIcon />}
                </IconButton>
              </Tooltip>
            )}

            <Button
              variant={isCustomMode ? 'disabled' : 'outline'}
              size='small'
              onClick={() => onColumnEdit(associationName || modelName)}
              disabled={isCustomMode}
            >
              Add Field
            </Button>
          </div>
        </div>

        {/* content */}
        {loading ? (
          <Loading />
        ) : (
          <>
            {_.isEmpty(columns) && <div>No columns found</div>}

            {_.map(columns, (column: IColumnData) => {
              const isForeignKey = !!column.association_name
              const isId = column.name === 'id'
              const selected = isSelected(column)

              let shouldHide = !showForeignKeys && (isForeignKey || isId) && !selected
              if (!selected && hideUnusedColumns && !isCustomMode) shouldHide = true
              if (shouldHide) return null

              return (
                <ColumnEntry
                  key={column.name}
                  column={column}
                  displayName={renderName?.(modelName, column.name)}
                  selected={selected}
                  isForeignKey={isForeignKey}
                  isId={isId}
                  onSelect={() => onSelect(column, parent)}
                  onEdit={(columnId?: number) => onColumnEdit(modelName, columnId)}
                  variant={
                    isCustomMode && selectedCustomFieldId === column.id ? 'primary' : 'outline'
                  }
                  searchText={searchText}
                  disabled={shouldDisableFields}
                  // we disable edit when the field is already selected, because the working selectedData from
                  // redux doesn't sync well after saving changes (field name, for example) to the server.
                  disableEdit={isCustomMode || selected}
                />
              )
            })}

            {isCustomMode && customTargetModel === modelName && !selectedCustomFieldId && (
              <ColumnEntry
                column={{
                  name: 'untitled_custom_field',
                  field_type: CustomFieldType.TEXT,
                  custom: true
                }}
                variant='primary'
                disableEdit={isCustomMode}
              />
            )}
          </>
        )}
      </div>

      {/* associations, if any */}
      {!_.isEmpty(associations) && (
        <div className='shrink-0'>
          <AssociationSelector
            associations={associations}
            renderName={renderName}
            foreignKeys={foreignKeys}
            showForeignKeys={showForeignKeys}
            hideUnusedTables={hideUnusedTables}
            parent={parent}
            onTableExpand={onTableExpand}
            onSelect={onSelect}
            onColumnEdit={onColumnEdit}
            selectedData={selectedData}
            selectedCustomData={selectedCustomData}
            selectionMode={selectionMode}
            customTargetModel={customTargetModel}
            selectedCustomFieldId={selectedCustomFieldId}
            searchText={searchText}
          />
        </div>
      )}
    </div>
  )
}

function ColumnEntry({
  column,
  displayName,
  selected = false,
  isForeignKey = false,
  isId = false,
  onSelect = _.noop,
  onEdit = _.noop,
  variant,
  searchText = '',
  disabled = false,
  disableEdit = false
}: {
  column: IColumnData
  displayName?: string
  selected?: boolean
  isForeignKey?: boolean
  isId?: boolean
  onSelect?: () => void
  onEdit?: (columnId?: number) => void
  variant?: 'outline' | 'primary'
  searchText?: string
  disabled?: boolean
  disableEdit?: boolean
}) {
  const label = displayName || humanizeFieldName(column.name)
  const isCustomField = column.custom
  return (
    <div className='flex items-center justify-between'>
      <DataPill
        key={column.name}
        label={label}
        dataType={column.field_type}
        variant={variant}
        isForeignKey={isForeignKey}
        isId={isId}
        isCustomField={isCustomField}
        selected={selected}
        onClick={onSelect}
        highlight={!!searchText && label.toLowerCase().includes(searchText.toLowerCase())}
        disabled={disabled}
        lightIcon
      />
      <div className='flex items-center gap-2'>
        {isCustomField && (
          <IconButton
            className='rounded-full enabled:hover:bg-grey-lighter disabled:hover:bg-white'
            variant='ghost'
            onClick={() => onEdit(column.id)}
            disabled={disableEdit}
          >
            <Icon icon={<Pencil />} />
          </IconButton>
        )}
        {selected && <Icon icon={<Check />} />}
      </div>
    </div>
  )
}

function BackButton({ onClick, disabled }: { onClick?: () => void; disabled?: boolean }) {
  return (
    <Tooltip title='Back to List' className='mr-1.5' side='left' delay={0} disabled={disabled}>
      <IconButton
        className={cn(
          '-ml-2 rounded-full enabled:hover:bg-grey-lighter disabled:hover:bg-white',
          disabled! && 'bg-white text-grey-darker'
        )}
        variant={disabled ? 'disabled' : 'ghost'}
        onClick={onClick}
        disabled={disabled}
      >
        <ArrowLeft />
      </IconButton>
    </Tooltip>
  )
}

function Loading() {
  const skeletonCount = 12
  return (
    <div>
      {_.times(skeletonCount, (i) => (
        <Skeleton key={i} className='-my-2 h-12 w-[200px]' />
      ))}
    </div>
  )
}

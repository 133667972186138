import { ICellRendererParams } from 'ag-grid-community'

import { getEndOfPeriod } from '@utils/date-utils'

import { format, parseISO } from 'date-fns'
import { ExpandedDateFilterInfo, Frequency } from 'types/filter'
import { isCellValueVisible } from 'utils/ag_grid/is-cell-value-visible'
import { isBlank } from 'utils/lodash'

import { isGeneralLedgerAccountNode, isTotalNode } from '../node-utils'
import { buildGlTransactionRedirectUrl } from '../render-utils'

export const FinancialDataCellRenderer = (params: ICellRendererParams) => {
  const formattedValue = params.valueFormatted
  const { firstRowWithNumbersId, enableDollarSignOnFirstRow } = params.context

  // Use the shared visibility function
  if (!isCellValueVisible(params.node)) {
    return <span style={{ visibility: 'hidden' }}>{formattedValue}</span>
  }

  if (enableDollarSignOnFirstRow && params.node.id === firstRowWithNumbersId) {
    return (
      <span onDoubleClick={() => navigateToGlTransactionsView(params)}>
        <span style={{ float: 'left' }}>$</span>
        {formattedValue}
      </span>
    )
  } else {
    return <span onDoubleClick={() => navigateToGlTransactionsView(params)}>{formattedValue}</span>
  }
}

const navigateToGlTransactionsView = (params: ICellRendererParams) => {
  let glAccountIds

  if (!isGeneralLedgerAccountNode(params.data)) {
    const isTotalNodeclicked = isTotalNode(params.node)
    const leafNodes = isTotalNodeclicked
      ? params.node?.parent?.allLeafChildren
      : params.node.allLeafChildren

    const glAccountLeafNodes = leafNodes?.filter((node) => isGeneralLedgerAccountNode(node.data))
    glAccountIds = glAccountLeafNodes?.map((node) => node.data.source_system_account_id)
  } else {
    glAccountIds = [params.data.source_system_account_id]
  }

  if (isBlank(glAccountIds)) return

  const dateFilter: ExpandedDateFilterInfo = params.context?.dateFilter

  const startDateString = params.colDef?.field?.substring(5) // double clicked cell header date with removed 'date.' prefix

  if (!startDateString) return

  const startDate = parseISO(startDateString)
  const endDate = getEndOfPeriod(
    startDate,
    dateFilter?.frequency || Frequency.Monthly,
    dateFilter?.originalInfo?.fiscalYearStart
  )
  const endDateString = format(endDate, 'yyyy-MM-dd')

  const redirectUrl = buildGlTransactionRedirectUrl(startDateString, endDateString, glAccountIds!)

  window.open(redirectUrl, '_blank')
}

import { barSeriesAttributes } from 'components/chart/modules/bar-series'
import { columnSeriesAttributes } from 'components/chart/modules/column-series'
import { lineSeriesAttributes } from 'components/chart/modules/line-series'
import { lineSeriesVerticalAttributes } from 'components/chart/modules/line-series-vertical'
import { waterfallSeriesAttributes } from 'components/chart/modules/waterfall-series'
import {
  IModuleDefinition,
  IModuleDefinitions,
  ITooltipPropsData
} from 'components/chart/types/module-types'
import { IDataType } from 'pages/component-management/types/component-types'

import {
  AxisPosition,
  IAxis,
  IChartActions,
  IChartSettings,
  LegendType,
  SeriesType,
  StackingType,
  TooltipTitleType,
  isStackingTypeLayered,
  isStackingTypeNone,
  isStackingTypeStacked
} from '../../types/chart-builder-types'
import { IResultRow, PREVIOUS_PERIOD_SUFFIX } from '../../types/shared-types'
import { XAxisModule, YAxisModule } from './axis-modules'
import { getValueFormatString } from './utils'

export class SeriesModule {
  constructor(
    public series: IAxis,
    public xAxis: XAxisModule,
    public flipAxis: boolean,
    public dateLabels: {
      ppStartLabel: string
      ppEndLabel: string
      cpStartLabel: string
      cpEndLabel: string
      ppLabel: string
      cpLabel: string
      fullPeriodLabel: string
    },
    public colorSet: string[],
    yAxes: YAxisModule[],
    breakdownLabels: string[],
    public settings: IChartSettings,
    public showDataLabelsControls: boolean,
    public openChartNodeContextMenu?: IChartActions['openChartNodeContextMenu']
  ) {
    this.yAxis = SeriesModule.findYAxisForSeries({ series: this.series, yAxes, settings })
    this.breakdownLabels = breakdownLabels
    this.postInit()
  }

  postInit() {
    this.moduleData = this._makeModuleData()
    this.tooltipRawData = this._makeTooltipRawData()
  }

  public yAxis: YAxisModule
  public moduleData: IModuleDefinitions | IModuleDefinition | null | undefined = null
  public tooltipRawData?: ITooltipPropsData | ITooltipPropsData[]
  public breakdownLabels: string[]

  static findYAxisForSeries({
    series,
    yAxes,
    settings
  }: {
    series: IAxis
    yAxes: YAxisModule[]
    settings: IChartSettings
  }): YAxisModule {
    const axisPosition = YAxisModule.resolveAxisPosition(series, settings)!
    let seriesId: string

    if (_.includes(AxisPosition, axisPosition)) {
      seriesId = series.id
    } else {
      seriesId = axisPosition!
    }

    const yAxis = yAxes.find((yAxis) => yAxis.originalId === seriesId)

    if (!yAxis) {
      throw new Error(`No axis found for series ${series.label}`)
    }

    return yAxis
  }

  static countTotalSeries(axis: IAxis, breakdowns?: string[]) {
    if (axis.breakdown && axis.breakdown !== '-') {
      return breakdowns?.length || 1
    }
    return 1
  }

  _makeModuleData(): IModuleDefinitions | IModuleDefinition | null | undefined {
    throw new Error('Subclasses must implement _makeModuleData')
  }

  _makeTooltipRawData(): ITooltipPropsData | ITooltipPropsData[] {
    if (this.breakdown) {
      return this.breakdownLabels.map((label, index) => {
        return {
          title: label,
          valueKey: label,
          valueFormat: this.valueFormatting.long,
          color: this.colorSet[index]
        }
      })
    } else {
      return _.assign(
        {
          valueKey: this.labelKey,
          valueFormat: this.valueFormatting.long
        },
        this.tooltipTitleProps,
        this instanceof WaterfallSeriesModule ? {} : { color: this.colorSet[0] }
      )
    }
  }

  references() {
    return {
      [this.yAxis.moduleName]: this.yAxis.axisId,
      [this.xAxis.moduleName]: this.xAxis.axisId
    }
  }

  handleNodeContextMenuOpen = (
    data: IResultRow,
    position: { clientX: number; clientY: number }
  ) => {
    this.openChartNodeContextMenu?.(this.xLabelKey, data, position)
  }

  static id(series: IAxis, breakdownLabel?: string) {
    if (breakdownLabel) {
      return `series-${series.id}-${breakdownLabel.split(' ').join('-')}`
    }
    return `series-${series.id}`
  }

  id(breakdownLabel?: string) {
    return SeriesModule.id(this.series, breakdownLabel)
  }

  get xLabelKey() {
    return this.xAxis.label
  }

  get isPreviousPeriod() {
    return this.series.previous_period
  }

  get legendType() {
    return this.series.legendType
  }

  get actualXLabelKey() {
    // For dates: when previous period data, we need to bind the chart to current period x-axis
    // but show previous period label in tooltip
    if (this.isPreviousPeriod && this.xAxis.axisDataType === IDataType.DATE_STRING) {
      return `${this.xLabelKey}${PREVIOUS_PERIOD_SUFFIX}`
    }
    return this.xLabelKey
  }

  get labelKey() {
    return this.series.label
  }

  get name() {
    if (this.isPreviousPeriod) {
      return this.ppName
    } else {
      return this.cpName
    }
  }

  get cpName() {
    switch (this.legendType) {
      case LegendType.FullPeriod:
        return `${this.dateLabels.cpStartLabel} - ${this.dateLabels.cpEndLabel}`
      case LegendType.FuturePeriod:
        return `Today - ${this.dateLabels.cpEndLabel}`
      case LegendType.PastPeriod:
        return `${this.dateLabels.cpStartLabel} - Today`
      case LegendType.Custom:
        return this.series.legendNameCustom || ''
      case LegendType.FromSeries:
      default:
        return this.series.label
    }
  }

  get ppName() {
    switch (this.legendType) {
      case LegendType.FullPeriod:
        return `${this.dateLabels.ppStartLabel} - ${this.dateLabels.ppEndLabel}`
      case LegendType.FuturePeriod:
        return `Today - ${this.dateLabels.ppEndLabel}`
      case LegendType.PastPeriod:
        return `${this.dateLabels.ppStartLabel} - Today`
      case LegendType.Custom:
        return this.series.legendNameCustom || ''
      case LegendType.FromSeries:
      default:
        return this.series.label
    }
  }

  get tooltipTitleProps() {
    const configSource = this.series.tooltip_override ? this.series : this.settings

    switch (configSource.tooltip_title_type) {
      case TooltipTitleType.FromBaseAxis:
        return {
          titleKey: this.actualXLabelKey,
          isDateTitle: this.xAxis.axisDataType === IDataType.DATE_STRING
        }
      case TooltipTitleType.Custom:
        return { title: configSource.tooltip_title_custom }
      case TooltipTitleType.FromSeries:
      default:
        return { title: this.name }
    }
  }

  get breakdown() {
    if (this.series.breakdown && this.series.breakdown !== '-') {
      return this.series.breakdown
    }
    return null
  }

  get valueFormatting() {
    return getValueFormatString({
      type: this.series.value_formatting_type,
      customShort: this.series.custom_value_short,
      customLong: this.series.custom_value_long
    })
  }

  get showDataLabels() {
    const configSource = this.series.data_labels_override ? this.series : this.settings

    if (configSource.data_labels_hook) {
      return this.showDataLabelsControls
    }

    return configSource.show_data_labels
  }

  get stacking() {
    return _.get(this.settings, 'seriesStacking', StackingType.NONE)
  }

  get stacked() {
    return false
  }

  get layered() {
    return false
  }

  get clustured() {
    return false
  }

  get breakdownStacking() {
    return _.get(this.series, 'stacking', StackingType.STACKED)
  }

  get stackedBreakdown() {
    return false
  }

  get layeredBreakdown() {
    return false
  }

  get clusturedBreakdown() {
    return false
  }
}

class LineAreaSeriesModule extends SeriesModule {
  _makeModuleData() {
    if (this.breakdown) {
      return this.breakdownLabels.map((label: string, index) => {
        return this.singleData({
          id: this.id(label),
          labelKey: label,
          name: label,
          stacked: this.stackedBreakdown,
          index
        })
      })
    } else {
      return this.singleData()
    }
  }

  singleData(props?: {
    id?: string
    labelKey?: string
    name?: string
    stacked?: boolean
    index?: number
  }) {
    const id = _.get(props, 'id', this.id())
    const labelKey = _.get(props, 'labelKey', this.labelKey)
    const name = _.get(props, 'name', this.name)
    const stacked = _.get(props, 'stacked', false)
    const index = _.get(props, 'index', 0)

    if (this.flipAxis) {
      return lineSeriesVerticalAttributes({
        id,
        options: {
          xAxisKey: labelKey,
          yAxisKey: this.xLabelKey,
          name,
          color: this.colorSet[index],
          numberFormat: this.valueFormatting.short,
          showDataLabels: this.showDataLabels
        },
        references: this.references()
      })
    } else {
      return lineSeriesAttributes({
        id,
        options: {
          xAxisKey: this.xLabelKey,
          yAxisKey: labelKey,
          name,
          stacked,
          area: this.series.seriesType === SeriesType.Area,
          color: this.colorSet[index],
          numberFormat: this.valueFormatting.short,
          showDataLabels: this.showDataLabels
        },
        references: this.references()
      })
    }
  }

  get stacked() {
    return isStackingTypeStacked(this.stacking)
  }

  get stackedBreakdown() {
    return isStackingTypeStacked(this.breakdownStacking)
  }
}

class BarSeriesModule extends SeriesModule {
  _makeModuleData() {
    if (this.breakdown) {
      return this.breakdownLabels.map((label: string, index) => {
        return this.singleData({
          id: this.id(label),
          labelKey: label,
          name: label,
          stacked: this.stackedBreakdown,
          layered: this.layeredBreakdown,
          index
        })
      })
    } else {
      return this.singleData()
    }
  }

  singleData(props?: {
    id?: string
    labelKey?: string
    name?: string
    stacked?: boolean
    layered?: boolean
    index?: number
  }) {
    const id = _.get(props, 'id', this.id())
    const labelKey = _.get(props, 'labelKey', this.labelKey)
    const name = _.get(props, 'name', this.name)
    const stacked = _.get(props, 'stacked', this.stacked)
    const layered = _.get(props, 'layered', this.layered)
    const index = _.get(props, 'index', 0)

    if (this.flipAxis) {
      return barSeriesAttributes({
        id,
        options: {
          xAxisKey: labelKey,
          yAxisKey: this.xLabelKey,
          name,
          stacked,
          layered,
          color: this.colorSet[index],
          numberFormat: this.valueFormatting.short,
          showDataLabels: this.showDataLabels,
          handleNodeContextMenuOpen: this.handleNodeContextMenuOpen
        },
        references: this.references()
      })
    } else {
      return columnSeriesAttributes({
        id,
        options: {
          xAxisKey: this.xLabelKey,
          yAxisKey: labelKey,
          name,
          stacked,
          layered,
          color: this.colorSet[index],
          numberFormat: this.valueFormatting.short,
          showDataLabels: this.showDataLabels
        },
        references: this.references()
      })
    }
  }

  get stacked() {
    return isStackingTypeStacked(this.stacking)
  }

  get layered() {
    return isStackingTypeLayered(this.stacking)
  }

  get clustured() {
    return isStackingTypeNone(this.stacking)
  }

  get stackedBreakdown() {
    return isStackingTypeStacked(this.breakdownStacking)
  }

  get layeredBreakdown() {
    return isStackingTypeLayered(this.breakdownStacking)
  }

  get clusturedBreakdown() {
    return isStackingTypeNone(this.breakdownStacking)
  }
}

class WaterfallSeriesModule extends SeriesModule {
  _makeModuleData() {
    if (this.flipAxis) {
      throw new Error('Waterfall series not supported for flipped axis')
    }

    if (this.breakdown) {
      return this.breakdownLabels.map((label: string, index) => {
        return this.singleData(
          this.id(label),
          label,
          label,
          true,
          index !== this.breakdownLabels.length - 1,
          this.labelKey
        )
      })
    } else {
      return this.singleData()
    }
  }

  singleData(
    id: string = this.id(),
    labelKey = this.labelKey,
    name = this.name,
    stacked = false,
    hideTotal = false,
    totalLabelKey = this.labelKey
  ) {
    return waterfallSeriesAttributes({
      id,
      options: {
        xAxisKey: this.xLabelKey,
        yAxisKey: labelKey || '',
        name: name || '',
        stacked,
        hideTotal,
        totalLabelKey,
        numberFormat: this.valueFormatting.short
      },
      references: this.references()
    })
  }
}

export default class SeriesFactory {
  static create({
    series,
    xAxis,
    yAxes,
    flipAxis,
    dateLabels,
    colorSet,
    settings,
    breakdownLabels = [],
    showDataLabelsControls,
    openChartNodeContextMenu
  }: {
    series: IAxis
    xAxis: XAxisModule
    yAxes: YAxisModule[]
    flipAxis: boolean
    dateLabels: {
      ppStartLabel: string
      ppEndLabel: string
      cpStartLabel: string
      cpEndLabel: string
      ppLabel: string
      cpLabel: string
      fullPeriodLabel: string
    }
    colorSet: string[]
    settings: IChartSettings
    breakdownLabels?: string[]
    showDataLabelsControls: boolean
    openChartNodeContextMenu?: IChartActions['openChartNodeContextMenu']
  }) {
    let SeriesModuleClass: typeof SeriesModule

    switch (series.seriesType) {
      case SeriesType.Line:
      case SeriesType.Area:
        SeriesModuleClass = LineAreaSeriesModule
        break
      case SeriesType.Bar:
        SeriesModuleClass = BarSeriesModule
        break
      case SeriesType.Waterfall:
        SeriesModuleClass = WaterfallSeriesModule
        break
      default:
        return null
    }

    return new SeriesModuleClass(
      series,
      xAxis,
      flipAxis,
      dateLabels,
      colorSet,
      yAxes,
      breakdownLabels,
      settings,
      showDataLabelsControls,
      openChartNodeContextMenu
    )
  }
}

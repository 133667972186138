import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Switch } from '@core/switch';

interface SwitchCellRendererProps extends ICellRendererParams {
  onSwitchChange?: (params: ICellRendererParams, recentValue: boolean) => void;
}

const SwitchCellRenderer: React.FC<SwitchCellRendererProps> = (params) => {
  const handleSwitchChange = (checked: boolean) => {
    params.node.setDataValue(params.colDef?.field!, checked);
    params.api.stopEditing();

    if (params.onSwitchChange) {
      params.onSwitchChange(params, checked);
    }
  };

  return (
    <div className="flex size-full items-center justify-end">
      <Switch
        checked={params.value}
        onCheckedChange={handleSwitchChange}
      />
    </div>
  );
};

export default SwitchCellRenderer;

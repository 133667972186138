import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Checkbox, CircularProgress, FormControlLabel, Grid, Typography } from '@mui/material'

import { capitalizeFirstLetter } from '@utils/string-utils'

import { Badge } from '@components/core/badge'
import Button from '@components/core/button'
import { Cancel, Icon, Link, Plus } from '@components/icons'

import { PageType } from 'types/page'

import { useActivatePage } from '../../queries/activate-page'
import { useConvertToInternalPage } from '../../queries/convert-to-internal-page'
import { useDeactivatePage } from '../../queries/deactive-page'
import useFetchPage from '../../queries/fetch-page'
import { useReleaseInternalPage } from '../../queries/release-internal-page'
import ExpandCollapse from '../shared/expand-collapse'

export const DetailsPanel = ({ selectedNode }: { selectedNode?: any }) => {
  const navigate = useNavigate()

  const { data: page, isFetching } = useFetchPage(selectedNode?.id)

  const [filterSettings, setFilterSettings] = useState({})

  const handleUpdateFilters = (newFilters: any) => setFilterSettings(newFilters)

  const { mutate: activatePage } = useActivatePage()
  const { mutate: deactivatePage } = useDeactivatePage()

  const { mutate: releaseInternalPage } = useReleaseInternalPage()
  const { mutate: convertToInternalPage } = useConvertToInternalPage()

  useEffect(() => {
    if (!isFetching) {
      setFilterSettings(page.settings?.search_filters || {})
    }
  }, [isFetching, page])

  const onPageEditorOpen = () => {
    navigate(`/pages/${page.id}/edit`)
  }

  if (isFetching) {
    return <CircularProgress />
  }

  const isActive = page.status === 'published' && page.enabled
  const isInternalPage = page.layout_type === PageType.INTERNAL
  const isDynamicPage = page.layout_type === PageType.PAGE

  return (
    <Box minHeight='700px' width={{ xs: '100%', sm: '100%', md: '90%' }}>
      <Box>
        {!isInternalPage && (
          <Button size='small' onClick={onPageEditorOpen}>
            <Icon icon={<Link />} /> Open Page Editor
          </Button>
        )}

        <PageInfo
          title={selectedNode?.title}
          pageRoute={page.page_route}
          ownerFullName={page?.owner?.name}
          updatedAt={page?.updated_at}
          isActive={isActive}
          layout_type={page.layout_type}
        />

        <div className='my-4 flex gap-2'>
          {!isInternalPage && (
            <Button onClick={() => convertToInternalPage({ pageId: page.id })}>
              Make Internal Page
            </Button>
          )}

          {isInternalPage && (
            <Button onClick={() => releaseInternalPage({ pageId: page.id })}>
              Release Internal Page
            </Button>
          )}

          {isActive ? (
            <Button
              className='bg-error pl-1 hover:bg-error-darker'
              onClick={() => deactivatePage({ pageId: page.id })}
            >
              <Icon icon={<Cancel />} />
              Deactivate Page
            </Button>
          ) : (
            <Button
              className='bg-success pl-1 hover:bg-success-darker'
              onClick={() => activatePage({ pageId: page.id })}
            >
              <Icon icon={<Plus />} />
              Activate Page
            </Button>
          )}
        </div>

        {isDynamicPage && (
          <FilterSettings filters={filterSettings} onUpdateFilters={handleUpdateFilters} />
        )}
      </Box>
    </Box>
  )
}

const FilterSettings = ({
  filters,
  onUpdateFilters
}: {
  filters: any
  onUpdateFilters: (newFilters: any) => void
}) => {
  const [newFilterSettings, setNewFilterSettings] = useState(filters)

  const handleToggle = (filterKey: string) => {
    setNewFilterSettings((prevFilters: any) => ({
      ...prevFilters,
      [filterKey]: !prevFilters[filterKey]
    }))
  }

  const handleUpdateFilters = () => {
    onUpdateFilters(newFilterSettings)
  }

  return (
    <ExpandCollapse header={'Page Settings'}>
      <Typography variant='h6'>Filter Settings</Typography>
      {Object.keys(filters).map((filterKey) => (
        <FormControlLabel
          key={filterKey}
          control={
            <Checkbox
              checked={newFilterSettings[filterKey]}
              onChange={() => handleToggle(filterKey)}
              color='primary'
            />
          }
          label={filterKey}
        />
      ))}
      <button onClick={handleUpdateFilters}>Apply Filters</button>
    </ExpandCollapse>
  )
}

const PageInfo = ({
  title,
  pageRoute,
  ownerFullName,
  layout_type,
  updatedAt,
  isActive
}: {
  title: string
  pageRoute: string
  ownerFullName: string
  layout_type: string
  updatedAt: Date
  isActive: boolean
}) => {
  const pageTypeLabel = useMemo(() => {
    if (!layout_type) return ''

    if (layout_type === PageType.PAGE) return 'Dynamic'

    return capitalizeFirstLetter(layout_type.split('_').join(' '))
  }, [layout_type])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography variant='h4'>{title}</Typography>
          <Typography variant='body2' color='textSecondary'>
            {pageRoute}
          </Typography>
          <Typography variant='body2' color='textSecondary'>
            Last edited by: <span style={{ fontWeight: 'bold' }}>{ownerFullName}</span> on{' '}
            <span style={{ fontWeight: 'bold' }}>
              {/* eslint-disable-next-line no-date-parsing/no-date-parsing */}
              {new Date(updatedAt).toLocaleString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                timeZoneName: 'short'
              })}
            </span>
          </Typography>
          <div className='flex gap-2 py-2'>
            <Badge variant='outline'>{pageTypeLabel}</Badge>
            <Badge variant='outline'>{isActive ? 'Active' : 'Inactive'}</Badge>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

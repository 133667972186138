import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SelectChangeEvent,
  Stack,
  Typography
} from '@mui/material'

import { enumToOptions } from '@utils/obj-utils'

import { IconButton } from '@components/core/icon-button'
import { Cancel, DragIndicator, Icon } from '@components/icons'

import { useDispatch, useSelector } from '@store/index'
import {
  addDimension,
  addMeasure,
  changeConfig,
  removeDimension,
  removeMeasure,
  replaceDimensions,
  replaceMeasures,
  selectTreemapConfig
} from '@store/slices/component/treemap-config'

import DropdownList from 'components/dropdown-list'
import MuiSelect from 'components/form/mui-select'
import { SortableList } from 'components/sortable-list'

import GenreDropdown from '../common/genre-dropdown'
import { useQueryResultContext } from '../contexts/query-result-context'
import { AggregateType } from '../types/component-types'

function SortableItems({
  items,
  onChange,
  onRemove
}: {
  items: { id: string; label: string }[]
  onChange: (values: { id: string; label: string }[]) => void
  onRemove: (id: string) => void
}) {
  return (
    <SortableList
      items={items}
      setItems={onChange}
      renderItem={({ draggableProps, setNodeRef, style, item, id }) => (
        <ListItem
          ref={setNodeRef}
          divider={true}
          secondaryAction={
            <IconButton onClick={() => onRemove(id as string)}>
              <Icon icon={<Cancel />} />
            </IconButton>
          }
          disablePadding
          sx={{ ...style }}
        >
          <ListItemButton>
            <IconButton className='px-0' {...draggableProps}>
              <Icon icon={<DragIndicator />} />
            </IconButton>
            <ListItemText sx={{ pl: 1 }} id={id as string} primary={item.label} />
          </ListItemButton>
        </ListItem>
      )}
      WrapperComponent={({ children }) => <List sx={{ width: '100%' }}>{children}</List>}
    />
  )
}

export default function TreemapConfig() {
  const { dimensions, measures, dates } = useQueryResultContext()
  const dispatch = useDispatch()
  const data = useSelector(selectTreemapConfig)

  return (
    <Box mt={2}>
      <Typography variant='h5'>Component Type And Data</Typography>
      <Typography variant='body1'>Arrange values to modify the layer order</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GenreDropdown />
        </Grid>
        <Grid item xs={12}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='h5'>Dimension</Typography>
            <Stack direction='row' spacing={1}>
              <DropdownList
                label='Add'
                options={[
                  ...dates.map((data) => ({ label: data.name, id: data.name })),
                  ...dimensions.map((data) => ({ label: data.name, id: data.name }))
                ]}
                onChange={(dimension) => dispatch(addDimension(_.pick(dimension, ['id', 'label'])))}
              />
            </Stack>
          </Stack>

          <SortableItems
            items={data.dimensions}
            onChange={(dimensions: { id: string; label: string }[]) =>
              dispatch(replaceDimensions(dimensions))
            }
            onRemove={(id: string) => dispatch(removeDimension(id))}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='h5'>Measures</Typography>
            <Stack direction='row' spacing={1}>
              <DropdownList
                label='Add'
                options={measures.map((data) => ({ id: data.name, label: data.name }))}
                onChange={(measure) => dispatch(addMeasure(_.pick(measure, ['id', 'label'])))}
              />
            </Stack>
          </Stack>

          <SortableItems
            items={data.measures}
            onChange={(measures: { id: string; label: string }[]) =>
              dispatch(replaceMeasures(measures))
            }
            onRemove={(id: string) => dispatch(removeMeasure(id))}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5'>Aggregate</Typography>
        <Typography variant='details' gutterBottom>
          Aggregate data if there are repeated categories
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <MuiSelect
          options={enumToOptions(AggregateType)}
          value={data.aggregate || AggregateType.first}
          onChange={(e: SelectChangeEvent<string>) => {
            dispatch(changeConfig({ aggregate: e.target.value as AggregateType }))
          }}
          label='Aggregate'
        />
      </Grid>
    </Box>
  )
}

import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { Theme } from '@mui/material/styles'

export default function Select(theme: Theme) {
  return {
    MuiSelect: {
      defaultProps: {
        variant: 'filled',
        disableUnderline: true,
        IconComponent: (props: { className: string }) => {
          return <ExpandMoreRoundedIcon {...props} style={{ transition: 'transform 0.3s' }} />
        },
        MenuProps: {
          sx: {
            marginTop: '0px',
            '& > .MuiPaper-root': {
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0
            }
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }
      },
      styleOverrides: {
        root: {
          fontWeight: 700,
          borderRadius: '0.25rem',
          '& > svg': {
            color: '#FFF'
          },
          '&:hover:not(.Mui-focused)': {
            backgroundColor: theme.palette.primary.darker,
            '& .MuiSelect-filled': {
              backgroundColor: theme.palette.primary.darker,
              borderColor: theme.palette.primary.darker
            }
          },
          '&.Mui-focused': {
            backgroundColor: 'transparent'
          },
          '&.Mui-focused .MuiSelect-filled': {
            borderColor: theme.palette.primary.darker,
            boxShadow: '0px 0px 0px 4px #FFF inset'
          }
        },
        filled: {
          transition:
            'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, border 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
          borderRadius: '0.25rem',
          color: '#FFF',
          padding: '0.3rem 0.5rem',
          border: '1px solid ' + theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main, // TODO: add support for multiple color variants,
          '&[aria-expanded="true"]': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
          },
          '&:hover:not(:focus)': {
            borderColor: theme.palette.primary.darker,
            backgroundColor: theme.palette.primary.darker
          },
          '&:active': {
            borderColor: theme.palette.primary.darker,
            boxShadow: '0px 0px 0px 4px #FFF inset'
          },
          '&:focus': {
            borderColor: theme.palette.primary.darker,
            boxShadow: '0px 0px 0px 4px #FFF inset'
          }
        },
        sizeActionBar: {
          height: '1.75rem'
        }
      }
    }
  }
}

import MainCard from '../../components/MainCard'
import { LayoutViewProps } from '../layout-view/types'
import List from './list'

export default function Home({ pageSettings }: LayoutViewProps) {
  return (
    <MainCard pageSettings={pageSettings}>
      <List />
    </MainCard>
  )
}

import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { rehydrateStore } from '@store/actions/rehydrate-store'
import { WithID } from '@store/page-util'

import { RootState } from '..'

interface GlTransactionsDimensionFilters {
  [key: string]: string[]
}

const glDimensionsAdapter = createEntityAdapter<WithID<GlTransactionsDimensionFilters>>()

const defaultValues: GlTransactionsDimensionFilters = {}

const initialState = glDimensionsAdapter.getInitialState()

const findOrCreateEntity = (state: typeof initialState, id: string) => {
  let entity = state.entities[id]
  if (!entity) {
    glDimensionsAdapter.addOne(state, _.assign({ id }, _.cloneDeep(defaultValues)))
    entity = state.entities[id]
  }
  return entity
}

const glTransactionsDimensionFilters = createSlice({
  name: '@GL_TRANSACTIONS_DIMENSION_FILTERS',
  initialState,
  reducers: {
    setDimensionFilter: (state, action: PayloadAction<{ id: string; values: any }>) => {
      const { id, values } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity[id] = values
    },
    removeDimensionFilter: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload
      glDimensionsAdapter.removeOne(state, id)
    }
  },

  extraReducers: (builder) => {
    builder.addCase(rehydrateStore, (_state, action) => {
      return { ...initialState, ...action.payload.glTransactionsDimensionFilters }
    })
  }
})

export const { removeDimensionFilter, setDimensionFilter } = glTransactionsDimensionFilters.actions

export const glTransactionsDimensionFiltersReducer = glTransactionsDimensionFilters.reducer

// select dimension filter and remove it from the state
export const selectDimensionFilter = (id: string) => (state: RootState) => {
  return state.glTransactionsDimensionFilters.entities[id]
}

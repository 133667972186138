import { Tabs, TabsContent, TabsList, TabsTrigger } from '@core/tab'

import { Separator } from '@components/core/separator'

import { VisualizeLayout } from '../common/visualize-layout'
import { useSyncColsOnInit } from '../table-builder/hooks/use-sync-cols-on-init'
import { TableDataSettings } from './table-data-settings'
import { TablePreview } from './table-preview'
import { TableStyling } from './table-styling'

enum TableDataTabs {
  DATA_SETTINGS = 'data-settings',
  STYLING = 'styling'
}

export const TableVisualize = () => {
  useSyncColsOnInit()

  return (
    <VisualizeLayout
      Preview={<TablePreview />}
      FullSettingsView={
        <div className='flex size-full'>
          <div className='h-full w-2/5 overflow-scroll'>
            <TableDataSettings />
          </div>
          <Separator className='mx-4 h-full' orientation='vertical' />
          <div className='h-full w-3/5'>
            <TableStyling />
          </div>
        </div>
      }
      RightSettingsView={
        <div className='size-full'>
          <Tabs
            id='table-visualize-data-tabs'
            className='flex size-full flex-col'
            defaultValue={TableDataTabs.DATA_SETTINGS}
          >
            <TabsList
              className='grid w-full grid-cols-2 gap-2'
              defaultValue={TableDataTabs.DATA_SETTINGS}
            >
              <TabsTrigger value={TableDataTabs.DATA_SETTINGS}>Data</TabsTrigger>
              <TabsTrigger value={TableDataTabs.STYLING}>Styling</TabsTrigger>
            </TabsList>
            <div className='h-0 flex-1'>
              <TabsContent value={TableDataTabs.DATA_SETTINGS} className='h-full overflow-scroll'>
                <TableDataSettings />
              </TabsContent>
              <TabsContent value={TableDataTabs.STYLING} className='h-full'>
                <TableStyling />
              </TabsContent>
            </div>
          </Tabs>
        </div>
      }
    />
  )
}

'use client'

import React from 'react'
import { useSelector } from 'react-redux'

import * as ToastPrimitives from '@radix-ui/react-toast'

import { selectHeaderHeight } from 'store/slices/toast'
import { cn } from 'utils/style-utils'

const TOAST_REMOVE_DELAY = 1000000
const MAX_WIDTH_SMALL_SCREEN = 639

const toastCloseActionSharedClasses = [
  'inline-flex h-6 p-2 ring-0 shrink-0 cursor-pointer items-center justify-right rounded',
  'border border-solid border-grey-lighter hover:border-grey-light focus:border-primary',
  'bg-white p-2 text-primary-darker'
]

const ToastProvider = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Provider>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Provider>
>(({ ...props }, ref) => (
  <ToastPrimitives.Provider swipeDirection='up' duration={TOAST_REMOVE_DELAY} {...props} />
))
ToastProvider.displayName = ToastPrimitives.Provider.displayName

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>((props, ref) => {
  const topSpacing = useSelector(selectHeaderHeight)

  const className = cn(
    `fixed flex max-h-screen w-full flex-col p-4 pt-0 mt-0 items-center md:max-w-[620px] md:left-1/2 md:-translate-x-1/2`,
    props.className
  )

  return (
    <ToastPrimitives.Viewport
      {...props}
      ref={ref}
      className={className}
      style={{ zIndex: 1201, top: `${topSpacing}px` }}
    />
  )
})

ToastViewport.displayName = ToastPrimitives.Viewport.displayName

function getToastClasses(variant: 'info' | 'success' | 'warning' | 'error') {
  const baseClasses = `
    data-[state=open]:animate-in data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-top-full 
    data-[state=closed]:slide-out-to-top-full data-[state=closed]:animate-out data-[state=closed]:fade-out-80 data-[swipe=end]:animate-out 
    data-[swipe=cancel]:translate-y-0 data-[swipe=end]:translate-y-[var(--radix-toast-swipe-end-y)] 
    data-[swipe=move]:translate-y-[var(--radix-toast-swipe-move-y)] data-[swipe=move]:transition-none 
    group pointer-events-auto 
    relative flex w-full p-3 px-8 shadow-md 
    items-center justify-between space-x-2 overflow-hidden 
    rounded-md rounded-t-none
    text-white 
    select-text
    `
  switch (variant) {
    case 'success':
      return cn(baseClasses, 'bg-green')
    case 'error':
      return cn(baseClasses, 'bg-red')
    case 'warning':
      return cn(baseClasses, 'bg-grey-dark')
    default:
      return cn(baseClasses, 'bg-blue')
  }
}

const handleSwipe = (event: any) => {
  if (!window.matchMedia(`(max-width: ${MAX_WIDTH_SMALL_SCREEN}px)`).matches) {
    event.preventDefault()
    event.stopPropagation() // Stops the event from propagating further if on small screens
  }
}

const Toast = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> & {
    variant?: 'info' | 'success' | 'warning' | 'error'
  }
>(({ className, variant = 'info', ...props }, ref) => {
  return (
    <ToastPrimitives.Root
      ref={ref}
      onSwipeStart={handleSwipe}
      onSwipeMove={handleSwipe}
      onSwipeEnd={handleSwipe}
      className={cn(getToastClasses(variant), className)}
      style={{ userSelect: 'text' }}
      {...props}
    />
  )
})
Toast.displayName = ToastPrimitives.Root.displayName

const ToastAction = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Action>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Action
    ref={ref}
    className={cn(toastCloseActionSharedClasses, className)}
    {...props}
  />
))
ToastAction.displayName = ToastPrimitives.Action.displayName

const ToastClose = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Close>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Close
    ref={ref}
    className={cn('ml-4', toastCloseActionSharedClasses, className)}
    {...props}
  >
    Close
  </ToastPrimitives.Close>
))
ToastClose.displayName = ToastPrimitives.Close.displayName

const ToastTitle = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Title>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Title ref={ref} className={cn('text-button font-bold', className)} {...props} />
))
ToastTitle.displayName = ToastPrimitives.Title.displayName

const ToastDescription = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Description>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Description
    ref={ref}
    className={cn('text-button font-semibold', className)}
    {...props}
  />
))
ToastDescription.displayName = ToastPrimitives.Description.displayName

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>

type ToastActionElement = React.ReactElement<typeof ToastAction>

export {
  type ToastProps,
  type ToastActionElement,
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastClose,
  ToastAction
}

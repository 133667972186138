interface DividerProps {
  layout_item: any
  widget: any
  item_index: number
}

const Index = ({ widget, item_index }: DividerProps) => {
  return <div className='divider-container'>Static - {item_index}</div>
}

export default Index

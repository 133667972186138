import { Select } from '@components/form/select'

import { useDispatch, useSelector } from '@store/index'
import {
  changeAxisPosition,
  selectFlipAxis,
  selectSettings,
  selectYAxes,
  updateYAxisData
} from '@store/slices/component/chart-config'

import { AxisPosition, IAxis } from '../types/chart-builder-types'

export const AxisAlignment = ({ item: data }: { item: IAxis }) => {
  const dispatch = useDispatch()
  const yAxes = useSelector(selectYAxes)
  const settings = useSelector(selectSettings)
  const stackAxes = _.get(settings, 'stackAxes', false)
  const flipAxis = useSelector(selectFlipAxis)

  return (
    <>
      {stackAxes ? (
        <Select
          value={_.get(data, 'stackedAxisPosition', AxisPosition.Normal)}
          onChange={(value) => {
            dispatch(updateYAxisData({ id: data.id, stackedAxisPosition: value }))
          }}
          options={[{ value: AxisPosition.Normal, label: flipAxis ? 'Bottom' : 'Left' }]}
        />
      ) : (
        <Select
          value={data.axisPosition || ''}
          onChange={(value) => {
            dispatch(changeAxisPosition({ id: data.id, axisPosition: value as AxisPosition }))
          }}
          options={[
            { value: AxisPosition.Normal, label: flipAxis ? 'Bottom' : 'Left' },
            { value: AxisPosition.Opposite, label: flipAxis ? 'Top' : 'Right' },
            ...yAxes
              .filter(
                (axis) =>
                  axis.id !== data.id &&
                  (axis.axisPosition === AxisPosition.Normal ||
                    axis.axisPosition === AxisPosition.Opposite)
              )
              .map((axis) => ({ value: axis.id, label: `Use ${axis.label}` }))
          ]}
        />
      )}
    </>
  )
}

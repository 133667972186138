import React, { ReactNode } from 'react'

import { cn } from '@utils/style-utils'

import { Button, ButtonProps } from './button'

interface ButtonIconProps extends ButtonProps {
  children: ReactNode
  className?: string
}

export const IconButton = React.forwardRef<HTMLButtonElement, ButtonIconProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        variant='outline'
        {...props}
        className={cn('p-1 hover:bg-grey-lighter', className)}
      >
        {children}
      </Button>
    )
  }
)

IconButton.displayName = 'IconButton'

export function getTooltipContainerId(selector: string): string {
  return `tooltips-container-${selector}`
}

export function getLegendId(selector: string): string {
  return `${selector}-legend`
}

export function getLegendPlaceholderId(selector: string): string {
  return `${selector}-legend-placeholder`
}

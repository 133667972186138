// import ArrowDropdownIcon from 'components/icons/arrow-dropdown';
import { useRef } from 'react'

import Button from '@core/button'
import { Popover, PopoverContent, PopoverTrigger } from '@core/popover'

import { ChargerForm, closePopover } from '@components/form/charger-form'
import { FieldTypes, FormLayoutTypes } from '@components/form/types'
import { ArrowDropdown, Icon } from '@components/icons'

import { useDispatch, useSelector } from '@store/index'
import { selectLimit, setLimit } from '@store/slices/component/query-config'

import * as Yup from 'yup'

export function LimitButton({ onSubmit }: { onSubmit: () => void }) {
  const dispatch = useDispatch()
  const limit = useSelector(selectLimit)
  const popoverTriggerRef = useRef<HTMLButtonElement>(null)

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant='outline' ref={popoverTriggerRef} className='pr-1'>
          Limit {limit} <Icon icon={<ArrowDropdown />} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className='p-4'>
        <ChargerForm
          layout={FormLayoutTypes.FULL_WIDTH}
          fieldsConfig={[
            {
              label: 'Total Rows',
              name: 'totalRows',
              type: FieldTypes.NUMBER,
              required: true,
              placeholder: 'Enter Total Rows',
              validationSchema: Yup.number()
                .required('Required')
                .max(10_000, 'Cannot exceed 10,000 rows')
                .min(1, 'Must be at least 1 row'),
              defaultValue: `${limit}`
            }
          ]}
          onSubmit={(values) => {
            dispatch(setLimit(_.toNumber(values.totalRows)))
            closePopover(popoverTriggerRef.current)
            onSubmit()
          }}
        />
      </PopoverContent>
    </Popover>
  )
}

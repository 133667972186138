/* eslint-disable @typescript-eslint/no-unused-vars */
import { ColumnState } from 'ag-grid-community'

export const sanitizeColumnState = (columnState: ColumnState[]): ColumnState[] => {
  return columnState.map((col) => {
    const {
      // Function properties from ColDef that do not belong to ColumnState
      aggFunc,
      cellRenderer,
      cellRendererFramework,
      cellEditor,
      cellEditorFramework,
      filter,
      filterFramework,
      // Keep all the valid properties for ColumnState
      ...rest
    } = col as any // Explicitly type it as any to destructure these properties
    return rest as ColumnState
  })
}

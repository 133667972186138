import { useMutation, useQueryClient } from '@tanstack/react-query'

import { snakeCaseKeys } from '@utils/case-conversion'

import useAuth from 'hooks/useAuth'
import { queryErrorToast, querySuccessToast } from 'queries/query-toasts'
import axiosServices from 'utils/axios'

import { IConnector } from '../types'

export interface IUpdateConnectionBody {
  connectionId: number
  connectionName?: string
  connector?: IConnector
  sourceApplicationId?: number
  businessId?: number
  shouldInvalidate?: boolean
}

export interface IUpdateConnectionProps {
  onSuccess?: () => void
  businessId?: number
}

export const useUpdateConnectedSourceSystem = (props: IUpdateConnectionProps) => {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const businessId = props.businessId || user?.business_id!

  return useMutation({
    mutationFn: (body: IUpdateConnectionBody) => {
      const payload: any = { connected_source_system: {} }
      const currentBusinessId = body.businessId || businessId

      if (body.connectionName) {
        payload.connected_source_system.name = body.connectionName
      }

      if (body.connector) {
        payload.connected_source_system.connector = snakeCaseKeys(body.connector, [
          'connectorConfig'
        ])
      }

      if (body.sourceApplicationId !== undefined) {
        payload.connected_source_system.source_application_id = body.sourceApplicationId
      }

      return axiosServices.put(
        `/businesses/${currentBusinessId}/connected_source_systems/${body.connectionId}`,
        payload
      )
    },
    onSuccess: (_response, variables) => {
      const currentBusinessId = variables.businessId || businessId

      if (variables.shouldInvalidate) {
        queryClient.invalidateQueries({
          queryKey: ['fetch-connected-source-systems', currentBusinessId]
        })
        queryClient.invalidateQueries({
          queryKey: ['fetch-all-businesses-connected-source-systems']
        })
        queryClient.invalidateQueries({
          queryKey: ['fetch-connected-source-system', variables.connectionId]
        })
      }

      props.onSuccess?.()
      querySuccessToast('Connection updated successfully')
    },
    onError: (error: any) => {
      queryErrorToast(error.message)
    }
  })
}

import { DisplayNameSchema, RENAMING_DEFAULTS } from '@store/slices/schema-renamings'

export function getDisplayName(
  schemaRenamings: DisplayNameSchema,
  modelName: string,
  columnName?: string,
  includeDefaults: boolean = false
): string | undefined {
  const modelRenamings = schemaRenamings[modelName]

  if (columnName) {
    if (includeDefaults) {
      return (
        modelRenamings?.columns?.[columnName] ||
        RENAMING_DEFAULTS[modelName]?.columns?.[columnName] ||
        columnName
      )
    }
    return modelRenamings?.columns?.[columnName]
  }

  if (includeDefaults) {
    return modelRenamings?.newName || RENAMING_DEFAULTS[modelName]?.default || modelName
  }
  return modelRenamings?.newName
}

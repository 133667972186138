import { useMutation, useQueryClient } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import { queryErrorToast } from 'queries/query-toasts'
import axiosServices from 'utils/axios'

export const useDeleteNode = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      hierarchyId,
      nodeId,
      parentNodeId,
      setErrorMessage
    }: {
      hierarchyId: number
      nodeId: number
      parentNodeId?: number
      setErrorMessage?: (message: string) => void
    }) => {
      return axiosServices.delete(
        `/businesses/${user?.business_id}/general_ledger_account_hierarchy_nodes/${nodeId}`
      )
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: ['fetch-hierarchy-builder-nodes', user?.business_id, variables.hierarchyId]
      })
      queryClient.invalidateQueries({
        queryKey: ['fetch-gl-accounts-in-hierarchy', user?.business_id, variables.hierarchyId]
      })
      queryClient.invalidateQueries({
        queryKey: ['fetch-all-hierarchies-with-nodes', user?.business_id]
      })
      variables.parentNodeId &&
        queryClient.invalidateQueries({
          queryKey: ['fetch-gl-account-nodes', user?.business_id, variables.parentNodeId]
        })
      queryClient.invalidateQueries({
        queryKey: ['fetch-report-tree', user?.business_id, variables.hierarchyId]
      })
      queryClient.invalidateQueries({ queryKey: ['fetch-total-node'] })
    },
    onError: (error, variables) => {
      if (variables.setErrorMessage) {
        variables.setErrorMessage(error.message)
      }

      queryErrorToast(error.message)
    }
  })
}

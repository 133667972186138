import { createAction } from '@reduxjs/toolkit'

interface LoggerPayload {
  group: LogGroup
  message: string
  tags?: Record<string, any>
}

export const log = createAction<LoggerPayload>('@LOGGER/info')

export const logError = createAction<LoggerPayload>('@LOGGER/error')

export const logDebug = createAction<LoggerPayload>('@LOGGER/debug')

export enum LogGroup {
  AUTH = 'AUTH',
  DATE_FILTER = 'DATE_FILTER',
  AG_GRID_INTERNAL_CHANGE = 'AG_GRID_INTERNAL_CHANGE',
  COMPONENT_MANAGEMENT = 'COMPONENT_MANAGEMENT',
  SQL_CELL_RENDERER = 'SQL_CELL_RENDERER'
}

import { useQuery } from '@tanstack/react-query';
import { camelCaseKeys } from '@utils/case-conversion';
import axiosServices from 'utils/axios';

export interface IBusinessAlertConfig {
  businessId: number | undefined;
  id: number | undefined;
  alertId: number | undefined;
  categoryType: string;
  categoryValue: string;
  severity: string;
  enabled: boolean;
  emailNotificationsEnabled: boolean;
  threshold: number | undefined;
  retriggerAfterDays: number | undefined;
  alertType: string;
  userGroups: number[];
}

const fetchBusinessAlertConfigs = async (businessId: number, categoryType: string): Promise<IBusinessAlertConfig[]> => {
  const response = await axiosServices.get(`/businesses/${businessId}/alerts/alert_config_by_category?category_type=${categoryType}`);
  return camelCaseKeys(response.data);
};

export const useBusinessFetchAlertConfigs = ({ categoryType, businessId }: { categoryType?: string; businessId?: number }) => {
  return useQuery<IBusinessAlertConfig[]>({
    queryKey: ['fetch-business-alert-configs', businessId, categoryType],
    enabled: !!businessId && !!categoryType,
    queryFn: () => fetchBusinessAlertConfigs(businessId!, categoryType!)
  });
};

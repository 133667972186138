import { Theme } from '@mui/material/styles'

export default function FilledInput(theme: Theme) {
  return {
    MuiFilledInput: {
      styleOverrides: {
        colorPrimary: {
          background: theme.palette.primary.main,
          '&.Mui-focused': {
            background: theme.palette.primary.main
          },
          '&:hover': {
            background: theme.palette.primary.main
          }
        }
      }
    }
  }
}

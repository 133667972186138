import {
  BasicFieldConfig,
  ChargerFormFieldConfig,
  OneOfFieldConfig,
  isFieldTypeMultiSelect,
  isFieldTypeOneOf
} from '../types'

export const getInitialValues = (fieldsConfig: ChargerFormFieldConfig[]) => {
  return fieldsConfig.reduce((acc: Record<string, any>, field) => {
    if (isFieldTypeOneOf(field.type)) {
      const conditionalField = field as OneOfFieldConfig
      const value = conditionalField.defaultValue || ''
      const selectedSection = _.find(
        conditionalField.sections,
        (section) => section.value === value
      )

      // Note: Fields in all the sections should be added to initialValues
      // https://github.com/jaredpalmer/formik/issues/445
      const sectionInitialValuesMap = _.reduce(
        conditionalField.sections,
        (sectionAcc, section) => {
          sectionAcc[section.value] = getInitialValues(section.formFields)
          return sectionAcc
        },
        {} as Record<string, any>
      )
      const initialData = _.assign(
        _.reduce(
          sectionInitialValuesMap,
          (initialValues, sectionValues) => _.assign(initialValues, sectionValues),
          {}
        ),
        selectedSection && sectionInitialValuesMap[selectedSection.value]
      )

      acc[conditionalField.name] = _.assign({ value }, selectedSection && { data: initialData })
      return acc
    }

    const basicField = field as BasicFieldConfig
    acc[basicField.name] = basicField.defaultValue || ''

    if (isFieldTypeMultiSelect(basicField.type)) {
      acc[basicField.name] = basicField.multiSelectDefaultValue?.map((option) => option.value)
    }
    return acc
  }, {})
}

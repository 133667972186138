import { Box } from '@mui/material'
import { BoxProps } from '@mui/system'

import { cn } from '@utils/style-utils'

const AuthCard = ({ children, className, ...other }: BoxProps) => (
  <Box
    className={cn('px-10 py-9', className)}
    sx={{
      margin: { xs: 2.5, md: 3 },
      zIndex: 1,
      '& > *': {
        flexGrow: 1
      },
      display: 'flex',
      width: '39rem',
      padding: '2.25rem 2.5rem',
      flexDirection: 'column',
      gap: '2.5rem',
      borderRadius: '0.5rem',
      background: '#FFF',
      boxShadow: '-2px 4px 0px 0px rgba(0, 0, 0, 0.02)'
    }}
    {...other}
  >
    {children}
  </Box>
)

export default AuthCard

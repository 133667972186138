import MainCard from '@components/MainCard'

import { ChargerOpsConnectionDetail } from 'layout-components/general/data-connections/charger-ops-connection-detail'
import { DataConnectionMode } from 'layout-components/general/data-connections/types'
import { LayoutViewProps } from 'pages/layout-view/types'

export const DetailLayout = (props: LayoutViewProps) => {
  const { pageSettings } = props

  return (
    <MainCard pageSettings={pageSettings}>
      <ChargerOpsConnectionDetail mode={DataConnectionMode.CHARGER_OPS} />
    </MainCard>
  )
}

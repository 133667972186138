import { useMemo, useRef } from 'react'
import { useParams } from 'react-router'

import { ColDef, IServerSideGetRowsParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { useQueryClient } from '@tanstack/react-query'

import AgGrid, { AUTO_FIT_TIMEOUT_MS } from '@components/ag-grid'
import { JSONCellRenderer } from '@components/ag-grid/custom-cell-renderer/json-cell-renderer'
import { autoFitColumnsWithAPI } from '@components/ag-grid/utils'

import { UNDO_REDO_CELL_EDITING_LIMIT } from '@hooks/grid/useKeyboardDetection'

import {
  FetchDataIntegrityJobsResponse,
  fetchDataIntegrityJobs
} from './queries/fetch-data-integrity-jobs'
import { IConnectedSourceSystem } from './types'
import { dateColumnDef, numericCommaSeparatedColumnDef } from './utils/col-defs'

export function DataIntegrityJobsList({
  connectedSourceSystem
}: {
  connectedSourceSystem?: IConnectedSourceSystem
}) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const gridRef = useRef<AgGridReact>(null)
  const queryClient = useQueryClient()

  const gridContext = useMemo(() => {
    return {
      validationState: {}
    }
  }, [])

  const { id } = useParams<{ id: string }>()

  const columnDefs: ColDef[] = useMemo<ColDef[]>(
    () => [
      // { field: 'id', headerName: 'ID', initialWidth: 200 },
      {
        field: 'stream',
        headerName: 'Name',
        initialWidth: 100,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          searchType: 'matchAny',
          allowTyping: true,
          filterList: true,
          highlightMatch: true
        },
        filter: 'agTextColumnFilter'
      },
      {
        field: 'namespace',
        headerName: 'Namespace',
        initialWidth: 80,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          searchType: 'matchAny',
          allowTyping: true,
          filterList: true,
          highlightMatch: true
        },
        filter: 'agTextColumnFilter'
      },
      {
        field: 'diffCheckType',
        headerName: 'Diff Check Type',
        initialWidth: 120,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          searchType: 'matchAny',
          allowTyping: true,
          filterList: true,
          highlightMatch: true
        },
        filter: 'agTextColumnFilter'
      },
      {
        field: 'uuid',
        headerName: 'UUID',
        initialWidth: 200,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          searchType: 'matchAny',
          allowTyping: true,
          filterList: true,
          highlightMatch: true
        },
        filter: 'agTextColumnFilter',
        sortable: false
      },
      {
        field: 'mismatchFound',
        headerName: 'Mismatch Found',
        initialWidth: 120,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['True', 'False'],
          comparator: (a: boolean, b: boolean) => {
            return a === b ? 0 : a ? 1 : -1
          }
        },
        sortable: false,
        valueFormatter: (params) => (params.value ? 'True' : 'False')
      },
      // NOTE: Not removing these comments. Will be useful for debugging.
      {
        field: 'metadata',
        headerName: 'Metadata',
        cellRenderer: JSONCellRenderer,
        initialWidth: 100,
        hide: true
      },
      {
        field: 'metadata.sourceHasNullPk',
        headerName: 'Source Has Null Pk',
        initialWidth: 100,
        sortable: false,
        filter: false
      },
      {
        field: 'metadata.rowCountDiff',
        headerName: 'Row Count Diff',
        initialWidth: 100,
        sortable: false,
        filter: false
      },
      {
        field: 'metadata.sourcePkNullCount',
        headerName: 'Source Pk Null Count',
        initialWidth: 100,
        sortable: false,
        filter: false
      },
      numericCommaSeparatedColumnDef('metadata.sourcePkRowCount', 'Source Pk Row Count', {
        sortable: false,
        filter: false
      }),
      numericCommaSeparatedColumnDef('metadata.destPkRowCount', 'Dest Pk Row Count', {
        sortable: false,
        filter: false
      }),

      numericCommaSeparatedColumnDef('metadata.sourceRowCount', 'Source Row Count', {
        sortable: false,
        filter: false
      }),
      numericCommaSeparatedColumnDef('metadata.destRowCount', 'Dest Row Count', {
        sortable: false,
        filter: false
      }),

      numericCommaSeparatedColumnDef('metadata.added', 'Rows Added', {
        sortable: false,
        filter: false
      }),
      numericCommaSeparatedColumnDef('metadata.updated', 'Rows Updated', {
        sortable: false,
        filter: false
      }),
      numericCommaSeparatedColumnDef('metadata.removed', 'Rows Removed', {
        sortable: false,
        filter: false
      }),
      {
        field: 'metadata.sourceCursorValues',
        headerName: 'Source Cursor Values',
        initialWidth: 100,
        sortable: false,
        filter: false
      },
      {
        field: 'metadata.destCursorValues',
        headerName: 'Dest Cursor Values',
        initialWidth: 100,
        sortable: false,
        filter: false
      },
      {
        field: 'metadata.cursorFields',
        headerName: 'Cursor Fields',
        initialWidth: 100,
        sortable: false,
        filter: false
      },
      dateColumnDef('startedAt', 'Started At', { initialWidth: 150 }),
      dateColumnDef('endedAt', 'Ended At', { initialWidth: 150 })
    ],
    []
  )

  const dataSource = useMemo(() => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        const { startRow, endRow, filterModel, sortModel } = params.request
        const limit = endRow! - startRow!
        const page = startRow! / limit + 1

        const queryKey = [
          'data-integrity-jobs',
          connectedSourceSystem?.businessId,
          id,
          page,
          limit,
          JSON.stringify(filterModel),
          JSON.stringify(sortModel)
        ]

        try {
          const data = await queryClient.fetchQuery<FetchDataIntegrityJobsResponse>({
            queryKey,
            queryFn: async () => {
              return await fetchDataIntegrityJobs(connectedSourceSystem?.businessId, id, {
                page,
                limit,
                filterModel,
                sortModel
              })
            }
          })

          params.success({ rowData: data.records })

          setTimeout(() => {
            if (gridRef.current?.api) {
              autoFitColumnsWithAPI(params.api)
              params.api.refreshHeader()
            }
          }, AUTO_FIT_TIMEOUT_MS)
        } catch (err) {
          console.error('Error fetching data integrity jobs:', err)
          params.fail()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridRef, queryClient])

  return (
    <div className='h-[calc(100vh-13rem)] w-full' ref={wrapperRef}>
      <AgGrid
        ref={gridRef}
        className='zebra-stripe'
        rowModelType='serverSide'
        loading={false}
        serverSideDatasource={dataSource}
        columnDefs={columnDefs}
        defaultColDef={{
          minWidth: 50,
          getQuickFilterText: () => '',
          filter: true,
          floatingFilter: true,
          suppressHeaderContextMenu: true
        }}
        autoSizeStrategy={{ type: 'fitGridWidth' }}
        style={{ height: 'calc(100vh - 12rem)', width: '100%' }}
        rowSelection='multiple'
        suppressRowClickSelection={true}
        wrapperRef={wrapperRef}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={UNDO_REDO_CELL_EDITING_LIMIT}
        enableFillHandle={true}
        suppressServerSideFullWidthLoadingRow={true}
        enableRangeSelection={true}
        enterNavigatesVertically={true}
        enterNavigatesVerticallyAfterEdit={false}
        serverSideEnableClientSideSort={true}
        context={gridContext}
        loadingOverlayComponentParams={{
          showHeader: true,
          topOffset: '49px'
        }}
      />
    </div>
  )
}

import { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'

import { Divider } from '@mui/material'

import MainCard from '@components/MainCard'

import { initComponentManagement } from '@store/actions/component-management'
import { useDispatch } from '@store/index'

import { DrilldownProvider } from 'contexts/drilldown-context'
import { LayoutViewProps } from 'pages/layout-view/types'

import { QueryResultProvider } from './contexts/query-result-context'
import { QueryTransformedResultProvider } from './contexts/query-transformed-result-context'
import ControlPanels from './control-panels'
import CreateLoading from './create-loading'
import Heading from './heading'
import { useFetchComponent } from './queries/fetch-component'
import type { IComponentData } from './types'

function CreateFlowChild({
  componentData,
  pageSettings
}: {
  componentData: IComponentData
  pageSettings: LayoutViewProps['pageSettings']
}) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(initComponentManagement(componentData))
  }, [componentData, dispatch])

  return (
    <MainCard title={' '} pageSettings={pageSettings} secondary={<ControlPanels />}>
      <DrilldownProvider componentId={`component_${componentData.id}`}>
        <QueryResultProvider>
          <QueryTransformedResultProvider>
            <div className='flex flex-col gap-2'>
              <Heading />
              <Divider />
              <Outlet />
            </div>
          </QueryTransformedResultProvider>
        </QueryResultProvider>
      </DrilldownProvider>
    </MainCard>
  )
}

export default function CreateFlow({ pageSettings }: LayoutViewProps) {
  const { id } = useParams<{ id: string }>()
  const { isPending, isError, data } = useFetchComponent(+id!)

  if (isPending) return <CreateLoading />

  if (isError) return <div>Something went wrong</div>

  return <CreateFlowChild componentData={data!} pageSettings={pageSettings} />
}

import { Theme } from '@mui/material/styles'

export default function TextField(theme: Theme) {
  return {
    MuiTextField: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: {
          '&> label:not(.Mui-focused)': {},
          '& .MuiInputLabel-outlined.MuiInputLabel-sizeMedium': {
            transform: 'translate(14px, 21px) scale(1)' // Adjust label position
          },
          '& .MuiInputLabel-outlined.MuiInputLabel-sizeMedium.MuiInputLabel-shrink': {
            transform: 'translate(14px, -8px) scale(0.75)' // Adjust label position when shrunk
          },
          '& .MuiInputLabel-outlined.MuiInputLabel-sizeSmall': {
            transform: 'translate(14px, 12px) scale(1)' // Adjust label position
          },
          '& .MuiInputLabel-outlined.MuiInputLabel-sizeSmall.MuiInputLabel-shrink': {
            transform: 'translate(14px, -8px) scale(0.75)' // Adjust label position when shrunk
          }
        }
      }
    }
  }
}

import React from 'react'

import CloseIcon from '@mui/icons-material/Close'
import MuiDialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'

const DialogTitle: React.FC<DialogTitleProps & { onClose?: () => void }> = ({
  children,
  onClose,
  ...other
}) => {
  return (
    <MuiDialogTitle {...other} sx={{ display: 'flex', alignItems: 'center' }}>
      {children}
      {onClose && (
        <IconButton
          aria-label='close'
          onClick={onClose}
          style={{
            position: 'absolute',
            right: '1rem',
            top: '1rem'
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  )
}

export default DialogTitle

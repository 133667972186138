import { Component, ErrorInfo, ReactNode } from 'react'

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
}

interface ErrorBoundaryProps {
  fallback: ReactNode | ((errorMessage: string) => ReactNode)
  children: ReactNode
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    console.log(error, info.componentStack) // Make sure logErrorToMyService is defined somewhere
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // Check if fallback is a function, and if so, call it with error.message
      if (typeof this.props.fallback === 'function') {
        return this.props.fallback(this.state.error?.message || 'Error rendering component')
      }

      // If fallback is not a function, render it as is
      return this.props.fallback
    }

    return this.props.children
  }
}

export default ErrorBoundary

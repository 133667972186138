import { useQuery } from '@tanstack/react-query'

import { camelCaseKeys } from '@utils/case-conversion'

import { QUERY_STALE_TIME_DEFAULT } from 'queries/constants'
import axiosServices from 'utils/axios'

import { IConnectedSourceSystem } from '../types'

const fetchAllBusinessesConnectedSourceSystems = async (): Promise<IConnectedSourceSystem[]> => {
  const response = await axiosServices.get(`/connected_source_systems`)
  const connectedSourceSystems = camelCaseKeys(response.data, [
    'connector.connector_config',
    'connector_spec'
  ]) as IConnectedSourceSystem[]
  return connectedSourceSystems
}

export const useFetchAllBusinessesConnectedSourceSystems = () => {
  return useQuery({
    queryKey: ['fetch-all-businesses-connected-source-systems'],
    enabled: true,
    queryFn: () => fetchAllBusinessesConnectedSourceSystems(),
    staleTime: QUERY_STALE_TIME_DEFAULT
  })
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  SelectChangeEvent,
  TextField
} from '@mui/material'

import { useDispatch, useSelector } from '@store/index'
import { addFormula, selectFormula, updateFormula } from '@store/slices/component/query-config'

import { RubyEditor } from 'components/code-editors'
import MuiSelect from 'components/form/mui-select'
import { Formik } from 'formik'
import { IDataType } from 'pages/component-management/types/component-types'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Column Name is required'),
  data_type: Yup.string().required('Data Type is required'),
  formula: Yup.string().required('Formula is required')
})

export default function AddFormulaModal({
  open,
  handleClose,
  refreshData,
  selectedFormulaName
}: {
  open: boolean
  handleClose: () => void
  refreshData: () => void
  selectedFormulaName: string | null
}) {
  const dispatch = useDispatch()

  let isEdit = true
  let initialValues = useSelector(selectFormula(selectedFormulaName))

  if (!initialValues) {
    isEdit = false
    initialValues = { name: '', data_type: IDataType.TEXT, formula: '' }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      maxWidth='md'
      fullWidth
      disableEscapeKeyDown
    >
      <DialogTitle>Add Formula Field</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (isEdit) {
            if (selectedFormulaName)
              dispatch(updateFormula({ config: values, oldName: selectedFormulaName }))
          } else {
            dispatch(addFormula(values))
          }
          refreshData()
          handleClose()
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => (
          <>
            <DialogContent dividers>
              <Grid container spacing={2} justifyContent='space-between' alignItems='center'>
                <Grid item xs={12}>
                  <TextField
                    name='name'
                    label='Column Name'
                    required
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MuiSelect
                    options={[
                      { value: IDataType.TEXT, label: 'text' },
                      { value: IDataType.NUMBER, label: 'number' },
                      { value: IDataType.BOOLEAN, label: 'boolean' },
                      { value: IDataType.DATE_STRING, label: 'date' }
                    ]}
                    required
                    value={values.data_type}
                    onChange={(e: SelectChangeEvent<string>) => {
                      setFieldValue('data_type', e.target.value as IDataType)
                    }}
                    error={touched.data_type && !!errors.data_type}
                    helperText={touched.data_type ? errors.data_type : ''}
                    label='Data Type'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <RubyEditor
                    value={values.formula}
                    height='200px'
                    onChange={(value) => setFieldValue('formula', value)}
                  />
                  {touched.formula && errors.formula && (
                    <FormHelperText error>{errors.formula}</FormHelperText>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color='error' onClick={handleClose}>
                Cancel
              </Button>
              <Button
                type='submit'
                variant='contained'
                onClick={() => handleSubmit()}
                sx={{ mr: 1 }}
              >
                Submit
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  )
}

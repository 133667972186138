import { resolveInternalPageSettings } from '@utils/layouts/page-settings'

import { RedirectTo } from '@components/redirect-to'

import { DataConnectionMode } from 'layout-components/general/data-connections/types'
import { CatalogTab } from 'pages/admin/charger-data-connections/catalog'
import { ConfigurationTab } from 'pages/admin/charger-data-connections/configuration-tab'
import { DetailLayout as ClientDataConnectionDetail } from 'pages/data-connections/detail-layout'
import LinkServices from 'pages/link-services'
import { ExtendedRouteObject } from 'routes/internal-routes'
import { Page } from 'types/page'

import { ConnectionTabs } from '../../layout-components/general/data-connections/charger-ops-connection-detail'
import { DataIntegrityChecksTab } from '../../pages/admin/charger-data-connections/data-integrity-checks-tab'
import { DataMovementJobsTab } from '../../pages/admin/charger-data-connections/data-movement-jobs-tab'
import { LiveConnectionsTab } from '../../pages/admin/charger-data-connections/live-connections-tab'

export const createDataConnectionRoute = (
  clientDataConnectionsPage: Page
): ExtendedRouteObject[] => {
  return [
    {
      path: 'data-connections',
      children: [
        {
          index: true,
          element: (
            <LinkServices pageSettings={resolveInternalPageSettings(clientDataConnectionsPage)} />
          )
        },
        {
          path: ':id',
          element: (
            <ClientDataConnectionDetail
              pageSettings={resolveInternalPageSettings(clientDataConnectionsPage)}
            />
          ),
          children: [
            {
              index: true,
              element: <RedirectTo path={ConnectionTabs.CONFIGURATION} />
            },
            {
              path: ConnectionTabs.CONFIGURATION,
              element: <ConfigurationTab mode={DataConnectionMode.CLIENT} />
            },
            {
              path: ConnectionTabs.LIVE_CONNECTIONS,
              element: <LiveConnectionsTab mode={DataConnectionMode.CLIENT} />
            },
            {
              path: ConnectionTabs.DATA_MOVEMENT,
              element: <DataMovementJobsTab mode={DataConnectionMode.CLIENT} />
            },
            {
              path: ConnectionTabs.CATALOG,
              element: <CatalogTab mode={DataConnectionMode.CLIENT} />
            },
            {
              path: ConnectionTabs.DATA_INTEGRITY_CHECKS,
              element: <DataIntegrityChecksTab mode={DataConnectionMode.CLIENT} />
            },
            {
              path: '*',
              element: <RedirectTo path='../configuration' />
            }
          ]
        }
      ]
    }
  ]
}

import { useState } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material'

const ConfirmRemoveMapping = ({
  open,
  setOpen,
  nodeCount,
  onConfirm
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  nodeCount: number
  onConfirm: (markAsNonPosting: boolean) => void
}) => {
  const handleClose = () => {
    setOpen(false)
  }

  const [markAsNonPosting, setMarkAsNonPosting] = useState(false)

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='confirm-remove-mapping'>
      <DialogTitle>{`Remove ${nodeCount} Mappings?`}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Once mapping is removed, general ledger accounts will show up under Un-mapped section.
        </DialogContentText>

        <Stack useFlexGap mt={4} spacing={1}>
          <Typography variant='h5'>Mark Removed GL Accounts as NON-POSTING?</Typography>
          <RadioGroup
            aria-label='mark-as-non-posting'
            value={markAsNonPosting}
            onChange={() => setMarkAsNonPosting(!markAsNonPosting)}
          >
            <Stack useFlexGap flexDirection='row'>
              <FormControlLabel value={true} control={<Radio />} label='Yes' />
              <FormControlLabel value={false} control={<Radio />} label='No' />
            </Stack>
          </RadioGroup>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button color='secondary' onClick={handleClose}>
          Cancel
        </Button>

        <Button onClick={() => onConfirm(markAsNonPosting)}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmRemoveMapping

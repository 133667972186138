import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { Box } from '@mui/material'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'

import LazyComponent from '../../components/core/LazyComponent'

const Header = LazyComponent(import('./Header'))
const FooterBlock = LazyComponent(import('./FooterBlock'))

const LoaderWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 2001,
  width: '100%',
  '& > * + *': {
    marginTop: theme.spacing(2)
  }
}))

export interface LoaderProps extends LinearProgressProps {}

const Loader = () => (
  <LoaderWrapper>
    <LinearProgress color='primary' />
  </LoaderWrapper>
)

const CommonLayout = ({
  layout = 'blank',
  addMargin = false
}: {
  layout?: string
  addMargin?: boolean
}) => (
  <>
    {(layout === 'landing' || layout === 'simple') && (
      <Suspense fallback={<Loader />}>
        <Header layout={layout} />
        {addMargin && <Box sx={{ marginTop: { xs: '80px', md: '135px' } }} />}
        <Outlet />
        <FooterBlock isFull={layout === 'landing'} />
      </Suspense>
    )}
    {layout === 'blank' && <Outlet />}
  </>
)

export default CommonLayout

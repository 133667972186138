import { EnumValueUnion } from '@utils/obj-utils'

import { FormikProps } from 'formik'
import * as Yup from 'yup'

export enum FormLayoutTypes {
  INLINE = 'inline',
  FULL_WIDTH = 'fullWidth',
  POPOVER = 'popover',
  DEFAULT = 'default'
}

export enum FormIconPlacement {
  INSIDE = 'inside',
  OUTSIDE = 'outside'
}
export type FormIconPlacementType = EnumValueUnion<typeof FormIconPlacement>

export const isFormLayoutTypeInline = (type: FormLayoutTypes) =>
  _.isEqual(type, FormLayoutTypes.INLINE)
export const isFormLayoutTypeFullWidth = (type: FormLayoutTypes) =>
  _.isEqual(type, FormLayoutTypes.FULL_WIDTH)
export const isFormLayoutTypePopover = (type: FormLayoutTypes) =>
  _.isEqual(type, FormLayoutTypes.POPOVER)
export const isFormLayoutTypeDefault = (type: FormLayoutTypes) =>
  _.isEqual(type, FormLayoutTypes.DEFAULT)

export interface ChargerFormViewProps {
  title?: string
  onCancel?: () => void
  submitLabel?: string
  fieldsConfig: ChargerFormFieldConfig[]
  noSubmit?: boolean
  formId?: string
  showLabels?: boolean
  layout?: FormLayoutTypes
  formik: FormikProps<Record<string, any>>
  allowCopyToClipboard?: boolean
  trailingIconPlacement?: FormIconPlacementType
}

export interface UseChargerFormProps {
  fieldsConfig: ChargerFormFieldConfig[]
  onSubmit: (data: any, formikHelpers: any) => void
}

export type ChargerFormProps = Omit<ChargerFormViewProps, 'formik'> & UseChargerFormProps

export enum FieldTypes {
  NUMBER = 'number',
  PASSWORD = 'password',
  TEXT_FIELD = 'textField',
  SELECT = 'select',
  SELECT_WITH_CLEAR = 'selectWithClear',
  MULTISELECT = 'multiSelect',
  ONE_OF = 'oneOf'
}
export const isFieldTypeOneOf = (type: FieldTypes) => _.isEqual(type, FieldTypes.ONE_OF)
export const isFieldTypeMultiSelect = (type: FieldTypes) => _.isEqual(type, FieldTypes.MULTISELECT)

export interface FieldOption {
  label: string
  value: any
}

export interface BasicFieldConfig {
  label: string
  name: string
  actualName?: string
  type: FieldTypes
  options?: Array<FieldOption>
  required?: boolean
  hidden?: boolean
  defaultValue?: string
  multiSelectDefaultValue?: Array<FieldOption>
  placeholder?: string
  helperText?: string
  validationSchema?: Yup.AnySchema
  isDisabled?: boolean
  minimumValue?: number
  maximumValue?: number
  readOnly?: boolean
  userData?: Record<string, any>
}

export interface OneOfFieldConfig {
  label: string
  name: string
  type: FieldTypes.ONE_OF
  options: Array<FieldOption>
  defaultValue?: string
  validationSchema?: Yup.AnySchema
  sections: {
    label: string
    value: string
    formFields: BasicFieldConfig[]
  }[]
  readOnly?: boolean
  userData?: Record<string, any>
}

export type ChargerFormFieldConfig = BasicFieldConfig | OneOfFieldConfig

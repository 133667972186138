import { Box, Grid } from '@mui/material'

import { Text } from '@components/core/text'

import GenreDropdown from '../common/genre-dropdown'
import { ColumnOrdering } from './column-data'
import { GrandTotals } from './grand-totals'

export default function TableSettings() {
  return (
    <Box>
      <Text variant='h5'>Component Type And Data</Text>
      <Text variant='details'>Arrange values to modify the layer order</Text>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GenreDropdown />
        </Grid>
        <Grid item xs={12}>
          <ColumnOrdering />
        </Grid>
        <Grid item xs={12}>
          <GrandTotals />
        </Grid>
      </Grid>
    </Box>
  )
}

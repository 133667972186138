import React, { ReactNode, createContext, useContext, useState } from 'react'

import { MINIMAL_LAYOUT } from '../pages/admin/layouts/data'

interface LayoutContextProps {
  layoutData: any
  setLayoutData: React.Dispatch<React.SetStateAction<any>>
  layoutStructure: any
  setLayoutStructure: React.Dispatch<React.SetStateAction<any>>
  updateLayoutStructure: React.Dispatch<React.SetStateAction<any>>
  findFilterByKey: (filterKey: string) => any | undefined
  selectedWidget: any
  setSelectedWidget: React.Dispatch<React.SetStateAction<any>>

  selectedWidgets: any[]
  setSelectedWidgets: React.Dispatch<React.SetStateAction<any[]>>
}

const LayoutContext = createContext<LayoutContextProps | undefined>(undefined)

export const LayoutProvider = ({ children }: { children: ReactNode }) => {
  const [layoutData, setLayoutData] = useState<any>({}) // The ful model: i.e response.data
  const [layoutStructure, setLayoutStructure] = useState<any>(MINIMAL_LAYOUT) // The layout_structure[]: i.e response.data.layout_structure

  const [selectedWidget, setSelectedWidget] = useState<{ id: number } | null>(null)
  const [selectedWidgets, setSelectedWidgets] = useState<{ id: number }[]>([])

  const updateLayoutStructure = (layout_structure: any[]) => {
    setLayoutData((prevLayoutData: any) => ({
      ...prevLayoutData,
      layout_structure: layout_structure
    }))
  }

  const findFilterByKey = (filterKey: string) => {
    return layoutData?.settings?.search_filters?.[filterKey]
  }

  return (
    <LayoutContext.Provider
      value={{
        layoutData,
        setLayoutData,
        layoutStructure,
        setLayoutStructure,
        updateLayoutStructure,
        findFilterByKey,
        selectedWidget,
        setSelectedWidget,
        selectedWidgets,
        setSelectedWidgets
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

export const useLayoutContext = () => {
  const context = useContext(LayoutContext)

  if (!context) {
    throw new Error('useLayout must be used within a LayoutProvider')
  }

  return context
}

import { useQuery } from '@tanstack/react-query'

import { ONE_MINUTE } from 'queries/constants'
import axiosServices from 'utils/axios'

import { IActiveConnections } from '../types'

const fetchActiveConnections = async (
  businessId: number,
  id: number
): Promise<IActiveConnections> => {
  const response = await axiosServices.get(
    `/businesses/${businessId}/connected_source_systems/${id}/list_connections`
  )
  return response.data as IActiveConnections
}

export const useFetchActiveConnections = ({
  businessId,
  id,
  pulseSupported
}: {
  businessId?: number
  id?: number
  pulseSupported?: boolean
}) => {
  return useQuery({
    queryKey: ['fetch-active-connections', id],
    enabled: !!businessId && !!id && pulseSupported,
    queryFn: () => fetchActiveConnections(businessId!, id!),
    refetchOnWindowFocus: true,
    refetchInterval: ONE_MINUTE
  })
}

import { useMutation } from '@tanstack/react-query'

import axiosServices from '@utils/axios'

import { queryErrorToast, querySuccessToast } from 'queries/query-toasts'

import { IQuickTestResponse, QuickTestStatus } from '../types'

export const useQuickTestConnection = () => {
  return useMutation({
    mutationFn: async ({ businessId, cssId }: { businessId: number; cssId: number }) => {
      const checkConnection = async (): Promise<IQuickTestResponse> => {
        const response = await axiosServices.get(
          `/businesses/${businessId}/connected_source_systems/${cssId}/quick_test_connection`
        )
        return response.data as IQuickTestResponse
      }

      return Promise.race([
        checkConnection(),
        new Promise<never>((_, reject) =>
          setTimeout(() => reject(new Error('Connection check timeout exceeded')), 80000)
        )
      ])
    },
    onSuccess: (data, variables) => {
      if (data && data.status !== QuickTestStatus.Failed) {
        querySuccessToast('Quick Test Successful')
      }
    },
    onError: (error) => {
      queryErrorToast(`Connection Test Failed: ${error.message}`)
    }
  })
}

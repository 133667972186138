import MainCard from '@components/MainCard'

import { ChargerOpsConnectionDashboard } from 'layout-components/general/data-connections/charger-ops-connection-dashboard'
import { LayoutViewProps } from 'pages/layout-view/types'

const ChargerDataConnections = (props: LayoutViewProps) => {
  const { pageSettings } = props
  return (
    <MainCard pageSettings={pageSettings}>
      <ChargerOpsConnectionDashboard />
    </MainCard>
  )
}

export default ChargerDataConnections

import { Outlet, useNavigate, useParams } from 'react-router'

import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbSeparator } from '@core/breadcrumb'
import { Separator } from '@core/separator'
import { Skeleton } from '@core/skeleton'
import { Tabs, TabsList, TabsTrigger } from '@core/tab'
import { Text } from '@core/text'

import { useCheckPathSelected } from '@hooks/use-check-path-selected'

import { useFetchConnectedSourceSystem } from 'layout-components/general/data-connections/queries/fetch-connected-source-system'

import { DataConnectionMode, IDataConnectionDetailProps } from './types'

export enum ConnectionTabs {
  CONFIGURATION = 'configuration',
  LIVE_CONNECTIONS = 'live-connections',
  DATA_MOVEMENT = 'data-movement',
  CATALOG = 'catalog',
  DATA_INTEGRITY_CHECKS = 'data-integrity-checks',
  DATA_INTEGRITY_JOBS = 'data-integrity-jobs',
  ALERTS = 'alerts'
}

interface ChargerOpsConnectionDetailProps extends IDataConnectionDetailProps {}

export const ChargerOpsConnectionDetail = ({ mode }: ChargerOpsConnectionDetailProps) => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { data, isPending, isError } = useFetchConnectedSourceSystem({ id })

  const renderBreadcrumbItem = (content?: string) => {
    if (isPending) return <Skeleton className="h-6 w-[200px]" />
    if (isError) return <Text variant="h5">ERR!</Text>

    return content ? <Text variant="h5">{content}</Text> : null
  }

  function getSelectedTab(): string | undefined {
    const tabMapping = {
      [ConnectionTabs.CONFIGURATION]: useCheckPathSelected(ConnectionTabs.CONFIGURATION),
      [ConnectionTabs.LIVE_CONNECTIONS]: useCheckPathSelected(ConnectionTabs.LIVE_CONNECTIONS),
      [ConnectionTabs.DATA_MOVEMENT]: useCheckPathSelected(ConnectionTabs.DATA_MOVEMENT),
      [ConnectionTabs.CATALOG]: useCheckPathSelected(ConnectionTabs.CATALOG),
      [ConnectionTabs.DATA_INTEGRITY_CHECKS]: useCheckPathSelected(ConnectionTabs.DATA_INTEGRITY_CHECKS),
      [ConnectionTabs.DATA_INTEGRITY_JOBS]: useCheckPathSelected(ConnectionTabs.DATA_INTEGRITY_JOBS),
      [ConnectionTabs.ALERTS]: useCheckPathSelected(ConnectionTabs.ALERTS),
    }

    return _.find(Object.keys(tabMapping) as Array<keyof typeof tabMapping>, (key) => tabMapping[key])
  }

  const tabValue = getSelectedTab()

  const connectionTitles = {
    [DataConnectionMode.CLIENT]: 'Data Connections',
    [DataConnectionMode.CHARGER_OPS]: 'Charger Data Connections'
  }

  const btnClassName = 'w-[150px] text-center';

  return (
    <div className='flex flex-col gap-2'>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem className='cursor-pointer' onClick={() => navigate('..')}>
            <Text variant='h5'>{connectionTitles[mode]}</Text>
          </BreadcrumbItem>
          <BreadcrumbSeparator />

          <BreadcrumbItem>{renderBreadcrumbItem(data?.businessName)}</BreadcrumbItem>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            {renderBreadcrumbItem(`${data?.name} (${data?.id.toString()})`)}
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <Separator className="w-full" />
      <div className="flex min-h-11 w-full items-center space-x-2">
        <Tabs id="dataConnectionsTabs" value={tabValue} onValueChange={navigate}>
          <TabsList className="flex gap-2">
            <TabsTrigger className={btnClassName} value={ConnectionTabs.CONFIGURATION}>Configuration</TabsTrigger>
            {data?.pulseSupported && <TabsTrigger value={ConnectionTabs.LIVE_CONNECTIONS}>Live Connections</TabsTrigger>}
            <TabsTrigger className={btnClassName} value={ConnectionTabs.CATALOG}>Catalog</TabsTrigger>
            <TabsTrigger className={btnClassName} value={ConnectionTabs.DATA_MOVEMENT}>Data Movement</TabsTrigger>
            <TabsTrigger className={btnClassName} value={ConnectionTabs.DATA_INTEGRITY_CHECKS}>Data Integrity</TabsTrigger>
            {mode == DataConnectionMode.CHARGER_OPS && (
              <TabsTrigger className={btnClassName} value={ConnectionTabs.DATA_INTEGRITY_JOBS}>Data Integrity Jobs</TabsTrigger>
            )}
            {mode == DataConnectionMode.CHARGER_OPS && (
              <TabsTrigger className={btnClassName} value={ConnectionTabs.ALERTS}>Alerting</TabsTrigger>
            )}
          </TabsList>
        </Tabs>
      </div>
      <Separator className='w-full' />
      <Outlet />
    </div>
  )
}

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps
} from '@mui/material'

export default function MuiSelect({
  options,
  value,
  onChange,
  label,
  variant = 'outlined',
  fullWidth = true,
  required = false,
  error,
  helperText
}: {
  options: { value: string; label: string }[]
  value?: string
  onChange: (value: SelectChangeEvent<string>) => void
  id?: string
  label?: string
  fullWidth?: boolean
  placeholder?: string
  variant?: SelectProps['variant']
  required?: boolean
  error?: boolean
  helperText?: string
}) {
  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel>
        {label} {required && '*'}
      </InputLabel>
      <Select
        variant={variant}
        required={required}
        size='small'
        value={value}
        label={label}
        onChange={onChange}
        error={error}
      >
        {options.map((option: any) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  )
}

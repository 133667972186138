import React, { useRef } from 'react'

import { Button, ButtonProps } from '@mui/material'
import { styled } from '@mui/material/styles'

import { cn } from '@utils/style-utils'

import { ChevronDown, Icon } from './icons'

interface Props extends ButtonProps {
  open?: boolean
}

export const useAnchor = () => {
  const anchorRef = useRef<HTMLElement>(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorRef.current || event.currentTarget)
    event.currentTarget.classList.add('Mui-focused')
  }

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.classList.remove('Mui-focused')
    }
    setAnchorEl(null)
  }

  return {
    anchorRef,
    open,
    anchorEl,
    onClick: handleClick,
    onClose: handleClose
  }
}

const StyledButton = styled(Button)(({ theme }) => ({
  '&[aria-expanded="true"]': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  }
}))

const DropDownButton = (props: Props) => {
  return (
    <StyledButton
      aria-expanded={!!props.open}
      endIcon={
        <Icon
          icon={<ChevronDown />}
          className={cn(
            'transition-transform duration-300',
            props.open ? 'rotate-180' : 'rotate-0'
          )}
        />
      }
      {...props}
    >
      {props.children}
    </StyledButton>
  )
}

export default DropDownButton

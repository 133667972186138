import { useEffect } from 'react'

import { IPropsEffectProps } from '../types/module-types'
import { getSelfRef } from '../utils/module-utils'

export default function useSetAxisName({ propsRef, moduleName, props }: IPropsEffectProps) {
  useEffect(() => {
    const series = getSelfRef({ ...propsRef.current, moduleName })
    series.set('name', props.options?.name)
  }, [props.options?.name, propsRef, moduleName])
}

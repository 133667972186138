// material-ui
import { CheckboxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

// types
import { ExtendedStyleProps } from 'types/extended'
// project import
import getColors from 'utils/getColors'

// ==============================|| RADIO - COLORS ||============================== //

function getColorStyle({ color, theme }: ExtendedStyleProps) {
  const colors = getColors(theme, color)
  const { main, dark } = colors

  return {
    '& .dot': {
      backgroundColor: main
    },
    '&:hover': {
      backgroundColor: theme.palette.grey['100']
    },
    '&.Mui-focusVisible': {
      outline: `2px solid ${dark}`,
      outlineOffset: -4
    }
  }
}

// ==============================|| CHECKBOX - SIZE STYLE ||============================== //

interface RadioSizeProps {
  size: number
  dotSize: number
  position: number
}

function getSizeStyle(size?: CheckboxProps['size']): RadioSizeProps {
  switch (size) {
    case 'small':
      return { size: 16, dotSize: 8, position: 3 }
    case 'large':
      return { size: 24, dotSize: 12, position: 5 }
    case 'medium':
    default:
      return { size: 20, dotSize: 10, position: 4 }
  }
}

// ==============================|| CHECKBOX - STYLE ||============================== //

function radioStyle(size?: CheckboxProps['size']) {
  const sizes: RadioSizeProps = getSizeStyle(size)

  return {
    '& .icon': {
      width: sizes.size,
      height: sizes.size,
      '& .dot': {
        width: sizes.dotSize,
        height: sizes.dotSize,
        top: sizes.position,
        left: sizes.position
      }
    }
  }
}

// ==============================|| OVERRIDES - CHECKBOX ||============================== //

export default function Radio(theme: Theme) {
  const { palette } = theme

  return {
    MuiRadio: {
      defaultProps: {
        className: 'size-medium',
        icon: (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
          >
            <rect x='1' y='1' width='16' height='16' rx='8' stroke='#2170BA' strokeWidth='2' />
          </svg>
        ),
        checkedIcon: (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
          >
            <rect
              x='1'
              y='1'
              width='16'
              height='16'
              rx='8'
              fill='white'
              stroke='#2170BA'
              strokeWidth='2'
            />
            <rect
              x='4'
              y='4'
              width='10'
              height='10'
              rx='5'
              fill='#2170BA'
              stroke='#2170BA'
              strokeWidth='2'
            />
          </svg>
        )
      },
      styleOverrides: {
        root: {
          borderColor: palette.primary.main,

          color: palette.secondary[300],
          '&.size-small': {
            ...radioStyle('small')
          },
          '&.size-medium': {
            ...radioStyle('medium')
          },
          '&.size-large': {
            ...radioStyle('large')
          }
        },
        colorPrimary: getColorStyle({ color: 'primary', theme }),
        colorSecondary: getColorStyle({ color: 'secondary', theme }),
        colorSuccess: getColorStyle({ color: 'success', theme }),
        colorWarning: getColorStyle({ color: 'warning', theme }),
        colorInfo: getColorStyle({ color: 'info', theme }),
        colorError: getColorStyle({ color: 'error', theme })
      }
    }
  }
}

import { useQuery } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'

import type { IModelDetail } from '../types/query-builder-types'

const fetchModelsDetail = async (modelName: string, business_id: number): Promise<IModelDetail> => {
  const response = await axiosServices.get(`/businesses/${business_id}/models/${modelName}`)
  return response.data
}

export const useFetchModelsDetail = (modelName: string) => {
  const { user } = useAuth()
  const business_id = user?.business_id!
  return useQuery({
    queryKey: ['fetch-models-detail', business_id, modelName],
    queryFn: () => fetchModelsDetail(modelName, business_id),
    enabled: !!business_id && !!modelName
  })
}

import { DEFAULT_LANGUAGE_ID } from '@components/control-panel/language-selector'
import {
  FinancialReportExportFilters,
  FinancialReportType,
  isModelPlusActualType,
  isModelToActualType
} from '@components/financial/types'

import { SelectedDimensions } from 'components/control-panel/types'
import { exportExcelByBackendSync } from 'services/excel-exporter/sync-excel-export'
import { FinancialReportParams } from 'services/excel-exporter/types'
import { DateRangeType, ExpandedDateFilterInfo, Frequency } from 'types/filter'
import { getRangeInfoFromDateFilterInfo } from 'utils/date-filter-utils'
import { dateFilterFrequencyForPayload } from 'utils/date-utils'
import { isNotEqual } from 'utils/lodash'

import { ACTUALS } from './model-plus-actual/const'
import { IModelSelection } from './model-plus-actual/types'

const getExportParams = ({
  type,
  dateFilter,
  showDuplicateAccounts,
  selectedDimensionFilters,
  nodesExpandedState,
  selectedDimension,
  hierarchyId,
  monthSelections,
  year = 0,
  modelId,
  yearMonthInfo,
  languageId
}: {
  type: FinancialReportType
  dateFilter: ExpandedDateFilterInfo | undefined
  showDuplicateAccounts?: boolean
  selectedDimensionFilters?: Partial<SelectedDimensions>
  nodesExpandedState: { node_id: number; node_label: string; expanded: boolean }[]
  selectedDimension?: string
  hierarchyId?: number
  monthSelections?: IModelSelection
  year?: number
  modelId?: string | number | null
  yearMonthInfo?: string
  languageId?: string
}): FinancialReportParams => {
  let yearFilter
  if (isModelPlusActualType(type)) {
    yearFilter = getRangeInfoFromDateFilterInfo({
      frequency: Frequency.Yearly,
      rangeType: DateRangeType.Range,
      startDate: { year },
      endDate: { year }
    })
  } else if (isModelToActualType(type)) {
    yearFilter = null
  } else {
    yearFilter = dateFilter
  }

  const params = _.omitBy(
    {
      type: _.snakeCase(type),
      selected_dimension: selectedDimension === '' ? null : selectedDimension,
      hierarchy_id: hierarchyId,
      model_params: isModelPlusActualType(type)
        ? JSON.stringify(_.omit(_.invertBy(monthSelections), [ACTUALS]))
        : null,
      model_id: isNotEqual(modelId, '') ? modelId : null,
      end_month: yearMonthInfo,
      start_date: yearFilter?.startDate,
      end_date: yearFilter?.endDate,
      mode: dateFilterFrequencyForPayload(dateFilter?.frequency || Frequency.Monthly),
      show_dupe_gl_accounts: showDuplicateAccounts,
      nodes_expansion_state: nodesExpandedState,
      language_id: languageId,
      ...selectedDimensionFilters
    },
    _.isNil
  )

  return params
}

export const handleExportXLSXByBackend = ({
  financialReportType,
  exportFilters,
  user,
  nodesExpandedState,
  selectedDimension,
  modelId,
  yearMonthInfo,
  languageId
}: {
  financialReportType: FinancialReportType
  exportFilters: FinancialReportExportFilters
  user: any
  nodesExpandedState: { node_id: number; node_label: string; expanded: boolean }[]
  selectedDimension?: string
  modelId?: string | number | null
  yearMonthInfo?: string
  languageId?: string
}) => {
  const endpoint = `/businesses/${user?.business_id}/financial_reports/export`
  const params = getExportParams({
    type: financialReportType,
    dateFilter: exportFilters.dateFilter,
    showDuplicateAccounts: exportFilters.showDuplicateAccounts,
    selectedDimensionFilters: exportFilters.selectedDimensionFilters,
    nodesExpandedState: nodesExpandedState,
    selectedDimension: selectedDimension,
    hierarchyId: exportFilters.selectedHierarchyId,
    monthSelections: exportFilters.monthSelections,
    year: exportFilters.selectedYear,
    modelId: modelId,
    yearMonthInfo: yearMonthInfo,
    languageId: _.isEqual(languageId, DEFAULT_LANGUAGE_ID) ? undefined : languageId
  })
  const fileName = `${_.snakeCase(financialReportType)}_${new Date().toISOString()}.xlsx`

  exportExcelByBackendSync({ endpoint: endpoint, params: params, fileName: fileName })
}

import { useMemo } from 'react'
import { Navigate } from 'react-router'

import useAuth from '@hooks/useAuth'

export const InternalPagesGuard = ({ children }: any) => {
  const { isLoggedIn } = useAuth()

  const canAccess = useMemo(() => {
    return isLoggedIn

    // TODO:
    // isLoggedIn && isPrimaryBusinessCharger && omniAccessCurrentBusiness;
  }, [isLoggedIn])

  if (!canAccess) {
    return <Navigate to='/login' replace={true} />
  }

  return children
}

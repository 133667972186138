import * as am5xy from '@amcharts/amcharts5/xy'

export default function convertCategoryLabelsToString({
  renderer,
  axisKey
}: {
  renderer: am5xy.AxisRendererX | am5xy.AxisRendererY
  axisKey: string
}) {
  renderer.labels.template.adapters.add('text', function (text, target) {
    const data = _.get(target, 'dataItem.dataContext') as Record<string, any>
    if (!data) return text

    return `${data[axisKey]}`
  })
}

import { IconButton } from '@components/core/icon-button'
import { Switch } from '@components/core/switch'
import ArrowLeftIcon from '@components/icons/arrow-left.svg?react'
import ArrowRightIcon from '@components/icons/arrow-right.svg?react'

import { useDispatch, useSelector } from '@store/index'
import { selectColumns, updateColumn } from '@store/slices/component/table-config'

import {
  ColumnPinType,
  ITableColumn,
  isColumnPinTypeLeft,
  isColumnPinTypeRight
} from '../../types/table-builder-types'
import { whenPivotColumnIsValueOrPivot } from '../utils'

export const Pinning = ({ item: column }: { item: ITableColumn }) => {
  const dispatch = useDispatch()
  const columns = useSelector(selectColumns)

  const disabled = whenPivotColumnIsValueOrPivot(column, columns)

  const sharedButtonVariant = disabled ? 'disabled' : 'outline'

  return (
    <div className='flex items-center justify-between'>
      <Switch
        disabled={disabled}
        checked={!!column.pinned}
        onCheckedChange={(checked) =>
          dispatch(
            updateColumn({ field: column.field, pinned: checked ? ColumnPinType.LEFT : null })
          )
        }
      />
      <div className='flex gap-2'>
        <IconButton
          disabled={disabled}
          variant={isColumnPinTypeLeft(column.pinned) ? 'primary' : sharedButtonVariant}
          onClick={() =>
            !isColumnPinTypeLeft(column.pinned) &&
            dispatch(updateColumn({ field: column.field, pinned: ColumnPinType.LEFT }))
          }
        >
          <ArrowLeftIcon />
        </IconButton>
        <IconButton
          disabled={disabled}
          variant={isColumnPinTypeRight(column.pinned) ? 'primary' : sharedButtonVariant}
          onClick={() =>
            !isColumnPinTypeRight(column.pinned) &&
            dispatch(updateColumn({ field: column.field, pinned: ColumnPinType.RIGHT }))
          }
        >
          <ArrowRightIcon />
        </IconButton>
      </div>
    </div>
  )
}

import React, { CSSProperties, ReactNode, useMemo, useRef, useState } from 'react'

import { cn } from '@utils/style-utils'

import Button from '@components/core/button'
import { DragIndicator, Icon } from '@components/icons'

import { useLatestRef } from '@hooks/useLatestRef'

type SnapComponentStyles = {
  parent?: CSSProperties
  first?: CSSProperties
  second?: CSSProperties
}

export const DEFAULT_SNAP_POINTS: [number, number, number] = [0, 60, 100]

export const ResizePlaceholder = () => <div className='h-full w-[26px] bg-transparent' />

export const Resizer = ({
  children,
  dimensionFilterOpen = false,
  snapPoints = DEFAULT_SNAP_POINTS,
  position: externalPosition,
  onPositionChange
}: {
  children: (p: { currentSnapIndex: number; snapComponentStyles: SnapComponentStyles }) => ReactNode
  dimensionFilterOpen?: boolean
  snapPoints?: [number, number, number]
  position?: number
  onPositionChange?: (position: number, snappedPosition: number) => void
}) => {
  const [internalPosition, setInternalPosition] = useState(snapPoints[1])
  const containerRef = useRef<HTMLDivElement>(null)
  const [isDragging, setIsDragging] = useState(false)
  const getClosestSnapPoint = (pos: number) => {
    return snapPoints.reduce((prev, curr) =>
      Math.abs(curr - pos) < Math.abs(prev - pos) ? curr : prev
    )
  }

  const position = externalPosition ?? internalPosition

  const positionRef = useLatestRef(position)

  const snappedPosition = getClosestSnapPoint(position)

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault()
    setIsDragging(true)

    const onMouseMove = (moveEvent: MouseEvent) => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth
        const mouseX = moveEvent.clientX - containerRef.current.getBoundingClientRect().left
        const newPosition = Math.min(Math.max((mouseX / containerWidth) * 100, 0), 100)

        setInternalPosition(newPosition)
        if (onPositionChange) {
          onPositionChange(newPosition, getClosestSnapPoint(newPosition))
        }
      }
    }

    const onMouseUp = () => {
      setIsDragging(false)
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)

      const newPosition = getClosestSnapPoint(positionRef.current)
      setInternalPosition(newPosition)
      if (onPositionChange) {
        onPositionChange(newPosition, newPosition)
      }
    }

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)
  }

  const currentSnapIndex = snapPoints.findIndex((snapPoint) => snapPoint === snappedPosition)

  const snapComponentStyles = useMemo(() => {
    const fullWidth = `calc(${snapPoints[2] - snapPoints[0]}% - 26px)`
    const leftWidth = `calc(${snapPoints[1] - snapPoints[0]}% - 13px)`
    const rightWidth = `calc(${snapPoints[2] - snapPoints[1]}% - 13px)`

    if (currentSnapIndex === 0) {
      return {
        parent: { display: 'grid', gridTemplateColumns: `26px ${fullWidth}` },
        first: { display: 'none' }
      } as SnapComponentStyles
    } else if (currentSnapIndex === 1) {
      return {
        parent: { display: 'grid', gridTemplateColumns: `${leftWidth} 26px ${rightWidth}` }
      } as SnapComponentStyles
    } else {
      return {
        parent: dimensionFilterOpen
          ? { display: 'grid', gridTemplateColumns: `${leftWidth} ${rightWidth} 26px` }
          : { display: 'grid', gridTemplateColumns: `${fullWidth} 26px` },
        second: { display: 'none' }
      } as SnapComponentStyles
    }
  }, [currentSnapIndex, snapPoints, dimensionFilterOpen])

  return (
    <>
      <div
        ref={containerRef}
        className={cn('relative size-full', isDragging && 'cursor-ew-resize')}
      >
        {children({ currentSnapIndex, snapComponentStyles })}

        <div
          className='absolute inset-y-0 h-full w-[26px] bg-transparent'
          style={{
            left:
              position === 100
                ? 'calc(100% - 26px)'
                : position === 0
                  ? `${position}%`
                  : `calc(${position}% - 13px)`
          }}
        >
          <div className='absolute left-[13px] top-0 h-full w-0 border-0 border-l border-dotted border-grey-lighter'></div>
        </div>

        <div
          className='absolute inset-y-0 h-full w-[26px] bg-transparent'
          style={{
            left:
              snappedPosition === 100
                ? 'calc(100% - 26px)'
                : snappedPosition === 0
                  ? `${snappedPosition}%`
                  : `calc(${snappedPosition}% - 13px)`
          }}
        >
          <div className='absolute left-[13px] top-0 h-full w-px bg-grey-lighter'></div>
          <div className='relative flex h-full items-center'>
            <Button
              variant={[0, 2].includes(currentSnapIndex) ? 'primary' : 'outline'}
              onMouseDown={handleMouseDown}
              className='cursor-ew-resize p-0 enabled:cursor-ew-resize'
            >
              <Icon icon={<DragIndicator />} />
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

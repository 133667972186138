import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Box,
  ClickAwayListener,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'

import { BorderOutlined } from '@ant-design/icons'
import { capitalizeValueAtPath, isLastOrUniqueNavItem } from '@utils/obj-utils'

import Transitions from 'components/@extended/Transitions'
import { selectCurrentMenuItem, selectCurrentPage, selectDrawerOpen } from 'store/slices/navigation'
import {
  MenuItemType,
  NavItemType,
  isMenuItemTypeCollapse,
  isMenuItemTypeGroup,
  isMenuItemTypeItem
} from 'types/menu'

import NavGroup from './NavGroup'
import NavItem from './NavItem'

const CHARGER_INTERNAL_PAGE_GROUP = 'Charger Internal'
const isChargerInternal = (label: React.ReactNode | string | undefined) => {
  if (typeof label === 'string') {
    return _.isEqual(_.toLower(_.trim(label)), _.toLower(CHARGER_INTERNAL_PAGE_GROUP))
  }
  return false
}

type VirtualElement = {
  getBoundingClientRect: () => DOMRect
  contextElement?: Element
}

// mini-menu - wrapper
const PopperStyled = styled(Popper)(({ theme }) => ({
  overflow: 'visible',
  zIndex: 1202,
  minWidth: 180,
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 38,
    left: -5,
    width: 10,
    height: 10,
    backgroundColor: theme.palette.background.paper,
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 120,
    borderLeft: `1px solid ${theme.palette.grey.A800}`,
    borderBottom: `1px solid ${theme.palette.grey.A800}`
  }
}))

// ==============================|| NAVIGATION - LIST COLLAPSE ||============================== //

interface Props {
  parent: MenuItemType
  menu: NavItemType
  level: number
  searchTerm?: string
}

const NavCollapse = ({ parent, menu, level, searchTerm }: Props) => {
  const theme = useTheme()

  const drawerOpen = useSelector(selectDrawerOpen)
  const currentMenuItem = useSelector(selectCurrentMenuItem)
  const currentPage = useSelector(selectCurrentPage)

  const currentPageID = currentPage?.pageSettings?.id ?? currentMenuItem.id

  const [open, setOpen] = useState(!isChargerInternal(menu?.title))
  const [selected, setSelected] = useState<string | null | undefined>(null)

  const [anchorEl, setAnchorEl] = useState<
    VirtualElement | (() => VirtualElement) | null | undefined
  >(null)

  const handleClick = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | undefined
  ) => {
    setAnchorEl(null)
    if (drawerOpen) {
      setOpen(!open)
      setSelected(!selected ? menu.id : null)
    } else {
      setAnchorEl(event?.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { pathname } = useLocation()

  useEffect(() => {
    if (searchTerm) {
      setOpen(true)
    }
  }, [searchTerm])

  useEffect(() => {
    const isAnyChildSelected = (items: any) => {
      if (!items.children) {
        return false
      }

      for (const item of items.children) {
        if (
          currentPageID &&
          isMenuItemTypeItem(item.type) &&
          currentPageID.toString() === item.id.toString()
        ) {
          return true
        } else if (isMenuItemTypeCollapse(item.type) && isAnyChildSelected(item.children)) {
          return true
        }
      }
      return false
    }

    menu.children?.forEach((item) => {
      // NOTE: For static pages, find by path, else find by ID
      if (item.url === pathname) {
        setOpen(true)
      } else if (isAnyChildSelected(item)) {
        setOpen(true)
      }
    })
  }, [pathname, menu, currentPageID])

  const openMini = Boolean(anchorEl)

  const navCollapse = menu.children?.map((item, index, array) => {
    switch (item.type) {
      case MenuItemType.collapse:
        return (
          <NavCollapse
            parent={MenuItemType.collapse}
            searchTerm={searchTerm}
            key={item.id}
            menu={item}
            level={level + 1}
          />
        )
      case MenuItemType.item:
        return (
          <NavItem
            parent={MenuItemType.collapse}
            searchTerm={searchTerm}
            key={item.id}
            item={item}
            level={level + 1}
            isLastItem={isLastOrUniqueNavItem(item, index, array)}
          />
        )
      default:
        return (
          <NavGroup
            parent={MenuItemType.collapse}
            searchTerm={searchTerm}
            key={item.id}
            item={item}
            level={level + 1}
          />
        )
    }
  })

  const enableIcon = false

  const borderIcon =
    level === 1 && enableIcon ? <BorderOutlined style={{ fontSize: '1rem' }} /> : false
  const Icon = menu.icon!
  const menuIcon = menu.icon ? (
    <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} />
  ) : (
    borderIcon
  )
  const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary'
  const iconSelectedColor =
    theme.palette.mode === 'dark' && drawerOpen
      ? theme.palette.text.primary
      : theme.palette.primary.main

  return (
    <>
      <ListItemButton
        className='nav-collapse-box'
        disableRipple
        {...(!drawerOpen && { onMouseEnter: handleClick, onMouseLeave: handleClose })}
        onClick={handleClick}
        sx={{
          height: '40px',
          pl: 0,
          py: 0.5,
          pR: 0,
          borderTop: '1px solid rgba(255, 255, 255, 0.2)',
          ...(drawerOpen && {
            '&:hover': {
              bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'primary.lighter'
            },
            '&.Mui-selected': {
              bgcolor: 'transparent',
              color: iconSelectedColor,
              '&:hover': {
                color: iconSelectedColor,
                bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'transparent'
              }
            }
          }),
          ...(!drawerOpen && {
            '&:hover': {
              bgcolor: 'transparent'
            },
            '&.Mui-selected': {
              '&:hover': {
                bgcolor: 'transparent'
              },
              bgcolor: 'transparent'
            }
          })
        }}
      >
        {menuIcon && (
          <ListItemIcon
            sx={{
              minWidth: 28,
              color: selected === menu.id ? 'primary.main' : textColor,
              ...(!drawerOpen && {
                borderRadius: 1.5,
                width: 36,
                height: 36,
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter'
                }
              }),
              ...(!drawerOpen &&
                selected === menu.id && {
                  bgcolor: theme.palette.mode === 'dark' ? 'primary.900' : 'primary.lighter',
                  '&:hover': {
                    bgcolor: theme.palette.mode === 'dark' ? 'primary.darker' : 'primary.lighter'
                  }
                })
            }}
          >
            {menuIcon}
          </ListItemIcon>
        )}

        {drawerOpen &&
          (openMini || open ? (
            <ExpandMoreIcon
              sx={{
                fontSize: '1.5rem',
                fontWeight: 'bolder',
                color: 'white',
                mr: '8px',
                mt: '4px',
                mb: '4px'
              }}
            />
          ) : (
            <ChevronRightIcon
              sx={{
                fontSize: '1.5rem',
                fontWeight: 'bolder',
                color: 'white',
                mr: '8px',
                mt: '4px',
                mb: '4px'
              }}
            />
          ))}

        {drawerOpen && (
          <ListItemText
            primary={
              <Typography
                className='nav-collapse-title'
                variant={parent !== MenuItemType.collapse ? 'menuPageGroup' : 'menuPageGroupBold'}
                color={selected === menu.id ? 'primary' : textColor}
                style={{ letterSpacing: level > 1 ? '0' : undefined }}
              >
                {isMenuItemTypeGroup(parent) && level === 1 && menu.title
                  ? capitalizeValueAtPath(menu, 'title')
                  : menu.title}
              </Typography>
            }
            secondary={
              menu.caption && (
                <Typography variant='caption' color='secondary'>
                  {menu.caption}
                </Typography>
              )
            }
          />
        )}

        {!drawerOpen && (
          <PopperStyled
            open={openMini}
            anchorEl={anchorEl}
            placement='right-start'
            style={{
              zIndex: 2001
            }}
            popperOptions={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [-12, 1]
                  }
                }
              ]
            }}
          >
            {({ TransitionProps }) => (
              <Transitions in={openMini} {...TransitionProps}>
                <Paper
                  sx={{
                    overflow: 'hidden',
                    mt: 1.5,
                    boxShadow: theme.customShadows.z1,
                    backgroundImage: 'none',
                    border: `1px solid ${theme.palette.divider}`
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <Box>{navCollapse}</Box>
                  </ClickAwayListener>
                </Paper>
              </Transitions>
            )}
          </PopperStyled>
        )}
      </ListItemButton>
      {drawerOpen && (
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List className='nav-collapse-list' sx={{ p: '0' }}>
            {navCollapse}
          </List>
        </Collapse>
      )}
    </>
  )
}

export default NavCollapse

import { AirbyteIntegration } from './airbyte-type'

export type IConnector = Partial<{
  connectorName: string
  description: string
  applicationName: string
  connectorConfig: Record<string, any>
}>

export enum TokenProvider {
  POSTGRESQL = 'Postgres',
  MS_SQL = 'MSSQL',
  ORACLE_DB = 'OracleDB'
}

export enum DataIntegrityCheckType {
  TRUE = 'True',
  FALSE = 'False'
}

export enum PulseStatus {
  ENABLED = 'Enabled',
  NOT_ENABLED = 'Not Enabled'
}

export const getPulseStatus = (isPulseSupported: boolean) => {
  return isPulseSupported ? PulseStatus.ENABLED : PulseStatus.NOT_ENABLED
}

export enum DataConnectionMode {
  CLIENT = 'client',
  CHARGER_OPS = 'chargerOps'
}

export interface IDataConnectionDetailProps {
  mode: DataConnectionMode
}

export interface ICheckConnectionResponse {
  status: string
  message?: string
}

export interface IQuickTestResponse {
  css_id?: number
  status: string
  reason?: string
}

export interface ISourceSystem {
  id: number
  name: string
  connectMultiple: boolean
  connectorSpec: string
  provider: string
  status: boolean
  visible: boolean
}

export interface IConnectedSourceSystem {
  id: number
  name: string
  isActive: boolean
  sourceApplicationName: string
  connectorSpec?: AirbyteIntegration | null
  connector: IConnector
  businessId: number
  businessName: string
  businessFriendlyName: string
  pulseSupported: boolean
  tokenProvider: TokenProvider
}

export interface ISourceApplicationType {
  id: number
  name: string
  sourceSystemId: number
}

export interface IActiveConnectionPostgres {
  application_name: string
  backend_start: string
  client_addr: string
  client_hostname: string
  client_port: number
  datid: number
  datname: string
  pid: number
  query: string
  query_start: string
  state: string
  state_change: string
  usename: string
  usesysid: number
  waiting: boolean
  xact_start: string
}

export interface IActiveConnectionMSSQL {
  session_id: number
  login_time: string
  login_name: string
  program_name: string
  host_name: string
  status: string
  cpu_time: number
  memory_usage: number
  total_scheduled_time: number
  total_elapsed_time: number
  last_request_start_time: string
  last_request_end_time: string
  reads: number
  writes: number
}

export interface IActiveConnectionOracleDB {
  CLIENT_IDENTIFIER: string | null
  EVENT: string
  MACHINE: string
  OSUSER: string
  PREV_EXEC_START: string
  PROGRAM: string
  SCHEMANAME: string
  'SERIAL#': number
  SERVICE_NAME: string
  SID: number
  SQL_EXEC_START: null
  STATE: string
  STATUS: string
  TYPE: string
  USERNAME: string
  WAIT_CLASS: string
  WAIT_TIME_MICRO: number
}

export type IActiveConnections =
  | IActiveConnectionPostgres[]
  | IActiveConnectionMSSQL[]
  | IActiveConnectionOracleDB[]

export const LAST_SYNC_STATUS: Record<string, string> = {
  complete: 'Completed',
  failed: 'Failed',
  in_progress: 'In Progress'
}

export const isLastSyncStatusComplete = (status: string) =>
  _.isEqual(status, LAST_SYNC_STATUS.complete)
export const isLastSyncStatusFailed = (status: string) => _.isEqual(status, LAST_SYNC_STATUS.failed)
export const isLastSyncStatusInProgress = (status: string) =>
  _.isEqual(status, LAST_SYNC_STATUS.in_progress)

export interface JobData {
  jobId: string
  startTime: string
  endTime: string
  status: string
  failureReason: string
  numOfRecordsRead?: number
  stats?: Record<string, any>
  streamStatusesId?: string
  streamStatusesConfig?: string
  streamStatusesPendingStreams?: string
  streamStatusesStreamStates?: string
  streamStatusesCatalogS3Key?: string
  streamStatusesCreatedAt?: string
  streamStatusesUpdatedAt?: string
}

export interface IDataIntegrityJob {
  id: number
  stream: string
  namespace: string
  diffCheckType: string
  startedAt: string
  endedAt: string
  uuid: string
  mismatchFound: boolean
  metadata: IMetadata
}

export interface IMetadata {
  uuid?: string
  added?: number
  schema?: string
  stream?: string
  removed?: number
  service?: string
  updated?: number
  businessId?: number
  messageType?: string
  featureFlags?: any
  destRowCount?: number
  mismatchFound?: boolean
  diffCheckType?: string
  sourceRowCount?: number
  sourcePkNullCount?: number
  sourcePkRowCount?: number
  destPkRowCount?: number
  rowCountDiff?: number
  connectedSourceSystemId?: number
  connectedSourceSystemName?: string
  sourceHasNullPk?: boolean

  sourceCursorValues?: string
  cursorFields?: string
  destCursorValues?: string
}

export enum QuickTestStatus {
  Success = 'success',
  Failed = 'failed'
}

export enum EventHandlerStatus {
  Pending = 'pending',
  InProgress = 'in_progress',
  Completed = 'completed',
  Failed = 'failed',
  Unknown = 'unknown'
}

export interface ICachedData {
  status: EventHandlerStatus
  message?: string
  asOf?: string
  connectionStatus?: string
  details?: IDataConnectionLogDetails
  expiresAt?: string
}

export interface IEventHandlerResponse {
  status?: string
  eventKey?: string
  eventName?: string
  data?: IDataConnectionLogDetails
  cachedData?: ICachedData
}

export interface IDataConnectionLogEntry {
  level: string
  message: string
}

export interface IDataConnectionLogDetails {
  stdout?: Array<{
    log: IDataConnectionLogEntry
    type: string
  }>
  [key: string]: any
}

import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField
} from '@mui/material'
import Button from '@mui/material/Button'

import { enumToOptions } from '@utils/obj-utils'

import useAuth from '@hooks/useAuth'

import MainCard from '../../components/MainCard'
import { useCreateComponent } from './queries/create-component'
import { fetchComponent } from './queries/fetch-component'
import {
  ComponentGenre,
  ComponentScope,
  ComponentSource,
  ComponentStatus
} from './types/component-types'

const genreOptions = enumToOptions(ComponentGenre)
const scopeOptions = enumToOptions(ComponentScope)

const componentList = [
  { label: 'General > insight', value: 'insights' },
  { label: 'General > sales', value: 'sales' },
  { label: 'General > sales-forecast', value: 'sales-forecast' },
  { label: 'General > invoices', value: 'invoices' },

  { label: 'General > income-statement', value: 'income-statement' },
  { label: 'General > balance-sheet', value: 'balance-sheet' },
  { label: 'General > expenses', value: 'expenses' },
  { label: 'General > data-connections', value: 'data-connections' },
  { label: 'General > audit-invoices', value: 'audit-invoices' },
  { label: 'General > chat', value: 'chat' },
  { label: 'General > sales-deep-dive', value: 'sales-deep-dive' },
  { label: 'General > locations', value: 'locations' }
]

export default function RegisterComponent() {
  const { id: componentID } = useParams()
  const { user } = useAuth()

  const [selectedComponent, setSelectedComponent] = useState('')
  const [title, setTitle] = useState('')
  const [name, setName] = useState('')
  const [selectedScope, setSelectedScope] = useState('general')
  const [selectedGenre, setSelectedGenre] = useState('table')
  const [status, setStatus] = useState('draft')
  const [enabled, setEnabled] = useState(true)

  const { mutate: createComponent, isPending } = useCreateComponent()

  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      if (componentID) {
        try {
          const fetchedComponentData = await fetchComponent(
            parseInt(componentID),
            user?.business_id as number
          )
          if (fetchedComponentData) {
            setSelectedComponent(fetchedComponentData.name)
            setTitle(fetchedComponentData.title)
            setName(fetchedComponentData.name)
            setSelectedScope(fetchedComponentData.scope)
            setSelectedGenre(fetchedComponentData.genre)
            setStatus(fetchedComponentData.status)
            setEnabled(fetchedComponentData.enabled)
          }
        } catch (error) {
          console.error('Error fetching component data:', error)
        }
      }
    }

    fetchData().then((r) => console.log(r))
  }, [componentID, user?.business_id])

  const handleComponentChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value
    const selectedComponent = componentList.find((component) => component.value === selectedValue)

    setSelectedComponent(selectedValue)
    setName(event.target.value)

    setTitle(selectedComponent?.label || '')
  }

  const handleScopeChange = (event: SelectChangeEvent<string>) => {
    alert(event.target.value)
    setSelectedScope(event.target.value)
  }

  const handleGenreChange = (event: SelectChangeEvent<string>) => {
    setSelectedGenre(event.target.value)
  }

  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newStatus = checked ? 'published' : 'draft'
    setStatus(newStatus)
  }

  const handleEnabledChange = (value: boolean) => {
    setEnabled(value)
  }

  const handleSaveOrUpdate = () => {
    createComponent(
      {
        body: {
          scope: selectedScope as ComponentScope,
          genre: selectedGenre as ComponentGenre,
          source: ComponentSource.static,
          status: status as ComponentStatus,
          title: title,
          name: selectedComponent,
          enabled: true
        }
      },
      {
        onSuccess: ({ data }) => {
          if (!componentID) {
            navigate(`${data.id}/edit`)
          }
        }
      }
    )
  }

  return (
    <MainCard title={componentID ? 'Update Component' : 'Register Component'}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl fullWidth style={{ marginBottom: '16px' }}>
            <InputLabel id='component-label'>Select a Component</InputLabel>
            <Select
              labelId='component-label'
              id='component-select'
              value={selectedComponent}
              label='Select a Component'
              onChange={handleComponentChange}
            >
              {componentList.map((component) => (
                <MenuItem key={component.value} value={component.value}>
                  {component.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label='Title'
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            margin='normal'
            style={{ marginBottom: '16px' }}
          />

          <TextField
            label='Name'
            fullWidth
            disabled
            value={name}
            onChange={(e) => setName(e.target.value)}
            margin='normal'
            style={{ marginBottom: '16px' }}
          />

          <FormControl fullWidth style={{ marginBottom: '16px' }}>
            <InputLabel id='scope-label'>Scope</InputLabel>
            <Select
              labelId='scope-label'
              id='scope-select'
              value={selectedScope}
              label='Scope'
              onChange={handleScopeChange}
            >
              {scopeOptions.map((scope) => (
                <MenuItem key={scope.value} value={scope.value}>
                  {scope.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth style={{ marginBottom: '16px' }}>
            <InputLabel id='genre-label'>Genre</InputLabel>
            <Select
              labelId='genre-label'
              id='genre-select'
              value={selectedGenre}
              label='Genre'
              onChange={handleGenreChange}
            >
              {genreOptions.map((genre) => (
                <MenuItem key={genre.value} value={genre.value}>
                  {genre.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Grid container spacing={2} style={{ marginBottom: '16px' }}>
            <Grid item xs={4}>
              <FormControlLabel
                style={{ marginBottom: '16px' }}
                control={<Switch checked={status === 'published'} onChange={handleStatusChange} />}
                label={`Status: ${status === 'published' ? 'Published' : 'Draft'}`}
              />
            </Grid>
            <Grid item xs={4}></Grid>

            <Grid item xs={4}>
              <FormControlLabel
                style={{ marginBottom: '16px' }}
                control={
                  <Checkbox
                    checked={enabled}
                    onChange={(e) => handleEnabledChange(e.target.checked)}
                  />
                }
                label='Enabled'
              />
            </Grid>
          </Grid>

          <Grid item xs={6} style={{ marginBottom: '16px' }}>
            <Button variant='contained' color='primary' fullWidth onClick={handleSaveOrUpdate}>
              {componentID
                ? isPending
                  ? 'Updating...'
                  : 'Update'
                : isPending
                  ? 'Registering...'
                  : 'Register'}
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={6}></Grid>
      </Grid>
    </MainCard>
  )
}

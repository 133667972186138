import { ReactElement, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// project import
import config from 'config'
import useAuth from 'hooks/useAuth'

export type GuardProps = {
  children: ReactElement | null
  enabled?: boolean
}

const RedirectionController = ({ enabled, children }: GuardProps) => {
  const { isLoggedIn, user } = useAuth()
  const navigate = useNavigate()
  const defaultPath = user?.business_default_route ?? config.defaultPath

  const location = useLocation()

  const afterLoginLandingPageFromUrl = useMemo(() => {
    if (!enabled) return undefined
    if (!isLoggedIn) return undefined
    if (location.pathname === '/login' || location.pathname === '/') return undefined
    return location.pathname + location.search
    // Note: If you are working with this component, please fix react-hooks/exhaustive-deps warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, isLoggedIn])

  const landingRoute = useMemo(() => {
    if (!enabled) return undefined
    switch (isLoggedIn) {
      case true: {
        return afterLoginLandingPageFromUrl ?? defaultPath
      }
      case false: {
        return undefined
      }
    }
    // Note: If you are working with this component, please fix react-hooks/exhaustive-deps warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, enabled, defaultPath])

  useEffect(() => {
    if (landingRoute == null) return
    navigate(landingRoute, { replace: true })
    // Note: If you are working with this component, please fix react-hooks/exhaustive-deps warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingRoute])

  return children
}

export default RedirectionController

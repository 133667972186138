import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material'

import useDelayedUpdate from '@hooks/use-delayed-update'

import { useSelector } from '@store/index'
import { useDispatch } from '@store/index'
import {
  selectSettings,
  updateSettingsData,
  updateYAxisData
} from '@store/slices/component/chart-config'

import MuiSelect from 'components/form/mui-select'

import { IAxis, TooltipHeadingType, TooltipTitleType } from '../types/chart-builder-types'

export function DefaultTooltipSettings() {
  const settings = useSelector(selectSettings)
  const dispatch = useDispatch()

  const [headingCustom, setHeadingCustom] = useDelayedUpdate(
    settings.tooltip_heading_custom || '',
    (value) => dispatch(updateSettingsData({ tooltip_heading_custom: value }))
  )
  const [titleCustom, setTitleCustom] = useDelayedUpdate(
    settings.tooltip_title_custom || '',
    (value) => dispatch(updateSettingsData({ tooltip_title_custom: value }))
  )

  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h5'>Tooltip</Typography>
        <Typography variant='details' gutterBottom>
          Configure the tooltip settings
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={settings.tooltipFreeFloating || false}
              onChange={(e) => {
                dispatch(updateSettingsData({ tooltipFreeFloating: e.target.checked }))
              }}
            />
          }
          label='Free-floating Tooltip'
        />
      </Grid>
      <Grid item xs={12}>
        <MuiSelect
          options={[
            { label: 'From Base Axis', value: TooltipHeadingType.FromBaseAxis },
            { label: 'Hidden', value: TooltipHeadingType.Hidden },
            { label: 'Custom', value: TooltipHeadingType.Custom }
          ]}
          value={settings.tooltip_heading_type || TooltipHeadingType.FromBaseAxis}
          onChange={(e: SelectChangeEvent<string>) => {
            dispatch(
              updateSettingsData({ tooltip_heading_type: e.target.value as TooltipHeadingType })
            )
          }}
          label='Heading Label'
        />
      </Grid>
      {settings.tooltip_heading_type === TooltipHeadingType.Custom && (
        <Grid item xs={12}>
          <TextField
            value={headingCustom}
            onChange={(e) => setHeadingCustom(e.target.value)}
            fullWidth
            variant='outlined'
            label='Custom Heading Text'
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <MuiSelect
          options={[
            { label: 'From Series', value: TooltipTitleType.FromSeries },
            { label: 'From Base Axis', value: TooltipTitleType.FromBaseAxis },
            { label: 'Custom', value: TooltipTitleType.Custom }
          ]}
          value={settings.tooltip_title_type || TooltipTitleType.FromSeries}
          onChange={(e: SelectChangeEvent<string>) => {
            dispatch(updateSettingsData({ tooltip_title_type: e.target.value as TooltipTitleType }))
          }}
          label='Title Label'
        />
      </Grid>
      {settings.tooltip_title_type === TooltipTitleType.Custom && (
        <Grid item xs={12}>
          <TextField
            value={titleCustom}
            onChange={(e) => setTitleCustom(e.target.value)}
            fullWidth
            variant='outlined'
            label='Custom Title Text'
          />
        </Grid>
      )}
    </>
  )
}

export function AxisTooltipSettings({ selected }: { selected: IAxis }) {
  const dispatch = useDispatch()

  const {
    id,
    tooltip_override = false,
    tooltip_title_type = TooltipTitleType.FromSeries,
    tooltip_title_custom
  } = selected

  const [titleCustom, setTitleCustom] = useDelayedUpdate(
    tooltip_title_custom || '',
    (value: string) => dispatch(updateYAxisData({ id, tooltip_title_custom: value }))
  )

  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h5'>Tooltip</Typography>
        <Typography variant='details' gutterBottom>
          Configure the tooltip settings
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={tooltip_override}
              onChange={(e) => {
                dispatch(updateYAxisData({ id, tooltip_override: e.target.checked }))
              }}
            />
          }
          label='Override Tooltip Settings'
        />
      </Grid>
      {tooltip_override && (
        <>
          <Grid item xs={12}>
            <MuiSelect
              options={[
                { label: 'From Series', value: TooltipTitleType.FromSeries },
                { label: 'From Base Axis', value: TooltipTitleType.FromBaseAxis },
                { label: 'Custom', value: TooltipTitleType.Custom }
              ]}
              value={tooltip_title_type}
              onChange={(e: SelectChangeEvent<string>) => {
                dispatch(
                  updateYAxisData({ id, tooltip_title_type: e.target.value as TooltipTitleType })
                )
              }}
              label='Title Label'
            />
          </Grid>
          {tooltip_title_type === TooltipTitleType.Custom && (
            <Grid item xs={12}>
              <TextField
                value={titleCustom}
                onChange={(e) => setTitleCustom(e.target.value)}
                fullWidth
                variant='outlined'
                label='Custom Title Text'
              />
            </Grid>
          )}
        </>
      )}
    </>
  )
}

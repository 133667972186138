import { CellClassParams, ColDef, ValueGetterParams } from 'ag-grid-community'

import { FinancialHierarchyNode } from '@components/financial/types'
import { resolveNodeFormatting } from '@components/financial/utils/formatting-utils'
import { isSpacerNode } from '@components/financial/utils/node-utils'

export const nodeLabelGroupColDef: ColDef = {
  field: 'label',
  headerName: '',
  cellRenderer: 'agGroupCellRenderer',
  showRowGroup: true,
  pinned: true,
  minWidth: 60,
  resizable: true,
  valueGetter: (params: ValueGetterParams) => {
    const node = params.node!

    if (isSpacerNode(node)) {
      return ''
    }
    return _.get(node.data, 'label')
  },
  cellRendererParams: {
    suppressCount: true
  },
  cellStyle: (params: CellClassParams) => {
    const data = params.node.data as FinancialHierarchyNode
    const styles = resolveNodeFormatting(data)
    return { fontWeight: styles.fontWeight }
  },
  filter: 'agTextColumnFilter'
}

import { useCallback, useMemo } from 'react'
import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector
} from 'react-redux'

import { configureStore } from '@reduxjs/toolkit'

import useRouteBasedID from '@hooks/useRouteBasedID'

import LogRocket from 'logrocket'
import logger from 'redux-logger'

import { persist } from './middleware/persist'
import { PageActionPayload } from './page-util'
import reducers from './reducers'

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(persist, logger, LogRocket.reduxMiddleware())
})

export type RootState = ReturnType<typeof reducers>

export type AppDispatch = typeof store.dispatch

const { dispatch } = store

const useDispatch = () => useAppDispatch<AppDispatch>()

const useSelector: TypedUseSelectorHook<RootState> = useAppSelector

const usePageSelector = <T>(selector: (id: string) => (state: RootState) => T): T => {
  const id = useRouteBasedID()
  const selectorMemoized = useMemo(() => selector(id), [id, selector])
  return useSelector(selectorMemoized)
}

const usePageDispatch = () => {
  const id = useRouteBasedID()
  const dispatch = useDispatch()
  return useCallback(
    (pageActionPayload: PageActionPayload) => dispatch(pageActionPayload(id)),
    [dispatch, id]
  )
}

export { store, dispatch, useSelector, useDispatch, usePageSelector, usePageDispatch }

import { cn } from '@utils/style-utils'

import { Switch } from '@components/core/switch'
import { Text } from '@components/core/text'

import { useDispatch } from '@store/index'
import { updateColumn } from '@store/slices/component/table-config'

import { ITableColumn, isCellDataTypeNumber } from '../../types/table-builder-types'

export const Heatmap = ({ item: column }: { item: ITableColumn }) => {
  const dispatch = useDispatch()

  const disabled = !isCellDataTypeNumber(column.cellDataType)
  const checked = !!column.customData?.heatmap

  return (
    <div className='flex flex-col gap-2'>
      <Switch
        disabled={disabled}
        checked={checked}
        onCheckedChange={(value) =>
          dispatch(updateColumn({ field: column.field, customData: { heatmap: value } }))
        }
      />
      {checked && (
        <>
          <Text variant='details' className='not-italic text-primary-darker'>
            Heatmaps are bucketed by Standard Deviations from the Mean, with each Row calculated
            independently
          </Text>
          <ColorScale />
        </>
      )}
    </div>
  )
}

const ColorScale = () => {
  return (
    <div className='flex flex-col items-center rounded border border-solid border-grey-lighter p-2 pb-6'>
      <div className='flex w-full'>
        {_.range(-3, 4).map((num) => (
          <div
            key={num}
            className={cn('relative h-4 w-6', {
              'bg-heatmap-divergent-neg3': _.eq(num, -3),
              'bg-heatmap-divergent-neg2': _.eq(num, -2),
              'bg-heatmap-divergent-neg1': _.eq(num, -1),
              'bg-heatmap-divergent': _.eq(num, 0),
              'bg-heatmap-divergent-pos1': _.eq(num, 1),
              'bg-heatmap-divergent-pos2': _.eq(num, 2),
              'bg-heatmap-divergent-pos3': _.eq(num, 3)
            })}
          >
            {num !== 0 && (
              <Text
                variant='details'
                className={cn(
                  'absolute -bottom-5 not-italic text-primary-darker',
                  _.gt(num, 0) ? 'left-0 -translate-x-1' : 'right-0 translate-x-1',
                  _.eq(num, -1) && 'translate-x-0.5',
                  _.eq(num, 1) && '-translate-x-0.5'
                )}
              >
                {num}
              </Text>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

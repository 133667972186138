import React, { useState } from 'react'

import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'

interface SearchBoxProps {
  searchTerm: string
  onSearchChange: (searchTerm: string) => void
  onClearSearch: () => void
}

const SearchBox: React.FC<SearchBoxProps> = ({ searchTerm, onSearchChange, onClearSearch }) => {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <TextField
      className='search-box'
      autoComplete='off'
      variant='outlined'
      placeholder='Search'
      fullWidth
      margin='dense'
      value={searchTerm}
      onChange={(e) => onSearchChange(e.target.value)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      InputProps={{
        startAdornment: !isFocused && (
          <SearchIcon
            style={{ fontSize: '24px', margin: '0', padding: 0, color: 'rgba(255, 255, 255, 0.5)' }}
          />
        ),
        endAdornment: searchTerm && (
          <IconButton
            size='actionBar'
            onClick={onClearSearch}
            edge='end'
            aria-label='clear search'
            sx={{
              border: 'none',
              padding: 0,
              color: 'rgba(255, 255, 255, 0.5)',
              '&:hover': {
                bgcolor: '#2170BA',
                color: 'white',
                border: 'none'
              },
              '&:focus': {
                border: 'none',
                bgcolor: '#2170BA'
              }
            }}
          >
            <ClearIcon />
          </IconButton>
        ),
        sx: {
          marginTop: '0',
          paddingLeft: '8px',
          color: 'white',
          height: '32px',
          border: '1px solid rgba(255, 255, 255, 0.5)',
          '& input': {
            paddingLeft: '8px'
          },
          '& fieldset': {
            border: 'none !important'
          },
          '&.Mui-focused': {
            border: '1px solid white'
          }
        }
      }}
      sx={{
        mb: 1,
        border: 'none'
      }}
    />
  )
}

export default SearchBox

import React, { useEffect, useState } from 'react'

import { Typography } from '@mui/material'
import Menu from '@mui/material/Menu'
import { Box, Stack } from '@mui/system'

import { getAllowedRange } from '@utils/date-filter-utils'

import { IconButton } from '@components/core/icon-button'
import { ChevronLeft, ChevronRight, Icon } from '@components/icons'

import DropDownButton, { useAnchor } from 'components/DropDownButton'
import { Frequency, YearMonthDayInfo } from 'types/filter'
import {
  Day,
  Month,
  getFiscalYear,
  getMonthFromIndex,
  getNextMonthInfo,
  getPreviousMonthInfo,
  getWeekDays
} from 'utils/date-utils'

import { GridMenuButton } from '../grid-render-helper'
import YearSelectView from '../year-select-view'
import { clampMonthMax, getCalendarOptions, navigationClamp, outOfBounds } from './utils'
import { maxInfo, minInfo } from './utils'

interface Props {
  value?: YearMonthDayInfo
  minAllowed?: YearMonthDayInfo
  maxAllowed?: YearMonthDayInfo
  fiscalYearStart?: Month
  onChange: (value: YearMonthDayInfo) => void
  render?: (value: YearMonthDayInfo) => React.ReactNode
}

// NOTE: Existing UI does not use a DayPicker
// This is a port from legacy component, so that the different utils does not break,
// When we need to use DayPicker, refactor to use ShadCn and test the useability of this
const DayPicker = (props: Props) => {
  const { fiscalYearStart, onChange, render } = props

  const { anchorEl, open, onClick, onClose } = useAnchor()

  const [view, setView] = useState<'year' | 'month'>('month')

  useEffect(() => {
    if (open) {
      setView('month')
    }
  }, [open])

  const value = props.value ?? {
    year: getFiscalYear(new Date(), fiscalYearStart),
    month: getMonthFromIndex(new Date().getMonth()),
    day: new Date().getDate()
  }

  const allowedRange = getAllowedRange(fiscalYearStart, Frequency.Daily)
  const minAllowed = maxInfo(props.minAllowed, allowedRange.minAllowed, fiscalYearStart)

  const maxAllowed = minInfo(props.maxAllowed, allowedRange.maxAllowed, fiscalYearStart)

  const numColumns = 7
  // const numRows = 6;

  const weekStart = Day.Mon

  const options = getCalendarOptions(value.year, value.month, weekStart, fiscalYearStart)

  const nextMonthInfo = getNextMonthInfo(value.year, value.month, fiscalYearStart)
  const nextVal = navigationClamp(
    clampMonthMax(
      {
        year: nextMonthInfo.year,
        month: nextMonthInfo.month,
        day: value.day
      },
      fiscalYearStart
    ),
    'month',
    fiscalYearStart,
    minAllowed,
    maxAllowed
  )

  const previousMonthInfo = getPreviousMonthInfo(value.year, value.month, fiscalYearStart)
  const prevVal = navigationClamp(
    clampMonthMax(
      {
        year: previousMonthInfo.year,
        month: previousMonthInfo.month,
        day: value.day
      },
      fiscalYearStart
    ),
    'month',
    fiscalYearStart,
    minAllowed,
    maxAllowed
  )

  const shouldDisable = (val: YearMonthDayInfo) =>
    outOfBounds(val, minAllowed, maxAllowed, fiscalYearStart)

  const yearSelectVal = (year: number) =>
    navigationClamp(
      clampMonthMax({ year, month: value!.month, day: value!.day }, fiscalYearStart),
      'year',
      fiscalYearStart,
      minAllowed,
      maxAllowed
    )

  const handleNextMonth = () => {
    handleValueChange(nextVal)
  }

  const handlePrevMonth = () => {
    handleValueChange(prevVal)
  }

  // also handle day overflow from month end
  const handleValueChange = (value: YearMonthDayInfo) => {
    onChange(clampMonthMax(value))
  }

  const toggleView = () => setView(view === 'month' ? 'year' : 'month')

  return (
    <div>
      <DropDownButton onClick={onClick} open={open}>
        {!value ? '' : render ? render(value) : `${value.month} ${value.day}, ${value.year}`}
      </DropDownButton>
      <Menu
        MenuListProps={{
          sx: {
            padding: '1rem'
          }
        }}
        slotProps={{
          paper: {
            sx: {
              borderTopLeftRadius: 0
            }
          }
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        <Stack>
          <Box
            style={{
              display: 'grid',
              alignItems: 'center',
              gridTemplateColumns: 'auto 1fr auto',
              cursor: 'pointer'
            }}
          >
            <IconButton disabled={!prevVal || shouldDisable(prevVal)} onClick={handlePrevMonth}>
              <Icon icon={<ChevronLeft />} />
            </IconButton>
            <Box display='flex' justifyContent='space-around' onClick={toggleView}>
              <Typography color='primary' variant='button' textAlign='center'>
                {value?.month}
                {fiscalYearStart ? `, FY ${value?.year}` : ' ' + value?.year}
              </Typography>
            </Box>
            <IconButton disabled={!nextVal || shouldDisable(nextVal)} onClick={handleNextMonth}>
              <Icon icon={<ChevronRight />} />
            </IconButton>
          </Box>
          {view === 'month' ? (
            <Box
              style={{
                display: 'grid',
                gridTemplateColumns: `repeat(${numColumns}, 1fr`,
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center'
              }}
            >
              {getWeekDays(weekStart).map((day) => (
                <Box p='0.5rem 0' key={day}>
                  <Typography textTransform='capitalize' color='primary' variant='button'>
                    {day.slice(0, 2).toLowerCase()}
                  </Typography>
                </Box>
              ))}
              {options.map((info, idx) => {
                // const row = Math.floor(idx / numColumns);
                // const col = idx % numColumns;
                const day = info.day
                const key = info.month + '/' + day
                const date = info
                return (
                  <GridMenuButton
                    key={key}
                    selected={day === value.day && info.month === value.month}
                    disabled={shouldDisable(date)}
                    onClick={() => {
                      handleValueChange(date)
                      onClose()
                    }}
                  >
                    {day}
                  </GridMenuButton>
                )
              })}
            </Box>
          ) : (
            <YearSelectView
              fiscalYearStart={fiscalYearStart}
              year={value?.year}
              disabled={(year) => shouldDisable(yearSelectVal(year))}
              onChange={(year) => {
                handleValueChange(yearSelectVal(year))
                setView('month')
              }}
            />
          )}
        </Stack>
      </Menu>
    </div>
  )
}

export default DayPicker

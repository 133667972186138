import { Checkbox, Divider, FormControlLabel, Grid, Typography } from '@mui/material'

import { useDispatch } from '@store/index'
import { useSelector } from '@store/index'
import {
  selectSettings,
  updateSettingsData,
  updateYAxisData
} from '@store/slices/component/chart-config'

import { IAxis, IChartSettings } from '../types/chart-builder-types'

export function DefaultDataLabelsSettings() {
  const dispatch = useDispatch()
  const settings = useSelector(selectSettings)

  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h5'>Data Labels</Typography>
        <Typography variant='details' gutterBottom>
          Select Data Labels Settings
        </Typography>
        <Divider />
      </Grid>
      <SharedDataLabelsForm
        show_data_labels={settings.show_data_labels}
        data_labels_hook={settings.data_labels_hook}
        handleChange={(payload) => dispatch(updateSettingsData(payload))}
      />
    </>
  )
}

export function AxisDataLabelsSettings({ selected }: { selected: IAxis }) {
  const dispatch = useDispatch()

  const { id, data_labels_override = false } = selected

  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h5'>Data Labels</Typography>
        <Typography variant='details' gutterBottom>
          Select Data Labels Settings
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={data_labels_override}
              onChange={(e) => {
                dispatch(updateYAxisData({ id, data_labels_override: e.target.checked }))
              }}
            />
          }
          label='Override Data Labels Settings'
        />
      </Grid>
      {data_labels_override && (
        <SharedDataLabelsForm
          show_data_labels={selected.show_data_labels}
          data_labels_hook={selected.data_labels_hook}
          handleChange={(payload) => dispatch(updateYAxisData(_.assign({ id }, payload)))}
        />
      )}
    </>
  )
}

function SharedDataLabelsForm({
  show_data_labels = false,
  data_labels_hook = false,
  handleChange
}: {
  show_data_labels?: boolean
  data_labels_hook?: boolean
  handleChange: (p: Partial<IChartSettings | IAxis>) => void
}) {
  return (
    <>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={show_data_labels || false}
              onChange={(e) => {
                handleChange({ show_data_labels: e.target.checked })
              }}
            />
          }
          label='Show Data Labels'
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={data_labels_hook || false}
              onChange={(e) => {
                handleChange({ data_labels_hook: e.target.checked })
              }}
            />
          }
          label='Data Labels Hook'
        />
      </Grid>
    </>
  )
}

import { PayloadAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'

import { WithID, createPageAction } from '@store/page-util'

import { EmptySelectedDimensions, SelectedDimensions } from '../../components/control-panel/types'
import { rehydrateStore } from '../actions/rehydrate-store'
import { RootState } from '../index'

export interface DimensionObject {
  selectedDimensions: SelectedDimensions
}

const dimensionAdapter = createEntityAdapter<WithID<DimensionObject>>()

const initialState = dimensionAdapter.getInitialState({
  entities: {},
  ids: []
})

type DimensionProps = { dimensionType: string; items: string[] }

const dimensionFilterSlice = createSlice({
  name: '@DIMENSIONS',
  initialState: initialState,
  reducers: {
    addDimension: (state, action: PayloadAction<WithID<DimensionProps>>) => {
      const { id, dimensionType, items } = action.payload

      let entity = state.entities[id]

      if (!entity) {
        entity = { id, selectedDimensions: EmptySelectedDimensions }
        dimensionAdapter.addOne(state, entity)
      }

      if (entity) {
        const updatedSelectedDimensions = {
          ...entity.selectedDimensions,
          // @ts-ignore
          [dimensionType]: [...new Set([...entity.selectedDimensions[dimensionType], ...items])]
        }

        dimensionAdapter.updateOne(state, {
          id,
          changes: { selectedDimensions: updatedSelectedDimensions }
        })
      }
    },
    removeDimension: (state, action: PayloadAction<WithID<DimensionProps>>) => {
      const { id, dimensionType, items } = action.payload

      const entity = state.entities[id]

      if (!entity) {
        return
      }

      const updatedSelectedDimensions = {
        ...entity.selectedDimensions,
        // @ts-ignore
        [dimensionType]: entity.selectedDimensions[dimensionType].filter(
          // @ts-ignore
          (item) => !items.includes(item)
        )
      }

      dimensionAdapter.updateOne(state, {
        id,
        changes: { selectedDimensions: updatedSelectedDimensions }
      })
    },
    replaceDimensions: (state, action: PayloadAction<WithID<DimensionProps>>) => {
      const { id, dimensionType, items } = action.payload

      let entity = state.entities[id]

      if (!entity) {
        entity = { id, selectedDimensions: EmptySelectedDimensions }
        dimensionAdapter.addOne(state, entity)
      }

      if (entity) {
        const updatedSelectedDimensions = {
          ...entity.selectedDimensions,
          // @ts-ignore
          [dimensionType]: [...new Set([...items])]
        }
        dimensionAdapter.updateOne(state, {
          id,
          changes: { selectedDimensions: updatedSelectedDimensions }
        })
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrateStore, (_state, action) => {
      return { ...initialState, ...action.payload.dimensions }
    })
  }
})

// actions
export const addDimension = createPageAction(dimensionFilterSlice.actions.addDimension)
export const removeDimension = createPageAction(dimensionFilterSlice.actions.removeDimension)
export const replaceDimensionsAction = createPageAction(
  dimensionFilterSlice.actions.replaceDimensions
)

// reducers
export const dimensionFilterReducer = dimensionFilterSlice.reducer

// selectors
const getDimensionsState = (id: string) => (state: RootState) =>
  _.get(state, ['dimensions', 'entities', id])

export const selectDimensions = (id: string) =>
  createSelector(getDimensionsState(id), (dimensionsState) =>
    _.get(dimensionsState, ['selectedDimensions'], EmptySelectedDimensions)
  )

import { Frequency } from 'types/filter'

import { DateTruncationType } from '../types/component-types'

export function frequencyToDateTrunc(frequency: Frequency): DateTruncationType {
  switch (frequency) {
    case Frequency.Weekly:
      return DateTruncationType.WEEK
    case Frequency.Quarterly:
      return DateTruncationType.QUARTER
    case Frequency.Yearly:
      return DateTruncationType.YEAR
    case Frequency.Daily:
      return DateTruncationType.DAY
    case Frequency.Monthly:
    default:
      return DateTruncationType.MONTH
  }
}

export function dateTruncationToFrequency(dateTruncation: DateTruncationType): Frequency {
  switch (dateTruncation) {
    case DateTruncationType.WEEK:
      return Frequency.Weekly
    case DateTruncationType.QUARTER:
      return Frequency.Quarterly
    case DateTruncationType.YEAR:
      return Frequency.Yearly
    case DateTruncationType.DAY:
      return Frequency.Daily
    case DateTruncationType.MONTH:
    default:
      return Frequency.Monthly
  }
}

import { DataPanel } from '@components/component-management/DataPanel'

import { Wrapper } from '../common/wrapper'
import { PreviewPanelBody } from '../data-panel/preview-panel-body'
import { AddFormulaPopover } from '../data-panel/preview-panel-body/formula-form'
import { DataPreviewPanel } from './data-preview-panel'

export function DataPreview() {
  return (
    <Wrapper>
      <div className='flex size-full gap-4'>
        <DataPreviewPanel />
        <DataPanel controls={<AddFormulaPopover />}>
          <PreviewPanelBody />
        </DataPanel>
      </div>
    </Wrapper>
  )
}

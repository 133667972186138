'use client'

import { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { usePageDispatch } from '@store/index'
import { setDisplayExpansionControls } from '@store/slices/action-bar'

import { LogGroup, log, logError } from 'store/actions/log'
import { updateDateFilter } from 'store/slices/date-time-filter'
import { DateFilterInfo, DateRangeType, Frequency, FrequencyAbbreviated } from 'types/filter'
import { handleFrequencyChange } from 'utils/date-frequency-utils'
import { Month } from 'utils/date-utils'

import { ToggleGroup, ToggleGroupItem } from '../core/toggle-group'
import Tooltip from '../core/tooltip'
import { BaseFilterProps } from './types'

interface DateFilterFrequencyQuickSelectProps extends BaseFilterProps {
  frequenciesToOmit?: Frequency[]
  dateFilterInfo?: DateFilterInfo
  fiscalYearStart?: Month
  onApply?: (dateFilter: DateFilterInfo) => void // Ensuring onApply is typed correctly
}

const DateFilterFrequencyQuickSelect = (props: DateFilterFrequencyQuickSelectProps) => {
  const dispatch = useDispatch()
  const pageDispatch = usePageDispatch()
  const dateFilter = props.dateFilterInfo
  const selectedFrequency = useMemo(() => dateFilter?.frequency, [dateFilter])
  const frequenciesToOmit = props.frequenciesToOmit ?? [Frequency.Daily, Frequency.Weekly]
  const handleToggleSelection = (frequency: Frequency) => {
    if (!dateFilter) {
      dispatch(
        logError({
          group: LogGroup.DATE_FILTER,
          message: 'Date Filter Info Missing',
          tags: { component: 'DateFilterFrequencyQuickSelect', props }
        })
      )
      return
    }

    // Check for the existing frequency to ensure it's not undefined
    const currentFrequency = dateFilter.frequency
    if (currentFrequency === undefined) {
      return
    }

    // Ensure that the frequency change is meaningful
    if (_.isEqual(frequency, currentFrequency)) {
      dispatch(
        log({
          group: LogGroup.DATE_FILTER,
          message: 'Selected frequency is the same as current',
          tags: { component: 'DateFilterFrequencyQuickSelect', props }
        })
      )
      return
    }

    const fiscalYearStart = props.fiscalYearStart || dateFilter.fiscalYearStart

    // Prepare parameters based on the rangeType
    let oldRange
    if (
      _.isEqual(dateFilter.rangeType, DateRangeType.Range) &&
      'startDate' in dateFilter &&
      'endDate' in dateFilter
    ) {
      oldRange = {
        startDate: dateFilter.startDate,
        endDate: dateFilter.endDate,
        frequency: currentFrequency
      }
    } else if (_.isEqual(dateFilter.rangeType, DateRangeType.AsOfDate) && 'endDate' in dateFilter) {
      // Assuming that for asOfDate type, the start and end dates are the same
      oldRange = {
        startDate: dateFilter.endDate,
        endDate: dateFilter.endDate,
        frequency: currentFrequency
      }
    } else {
      dispatch(
        log({
          group: LogGroup.DATE_FILTER,
          message: 'DateFilterInfo state is invalid for frequency change.',
          tags: { component: 'DateFilterFrequencyQuickSelect', props }
        })
      )
      return
    }

    // Handling frequency change with proper error checks
    try {
      const newRange = handleFrequencyChange(oldRange, frequency, {}, fiscalYearStart)

      const updatedDateFilter: DateFilterInfo = {
        ...dateFilter,
        ...newRange,
        frequency,
        fiscalYearStart,
        rangeType: DateRangeType.Range
      }

      pageDispatch(updateDateFilter(updatedDateFilter))
      pageDispatch(setDisplayExpansionControls({ displayExpansionControls: false }))

      if (props.onApply) {
        props.onApply(updatedDateFilter)
      }
    } catch (error: any) {
      dispatch(
        logError({
          group: LogGroup.DATE_FILTER,
          message: `Failed to change frequency: ${error.message}`,
          tags: { component: 'DateFilterFrequencyQuickSelect', props, error }
        })
      )
    }
  }

  const frequencyItems = Object.values(Frequency)
    .filter((frequency) => !frequenciesToOmit.includes(frequency))
    .map((frequency) => (
      <Tooltip key={frequency} title={frequency}>
        <ToggleGroupItem
          value={frequency}
          selected={_.isEqual(selectedFrequency, frequency)}
          variant='primary'
          className='h-8 w-[26px]'
        >
          {FrequencyAbbreviated[frequency]}
        </ToggleGroupItem>
      </Tooltip>
    ))

  return (
    <ToggleGroup type='single' value={selectedFrequency} onValueChange={handleToggleSelection}>
      {frequencyItems}
    </ToggleGroup>
  )
}

export default DateFilterFrequencyQuickSelect

import {
  ChartErrorWidget,
  ChartLoadingWidget,
  ChartNoDataWidget
} from 'components/chart/chart-container'
import useChangeDetection from 'hooks/use-change-detection'

import useChartPropsRetriever from '../chart-builder/useChartPropsRetriever'
import DynamicChart from '../common/dynamic-chart'
import { IChartConfig } from '../types/chart-builder-types'
import { ComponentPreviewProps } from './types'

function ChartContent(props: Pick<Required<ComponentPreviewProps>, 'isFetching' | 'data'>) {
  const { data, isFetching: refetching } = props
  const { title, config, metadata, results, drilldowns } = data
  const [chartProps, chartError] = useChartPropsRetriever({
    data: results,
    metadata,
    config: config as IChartConfig
  })!
  const [chartKey, triggerReinit] = useChangeDetection()

  if (chartError) {
    return <ChartErrorWidget title={title} errorMessage={chartError} />
  }

  if (!chartProps) {
    return <ChartLoadingWidget title={title} />
  }

  return (
    <DynamicChart
      key={chartKey}
      {...{
        ...chartProps,
        title,
        refetching,
        triggerReinit,
        drilldowns
      }}
    />
  )
}

const Chart = ({ loading, errorMessage, isFetching, title, data }: ComponentPreviewProps) => {
  const noResults = _.isEqual(_.size(_.get(data, 'results', [])), 0)

  if (loading) {
    return <ChartLoadingWidget title={title} />
  }

  if (errorMessage) {
    return <ChartErrorWidget title={title} errorMessage={errorMessage} />
  }

  if (!data || noResults) {
    return <ChartNoDataWidget title={title} />
  }

  return <ChartContent {...{ data, isFetching }} />
}

export default Chart

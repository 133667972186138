import { Box } from '@mui/material'

import { FinancialReportType } from '@components/financial/types'
import { ChargerFormFieldConfig } from '@components/form/types'

import ControlComponent from './control-component'
import { FilterDisplayModeEnum } from './types'

interface FlyOverMenuProps {
  filters: any
  financialReportType?: FinancialReportType
  exporterFieldsConfig?: ChargerFormFieldConfig[]
  backendExportHandler?: (data: any, exportFilters: any) => void
}

const FlyOverMenu = ({
  filters,
  financialReportType,
  exporterFieldsConfig,
  backendExportHandler
}: FlyOverMenuProps) => {
  return filters.map((filterName: any) => {
    return (
      <Box sx={{ padding: '16px', minWidth: 340 }}>
        <ControlComponent
          key={filterName}
          name={filterName}
          shouldRender={true}
          componentProps={{
            filter: filters[filterName],
            filterDisplayMode: FilterDisplayModeEnum.list,
            financialReportType: financialReportType,
            exporterFieldsConfig: exporterFieldsConfig,
            backendExportHandler: backendExportHandler
          }}
        />
      </Box>
    )
  })
}

export default FlyOverMenu

import { useMemo } from 'react'

import { SQLEditor } from '@components/code-editors'
import { Skeleton } from '@components/core/skeleton'

import { LogGroup, logError } from '@store/actions/log'
import { useDispatch } from '@store/index'

import { format as formatSql } from 'sql-formatter'

import { useQueryResultContext } from '../contexts/query-result-context'

export function SQLViewer() {
  const dispatch = useDispatch()
  const { metadata, isFetching } = useQueryResultContext()

  const sqlQuery = useMemo(() => {
    if (!metadata?.sql) return null

    try {
      return formatSql((metadata?.sql || '').replace('\\"', '"'), { language: 'postgresql' })
    } catch (error) {
      dispatch(
        logError({
          group: LogGroup.COMPONENT_MANAGEMENT,
          message: 'Error formatting SQL query',
          tags: { type: 'FORMAT_SQL', error: (error as Error)?.message || 'Unknown error' }
        })
      )
    }
  }, [metadata?.sql, dispatch])

  if (isFetching) {
    return (
      <div>
        {_.times(20, (n) => (
          <Skeleton key={n} className='h-6 w-full' />
        ))}
      </div>
    )
  }

  return (
    <div>
      <SQLEditor value={sqlQuery || '-- SQL Query Not Found'} readOnly />
    </div>
  )
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { CurrentPage, NavItemType, RightDrawerType, RightDrawerTypeEnum } from '../../types/menu'
import { rehydrateStore } from '../actions/rehydrate-store'
import { RootState } from '../index'

interface NavigationState {
  currentMenuItem: NavItemType
  drawerOpen: boolean
  headerCollapsed: boolean
  rightDrawerOpen: boolean
  rightDrawerType: RightDrawerType
  currentPage: CurrentPage
}

const initialState: NavigationState = {
  currentMenuItem: { title: 'dashboard' },
  currentPage: null,
  drawerOpen: true,
  headerCollapsed: false,
  rightDrawerOpen: false,
  rightDrawerType: RightDrawerTypeEnum.dimensions
}

const navigationSlice = createSlice({
  name: '@NAVIGATION',
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.drawerOpen = !state.drawerOpen
    },
    openDrawer: (state) => {
      state.drawerOpen = true
    },
    closeDrawer: (state) => {
      state.drawerOpen = false
    },
    updateCurrentPage: (state, action: PayloadAction<CurrentPage>) => {
      state.currentPage = action.payload
    },
    updateCurrentMenuItem: (state, action: PayloadAction<NavItemType>) => {
      state.currentMenuItem = action.payload as any
    },
    toggleRightDrawer: (state) => {
      state.rightDrawerOpen = !state.rightDrawerOpen
    },
    toggleHeaderCollapse: (state) => {
      state.headerCollapsed = !state.headerCollapsed
    },
    openRightDrawer: (state) => {
      state.rightDrawerOpen = true
    },
    closeRightDrawer: (state) => {
      state.rightDrawerOpen = false
    },
    updateRightDrawerType: (state, action: PayloadAction<RightDrawerType>) => {
      state.rightDrawerType = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrateStore, (state, action) => {
      if (!action.payload.navigation) return

      state.drawerOpen = action.payload.navigation.drawerOpen
    })
  }
})

// Actions
export const {
  toggleDrawer,
  closeDrawer,
  openDrawer,
  toggleHeaderCollapse,
  updateCurrentPage,
  updateCurrentMenuItem,
  toggleRightDrawer,
  openRightDrawer,
  closeRightDrawer,
  updateRightDrawerType
} = navigationSlice.actions

// Reducer
export const navigationReducer = navigationSlice.reducer

// Selectors
export const selectCurrentMenuItem = (state: RootState) => state.navigation.currentMenuItem

export const selectDrawerOpen = (state: RootState) => state.navigation.drawerOpen

export const selectCurrentPage = (state: RootState) => state.navigation.currentPage

export const selectRightDrawerOpen = (state: RootState) => state.navigation.rightDrawerOpen

export const selectHeaderCollapsed = (state: RootState) => state.navigation.headerCollapsed

import { forwardRef, useEffect, useImperativeHandle } from 'react'

import { Box, Grid, Typography } from '@mui/material'

import { useDispatch } from '@store/index'
import { MODEL_DOES_NOT_EXIST, chooseModel } from '@store/slices/component/query-config'

import { useComponentAttrRef } from '../../hooks/use-component-attr-refs'
import { useFetchModelsDetail } from '../../queries/fetch-models-detail'
import type { IFieldSelectorHandles } from '../../types/query-builder-types'
import FieldsChooser from '../model-fields-selector/fields-chooser'
import SidebarContainer from './sidebar-container'

const FieldsSelector = forwardRef<IFieldSelectorHandles, { modelName: string }>(
  ({ modelName }, ref) => {
    const dispatch = useDispatch()
    const { queryDataRef: dataRef } = useComponentAttrRef('custom-field')
    const { data, isLoading, isSuccess } = useFetchModelsDetail(modelName)

    useEffect(() => {
      if (dataRef.current?.model === MODEL_DOES_NOT_EXIST) {
        dispatch(chooseModel({ modelName, placement: 'custom-field' }))
      }
    }, [dataRef, modelName, dispatch])

    useImperativeHandle(ref, () => ({
      getQueryData() {
        return dataRef.current
      }
    }))

    return (
      <Grid item xs={12} md={4}>
        <Box sx={{ width: '100%', height: '100%' }}>
          <SidebarContainer modelName={modelName}>
            {isLoading && <Typography variant='body1'>Loading...</Typography>}
            {isSuccess && (
              <FieldsChooser
                modelName={modelName}
                columns={data?.columns}
                associations={data?.associations}
                placement='custom-field'
              />
            )}
          </SidebarContainer>
        </Box>
      </Grid>
    )
  }
)

export default FieldsSelector

import { useMutation, useQueryClient } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'
import { errorSnack, successSnack } from 'utils/snackbar-utils'

import { IAPIErrorType, IComponentData } from '../types'

export const useUpdateComponent = (id?: number) => {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const business_id = user?.business_id!

  return useMutation({
    mutationFn: ({ body, id: idArg }: { body: Partial<IComponentData>; id?: number }) => {
      return axiosServices.put(`/businesses/${business_id}/components/${idArg || id}`, body)
    },
    onSuccess: ({ data }, variables) => {
      queryClient.setQueryData(['fetch-component', business_id, data.id], data)
      let successMessage = 'Component Updated Successfully'
      if (variables.body?.scope) {
        successMessage = 'Scope Updated Successfully'
      }
      successSnack(successMessage)
    },
    onError: (error: IAPIErrorType) => {
      let message = 'Error Updating Component'

      if (error?.error?.base) {
        message = error?.error?.base[0]
      }
      errorSnack(message)
    }
  })
}

import { IAxis } from '../types/chart-builder-types'
import { IHeader } from '../types/component-types'

export function convertHeaderToAxis(header: IHeader): IAxis {
  return _.merge(
    _.pick(header, ['name', 'type', 'data_type', 'previous_period', 'formula', 'is_hidden']),
    {
      id: header.name,
      label: header.name
    }
  )
}

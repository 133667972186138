import { useEffect, useState } from 'react'

export const pageOnline = async () => {
  try {
    await fetch('/', {
      method: 'HEAD'
    })
    return true
  } catch {
    return false
  }
}

export const useFrontEndServerStatus = () => {
  const [info, setInfo] = useState<{
    loading: boolean
    online?: boolean
  }>({
    loading: true
  })

  useEffect(() => {
    pageOnline().then((online) => {
      setInfo({
        loading: false,
        online
      })
    })
  }, [])

  return info
}

import React, { useEffect } from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { cn } from '@utils/style-utils'

import { DataPill, DataPillProps } from '@components/component-management/DataPill/DataPill'
import { DragIndicator, Icon } from '@components/icons'

import { getColumnField } from 'pages/component-management/table-visualize/column-organization/utils'

interface DraggableDataPillProps extends DataPillProps {
  id?: string
  currentActiveField?: string | null
  setDragOverPosition?: React.Dispatch<React.SetStateAction<number | null>>
  animateDragOver?: boolean
}

export function DraggableDataPill(props: DraggableDataPillProps) {
  const { id = '', currentActiveField, setDragOverPosition, animateDragOver = false } = props

  const {
    attributes,
    listeners,
    setNodeRef,
    transition,
    transform,
    index,
    isOver,
    isDragging,
    items
  } = useSortable({ id })

  const style = {
    transition,
    transform: animateDragOver ? CSS.Translate.toString(transform) : undefined,
    opacity: animateDragOver && isDragging ? 0.5 : undefined
  }

  useEffect(() => {
    if (setDragOverPosition && isOver) {
      const draggingIndex = _.findIndex(
        items,
        (item) => getColumnField(`${item}`) === currentActiveField
      )
      setDragOverPosition((draggingIndex <= index ? -1 : 1) * (index + 1))
    }
  }, [isOver, index, setDragOverPosition, items, currentActiveField])

  const dragActionProps = _.merge(attributes, listeners)

  return (
    <div ref={setNodeRef} className='flex items-center' style={style}>
      <div className='flex items-center' {...dragActionProps}>
        <Icon
          className={cn('cursor-move', props.disabled && 'cursor-not-allowed text-grey')}
          icon={<DragIndicator />}
        />
      </div>
      <DataPill {...props} />
    </div>
  )
}

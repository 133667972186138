import { getMonthsForFiscalYear } from '@utils/date-utils'

import { Month } from 'types/date'
import { YearMonthInfo } from 'types/filter'

export const cmpMonth = (month1: Month, month2: Month, fiscalYearStart?: Month) => {
  const months = getMonthsForFiscalYear(fiscalYearStart)
  return months.indexOf(month1) - months.indexOf(month2)
}

export const monthMin = (month1: Month, month2: Month, fiscalYearStart?: Month) =>
  cmpMonth(month1, month2, fiscalYearStart) < 0 ? month1 : month2

export const monthMax = (month1: Month, month2: Month, fiscalYearStart?: Month) =>
  cmpMonth(month1, month2, fiscalYearStart) > 0 ? month1 : month2

const cmpInfo = <T extends YearMonthInfo>(info1: T, info2: T, fiscalYearStart?: Month) => {
  if (info1.year !== info2.year) return info1.year - info2.year
  return cmpMonth(info1.month, info2.month, fiscalYearStart)
}

export const minInfo = <T extends YearMonthInfo>(info1?: T, info2?: T, fiscalYearStart?: Month) => {
  if (!info1) return info2
  if (!info2) return info1
  return cmpInfo(info1, info2, fiscalYearStart) < 0 ? info1 : info2
}

export const maxInfo = <T extends YearMonthInfo>(info1?: T, info2?: T, fiscalYearStart?: Month) => {
  if (!info1) return info2
  if (!info2) return info1
  return cmpInfo(info1, info2, fiscalYearStart) > 0 ? info1 : info2
}

export const outOfBounds = <T extends YearMonthInfo>(
  info: T,
  minAllowed?: T,
  maxAllowed?: T,
  fiscalYearStart?: Month
) =>
  (minAllowed != null && cmpInfo(info, minAllowed, fiscalYearStart) < 0) ||
  (maxAllowed != null && cmpInfo(info, maxAllowed, fiscalYearStart) > 0)

export const navigationClamp = <T extends YearMonthInfo>(
  info: T,
  navigationMode: 'year' | 'month',
  fiscalYearStart?: Month,
  minAllowed?: T,
  maxAllowed?: T
) => {
  if (navigationMode === 'month') {
    return info
  }

  if (minAllowed && info.year === minAllowed.year) {
    return maxInfo(info, minAllowed, fiscalYearStart)!
  }

  if (maxAllowed && info.year === maxAllowed.year) {
    return minInfo(info, maxAllowed, fiscalYearStart)!
  }

  return info
}

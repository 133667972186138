import { useEffect, useMemo, useRef, useState } from 'react'

import {
  AmModules,
  IBaseChartProps,
  IChartInstance,
  IGlobalOptions,
  IGlobalUseInstance,
  IModuleDefinitions
} from './types/module-types'

const useGlobalInstance: IGlobalUseInstance = (instance) => {
  const [initialized, setInitialized] = useState(instance.initialized)

  useEffect(() => setInitialized(true), [])

  instance.initialized = initialized
}

const useChart = (props: IBaseChartProps) => {
  const amModulesRef = useRef<AmModules>({})
  const modulesOutOfSyncCountRef = useRef(0)
  const previousModulesRef = useRef<IModuleDefinitions | null>(null)
  const modules = useMemo(
    () => props.modules.map((module) => ('module' in module ? module : { module })),
    [props.modules]
  )

  // move this to a state reducer later when we need to listen on the changes of these options
  const globalOptionsRef = useRef<IGlobalOptions>(props.options || ({} as IGlobalOptions))

  // initialize
  const initialModulesRef = useRef(modules)
  useEffect(() => {
    const initialModules = initialModulesRef.current
    const globalOptions = globalOptionsRef.current
    initialModules.forEach((moduleDef) => {
      moduleDef.module.init?.({
        amModulesRef,
        selector: props.selector,
        defn: moduleDef,
        options: { ...globalOptions, ...moduleDef.options }
      })
    })

    return () => {
      // dispose in reverse order
      ;[...initialModules].reverse().forEach((moduleDef) => {
        moduleDef.module.dispose?.({
          amModulesRef,
          defn: moduleDef,
          options: { ...globalOptions, ...moduleDef.options },
          selector: props.selector
        })
      })
    }
  }, [props.selector])

  const isChanged = () => {
    const prevModules = previousModulesRef.current
    if (prevModules === null) return false // first render

    if (prevModules.length !== modules.length) return true // different length

    const prevModulesUniq = prevModules.map((m) => `${m.id || ''}.${m.module.name}`).join(',')
    const modulesUniq = modules.map((m) => `${m.id || ''}.${m.module.name}`).join(',')

    return prevModulesUniq !== modulesUniq // different modules
  }

  // make sure the data from the props is memoized. otherwise, the chart will be re-rendered
  let instance: IChartInstance = { data: props.data, initialized: false }

  let triggerReinit = false
  let activeModules: IModuleDefinitions

  if (isChanged()) {
    triggerReinit = true
    activeModules = previousModulesRef.current!
  } else {
    activeModules = modules
  }

  // register all module hooks
  instance = activeModules.reduce((acc, moduleDef) => {
    const module = moduleDef.module
    if (module.useInstance) {
      module.useInstance(acc, {
        amModulesRef,
        defn: moduleDef,
        options: { ...props.options, ...moduleDef.options },
        selector: props.selector
      })
      return acc
    }
    return acc
  }, instance)

  // register global hooks
  useGlobalInstance(instance, { amModulesRef })

  if (triggerReinit) {
    setTimeout(() => props.triggerReinit?.(), 0)
    modulesOutOfSyncCountRef.current++

    if (modulesOutOfSyncCountRef.current > 1) {
      console.error(
        `Modules are out of sync. This can cause unexpected behavior. 
Please teardown and reinitialize the component where useChart is being used. Use a key to force a re-render.`
      )
    }
  } else {
    previousModulesRef.current = modules
  }

  return instance
}

export default useChart

// project import
import { PalettesProps } from '@ant-design/colors'

import { FontFamily, PresetColor, ThemeMode } from 'types/config'
// types
import { PaletteThemeProps } from 'types/theme'

import ChargerColors from './charger-colors'

// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = (
  colors: PalettesProps,
  presetColor: PresetColor,
  mode: ThemeMode
): PaletteThemeProps => {
  switch (presetColor) {
    case 'theme1':
      return ChargerColors(colors)
    default:
      return ChargerColors(colors)
  }
}

export const Font = (presetColor: PresetColor): FontFamily => {
  switch (presetColor) {
    case 'theme1':
      return 'Proxima-Nova, Proxima Nova, Roboto, sans-serif'
    default:
      return `'Public Sans', sans-serif`
  }
}

export default Theme

import { useMemo, useRef } from 'react'

import { ChargerForm, closePopover } from '@components/form/charger-form'
import { ChargerFormFieldConfig, FieldTypes, FormLayoutTypes } from '@components/form/types'

import Button from 'components/core/button'
import { Popover, PopoverContent, PopoverTrigger } from 'components/core/popover'
import * as Yup from 'yup'

import { useCreateSourceApplication } from './queries/create-source-application'
import { useFetchSourceSystems } from './queries/fetch-source-systems'

export const AddApplicationForm = () => {
  const { data: sourceSystems } = useFetchSourceSystems()

  const popoverTriggerRef = useRef<HTMLButtonElement>(null)

  const { mutate: addNewSourceApplication } = useCreateSourceApplication()

  const fieldsConfig = useMemo(
    () =>
      [
        {
          name: 'applicationName',
          label: 'Name',
          type: FieldTypes.TEXT_FIELD,
          required: true,
          placeholder: 'Provide an Application Name',
          validationSchema: Yup.string().required('Required')
        },
        {
          name: 'destinationSchema',
          label: 'Destination Schema',
          type: FieldTypes.TEXT_FIELD,
          required: true,
          placeholder: 'Destination Schema',
          validationSchema: Yup.string().required('Required')
        },
        {
          name: 'sourceSystemId',
          label: 'Source System',
          type: FieldTypes.SELECT,
          options: _.map(_.sortBy(sourceSystems, 'name'), (system) => ({
            label: system.name,
            value: `${system.id}`
          })),
          required: true,
          placeholder: 'Select a Source System',
          validationSchema: Yup.number().required('Required')
        }
      ] as ChargerFormFieldConfig[],
    [sourceSystems]
  )

  return (
    <div className='flex items-center'>
      <Popover>
        <PopoverTrigger asChild>
          <Button ref={popoverTriggerRef} variant='popoverTrigger'>
            Add New Application
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <ChargerForm
            submitLabel='Add Application'
            onCancel={() => closePopover(popoverTriggerRef.current)}
            layout={FormLayoutTypes.POPOVER}
            onSubmit={(data) => {
              addNewSourceApplication({ ...data })
              closePopover(popoverTriggerRef.current)
            }}
            fieldsConfig={fieldsConfig}
            showLabels={false}
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { rehydrateStore } from '@store/actions/rehydrate-store'

import { RootState } from '../index'

interface TabState {
  [key: string]: string // Support multiple tabs by page key
}

const initialState: TabState = {}

const tabSlice = createSlice({
  name: '@ACTIVE_TAB',
  initialState,
  reducers: {
    initializeTab: (state, action: PayloadAction<{ id: string; defaultValue: string }>) => {
      const { id, defaultValue } = action.payload
      if (!state[id]) {
        state[id] = defaultValue
      }
    },
    setSelectedTab: (state, action: PayloadAction<{ id: string; tab: string }>) => {
      const { id, tab } = action.payload
      state[id] = tab
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrateStore, (_state, action) => {
      return { ...initialState, ...action.payload.activeTab }
    })
  }
})

// Export actions
export const { initializeTab, setSelectedTab } = tabSlice.actions

// Reducer
export const tabReducer = tabSlice.reducer

// Selector
export const selectActiveTab = (state: RootState, id?: string) =>
  id ? _.get(state.activeTab, id, initialState[id]) : undefined

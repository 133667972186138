import React, { useState } from 'react'

import { getAllowedRange } from '@utils/date-filter-utils'
import { cn } from '@utils/style-utils'

import Button from '@components/core/button'
import { IconButton } from '@components/core/icon-button'
import { Popover, PopoverContent, PopoverTrigger } from '@components/core/popover'
import { Text } from '@components/core/text'
import { ChevronLeft, ChevronRight, Icon } from '@components/icons'

import { Frequency, YearMonthDayInfo, YearWeekInfo } from 'types/filter'
import {
  Month,
  getFiscalYear,
  getMonthFromIndex,
  getWeekDays,
  getWeekHeading
} from 'utils/date-utils'
import { Day } from 'utils/date-utils'
import {
  cmpInfo,
  getFirstWeekOfNextMonth,
  getLastWeekOfPreviousMonth,
  getWeekNumber,
  isoWeekToCalendarDate
} from 'utils/date-week-utils'

import { getCalendarOptions } from '../day-picker/utils'
import { GridMenuButton } from '../grid-render-helper'
import YearSelectView from '../year-select-view'
import { clampYearMax, maxInfo, minInfo, navigationClamp, outOfBounds } from './utils'

interface Props<T extends YearWeekInfo = YearWeekInfo> {
  value?: T
  minAllowed?: T
  maxAllowed?: T
  fiscalYearStart?: Month
  onChange: (value: T) => void
  render?: (value: T) => React.ReactNode
}

const WeeklyPicker = (props: Props) => {
  const { fiscalYearStart, onChange, render } = props

  const [view, setView] = useState<'year' | 'month'>('month')

  const [popoverOpen, setPopoverOpen] = useState(false)

  const value =
    props.value ??
    getWeekNumber(
      getFiscalYear(new Date(), fiscalYearStart),
      getMonthFromIndex(new Date().getMonth()),
      new Date().getDate()
    )

  const allowedRange = getAllowedRange(fiscalYearStart, Frequency.Weekly)
  const minAllowed = maxInfo(props.minAllowed, allowedRange.minAllowed)

  const maxAllowed = minInfo(props.maxAllowed, allowedRange.maxAllowed)

  const numColumns = 8 // 1 for the week label

  const weekStart = Day.Mon

  const valueMonth = isoWeekToCalendarDate(value.year, value.week, fiscalYearStart).id.month

  const options = getCalendarOptions(value.year, valueMonth, weekStart, fiscalYearStart)

  const optionsWithWeek = options.flatMap<
    | { type: 'weekLabel'; value: number; weekIndex: number }
    | { type: 'day'; value: YearMonthDayInfo; weekIndex: number }
  >((item, index) =>
    index % 7 === 0
      ? [
          { type: 'weekLabel', value: index / 7, weekIndex: index / 7 },
          { type: 'day', value: item, weekIndex: index / 7 }
        ]
      : [{ type: 'day', value: item, weekIndex: index / 7 }]
  )

  const nextVal = navigationClamp(
    getFirstWeekOfNextMonth(value.year, value.week, fiscalYearStart),
    'week',
    minAllowed,
    maxAllowed
  )

  const prevVal = navigationClamp(
    getLastWeekOfPreviousMonth(value.year, value.week, fiscalYearStart),
    'week',
    minAllowed,
    maxAllowed
  )

  const shouldDisable = (val: YearWeekInfo) => outOfBounds(val, minAllowed, maxAllowed)

  const yearSelectVal = (year: number) =>
    navigationClamp(
      clampYearMax({ year, week: value.week }, fiscalYearStart),
      'year',
      minAllowed,
      maxAllowed
    )

  const handleNextMonth = () => {
    handleValueChange(nextVal!)
  }

  const handlePrevMonth = () => {
    handleValueChange(prevVal!)
  }

  // also handle day overflow from month end
  const handleValueChange = (value: YearWeekInfo) => {
    onChange(value)
  }

  const toggleView = () => setView(view === 'month' ? 'year' : 'month')
  const [hoverInfo, setHoverInfo] = useState<YearWeekInfo>()

  return (
    <div>
      <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
        <PopoverTrigger asChild>
          <Button variant='outline'>
            {!value
              ? ''
              : render
                ? render(value)
                : getWeekHeading(value.year, value.week, fiscalYearStart)}
          </Button>
        </PopoverTrigger>

        <PopoverContent className='flex min-w-64 flex-col'>
          <div className='mb-4 flex flex-1 items-center justify-between'>
            <IconButton
              disabled={!prevVal || shouldDisable(prevVal)}
              onClick={handlePrevMonth}
              variant='outline'
            >
              <Icon icon={<ChevronLeft />} />
            </IconButton>
            <div className='flex' onClick={toggleView}>
              <Text variant='button' className='cursor-pointer font-medium'>
                {valueMonth}
                {fiscalYearStart ? `, FY ${value?.year}` : ' ' + value?.year}
              </Text>
            </div>
            <IconButton disabled={!nextVal || shouldDisable(nextVal)} onClick={handleNextMonth}>
              <Icon icon={<ChevronRight />} />
            </IconButton>
          </div>

          {view === 'month' ? (
            <div
              style={{
                gridTemplateColumns: `repeat(${numColumns}, 1fr`
              }}
              className='grid items-center justify-center text-center'
            >
              <div className='py-2'>
                <Text variant='button' className='capitalize'>
                  Wk
                </Text>
              </div>

              {getWeekDays(weekStart).map((day) => (
                <div className='py-2' key={day}>
                  <Text variant='button' className='capitalize text-primary'>
                    {day.slice(0, 2).toLowerCase()}
                  </Text>
                </div>
              ))}
              {optionsWithWeek.map((obj, idx: number) => {
                if (obj.type === 'weekLabel') {
                  const info = optionsWithWeek[idx + 1].value as YearMonthDayInfo
                  const weekNum = getWeekNumber(info.year, info.month, info.day, fiscalYearStart)
                  const selected = cmpInfo(weekNum, value) === 0
                  const hovered =
                    hoverInfo?.week === weekNum.week && hoverInfo?.year === weekNum.year
                  return (
                    <GridMenuButton
                      key={'label/' + idx}
                      selected={false}
                      onClick={() => {
                        handleValueChange(weekNum)
                        setPopoverOpen(false)
                      }}
                      onMouseEnter={() => setHoverInfo(weekNum)}
                      onMouseLeave={() => setHoverInfo(undefined)}
                      className={cn(
                        'my-[-1px] size-auto cursor-pointer rounded-none border border-grey-light hover:text-white',
                        selected || hovered ? 'bg-primary-darker text-white' : 'bg-grey-lighter'
                      )}
                    >
                      {weekNum.week}
                    </GridMenuButton>
                  )
                }

                const info = obj.value

                const day = info.day
                const key = info.month + '/' + day
                const weekNum = getWeekNumber(info.year, info.month, info.day, fiscalYearStart)
                const selected =
                  cmpInfo(weekNum, value) === 0 ||
                  (hoverInfo != null && cmpInfo(hoverInfo, weekNum) === 0)

                return (
                  <GridMenuButton
                    key={key}
                    disabled={shouldDisable(weekNum)}
                    onMouseEnter={() => {
                      setHoverInfo(weekNum)
                    }}
                    onMouseLeave={() => setHoverInfo(undefined)}
                    onClick={() => {
                      handleValueChange(weekNum)
                      setPopoverOpen(false)
                    }}
                    className={cn(
                      '-m-[1px] rounded-none hover:bg-primary hover:text-white',
                      selected ? 'bg-primary text-white' : 'bg-white',
                      hoverInfo === weekNum && 'bg-primary'
                    )}
                  >
                    {day}
                  </GridMenuButton>
                )
              })}
            </div>
          ) : (
            <YearSelectView
              fiscalYearStart={fiscalYearStart}
              year={value?.year}
              disabled={(year) => shouldDisable(yearSelectVal(year))}
              onChange={(year) => {
                handleValueChange(yearSelectVal(year))
                setView('month')
              }}
            />
          )}
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default WeeklyPicker

import { useEffect, useState } from 'react'

export function useManualRefetchStatus({ isRefetching }: { isRefetching: boolean }) {
  const [isManualRefetching, setManualRefetch] = useState(false)
  useEffect(() => {
    if (isRefetching) return
    setManualRefetch(false)
  }, [isRefetching])

  const startManualRefetching = () => setManualRefetch(true)

  return [isManualRefetching, startManualRefetching] as const
}

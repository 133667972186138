import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'

const DeleteConfirmation = ({
  open,
  setOpen,
  id,
  label,
  onConfirm
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  id: number
  label: string
  onConfirm: (id: number) => void
}) => {
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='delete-component'
      aria-describedby='delete-component'
    >
      <DialogTitle id='alert-dialog-title'>{`Delete ${label}?`}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Are you sure you want to delete component &quot;{label}&quot;?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={handleClose}>
          Cancel
        </Button>

        <Button onClick={() => onConfirm(id)}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteConfirmation

import { useState } from 'react'

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'

import { UniqueIdentifier } from '@dnd-kit/core'

const DeleteConfirmation = ({
  open,
  setOpen,
  nodeId,
  label,
  onConfirm
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  nodeId: UniqueIdentifier
  label: string
  onConfirm: (id: number, setErrorMessage?: (message: string) => void) => void
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const handleClose = () => {
    setOpen(false)
    setErrorMessage('')
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='delete-tree-node'
      aria-describedby='delete-tree-node'
    >
      <DialogTitle id='alert-dialog-title'>{`Delete ${label}?`}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          All associated children of node {label} will be deleted.
        </DialogContentText>
        {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={handleClose}>
          Cancel
        </Button>

        <Button onClick={() => onConfirm(nodeId as number, (message) => setErrorMessage(message))}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteConfirmation

import React from 'react'

import { Grid, MenuItem, Select, TextField } from '@mui/material'

import { enumToOptions } from '@utils/obj-utils'

import { Text } from '@core/text'

import { RubyEditor } from 'components/code-editors'
import { useFormikContext } from 'formik'

import { CustomFieldType } from '../../types/query-builder-types'

export default function QueryComponent({
  children,
  v2 = false
}: {
  children: React.ReactNode
  v2?: boolean
}) {
  const formik = useFormikContext<{
    name: string
    field_type: string
    formula_type: string
    formula: string
  }>()

  return (
    <Grid className={v2 ? '-mt-2' : 'mt-1'} container spacing={2}>
      <Grid className='flex items-start justify-between gap-2' item xs={12}>
        <TextField
          name='name'
          label='Field Name'
          required
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          autoComplete='off'
          autoFocus
          fullWidth
        />
        <Select
          name='field_type'
          required
          value={formik.values.field_type}
          onChange={formik.handleChange}
          sx={{ mt: '3px' }}
          displayEmpty
        >
          <MenuItem value='' disabled>
            Select Field Type
          </MenuItem>
          {enumToOptions(CustomFieldType).map((field) => (
            <MenuItem key={field.value} value={field.value}>
              {field.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      {v2 && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}

      <Grid item xs={12}>
        <RubyEditor
          value={formik.values.formula}
          height='calc(100vh - 500px)'
          onChange={(value) => formik.setFieldValue('formula', value)}
        />
        {formik.touched.formula && formik.errors.formula && (
          <Text variant='description' className='text-error'>
            {formik.errors.formula}
          </Text>
        )}
      </Grid>
    </Grid>
  )
}

import Skeleton from '@mui/material/Skeleton'

import './skeleton-table.css'

type SkeletonRowProps = {
  columnsLength: number
  rowClassName?: string
}

export const SkeletonRow = ({ columnsLength = 1, rowClassName = '' }: SkeletonRowProps) => {
  const fakeColumns = [...new Array(columnsLength)].map((_, i) => i)

  return (
    <div className={`row_class_name ${rowClassName}`}>
      {fakeColumns.map((key) => (
        <Skeleton key={key} variant='text' className='cell' />
      ))}
    </div>
  )
}

interface Props {
  rowLength?: number
  top?: number
}
const SkeletonTable = ({ rowLength = 12, top }: Props) => {
  const fakeRows = [...new Array(rowLength)].map((_, i) => i)

  return (
    <div className='skeleton-table' style={{ top: top }}>
      <div>
        {fakeRows.map((key) => (
          <SkeletonRow key={key} columnsLength={1} rowClassName='overlayRow' />
        ))}
      </div>
    </div>
  )
}

export default SkeletonTable

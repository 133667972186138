import { useMutation, useQueryClient } from '@tanstack/react-query'

import axiosServices from '@utils/axios'
import { snakeCaseKeys } from '@utils/case-conversion'

import useAuth from '@hooks/useAuth'

import { queryErrorToast, querySuccessToast } from 'queries/query-toasts'

export interface IUpdateJobSchedules {
  id: number
  scheduleExpression: string
  timezone: string
}

export interface IUpdateJobSchedulesProps {
  onSuccess?: () => void
}

export const useUpdateJobSchedules = (props: IUpdateJobSchedulesProps) => {
  const queryClient = useQueryClient()
  const { user } = useAuth()

  return useMutation({
    mutationFn: (schedules: IUpdateJobSchedules[]) => {
      const payload: any = { data_replication_schedules: [] }
      payload.data_replication_schedules = schedules.map((schedule) => snakeCaseKeys(schedule))

      return axiosServices.patch(
        `/businesses/${user?.business_id}/data_replication_schedule/update_bulk_schedules`,
        payload
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetch-job-schedules', user?.business_id] })
      props.onSuccess?.()
      querySuccessToast('Schedules updated successfully')
    },
    onError: (error: any) => {
      queryErrorToast(error.message)
    }
  })
}

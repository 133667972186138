import { Box, Divider, Grid, Skeleton } from '@mui/material'

function HeaderSkeleton() {
  return (
    <Box display='flex' justifyContent='space-between' alignItems='center' sx={{ py: 2 }}>
      <Box>
        <Skeleton variant='rectangular' width={300} height={40} sx={{ mb: 2 }} />
        <Skeleton variant='rectangular' width={510} height={20} />
      </Box>
      <Box>
        <Skeleton variant='rectangular' width={90} height={40} />
      </Box>
    </Box>
  )
}

function TableListSkeleton() {
  return (
    <Box display='flex' flexDirection='column'>
      {Array.from(new Array(12)).map((_, index) => (
        <Skeleton key={index} variant='text' width='90%' height={30} />
      ))}
    </Box>
  )
}

function CodeBlockSkeleton() {
  return <Skeleton variant='rectangular' width='100%' height={300} />
}

function ButtonSkeleton() {
  return (
    <Box display='flex' justifyContent='flex-end' mt={2}>
      <Skeleton variant='rectangular' width={90} height={40} />
      <Skeleton variant='rectangular' width={90} height={40} style={{ marginLeft: 8 }} />
    </Box>
  )
}

function CreateLoading() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <HeaderSkeleton />
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Skeleton variant='text' width='50%' />
            <TableListSkeleton />
          </Grid>
          <Grid item xs={12} md={8}>
            <Skeleton variant='text' width='100%' />
            <CodeBlockSkeleton />
            <ButtonSkeleton />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CreateLoading

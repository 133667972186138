// material-ui
import { alpha } from '@mui/material/styles'

// ==============================|| OVERRIDES - DIALOG ||============================== //

export default function Dialog() {
  return {
    MuiDialog: {
      defaultProps: {
        fullWidth: true
      },
      styleOverrides: {
        paper: {
          borderRadius: '1rem',
          gap: '1.5rem',
          '& > .MuiDialogTitle-root+.MuiDialogContent-root': {
            paddingTop: '0.5rem'
          },
          '& > *:first-child': {
            paddingTop: '2rem',
            paddingBottom: 0
          },
          '& > *:last-child': {
            paddingTop: 0,
            paddingBottom: '2rem'
          },
          '& > *:last-child:first-child': {
            paddingTop: '2rem',
            paddingBottom: '2rem'
          },
          '& > *': {
            paddingLeft: '2rem',
            paddingRight: '2rem'
          }
        },
        root: {
          '& .MuiBackdrop-root': {
            backgroundColor: alpha('#000', 0.7)
          }
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0.25rem 2rem 0.25rem 2rem'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0.5rem 2rem 2rem 2rem',
          gap: '2rem'
        }
      }
    }
  }
}

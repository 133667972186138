import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

import { IModule, IModuleAttributes, ModuleName } from '../types/module-types'
import { assignRef, getSelfRef } from '../utils/module-utils'
import getTheme from '../utils/theme'

const moduleName = ModuleName.root

const rootModule: IModule<ModuleName.root> = {
  name: moduleName
}

rootModule.init = (props) => {
  const { selector } = props
  const root = am5.Root.new(selector, {
    tooltipContainerBounds: {
      top: 50,
      right: 50,
      bottom: 300,
      left: 200
    }
  })

  root.setThemes([am5themes_Animated.new(root), getTheme(root)])

  root.dom.addEventListener('contextmenu', function (event) {
    event.preventDefault()
  })

  assignRef({ ...props, moduleName, item: root })
}

rootModule.dispose = (props) => {
  const root = getSelfRef({ ...props, moduleName })
  root.dispose()
}

export const rootAttributes: IModuleAttributes<ModuleName.root> = (p) => ({
  module: rootModule,
  ...p
})

export default rootModule

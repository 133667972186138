export interface IChartDataItem {
  identifier?: 'begin' | 'end'
  dimension: string
  value: number
  value_open: number
  value_close: number
  value_step?: number
}

export enum Display {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL'
}

export function isDisplayHorizontal(display: Display) {
  return display === Display.HORIZONTAL
}

export function isDisplayVertical(display: Display) {
  return display === Display.VERTICAL
}

export enum Position {
  INSIDE = 'INSIDE',
  OUTSIDE = 'OUTSIDE'
}

export function isPositionInside(position: Position) {
  return position === Position.INSIDE
}

export function isPositionOutside(position: Position) {
  return position === Position.OUTSIDE
}

export const DATA_LABEL_BULLETS = 'dataLabelBullets'
export const RECTANGULAR_BULLETS = 'rectangularBullets'

export const DATE_CELL_WIDTH_BREAKPOINTS = {
  small: 20,
  medium: 40
}

export const CATEGORY_AXIS_CHART_RATIO = 0.33
export const CHART_FONT_FAMILY = 'Roboto, monospace'
export const CHART_LEGEND_HEIGHT = 20

import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { AgGridReact } from 'ag-grid-react'

import { isBlank } from '@utils/lodash'
import { humanizeFieldName } from '@utils/string-utils'

import FilterPanel from '@components/control-panel/filter-panel'
import LanguageSelector from '@components/control-panel/language-selector'
import { handleExportXLSXByBackend } from '@components/financial/export-financial-report'

import { selectLanguageId, selectViewMoreItems } from '@store/slices/action-bar'
import { selectDimensions } from '@store/slices/dimension'

import MainCard from 'components/MainCard'
import {
  EXPANDED,
  financialStatementExporterConfig
} from 'components/control-panel/data-exporter-configs'
import { FinancialReportExportFilters } from 'components/financial/types'
import { useGetRegisteredAgGridRefs } from 'contexts/grid-ref-context'
import useAuth from 'hooks/useAuth'
import { infoToast } from 'queries/query-toasts'
import { usePageSelector } from 'store'
import { selectExpandedDateFilterInfo } from 'store/slices/date-time-filter'
import { addToast } from 'store/slices/toast'
import { getNodesExpandedState } from 'utils/ag_grid/grid-utils'

import { useRegisterAgGridRefEffect } from '../../contexts/grid-ref-context'
import { PageSettings } from '../layout-view/types'
import { FinancialsGrid } from './components/financials-grid'
import { useDataColumnDefinition } from './hooks/data-column-definition'
import { useFetchFinancialStatement } from './queries/fetch-financial-statement'
import { FinancialStatementType, FinancialStatementTypeEnum } from './types'

interface Props {
  hierarchyID?: number
  type: FinancialStatementType
  pageSettings: PageSettings
}

export const FinancialStatement = ({ pageSettings, type, hierarchyID }: Props) => {
  const searchFilters = useMemo(() => pageSettings?.settings?.search_filters || {}, [pageSettings])

  const gridRef = useRef<AgGridReact<any>>(null)
  const { user } = useAuth()
  const gridRefs = useGetRegisteredAgGridRefs(type)
  const expandedDateFilter = usePageSelector(selectExpandedDateFilterInfo)
  const dimensions = usePageSelector(selectDimensions)
  const languageId = usePageSelector(selectLanguageId)

  const viewMoreItems = usePageSelector(selectViewMoreItems)

  useRegisterAgGridRefEffect(gridRef, type)

  const { isFetching, isError, error, data } = useFetchFinancialStatement({
    type,
    dateFilter: expandedDateFilter,
    hierarchyId: hierarchyID,
    showDuplicateAccounts: viewMoreItems.duplicateGlAccount,
    showAllZeroRows: viewMoreItems.showAllZeroRows,
    selectedDimensionFilters: dimensions,
    languageId
  })

  const dataColumnDefinition = useDataColumnDefinition({
    type: type,
    dateFilter: expandedDateFilter
  })

  const backendExportHandler = (data: any, exportFilters: any) => {
    const downloadReadyMessage =
      'Your Financial Statement Excel export will be ready shortly for download.'
    infoToast(downloadReadyMessage)
    const hierarchy = data.hierarchy
    const nodesExpandedState = hierarchy === EXPANDED ? [] : getNodesExpandedState(gridRefs)
    const financialReportType = type ?? FinancialStatementTypeEnum.IncomeStatement

    handleExportXLSXByBackend({
      financialReportType,
      exportFilters: exportFilters as FinancialReportExportFilters,
      user,
      languageId,
      nodesExpandedState
    })
  }

  const dispatch = useDispatch()
  const [toastShown, setToastShown] = useState(false)

  useEffect(() => {
    if (isError && !toastShown) {
      const reportName = humanizeFieldName(type)
      // Dispatch the toast message with updated text and button
      dispatch(
        addToast({
          description: `Please use the Report Builder to build your ${reportName}.`,
          action: {
            onClickType: 'navigateToReportBuilder',
            text: 'Report Builder'
          },
          showCloseButton: true
        })
      )
      setToastShown(true)
    }
  }, [isError, error, toastShown, dispatch, type])

  return (
    <MainCard
      pageSettings={pageSettings}
      sx={{
        height: '100%'
      }}
      border={false}
      content={true}
      title={isBlank(searchFilters) ? '' : ' '}
      divider={true}
      secondaryAlign='left'
      secondary={
        !isBlank(searchFilters) ? (
          <FilterPanel
            searchFilters={searchFilters}
            financialReportType={type}
            exporterFieldsConfig={financialStatementExporterConfig}
            backendExportHandler={backendExportHandler}
          >
            <LanguageSelector />
          </FilterPanel>
        ) : undefined
      }
    >
      {!isError && (
        <FinancialsGrid
          key={type}
          ref={gridRef}
          isLoading={isFetching}
          dateFilter={expandedDateFilter!}
          rowData={data}
          dataColumnDefinition={dataColumnDefinition}
          fileName={type}
        />
      )}
    </MainCard>
  )
}

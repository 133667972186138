import { PageActionPayload } from '@store/page-util'

import {
  DEFAULT_MOST_EXPANDED_LEVEL,
  restoreDefaultExpansion
} from 'components/control-panel/expand-collapse-control'
import { AgGridRef } from 'contexts/grid-ref-context'

export const restoreExpansionWithClick = (
  dispatch: (pageActionPayload: PageActionPayload) => void,
  gridRefs: AgGridRef,
  initialMostExpandedLevel?: number
) => {
  const fakeMouseEvent = new MouseEvent('filter')
  restoreDefaultExpansion(
    dispatch,
    [gridRefs],
    initialMostExpandedLevel ?? DEFAULT_MOST_EXPANDED_LEVEL,
    fakeMouseEvent as unknown as React.MouseEvent<HTMLButtonElement>
  )
}

export interface Page {
  id: number | string
  description: string
  path: string
  title: string
  slug: string
  pageRoute: string
  enabled: boolean
  sidebarItem: boolean
  layoutType: PageType
  layoutStructure: any
  businessId: string | number
  metaData?: Record<string, any>
  owner?: Record<string, any>
  position: number
  settings?: Record<string, any>
  updatedAt: string
  status: PageStatus
}

export interface PageTreeItem extends Page {
  children?: PageTreeItem[]
}

export const enum PageType {
  PAGE = 'page',
  STATIC_PAGE = 'static_page',
  UNREGISTERED = 'unregistered',
  PAGE_GROUP = 'page_group',
  COLLECTION = 'collection',
  INTERNAL = 'internal'
}

export const isClientPage = (page: Page): boolean =>
  _.includes([PageType.STATIC_PAGE, PageType.PAGE], page.layoutType) && isActive(page)
export const isInternalPage = (page: Page): boolean => _.isEqual(page.layoutType, PageType.INTERNAL)
export const isStaticPage = (page: Page): boolean =>
  _.isEqual(page.layoutType, PageType.STATIC_PAGE)
export const isDynamicPage = (page: Page): boolean => _.isEqual(page.layoutType, PageType.PAGE)

export enum PageStatus {
  draft = 'draft',
  published = 'published'
}

export const isBrowsablePage = (page: Page): boolean =>
  _.includes([PageType.STATIC_PAGE, PageType.PAGE, PageType.INTERNAL], page.layoutType)

export const isActive = (page: Page): boolean =>
  page.enabled && _.isEqual(page.status, PageStatus.published)
export const isSidebarItem = (page: Page): boolean => page.sidebarItem && isActive(page)

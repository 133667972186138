import { IRowNode } from 'ag-grid-community'

import { GlAccountNodeIdentifierEnum } from '@components/financial/types'

export const isCellValueVisible = (node: IRowNode): boolean => {
  if (
    node.expanded &&
    [GlAccountNodeIdentifierEnum.heading, GlAccountNodeIdentifierEnum.pointer].includes(
      node.data.identifier
    ) &&
    !node.footer
  ) {
    return false // Cell value is hidden
  }
  return true // Cell value is visible
}

import { Navigate } from 'react-router-dom'

import useAuth from 'hooks/useAuth'
import { GuardProps } from 'types/auth'

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAuth()

  if (!isLoggedIn) {
    return <Navigate to='/login' />
  }

  return children
}

export default AuthGuard

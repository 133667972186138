import { useMemo } from 'react'

import { humanizeFieldName } from '@utils/string-utils'

import LazyComponent from '../core/LazyComponent'
import { FilterError } from '../fallback/filter-error'

const ControlComponent = ({
  name,
  shouldRender,
  componentProps
}: {
  name: string
  shouldRender: boolean
  componentProps: any
}) => {
  const component = useMemo(() => {
    // TODO: only load the required files
    const modules: Record<string, any> = import.meta.glob('./*.tsx')

    if (shouldRender) {
      const configPath = `./${_.kebabCase(name)}.tsx`

      const ImportedControl = LazyComponent(modules[configPath], undefined, () => (
        <FilterError message={`${humanizeFieldName(name)} filter failed to load`} />
      ))
      return <ImportedControl {...componentProps} />
    }

    return <></>
    // Note: If you are working with this component, please fix react-hooks/exhaustive-deps warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentProps?.financialReportType])

  return <>{component}</>
}

export default ControlComponent

import { useRef } from 'react'
import { Fragment } from 'react/jsx-runtime'

import { ReactCodeMirrorRef } from '@uiw/react-codemirror'
import { humanizeFieldName } from '@utils/string-utils'
import { cn } from '@utils/style-utils'

import { RubyEditor } from '@components/code-editors'
import { useCursorTrackerExtension } from '@components/code-editors/hooks/use-cursor-tracker-extension'
import Button from '@components/core/button'
import { IconButton } from '@components/core/icon-button'
import { Input } from '@components/core/input'
import { Popover, PopoverContent, PopoverTrigger } from '@components/core/popover'
import { Separator } from '@components/core/separator'
import { Text } from '@components/core/text'
import { closePopover } from '@components/form/charger-form'
import { Select } from '@components/form/select'
import { DotsVertical, Icon } from '@components/icons'

import { useDispatch, useSelector } from '@store/index'
import {
  addFormula,
  removeFormula,
  selectFormula,
  updateFormula,
  updateParameterConfigOnHeaderNameChange
} from '@store/slices/component/query-config'

import { Formik } from 'formik'
import * as Yup from 'yup'

import { useQueryResultContext } from '../../contexts/query-result-context'
import { IDataType } from '../../types/component-types'
import { validateFieldName } from '../../utils/validate-field-name'

export function AddFormulaPopover() {
  const popoverTriggerRef = useRef<HTMLButtonElement>(null)

  return (
    <Popover>
      <PopoverTrigger asChild ref={popoverTriggerRef}>
        <Button variant='outline'>Add Formula</Button>
      </PopoverTrigger>

      <PopoverContent
        align='end'
        className='w-[43rem] p-2'
        onEscapeKeyDown={(e) => e.preventDefault()}
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <FormulaForm
          selectedFormulaName={null}
          handleClose={() => closePopover(popoverTriggerRef.current)}
        />
      </PopoverContent>
    </Popover>
  )
}

export function EditFormulaPopover({
  formulaName,
  controls
}: {
  formulaName: string
  controls?: React.ReactNode
}) {
  const popoverTriggerRef = useRef<HTMLButtonElement>(null)

  return (
    <Popover>
      <PopoverTrigger asChild ref={popoverTriggerRef}>
        <IconButton variant='ghost' className='rounded-md border-none focus:text-primary-darker'>
          <Icon icon={<DotsVertical />} />
        </IconButton>
      </PopoverTrigger>

      <PopoverContent
        align='end'
        className='mt-1 w-[43rem] p-2'
        onEscapeKeyDown={(e) => e.preventDefault()}
        onPointerDownOutside={(e) => e.preventDefault()}
      >
        <FormulaForm
          selectedFormulaName={formulaName}
          handleClose={() => closePopover(popoverTriggerRef.current)}
          controls={controls}
        />
      </PopoverContent>
    </Popover>
  )
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Column Name is required')
    .test('validate-field-name', 'Invalid field name', function (value) {
      const validationError = validateFieldName(value || '')
      if (validationError) {
        return this.createError({ message: validationError })
      }
      return true
    }),
  data_type: Yup.string().required('Data Type is required'),
  formula: Yup.string().required('Formula is required')
})

function FormulaForm({
  selectedFormulaName,
  handleClose,
  controls
}: {
  selectedFormulaName: string | null
  handleClose: () => void
  controls?: React.ReactNode
}) {
  const dispatch = useDispatch()
  const editorRef = useRef<ReactCodeMirrorRef>(null)

  let isEdit = true
  let initialValues = useSelector(selectFormula(selectedFormulaName))
  const { cursorPosition, cursorTracker } = useCursorTrackerExtension()

  const { visibleHeaders } = useQueryResultContext()

  if (!initialValues) {
    isEdit = false
    initialValues = { name: '', data_type: IDataType.NUMBER, formula: '' }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (isEdit) {
          if (selectedFormulaName) {
            dispatch(updateFormula({ config: values, oldName: selectedFormulaName }))

            if (selectedFormulaName !== values.name) {
              dispatch(
                updateParameterConfigOnHeaderNameChange({
                  headerName: selectedFormulaName,
                  newHeaderName: values.name
                })
              )
            }
          }
        } else {
          dispatch(addFormula(values))
        }
        handleClose()
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => (
        <>
          <div className='flex items-center justify-between'>
            <Text variant='button' weight='semibold' className='text-primary-darker'>
              {isEdit ? 'Edit' : 'Add'} Formula
            </Text>
            <div className='flex gap-2'>
              {controls}
              {isEdit && (
                <Button
                  variant='outline'
                  onClick={() => {
                    if (selectedFormulaName) {
                      dispatch(removeFormula(selectedFormulaName))
                    }
                    handleClose()
                  }}
                >
                  Delete
                </Button>
              )}
              <Button variant='outline' onClick={() => handleClose()}>
                Cancel
              </Button>
              <Button variant='primary' onClick={() => handleSubmit()}>
                {isEdit ? 'Save' : 'Add'}
              </Button>
            </div>
          </div>
          <Separator className='my-2' />
          <div className='flex items-center justify-between'>
            <Input
              name='name'
              value={values.name}
              placeholder='Custom formula name'
              onChange={handleChange}
              onBlur={handleBlur}
              required
              failedValidation={touched.name && !!errors.name}
            />
            <Select
              className='w-28'
              value={values.data_type}
              onChange={(value) => {
                setFieldValue('data_type', value as IDataType)
              }}
              options={[
                { value: IDataType.NUMBER, label: 'Number' },
                { value: IDataType.TEXT, label: 'Text' },
                { value: IDataType.BOOLEAN, label: 'Boolean' },
                { value: IDataType.DATE_STRING, label: 'Date' }
              ]}
              placeholder='Data Type'
            />
          </div>
          <Separator className='my-2' />

          <div className='grid grid-cols-[auto_32rem] gap-2'>
            <div className='max-h-64 overflow-auto'>
              <Text variant='details' className='mb-1'>
                Click to insert
              </Text>
              {_.map(visibleHeaders, (header) => (
                <Fragment key={header.name}>
                  <Text
                    variant='submenu'
                    className='cursor-pointer'
                    onClick={() => {
                      const textToInsert = header.name
                      const editor = editorRef.current?.view
                      if (!editor) return

                      editor.dispatch({
                        changes: { from: cursorPosition, to: cursorPosition, insert: textToInsert },
                        selection: { anchor: cursorPosition + textToInsert.length }
                      })

                      editor.focus()
                    }}
                  >
                    {humanizeFieldName(header.name)}
                  </Text>
                  <Separator className='my-1' />
                </Fragment>
              ))}
            </div>

            <div
              className={cn(
                'border border-solid',
                touched.formula && !!errors.formula ? 'border-red' : 'border-grey-lighter'
              )}
            >
              <RubyEditor
                editorRef={editorRef}
                value={values.formula}
                height='250px'
                extensions={[cursorTracker]}
                onChange={(value) => setFieldValue('formula', value)}
              />
            </div>
          </div>
        </>
      )}
    </Formik>
  )
}

import { ValueOf } from '../../../utils/obj-utils'
import { LAYOUT_TYPE } from './data'

export type PageIdentifier = ValueOf<typeof LAYOUT_TYPE>

// export type ViewModeType = 'Edit' | 'View' | 'ParentPreview';

export enum ViewMode {
  edit = 'edit',
  view = 'view',
  parentView = 'parent-view'
}

export const isViewModeEdit = (viewMode: ViewMode): boolean => viewMode === ViewMode.edit
export const isViewModeParentView = (viewMode: ViewMode): boolean =>
  viewMode === ViewMode.parentView

// @deprecated - use PageStatus instead
export enum LayoutStatus {
  draft = 'draft',
  published = 'published'
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import { dispatch } from 'store'
import { openSnackbar } from 'store/reducers/snackbar'
import axiosServices from 'utils/axios'

export const useReorderPage = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      parentPageId,
      sortedNodeIds
    }: {
      parentPageId?: number
      sortedNodeIds?: Array<number>
    }) => {
      return axiosServices.post(`/businesses/${user?.business_id}/pages/reorder`, {
        parent_node_id: parentPageId,
        sorted_node_ids: sortedNodeIds
      })
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: ['fetch-pages-tree', user?.business_id] })
    },
    onError: (error, variables) => {
      queryClient.invalidateQueries({ queryKey: ['fetch-pages-tree', user?.business_id] })

      dispatch(
        openSnackbar({
          open: true,
          message: 'Error Reordering Pages.',
          variant: 'alert',
          alert: { color: 'error' },
          close: false
        })
      )
    }
  })
}

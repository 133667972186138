// types
import { Color } from '@mui/material'

import { PalettesProps } from '@ant-design/colors'

import { PaletteThemeProps } from 'types/theme'

// ==============================|| PRESET THEME - DEFAULT ||============================== //

const ChargerColors = (colors: PalettesProps): PaletteThemeProps => {
  const { red, gold, cyan, green } = colors // TODO: this is passed from ant design palletes, can we get rid of this??

  const chargerBlue = {
    light: '#4EADEA',
    medium: '#2170BA',
    dark: '#24528E',
    darker: '#303D4E'
  }

  const contrastText = '#fff'

  const greyColors: Color = {
    0: '#fff',
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#f2f2f2',
    300: '#D5D4DF',
    400: '#bfbfbf',
    500: '#8c8c8c',
    600: '#757575',
    700: '#262626',
    800: '#141414',
    900: '#000000',
    A50: '#fafafb',
    A100: '#fafafa',
    A200: '#bfbfbf',
    A400: '#434343',
    A700: '#1f1f1f',
    A800: '#e6ebf1'
  }

  return {
    primary: {
      lighter: chargerBlue.light,
      100: chargerBlue.light,
      200: chargerBlue.light,
      light: chargerBlue.light,
      400: chargerBlue.medium,
      main: chargerBlue.medium,
      dark: chargerBlue.dark,
      700: chargerBlue.dark,
      darker: chargerBlue.darker,
      900: chargerBlue.dark,
      contrastText,
      A100: '#E9F0FD'
    },
    secondary: {
      lighter: greyColors[100],
      100: greyColors[100],
      200: greyColors[200],
      light: greyColors[300],
      400: greyColors[400],
      main: '#262626',
      600: greyColors[600],
      dark: greyColors[700],
      800: greyColors[800],
      darker: greyColors[900],
      A100: greyColors[0],
      A200: greyColors.A400,
      A300: greyColors.A700,
      contrastText: '#FFF'
    },
    error: {
      lighter: red[0],
      light: red[2],
      main: red[4],
      dark: '#8C0000',
      darker: red[9],
      contrastText
    },
    warning: {
      lighter: gold[0],
      light: gold[3],
      main: gold[5],
      dark: gold[7],
      darker: gold[9],
      contrastText: greyColors.A100
    },
    info: {
      lighter: cyan[0],
      light: cyan[3],
      main: cyan[5],
      dark: cyan[7],
      darker: cyan[9],
      contrastText
    },
    success: {
      lighter: green[0],
      light: green[3],
      main: green[5],
      dark: green[7],
      darker: green[9],
      contrastText
    },
    grey: greyColors, // previously used grey colors, breaks existing UI if modified to use chargerGrey
    chargerGrey: {
      lighter: '#f2f2f2',
      light: '#f2f2f2',
      main: '#C6C6C6',
      dark: '#757575',
      darker: '#757575'
    },
    charts: {
      black: '#292929', // charger black
      single: {
        color0: '#4EADEA' // charger light blue
      },
      three: {
        color0: '#4EADEA', // charger light blue
        color1Alt: '#F2AAA1', // charger light red
        color1: '#2170BA', // charger non red -- dark blue
        color2: '#33D39A' // charger light green
      },
      five: {
        color0: '#4EADEA', // charger light blue
        color1: '#F2AAA1', // charger light red
        color2: '#33D39A', // charger light green
        color3: '#BC80BD', // charger light purple
        color4: '#FFED6F' // charger light yellow
      },
      many: {
        color0: '#ACC5E0', // blue
        color1: '#C0E7B0', // green
        color2: '#E99288', // red
        color3: '#D9D9D9', // grey
        color4: '#BC80BD', // purple
        color5: '#8DD3C7', // teal
        color6: '#B3BADA', // light blue
        color7: '#FCCDE5', // light pink
        color8: '#FDB462', // orange
        color9: '#FFED6F', // yellow
        color10: '#B9E273' // light green
      }
    },
    divider: greyColors[200]
  }
}

export default ChargerColors

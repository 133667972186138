import { AdvancedFilterModel } from 'ag-grid-community'

export const isEmptyAdvancedFilter = (filterModel: AdvancedFilterModel | null) => {
  if (
    !filterModel ||
    (filterModel.filterType === 'join' ? !filterModel.conditions?.length : !filterModel.filterType)
  )
    return true

  return false
}

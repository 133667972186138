import { useMemo } from 'react'

import { JSONEditor } from '@components/code-editors'

import { useSelector } from '@store/index'
import {
  selectFilters,
  selectFormulas,
  selectHiddenFilters,
  selectLimit,
  selectQueryData,
  selectSorts
} from '@store/slices/component/query-config'

export function JSONViewer() {
  const query = useSelector(selectQueryData())
  const filters = useSelector(selectFilters)
  const hiddenFilters = useSelector(selectHiddenFilters)
  const sorts = useSelector(selectSorts)
  const limit = useSelector(selectLimit)
  const formulas = useSelector(selectFormulas)

  const json = useMemo(() => {
    return JSON.stringify({ query, filters, hiddenFilters, sorts, limit, formulas }, null, 2)
  }, [query, filters, hiddenFilters, sorts, limit, formulas])

  return (
    <div>
      <JSONEditor value={json} readOnly />
    </div>
  )
}

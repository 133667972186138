import React from 'react'
import { Children, HTMLProps, ReactElement, ReactNode } from 'react'

import { cn } from '@utils/style-utils'

import { Separator } from './separator'

export interface ListProps extends HTMLProps<HTMLDivElement> {
  orientation?: 'vertical' | 'horizontal'
  separator?: boolean
  children: ReactNode[]
}

const separatorElement = (key: string, orientation: 'vertical' | 'horizontal') => (
  <Separator
    key={key}
    orientation={orientation}
    className={cn({
      'w-full': orientation === 'horizontal'
    })}
  />
)

export const List = (props: ListProps) => {
  const { orientation = 'vertical', separator, children, className, ...rest } = props

  const childrenWithSeparators = separator
    ? Children.map(children, (child, index) => {
        const childKey = (child as ReactElement)?.key || index
        return (
          <>
            {index > 0 &&
              separatorElement(
                `separator-${childKey}`,
                orientation === 'vertical' ? 'horizontal' : 'vertical'
              )}
            {React.cloneElement(child as ReactElement, { key: `child-${childKey}` })}
          </>
        )
      })
    : children

  return (
    <div className={cn('flex', orientation === 'vertical' && 'flex-col', className)} {...rest}>
      {childrenWithSeparators}
    </div>
  )
}

import React from 'react'

import { Month } from '@utils/date-utils'

import GridMenuRenderHelper from '@components/control-panel/calendar-filter-popover/grid-render-helper'

import { getYears } from './year-picker'

interface Props {
  fiscalYearStart?: Month
  year?: number
  onChange: (year: number) => void
  render?: (year: number) => React.ReactNode
  disabled?: (year: number) => boolean
}

const YearSelectView = (props: Props) => {
  const { fiscalYearStart, disabled, year, onChange } = props

  return (
    <GridMenuRenderHelper
      selectedItem={year}
      keySelector={(value) => value}
      className='min-w-72'
      data={getYears(fiscalYearStart).map((info) => info.year)}
      render={(value) => `${value}`}
      disabled={disabled}
      numColumns={4}
      onChange={onChange}
    />
  )
}

export default YearSelectView

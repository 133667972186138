import { useQuery } from '@tanstack/react-query'

import { camelCaseKeys } from '@utils/case-conversion'

import useAuth from 'hooks/useAuth'
import { QUERY_STALE_TIME_DEFAULT } from 'queries/constants'
import axiosServices from 'utils/axios'

import { ISourceSystem } from '../types'

const fetchSourceSystems = async (businessId: number | undefined): Promise<ISourceSystem[]> => {
  const response = await axiosServices.get(`/businesses/${businessId}/source_systems`)

  if (!response?.data || !response.data.source_systems) {
    return []
  }

  const sourceSystems = camelCaseKeys(response.data.source_systems) as ISourceSystem[]
  return sourceSystems
}

export const useFetchSourceSystems = () => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-source-systems', user?.business_id],
    enabled: !!user?.business_id,
    queryFn: () => fetchSourceSystems(user?.business_id),
    staleTime: QUERY_STALE_TIME_DEFAULT
  })
}

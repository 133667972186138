import { useState } from 'react'

import { Box, Divider, Grid, SelectChangeEvent, Typography } from '@mui/material'

import { enumToOptions } from '@utils/obj-utils'

import { Select } from '@components/form/select'

import { useDispatch } from '@store/index'
import { useSelector } from '@store/index'
import {
  changeAxisPosition,
  changeSeriesType,
  selectFlipAxis,
  selectSettings,
  selectYAxes,
  updateYAxisData
} from '@store/slices/component/chart-config'

import { Label } from 'components/core/label'
import MuiSelect from 'components/form/mui-select'

import { ItemSelection } from '../common/item-selection'
import { AxisPosition, SeriesType } from '../types/chart-builder-types'
import { AggregateType } from '../types/component-types'
import AxisLimit from './axis-limit'
import Breakdown from './breakdown'
import { AxisDataLabelsSettings } from './data-labels-settings'
import { LegendSettings } from './legend-settings'
import NumberFormatting from './number-formatting'
import { AxisTooltipSettings } from './tooltip-settings'

const seriesTypes = enumToOptions(SeriesType)

export default function ChartYAxis() {
  const dispatch = useDispatch()
  const yAxes = useSelector(selectYAxes)
  const flipAxis = useSelector(selectFlipAxis)
  const settings = useSelector(selectSettings)
  const stackAxes = _.get(settings, 'stackAxes', false)
  const [selectedId, setSelectedId] = useState<null | string>(yAxes.length ? yAxes[0].id : null)

  const selected = yAxes.find((axis) => axis.id === selectedId) || null

  if (!selected) {
    return <Typography variant='body1'>No axis selected</Typography>
  }

  return (
    <Box>
      <ItemSelection
        options={yAxes}
        selected={selected}
        setSelected={setSelectedId}
        description='Select which measure to edit'
      />
      <Grid container spacing={2} key={selected.id}>
        <Grid item xs={12}>
          <Typography variant='h5'>Component Type And Direction</Typography>
          <Typography variant='details' gutterBottom>
            Select the type of component and then arrange data to modify the layer order
          </Typography>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Label htmlFor='series-type'>Series Type</Label>
          <MuiSelect
            options={seriesTypes}
            value={selected.seriesType}
            onChange={(e: SelectChangeEvent<string>) => {
              dispatch(
                changeSeriesType({ id: selected.id, seriesType: e.target.value as SeriesType })
              )
            }}
            id='series-type'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {stackAxes ? (
            <>
              <Select
                id='stacked-axis-position'
                label='Axis Position'
                value={_.get(selected, 'stackedAxisPosition', AxisPosition.Normal)}
                onChange={(value) => {
                  dispatch(updateYAxisData({ id: selected.id, stackedAxisPosition: value }))
                }}
                options={[{ value: AxisPosition.Normal, label: flipAxis ? 'Bottom' : 'Left' }]}
              />
            </>
          ) : (
            <>
              <Label htmlFor='axis-position'>Axis Position</Label>
              <MuiSelect
                options={[
                  { value: AxisPosition.Normal, label: flipAxis ? 'Bottom' : 'Left' },
                  { value: AxisPosition.Opposite, label: flipAxis ? 'Top' : 'Right' },
                  ...yAxes
                    .filter(
                      (axis) =>
                        axis.id !== selected.id &&
                        (axis.axisPosition === AxisPosition.Normal ||
                          axis.axisPosition === AxisPosition.Opposite)
                    )
                    .map((axis) => ({ value: axis.id, label: `Use ${axis.label}` }))
                ]}
                value={selected.axisPosition}
                onChange={(e: SelectChangeEvent<string>) => {
                  dispatch(
                    changeAxisPosition({
                      id: selected.id,
                      axisPosition: e.target.value as AxisPosition
                    })
                  )
                }}
                id='axis-position'
              />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5'>Aggregate</Typography>
          <Typography variant='details' gutterBottom>
            Aggegrate data if there are repeated categories
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MuiSelect
            options={enumToOptions(AggregateType)}
            value={selected.aggregate || AggregateType.first}
            onChange={(e: SelectChangeEvent<string>) => {
              dispatch(
                updateYAxisData({ id: selected.id, aggregate: e.target.value as AggregateType })
              )
            }}
            label='Aggregate'
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5'>Legend</Typography>
          <Typography variant='details' gutterBottom>
            Select Legend Settings
          </Typography>
          <Divider />
        </Grid>
        <LegendSettings selected={selected} />
        <Breakdown selected={selected} />
        <NumberFormatting selected={selected} />
        <AxisDataLabelsSettings selected={selected} />
        <AxisLimit selected={selected} />
        <AxisTooltipSettings selected={selected} />
      </Grid>
    </Box>
  )
}

import { useState } from 'react'

import Button from '@components/core/button'
import { Input } from '@components/core/input'
import { Label } from '@components/core/label'
import { Popover, PopoverContent, PopoverTrigger } from '@components/core/popover'

import { initComponentManagement } from '@store/actions/component-management'
import { useDispatch, useSelector } from '@store/index'
import { selectBasicConfig } from '@store/slices/component/basic-config'

import { IComponentData } from 'pages/component-management/types'

export const CopyConfig = () => {
  const [json, setJson] = useState('')
  const [isValid, setIsValid] = useState(true)
  const dispatch = useDispatch()

  const handleJsonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value
    setJson(input)

    try {
      JSON.parse(input)
      setIsValid(true)
    } catch (error) {
      setIsValid(false)
    }
  }

  const basicConfig = useSelector(selectBasicConfig)

  return (
    <div>
      <Popover>
        <PopoverTrigger asChild>
          <Button variant='outline'>Copy config</Button>
        </PopoverTrigger>

        <PopoverContent>
          <Label htmlFor='json-copy-config'>JSON</Label>
          <Input
            allowMultiline
            maxRows={15}
            id='json-copy-config'
            value={json}
            onChange={handleJsonChange}
            className='w-96'
            placeholder='Enter JSON here...'
          />
          <div className='mt-2'>
            {isValid ? (
              <div className='text-success'>
                Valid{' '}
                <Button
                  onClick={() => {
                    dispatch(
                      initComponentManagement({
                        ..._.omit(basicConfig, 'pending'),
                        component_attributes: JSON.parse(
                          json
                        ) as IComponentData['component_attributes']
                      } as IComponentData)
                    )
                  }}
                >
                  Populate
                </Button>
              </div>
            ) : (
              <div className='text-error'>Invalid</div>
            )}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}

import { ReactNode, useRef } from 'react'

import { restrictToVerticalAxis } from '@dnd-kit/modifiers'

import { restrictToCustomParentElement } from '@utils/dnd-utils'
import { humanizeFieldName } from '@utils/string-utils'

import { DataPill } from '@components/component-management/DataPill'
import { IconButton } from '@components/core/icon-button'
import { Cancel, DragIndicator, Icon } from '@components/icons'
import { SortableList } from '@components/sortable-list'

import { IAxis } from '../types/chart-builder-types'

export function SortableAxisItems({
  axes,
  onChangeAxes,
  onRemoveAxis,
  secondary
}: {
  axes: IAxis[]
  onChangeAxes: (axes: IAxis[]) => void
  onRemoveAxis: (id: string) => void
  secondary?: (p: { item: IAxis }) => ReactNode
}) {
  const parentRef = useRef<HTMLDivElement>(null)

  return (
    <div ref={parentRef}>
      <SortableList
        items={axes}
        setItems={onChangeAxes}
        dndContextModifers={[restrictToCustomParentElement(parentRef), restrictToVerticalAxis]}
        renderItem={({ draggableProps, setNodeRef, style, item, id }) => (
          <div ref={setNodeRef} style={style} className='flex items-center justify-between'>
            <div className='flex items-center gap-2'>
              <IconButton className='border-none p-0' {...draggableProps}>
                <Icon icon={<DragIndicator />} />
              </IconButton>
              <DataPill label={humanizeFieldName(item.label)} dataType={item.data_type} />
            </div>
            <div className='flex items-center gap-6'>
              {secondary?.({ item })}
              <IconButton className='border-none p-0' onClick={() => onRemoveAxis(id as string)}>
                <Icon icon={<Cancel />} />
              </IconButton>
            </div>
          </div>
        )}
      />
    </div>
  )
}

import { Fragment } from 'react'

import { Skeleton } from '@core/skeleton'

import { Separator } from '@components/core/separator'

export function PanelSkeleton() {
  return (
    <>
      {_.times(4, (idx: number) => (
        <Fragment key={idx}>
          {_.gt(idx, 0) && (
            <>
              <Skeleton className='mt-2 h-5 w-48' />
              <Separator className='my-2' />
            </>
          )}
          <div className='flex items-center justify-between'>
            <Skeleton className='h-8 w-32' />
            <div className='flex gap-1'>
              <Skeleton className='h-8 w-20' />
              <Skeleton className='h-8 w-2 px-3' />
            </div>
          </div>
          <div className='flex items-center justify-between'>
            <Skeleton className='h-8 w-48' />
            <div className='flex gap-1'>
              <Skeleton className='h-8 w-20' />
              <Skeleton className='h-8 w-2 px-3' />
            </div>
          </div>
          <div className='flex items-center justify-between'>
            <Skeleton className='h-8 w-24' />
          </div>
          <div className='flex items-center justify-between'>
            <Skeleton className='h-8 w-48' />
            <div className='flex gap-1'>
              <Skeleton className='h-8 w-20' />
              <Skeleton className='h-8 w-2 px-3' />
            </div>
          </div>
        </Fragment>
      ))}
    </>
  )
}

import { ReactNode } from 'react'

import { LinearProgress } from '@mui/material'

import { Separator } from '@components/core/separator'
import { Skeleton } from '@components/core/skeleton'
import { Text } from '@components/core/text'

export interface ComponentWidgetProps {
  title?: ReactNode | string
  titleSecondary?: ReactNode | string
  refetching?: boolean
  loading?: boolean
  subtitle?: ReactNode | string
  children?: ReactNode | string
}

const ComponentWidget = ({
  title,
  titleSecondary,
  subtitle,
  refetching,
  loading = false,
  children = null
}: ComponentWidgetProps) => {
  return (
    <div className='flex size-full flex-col overflow-hidden rounded-md border border-solid border-grey bg-white p-2'>
      <div className='flex flex-row gap-2'>
        <div className='flex items-center'>
          {loading && !title ? (
            <Skeleton className='h-[25px] w-[200px]' />
          ) : (
            <Text variant='h5'>{title}</Text>
          )}
        </div>
        {titleSecondary && <div>{titleSecondary}</div>}
      </div>

      {subtitle && <div>{subtitle}</div>}

      <Separator className='my-2' />

      {refetching ? (
        <LinearProgress color='primary' sx={{ '&.MuiLinearProgress-root': { mt: 0 } }} />
      ) : (
        <div className='mt-0 h-0'></div> // Empty Box
      )}

      <div className='mt-0 size-full flex-1'>{children}</div>
    </div>
  )
}

export const ComponentErrorWidget = ({
  errorMessage,
  title
}: {
  errorMessage: ReactNode | string
  title: string
}) => (
  <ComponentWidget title={title}>
    <div className='flex size-full flex-1 items-center justify-center'>{errorMessage}</div>
  </ComponentWidget>
)

export default ComponentWidget

import { ChargerFormFieldConfig, FieldTypes } from '@components/form/types'

import { DimensionNameObject } from 'components/control-panel/types'
import { prepareDimensionOptions } from 'utils/dimension-utils'
import * as Yup from 'yup'

export const EXPANDED = 'expanded'
const CURRENT = 'current'

const hierarchySelectorConfig: ChargerFormFieldConfig = {
  name: 'hierarchy',
  label: 'Hierarchy',
  type: FieldTypes.SELECT,
  options: [
    { value: CURRENT, label: 'Currently Expanded Rows' },
    { value: EXPANDED, label: 'Expanded to All Rows' }
  ],
  defaultValue: EXPANDED,
  required: true,
  validationSchema: Yup.string().required('This field is required').nullable()
}

export const financialStatementExporterConfig: ChargerFormFieldConfig[] = [hierarchySelectorConfig]

export const getTrialBalanceExporterConfig = (
  dimensions: DimensionNameObject
): ChargerFormFieldConfig[] => {
  const dimensionOptions = prepareDimensionOptions(dimensions)
  const trialBalanceExporterConfig: ChargerFormFieldConfig[] = [
    hierarchySelectorConfig,
    ...(dimensionOptions.length > 0
      ? [
          {
            name: 'dimension',
            label: 'Dimension',
            type: FieldTypes.SELECT,
            options: dimensionOptions,
            required: false
          }
        ]
      : [])
  ]

  return trialBalanceExporterConfig
}

// material-ui
import { Theme } from '@mui/material/styles'

// ==============================|| OVERRIDES - TOGGLE BUTTON ||============================== //

export default function ToggleButton(theme: Theme) {
  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: '0.46875rem 1rem',
          color: theme.palette.primary.darker,
          border: '1px solid ' + theme.palette.grey[200],
          '&.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
            border: '1px solid ' + theme.palette.grey[200],
            '&.Mui-selected': {
              border: '1px solid ' + theme.palette.primary.main
            },
            margin: 0
          },
          '&.MuiToggleButtonGroup-grouped:not(:last-of-type)': {
            border: '1px solid ' + theme.palette.grey[200],
            '&.Mui-selected': {
              border: '1px solid ' + theme.palette.primary.main
            },
            margin: 0
          },
          '&.Mui-disabled': {
            borderColor: theme.palette.divider,
            color: theme.palette.text.disabled
          },
          '&.Mui-selected': {
            border: '1px solid ' + theme.palette.primary.main,
            color: '#fff',
            background: theme.palette.primary.main,
            '&:hover': {
              background: theme.palette.primary.main
            }
          },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2
          }
        },
        sizeMedium: {
          height: '2.25rem'
        },
        sizeSmall: {
          padding: '0.25rem 0.75rem'
        },
        sizeActionBar: {
          height: '1.75rem'
        }
      }
    }
  }
}

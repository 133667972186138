import { useMemo, useRef } from 'react'

import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { localTimezone } from '@utils/date-utils'

import AgGrid from '@components/ag-grid'
import { DateCellRenderer } from '@components/ag-grid/custom-cell-renderer/date-cell-renderer'
import { JSONCellRenderer } from '@components/ag-grid/custom-cell-renderer/json-cell-renderer'
import { SQLCellRenderer } from '@components/ag-grid/custom-cell-renderer/sql-cell-renderer'
import { autoFitAllColumns } from '@components/ag-grid/utils'

import { useRegisterAgGridRefEffect } from 'contexts/grid-ref-context'

import { IActiveConnections, TokenProvider } from './types'

const columnDefsByProvider: Record<TokenProvider, ColDef[]> = {
  [TokenProvider.POSTGRESQL]: [
    { field: 'application_name', headerName: 'Application Name', resizable: true },
    {
      field: 'backend_start',
      headerName: `Backend Start (${localTimezone()})`,
      resizable: true,
      cellRenderer: DateCellRenderer
    },
    { field: 'client_addr', headerName: 'Client Address', resizable: true },
    { field: 'datname', headerName: 'Database Name', resizable: true },
    { field: 'pid', headerName: 'Process ID', resizable: true },
    {
      field: 'query',
      headerName: 'Query',
      minWidth: 200,
      maxWidth: 200,
      cellRenderer: SQLCellRenderer
    },
    { field: 'state', headerName: 'State', resizable: true },
    { field: 'usename', headerName: 'Username', resizable: true }
  ],
  [TokenProvider.MS_SQL]: [
    { field: 'session_id', headerName: 'Session ID', resizable: true },
    {
      field: 'login_time',
      headerName: `Login Time (${localTimezone()})`,
      resizable: true,
      cellRenderer: DateCellRenderer
    },
    { field: 'login_name', headerName: 'Login Name', resizable: true },
    { field: 'program_name', headerName: 'Program Name', resizable: true },
    { field: 'host_name', headerName: 'Host Name', resizable: true },
    { field: 'status', headerName: 'Status', resizable: true },
    { field: 'cpu_time', headerName: 'CPU Time', resizable: true },
    { field: 'memory_usage', headerName: 'Memory Usage', resizable: true },
    { field: 'total_scheduled_time', headerName: 'Scheduled Time', resizable: true },
    { field: 'total_elapsed_time', headerName: 'Elapsed Time', resizable: true },
    { field: 'reads', headerName: 'Reads', resizable: true },
    { field: 'writes', headerName: 'Writes', resizable: true }
  ],
  [TokenProvider.ORACLE_DB]: [
    { field: 'SID', headerName: 'Session ID', resizable: true },
    { field: 'SERIAL#', headerName: 'Serial Number', resizable: true },
    { field: 'STATUS', headerName: 'Status', resizable: true },
    { field: 'USERNAME', headerName: 'Username', resizable: true },
    { field: 'SERVICE_NAME', headerName: 'Service Name', resizable: true },
    { field: 'PROGRAM', headerName: 'Program', resizable: true },
    { field: 'SCHEMANAME', headerName: 'Schema Name', resizable: true },
    { field: 'EVENT', headerName: 'Event', resizable: true },
    { field: 'OSUSER', headerName: 'OS User', resizable: true },
    { field: 'MACHINE', headerName: 'Machine', resizable: true },
    { field: 'STATE', headerName: 'State', resizable: true },
    { field: 'WAIT_CLASS', headerName: 'Wait Class', resizable: true },
    { field: 'WAIT_TIME_MICRO', headerName: 'Wait Time (Microseconds)', resizable: true }
  ]
}

const getColumnDefsByProvider = (provider?: TokenProvider) => {
  return provider ? columnDefsByProvider[provider] : []
}

export function ActiveConnectionsList({
  data,
  isPending,
  tokenProvider
}: {
  data?: IActiveConnections
  isPending: boolean
  tokenProvider?: TokenProvider
}) {
  const activeConnections = useMemo(() => data ?? [], [data])
  const wrapperRef = useRef<HTMLDivElement>(null)
  const gridRef = useRef<AgGridReact>(null)

  useRegisterAgGridRefEffect(gridRef)

  const columnDefs: ColDef[] = useMemo(
    () => [
      ...getColumnDefsByProvider(tokenProvider),
      {
        colId: 'fullJSON',
        headerName: 'Full JSON',
        minWidth: 200,
        maxWidth: 200,
        cellRenderer: JSONCellRenderer,
        cellRendererParams: { showFullData: true }
      }
    ],
    [tokenProvider]
  )

  return (
    <div className='h-[calc(100vh-250px)] w-full' ref={wrapperRef}>
      <AgGrid
        style={{ height: '100%' }}
        ref={gridRef}
        autoSizeStrategy={{ type: 'fitCellContents' }}
        onGridSizeChanged={autoFitAllColumns}
        rowData={activeConnections}
        columnDefs={columnDefs}
        loading={isPending}
      />
    </div>
  )
}

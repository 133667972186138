import { useEffect } from 'react'

import { IPropsEffectProps } from '../types/module-types'
import { getSelfRef } from '../utils/module-utils'

export default function useSetGridVisibility({ propsRef, moduleName, props }: IPropsEffectProps) {
  const hideGrid = _.get(props, 'options.hideGrid', false)
  useEffect(() => {
    const axis = getSelfRef({ ...propsRef.current, moduleName })
    const renderer = axis.get('renderer')
    if (!renderer) return

    renderer.grid.template.set('forceHidden', hideGrid)
  }, [hideGrid, propsRef, moduleName])
}

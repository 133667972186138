import { SortDimensionFilters, selectedDimensionKeyFromDimensionType } from '@utils/dimension-utils'
import { capitalizeFirstLetter } from '@utils/string-utils'

import { Dimensions, DollarUnitEnum } from '@components/control-panel/types'
import { Text } from '@components/core/text'

import { usePageSelector } from '@store/index'
import { selectDollarUnit } from '@store/slices/action-bar'
import { selectDimensions } from '@store/slices/dimension'

import { useFetchDimensions } from 'queries/fetch-dimensions'

export const ShowListOfSelectedDimensions = () => {
  const { data: dimensions } = useFetchDimensions()
  const dollarUnit = usePageSelector(selectDollarUnit)

  const selectedDimensions = usePageSelector(selectDimensions)

  const isConsolidated =
    dimensions &&
    Object.entries(dimensions).every(([key, values]) => {
      const label = selectedDimensionKeyFromDimensionType(key as keyof Dimensions)
      const selectedValues = selectedDimensions[label]
      return !selectedValues || selectedValues.length === 0
    })

  return (
    <div className='flex min-h-[20px] gap-2 px-1'>
      {dollarUnit !== DollarUnitEnum.E && (
        <Text variant='details' className='font-bold'>
          {dollarUnit === DollarUnitEnum.K ? 'In Thousands' : 'In Millions'}
        </Text>
      )}

      {isConsolidated && (
        <Text variant='details' className='font-bold'>
          Consolidated
        </Text>
      )}
      {dimensions &&
        Object.entries(dimensions)
          .filter(([key]) => {
            const label = selectedDimensionKeyFromDimensionType(key as keyof Dimensions)
            return selectedDimensions[label]?.length > 0
          })
          .sort(([key1], [key2]) => {
            return SortDimensionFilters(key1, key2)
          })
          .map(([key, values]) => {
            const label = selectedDimensionKeyFromDimensionType(key as keyof Dimensions)
            const displayLabel = key
              .split('_')
              .map((word) => capitalizeFirstLetter(word))
              .join(' ')

            return (
              <Text
                key={key}
                className='inline-block min-w-32 max-w-[600px] truncate break-all'
                variant='details'
              >
                <span className='break-all font-bold'>
                  ({selectedDimensions[label]?.length}) {displayLabel}:
                </span>{' '}
                {selectedDimensions[label].map((value) => (values as any)[value]).join(', ')}
              </Text>
            )
          })}
    </div>
  )
}

import { useMemo, useRef } from 'react'

import { CellClassParams, ColDef, IAggFuncParams } from 'ag-grid-community'

import { useFinancialValueFormatter } from '@components/financial/shared/hooks/useFinancialValueFormatter'
import { useFinancialValueGetter } from '@components/financial/shared/hooks/useFinancialValueGetter'
import { formatFinancialDateHeaderUsingFrequency } from '@components/financial/utils/formatting-utils'
import { FinancialDataCellRenderer } from '@components/financial/utils/grid/financial-data-cell-renderer'
import { getDataColumns } from '@components/financial/utils/grid/get-data-columns'

import { ExpandedDateFilterInfo } from 'types/filter'

import { FinancialStatementType, FinancialStatementTypeEnum } from '../types'

export const useDataColumnDefinition = ({
  type,
  dateFilter
}: {
  type: FinancialStatementType
  dateFilter: ExpandedDateFilterInfo
}) => {
  const valueGetter = useFinancialValueGetter()
  const valueFormatter = useFinancialValueFormatter()
  const previousDataColumnsRef = useRef<ColDef[]>([])

  const TOTAL = 'total'

  return useMemo(() => {
    const colDefs = getDataColumns({
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
      frequency: dateFilter.frequency,
      hasTotals: _.includes(
        [FinancialStatementTypeEnum.CashFlow, FinancialStatementTypeEnum.IncomeStatement],
        type
      )
    }).map((key: string) => {
      const formattedDate = formatFinancialDateHeaderUsingFrequency(
        key,
        dateFilter.frequency,
        dateFilter.originalInfo.fiscalYearStart
      )

      return {
        field: `data.${key}`,
        lockPosition: key === TOTAL ? 'right' : undefined,
        aggFunc: (params: IAggFuncParams) => {
          // Total Nodes are always guaranteed to be the last child
          return _.last(params.values)
        },
        headerName: key === TOTAL ? 'Total' : formattedDate,
        width: 110,
        type: 'rightAligned',
        cellStyle: (params: CellClassParams) => {
          const defaultStyles = { textAlign: 'right', padding: '0 8px' }

          return defaultStyles
        },
        suppressHeaderMenuButton: true,
        valueGetter: valueGetter,
        valueFormatter: valueFormatter,
        // The params.value here is the value returned by valueGetter
        cellRenderer: FinancialDataCellRenderer
      } as ColDef
    })

    previousDataColumnsRef.current = colDefs

    return colDefs
  }, [
    dateFilter.startDate,
    dateFilter.endDate,
    dateFilter.frequency,
    dateFilter.originalInfo.fiscalYearStart,
    type,
    valueGetter,
    valueFormatter
  ])
}

import { useMemo, useRef } from 'react'

import { Popover, PopoverContent, PopoverTrigger } from '@core/popover'

import { IconButton } from '@components/core/icon-button'
import { ChargerForm, closePopover } from '@components/form/charger-form'
import { ChargerFormFieldConfig, FieldTypes, FormLayoutTypes } from '@components/form/types'
import { Icon } from '@components/icons'
import { Pencil } from '@components/icons/pencil'

import { useDispatch, useSelector } from '@store/index'
import {
  selectDescription,
  selectTitle,
  updateDescription,
  updateTitle
} from '@store/slices/component/basic-config'

import * as Yup from 'yup'

export function TitleDescriptionForm() {
  const popoverTriggerRef = useRef<HTMLButtonElement>(null)

  const dispatch = useDispatch()
  const title = useSelector(selectTitle)
  const description = useSelector(selectDescription)

  const fieldsConfig: ChargerFormFieldConfig[] = useMemo(
    () => [
      {
        name: 'title',
        label: 'Title',
        type: FieldTypes.TEXT_FIELD,
        defaultValue: title,
        validationSchema: Yup.string().required('Title is required')
      },
      {
        name: 'description',
        label: 'Description',
        type: FieldTypes.TEXT_FIELD,
        defaultValue: description
      }
    ],
    [title, description]
  )

  return (
    <Popover>
      <PopoverTrigger asChild>
        <IconButton className='m-0 border-none p-0' ref={popoverTriggerRef}>
          <Icon icon={<Pencil />} />
        </IconButton>
      </PopoverTrigger>

      <PopoverContent>
        <ChargerForm
          submitLabel='Save'
          onCancel={() => closePopover(popoverTriggerRef.current)}
          layout={FormLayoutTypes.POPOVER}
          onSubmit={(data) => {
            dispatch(updateTitle(data.title))
            dispatch(updateDescription(data.description))
            closePopover(popoverTriggerRef.current)
          }}
          fieldsConfig={fieldsConfig}
        />
      </PopoverContent>
    </Popover>
  )
}

import React from 'react'

import { cn } from '@utils/style-utils'

import { Label } from './label'
import { Switch } from './switch'

interface SwitchWithTitleProps {
  title: string
  id: string
  className?: string
  checked?: boolean
  onCheckedChange?: (checked: boolean) => void
  onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void
}

export const SwitchWithTitle: React.FC<SwitchWithTitleProps> = ({
  title,
  id,
  className,
  onCheckedChange,
  checked,
  onBlur
}) => {
  return (
    <div className={cn('flex items-center space-x-2', className)}>
      <Switch id={id} checked={checked} onCheckedChange={onCheckedChange} onBlur={onBlur} />
      <Label htmlFor={id} className='cursor-pointer'>
        {title}
      </Label>
    </div>
  )
}

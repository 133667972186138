import { useFetchNavigationTree } from 'queries/pages/fetch-navigation-tree'
import { MenuItemType, NavItemType } from 'types/menu'
import { Page, PageTreeItem, PageType, isBrowsablePage, isSidebarItem } from 'types/page'

// NOTE: We currently only support page, page_group and static_page in the Menu
// Category is not supported.
const processPage = (page: PageTreeItem): NavItemType | NavItemType[] | null => {
  if (isBrowsablePage(page)) {
    return {
      id: page.id?.toString(),
      title: page.title,
      type: MenuItemType.item,
      url: page.pageRoute,
      role: page.metaData?.allowed_roles || [],
      itemRelated: page.metaData?.itemRelated || false,
      feature_name: page.metaData?.feature_name || null,
      position: page.position || 0,
      children: []
    }
  } else if (_.includes([PageType.COLLECTION, PageType.PAGE_GROUP], page.layoutType)) {
    return {
      id: page.id?.toString(),
      title: page.title,
      type: _.isEqual(page.layoutType, PageType.PAGE_GROUP)
        ? MenuItemType.collapse
        : MenuItemType.group,
      children: _.compact(
        page.children
          ? page.children
              .filter((child: PageTreeItem) => isSidebarItem(child))
              .map((child: PageTreeItem) => processPage(child))
          : []
      ) as NavItemType[]
    }
  }
  return null
}

const MenuItems: () => { items: NavItemType[] } = () => {
  const { data: pagesTree } = useFetchNavigationTree()

  const generalMenuItems: NavItemType[] = []

  if (pagesTree && Array.isArray(pagesTree)) {
    pagesTree
      .filter((page: Page) => isSidebarItem(page))
      .forEach((page) => {
        const processed = processPage(page)
        if (processed) {
          if (Array.isArray(processed)) {
            generalMenuItems.push(...processed)
          } else {
            generalMenuItems.push(processed)
          }
        }
      })
  }

  const menuItems: { items: NavItemType[] } = {
    items: [
      {
        id: 'general',
        type: MenuItemType.group,
        children: [...generalMenuItems]
      }
    ]
  }

  return menuItems
}

export default MenuItems

import {
  Day,
  Month,
  convertYearMonthDayInfoToCalendarDate,
  getCalendarYearFromFiscalYear,
  getDaysInMonth,
  getDaysInPreviousMonth,
  getIndexFromDay,
  getIndexFromMonth,
  getNextMonthInfo,
  getPreviousMonthInfo
} from '@utils/date-utils'

import { cmpMonth } from '@components/control-panel/calendar-filter-popover/monthly-picker/utils'

import { YearMonthDayInfo } from 'types/filter'

export const clampMonthMax = (value: YearMonthDayInfo, fiscalYearStart?: Month) => {
  const day = Math.min(value.day, getDaysInMonth(value.year, value.month, fiscalYearStart))
  return { year: value.year, month: value.month, day: day }
}

const toDate = (info: YearMonthDayInfo, fiscalYearStart?: Month) => {
  const calendarDate = convertYearMonthDayInfoToCalendarDate(info, fiscalYearStart)
  return Date.UTC(calendarDate.year, getIndexFromMonth(calendarDate.month), calendarDate.day)
}

export const minInfo = (
  info1?: YearMonthDayInfo,
  info2?: YearMonthDayInfo,
  fiscalYearStart?: Month
) => {
  if (!info1) return info2
  if (!info2) return info1
  return toDate(info1, fiscalYearStart) - toDate(info2, fiscalYearStart) < 0 ? info1 : info2
}

export const maxInfo = (
  info1?: YearMonthDayInfo,
  info2?: YearMonthDayInfo,
  fiscalYearStart?: Month
) => {
  if (!info1) return info2
  if (!info2) return info1
  return toDate(info1, fiscalYearStart) - toDate(info2, fiscalYearStart) > 0 ? info1 : info2
}

export const clampInfo = (
  info: YearMonthDayInfo,
  minAllowed: YearMonthDayInfo | undefined,
  maxAllowed: YearMonthDayInfo | undefined,
  fiscalYearStart: Month | undefined
) => maxInfo(minInfo(info, maxAllowed, fiscalYearStart), minAllowed, fiscalYearStart)

export const outOfBounds = <T extends YearMonthDayInfo>(
  info: T,
  minAllowed?: T,
  maxAllowed?: T,
  fiscalYearStart?: Month
) => {
  if (!minAllowed && !maxAllowed) return false

  const infoTS = toDate(info, fiscalYearStart)

  return (
    (minAllowed != null && infoTS < toDate(minAllowed, fiscalYearStart)) ||
    (maxAllowed != null && infoTS > toDate(maxAllowed, fiscalYearStart))
  )
}

// navigationMode is the mode of the picker that is being navigated,
// selecting a month or selecting a year, prev and next button work on months,
// year select view works on year
export const navigationClamp = <T extends YearMonthDayInfo>(
  info: T,
  navigationMode: 'month' | 'year',
  fiscalYearStart?: Month,
  minAllowed?: T,
  maxAllowed?: T
) => {
  if (minAllowed && info.year === minAllowed.year) {
    const cmpVal = cmpMonth(info.month, minAllowed.month, fiscalYearStart)
    if (navigationMode === 'year' && cmpVal < 0) {
      return minAllowed
    }
    if (navigationMode === 'month' && cmpVal === 0) {
      return maxInfo(info, minAllowed)!
    }
  }

  if (maxAllowed && info.year === maxAllowed.year) {
    const cmpVal = cmpMonth(info.month, maxAllowed.month, fiscalYearStart)
    if (navigationMode === 'year' && cmpVal > 0) {
      return maxAllowed
    }
    if (navigationMode === 'month' && cmpVal === 0) {
      return minInfo(info, maxAllowed)!
    }
  }
  return info
}

export const getCalendarOptions = (
  year: number,
  month: Month,
  weekStart: Day,
  fiscalYearStart?: Month
) => {
  const weekStartDay = getIndexFromDay(weekStart)
  const calendarYear = getCalendarYearFromFiscalYear(year, month, fiscalYearStart)

  const daysInPrevMonth = getDaysInPreviousMonth(year, month, fiscalYearStart)

  const dayOneWeekDay = new Date(calendarYear, getIndexFromMonth(month), 1).getDay()

  const daysInMonth = getDaysInMonth(year, month, fiscalYearStart)

  const calendarDays = new Array(daysInMonth).fill(0).map((_, idx) => ({
    year: year,
    month: month,
    day: idx + 1
  }))

  const previousMonthInfo = getPreviousMonthInfo(year, month, fiscalYearStart)

  // M T W T F S S
  //             1
  const adjustedDayOneWeekDay = (dayOneWeekDay - weekStartDay + 7) % 7
  const prevCalendarDaysCount = adjustedDayOneWeekDay
  const prevCalendarDays = new Array(prevCalendarDaysCount).fill(0).map((_, idx) => ({
    year: previousMonthInfo.year,
    month: previousMonthInfo.month,
    day: daysInPrevMonth - prevCalendarDaysCount + idx + 1
  }))

  const numColumns = 7
  const numRows = 6

  const nextMonthInfo = getNextMonthInfo(year, month, fiscalYearStart)

  const nextCalendarDays = new Array(
    numRows * numColumns - calendarDays.length - prevCalendarDays.length
  )
    .fill(0)
    .map((_, idx) => ({
      year: nextMonthInfo.year,
      month: nextMonthInfo.month,
      day: idx + 1
    }))

  return prevCalendarDays.concat(calendarDays).concat(nextCalendarDays)
}

import * as React from 'react'

import * as SwitchPrimitives from '@radix-ui/react-switch'
import { cn } from '@utils/style-utils'

interface SwitchProps extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {
  size?: 'default' | 'small' | 'large'
}

const Switch = React.forwardRef<React.ElementRef<typeof SwitchPrimitives.Root>, SwitchProps>(
  ({ className, size = 'default', ...props }, ref) => {
    const sizeClasses = {
      small: 'h-4 w-8',
      default: 'h-5 w-9',
      large: 'h-6 w-11'
    }

    const thumbSizeClasses = {
      small: 'h-3 w-3 data-[state=checked]:translate-x-4',
      default: 'h-4 w-4 data-[state=checked]:translate-x-4',
      large: 'h-5 w-5 data-[state=checked]:translate-x-5'
    }

    return (
      <SwitchPrimitives.Root
        className={cn(
          'peer twp inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-grey-light shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-primary data-[state=checked]:bg-primary data-[state=unchecked]:bg-grey-light',
          sizeClasses[size],
          className
        )}
        {...props}
        ref={ref}
      >
        <SwitchPrimitives.Thumb
          className={cn(
            'pointer-events-none block rounded-full bg-white shadow-lg ring-0 transition-transform data-[state=unchecked]:translate-x-0',
            thumbSizeClasses[size]
          )}
        />
      </SwitchPrimitives.Root>
    )
  }
)

Switch.displayName = SwitchPrimitives.Root.displayName

export { Switch }

import { useEffect, useState } from 'react'

import Button from '@components/core/button'
import { Text } from '@components/core/text'
import Tooltip from '@components/core/tooltip'
import LogoIcon from '@components/logo/LogoIcon'

import useAuth from '@hooks/useAuth'

import { Separator } from 'components/core/separator'
import LogRocket from 'logrocket'

import DesktopSection from './desktop-section'

function getSessionID(sessionURL: string | null): string {
  if (!sessionURL) return ''

  return sessionURL?.split('/s/')[1]?.split('/')[0]
}

const HeaderContent = () => {
  const { isChargerSuperUser } = useAuth()
  const [sessionURL, setSessionURL] = useState<string | null>(null)

  useEffect(() => {
    LogRocket.getSessionURL((url) => {
      setSessionURL(url)
    })
  }, [])

  const [showClipboardTooltip, setShowClipboardTooltip] = useState(false)

  return (
    <div className='flex items-center'>
      {sessionURL && isChargerSuperUser() && (
        <Tooltip title='Copied to clipboard' open={showClipboardTooltip}>
          <Button
            variant='text'
            className='cursor-pointer focus:border-transparent'
            onClick={() => {
              navigator.clipboard.writeText(sessionURL)
              setShowClipboardTooltip(true)
              setTimeout(() => setShowClipboardTooltip(false), 1000)
            }}
          >
            <Text variant='details'>{getSessionID(sessionURL)}</Text>
          </Button>
        </Tooltip>
      )}

      <DesktopSection />
      <Separator orientation='vertical' className='h-10' />
      <div className='flex items-center px-4'>
        <LogoIcon className='w-6' />
      </div>
    </div>
  )
}

export default HeaderContent

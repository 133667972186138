import * as React from 'react'

import * as LabelPrimitive from '@radix-ui/react-label'
import { cn } from '@utils/style-utils'

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => (
  <div className={'twp'}>
    <LabelPrimitive.Root
      ref={ref}
      className={cn(
        'font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
        className
      )}
      {...props}
    />
  </div>
))
Label.displayName = LabelPrimitive.Root.displayName

export { Label }

import { useEffect } from 'react'

import * as am5 from '@amcharts/amcharts5'
import * as am5hierarchy from '@amcharts/amcharts5/hierarchy'

import { useLatestRef } from 'hooks/useLatestRef'

import { IModule, IModuleAttributes, IModuleUseInstance, ModuleName } from '../types/module-types'
import { assignRef, getRef, getSelfRef } from '../utils/module-utils'

const moduleName = ModuleName.treeMap
const treeMapModule: IModule<ModuleName.treeMap> = {
  name: moduleName
}

treeMapModule.init = (props) => {
  const root: am5.Root = getRef({ ...props, moduleName: ModuleName.root })

  const container = root.container.children.push(
    am5.Container.new(root, {
      width: am5.percent(100),
      height: am5.percent(100),
      layout: root.verticalLayout
    })
  )

  const series = container.children.push(
    am5hierarchy.Treemap.new(root, {
      singleBranchOnly: false,
      downDepth: 1,
      upDepth: -1,
      initialDepth: 2,
      valueField: 'value',
      categoryField: 'name',
      childDataField: 'children',
      nodePaddingOuter: 0,
      nodePaddingInner: 0
    })
  )

  series.rectangles.template.setAll({
    strokeWidth: 2
  })

  assignRef({ ...props, moduleName, item: series })
}

const useInstance: IModuleUseInstance<ModuleName.treeMap> = (instance, props) => {
  const propsRef = useLatestRef(props)

  useEffect(() => {
    const series = getSelfRef({ ...propsRef.current, moduleName })
    series.data.setAll(instance.data)
    series.set('selectedDataItem', series.dataItems[0])
  }, [instance.data, propsRef])
}

treeMapModule.useInstance = useInstance

export const treeMapAttributes: IModuleAttributes<ModuleName.treeMap> = (p) => ({
  module: treeMapModule,
  ...p
})

export default treeMapModule

import { useMutation, useQueryClient } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'
import { errorSnack } from 'utils/snackbar-utils'

import type { ICustomField } from '../types/query-builder-types'

export const useUpdateCustomField = () => {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const business_id = user?.business_id!

  return useMutation({
    mutationFn: ({ body, id }: { body: Partial<ICustomField>; id: number }) => {
      return axiosServices.put(`/businesses/${business_id}/custom_fields/${id}`, body)
    },
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['fetch-models-detail', business_id] })
      queryClient.invalidateQueries({ queryKey: ['fetch-custom-field', business_id, variables.id] })
    },
    onError() {
      errorSnack('Error Creating Custom Field')
    }
  })
}

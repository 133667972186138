import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

import { useLatestRef } from 'hooks/useLatestRef'

import { useSetInstanceData } from '../hooks'
import { IModule, IModuleAttributes, IModuleUseInstance, ModuleName } from '../types/module-types'
import { setAxisCellStartEndLocation } from '../utils/clustered-series-utils'
import convertCategoryLabelsToString from '../utils/convert-category-labels-to-string'
import { assignRef, getRef } from '../utils/module-utils'

const moduleName = ModuleName.categoryXAxis

const categoryXAxisModule: IModule<ModuleName.categoryXAxis> = {
  name: moduleName
}

categoryXAxisModule.init = (props) => {
  const root: am5.Root = getRef({ ...props, moduleName: ModuleName.root })
  const chart: am5xy.XYChart = getRef({ ...props, moduleName: ModuleName.xyChart })
  const tooltip: am5.Tooltip = getRef({ ...props, moduleName: ModuleName.tooltip })

  const {
    options: {
      xAxisKey = 'category',
      inversed = false,
      // global options
      clusteredSeries = false
    }
  } = props

  const xRenderer = am5xy.AxisRendererX.new(root, {
    inversed,
    minorGridEnabled: true,
    minGridDistance: 1
  })
  xRenderer.labels.template.setAll({
    oversizedBehavior: 'wrap',
    textAlign: 'center',
    fontSize: '11px'
  })
  xRenderer.grid.template.set('visible', false)

  if (clusteredSeries) {
    setAxisCellStartEndLocation(xRenderer)
  }

  const xAxis = chart.xAxes.push(
    am5xy.CategoryAxis.new(root, {
      maxDeviation: 0,
      categoryField: xAxisKey,
      renderer: xRenderer,
      tooltip
    })
  )

  convertCategoryLabelsToString({ renderer: xRenderer, axisKey: xAxisKey })

  xAxis.onPrivate('cellWidth', function (cellWidth) {
    xRenderer.labels.template.set('maxWidth', cellWidth)
  })

  assignRef({ ...props, moduleName, item: xAxis })
}

const useInstance: IModuleUseInstance<ModuleName.categoryXAxis> = (instance, props) => {
  const propsRef = useLatestRef(props)

  useSetInstanceData({ instance, moduleName, propsRef })
}

categoryXAxisModule.useInstance = useInstance

export const categoryXAxisAttributes: IModuleAttributes<ModuleName.categoryXAxis> = (p) => ({
  module: categoryXAxisModule,
  ...p
})

export default categoryXAxisModule

export function countFractionDigits(num: number): number {
  const numStr: string = num.toString()

  if (numStr.includes('e')) {
    return handleScientificNotation(numStr)
  } else {
    return handleStandardNotation(numStr)
  }
}

export function handleScientificNotation(numStr: string): number {
  const [base, exponentStr] = numStr.split('e')
  const exponent: number = parseInt(exponentStr, 10)
  const decimalSplit: string[] = base.split('.')
  const fractionDigits: number = decimalSplit.length > 1 ? decimalSplit[1].length : 0

  if (exponent < 0) {
    return fractionDigits + Math.abs(exponent)
  } else {
    return Math.max(0, fractionDigits - exponent)
  }
}

export function handleStandardNotation(numStr: string): number {
  const parts: string[] = numStr.split('.')
  return parts.length === 2 ? parts[1].length : 0
}

function safeMaxDecimals(maxDecimals: number): number {
  return typeof maxDecimals === 'number' ? Math.min(3, Math.max(0, maxDecimals)) : 0
}

const numberFormatBuilder = (fractionDigits: number) =>
  new Intl.NumberFormat('en-US', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  })

// predefined number formatters for efficiency ~50x faster
const numberFormatters = [
  numberFormatBuilder(0),
  numberFormatBuilder(1),
  numberFormatBuilder(2),
  numberFormatBuilder(3)
]

export const formatNumber = (value: number, maxDecimals: number = 0) => {
  return numberFormatters[safeMaxDecimals(maxDecimals)].format(value)
}

// this multiplies the number by 100 and adds a percentage sign
const percentageFormatBuilder = (fractionDigits: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  })

// predefined percentage formatters for efficiency ~50x faster
const percentageFormatters = [
  percentageFormatBuilder(0),
  percentageFormatBuilder(1),
  percentageFormatBuilder(2),
  percentageFormatBuilder(3)
]

export const formatPercentage = (value: number, maxDecimals: number = 0) => {
  if (_.isEqual(value, 0)) return '-'

  const isNegative = value < 0

  const formattedValue = percentageFormatters[safeMaxDecimals(maxDecimals)].format(Math.abs(value))

  return isNegative ? `(${formattedValue})` : formattedValue
}

const formatAmount = (
  value: number,
  options?: Partial<{ precision: number; withDollarUnit: boolean; replaceZeroWithHyphen: boolean }>
) => {
  if (_.isEqual(value, '')) return ''
  const precision = _.get(options, 'precision', 0)
  const withDollarUnit = _.get(options, 'withDollarUnit', false)
  const replaceZeroWithHyphen = _.get(options, 'replaceZeroWithHyphen', false)
  const formattedValue = formatNumber(Math.abs(value), precision)

  if (replaceZeroWithHyphen && (_.isEqual(formattedValue, '0') || _.isEqual(formattedValue, '-0')))
    return '-'

  const dollarUnit = withDollarUnit ? '$' : ''

  return _.lt(value, 0) ? `(${dollarUnit}${formattedValue})` : `${dollarUnit}${formattedValue}`
}

export const formatFinancialAmount = (value: number, precision: number = 0) =>
  formatAmount(value, { precision, withDollarUnit: false, replaceZeroWithHyphen: true })

export const formatAmountWithDollarUnit = (value: number, precision: number = 0) =>
  formatAmount(value, { precision, withDollarUnit: true, replaceZeroWithHyphen: false })

export function standardDeviation(array: number[]) {
  if (_.isEmpty(array)) return 0
  const mean = _.mean(array)

  // Calculate the variance
  const variance = _.mean(_.map(array, (num) => Math.pow(num - mean, 2)))

  // Return the square root of the variance (standard deviation)
  return Math.sqrt(variance)
}

export const valueWithinStdDev = ({
  value,
  stddevCount,
  mean,
  stddev
}: {
  value: number
  stddevCount: number
  mean: number
  stddev: number
}) => {
  if (_.lt(stddevCount, 0)) {
    return value <= mean && value >= mean + stddevCount * stddev
  } else {
    return value >= mean && value <= mean + stddevCount * stddev
  }
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'
import { errorSnack, successSnack } from 'utils/snackbar-utils'

import { IAPIErrorType } from '../types'

export const usePublishComponent = (id?: number) => {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const business_id = user?.business_id!

  return useMutation({
    mutationFn: ({ id: idArg }: { id?: number }) => {
      return axiosServices.post(`/businesses/${business_id}/components/${idArg || id}/publish`, {})
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(['fetch-component', business_id, data.id], data)
      successSnack('Component Published Successfully')
    },
    onError: (error: IAPIErrorType) => {
      let message = 'Error changing component status to published'
      if (error?.error?.component_attributes) {
        message = error?.error?.component_attributes[0]
      }

      errorSnack(message)
    }
  })
}

import { FilterModel, SortModelItem } from 'ag-grid-community'

import { useQuery } from '@tanstack/react-query'

import { snakeCaseKeys } from '@utils/case-conversion'

import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'

interface Body {
  filterModel: FilterModel
  sortModel: SortModelItem[]
  limit: number
  page: number
}

export const fetchModelPreview = async (
  business_id: number,
  modelName: string,
  body: Body
): Promise<{ headers: any[]; data: any[]; count: number }> => {
  const response = await axiosServices.post(
    `/businesses/${business_id}/models/${modelName}/preview`,
    snakeCaseKeys(body, ['filterModel', 'sortModel'])
  )

  return response.data
}

export const useFetchModelPreview = ({ modelName, ...rest }: { modelName: string } & Body) => {
  const { user } = useAuth()
  const business_id = user?.business_id!

  return useQuery({
    queryKey: ['fetch-model-preview', business_id, modelName, rest],
    queryFn: () => fetchModelPreview(business_id, modelName, rest),
    enabled: !!business_id && !!modelName
  })
}

import { useEffect, useRef, useState } from 'react'

import { Label } from '@core/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@core/select'
import { Text } from '@core/text'

import Button from '@components/core/button'

import { useSelector } from '@store/index'
import { selectTitle } from '@store/slices/component/basic-config'
import { selectChartConfig } from '@store/slices/component/chart-config'

import ErrorBoundary from 'components/ErrorBoundary'
import { ChartErrorWidget, ChartLoadingWidget } from 'components/chart/chart-container'
import useChangeDetection from 'hooks/use-change-detection'

import useChartPropsRetriever from '../chart-builder/useChartPropsRetriever'
import DynamicChart from '../common/dynamic-chart'
import { useQueryTransformedResultContext } from '../contexts/query-transformed-result-context'

const MIN_WIDTH = '480px'
const MAX_WIDTH = '720px'
const FULL_WIDTH = '100%'
const MIN_HEIGHT = '270px'
const MAX_HEIGHT = '510px'

function Chart({ width, height }: { width: string; height: string }) {
  const title = useSelector(selectTitle)
  const chartConfig = useSelector(selectChartConfig)
  const { data, metadata, drilldowns, isFetching, errorMessage } =
    useQueryTransformedResultContext()
  const [chartProps, chartError] = useChartPropsRetriever({ data, metadata, config: chartConfig })!
  const [chartKey, triggerChange] = useChangeDetection()
  useEffect(() => triggerChange(), [chartProps, triggerChange])

  return (
    <div className='max-w-full rounded-md shadow-brand' style={{ width, height }}>
      {errorMessage || chartError ? (
        <ErrorFallback title={title} message={errorMessage || chartError} />
      ) : isFetching || !chartProps ? (
        <ChartLoadingWidget title={title} />
      ) : (
        <DynamicChart key={chartKey} {...chartProps} title={title} drilldowns={drilldowns} />
      )}
    </div>
  )
}

const ErrorFallback = ({
  message = 'Error creating chart',
  title,
  triggerReset,
  setErrored
}: {
  message?: string | null
  title: string
  triggerReset?: () => void
  setErrored?: () => void
}) => {
  setErrored?.()
  return (
    <div className='size-full'>
      <ChartErrorWidget
        title={title}
        errorMessage={
          <div className='flex size-full flex-col items-center justify-center gap-2'>
            <Text variant='body'>{message}</Text>
            {!!triggerReset && (
              <Button variant='outline' onClick={() => triggerReset()}>
                Refresh
              </Button>
            )}
          </div>
        }
      />
    </div>
  )
}

export const ChartPreview = () => {
  const title = useSelector(selectTitle)
  const [width, setWidth] = useState(MAX_WIDTH)
  const [height, setHeight] = useState(MIN_HEIGHT)

  const [changeKey, triggerChange] = useChangeDetection()
  const erroredRef = useRef(false)
  const config = useSelector(selectChartConfig)

  useEffect(() => {
    if (!erroredRef.current) return

    erroredRef.current = false
    triggerChange()
  }, [config, triggerChange])

  return (
    <div className='flex size-full flex-col'>
      <div className='grid flex-1 place-items-center overflow-auto'>
        <ErrorBoundary
          key={changeKey}
          fallback={(message) => {
            return <ErrorFallback message={message} title={title} />
          }}
        >
          <Chart width={width} height={height} />
        </ErrorBoundary>
      </div>
      <div className='flex justify-start space-x-4 bg-white p-1'>
        <div className='flex items-center gap-2'>
          <Label htmlFor='simple-select' className='font-semibold'>
            Width:
          </Label>
          <Select value={width} onValueChange={(width) => setWidth(width)}>
            <SelectTrigger className='w-[90px]'>
              <SelectValue placeholder='Width' />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={MAX_WIDTH}>MAX</SelectItem>
              <SelectItem value={MIN_WIDTH}>MIN</SelectItem>
              <SelectItem value={FULL_WIDTH}>{FULL_WIDTH}</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className='flex items-center gap-2'>
          <Label htmlFor='simple-select' className='font-semibold'>
            Height:
          </Label>
          <Select value={height} onValueChange={(height) => setHeight(height)}>
            <SelectTrigger className='w-[90px]'>
              <SelectValue placeholder='Height' />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={MAX_HEIGHT}>MAX</SelectItem>
              <SelectItem value={MIN_HEIGHT}>MIN</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  )
}

import { ReactNode } from 'react'

import { Box, Grid } from '@mui/material'

import AuthBackground from 'assets/images/auth/AuthBackground'

import AuthCard from './AuthCard'

interface Props {
  children: ReactNode
  cardClassName?: string
}

const AuthWrapper = ({ children, cardClassName }: Props) => (
  <Box
    sx={{
      minHeight: '100vh',
      background: (theme) => theme.palette.primary.main,
      '& .MuiFormHelperText-root.Mui-error': {
        textAlign: 'center'
      }
    }}
  >
    <AuthBackground />
    <Grid item container justifyContent='center' alignItems='center' sx={{ minHeight: '100vh' }}>
      <AuthCard className={cardClassName}>{children}</AuthCard>
    </Grid>
  </Box>
)

export default AuthWrapper

// action - account reducer
export const LOGIN = '@auth/LOGIN'
export const LOGOUT = '@auth/LOGOUT'
export const REGISTER = '@auth/REGISTER'

export const MASQUARADE = 'MASQUARADE'
export const REVERSE_MASQUARADE = 'REVERSE_MASQUARADE'
export const CurrentUserServiceToken = 'CurrentUserServiceToken'
export const CurrentUser = 'CurrentUser'
export const ServiceToken = 'ServiceToken'
export const User = 'User'
export const Masquarading = 'Masquarading'
export const HANDLE_BUSINESS_SELECTOR_CHANGE = 'HANDLE_BUSINESS_SELECTOR_CHANGE'
export const HANDLE_AUTOMAP_UPDATE = 'HANDLE_AUTOMAP_UPDATE'

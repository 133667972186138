import { useDroppable } from '@dnd-kit/core'

import { cn } from '@utils/style-utils'

export const DroppableContainer = ({
  id,
  children,
  isOverContainer,
  dropDisabled
}: {
  id: string
  children: React.ReactNode
  isOverContainer: boolean
  dropDisabled?: boolean
}) => {
  const { setNodeRef } = useDroppable({ id })

  return (
    <div
      ref={setNodeRef}
      className={cn(
        'flex flex-col gap-2 rounded border border-dashed border-grey p-2',
        isOverContainer && dropDisabled && 'bg-error-lighter'
      )}
    >
      {children}
    </div>
  )
}

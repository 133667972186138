import { humanizeFieldName } from '@utils/string-utils'

import { ChevronRight } from '@components/icons'

import { IModel, ModelType } from 'pages/component-management/types/query-builder-types'

interface ModelSelectorProps {
  models: IModel[]
  renderName?: (name: string) => string | undefined // render prop for schema renaming
  onSelect?: (model: IModel) => void
}

export function ModelSelector(props: ModelSelectorProps) {
  const { models, renderName, onSelect = _.noop } = props

  const tables = _.sortBy(
    models.filter((model) => model.type === ModelType.table),
    'name'
  )

  return (
    <div className='h-full w-[361px] overflow-scroll rounded-lg border border-solid border-grey bg-white shadow-brand'>
      <div className='mb-1.5 mt-1 px-2 text-inline-form-title'>Data Selector</div>

      <Header title='KPIs' />
      <div>
        <Row name='List of KPIs' onClick={() => alert('🛑 KPI selection not available yet')} />
      </div>

      <Header title='Tables' />
      <div>
        {tables.map((model) => (
          <Row
            key={model.name}
            name={humanizeFieldName(model.name)}
            displayName={renderName?.(model.name)}
            onClick={() => onSelect(model)}
          />
        ))}
      </div>
    </div>
  )
}

function Header({ title }: { title: string }) {
  return (
    <div className='border-x-0 border-y border-solid border-grey-lighter px-2 py-1.5 text-inline-form-title'>
      {title}
    </div>
  )
}

function Row({
  name,
  displayName,
  onClick
}: {
  name: string
  displayName?: string | undefined
  onClick?: () => void
}) {
  return (
    <div
      className='flex cursor-pointer items-center justify-between border-0 border-b border-solid border-grey-lighter py-2.5 pl-4 pr-2 last:border-b-0 hover:bg-grey-lighter'
      onClick={onClick}
    >
      <div>{displayName || name}</div>
      <ChevronRight />
    </div>
  )
}

import { shouldRender } from '@utils/layouts/filter-render-utils'
import { sortSearchFilters } from '@utils/obj-utils'

import { useLayoutContext } from '../../contexts/LayoutContext'
import ControlComponent from './control-component'
import { ControlPanel } from './index'

import './filters.css'

interface FilterProps {
  renderer: 'page' | 'component'
  filters: any
}

const Filters = ({ renderer = 'component', filters = {} }: FilterProps) => {
  const sortedFilters = sortSearchFilters(filters)
  const { layoutData, layoutStructure } = useLayoutContext()
  const pageFilters: {} = renderer === 'component' ? layoutData?.settings?.search_filters : filters

  // TODO: Remove these conditionals. This is to handle conflicting issue due to old approach and new approach of storing layout_structure
  const structure: any[] = renderer === 'component' ? layoutStructure : layoutData?.layout_structure

  return (
    <div className={`filter-inputs-container filter-inputs-container-${renderer}`}>
      <ControlPanel>
        {_.keys(sortedFilters).map((key: string, data: any) => {
          const render = shouldRender(renderer, pageFilters, sortedFilters[key], structure, key)
          const componentProps = {
            filter: sortedFilters[key]
          }

          return (
            <ControlComponent
              key={key}
              name={key}
              shouldRender={render}
              componentProps={componentProps}
            />
          )
        })}
      </ControlPanel>
    </div>
  )
}

export default Filters

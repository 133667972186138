import { generateDateList } from '@utils/date-utils'

import { Frequency } from 'types/filter'

export const getDataColumns = ({
  startDate,
  endDate,
  frequency,
  hasTotals = false
}: {
  startDate?: string
  endDate?: string
  frequency?: Frequency
  hasTotals?: boolean
}) => {
  let dateList = generateDateList(startDate, endDate, frequency)

  if (hasTotals) {
    dateList = [...dateList, 'total']
  }

  return dateList
}

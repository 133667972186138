import { PayloadAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'

import { FilterModel } from 'ag-grid-community'

import { isBlank } from '@utils/lodash'

import { IComponentSorts } from 'pages/component-management/types/component-types'
import { RootState } from 'store'

export type TableParameters = {
  componentId: string
  filterModel: FilterModel | null
  sortModel: IComponentSorts
}

const tableParametersAdapter = createEntityAdapter({
  selectId: (tableParameters: TableParameters) => tableParameters.componentId
})

const defaultValue = { filterModel: null, sortModel: [] }

const initialState = tableParametersAdapter.getInitialState()

const findOrCreateEntity = (state: typeof initialState, componentId: string) => {
  let entity = state.entities[componentId]
  if (!entity) {
    tableParametersAdapter.addOne(state, _.assign({ componentId }, _.cloneDeep(defaultValue)))
    entity = state.entities[componentId]
  }
  return entity
}

const tableParametersSlice = createSlice({
  name: '@COMPONENT/TABLE_PARAMETERS',
  initialState: tableParametersAdapter.getInitialState(),
  reducers: {
    modifyFilterModel: (
      state,
      action: PayloadAction<{ componentId: string; filterModel: FilterModel | null }>
    ) => {
      const { componentId, filterModel } = action.payload
      const entity = findOrCreateEntity(state, componentId)
      entity.filterModel = isBlank(filterModel) ? null : filterModel
    },
    modifySortModel: (
      state,
      action: PayloadAction<{ componentId: string; sortModel: IComponentSorts }>
    ) => {
      const { componentId, sortModel } = action.payload
      const entity = findOrCreateEntity(state, componentId)
      entity.sortModel = sortModel
    }
  }
})

// Reducer
export const tableParametersReducer = tableParametersSlice.reducer

// Selectors
const selectTableParametersState = (state: RootState) =>
  _.get(state, ['component', 'tableParameters'])

export const selectFilterModel = (componentId: string) => {
  return createSelector(selectTableParametersState, (tableParameters) =>
    _.get(tableParameters, ['entities', componentId, 'filterModel'])
  )
}

export const selectFilterModels = (componentIds: number[]) => {
  return createSelector(selectTableParametersState, (tableParameters) => {
    // Create an object that maps each componentId to its filterModel
    return componentIds.reduce(
      (acc, componentId) => {
        const filterModel = _.get(tableParameters, ['entities', componentId, 'filterModel'])

        // Only add to the result if the filterModel exists
        if (filterModel) {
          acc[componentId] = filterModel
        }

        return acc
      },
      {} as Record<number, any>
    )
  })
}

export const selectSortModels = (componentIds: number[]) => {
  return createSelector(selectTableParametersState, (tableParameters) => {
    // Create an object that maps each componentId to its sortModel
    return componentIds.reduce(
      (acc, componentId) => {
        const sortModel = _.get(tableParameters, ['entities', componentId, 'sortModel'])

        // Only add to the result if the sortModel exists
        if (sortModel) {
          acc[componentId] = sortModel
        }

        return acc
      },
      {} as Record<number, any>
    )
  })
}

export const selectSortModel = (componentId: string) => {
  return createSelector(selectTableParametersState, (tableParameters) =>
    _.get(tableParameters, ['entities', componentId, 'sortModel'])
  )
}

// Actions
export const { modifyFilterModel, modifySortModel } = tableParametersSlice.actions

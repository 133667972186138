import { PREVIOUS_PERIOD_SUFFIX } from '@pages/component-management/types/shared-types'

import { cursorAttributes } from 'components/chart/modules/cursor'
import { dataProcessorAttributes } from 'components/chart/modules/data-processor'
import { externalLegendAttributes } from 'components/chart/modules/external-legend'
import { freeFloatingTooltipAttributes } from 'components/chart/modules/free-floating-tooltip'
import { tooltipAttributes } from 'components/chart/modules/tooltip'
import { xyChartAttributes } from 'components/chart/modules/xy-chart'
import {
  IGlobalOptions,
  IModuleOptions,
  ITooltipPropsData,
  ModuleName
} from 'components/chart/types/module-types'
import { ChartColorsKeys } from 'themes/theme/charger-colors-tw'

import {
  ChartSubtitleType,
  IAxis,
  IChartActions,
  IChartAdditionalMetadata,
  IChartSettings,
  SeriesType,
  TooltipHeadingType
} from '../../types/chart-builder-types'
import { IDataType, IMetadata } from '../../types/component-types'
import { XAxisModule, YAxisModule } from './axis-modules'
import ChartModules from './chart-modules'
import SeriesFactory, { SeriesModule } from './series-factory'
import { areAxesStacked as areAxesStackedUtil } from './utils'

export type XYChartModulesProps = {
  selectedXAxes: IAxis[]
  selectedYAxes: IAxis[]
  flipAxis: boolean
  settings: IChartSettings
  metadata: IMetadata & IChartAdditionalMetadata
  actions?: IChartActions
}

export default class XYChartModules extends ChartModules {
  static create({
    selectedXAxes,
    selectedYAxes,
    flipAxis,
    metadata,
    settings,
    actions
  }: XYChartModulesProps) {
    const chart = new XYChartModules(
      flipAxis,
      selectedXAxes,
      selectedYAxes,
      settings,
      metadata,
      actions
    )
    return chart.create()
  }

  constructor(
    public flipAxis: boolean,
    selectedXAxes: IAxis[],
    selectedYAxes: IAxis[],
    settings: IChartSettings,
    metadata: IMetadata & IChartAdditionalMetadata,
    actions?: IChartActions
  ) {
    super(metadata, actions)

    this.waterfallSeriesPresent = _.some(selectedYAxes, { seriesType: SeriesType.Waterfall })
    this.settings = this.resolvedSettings(settings)
    this.xAxis = this.prepareXAxis(
      this.currentDrilldownConfig ? [this.currentDrilldownConfig] : selectedXAxes
    )
    this.yAxes = this.prepareYAxes(selectedYAxes)
    this.totalSeriesCount = this.countTotalSeries(selectedYAxes)
    this.series = this.prepareSeries(selectedYAxes)
  }

  public xAxis: XAxisModule | null
  public yAxes: YAxisModule[]
  public series: SeriesModule[]
  public totalSeriesCount: number
  public settings: IChartSettings
  public waterfallSeriesPresent: boolean

  create() {
    this.addToModules(
      xyChartAttributes({
        options: { areAxesStacked: this.areAxesStacked, flipAxis: this.flipAxis }
      })
    )
    if (!this.waterfallSeriesPresent) this.addToModules(externalLegendAttributes())
    this.addToModules(
      dataProcessorAttributes({
        options: {
          dateFields: this.dateFields,
          numericFields: this.numericFields
        }
      })
    )
    this.addTooltipModule()
    if (this.xAxis) this.addToModules(this.xAxis.moduleData)
    this.yAxes.forEach((yAxis) => this.addToModules(yAxis.moduleData))
    this.addToModules(
      cursorAttributes({
        options: { flippedAxis: this.flipAxis },
        references: this.xAxis ? { [this.xAxis.moduleName]: this.xAxis.axisId } : {}
      })
    ) // cursorModule is required to create gridlines and tooltips
    this.series.forEach((series) => {
      this.addToModules(series.moduleData)
    })
    return { modules: this.modules, options: this.globalOptions() }
  }

  addTooltipModule() {
    // const tooltipData = this.series.filter((series) => series.tooltipRawData).flatMap((series) => series.tooltipRawData);
    const tooltipData = _.flatMap(
      _.filter(this.series, (series) => series.tooltipRawData) as SeriesModule[],
      (series) => series.tooltipRawData
    ) as ITooltipPropsData[]
    this.addToModules(
      (this.settings.tooltipFreeFloating ? freeFloatingTooltipAttributes : tooltipAttributes)({
        options: {
          ...this.tooltipHeaderProps,
          data: tooltipData,
          flippedAxis: this.flipAxis,
          frequency: this.dateFrequency
        }
      })
    )
  }

  get dateFields() {
    const fields: string[] = []
    if (this.xAxis && this.xAxis.axisDataType === IDataType.DATE_STRING) {
      fields.push(this.xAxis.label)

      if (this.series.some((series) => series.isPreviousPeriod)) {
        fields.push(`${this.xAxis.label}${PREVIOUS_PERIOD_SUFFIX}`)
      }
    }
    return fields
  }

  get numericFields() {
    return this.yAxes.map((axis) => axis.label)
  }

  get tooltipHeaderProps(): Omit<IModuleOptions[ModuleName.tooltip], 'data'> {
    switch (this.settings.tooltip_heading_type) {
      case TooltipHeadingType.Hidden:
        return { isHeaderHidden: true }
      case TooltipHeadingType.Custom:
        return { header: this.settings.tooltip_heading_custom }
      case TooltipHeadingType.FromBaseAxis:
      default:
        if (this.xAxis)
          return {
            headerKey: this.xAxis.label,
            isDateHeader: this.xAxis.axisDataType === IDataType.DATE_STRING
          }
        else return { isHeaderHidden: true }
    }
  }

  get chartSubtitle() {
    switch (this.settings.chart_subtitle_type) {
      case ChartSubtitleType.Hidden:
        return ''
      case ChartSubtitleType.Custom:
        return this.settings.chart_subtitle_custom || ''
      case ChartSubtitleType.UseTwoPeriodLabel:
        return this.fullPeriodLabel
      case ChartSubtitleType.UsePeriodLabel:
      default:
        return this.periodLabel(false)
    }
  }

  get areAxesStacked() {
    return areAxesStackedUtil(this.settings)
  }

  prepareXAxis(selectedXAxes: IAxis[]) {
    if (!selectedXAxes.length) {
      return null
    }

    if (selectedXAxes.length > 1) {
      throw new Error(`Only one ${this.flipAxis ? 'y' : 'x'}-Axis is supported`)
    }

    return new XAxisModule(
      selectedXAxes[0],
      this.flipAxis,
      0,
      this.dateFrequency,
      this.showChartGridLines,
      this.settings
    )
  }

  prepareYAxes(selectedYAxes: IAxis[]) {
    if (selectedYAxes.length > 1 && this.waterfallSeriesPresent) {
      throw new Error(`Waterfall series supports only one y-Axis`)
    }

    return selectedYAxes
      .map(
        (axis, index) =>
          new YAxisModule(
            axis,
            this.flipAxis,
            index,
            this.dateFrequency,
            this.showChartGridLines,
            this.settings
          )
      )
      .filter((axis) => !!axis.moduleData)
  }

  prepareSeries(selectedYAxes: IAxis[]): SeriesModule[] {
    if (!this.xAxis) return []

    let currIndex = 0

    const mappedSeries = _.map(selectedYAxes, (series: IAxis) => {
      const seriesCount = SeriesModule.countTotalSeries(series, this.breakdowns[series.name!])

      const seriesObj = SeriesFactory.create({
        series,
        xAxis: this.xAxis!,
        yAxes: this.yAxes,
        flipAxis: this.flipAxis,
        dateLabels: this.dateLabels,
        breakdownLabels: this.breakdowns[series.name!],
        colorSet: this.colorSet(currIndex, currIndex + seriesCount),
        settings: this.settings,
        showDataLabelsControls: this.showDataLabelsControls,
        openChartNodeContextMenu: this.openChartNodeContextMenu
      })

      currIndex += seriesCount

      return seriesObj
    })
    const filteredSeries = _.filter(mappedSeries, (series) => !!series) as SeriesModule[]

    if (this.reverseSeriesStackingOrder) {
      _.reverse(filteredSeries)
    }

    return filteredSeries
  }

  resolvedSettings(settings: IChartSettings) {
    return settings || {}
  }

  countTotalSeries(selectedYAxes: IAxis[]) {
    return selectedYAxes.reduce((acc, axis) => {
      return acc + SeriesModule.countTotalSeries(axis, this.breakdowns[axis.name!])
    }, 0)
  }

  colorSet(startIndex: number, endIndex: number) {
    let colorSetKey: ChartColorsKeys
    if (this.totalSeriesCount <= 1) {
      colorSetKey = 'single'
    } else if (this.totalSeriesCount <= 2) {
      colorSetKey = 'two'
    } else if (this.totalSeriesCount <= 3) {
      colorSetKey = 'three'
    } else {
      colorSetKey = 'many'
    }
    let colorSet = Object.values(this.colors[colorSetKey])

    while (endIndex > colorSet.length) {
      // loop over for more colors
      colorSet = colorSet.concat(colorSet)
    }

    return colorSet.slice(startIndex, endIndex)
  }

  // global options
  globalOptions(): IGlobalOptions {
    return {
      clusteredSeries: this.clusteredSeries()
    }
  }

  clusteredSeries() {
    const clusturedFlags = _.flatMap(this.series, (series) => [
      series.clusturedBreakdown,
      series.clustured
    ])
    return _.some(clusturedFlags)
  }

  get reverseSeriesStackingOrder() {
    return _.get(this.settings, 'reverseStackingOrder', false)
  }
}

import { useEffect } from 'react'

import * as am5 from '@amcharts/amcharts5'

import { DATA_LABEL_BULLETS, Display, Position } from '../types/data-types'
import { IPropsEffectProps } from '../types/module-types'
import dataLabelsBulletsFactory from '../utils/data-labels-bullets-factory'
import { getSelfRef } from '../utils/module-utils'

interface Props extends IPropsEffectProps {
  axisKey: string
  display?: Display
  position?: Position
  showDataLabels: boolean
  color?: string
}

export default function useToggleDataLabels({
  propsRef,
  moduleName,
  props,
  axisKey,
  display,
  position,
  color,
  showDataLabels
}: Props) {
  useEffect(() => {
    const options = _.get(propsRef, 'current.options', {})
    const series: am5.Series = getSelfRef({ ...propsRef.current, moduleName })

    const idx = _.findIndex(series.bullets.values, ['name', DATA_LABEL_BULLETS])
    // using amcharts' removeIndex because it does some internal work
    if (idx !== -1) series.bullets.removeIndex(idx)

    if (!showDataLabels) return

    series.bullets.push(
      dataLabelsBulletsFactory({
        axisKey: axisKey,
        numberFormat: options.numberFormat,
        display,
        position,
        color
      })
    )
  }, [showDataLabels, propsRef, moduleName, axisKey, display, position, color])
}

// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function ButtonBase() {
  return {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    }
  }
}

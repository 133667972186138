import CommonLayout from 'layout/CommonLayout'
import { CreatePasswordType } from 'pages/auth/create-password'

import LazyComponent from '../components/core/LazyComponent'

const Login = LazyComponent(import('pages/auth/login'))
const ForgotPassword = LazyComponent(import('pages/auth/forgot-password'))
const CheckMail = LazyComponent(import('pages/auth/check-mail'))
const CreatePassword = LazyComponent(import('pages/auth/create-password'))

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <CommonLayout />,
      children: [
        {
          path: 'login',
          element: <Login />
        },
        {
          path: 'forgot-password',
          element: <ForgotPassword />
        },
        {
          path: 'check-mail',
          element: <CheckMail />
        },
        {
          path: 'users/invitation/:token?',
          element: <CreatePassword type={CreatePasswordType.ACCEPT_INVITE} />
        },
        {
          path: 'users/reset-password/:token?',
          element: <CreatePassword type={CreatePasswordType.RESET_PASSWORD} />
        }
      ]
    }
  ]
}

export default LoginRoutes

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'

// assets
import { EditOutlined, LogoutOutlined } from '@ant-design/icons'

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
  onClose?: () => void
  handleLogout: () => void
}

const ProfileTab = ({ handleLogout, onClose }: Props) => {
  const navigate = useNavigate()
  const [selectedIndex] = useState(0)
  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
    navigate('/profiles/user/password')
    onClose?.()
  }

  return (
    <List component='nav' sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      <ListItemButton
        selected={selectedIndex === 0}
        onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 0)}
      >
        <ListItemIcon>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText primary='Edit Profile' />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary='Logout' />
      </ListItemButton>
    </List>
  )
}

export default ProfileTab

import { ColDef, GridState } from 'ag-grid-community'

import { pairKeyValues } from '@utils/obj-utils'

import { isNotEqual } from 'utils/lodash'

import {
  IFilter,
  ITableColumn,
  PivotComparatorConfigRow,
  PivotComparatorOrder,
  PivotComparatorType,
  isPivotComparatorOrderAsc
} from '../types/table-builder-types'

export function getColumnLayoutChanges(
  gridState: GridState,
  oldColumns: ITableColumn[],
  oldPivotMode: boolean
) {
  const { aggregation, columnVisibility, pivot, rowGroup } = gridState

  const pivotMode = _.get(pivot, 'pivotMode', false)
  const pivotColIds = _.get(pivot, 'pivotColIds', [])
  const aggregationModel = _.get(aggregation, 'aggregationModel', [])
  const aggregationHash = pairKeyValues(aggregationModel, 'colId', 'aggFunc')
  const aggregationColIds = _.map(aggregationModel, 'colId')
  const hiddenColIds = _.get(columnVisibility, 'hiddenColIds', []) // hidden column-ids only in non-pivot mode
  const groupColIds = _.get(rowGroup, 'groupColIds', [])

  const columns: Record<string, ColDef> = {}

  _.forEach(oldColumns, (oldColumn) => {
    const field = oldColumn.field!

    const oldColumnLayoutAttrs = _.pick(oldColumn, ['pivot', 'rowGroup', 'hide', 'aggFunc'])
    const newColumnLayoutAttrs = { pivot: false, rowGroup: false, hide: false, aggFunc: undefined }
    if (_.includes(pivotColIds, field)) {
      newColumnLayoutAttrs.pivot = pivotMode
    } else if (_.includes(aggregationColIds, field)) {
      newColumnLayoutAttrs.aggFunc = aggregationHash[field]
    } else if (_.includes(groupColIds, field)) {
      newColumnLayoutAttrs.rowGroup = true
    } else {
      newColumnLayoutAttrs.hide = pivotMode || _.includes(hiddenColIds, field)
    }

    if (isNotEqual(oldColumnLayoutAttrs, newColumnLayoutAttrs)) {
      columns[field] = newColumnLayoutAttrs
    }
  })

  if (_.isEqual(oldPivotMode, pivotMode) && _.isEmpty(columns)) {
    return null
  }

  return { pivotMode, columns }
}

export function getDefaultExternalFiltersMap(
  externalFilters: Record<string, IFilter>
): Record<string, string> {
  return _.reduce(
    _.toPairs(externalFilters),
    (acc, [columnField, filter]) => {
      acc[columnField] = filter.defaultValue || ''
      return acc
    },
    {} as Record<string, string>
  )
}

export const getPivotComparator: (
  config: PivotComparatorConfigRow[]
) => Exclude<ColDef['pivotComparator'], undefined> = (config) => {
  let indexOfRest = _.findIndex(config, { type: PivotComparatorType.REST })
  if (_.isEqual(indexOfRest, -1)) indexOfRest = config.length

  const ascendingRest = isPivotComparatorOrderAsc(
    _.get(config, [indexOfRest, 'order'], PivotComparatorOrder.ASC)
  )

  return (valueA, valueB) => {
    const valueAFixedIndex = _.findIndex(config, { type: PivotComparatorType.FIXED, value: valueA })
    const valueBFixedIndex = _.findIndex(config, { type: PivotComparatorType.FIXED, value: valueB })
    const isValueAFixed = isNotEqual(valueAFixedIndex, -1)
    const isValueBFixed = isNotEqual(valueBFixedIndex, -1)
    // both are fixed index
    if (isValueAFixed && isValueBFixed) {
      return _.gt(valueAFixedIndex, valueBFixedIndex) ? 1 : -1
    }
    // valueA is fixed
    if (isValueAFixed) {
      return _.gt(valueAFixedIndex, indexOfRest) ? 1 : -1
    }
    // valueB is fixed
    if (isValueBFixed) {
      return _.gt(indexOfRest, valueBFixedIndex) ? 1 : -1
    }

    // both are rest
    if (ascendingRest) {
      return _.gt(valueA, valueB) ? 1 : -1
    } else {
      return _.gt(valueA, valueB) ? -1 : 1
    }
  }
}

export function heatMapColumn(column: ITableColumn) {
  return column.customData?.heatmap
}

import { ReactElement } from 'react'

import { Month } from '../utils/date-utils'
import { EnumValueUnion } from '../utils/obj-utils'
import { BusinessType } from './business'
import { Language } from './language'

// ==============================|| AUTH TYPES  ||============================== //

export type GuardProps = {
  children: ReactElement | null
}

export type FeatureProps = {
  id?: number
  name?: string
  description?: string
  allowed_users?: string
  enabled?: boolean
}

export type UserProfile = {
  id?: string
  email?: string
  avatar?: string
  image?: string
  name?: string
  roles?: Array<string>
  business_id?: number
  charger_customer_id?: string
  business_friendly_name?: string
  business_fiscal_year_start?: Month
  business_local_timezone?: string
  business_name?: string
  business_type?: BusinessType
  business_default_route?: string
  business_languages?: Language[]
  business_earliest_transaction_year?: number
  business_latest_transaction_year?: number
  businesses?: Business[]
  business_automap_gl_account?: boolean
  primary_business_charger?: boolean
}

export type Business = {
  id: string | number
  friendly_name?: string
  charger_customer_id?: string
  local_timezone?: string
  name?: string
  fiscal_year?: string
  default_route?: string
  languages?: Language[]
  earliest_transaction_year?: number
  latest_transaction_year?: number
  type: BusinessType
  automap_gl_account?: boolean
}

export interface AuthProps {
  isLoggedIn?: boolean
  isInitialized?: boolean
  isMasquarade?: boolean
  user?: UserProfile | null
  features?: Array<FeatureProps> | []
  token?: string | null
  selectedBusinessId?: string | number
}

export interface AuthActionProps {
  type: string
  payload?: AuthProps
}

export type AWSCognitoContextType = {
  isLoggedIn: boolean
  isInitialized?: boolean
  user?: UserProfile | null | undefined
  logout: () => void
  login: (email: string, password: string) => Promise<void>
  register: (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => Promise<unknown>
  resetPassword: (email: string) => Promise<void>
  updateProfile: VoidFunction
}

export interface InitialLoginContextProps {
  isLoggedIn: boolean
  isInitialized?: boolean
  user?: UserProfile | null | undefined
}

export interface JWTDataProps {
  userId: string
}

export type JWTContextType = {
  isLoggedIn?: boolean
  isInitialized?: boolean
  isMasquarade?: boolean
  user?: UserProfile | null | undefined
  features?: Array<FeatureProps> | []
  logout: () => void
  login: (email: string, password: string) => Promise<void>
  register: (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    business_name: string,
    local_timezone: string,
    business_industry: string
  ) => Promise<void>
  userHasRole: (roles: Array<string>) => boolean
  userIsSuperAdmin: () => boolean
  isChargerSuperUser: () => boolean
  isChargerAccountsManager: () => boolean
  resetPassword: (email: string) => Promise<void>
  masquarade: (user: UserProfile, serviceToken: string) => void
  reverseMasquarade: () => void
  onBusinessSelectorChange: (user: UserProfile, selectedBusinessId: number) => void
  isCurrentBusinessCharger: () => boolean
  isCurrentBusinessClient: () => boolean
  isCurrentBusinessDemo: () => boolean
  isPrimaryBusinessCharger: () => boolean
}

export type Auth0ContextType = {
  isLoggedIn: boolean
  isInitialized?: boolean
  user?: UserProfile | null | undefined
  logout: () => void
  login: () => void
  resetPassword: (email: string) => Promise<void>
  updateProfile: VoidFunction
}

export enum UserRole {
  admin = 'admin',
  super_admin = 'super_admin',
  customer = 'customer',
  chargerSuperUser = 'charger_super_user',
  chargerAccountsManager = 'charger_accounts_manager'
}

export type UserRoles = EnumValueUnion<typeof UserRole> // 'admin' | 'super_admin' | 'customer';

export const CHARGER_FRIENDLY_NAME = 'charger'

import { useEffect, useRef, useState } from 'react'

import { useDebounce } from '@uidotdev/usehooks'

import { useLatestRef } from 'hooks/useLatestRef'

export default function useDelayedUpdate<T = any>(
  value: T,
  updater: (value: T) => void,
  debounceMS = 300
) {
  const initialValueRef = useRef(value)
  const initialized = useRef(false)
  const [state, setState] = useState(value)
  const debouncedState = useDebounce(state, debounceMS)
  const updaterRef = useLatestRef(updater)
  const stateRef = useLatestRef(state)
  const debouncedStateRef = useLatestRef(debouncedState)

  // keep value up to date if it changes
  useEffect(() => setState(value), [value])

  // run the updater when the debounced state changes
  useEffect(() => {
    if (!initialized.current && initialValueRef.current === debouncedState) return

    updaterRef.current(debouncedState)
    initialized.current = true
  }, [debouncedState, updaterRef])

  // run the updater during the component unmount lifecycle and the updater has not been run yet
  useEffect(
    () => () => {
      if (stateRef.current !== debouncedStateRef.current) {
        updaterRef.current(stateRef.current)
      }
    },
    [debouncedStateRef, stateRef, updaterRef]
  )

  return [state, setState] as const
}

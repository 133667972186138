import { Checkbox } from '@components/core/checkbox'
import { Input } from '@components/core/input'
import { Label } from '@components/core/label'
import { Separator } from '@components/core/separator'
import { Select } from '@components/form/select'

import useDelayedUpdate from '@hooks/use-delayed-update'

import { useDispatch, useSelector } from '@store/index'
import {
  selectSettings,
  updateSettingsData,
  updateYAxisData
} from '@store/slices/component/chart-config'

import { Description } from '../common/description'
import { Title } from '../common/title'
import { IAxis, TooltipHeadingType, TooltipTitleType } from '../types/chart-builder-types'

export function DefaultTooltipSettings() {
  const settings = useSelector(selectSettings)
  const dispatch = useDispatch()

  const [headingCustom, setHeadingCustom] = useDelayedUpdate(
    settings.tooltip_heading_custom || '',
    (value) => dispatch(updateSettingsData({ tooltip_heading_custom: value }))
  )
  const [titleCustom, setTitleCustom] = useDelayedUpdate(
    settings.tooltip_title_custom || '',
    (value) => dispatch(updateSettingsData({ tooltip_title_custom: value }))
  )

  return (
    <>
      <div className='mt-4'>
        <Title>Tooltip</Title>
        <Description>These settings modify the tooltip that floats over your chart</Description>
      </div>
      <Separator className='my-2' />
      <div className='grid grid-cols-[auto,1fr] items-center gap-2'>
        <Label className='text-submenu font-normal' htmlFor='default-tooltip-free-floating'>
          Free-floating Tooltip
        </Label>
        <Checkbox
          id='default-tooltip-free-floating'
          className='m-2 ml-0'
          checked={settings.tooltipFreeFloating || false}
          onCheckedChange={(value) =>
            dispatch(updateSettingsData({ tooltipFreeFloating: value as boolean }))
          }
        />
        <Select
          id='default-tooltip-heading'
          label='Heading Label'
          options={[
            { label: 'From Base Axis', value: TooltipHeadingType.FromBaseAxis },
            { label: 'Hidden', value: TooltipHeadingType.Hidden },
            { label: 'Custom', value: TooltipHeadingType.Custom }
          ]}
          value={settings.tooltip_heading_type || TooltipHeadingType.FromBaseAxis}
          onChange={(value) =>
            dispatch(updateSettingsData({ tooltip_heading_type: value as TooltipHeadingType }))
          }
          fullWidth
        />
        {_.isEqual(settings.tooltip_heading_type, TooltipHeadingType.Custom) && (
          <>
            <div />
            <Input
              value={headingCustom}
              onChange={(e) => setHeadingCustom(e.target.value)}
              placeholder='Custom Heading Text'
            />
          </>
        )}
        <Select
          id='default-tooltip-title'
          label='Title Label'
          options={[
            { label: 'From Series', value: TooltipTitleType.FromSeries },
            { label: 'From Base Axis', value: TooltipTitleType.FromBaseAxis },
            { label: 'Custom', value: TooltipTitleType.Custom }
          ]}
          value={settings.tooltip_title_type || TooltipTitleType.FromSeries}
          onChange={(value) =>
            dispatch(updateSettingsData({ tooltip_title_type: value as TooltipTitleType }))
          }
          fullWidth
        />
        {_.isEqual(settings.tooltip_title_type, TooltipTitleType.Custom) && (
          <>
            <div />
            <Input
              value={titleCustom}
              onChange={(e) => setTitleCustom(e.target.value)}
              placeholder='Custom Title Text'
            />
          </>
        )}
      </div>
    </>
  )
}

export function AxisTooltipOverrideSettings({ item: data }: { item: IAxis }) {
  const dispatch = useDispatch()

  const { id, tooltip_override = false } = data

  return (
    <div className='flex items-center gap-2'>
      <Label className='text-submenu font-normal' htmlFor={`override-tooltip-settings-${data.id}`}>
        Override Tooltip Settings
      </Label>
      <Checkbox
        id={`override-tooltip-settings-${data.id}`}
        className='m-2 ml-0'
        checked={tooltip_override}
        onCheckedChange={(value) =>
          dispatch(updateYAxisData({ id, tooltip_override: value as boolean }))
        }
      />
    </div>
  )
}

export function AxisTooltipOverrideTitle({ item: data }: { item: IAxis }) {
  const dispatch = useDispatch()

  const {
    id,
    tooltip_override = false,
    tooltip_title_type = TooltipTitleType.FromSeries,
    tooltip_title_custom
  } = data

  const [titleCustom, setTitleCustom] = useDelayedUpdate(
    tooltip_title_custom || '',
    (value: string) => dispatch(updateYAxisData({ id, tooltip_title_custom: value }))
  )

  if (!tooltip_override) return null

  return (
    <div>
      <Select
        options={[
          { label: 'From Series', value: TooltipTitleType.FromSeries },
          { label: 'From Base Axis', value: TooltipTitleType.FromBaseAxis },
          { label: 'Custom', value: TooltipTitleType.Custom }
        ]}
        value={tooltip_title_type}
        onChange={(value) => {
          dispatch(updateYAxisData({ id, tooltip_title_type: value as TooltipTitleType }))
        }}
      />
      {_.isEqual(tooltip_title_type, TooltipTitleType.Custom) && (
        <Input
          value={titleCustom}
          onChange={(e) => setTitleCustom(e.target.value)}
          placeholder='Custom Title Text'
          className='mt-2'
        />
      )}
    </div>
  )
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootState } from '../index'

interface GridState {
  searchTerms: Record<string, string>
}

// NOTE: Having search term in global redux state might have UX issues,
// e.g. user searches on a grid in one page and navigates to another page, the search term is persisted. This looks more like a local state specific to the grid than a global state
const initialState: GridState = {
  searchTerms: {}
}

const DEFAULT_ID = 'default'

const gridSlice = createSlice({
  name: '@GRID',
  initialState,
  reducers: {
    updateGridSearchTerm: (state, action: PayloadAction<{ id?: string; searchTerm: string }>) => {
      const { id = DEFAULT_ID, searchTerm } = action.payload
      state.searchTerms[id] = searchTerm
    },
    clearGridSearchTerm: (state, action: PayloadAction<{ id?: string }>) => {
      const { id = DEFAULT_ID } = action.payload
      state.searchTerms[id] = ''
    }
  }
})

// Actions
export const { updateGridSearchTerm, clearGridSearchTerm } = gridSlice.actions

// Reducer
export const gridReducer = gridSlice.reducer

// Selectors
export const selectGridSearchTerm = (state: RootState, id = DEFAULT_ID) =>
  state.grid.searchTerms[id] || ''

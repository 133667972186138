import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

interface Props {
  message?: string
}

export const FilterError = ({ message = 'Failed to load filter.' }: Props) => (
  <Tooltip title={message}>
    <IconButton size='actionBar'>
      <WarningAmberIcon />
    </IconButton>
  </Tooltip>
)

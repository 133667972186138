import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { Button } from '@core/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@core/dialog'
import { Input } from '@core/input'
import { Label } from '@core/label'

import { useDispatch } from '@store/index'
import {
  selectParameterNameByHeaderName,
  updateParameterName
} from '@store/slices/component/query-config'

import { IDataType } from '../../types/component-types'

export function AddParameterModal({
  open,
  onOpenChange,
  selectedHeader,
  onSubmit
}: {
  open: boolean
  onOpenChange: (open: boolean) => void
  selectedHeader: { name: string; dataType: IDataType } | null
  onSubmit: () => void
}) {
  const dispatch = useDispatch()
  const headerName = _.get(selectedHeader, 'name', '')
  const selectParameterizedConfigByHeaderNameMemoized = useMemo(
    () => selectParameterNameByHeaderName(headerName),
    [headerName]
  )
  const initialParameterName = useSelector(selectParameterizedConfigByHeaderNameMemoized)
  const [parameterName, setParameterName] = useState('')
  useEffect(() => setParameterName(initialParameterName), [initialParameterName])

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className='sm:max-w-md'>
        <DialogHeader>
          <DialogTitle>Parameter Config for {_.get(selectedHeader, 'name', '')}</DialogTitle>
          <DialogDescription>
            Add a parameter name for this column. This name is used to identify this column and can
            be used for filtering.
          </DialogDescription>
        </DialogHeader>
        <div className='grid flex-1 gap-2'>
          <Label htmlFor='parameter-name'>Parameter Name</Label>
          <Input
            id='parameter-name'
            placeholder='Enter parameter name'
            value={parameterName}
            onChange={(e) => setParameterName(e.target.value)}
          />
        </div>
        <DialogFooter className='sm:justify-end'>
          <Button
            type='button'
            variant={!initialParameterName ? 'disabled' : 'danger'}
            disabled={!initialParameterName}
            onClick={() => {
              if (!selectedHeader) return

              const { name } = selectedHeader
              dispatch(updateParameterName({ parameterName: '', headerName: name }))
              onOpenChange(false)
            }}
          >
            Remove
          </Button>
          <Button
            type='button'
            onClick={() => {
              if (!selectedHeader) return

              const { name } = selectedHeader
              dispatch(updateParameterName({ parameterName, headerName: name }))
              onSubmit()
              onOpenChange(false)
            }}
          >
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

import { useQuery } from '@tanstack/react-query'

import { camelCaseKeys } from '@utils/case-conversion'

import axiosServices from 'utils/axios'

export interface CatalogStream {
  streamName: string
  streamNamespace: string
  syncMode: string
  primaryKey: string
  pkMismatchFound: boolean
  lastDataIntegrityCheck: string
  cursorField: string
  stateType: string
  cursor: string
}

const fetchCatalogStreams = async (businessId: number, id: string): Promise<CatalogStream[]> => {
  const response = await axiosServices.get(
    `/businesses/${businessId}/connected_source_systems/${id}/catalog_streams`
  )
  return camelCaseKeys(response.data)
}

export const useFetchCatalogStreams = ({
  id,
  businessId
}: {
  id?: string
  businessId?: number
}) => {
  return useQuery<CatalogStream[]>({
    queryKey: ['fetch-css-catalog-streams', id],
    enabled: !!businessId && !!id,
    queryFn: () => fetchCatalogStreams(businessId!, id!)
  })
}

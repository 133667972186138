import axios from 'axios'
import { FinancialAdjustment } from 'components/financial/types'
import { format, parse } from 'date-fns'
import { YMD_DATE_FORMAT } from 'types/date'

import { clearSession, setSession } from '../contexts/JWTContext'
import { REFRESH_SESSION_URL, refreshSession } from './refresh-token'
import { errorSnack } from './snackbar-utils'

axios.defaults.baseURL = import.meta.env.VITE_BACKEND_URL
axios.defaults.withCredentials = true

const SERVER_DATE_FORMAT = 'yyyy-MM-dd'

const axiosServices = axios.create()

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //
axiosServices.interceptors.request.use(
  (request) => {
    const serviceToken = localStorage.getItem('ServiceToken')
    // Do something before request is sent
    if (serviceToken != null && request.url !== '/login') {
      request.headers['Authorization'] = serviceToken
    }

    request.headers['x-current-page'] = window.location.pathname
    return request
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

export const shouldRefreshSession = (url: string, hasAuthHeader: boolean, firstTry: boolean) => {
  if (!hasAuthHeader) {
    return false
  }
  if (url === REFRESH_SESSION_URL) {
    return false
  }
  if (!firstTry) {
    return false
  }
  return true
}

axiosServices.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config

    console.log('axios interceptor error', error)

    // we should only attempt to renew if the original request was authorized, avoid the requests outside of the app
    // like login, register, etc.
    if (error.response?.status === 401) {
      const authHeaderPresent = originalRequest.headers['Authorization'] != null
      if (shouldRefreshSession(originalRequest.url, authHeaderPresent, !originalRequest._retry)) {
        try {
          const { user, accessToken } = await refreshSession()
          console.log('session refreshed', user, accessToken)

          setSession(user, accessToken)

          originalRequest._retry = true
          return axiosServices(originalRequest)
        } catch {
          /* empty */
        }
      }

      // session exit point
      if (window.location.pathname === '/login') {
        clearSession()
        return Promise.reject((error.response && error.response.data) || 'Wrong Services')
      } else {
        clearSession()
        errorSnack('Session expired, please login again', false)
        window.location.href = '/login?reason=unauthorized'
      }
    } else {
      return Promise.reject(
        (error.response && error.response.data) ||
          'Wrong Services. Either the internet is down or the server is down.'
      )
    }
  }
)

const formatAccountingPeriod = (date: string | undefined): string | undefined => {
  if (!date) return date
  try {
    if (date.startsWith('yyyy')) {
      return date
    }
    const parsedDate = parse(date, YMD_DATE_FORMAT, new Date())
    return format(parsedDate, SERVER_DATE_FORMAT)
  } catch (error) {
    return date
  }
}

// Request Interceptor to format dates
axiosServices.interceptors.request.use(
  (config) => {
    try {
      // Check if the request data contains FinancialAdjustment objects and adjust the date format
      if (config.data && config.url && config.url.includes('/general_ledgers/')) {
        const data = config.data

        if (Array.isArray(data.adjustments)) {
          // Handle arrays for bulk operations
          data.adjustments = data.adjustments.map((adj: FinancialAdjustment) => ({
            ...adj,
            accounting_period: formatAccountingPeriod(adj.accounting_period)
          }))
        } else if (data.general_ledger && data.general_ledger.accounting_period) {
          // Handle single object updates
          data.general_ledger = {
            ...data.general_ledger,
            accounting_period: formatAccountingPeriod(data.general_ledger.accounting_period)
          }
        }

        config.data = data
      }
    } catch (error) {
      console.error('Error in date formatting interceptor', error)
      throw error
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosServices

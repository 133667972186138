import { useCallback, useState } from 'react'

export type CHANGE_KEY = number

export default function useChangeDetection() {
  const [changeKey, setChangeKey] = useState<CHANGE_KEY>(0)

  const triggerChange = useCallback(() => setChangeKey((prev) => (prev > 100 ? 0 : prev + 1)), [])

  return [changeKey, triggerChange] as const
}

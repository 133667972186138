import { useMutation, useQueryClient } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'
import { errorSnack, successSnack } from 'utils/snackbar-utils'

import { IAPIErrorType } from '../types'

export const useDeleteComponent = (id: number) => {
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => {
      return axiosServices.delete(`/businesses/${user?.business_id}/components/${id}`)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['fetch-components', user?.business_id] })

      successSnack('Component Deleted Successfully')
    },
    onError(error: IAPIErrorType) {
      let message = 'Failed to Delete Component.'
      if (error?.error?.base) {
        message = error?.error?.base[0]
      }
      errorSnack(message)
    }
  })
}

import { RefObject } from 'react'

import type { Modifier } from '@dnd-kit/core'
import { restrictToParentElement } from '@dnd-kit/modifiers'

export function restrictToCustomParentElement(elementRef: RefObject<HTMLElement | null>): Modifier {
  return (e) => {
    return restrictToParentElement({
      ...e,
      containerNodeRect: elementRef.current?.getBoundingClientRect() as DOMRect | null
    })
  }
}

export const fontSizes = {
  h3: ['1.625rem', { lineHeight: 'normal', fontWeight: '400' }],
  h4: ['1.5rem', { lineHeight: '2rem', fontWeight: '400' }],
  h5: ['1.125rem', { lineHeight: '1.125rem', fontWeight: '400' }],
  h6: ['0.75rem', { lineHeight: '1.57rem', fontWeight: '400' }],
  description: ['1rem', { lineHeight: '2rem', fontWeight: '400' }],
  body: ['1rem', { lineHeight: '1.375rem', fontWeight: '400' }],
  details: ['0.75rem', { lineHeight: '1.25rem', fontWeight: '400' }],
  'error-popover': ['0.7rem', { lineHeight: '1rem', fontWeight: '400' }],
  link: ['1rem', { lineHeight: '1.375rem', fontWeight: '400' }],
  submenu: ['.8125rem', { lineHeight: 'normal', fontWeight: '400' }],
  button: ['0.875rem', { lineHeight: 'normal', fontWeight: '600' }],
  'card-value': ['1.625rem', { lineHeight: 'normal', fontWeight: '400' }],
  'table-value': ['0.75rem', { lineHeight: '0.9rem', fontWeight: '400' }],
  'inline-form-title': ['0.9rem', { lineHeight: 'normal', fontWeight: '600' }],
  'tab-description': ['1.25rem', { lineHeight: '1.25rem', fontWeight: '400' }],
  input: ['1rem', { lineHeight: 'normal', fontWeight: '400' }]
}

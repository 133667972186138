import { SyntheticEvent, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Grid, InputAdornment, InputLabel, OutlinedInput, Stack } from '@mui/material'

import { isBlank } from '@utils/lodash'
import { cn } from '@utils/style-utils'

import Button from '@components/core/button'
import { Text } from '@components/core/text'
import { Icon } from '@components/icons'
import { Eye } from '@components/icons/eye'
import { EyeOff } from '@components/icons/eye-off'

import { Formik } from 'formik'
import axiosServices from 'utils/axios'
import * as Yup from 'yup'

interface InfoProps {
  children?: React.ReactNode
  error?: boolean
}

const InfoBox = (props: InfoProps) => (
  <div className={cn('flex items-center gap-2')}>
    <div
      className={cn(
        'h-[0.625rem] w-[0.625rem] rounded-[50%] bg-[#5E6FC5]',
        props.error && 'bg-error'
      )}
    />
    <Text variant='details' className={cn(props.error && 'text-error', 'leading-none')}>
      {props.children}
    </Text>
  </div>
)

const isAnyUpper = (str: string | undefined) => !!str && /\p{Lu}/u.test(str)
const isAnyLower = (str: string | undefined) => !!str && /\p{Ll}/u.test(str)
const isAnyDigit = (str: string | undefined): boolean => !!str && /\d/.test(str)
const isAnySpecial = (str: string | undefined): boolean => !!str && /[^\p{L}\d\s]/u.test(str)

const ValidationSchema = Yup.object().shape({
  password: Yup.string().min(8).max(255).required('Password is required'),
  hasUpperCase: Yup.string().test('uppercase', 'Error', function () {
    return isAnyUpper(this.parent.password)
  }),
  hasLowerCase: Yup.string().test('lowercase', 'Error', function () {
    return isAnyLower(this.parent.password)
  }),
  hasDigit: Yup.string().test('digit', 'Error', function () {
    return isAnyDigit(this.parent.password)
  }),
  hasSpecialChar: Yup.string().test('special-char', 'Error', function () {
    return isAnySpecial(this.parent.password)
  }),
  matchPassword: Yup.string().test('match-password', 'Error', function () {
    return this.parent.password === this.parent.confirmPassword
  }),
  confirmPassword: Yup.string().required('Confirm Password is required')
})

interface Props {
  onSuccess: () => void
  apiUrl: string
}

const CreateNewPassword = (props: Props) => {
  const { token } = useParams()

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const handleMouseDownPassword = (event: SyntheticEvent) => {
    event.preventDefault()
  }

  return (
    <>
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
          hasUpperCase: '',
          hasLowerCase: '',
          hasSpecialChar: '',
          hasDigit: '',
          matchPassword: '',
          token,
          submit: null
        }}
        validateOnMount
        validationSchema={ValidationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          // password reset
          axiosServices
            .patch(props.apiUrl, {
              user: {
                token: values.token,
                password: values.password,
                password_confirmation: values.confirmPassword
              }
            })
            .then(() => {
              setStatus({ success: true })
              setSubmitting(false)
              props.onSuccess()
            })
            .catch((err) => {
              setStatus({ success: false })
              setErrors({ submit: err.message })
              setSubmitting(false)
            })
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor='password-reset'>Password</InputLabel>
                    <OutlinedInput
                      fullWidth
                      className='h-10 pr-0 [&>input]:h-full [&>input]:py-0'
                      id='password-reset'
                      type={showPassword ? 'text' : 'password'}
                      value={values.password}
                      name='password'
                      autoComplete='new-password'
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e)
                      }}
                      endAdornment={
                        <InputAdornment position='end' className='ml-0'>
                          <Button
                            variant='text'
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            color='primary'
                            className='[&>*]:text-primary'
                          >
                            <Icon icon={!showPassword ? <EyeOff /> : <Eye />} />
                          </Button>
                        </InputAdornment>
                      }
                      placeholder='Enter password'
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor='confirm-password-reset'>Confirm Password</InputLabel>
                    <OutlinedInput
                      fullWidth
                      className='h-10 pr-0 [&>input]:h-full [&>input]:py-0'
                      id='confirm-password-reset'
                      type={showConfirmPassword ? 'text' : 'password'}
                      autoComplete='new-password'
                      value={values.confirmPassword}
                      name='confirmPassword'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='Enter confirm password'
                      endAdornment={
                        <InputAdornment position='end' className='ml-0'>
                          <Button
                            variant='text'
                            aria-label='toggle password visibility'
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            color='primary'
                            className='[&>*]:text-primary'
                          >
                            <Icon icon={!showConfirmPassword ? <EyeOff /> : <Eye />} />
                          </Button>
                        </InputAdornment>
                      }
                    />
                  </Stack>
                </Grid>

                <Grid item className='ml-4 grid w-full grid-cols-2 gap-4'>
                  <InfoBox error={!values.password || !values.confirmPassword}>
                    No blank fields
                  </InfoBox>
                  <InfoBox error={!!errors.matchPassword}>Passwords should match</InfoBox>
                  <InfoBox error={values.password.length < 8}>8 Character minimum</InfoBox>
                  <InfoBox error={!!errors.hasLowerCase}>One lowercase character</InfoBox>
                  <InfoBox error={!!errors.hasUpperCase}>One Uppercase character</InfoBox>
                  <InfoBox error={!!errors.hasDigit}>One number</InfoBox>
                  <InfoBox error={!!errors.hasSpecialChar}>One special character</InfoBox>
                </Grid>

                {errors.submit && (
                  <Grid item>
                    <div className='text-error'>{errors.submit}</div>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Button
                    size='extra-small'
                    className='ml-auto'
                    type='submit'
                    variant={isSubmitting || !isBlank(errors) ? 'disabled' : 'primary'}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          )
        }}
      </Formik>
    </>
  )
}

export { CreateNewPassword }

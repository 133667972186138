import { useRef, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { useToggle } from '@uidotdev/usehooks'

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@core/tab'

import Button from '@components/core/button'
import { ArrowPlay, Filter, Icon } from '@components/icons'

import { Panel } from '../common/panel'
import { QUERY_KEY_PREFIX } from '../queries/fetch-component-staged-data'
import { JSONViewer } from './json-viewer'
import { LimitButton } from './limit-button'
import { SQLViewer } from './sql-viewer'
import { ITableViewerRef, TableViewer } from './table-viewer'

enum DataPreviewTabs {
  TABLE = 'table',
  SQL = 'sql',
  JSON = 'json'
}

export function DataPreviewPanel({
  title = '',
  secondary = null
}: {
  title?: string
  secondary?: React.ReactNode
}) {
  const tableViewerRef = useRef<ITableViewerRef>(null)
  const [showHidden, toggleHidden] = useToggle(false)
  const [selectedTab, setSelectedTab] = useState<DataPreviewTabs>(DataPreviewTabs.TABLE)
  const tableSelected = _.isEqual(selectedTab, DataPreviewTabs.TABLE)
  const showFiltersAfterGridReadyRef = useRef(false)
  const queryClient = useQueryClient()

  return (
    <Tabs
      id='dataPreviewViewer'
      value={selectedTab}
      onValueChange={(value) => setSelectedTab(value as DataPreviewTabs)}
      className='grow overflow-hidden'
    >
      <Panel
        title={title || 'Data Preview'}
        className='h-full'
        secondary={
          <div className='flex flex-wrap gap-2'>
            <TabsList className='grid w-48 grid-cols-3 gap-2'>
              <TabsTrigger value={DataPreviewTabs.TABLE}>Table</TabsTrigger>
              <TabsTrigger value={DataPreviewTabs.SQL}>SQL</TabsTrigger>
              <TabsTrigger value={DataPreviewTabs.JSON}>JSON</TabsTrigger>
            </TabsList>
            <Button
              variant='outline'
              onClick={() => {
                if (tableSelected || !showHidden) toggleHidden()
                setSelectedTab(DataPreviewTabs.TABLE)
              }}
            >
              Show Hidden
            </Button>
            <Button
              variant='outline'
              className='pl-1'
              onClick={() => {
                if (tableSelected) {
                  tableViewerRef.current?.toggleAdvancedFilterBuilder()
                } else {
                  setSelectedTab(DataPreviewTabs.TABLE)
                  showFiltersAfterGridReadyRef.current = true
                }
              }}
            >
              <Icon icon={<Filter />} />
              Filter
            </Button>
            <LimitButton onSubmit={() => tableViewerRef.current?.refreshData()} />
            <Button
              className='pr-1'
              onClick={() => {
                if (tableSelected) {
                  tableViewerRef.current?.refreshData(true)
                } else {
                  queryClient.invalidateQueries({ queryKey: [QUERY_KEY_PREFIX] })
                }
              }}
            >
              Run Query <Icon icon={<ArrowPlay />} />
            </Button>
            {secondary}
          </div>
        }
      >
        <TabsContent value={DataPreviewTabs.TABLE} className='size-full'>
          <TableViewer
            ref={tableViewerRef}
            showHidden={showHidden}
            showFiltersAfterGridReadyRef={showFiltersAfterGridReadyRef}
          />
        </TabsContent>
        <TabsContent value={DataPreviewTabs.SQL} className='size-full'>
          <SQLViewer />
        </TabsContent>
        <TabsContent value={DataPreviewTabs.JSON} className='size-full'>
          <JSONViewer />
        </TabsContent>
      </Panel>
    </Tabs>
  )
}

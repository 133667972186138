import { createContext, useEffect, useMemo, useState } from 'react'

import { Active, DndContext, UniqueIdentifier, pointerWithin } from '@dnd-kit/core'

import SkeletonTable from '@components/ag-grid/skeleton-table'
import { Tabs, TabsList, TabsTrigger } from '@components/core/tab'

import useAuth from '@hooks/useAuth'

import { HierarchyBuilderTree } from 'components/financial/shared/components/hierarchy-builder-tree'
import {
  ChartOfAccountDisplayModes,
  FinancialHierarchy,
  FinancialHierarchyNode,
  GeneralLedgerAccount
} from 'components/financial/types'
import useDefaultSensors from 'components/tree/useDefaultSensors'
import { findItemDeep } from 'components/tree/utilities'
import { queryErrorToast } from 'queries/query-toasts'
import { Business } from 'types/auth'

import { useFetchHierarchyBuilderNodes } from '../queries/fetch-hierarchy-builder-nodes'
import { useFetchUnmapped } from '../queries/fetch-unmapped'
import { useUpdateMapping } from '../queries/update-mapping'
import { DetailsPanel } from './details-panel'

export const ConfigureTrialBalanceContext = createContext<{
  editingTrialBalance: boolean
  setEditingTrialBalance: (value: boolean) => void
  showConfigureTBButton: boolean
  setShowConfigureTBButton: (value: boolean) => void
}>({
  editingTrialBalance: false,
  setEditingTrialBalance: () => {},
  showConfigureTBButton: false,
  setShowConfigureTBButton: () => {}
})

const WorkingHierarchy = ({
  hierarchy,
  currentBusiness
}: {
  hierarchy: FinancialHierarchy
  currentBusiness: Business
}) => {
  const [mode, setMode] = useState<ChartOfAccountDisplayModes>(ChartOfAccountDisplayModes.unmapped)

  const [selectedNodeId, setSelectedNodeId] = useState<UniqueIdentifier>()
  // Report Builder Tree should be readonly when the user is dragging GL mapped nodes to report builder
  const [active, setActive] = useState<Active | null>(null)
  const sensors = useDefaultSensors()

  const [editingTrialBalance, setEditingTrialBalance] = useState(false)
  const [showConfigureTBButton, setShowConfigureTBButton] = useState(true)

  const { isLoading: isLoadingUnmappedAccounts, data: unMappedAccounts } = useFetchUnmapped(
    hierarchy.id
  )

  useEffect(() => {
    setMode(ChartOfAccountDisplayModes.unmapped)
  }, [hierarchy])

  useEffect(() => {
    if (editingTrialBalance) {
      setSelectedNodeId(undefined)
    }
  }, [editingTrialBalance])

  useEffect(() => {
    const unmappedPostingAccounts =
      unMappedAccounts?.filter((unmappedAccount) => unmappedAccount.is_posting) ?? []

    if (
      !isLoadingUnmappedAccounts &&
      unmappedPostingAccounts?.length === 0 &&
      mode === ChartOfAccountDisplayModes.unmapped
    ) {
      setMode(ChartOfAccountDisplayModes.mapped)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUnmappedAccounts, unMappedAccounts])

  const { isLoading, data: nodes } = useFetchHierarchyBuilderNodes(hierarchy?.id)

  const selectedNode: FinancialHierarchyNode | undefined = useMemo(() => {
    if (!nodes) return undefined

    return findItemDeep(nodes, selectedNodeId as UniqueIdentifier) as FinancialHierarchyNode
  }, [nodes, selectedNodeId])

  const { mutate: updateMapping } = useUpdateMapping()

  return (
    <div className='flex flex-col'>
      <ConfigureTrialBalanceContext.Provider
        value={{
          editingTrialBalance,
          setEditingTrialBalance,
          showConfigureTBButton,
          setShowConfigureTBButton
        }}
      >
        {hierarchy && (
          <DndContext
            collisionDetection={active?.data?.current?.collisionDetection || pointerWithin}
            sensors={active?.data?.current?.sensors || sensors}
            measuring={active?.data?.current?.measuring}
            accessibility={{ announcements: active?.data?.current?.announcements ?? undefined }}
            onDragStart={(event) => {
              setActive(event?.active ?? null)
            }}
            onDragEnd={(event) => {
              setActive(null)
              const overId = event.over?.id
              if (!overId || event?.active?.data?.current?.type === 'sort') return

              const droppedNodeIdentifier = event.over?.data.current?.identifier // This is added manually to the droppable component ReadOnlyTree
              if (droppedNodeIdentifier === 'formula') {
                queryErrorToast('GL Accounts Can Only be Mapped to Heading Nodes.')
                return
              }

              const movingAccounts = event.active.data.current?.movingGlAccounts // This is added manually to the draggable component
              const movingGlAccountIds = movingAccounts.map((item: GeneralLedgerAccount) => item.id)

              updateMapping({
                hierarchyId: hierarchy.id,
                destinationNodeId: overId as number,
                movingGlAccountIds: movingGlAccountIds,
                sourceNodeId: selectedNodeId as number
              })
            }}
          >
            {isLoading ? (
              <div className='flex h-96'>
                <div className='relative h-full flex-1'>
                  <SkeletonTable />
                </div>
                <div className='relative h-full flex-1'>
                  <SkeletonTable />
                </div>
              </div>
            ) : (
              <div className='flex h-[calc(100vh-120px)] gap-2'>
                <div className='flex basis-5/12 flex-col rounded-md border-2 p-1'>
                  <HierarchyBuilderTree
                    hierarchyId={hierarchy.id}
                    isEditMode={true}
                    nodes={nodes || []}
                    showSearchInput={true}
                    onNodeClick={(id: UniqueIdentifier) => {
                      if (mode !== ChartOfAccountDisplayModes.mapped)
                        setMode(ChartOfAccountDisplayModes.mapped)

                      if (id === selectedNodeId) {
                        setSelectedNodeId(undefined)
                      } else {
                        setSelectedNodeId(id)
                      }
                    }}
                    selectedNodeId={selectedNodeId as number}
                  />
                </div>
                <div className='flex basis-7/12 flex-col overflow-auto p-1'>
                  <ModeToggle
                    mode={mode}
                    setMode={setMode}
                    hierarchyId={hierarchy.id}
                    handleModeChange={(event: ChartOfAccountDisplayModes) => {
                      setMode(event)
                      setSelectedNodeId(undefined)
                    }}
                  />
                  <DetailsPanel
                    selectedNode={selectedNode}
                    mode={mode}
                    hierarchyId={hierarchy.id}
                  />
                </div>
              </div>
            )}
          </DndContext>
        )}
      </ConfigureTrialBalanceContext.Provider>
    </div>
  )
}

const ModeToggle = ({
  mode,
  setMode,
  handleModeChange,
  hierarchyId
}: {
  mode: ChartOfAccountDisplayModes
  setMode: (mode: ChartOfAccountDisplayModes) => void
  hierarchyId: number
  handleModeChange: (event: any) => void
}) => {
  const { isLoading, data: unmappedAccounts } = useFetchUnmapped(hierarchyId)
  const { user, isPrimaryBusinessCharger } = useAuth()

  const currentBusiness = user?.businesses?.find((business) => business.id === user?.business_id)

  const unmappedCount = useMemo(() => {
    const unmappedPostingAccounts =
      !isLoading && unmappedAccounts
        ? unmappedAccounts.filter((unmappedAccount) => unmappedAccount.is_posting)
        : []

    return unmappedPostingAccounts?.length
  }, [unmappedAccounts, isLoading])

  return (
    <Tabs id='mode-tabs' value={mode} onValueChange={handleModeChange}>
      <TabsList className='gap-2 p-1'>
        <TabsTrigger
          value={ChartOfAccountDisplayModes.mapped}
          className='w-36 border-none data-[state=active]:shadow-sm'
        >
          Mapped
        </TabsTrigger>

        <TabsTrigger
          value={ChartOfAccountDisplayModes.unmapped}
          className='w-36 border-none data-[state=active]:shadow-sm'
        >
          Unmapped {!isLoading && `(${unmappedCount})`}
        </TabsTrigger>

        {currentBusiness?.automap_gl_account && isPrimaryBusinessCharger() && (
          <TabsTrigger
            value={ChartOfAccountDisplayModes.automapped}
            className='w-36 border-none data-[state=active]:shadow-sm'
          >
            Auto-Mapped
          </TabsTrigger>
        )}

        <TabsTrigger
          value={ChartOfAccountDisplayModes.nonposting}
          className='w-36 border-none data-[state=active]:shadow-sm'
        >
          Non-Posting
        </TabsTrigger>
      </TabsList>
    </Tabs>
  )
}

export default WorkingHierarchy

export const sample_data = [
  {
    account_description: 'Sales:Catering Sales',
    account_description_sort_order: null,
    account_id: 343,
    amount: 2349.13,
    date: '2023-01-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Catering Sales'
  },
  {
    account_description: 'Sales:Online Sales - Bellevue',
    account_description_sort_order: null,
    account_id: 348,
    amount: 4317.02,
    date: '2023-01-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Bellevue'
  },
  {
    account_description: 'Sales:Online Sales - Edmonds',
    account_description_sort_order: null,
    account_id: 13,
    amount: 7693.31,
    date: '2023-01-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Edmonds'
  },
  {
    account_description: 'Sales:Online Sales - Everett Mall',
    account_description_sort_order: null,
    account_id: 88,
    amount: 11266.96,
    date: '2023-01-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Everett Mall'
  },
  {
    account_description: 'Sales:Online Sales - Juanita',
    account_description_sort_order: null,
    account_id: 64,
    amount: 8671.83,
    date: '2023-01-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Juanita'
  },
  {
    account_description: 'Sales:Online Sales - Pacific Place',
    account_description_sort_order: null,
    account_id: 332,
    amount: 13729.77,
    date: '2023-01-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Pacific Place'
  },
  {
    account_description: 'Sales:Online Sales - Wallingford',
    account_description_sort_order: null,
    account_id: 12,
    amount: 25748.5,
    date: '2023-01-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Wallingford'
  },
  {
    account_description: 'Sales:Retail Sales - Bellevue',
    account_description_sort_order: null,
    account_id: 346,
    amount: 16817,
    date: '2023-01-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Bellevue'
  },
  {
    account_description: 'Sales:Retail Sales - Edmonds',
    account_description_sort_order: null,
    account_id: 22,
    amount: 10511,
    date: '2023-01-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Edmonds'
  },
  {
    account_description: 'Sales:Retail Sales - Everett Mall',
    account_description_sort_order: null,
    account_id: 89,
    amount: 9292,
    date: '2023-01-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Everett Mall'
  },
  {
    account_description: 'Sales:Retail Sales - Juanita',
    account_description_sort_order: null,
    account_id: 63,
    amount: 19492,
    date: '2023-01-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Juanita'
  },
  {
    account_description: 'Sales:Retail Sales - Pacific Place',
    account_description_sort_order: null,
    account_id: 331,
    amount: 33481,
    date: '2023-01-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Pacific Place'
  },
  {
    account_description: 'Sales:Retail Sales - Wallingford',
    account_description_sort_order: null,
    account_id: 23,
    amount: 24122,
    date: '2023-01-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Wallingford'
  },
  {
    account_description: 'Sales:Catering Sales',
    account_description_sort_order: null,
    account_id: 343,
    amount: 2321.61,
    date: '2023-02-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Catering Sales'
  },
  {
    account_description: 'Sales:Online Sales - Bellevue',
    account_description_sort_order: null,
    account_id: 348,
    amount: 4016.26,
    date: '2023-02-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Bellevue'
  },
  {
    account_description: 'Sales:Online Sales - Edmonds',
    account_description_sort_order: null,
    account_id: 13,
    amount: 9111.07,
    date: '2023-02-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Edmonds'
  },
  {
    account_description: 'Sales:Online Sales - Everett Mall',
    account_description_sort_order: null,
    account_id: 88,
    amount: 10859.89,
    date: '2023-02-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Everett Mall'
  },
  {
    account_description: 'Sales:Online Sales - Juanita',
    account_description_sort_order: null,
    account_id: 64,
    amount: 9768.16,
    date: '2023-02-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Juanita'
  },
  {
    account_description: 'Sales:Online Sales - Pacific Place',
    account_description_sort_order: null,
    account_id: 332,
    amount: 14070.47,
    date: '2023-02-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Pacific Place'
  },
  {
    account_description: 'Sales:Online Sales - Wallingford',
    account_description_sort_order: null,
    account_id: 12,
    amount: 29056.59,
    date: '2023-02-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Wallingford'
  },
  {
    account_description: 'Sales:Retail Sales - Bellevue',
    account_description_sort_order: null,
    account_id: 346,
    amount: 15690,
    date: '2023-02-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Bellevue'
  },
  {
    account_description: 'Sales:Retail Sales - Edmonds',
    account_description_sort_order: null,
    account_id: 22,
    amount: 12726,
    date: '2023-02-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Edmonds'
  },
  {
    account_description: 'Sales:Retail Sales - Everett Mall',
    account_description_sort_order: null,
    account_id: 89,
    amount: 10419,
    date: '2023-02-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Everett Mall'
  },
  {
    account_description: 'Sales:Retail Sales - Juanita',
    account_description_sort_order: null,
    account_id: 63,
    amount: 17982,
    date: '2023-02-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Juanita'
  },
  {
    account_description: 'Sales:Retail Sales - Pacific Place',
    account_description_sort_order: null,
    account_id: 331,
    amount: 42536,
    date: '2023-02-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Pacific Place'
  },
  {
    account_description: 'Sales:Retail Sales - Wallingford',
    account_description_sort_order: null,
    account_id: 23,
    amount: 24390,
    date: '2023-02-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Wallingford'
  },
  {
    account_description: 'Sales:Catering Sales',
    account_description_sort_order: null,
    account_id: 343,
    amount: 4575.82,
    date: '2023-03-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Catering Sales'
  },
  {
    account_description: 'Sales:Online Sales - Bellevue',
    account_description_sort_order: null,
    account_id: 348,
    amount: 3929.07,
    date: '2023-03-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Bellevue'
  },
  {
    account_description: 'Sales:Online Sales - Edmonds',
    account_description_sort_order: null,
    account_id: 13,
    amount: 10330.32,
    date: '2023-03-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Edmonds'
  },
  {
    account_description: 'Sales:Online Sales - Everett Mall',
    account_description_sort_order: null,
    account_id: 88,
    amount: 12891.35,
    date: '2023-03-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Everett Mall'
  },
  {
    account_description: 'Sales:Online Sales - Juanita',
    account_description_sort_order: null,
    account_id: 64,
    amount: 10486.81,
    date: '2023-03-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Juanita'
  },
  {
    account_description: 'Sales:Online Sales - Pacific Place',
    account_description_sort_order: null,
    account_id: 332,
    amount: 17723.27,
    date: '2023-03-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Pacific Place'
  },
  {
    account_description: 'Sales:Online Sales - Wallingford',
    account_description_sort_order: null,
    account_id: 12,
    amount: 29199.45,
    date: '2023-03-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Wallingford'
  },
  {
    account_description: 'Sales:Retail Sales - Bellevue',
    account_description_sort_order: null,
    account_id: 346,
    amount: 17326,
    date: '2023-03-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Bellevue'
  },
  {
    account_description: 'Sales:Retail Sales - Edmonds',
    account_description_sort_order: null,
    account_id: 22,
    amount: 14007,
    date: '2023-03-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Edmonds'
  },
  {
    account_description: 'Sales:Retail Sales - Everett Mall',
    account_description_sort_order: null,
    account_id: 89,
    amount: 9474,
    date: '2023-03-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Everett Mall'
  },
  {
    account_description: 'Sales:Retail Sales - Juanita',
    account_description_sort_order: null,
    account_id: 63,
    amount: 20915,
    date: '2023-03-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Juanita'
  },
  {
    account_description: 'Sales:Retail Sales - Pacific Place',
    account_description_sort_order: null,
    account_id: 331,
    amount: 45536,
    date: '2023-03-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Pacific Place'
  },
  {
    account_description: 'Sales:Retail Sales - Wallingford',
    account_description_sort_order: null,
    account_id: 23,
    amount: 25543,
    date: '2023-03-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Wallingford'
  },
  {
    account_description: 'Sales:Catering Sales',
    account_description_sort_order: null,
    account_id: 343,
    amount: 3302.81,
    date: '2023-04-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Catering Sales'
  },
  {
    account_description: 'Sales:Online Sales - Bellevue',
    account_description_sort_order: null,
    account_id: 348,
    amount: 3086.35,
    date: '2023-04-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Bellevue'
  },
  {
    account_description: 'Sales:Online Sales - Edmonds',
    account_description_sort_order: null,
    account_id: 13,
    amount: 8305.14,
    date: '2023-04-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Edmonds'
  },
  {
    account_description: 'Sales:Online Sales - Everett Mall',
    account_description_sort_order: null,
    account_id: 88,
    amount: 10351.54,
    date: '2023-04-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Everett Mall'
  },
  {
    account_description: 'Sales:Online Sales - Juanita',
    account_description_sort_order: null,
    account_id: 64,
    amount: 8631.89,
    date: '2023-04-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Juanita'
  },
  {
    account_description: 'Sales:Online Sales - Pacific Place',
    account_description_sort_order: null,
    account_id: 332,
    amount: 15348.18,
    date: '2023-04-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Pacific Place'
  },
  {
    account_description: 'Sales:Online Sales - Wallingford',
    account_description_sort_order: null,
    account_id: 12,
    amount: 28596.64,
    date: '2023-04-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Wallingford'
  },
  {
    account_description: 'Sales:Retail Sales - Bellevue',
    account_description_sort_order: null,
    account_id: 346,
    amount: 14061,
    date: '2023-04-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Bellevue'
  },
  {
    account_description: 'Sales:Retail Sales - Edmonds',
    account_description_sort_order: null,
    account_id: 22,
    amount: 14369,
    date: '2023-04-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Edmonds'
  },
  {
    account_description: 'Sales:Retail Sales - Everett Mall',
    account_description_sort_order: null,
    account_id: 89,
    amount: 9054,
    date: '2023-04-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Everett Mall'
  },
  {
    account_description: 'Sales:Retail Sales - Juanita',
    account_description_sort_order: null,
    account_id: 63,
    amount: 21789,
    date: '2023-04-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Juanita'
  },
  {
    account_description: 'Sales:Retail Sales - Pacific Place',
    account_description_sort_order: null,
    account_id: 331,
    amount: 43339,
    date: '2023-04-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Pacific Place'
  },
  {
    account_description: 'Sales:Retail Sales - Wallingford',
    account_description_sort_order: null,
    account_id: 23,
    amount: 27209,
    date: '2023-04-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Wallingford'
  },
  {
    account_description: 'Sales of Product Income',
    account_description_sort_order: null,
    account_id: 365,
    amount: 8442.99,
    date: '2023-05-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales of Product Income'
  },
  {
    account_description: 'Sales:Catering Sales',
    account_description_sort_order: null,
    account_id: 343,
    amount: 9256.23,
    date: '2023-05-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Catering Sales'
  },
  {
    account_description: 'Sales:Online Sales - Bellevue',
    account_description_sort_order: null,
    account_id: 348,
    amount: 3547.67,
    date: '2023-05-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Bellevue'
  },
  {
    account_description: 'Sales:Online Sales - Edmonds',
    account_description_sort_order: null,
    account_id: 13,
    amount: 9653.72,
    date: '2023-05-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Edmonds'
  },
  {
    account_description: 'Sales:Online Sales - Everett Mall',
    account_description_sort_order: null,
    account_id: 88,
    amount: 12960.71,
    date: '2023-05-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Everett Mall'
  },
  {
    account_description: 'Sales:Online Sales - Juanita',
    account_description_sort_order: null,
    account_id: 64,
    amount: 9869.96,
    date: '2023-05-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Juanita'
  },
  {
    account_description: 'Sales:Online Sales - Pacific Place',
    account_description_sort_order: null,
    account_id: 332,
    amount: 17853.69,
    date: '2023-05-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Pacific Place'
  },
  {
    account_description: 'Sales:Online Sales - Redmond',
    account_description_sort_order: null,
    account_id: 363,
    amount: 1097.46,
    date: '2023-05-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Redmond'
  },
  {
    account_description: 'Sales:Online Sales - Wallingford',
    account_description_sort_order: null,
    account_id: 12,
    amount: 29114.75,
    date: '2023-05-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Wallingford'
  },
  {
    account_description: 'Sales:Retail Sales - Bellevue',
    account_description_sort_order: null,
    account_id: 346,
    amount: 12748,
    date: '2023-05-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Bellevue'
  },
  {
    account_description: 'Sales:Retail Sales - Edmonds',
    account_description_sort_order: null,
    account_id: 22,
    amount: 14836,
    date: '2023-05-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Edmonds'
  },
  {
    account_description: 'Sales:Retail Sales - Everett Mall',
    account_description_sort_order: null,
    account_id: 89,
    amount: 8711,
    date: '2023-05-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Everett Mall'
  },
  {
    account_description: 'Sales:Retail Sales - Juanita',
    account_description_sort_order: null,
    account_id: 63,
    amount: 26111,
    date: '2023-05-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Juanita'
  },
  {
    account_description: 'Sales:Retail Sales - Pacific Place',
    account_description_sort_order: null,
    account_id: 331,
    amount: 36937,
    date: '2023-05-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Pacific Place'
  },
  {
    account_description: 'Sales:Retail Sales - Redmond',
    account_description_sort_order: null,
    account_id: 362,
    amount: 8525.25,
    date: '2023-05-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Redmond'
  },
  {
    account_description: 'Sales:Retail Sales - Wallingford',
    account_description_sort_order: null,
    account_id: 23,
    amount: 26367,
    date: '2023-05-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Wallingford'
  },
  {
    account_description: 'Sales:Catering Sales',
    account_description_sort_order: null,
    account_id: 343,
    amount: 3991.14,
    date: '2023-06-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Catering Sales'
  },
  {
    account_description: 'Sales:Online Sales - Bellevue',
    account_description_sort_order: null,
    account_id: 348,
    amount: 3336.85,
    date: '2023-06-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Bellevue'
  },
  {
    account_description: 'Sales:Online Sales - Edmonds',
    account_description_sort_order: null,
    account_id: 13,
    amount: 9674.94,
    date: '2023-06-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Edmonds'
  },
  {
    account_description: 'Sales:Online Sales - Everett Mall',
    account_description_sort_order: null,
    account_id: 88,
    amount: 17551.8,
    date: '2023-06-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Everett Mall'
  },
  {
    account_description: 'Sales:Online Sales - Juanita',
    account_description_sort_order: null,
    account_id: 64,
    amount: 9050.92,
    date: '2023-06-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Juanita'
  },
  {
    account_description: 'Sales:Online Sales - Pacific Place',
    account_description_sort_order: null,
    account_id: 332,
    amount: 21290.44,
    date: '2023-06-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Pacific Place'
  },
  {
    account_description: 'Sales:Online Sales - Redmond',
    account_description_sort_order: null,
    account_id: 363,
    amount: 4447.53,
    date: '2023-06-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Redmond'
  },
  {
    account_description: 'Sales:Online Sales - Wallingford',
    account_description_sort_order: null,
    account_id: 12,
    amount: 30746.09,
    date: '2023-06-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Wallingford'
  },
  {
    account_description: 'Sales:Retail Sales - Bellevue',
    account_description_sort_order: null,
    account_id: 346,
    amount: 13449,
    date: '2023-06-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Bellevue'
  },
  {
    account_description: 'Sales:Retail Sales - Edmonds',
    account_description_sort_order: null,
    account_id: 22,
    amount: 14537,
    date: '2023-06-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Edmonds'
  },
  {
    account_description: 'Sales:Retail Sales - Everett Mall',
    account_description_sort_order: null,
    account_id: 89,
    amount: 4556,
    date: '2023-06-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Everett Mall'
  },
  {
    account_description: 'Sales:Retail Sales - Juanita',
    account_description_sort_order: null,
    account_id: 63,
    amount: 26074,
    date: '2023-06-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Juanita'
  },
  {
    account_description: 'Sales:Retail Sales - Pacific Place',
    account_description_sort_order: null,
    account_id: 331,
    amount: 37359,
    date: '2023-06-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Pacific Place'
  },
  {
    account_description: 'Sales:Retail Sales - Redmond',
    account_description_sort_order: null,
    account_id: 362,
    amount: 17245,
    date: '2023-06-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Redmond'
  },
  {
    account_description: 'Sales:Retail Sales - Wallingford',
    account_description_sort_order: null,
    account_id: 23,
    amount: 24894,
    date: '2023-06-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Wallingford'
  },
  {
    account_description: 'Sales:Catering Sales',
    account_description_sort_order: null,
    account_id: 343,
    amount: 4494.39,
    date: '2023-07-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Catering Sales'
  },
  {
    account_description: 'Sales:Online Sales - Bellevue',
    account_description_sort_order: null,
    account_id: 348,
    amount: 2429.12,
    date: '2023-07-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Bellevue'
  },
  {
    account_description: 'Sales:Online Sales - Edmonds',
    account_description_sort_order: null,
    account_id: 13,
    amount: 7492.16,
    date: '2023-07-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Edmonds'
  },
  {
    account_description: 'Sales:Online Sales - Everett Mall',
    account_description_sort_order: null,
    account_id: 88,
    amount: 11781.51,
    date: '2023-07-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Everett Mall'
  },
  {
    account_description: 'Sales:Online Sales - Juanita',
    account_description_sort_order: null,
    account_id: 64,
    amount: 6980.27,
    date: '2023-07-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Juanita'
  },
  {
    account_description: 'Sales:Online Sales - Pacific Place',
    account_description_sort_order: null,
    account_id: 332,
    amount: 15397.4,
    date: '2023-07-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Pacific Place'
  },
  {
    account_description: 'Sales:Online Sales - Redmond',
    account_description_sort_order: null,
    account_id: 363,
    amount: 9069.47,
    date: '2023-07-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Redmond'
  },
  {
    account_description: 'Sales:Online Sales - Wallingford',
    account_description_sort_order: null,
    account_id: 12,
    amount: 27197.01,
    date: '2023-07-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Wallingford'
  },
  {
    account_description: 'Sales:Retail Sales - Bellevue',
    account_description_sort_order: null,
    account_id: 346,
    amount: 13182,
    date: '2023-07-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Bellevue'
  },
  {
    account_description: 'Sales:Retail Sales - Edmonds',
    account_description_sort_order: null,
    account_id: 22,
    amount: 13163,
    date: '2023-07-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Edmonds'
  },
  {
    account_description: 'Sales:Retail Sales - Everett Mall',
    account_description_sort_order: null,
    account_id: 89,
    amount: 8057,
    date: '2023-07-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Everett Mall'
  },
  {
    account_description: 'Sales:Retail Sales - Juanita',
    account_description_sort_order: null,
    account_id: 63,
    amount: 26106,
    date: '2023-07-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Juanita'
  },
  {
    account_description: 'Sales:Retail Sales - Pacific Place',
    account_description_sort_order: null,
    account_id: 331,
    amount: 45750,
    date: '2023-07-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Pacific Place'
  },
  {
    account_description: 'Sales:Retail Sales - Redmond',
    account_description_sort_order: null,
    account_id: 362,
    amount: 20515.09,
    date: '2023-07-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Redmond'
  },
  {
    account_description: 'Sales:Retail Sales - Wallingford',
    account_description_sort_order: null,
    account_id: 23,
    amount: 23780,
    date: '2023-07-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Retail Sales - Wallingford'
  },
  {
    account_description: 'Sales:Catering Sales',
    account_description_sort_order: null,
    account_id: 343,
    amount: 5852.14,
    date: '2023-08-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Catering Sales'
  },
  {
    account_description: 'Sales:Online Sales - Bellevue',
    account_description_sort_order: null,
    account_id: 348,
    amount: 2737.42,
    date: '2023-08-01',
    header_1: 'Revenue',
    header_1_sort_order: 1,
    header_2: 'Product Sales',
    header_2_sort_order: 1,
    row_num: 1,
    header: 'Revenue.Product Sales.Sales:Online Sales - Bellevue'
  }
]

import { forwardRef } from 'react'
import { useSelector } from 'react-redux'

import { Box, Drawer, DrawerProps, useMediaQuery } from '@mui/material'
import { Theme, useTheme } from '@mui/material/styles'

import { selectHeaderCollapsed } from 'store/slices/navigation'

import Navigation from './DrawerContent/Navigation'
import { drawerStyles } from './MiniDrawerStyled'

interface Props {
  open: boolean
  window?: () => Window
  handleDrawerClose?: () => void
}

const MainDrawer = forwardRef<HTMLDivElement | undefined, Props>((props, ref) => {
  const { open, handleDrawerClose } = props
  const theme = useTheme()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const headerCollapsed = useSelector(selectHeaderCollapsed)

  return (
    <Box
      ref={ref}
      tabIndex={0}
      component='nav'
      sx={{
        display: 'block',
        position: 'relative',
        flexShrink: { md: 0 },
        '&:focus': {
          outline: 'none'
        },
        background: theme.palette.primary.main
      }}
      aria-label='mailbox folders'
    >
      <Drawer
        className='drawer-container'
        tabIndex={0}
        variant='permanent'
        open={open}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        sx={drawerStyles(theme, open, isMobile, headerCollapsed) as DrawerProps['sx']}
      >
        <Navigation closeDrawer={handleDrawerClose} />
      </Drawer>
    </Box>
  )
})

export default MainDrawer

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { TrialBalanceViewMode } from '@components/financial/types'

import { rehydrateStore } from '@store/actions/rehydrate-store'

import { RootState } from '..'

interface TrialBalanceState {
  viewMode: TrialBalanceViewMode
}

const initialState: TrialBalanceState = {
  viewMode: TrialBalanceViewMode.SINGLE_ENTRY
}

const trialBalanceSlice = createSlice({
  name: '@TRIAL_BALANCE',
  initialState,
  reducers: {
    updateTrialBalanceViewMode: (state, action: PayloadAction<TrialBalanceViewMode>) => {
      state.viewMode = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrateStore, (_state, action) => {
      return { ...initialState, ...action.payload.trialBalance }
    })
  }
})

export const { updateTrialBalanceViewMode } = trialBalanceSlice.actions
export const trialBalanceReducer = trialBalanceSlice.reducer

export const selectTrialBalanceViewMode = (state: RootState) => state.trialBalance.viewMode

import { useLatestRef } from '@hooks/useLatestRef'

import { useSelector } from '@store/index'
import {
  selectFilters,
  selectFormulas,
  selectHiddenFilters,
  selectLimit,
  selectParameterizedConfig,
  selectQueryData,
  selectSorts
} from '@store/slices/component/query-config'

import { QueryBuilderPlacement } from '../types/query-builder-types'

export function useComponentAttrRef(placement?: QueryBuilderPlacement) {
  const queryData = useSelector(selectQueryData(placement))
  const hiddenFilters = useSelector(selectHiddenFilters)
  const filters = useSelector(selectFilters)
  const sorts = useSelector(selectSorts)
  const limit = useSelector(selectLimit)
  const formulas = useSelector(selectFormulas)
  const parameterizedConfig = useSelector(selectParameterizedConfig)
  const queryDataRef = useLatestRef(queryData)
  const hiddenFiltersRef = useLatestRef(hiddenFilters)
  const filtersRef = useLatestRef(filters)
  const sortsRef = useLatestRef(sorts)
  const limitRef = useLatestRef(limit)
  const formulasRef = useLatestRef(formulas)
  const parameterizedConfigRef = useLatestRef(parameterizedConfig)

  return {
    queryDataRef,
    hiddenFiltersRef,
    filtersRef,
    sortsRef,
    limitRef,
    formulasRef,
    parameterizedConfigRef
  }
}

import { useState } from 'react'

import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormLabel,
  Grid,
  SelectChangeEvent,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material'
import { Stack } from '@mui/system'

import { enumToOptions } from '@utils/obj-utils'

import { Icon, PlusCircle, Trash } from '@components/icons'

import useDelayedUpdate from '@hooks/use-delayed-update'

import { useDispatch } from '@store/index'
import { useSelector } from '@store/index'
import {
  clearXAxisLabels,
  syncXAxisLabels,
  updateXAxisData,
  waterfallSeriesPresent as waterfallSeriesPresentSelector
} from '@store/slices/component/chart-config'

import { RubyEditor } from 'components/code-editors'
import MuiSelect from 'components/form/mui-select'
import { nanoid } from 'nanoid'

import CustomTabPanelMain, { CustomTabPanelProps } from '../common/custom-tab-panel'
import { useQueryResultContext } from '../contexts/query-result-context'
import {
  BucketEdgeNodeType,
  BucketIntermediateNodeType,
  BucketLabelType,
  BucketLabelsType,
  BucketValueType,
  IAxis
} from '../types/chart-builder-types'
import { IDataType } from '../types/component-types'
import a11yPropsMain from '../utils/a11y-props'

const DEBOUNCE_TIME = 1_500

const a11yProps = (index: number) => a11yPropsMain(index, 'labels')
const CustomTabPanel = (props: CustomTabPanelProps) => (
  <CustomTabPanelMain {...props} uniqueId='labels' padding={0} />
)

function EdgeNodeConfig({
  nodeData,
  edgeType = 'begin',
  selected,
  labelOptions
}: {
  nodeData?: BucketEdgeNodeType
  edgeType: 'begin' | 'end'
  selected: IAxis
  labelOptions: { label: string; value: string }[]
}) {
  const dispatch = useDispatch()
  const {
    label_type = BucketLabelType.UsePeriodLabel,
    label_formula,
    label_formula_data_type = IDataType.TEXT,
    value_type = BucketValueType.Formula,
    value_formula,
    value_formula_data_type = IDataType.NUMBER,
    value_kpi_node
  } = nodeData || {}

  let nodeLabel = 'Begin'

  if (edgeType === 'end') {
    nodeLabel = 'End'
  }

  const updater = (payload: BucketEdgeNodeType) => {
    dispatch(updateXAxisData({ id: selected.id, [edgeType]: _.assign({}, nodeData, payload) }))
  }

  const [labelFormulaState, setLabelFormulaState] = useDelayedUpdate(
    label_formula || '',
    (value) => updater({ label_formula: value, label_formula_data_type }),
    DEBOUNCE_TIME
  )
  const [valueFormulaState, setValueFormulaState] = useDelayedUpdate(
    value_formula || '',
    (value) => updater({ value_formula: value, value_formula_data_type }),
    DEBOUNCE_TIME
  )

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant='details'>{nodeLabel} Value</Typography>
      </Grid>
      <Grid item xs={12}>
        <MuiSelect
          options={enumToOptions(BucketValueType)}
          value={value_type}
          onChange={(e: SelectChangeEvent<string>) => {
            updater({ value_type: e.target.value as BucketValueType })
          }}
          label='Type'
        />
      </Grid>
      {value_type === BucketValueType.Formula && (
        <Grid item xs={12}>
          <FormLabel>Formula (Number)</FormLabel>
          <RubyEditor value={valueFormulaState} onChange={setValueFormulaState} />
        </Grid>
      )}
      {value_type === BucketValueType.KPINode && (
        <Grid item xs={12}>
          <MuiSelect
            options={labelOptions}
            value={value_kpi_node}
            onChange={(e: SelectChangeEvent<string>) => {
              updater({ value_kpi_node: e.target.value })
            }}
            label='KPI Node'
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography variant='details'>{nodeLabel} Label</Typography>
      </Grid>
      <Grid item xs={12}>
        <MuiSelect
          options={enumToOptions(BucketLabelType)}
          value={label_type}
          onChange={(e: SelectChangeEvent<string>) => {
            updater({ label_type: e.target.value as BucketLabelType })
          }}
          label='Type'
        />
      </Grid>
      {label_type === BucketLabelType.Formula && (
        <Grid item xs={12}>
          <Grid item xs={12}>
            <FormLabel>Formula (String)</FormLabel>
            <RubyEditor value={labelFormulaState} onChange={setLabelFormulaState} />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

function IntermediateNodeConfig({
  nodeData,
  index,
  onRemove,
  onUpdate
}: {
  nodeData?: BucketIntermediateNodeType
  index: number
  onRemove: (id: string) => void
  onUpdate: (id: string, payload: BucketIntermediateNodeType) => void
}) {
  const {
    id,
    label_formula,
    label_formula_data_type = IDataType.TEXT,
    value_formula,
    value_formula_data_type = IDataType.NUMBER
  } = nodeData || {}

  const [labelFormulaState, setLabelFormulaState] = useDelayedUpdate(
    label_formula || '',
    (value) => onUpdate(id!, { label_formula: value, label_formula_data_type }),
    DEBOUNCE_TIME
  )

  const [valueFormulaState, setValueFormulaState] = useDelayedUpdate(
    value_formula || '',
    (value) => onUpdate(id!, { value_formula: value, value_formula_data_type }),
    DEBOUNCE_TIME
  )

  return (
    <>
      <Grid item xs={12} container alignItems='center' gap={1}>
        <Typography variant='websiteDetails'>Node {index + 1}</Typography>
        <Box flexGrow={1}>
          <Divider />
        </Box>
        <Button
          variant='outlined'
          onClick={() => onRemove(id!)}
          aria-label='Remove Intermediate Label'
        >
          <Stack direction='row' alignItems='center' gap={1}>
            <Icon icon={<Trash />} /> Remove
          </Stack>
        </Button>
      </Grid>
      <Grid item xs={12} marginTop={-1.5}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormLabel>Value (Number)</FormLabel>
            <RubyEditor
              style={{ border: '1px solid #F2F2F2' }}
              value={valueFormulaState}
              onChange={setValueFormulaState}
            />
          </Grid>
          <Grid item xs={12}>
            <FormLabel>Label</FormLabel>
            <RubyEditor
              style={{ border: '1px solid #F2F2F2' }}
              value={labelFormulaState}
              onChange={setLabelFormulaState}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default function WaterfallBucket({ selected }: { selected: IAxis }) {
  const [tabValue, setTabValue] = useState(0)
  const { dataRef: resultsRef, metadata } = useQueryResultContext()

  const dispatch = useDispatch()
  const waterfallSeriesPresent = useSelector(waterfallSeriesPresentSelector)

  const {
    begin,
    intermediate_nodes_type = BucketLabelsType.FromData,
    intermediate_nodes = [],
    end
  } = selected

  const labelOptions = _.concat(
    [{ value: '-', label: 'None' }],
    _.map(
      _.filter(intermediate_nodes, (node) => !!node.value),
      (node) => ({ value: node.value!, label: node.name! })
    )
  )

  if (!waterfallSeriesPresent) return null

  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h5'>Configure Labels</Typography>
        <Typography variant='details' gutterBottom>
          Configure each Labels
        </Typography>
        <Stack
          direction='row'
          justifyContent='space-between'
          sx={{
            backgroundColor: '#f8f8f8'
          }}
        >
          <Tabs
            value={tabValue}
            onChange={(_e, val) => setTabValue(val)}
            aria-label='chart config'
            variant='scrollable'
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label='Begin' {...a11yProps(0)} />
            <Tab label='Intermediate' {...a11yProps(1)} />
            <Tab label='End' {...a11yProps(2)} />
          </Tabs>
          <Stack direction='row' alignItems='center'>
            <ButtonGroup variant='outlined'>
              <Tooltip title='Load Labels from Data'>
                <Button
                  onClick={() => {
                    dispatch(
                      syncXAxisLabels({ id: selected.id, data: resultsRef.current, metadata })
                    )
                  }}
                >
                  Load
                </Button>
              </Tooltip>
              <Tooltip title='Clear Labels'>
                <Button
                  onClick={() => {
                    dispatch(clearXAxisLabels({ id: selected.id }))
                  }}
                >
                  Clear
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Stack>
        </Stack>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <CustomTabPanel value={tabValue} index={0}>
          <EdgeNodeConfig
            nodeData={begin}
            edgeType='begin'
            selected={selected}
            labelOptions={labelOptions}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MuiSelect
                options={enumToOptions(BucketLabelsType)}
                value={intermediate_nodes_type}
                onChange={(e: SelectChangeEvent<string>) => {
                  dispatch(
                    updateXAxisData({
                      id: selected.id,
                      intermediate_nodes_type: e.target.value as BucketLabelsType,
                      intermediate_nodes: []
                    })
                  )
                }}
                label='Labels Type'
              />
            </Grid>
            <Grid item xs={12}>
              {intermediate_nodes_type === BucketLabelsType.FromData && (
                <Grid container spacing={1}>
                  {intermediate_nodes
                    .filter(
                      (label) =>
                        label.value !== begin?.value_kpi_node && label.value !== end?.value_kpi_node
                    )
                    .map((label) => {
                      return (
                        <Grid key={label.name} item xs={12}>
                          <Typography variant='body'>{label.name}</Typography>
                        </Grid>
                      )
                    })}
                </Grid>
              )}
              {intermediate_nodes_type === BucketLabelsType.CustomDefinition && (
                <Grid container spacing={1}>
                  {intermediate_nodes.map((node, index) => (
                    <IntermediateNodeConfig
                      key={node.id}
                      nodeData={node}
                      index={index}
                      onRemove={(id: string) => {
                        dispatch(
                          updateXAxisData({
                            id: selected.id,
                            intermediate_nodes: _.filter(intermediate_nodes, (l) => l.id !== id)
                          })
                        )
                      }}
                      onUpdate={(id: string, payload: BucketIntermediateNodeType) => {
                        dispatch(
                          updateXAxisData({
                            id: selected.id,
                            intermediate_nodes: _.map(intermediate_nodes, (l) => {
                              if (l.id === id) {
                                return _.assign({}, node, payload)
                              }
                              return l
                            })
                          })
                        )
                      }}
                    />
                  ))}
                  <Grid item xs={12} container alignItems='center'>
                    <Box flexGrow={1}>
                      <Divider />
                    </Box>
                    <Button
                      variant='outlined'
                      onClick={() => {
                        dispatch(
                          updateXAxisData({
                            id: selected.id,
                            intermediate_nodes: [...intermediate_nodes, { id: nanoid() }]
                          })
                        )
                      }}
                    >
                      <Stack direction='row' alignItems='center' gap={1}>
                        <Icon icon={<PlusCircle />} />
                        Add
                      </Stack>
                    </Button>
                    <Box flexGrow={1}>
                      <Divider />
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          <EdgeNodeConfig
            nodeData={end}
            edgeType='end'
            selected={selected}
            labelOptions={labelOptions}
          />
        </CustomTabPanel>
      </Grid>
    </>
  )
}

import * as am5xy from '@amcharts/amcharts5/xy'

export const setAxisCellStartEndLocation = (
  renderer: am5xy.AxisRendererX | am5xy.AxisRendererY
) => {
  renderer.setAll({
    cellStartLocation: 0.1,
    cellEndLocation: 0.9
  })
}

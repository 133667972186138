import { PayloadAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'

import { IResultValue } from 'pages/component-management/types/shared-types'
import { RootState } from 'store'
import { humanizeFieldName } from 'utils/string-utils'

export type ActiveDrilldown = { key: string; filter?: IResultValue }
export type FocusedDrilldown = { key: string; filter: IResultValue }

export type Drilldown = {
  componentId: string
  activeDimensions: ActiveDrilldown[]
  focusedDimension?: FocusedDrilldown
}

const drilldownsAdapter = createEntityAdapter({
  selectId: (drilldown: Drilldown) => drilldown.componentId
})

const defaultState: Omit<Drilldown, 'componentId'> = {
  activeDimensions: []
}

const drilldownsSlice = createSlice({
  name: '@COMPONENT/DRILLDOWNS',
  initialState: drilldownsAdapter.getInitialState(),
  reducers: {
    addDrilldown: drilldownsAdapter.addOne,
    removeDrilldown: drilldownsAdapter.removeOne,
    setFocusedDimensions: (
      state,
      action: PayloadAction<{ componentId: string; focusedDimension: FocusedDrilldown }>
    ) => {
      const { componentId, focusedDimension } = action.payload
      drilldownsAdapter.updateOne(state, { id: componentId, changes: { focusedDimension } })
    },
    setActiveDimensions: (
      state,
      action: PayloadAction<{ componentId: string; activeDimensions: ActiveDrilldown[] }>
    ) => {
      const { componentId, activeDimensions } = action.payload
      drilldownsAdapter.updateOne(state, { id: componentId, changes: { activeDimensions } })
    }
  }
})

// Reducer
export const drilldownsReducer = drilldownsSlice.reducer

// Selectors
const getDrilldownsState = (state: RootState) => _.get(state, ['component', 'drilldowns'])

export const getActiveDrilldownDimensions = (componentId: string) => {
  return createSelector(getDrilldownsState, (drilldowns) =>
    _.get(drilldowns, ['entities', componentId, 'activeDimensions'], defaultState.activeDimensions)
  )
}

export const getFocusedDrilldownDimension = (componentId: string) => {
  return createSelector(getDrilldownsState, (drilldowns) =>
    _.get(drilldowns, ['entities', componentId, 'focusedDimension'])
  )
}

export const getDrilldown = (componentId: string) => {
  return createSelector(getDrilldownsState, (drilldowns) =>
    _.get(drilldowns, ['entities', componentId])
  )
}

export const getFilteredDrilldownDimensions = (
  componentId: string,
  availableDimensions: string[]
) => {
  return createSelector(getDrilldownsState, (drilldowns) => {
    const drilldown = _.get(drilldowns, ['entities', componentId])
    if (!drilldown) return []

    return _.map(
      _.difference(availableDimensions, _.map(_.get(drilldown, 'activeDimensions'), 'key')),
      (item) => ({
        label: humanizeFieldName(item),
        value: item
      })
    )
  })
}

// Actions
export const { addDrilldown, removeDrilldown, setFocusedDimensions, setActiveDimensions } =
  drilldownsSlice.actions

import React, { useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  ColDef,
  ICellRendererParams,
  ValueFormatterParams
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { compareStringsCaseInsensitive } from '@utils/string-utils'

import AgGrid from '@components/ag-grid'
import { applyHighlightRenderer } from '@components/ag-grid/custom-cell-renderer/highlight-cell-renderer'
import OpenInNewTabCellRenderer from '@components/ag-grid/custom-cell-renderer/open-in-new-tab-cell-renderer'
import { useSkeleton } from '@components/ag-grid/hooks/useSkeleton'
import { fixedColumnWidths } from '@components/ag-grid/utils'
import { Button } from '@components/core/button'
import { IconButton } from '@components/core/icon-button'
import { Cancel, Check, Icon, Pencil } from '@components/icons'

import { UNDO_REDO_CELL_EDITING_LIMIT } from '@hooks/grid/useKeyboardDetection'
import { useMaxRows } from '@hooks/grid/useMaxRows'

import { RootState } from '@store/index'
import { selectGridSearchTerm } from '@store/slices/grid'

import { AgGridRef, useRegisterAgGridRefEffect } from 'contexts/grid-ref-context'

import { useFetchAllBusinessesConnectedSourceSystems } from './queries/fetch-all-businesses-connected-source-systems'
import { useFetchSourceApplications } from './queries/fetch-source-applications'
import { useUpdateConnectedSourceSystem } from './queries/update-connected-source-system'
import { DataIntegrityCheckType, PulseStatus, getPulseStatus } from './types'
import {
  dateColumnDef,
  lastSyncStatusColumnDef,
  multiNumericColumnDef,
  multiTextColumnDef,
  numericColumnDef,
  staticSwitchColumnDef,
  textSearchColumnDef
} from './utils/col-defs'

const SKELETON_COLUMN_WIDTHS: Record<string, number> = {
  openInNewTab: 50,
  businessId: 80,
  businessName: 150,
  sourceApplicationId: 80,
  sourceApplicationName: 150,
  sourceSystemId: 80,
  sourceSystemName: 150,
  id: 80,
  name: 200,
  destinationSchema: 150,
  lastHeartbeatAt: 120,
  // activeConnectionsCount: 80,
  pulseSupported: 70,
  lastSyncTime: 120,
  lastSyncStatus: 100,
  isActive: 75,
  actions: 80
}

const generateData = (maxRows: number) => new Array(maxRows).fill({})

const useConnectedSourceSystemListSkeleton = ({
  columnDefinition,
  wrapperRef,
  isLoading,
  gridRef
}: {
  columnDefinition: ColDef[]
  wrapperRef: React.RefObject<HTMLDivElement>
  isLoading: boolean
  gridRef: AgGridRef
}) => {
  const columnDefsRef = useRef<ColDef[]>(columnDefinition)
  const { maxRows, refetch: refetchMaxLoadingRows } = useMaxRows(wrapperRef)
  const skeletonData = useMemo(() => generateData(maxRows), [maxRows])
  const skeletonColumnDefs = useMemo(
    () => fixedColumnWidths(columnDefsRef.current, SKELETON_COLUMN_WIDTHS),
    []
  )
  const skeletonInfo = useSkeleton({
    skeletonData,
    columnDefs: skeletonColumnDefs,
    forDataTable: true
  })

  return _.assign({ refetchMaxLoadingRows }, skeletonInfo)
}

export const ConnectedSourceSystemAllBusinessesList = () => {
  const { isPending = false, data } = useFetchAllBusinessesConnectedSourceSystems()

  const { data: sourceApplications } = useFetchSourceApplications()
  const connectedSourceSystems = useMemo(() => data ?? [], [data])
  const wrapperRef = useRef<HTMLDivElement>(null)
  const gridRef = useRef<AgGridReact>(null)
  const [editingRowId, setEditingRowId] = useState<string | null>(null)
  const searchTerm = useSelector((state: RootState) => selectGridSearchTerm(state, undefined))

  const { mutate: updateConnection } = useUpdateConnectedSourceSystem({
    onSuccess: () => {
      gridRef.current?.api.refreshCells({ force: true })
      gridRef.current?.api.deselectAll()
    }
  })

  useRegisterAgGridRefEffect(gridRef)

  const undoEdit = () => {
    gridRef.current?.api.stopEditing()
    gridRef.current?.api.undoCellEditing()
    gridRef.current?.api.deselectAll()
  }

  const undoEditingForRowById = (rowId: string) => {
    const gridApi = gridRef.current?.api
    if (!gridApi) return

    const rowNode = gridApi.getRowNode(rowId)
    if (!rowNode) return

    if (gridApi.getEditingCells().some((cell) => cell.rowIndex === rowNode.rowIndex)) {
      gridApi.stopEditing()
    }

    gridApi.undoCellEditing()
    gridApi.deselectAll()
  }

  const onCellEditingStarted = (event: CellEditingStartedEvent<any, any>) => {
    if (editingRowId !== null && editingRowId !== event.node.id) {
      undoEdit()
    }

    setEditingRowId(event.node.id?.toString() ?? null)
  }

  const onCellEditingStopped = (event: CellEditingStoppedEvent) => {
    if (event.column.getColId() === 'sourceApplicationId_1') {
      undoEditingForRowById(event.node.id?.toString() ?? '')

      setEditingRowId(null)
    }
  }

  const actionCellRenderer = (params: ICellRendererParams) => {
    const onActionButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
      action: 'save' | 'cancel'
    ) => {
      gridRef.current?.api.stopEditing()

      event.stopPropagation()

      if (action === 'save') {
        const newParams = params.node.data
        const updatePayload: any = { connectionId: newParams.id }

        if (newParams?.sourceApplicationId !== undefined) {
          updatePayload.sourceApplicationId = newParams.sourceApplicationId
        }

        updatePayload.businessId = params.node.data.businessId

        updateConnection(updatePayload)
      } else if (action === 'cancel') {
        undoEdit()
      }
    }

    const isCurrentRowEditing: boolean = params.api
      .getEditingCells()
      .some((cell) => cell.rowIndex === params.node.rowIndex)

    if (isCurrentRowEditing) {
      return (
        <div className='flex h-full items-center'>
          <IconButton
            className='mr-1'
            size='action-bar'
            onClick={(event) => onActionButtonClick(event, 'save')}
          >
            <Icon icon={<Check />} className='size-8' />
          </IconButton>

          <IconButton size='action-bar' onClick={(event) => onActionButtonClick(event, 'cancel')}>
            <Icon icon={<Cancel />} className='size-8' />
          </IconButton>
        </div>
      )
    }

    return (
      <div id='action-cell-renderer' className='flex size-full items-center'>
        <Button variant={params.node.isSelected() ? 'primary' : 'text'} className='p-0'>
          <Icon icon={<Pencil />} />
        </Button>
      </div>
    )
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columnDefs: ColDef[] = [
    {
      colId: 'openInNewTab',
      field: 'id',
      headerName: '',
      minWidth: 50,
      maxWidth: 50,
      sortable: false,
      filter: false,
      cellRenderer: 'openInNewTabCellRenderer',
      getQuickFilterText: () => ''
    },
    multiNumericColumnDef('businessId', 'Business ID'),
    multiTextColumnDef('businessName', 'Business Name'),
    multiTextColumnDef('sourceSystemName', 'Source System'),
    multiNumericColumnDef('sourceSystemId', 'Source System ID'),
    multiNumericColumnDef('sourceApplicationId', 'Application ID'),
    {
      headerName: 'Application',
      field: 'sourceApplicationId',
      minWidth: 150,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params: any) => {
        const sourceSystemId = params.data.sourceSystemId
        const filteredSourceApplications = sourceApplications
          ? sourceApplications
              .filter((app) => app.sourceSystemId === sourceSystemId)
              .sort((appA, appB) => compareStringsCaseInsensitive(appA.name, appB.name))
          : []

        return {
          values: filteredSourceApplications.map((app) => app.id)
        }
      },
      filter: 'agMultiColumnFilter',
      filterParams: {
        values: sourceApplications ? sourceApplications.map((app) => app.id) : [],
        valueFormatter: (params: ValueFormatterParams<any, any>) => {
          const app = sourceApplications?.find((app) => app.id === params.value)
          return app ? app.name : ''
        }
      },
      valueFormatter: (params: ValueFormatterParams<any, any>) => {
        const app = sourceApplications?.find((app) => app.id === params.value)
        return app ? app.name : ''
      },
      filterValueGetter: (params) => {
        const app = sourceApplications?.find((app) => app.id === params.data.sourceApplicationId)
        return app ? app.name : ''
      },
      floatingFilter: true,
      editable: true
    },
    numericColumnDef('id', 'Connection ID'),
    textSearchColumnDef('name', 'Connection Name', { width: 200 }),
    multiTextColumnDef('destinationSchema', 'Destination Schema'),
    dateColumnDef('lastHeartbeatAt', 'Last Heartbeat Time'),
    staticSwitchColumnDef('connector.runQaChecks', 'Data Integrity Check', true, {
      maxWidth: 150,
      minWidth: 150,
      filterParams: {
        values: [DataIntegrityCheckType.TRUE, DataIntegrityCheckType.FALSE]
      },
      filterValueGetter: (params) => {
        const value = params.data.connector?.runQaChecks
        return value ? DataIntegrityCheckType.TRUE : DataIntegrityCheckType.FALSE
      },
      filter: 'agMultiColumnFilter'
    }),
    // numericColumnDef('activeConnectionsCount', 'Active Connections Count', { type: 'rightAligned' }),
    staticSwitchColumnDef('pulseSupported', 'Pulse', PulseStatus.ENABLED, {
      maxWidth: 80,
      minWidth: 80,
      filterParams: {
        values: [PulseStatus.ENABLED, PulseStatus.NOT_ENABLED]
      },
      valueGetter: (params) => getPulseStatus(params.data.pulseSupported),
      filterValueGetter: (params) => getPulseStatus(params.data.pulseSupported),
      filter: 'agMultiColumnFilter'
    }),
    dateColumnDef('lastSyncTime', 'Last Sync Time'),
    lastSyncStatusColumnDef({
      filter: 'agMultiColumnFilter'
    }),
    {
      colId: 'actions',
      field: 'id',
      headerName: '',
      minWidth: 70,
      maxWidth: 70,
      sortable: false,
      filter: false,
      cellRenderer: actionCellRenderer,
      getQuickFilterText: () => ''
    }
  ]

  const { refetchMaxLoadingRows } = useConnectedSourceSystemListSkeleton({
    columnDefinition: columnDefs,
    wrapperRef,
    isLoading: isPending,
    gridRef
  })

  const finalColumnDefs = useMemo(
    () => applyHighlightRenderer(columnDefs, searchTerm, ['openInNewTab']),
    [columnDefs, searchTerm]
  )

  return (
    <div className='h-[calc(100vh-9rem)] w-full' ref={wrapperRef}>
      <AgGrid
        style={{ height: '100%' }}
        ref={gridRef}
        rowData={connectedSourceSystems}
        enableRangeSelection={true}
        columnDefs={finalColumnDefs}
        defaultColDef={{
          resizable: true,
          filter: 'agTextColumnFilter',
          floatingFilter: true,
          filterParams: {
            buttons: ['clear']
          }
        }}
        editType={'fullRow'}
        onGridReady={refetchMaxLoadingRows}
        suppressRowClickSelection={true}
        undoRedoCellEditing={true}
        undoRedoCellEditingLimit={UNDO_REDO_CELL_EDITING_LIMIT}
        stopEditingWhenCellsLoseFocus={false}
        onCellEditingStarted={onCellEditingStarted}
        onCellEditingStopped={onCellEditingStopped}
        loading={isPending}
        suppressContextMenu={false}
        autoSizeStrategy={{ type: 'fitCellContents' }}
        gridOptions={{
          components: {
            openInNewTabCellRenderer: OpenInNewTabCellRenderer
          }
        }}
      />
    </div>
  )
}

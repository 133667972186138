export default function Drawer() {
  return {
    MuiDrawer: {
      defaultProps: {},
      styleOverrides: {
        paper: {}
      }
    }
  }
}

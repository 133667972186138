import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

import { useLatestRef } from 'hooks/useLatestRef'

import { useSetAxisName, useSetInstanceData } from '../hooks'
import useToggleDataLabels from '../hooks/useToggleDataLabels'
import { IModule, IModuleAttributes, IModuleUseInstance, ModuleName } from '../types/module-types'
import { getDefaultPrimaryColor } from '../utils/color-utils'
import { assignRef, getRef } from '../utils/module-utils'

const moduleName = ModuleName.lineSeries
const lineSeriesModule: IModule<ModuleName.lineSeries> = {
  name: moduleName
}

lineSeriesModule.init = (props) => {
  const root: am5.Root = getRef({ ...props, moduleName: ModuleName.root })
  const chart: am5xy.XYChart = getRef({ ...props, moduleName: ModuleName.xyChart })
  const xAxis:
    | am5xy.CategoryAxis<am5xy.AxisRenderer>
    | am5xy.ValueAxis<am5xy.AxisRenderer>
    | am5xy.DateAxis<am5xy.AxisRenderer> = getRef({
    ...props,
    moduleName: [ModuleName.categoryXAxis, ModuleName.valueXAxis, ModuleName.dateXAxis]
  })
  const yAxis: am5xy.ValueAxis<am5xy.AxisRenderer> = getRef({
    ...props,
    moduleName: ModuleName.valueYAxis
  })
  const legend: am5.Legend = getRef({ ...props, moduleName: ModuleName.legend })
  const externalLegend: am5.Legend = getRef({ ...props, moduleName: ModuleName.externalLegend })
  const dataProcessor: am5.DataProcessor = getRef({
    ...props,
    moduleName: ModuleName.dataProcessor
  })

  const {
    options: { yAxisKey = 'category', xAxisKey, area = false, name = '', stacked = false, color }
  } = props
  const categoryXAxis = xAxis instanceof am5xy.CategoryAxis

  const series = chart.series.push(
    am5xy.LineSeries.new(root, {
      name,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: yAxisKey,
      [categoryXAxis ? 'categoryXField' : 'valueXField']: xAxisKey,
      fill: am5.color(color || getDefaultPrimaryColor()),
      stroke: am5.color(color || getDefaultPrimaryColor()),
      stacked
    })
  )

  series.bullets.push(function (root) {
    return am5.Bullet.new(root, {
      sprite: am5.Circle.new(root, {
        radius: 4,
        fill: series.get('fill')
      })
    })
  })

  if (dataProcessor) {
    series.data.processor = dataProcessor
  }

  if (area) {
    series.fills.template.setAll({
      fillOpacity: 0.5,
      visible: true
    })
  }

  if (legend) {
    legend.data.push(series)
  } else if (externalLegend) {
    externalLegend.data.push(series)
  }
  assignRef({ ...props, moduleName, item: series })
}

const useInstance: IModuleUseInstance<ModuleName.lineSeries> = (instance, props) => {
  const propsRef = useLatestRef(props)

  const {
    options: { yAxisKey, stacked = false, showDataLabels = false }
  } = props

  useSetInstanceData({ propsRef, moduleName, instance })
  useSetAxisName({ propsRef, moduleName, props })
  useToggleDataLabels({
    propsRef,
    moduleName,
    props,
    axisKey: yAxisKey,
    showDataLabels: !stacked && showDataLabels
  })
}

lineSeriesModule.useInstance = useInstance

export const lineSeriesAttributes: IModuleAttributes<ModuleName.lineSeries> = (p) => ({
  module: lineSeriesModule,
  ...p
})

export default lineSeriesModule

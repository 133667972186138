import { format, isSameDay, parseISO, startOfDay } from 'date-fns'

const ALL_CAPS_WORDS = [
  'id',
  'qa',
  'gl',
  'kpi',
  'url',
  'api',
  'ui',
  'sql',
  'css',
  'ytd',
  'jti',
  'naics'
]

/**
 * Humanizes the given field name (i.e. table name, column name, etc.)
 * ex) "customer_contact_id" -> "Customer Contact ID"
 * ex) "QaCheck" -> "QA Check"
 * ex) "FinancialStatementGlAccountValue" -> "Financial Statement GL Account Value"
 */
export const humanizeFieldName = (columnName: string) => {
  return _.words(columnName)
    .map((word) =>
      ALL_CAPS_WORDS.includes(word.toLowerCase()) ? word.toUpperCase() : _.upperFirst(word)
    )
    .join(' ')
}

const formatDateTime = () => {
  const now = new Date()
  const year = now.getFullYear()
  const month = now.toLocaleString('default', { month: 'short' }).toUpperCase()
  const day = now.getDate()
  const hours = now.getHours()
  const minutes = now.getMinutes()
  const seconds = now.getSeconds()

  return `${year}-${month}-${day}_${hours}_${minutes}_${seconds}`
}

export const capitalizeFirstLetter = (str: string, lowercaseRest = false) =>
  str.charAt(0).toUpperCase() + (lowercaseRest ? str.slice(1).toLowerCase() : str.slice(1))

export const generateExportFileName = (pageName: string, fileType: 'pdf' | 'csv' | 'xlsx' | '') => {
  const fileExtension = fileType ? `.${fileType}` : ''
  const dateTimeString = formatDateTime()
  return `${dateTimeString}_${pageName}${fileExtension}`
}

export const shortUniqueID = (prefix: string = 'comp-', length: number = 10): string => {
  if (length <= prefix.length) {
    length = 5
  }

  const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const randomPortionLength = length
  let randomPortion = ''

  for (let i = 0; i < randomPortionLength; i++) {
    const randomIndex = Math.floor(Math.random() * randomChars.length)
    randomPortion += randomChars.charAt(randomIndex)
  }

  return prefix + randomPortion
}

export const toClassName = (key: string = '') => {
  return `${key
    .split('(')[0]
    .replace(/[^a-zA-Z0-9]+/g, '-')
    .toLowerCase()
    .replace(/^-+|-+$/g, '')}`
}

export const capitalize = (inputStr: string = '') => _.startCase(_.toLower(_.toString(inputStr)))

export const toClearString = (value: string) => {
  return value.replace(/,/g, '')
}

export const toDividedNumber = (amount: number, divide_by: number = 1000): number => {
  if (!amount) {
    return 0
  }

  return amount / divide_by
}

export const toUSDFormat = (
  params: any,
  sign: string = '$',
  divide: boolean = false,
  divide_by: number = 1000
) => {
  let amount = params.value

  if (divide) {
    amount = amount / divide_by
  }

  const numericAmount = parseFloat(amount ? amount : 0.0)
  return currencyFormatter(numericAmount, sign)
}

export const currencyParser = (params: any) => {
  let value = params.newValue
  if (value == null || value === '') {
    return null
  }
  value = String(value)
  if (value.startsWith('$')) {
    value = value.slice(1)
  }
  return parseFloat(value)
}

export const currencyFormatter = (currency: number, sign: string, round: number = 2) => {
  const sansDec = currency.toFixed(round)
  const formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return sign + `${formatted}`
}

export const toCommaSeparated = (value: number): string => {
  if (value === undefined) {
    return ''
  }

  if (!value) {
    return '0'
  }

  return value.toLocaleString()
}

export const stringToCommaSeparated = (value: string | string[]): string => {
  if (Array.isArray(value)) {
    return _.join(
      _.map(value, (item) => _.toString(item).trim()),
      ', '
    )
  } else if (typeof value === 'string') {
    return _.join(_.map(_.split(value, ','), _.trim), ', ')
  }

  return value ?? ''
}

export const shortDate = (date: string | undefined) => {
  if (!date) return ''

  // Parse the date string and set time to start of the day
  const parsedDate = startOfDay(parseISO(date))

  // Get the current date and set time to start of the day
  const today = startOfDay(new Date())

  // Compare dates
  if (isSameDay(parsedDate, today)) return 'Today'
  return format(parsedDate, 'dd-MMM')
}

export const containsOnlyDigitsOrDashes = (str: string) => {
  for (const char of str) {
    if (!((char >= '0' && char <= '9') || char === '-')) {
      return false
    }
  }
  return true
}

export const replaceNewLineWithSpace = (str: string) => {
  return str.replace(/\n/g, ' ')
}

export const replaceSlashWithDash = (str: string) => {
  return str.replace(/\//g, '-')
}

/**
 * Compare two strings in a case-insensitive manner
 * @param valueA First string to compare
 * @param valueB Second string to compare
 * @returns Sort order of the two strings
 */
export const compareStringsCaseInsensitive = (valueA: string, valueB: string): number => {
  return _.lowerCase(valueA).localeCompare(_.lowerCase(valueB))
}

import { Chip, Tooltip } from '@mui/material'
import { Stack } from '@mui/system'

import { UniqueIdentifier } from '@dnd-kit/core'

import { Badge } from '@components/core/badge'
import { CollectionIcon } from '@components/icons/page/collection-icon'
import { DynamicPageIcon } from '@components/icons/page/dynamic-page-icon'
import { PageGroupIcon } from '@components/icons/page/page-group-icon'
import { StaticPageIcon } from '@components/icons/page/static-page-icon'
import LogoIcon from '@components/logo/LogoIcon'
import { SortableTree } from '@components/tree/sortable-tree'
import {
  TreeItem,
  TreeItem as TreeItemType,
  TreeSource,
  TreeSourceEnum
} from '@components/tree/types'

import useAuth from '@hooks/useAuth'

import { PageType } from 'types/page'

import { LAYOUT_TYPE } from '../../data'
import { useCreatePage } from '../../queries/create-page'
import { useDeletePage } from '../../queries/delete-page'
import { useReorderPage } from '../../queries/reorder-page'
import { useUpdatePage } from '../../queries/update-page'
import { LayoutStatus } from '../../types'

import styles from './PageTreePanel.module.css'

export const PagesTreePanel = ({
  isEditMode,
  pages,
  selectedPageId,
  onPageClick
}: {
  isEditMode: boolean
  pages: any[]
  selectedPageId: number
  onPageClick: (id: UniqueIdentifier) => void
}) => {
  const { mutate: createPage } = useCreatePage()
  const { mutate: updatePage } = useUpdatePage()
  const { mutate: reorder } = useReorderPage()
  const { mutate: deletePage } = useDeletePage()

  const { user } = useAuth()

  return (
    <div className='w-full text-clip'>
      <SortableTree
        collapsible
        indicator
        source={TreeSourceEnum.Page}
        sortable={isEditMode}
        editable={isEditMode}
        defaultItems={pages}
        onItemClick={onPageClick}
        persistedCollapsedIdsKey={`pages-hierarchy-${user?.business_id}`}
        onNodeEdit={(nodeId: number, item: TreeItem) =>
          updatePage({ pageId: nodeId, title: item.label })
        }
        treeItemClasses={(item: TreeItemType) => {
          if (!item?.enabled) {
            return styles.TreeItemDisabled
          }

          if (item?.status === LayoutStatus.published) {
            return styles.TreeItemPublished
          }

          if (item?.status === LayoutStatus.draft) {
            return styles.TreeItemDraft
          }

          return ''
        }}
        onNodeCreate={({ parentNodeId, item }: { parentNodeId?: number; item: TreeItem }) =>
          createPage({ parentPageId: parentNodeId, title: item.label, type: item.identifier })
        }
        onNodeDelete={(nodeId: number) => {
          deletePage({ pageNodeId: nodeId })
        }}
        onDragEnd={({
          parentNodeId,
          sortedNodeIds
        }: {
          parentNodeId?: number
          sortedNodeIds: number[]
        }) => reorder({ parentPageId: parentNodeId, sortedNodeIds: sortedNodeIds })}
        selectedNodeId={selectedPageId}
        treeItemSecondaryUtils={(item: TreeItemType, source: TreeSource | undefined) => {
          return (
            <>
              {/*{item?.status === LayoutStatus.published && (*/}
              {/*  <Stack alignItems="center" justifyContent="center" mr={1}>*/}
              {/*    <Chip*/}
              {/*      label="published"*/}
              {/*      color="primary"*/}
              {/*      size="small"*/}
              {/*      variant="outlined"*/}
              {/*      style={{ borderRadius: '12px', marginLeft: '8px' }}*/}
              {/*    />*/}
              {/*  </Stack>*/}
              {/*)}*/}

              {item?.status === LayoutStatus.draft && (
                <Stack alignItems='center' justifyContent='center' mr={1}>
                  <Chip
                    label='draft'
                    color='primary'
                    size='small'
                    variant='outlined'
                    style={{ borderRadius: '12px', marginLeft: '8px' }}
                  />
                </Stack>
              )}

              {item.identifier === PageType.INTERNAL && (
                <Badge variant='outline' className='font-normal'>
                  Internal
                </Badge>
              )}

              {(item?.parent_page_id || user?.business_friendly_name === 'charger') && (
                <Tooltip title='Page is maintained by Charger'>
                  <Stack alignItems='center' justifyContent='center' mr={1}>
                    <LogoIcon style={{ color: 'black', fontSize: '0.75rem' }} />
                  </Stack>
                </Tooltip>
              )}

              {item.identifier === LAYOUT_TYPE.STATIC_PAGE && (
                <Tooltip title='Static Page' arrow>
                  <Stack alignItems='center' justifyContent='center' mr={1}>
                    <StaticPageIcon color={'black'} />
                  </Stack>
                </Tooltip>
              )}

              {item.identifier === LAYOUT_TYPE.PAGE && (
                <>
                  <Tooltip title='Layout Builder Page' arrow>
                    <Stack alignItems='center' justifyContent='center' mr={1}>
                      <DynamicPageIcon color={'black'} />
                    </Stack>
                  </Tooltip>
                </>
              )}

              {item.identifier === LAYOUT_TYPE.PAGE_GROUP && (
                <>
                  <Tooltip title='Page Group' arrow>
                    <Stack alignItems='center' justifyContent='center' mr={1}>
                      <PageGroupIcon color={'black'} />
                    </Stack>
                  </Tooltip>
                </>
              )}

              {item.identifier === LAYOUT_TYPE.COLLECTION && (
                <>
                  <Tooltip title='Collection' arrow>
                    <Stack alignItems='center' justifyContent='center' mr={1}>
                      <CollectionIcon color={'black'} />
                    </Stack>
                  </Tooltip>
                </>
              )}
            </>
          )
        }}
      />
    </div>
  )
}

import { useEffect, useState } from 'react'

import { useSelector } from '@store/index'
import { selectTitle } from '@store/slices/component/basic-config'
import { selectTreemapConfig } from '@store/slices/component/treemap-config'

import ErrorBoundary from 'components/ErrorBoundary'
import { ChartErrorWidget } from 'components/chart/chart-container'
import Button from 'components/core/button'
import { Text } from 'components/core/text'
import useChangeDetection from 'hooks/use-change-detection'

import DynamicTreemap from '../common/dynamic-chart'
import { useQueryResultContext } from '../contexts/query-result-context'
import useTreemapPropsRetriever from './useTreemapPropsRetriever'

function TreemapPreview() {
  const title = useSelector(selectTitle)
  const treemapData = useSelector(selectTreemapConfig)
  const { data, metadata, drilldowns } = useQueryResultContext()
  const chartProps = useTreemapPropsRetriever({ data, metadata, config: treemapData })!
  const [chartKey, triggerChange] = useChangeDetection()
  useEffect(() => triggerChange(), [chartProps, triggerChange])

  return (
    <div className='h-[500px]'>
      <DynamicTreemap key={chartKey} {...chartProps} title={title} drilldowns={drilldowns} />
    </div>
  )
}

const ErrorFallback = ({
  message,
  refreshTreemap,
  title
}: {
  message: string
  refreshTreemap: () => void
  title: string
}) => (
  <div className='h-[500px]'>
    <ChartErrorWidget
      title={title}
      errorMessage={
        <div>
          <Text variant='body'>{message}</Text>
          <Button variant='outline' onClick={refreshTreemap}>
            Refresh Chart
          </Button>
        </div>
      }
    />
  </div>
)

export default function TreemapWrapper() {
  const title = useSelector(selectTitle)
  const treemapData = useSelector(selectTreemapConfig)
  const [hasError, setHasError] = useState(false)
  const refreshTreemap = () => setHasError(false)
  useEffect(() => refreshTreemap(), [treemapData])

  return (
    <>
      <ErrorBoundary
        key={hasError ? 'error' : 'no-error'}
        fallback={(message) => {
          setHasError(true)
          return <ErrorFallback message={message} refreshTreemap={refreshTreemap} title={title} />
        }}
      >
        <TreemapPreview />
      </ErrorBoundary>
    </>
  )
}

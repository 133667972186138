import { Link } from '@mui/material'

import { ICellRendererParams } from 'ag-grid-community'

import { setDimensionFilter } from '@store/slices/gl-transactions-dimension-filters'

import { ExpandedDateFilterInfo } from 'types/filter'

interface IAccountIdCellRendererParams extends ICellRendererParams {
  context:
    | {
        dateFilter?: ExpandedDateFilterInfo
        dispatch: any
        dimensionFilters: any
      }
    | Record<string, any>
}

export const sourceSystemAccountIdCellRenderer = (params: IAccountIdCellRendererParams) => {
  const dateFilter: ExpandedDateFilterInfo = params.context?.dateFilter
  const dimensionsUUID = crypto.randomUUID()
  const dispatch = params.context?.dispatch

  return params.value ? (
    <Link
      target='_blank'
      onClick={(e) => {
        dispatch(
          setDimensionFilter({
            id: dimensionsUUID,
            values: { ...params?.context.dimensionFilters }
          })
        )
        window
          .open(
            buildGlTransactionRedirectUrl(
              dateFilter.startDate || '',
              dateFilter.endDate || '',
              [params.value],
              dimensionsUUID
            ),
            '_blank'
          )
          ?.focus()
      }}
      sx={{ paddingRight: '0.5rem', display: 'block', width: '100%', textAlign: 'right' }}
    >
      {params.value}
    </Link>
  ) : (
    ''
  )
}

export const buildGlTransactionRedirectUrl = (
  startDate: string,
  endDate: string,
  glAccountIds: string[],
  dimensionsUUID?: string
) => {
  const queryString = new URLSearchParams({
    startDate,
    endDate,
    ...(dimensionsUUID ? { dimensionsUUID } : {})
  })

  glAccountIds.forEach((id) => queryString.append('glAccountIds', id))

  return `/gl-transactions?${queryString.toString()}`
}

import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { usePrevious } from '@uidotdev/usehooks'

import { Input } from '@components/core/input'
import { Switch } from '@components/core/switch'
import { Text } from '@components/core/text'

import useDelayedUpdate from '@hooks/use-delayed-update'

import { useDispatch } from '@store/index'
import {
  selectParameterNameByHeaderName,
  updateParameterName
} from '@store/slices/component/query-config'

export function ParameterizedFieldConfig({
  headerName,
  inline = false
}: {
  headerName: string
  inline?: boolean
}) {
  const dispatch = useDispatch()

  const selectParameterNameByHeaderNameMemoized = useMemo(
    () => selectParameterNameByHeaderName(headerName),
    [headerName]
  )
  const parameterName = useSelector(selectParameterNameByHeaderNameMemoized)

  const [parameterized, setParameterized] = useState(!!parameterName)
  const previouslyParameterized = usePrevious(parameterized)

  const [paramterName, setParameterName] = useDelayedUpdate(
    parameterName || '',
    (parameterName) => dispatch(updateParameterName({ parameterName, headerName })),
    500
  )

  useEffect(() => {
    if (previouslyParameterized && !parameterized) {
      setParameterName('')
    }
  }, [previouslyParameterized, parameterized, setParameterName])

  if (inline) {
    return (
      <>
        <div className='flex items-center justify-between gap-1'>
          <Text variant='submenu' weight='semibold' className='text-grey-dark'>
            Parameterized
          </Text>
          <Switch checked={parameterized} onCheckedChange={(value) => setParameterized(value)} />
        </div>
        {parameterized && (
          <Input
            placeholder='Enter Parameter Name'
            value={paramterName}
            onChange={(e) => setParameterName(e.target.value)}
          />
        )}
      </>
    )
  } else {
    return (
      <>
        <div className='mb-2 flex items-center justify-between'>
          <Text variant='submenu' weight='semibold' className='text-grey-dark'>
            Parameterized
          </Text>
          <Switch checked={parameterized} onCheckedChange={(value) => setParameterized(value)} />
        </div>
        {parameterized && (
          <Input
            placeholder='Enter Parameter Name'
            value={paramterName}
            onChange={(e) => setParameterName(e.target.value)}
          />
        )}
      </>
    )
  }
}

// import PageLocked from '../../../assets/images/layout-builder/2-page_static.png';
// import PageLocked from '../../../assets/images/layout-builder/page_locked_2.png';
// import PageLocked from '../../../assets/images/layout-builder/1-page_locked.png';
// import PageUnLocked from '../../../assets/images/layout-builder/page_unlocked_2.png';
// import PageUnLocked from '../../../assets/images/layout-builder/1-page_unlocked.png';
import { GridOffOutlined } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

export const StaticPageIcon = (props: any) => {
  const theme = useTheme()
  return (
    <GridOffOutlined
      style={{ fontSize: '1.2rem', color: props.color ? props.color : theme.palette.primary.main }}
    />
  )
  // return <img src={PageLocked} alt="PageLocked" width="25px" height="25px" />;
}
//
// <Chip
//   label="Static"
//   color="primary"
//   size="small"
//   variant="outlined"
//   style={{ borderRadius: '12px', marginLeft: '8px' }}
// />

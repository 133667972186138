import React from 'react'

import { cn } from '@utils/style-utils'

export function PreviousPeriodConnection({
  children,
  hidden = false
}: {
  children: React.ReactNode
  hidden?: boolean
}) {
  return (
    <div className='relative mt-2'>
      <div
        className={cn('absolute -top-2 bottom-1/2 left-2 w-px bg-grey', hidden && 'opacity-50')}
      />
      <div className={cn('absolute left-2 top-1/2 h-px w-3 bg-grey', hidden && 'opacity-50')} />
      {children}
    </div>
  )
}

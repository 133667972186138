import { useMemo } from 'react'

import { ColDef } from 'ag-grid-community'

import AgGrid from '@components/ag-grid'

const transformPendingStreamsData = (pendingStreams: string[]) => {
  return pendingStreams.map((stream) => ({ streamName: stream }))
}

const PendingStreams = ({
  streamStatusesId,
  streamStatusesPendingStreams,
  gridWidth = '10%'
}: {
  streamStatusesId?: string | null
  streamStatusesPendingStreams: string
  gridWidth: string
}) => {
  const pendingStreamsColumnDefs: ColDef[] = [
    {
      field: 'streamName',
      headerName: `Pending Streams ${streamStatusesId ? `(${streamStatusesId})` : ''}`
    }
  ]

  const rowData = useMemo(
    () => transformPendingStreamsData(JSON.parse(streamStatusesPendingStreams)),
    [streamStatusesPendingStreams]
  )

  return (
    <AgGrid
      className='ml-3'
      style={{ height: '100%', width: gridWidth }}
      rowData={rowData}
      columnDefs={pendingStreamsColumnDefs}
      defaultColDef={{
        resizable: true,
        sortable: true,
        filter: true
      }}
      autoSizeStrategy={{ type: 'fitCellContents' }}
    />
  )
}

export default PendingStreams

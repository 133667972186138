import ReorderIcon from '@mui/icons-material/Reorder'
import { Box, Divider, Grid, Typography } from '@mui/material'

import Button from '@components/core/button'
import Tooltip from '@components/core/tooltip'
import { DataLabelVisibility, DataLabelVisibilityOff, Icon } from '@components/icons'

import { usePageDispatch, usePageSelector } from '@store/index'

import { selectShowDataLabels, toggleShowDataLabels } from 'store/slices/action-bar'

import { BaseFilterProps, FilterDisplayModeEnum, isFilterDisplayModeList } from './types'

export default function DataLabelsToggle({
  filter,
  filterDisplayMode = FilterDisplayModeEnum.default
}: BaseFilterProps) {
  const showDataLabels = usePageSelector(selectShowDataLabels)
  const pageDispatch = usePageDispatch()
  const label = _.get(filter, 'label', 'Toggle Data Labels')

  if (isFilterDisplayModeList(filterDisplayMode)) {
    return (
      <>
        <Box sx={{ px: '16px' }}>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Typography variant='subText'>{label}</Typography>
            <ReorderIcon />
          </Grid>
          <Divider sx={{ my: '4px' }} />

          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            sx={{ padding: 0, margin: 0, my: '8px' }}
          >
            <Typography variant='subtitle1'>Show Data Labels</Typography>
            <Button
              variant='ghost'
              size='action-bar'
              onClick={() => pageDispatch(toggleShowDataLabels({}))}
            >
              <Icon icon={showDataLabels ? <DataLabelVisibility /> : <DataLabelVisibilityOff />} />
            </Button>
          </Grid>
        </Box>
      </>
    )
  } else {
    return (
      <Tooltip title={label}>
        <Button
          variant='ghost'
          size='action-bar'
          onClick={() => pageDispatch(toggleShowDataLabels({}))}
          className='m-1'
        >
          <Icon icon={showDataLabels ? <DataLabelVisibility /> : <DataLabelVisibilityOff />} />
        </Button>
      </Tooltip>
    )
  }
}

import { ReactNode } from 'react'

import type { ColDef } from 'ag-grid-community'

import { cn } from '@utils/style-utils'

import { Text } from '@core/text'

import {
  ABC,
  Calendar,
  Cancel,
  FormulaF,
  FormulaFx,
  Hash,
  HeadingH,
  Icon,
  TrueFalse
} from '@components/icons'
import KeyIcon from '@components/icons/key.svg?react'

import {
  IModelFieldDataType,
  isDataTypeBoolean,
  isDataTypeDate,
  isDataTypeKpiFormula,
  isDataTypeKpiHeading,
  isDataTypeString
} from 'pages/component-management/types/query-builder-types'

export interface DataPillProps {
  label: string | ReactNode
  dataType?: IModelFieldDataType | ColDef['cellDataType'] // for displaying matching icon for the type
  variant?: 'outline' | 'primary'
  isForeignKey?: boolean // adds green background and an extra key icon
  isId?: boolean // adds yellow background
  isCustomField?: boolean // adds an extra custom field icon
  selected?: boolean
  onClick?: () => void // makes the pill clickable
  onDelete?: () => void // adds a delete button (x) to the right of the pill
  lightIcon?: boolean
  highlight?: boolean
  disabled?: boolean
  className?: string
}

export function DataPill(props: DataPillProps) {
  const {
    label,
    dataType,
    variant = 'outline',
    isForeignKey = false,
    isId = false,
    isCustomField = false,
    selected = false,
    onClick,
    onDelete,
    lightIcon = false,
    highlight = false,
    disabled = false,
    className
  } = props

  const primary = variant === 'primary'
  const outline = variant === 'outline'

  const icons = []
  if (dataType) icons.push(getIconForDataType(dataType))
  if (isForeignKey) icons.push(<KeyIcon />)
  if (isCustomField) icons.push(<FormulaFx />)

  return (
    <div
      className={cn(
        'group inline-flex cursor-pointer items-center rounded bg-white px-2.5 py-1',
        outline && `border border-solid border-grey-light text-primary-darker`,
        !disabled && outline && `hover:border-grey-dark hover:bg-grey-lightest`,
        selected && !primary && `border border-grey-darker bg-grey-lighter`,
        primary && `border-none border-primary-darker bg-primary text-white`,
        isForeignKey && '!bg-green-light25',
        isId && '!bg-warning-lighter',
        highlight && 'border border-warning ring-1 ring-inset ring-warning',
        disabled && 'cursor-default select-none opacity-20',
        className
      )}
      onClick={onClick && !disabled ? onClick : _.noop}
    >
      {/* icon(s) */}
      {icons.map((icon, i) => {
        return (
          <div
            key={i}
            className={cn(
              '-my-0.5 -ml-1 mr-0.5 flex items-center text-primary-darker',
              lightIcon && !selected && 'text-grey-light',
              lightIcon && !disabled && 'group-hover:text-primary-darker',
              primary && 'text-white'
            )}
          >
            <Icon icon={icon} />
          </div>
        )
      })}

      {/* main content */}
      <Text
        variant='button'
        weight='semibold'
        className={cn(primary && 'text-white', outline && 'text-primary-darker')}
      >
        {label}
      </Text>

      {/* deletable button */}
      {onDelete && (
        <div
          className={cn(
            '-my-0.5 -mr-2 ml-1 inline-flex text-grey',
            !disabled && 'hover:text-grey-dark'
          )}
        >
          <Icon
            icon={<Cancel />}
            onClick={(e) => {
              if (disabled) return
              e.stopPropagation() // prevents click event on the pill itself (the parent div)
              onDelete()
            }}
          />
        </div>
      )}
    </div>
  )
}

export const getIconForDataType = (dataType: IModelFieldDataType | ColDef['cellDataType']) => {
  if (!dataType) return null
  if (isDataTypeDate(dataType)) return <Calendar />
  if (isDataTypeString(dataType)) return <ABC />
  if (isDataTypeBoolean(dataType)) return <TrueFalse />
  if (isDataTypeKpiHeading(dataType)) return <HeadingH />
  if (isDataTypeKpiFormula(dataType)) return <FormulaF />
  return <Hash />
}

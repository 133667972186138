import { Fragment, useEffect, useState } from 'react'

import { humanizeFieldName } from '@utils/string-utils'

import { DataPill } from '@components/component-management/DataPill'
import { Separator } from '@components/core/separator'
import { Text } from '@components/core/text'

import { useSelector } from '@store/index'
import { selectPending } from '@store/slices/component/basic-config'
import { selectFlipAxis, selectXAxes, selectYAxes } from '@store/slices/component/chart-config'

import { Aggregation } from '../../chart-visualize/aggregation'
import { useQueryResultContext } from '../../contexts/query-result-context'
import { IAxis } from '../../types/chart-builder-types'
import { IHeader } from '../../types/component-types'
import { PanelSkeleton } from '../panel-skeleton'

export default function ChartPanelBody() {
  const { headers, isFetching } = useQueryResultContext()
  const isPendingComponentData = useSelector(selectPending)
  const xAxes = useSelector(selectXAxes)
  const yAxes = useSelector(selectYAxes)
  const flipAxis = useSelector(selectFlipAxis)

  const [cachedHeaders, setCachedHeaders] = useState(headers)

  useEffect(() => {
    if (isFetching) return

    setCachedHeaders(headers)
  }, [headers, isFetching])

  const breakdowns = _.filter(cachedHeaders, (header) => _.some(yAxes, { breakdown: header.name }))

  return (
    <div>
      {isPendingComponentData ? (
        <PanelSkeleton />
      ) : (
        <div className='flex flex-col gap-2'>
          {_.map(yAxes, (axis) => {
            const header = cachedHeaders.find((h) => h.name === axis.name)

            return (
              <Fragment key={axis.name}>
                <div className='flex items-center justify-between'>
                  <DataPill
                    label={humanizeFieldName(axis.name || '')}
                    dataType={header?.data_type}
                  />
                  <div className='flex items-center gap-2'>
                    <Text variant='button' weight='semibold'>
                      {flipAxis ? 'X-Axis' : axis.seriesType}
                    </Text>
                    <Aggregation item={axis} className='w-20' />
                  </div>
                </div>
                <Separator />
              </Fragment>
            )
          })}
          {_.map(breakdowns, (header) => {
            return (
              <Fragment key={header.name}>
                <div className='flex items-center justify-between'>
                  <DataPill label={humanizeFieldName(header.name)} dataType={header?.data_type} />
                  <div className='flex items-center gap-2'>
                    <Text variant='button' weight='semibold'>
                      Breakdown
                    </Text>
                  </div>
                </div>
                <Separator />
              </Fragment>
            )
          })}
          {_.map(xAxes, (axis) => {
            const header = cachedHeaders.find((h) => h.name === axis.name)

            return (
              <Fragment key={axis.name}>
                <div className='flex items-center justify-between'>
                  <DataPill
                    label={humanizeFieldName(axis.name || '')}
                    dataType={header?.data_type}
                  />
                  <div className='flex items-center gap-2'>
                    <Text variant='button' weight='semibold'>
                      {flipAxis ? 'Y-Axis' : 'X-Axis'}
                    </Text>
                  </div>
                </div>
                <Separator />
              </Fragment>
            )
          })}
          {_.differenceBy(
            cachedHeaders,
            _.concat<IAxis | IHeader>(yAxes, xAxes, breakdowns),
            'name'
          ).map((header) => (
            <Fragment key={header.name}>
              <div className='flex items-center justify-between'>
                <DataPill
                  label={humanizeFieldName(header.name)}
                  dataType={header.data_type}
                  disabled
                />
              </div>
              <Separator />
            </Fragment>
          ))}
        </div>
      )}
    </div>
  )
}

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

import { useLatestRef } from 'hooks/useLatestRef'

import { useSetGridVisibility } from '../hooks'
import { IModule, IModuleAttributes, IModuleUseInstance, ModuleName } from '../types/module-types'
import { assignRef, getRef } from '../utils/module-utils'
import { titlePositionAdjustment } from '../utils/title-position-adjustment'

const moduleName = ModuleName.valueYAxis

const valueYAxisModule: IModule<ModuleName.valueYAxis> = {
  name: moduleName
}

valueYAxisModule.init = (props) => {
  const root: am5.Root = getRef({ ...props, moduleName: ModuleName.root })
  const chart: am5xy.XYChart = getRef({ ...props, moduleName: ModuleName.xyChart })
  const headingContainer: am5.Container = getRef({
    ...props,
    moduleName: ModuleName.headingContainer
  })

  const {
    options: {
      numberFormat = '#a',
      yAxisLabel = '',
      opposite = false,
      min = null,
      max = null,
      marginTop = 0
    }
  } = props

  const valueAxisRenderer = am5xy.AxisRendererY.new(root, { opposite, minGridDistance: 25 })
  valueAxisRenderer.labels.template.setAll({
    fontSize: '11px'
  })
  valueAxisRenderer.grid.template.set('strokeDasharray', [3])

  const minAndMaxBothProvided = min !== null && max !== null

  const yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      baseValue: 0,
      maxDeviation: 0,
      renderer: valueAxisRenderer,
      numberFormat,
      marginTop,
      ...(min !== null ? { min } : {}),
      ...(max !== null ? { max } : { extraMax: 0.1 }),
      ...(minAndMaxBothProvided ? { strictMinMax: true } : {})
    })
  )

  if (yAxisLabel) {
    const label = am5.Label.new(root, {
      rotation: -90,
      text: yAxisLabel,
      y: am5.p50,
      centerX: am5.p50
    })

    yAxis.children.unshift(label)
  }

  if (min === null) {
    yAxis.onPrivate('selectionMinFinal', function (selectionMinFinal) {
      if (selectionMinFinal && !isNaN(selectionMinFinal)) {
        // When minimum value is 0, selectionMinFinal is equal to -1
        if (selectionMinFinal >= -1) yAxis.set('min', 0)
      }
    })
  }

  if (!opposite && headingContainer) {
    titlePositionAdjustment({ headingContainer, yAxis, chart })
  }

  assignRef({ ...props, moduleName, item: yAxis })
}

const useInstance: IModuleUseInstance<ModuleName.valueYAxis> = (instance, props) => {
  const propsRef = useLatestRef(props)

  useSetGridVisibility({ moduleName, propsRef, props })
}

valueYAxisModule.useInstance = useInstance

export const valueYAxisAttributes: IModuleAttributes<ModuleName.valueYAxis> = (p) => ({
  module: valueYAxisModule,
  ...p
})

export default valueYAxisModule

import * as am5xy from '@amcharts/amcharts5/xy'

import { IModule, IModuleAttributes, ModuleName } from '../types/module-types'
import { assignRef, getRef } from '../utils/module-utils'

const moduleName = ModuleName.cursor
const cursorModule: IModule<ModuleName.cursor> = {
  name: moduleName
}

cursorModule.init = (props) => {
  const root = getRef({ ...props, moduleName: ModuleName.root })
  const chart: am5xy.XYChart = getRef({ ...props, moduleName: ModuleName.xyChart })
  const xAxis:
    | am5xy.CategoryAxis<am5xy.AxisRenderer>
    | am5xy.ValueAxis<am5xy.AxisRenderer>
    | am5xy.DateAxis<am5xy.AxisRenderer> = getRef({
    ...props,
    moduleName: [ModuleName.categoryXAxis, ModuleName.valueXAxis, ModuleName.dateXAxis]
  })
  const yAxis: am5xy.CategoryAxis<am5xy.AxisRenderer> | am5xy.ValueAxis<am5xy.AxisRenderer> =
    getRef({
      ...props,
      moduleName: [ModuleName.valueYAxis, ModuleName.categoryYAxis, ModuleName.dateYAxis]
    })

  const {
    options: { flippedAxis = false }
  } = props

  const cursor = chart.set(
    'cursor',
    am5xy.XYCursor.new(root, {
      xAxis: xAxis,
      yAxis: yAxis
    })
  )

  if (flippedAxis) {
    cursor.lineX.set('visible', false)
  } else {
    cursor.lineY.set('visible', false)
  }

  assignRef({ ...props, moduleName, item: cursor })
}

export const cursorAttributes: IModuleAttributes<ModuleName.cursor> = (p) => ({
  module: cursorModule,
  ...p
})

export default cursorModule

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

import { useLatestRef } from 'hooks/useLatestRef'

import { useSetGridVisibility, useSetInstanceData } from '../hooks'
import { IModule, IModuleAttributes, IModuleUseInstance, ModuleName } from '../types/module-types'
import { assignRef, getRef } from '../utils/module-utils'

const moduleName = ModuleName.valueXAxis

const valueXAxisModule: IModule<ModuleName.valueXAxis> = {
  name: moduleName
}

valueXAxisModule.init = (props) => {
  const root: am5.Root = getRef({ ...props, moduleName: ModuleName.root })
  const chart: am5xy.XYChart = getRef({ ...props, moduleName: ModuleName.xyChart })

  const {
    options: {
      numberFormat = '#a',
      xAxisLabel = '',
      opposite = false,
      min = null,
      max = null,
      marginRight = 0
    }
  } = props

  const xRenderer = am5xy.AxisRendererX.new(root, { opposite, minGridDistance: 60 })
  xRenderer.labels.template.setAll({
    fontSize: '11px'
  })
  xRenderer.grid.template.set('strokeDasharray', [3])

  const xAxis = chart.xAxes.push(
    am5xy.ValueAxis.new(root, {
      baseValue: 0,
      maxDeviation: 0,
      renderer: xRenderer,
      numberFormat,
      strictMinMax: true,
      ...(min !== null ? { min } : {}),
      ...(max !== null ? { max } : { extraMax: 0.1 }),
      marginRight
    })
  )
  if (xAxisLabel) {
    const label = am5.Label.new(root, {
      rotation: -90,
      text: xAxisLabel,
      y: am5.p50,
      centerX: am5.p50
    })

    xAxis.children.unshift(label)
  }

  if (min === null) {
    xAxis.onPrivate('selectionMinFinal', function (selectionMinFinal) {
      if (selectionMinFinal && !isNaN(selectionMinFinal)) {
        // When minimum value is 0, selectionMinFinal is equal to -1
        if (selectionMinFinal >= -1) xAxis.set('min', 0)
      }
    })
  }

  assignRef({ ...props, moduleName, item: xAxis })
}

const useInstance: IModuleUseInstance<ModuleName.valueXAxis> = (instance, props) => {
  const propsRef = useLatestRef(props)

  useSetInstanceData({ instance, moduleName, propsRef })
  useSetGridVisibility({ moduleName, propsRef, props })
}

valueXAxisModule.useInstance = useInstance

export const valueXAxisAttributes: IModuleAttributes<ModuleName.valueXAxis> = (p) => ({
  module: valueXAxisModule,
  ...p
})

export default valueXAxisModule

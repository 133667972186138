import { useMemo } from 'react'

import {
  glTransactionsFilterKeys,
  selectedDimensionKeyFromDimensionType
} from '@utils/dimension-utils'

import {
  DimensionFilterType,
  Dimensions,
  SelectedDimensions
} from '@components/control-panel/types'

export const useGlTransactionDimensionFilterPayload = (
  dimensionFilters: SelectedDimensions,
  dimensions: Dimensions | undefined
) => {
  return useMemo(() => {
    const payload: Record<string, any> = {}

    Object.keys(dimensions || {}).forEach((key) => {
      const filterKey = selectedDimensionKeyFromDimensionType(key as DimensionFilterType)

      const payloadKey = glTransactionsFilterKeys[key as DimensionFilterType]
      const selectedDimensionIds = dimensionFilters[filterKey] || []

      const selectedItems = selectedDimensionIds?.map((id: string) => {
        return (dimensions as Record<string, Record<string, string>>)?.[key]?.[id] ?? ''
      })

      if (selectedItems.length === 0) return

      payload[payloadKey] = {
        filterType: 'text',
        selectedItems: selectedItems
      }
    })

    return payload
  }, [dimensionFilters, dimensions])
}

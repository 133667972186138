import { Description } from './description'
import GenreDropdown from './genre-dropdown'
import { Title } from './title'

export const GenreDropdownWithLabel = () => (
  <div className='my-2 flex items-center gap-4'>
    <div className='flex-1'>
      <Title>Component Type And Data</Title>
      <Description>
        Select which type of component you want to build. Adjusting this will remove all settings
        you have associated to your current component.
      </Description>
    </div>
    <GenreDropdown className='w-24' />
  </div>
)

import { useState } from 'react'
import ReactJson from 'react-json-view'

import { Grid, Typography } from '@mui/material'

import ErrorBoundary from 'components/ErrorBoundary'
import FilterPanel from 'components/control-panel/filter-panel'
import { orderedFilterOptions } from 'types/page-filters'

interface FilterInputProps {
  // layoutStructure?: any[];
  defaultFilters?: {}
  onUpdateFilters: any
}

const FilterInput = ({ defaultFilters = {}, onUpdateFilters }: FilterInputProps) => {
  const predefinedFilters = Object.keys(orderedFilterOptions)

  const [searchFiltersInput, setSearchFiltersInput] = useState(() =>
    JSON.stringify(defaultFilters, null, 2)
  )

  const handleAddFilter = (filterName: string) => {
    const defaultValue = orderedFilterOptions[filterName]

    const updatedFilters = {
      ...JSON.parse(searchFiltersInput),
      [filterName]: Array.isArray(defaultValue) ? [...defaultValue] : defaultValue
    }

    updateFilterInput(updatedFilters)
  }

  const filterInputs = JSON.parse(searchFiltersInput)

  const updateFilterInput = (newInputData: any) => {
    setSearchFiltersInput(JSON.stringify(newInputData, null, 2))
    onUpdateFilters(newInputData)
  }

  return (
    <Grid className='filter-settings-container' container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6' gutterBottom>
          Filter Preview
        </Typography>
        <div className='filter-inputs-preview'>
          <ErrorBoundary fallback={<div>Filter loading...</div>}>
            <FilterPanel searchFilters={filterInputs} />
          </ErrorBoundary>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div>Available Filters (Click to Add)</div>
        <ul>
          {predefinedFilters.map((filter) => (
            <li className='filter-config' key={filter} onClick={() => handleAddFilter(filter)}>
              {filter}
            </li>
          ))}
        </ul>
      </Grid>
      <Grid item xs={6}>
        <div>Active Filter Settings</div>
        <ReactJson
          src={filterInputs}
          theme='rjv-default'
          onEdit={({ updated_src }) => updateFilterInput(updated_src)}
          onAdd={({ updated_src }) => updateFilterInput(updated_src)}
          onDelete={({ updated_src }) => updateFilterInput(updated_src)}
        />
      </Grid>
    </Grid>
  )
}

export default FilterInput

import './kpi.css'

interface KpiProps {
  layout_item: any
  widget: any
  item_index: number
}

const Index = ({ widget }: KpiProps) => {
  const { label, value, unit } = widget

  return (
    <div className='kpi-container'>
      <div className='kpi-label'>{label}</div>
      <div className='kpi-value'>{value}</div>
      <div className='kpi-unit'>{unit}</div>
    </div>
  )
}

export default Index

import { useMemo } from 'react'

import { useSelector } from '@store/index'
import { selectColumns } from '@store/slices/component/table-config'

import { SettingsRow, SettingsTable } from '../common/settings-table'
import { Broadcast } from './styling/broadcast'
import { Heatmap } from './styling/heatmap'
import { Pinning } from './styling/pinning'

export const TableStyling = () => {
  const columns = useSelector(selectColumns)

  const visibleColumns = useMemo(() => _.filter(columns, (column) => !column.hide), [columns])

  return (
    <SettingsTable
      data={visibleColumns}
      titleColumnLabel='Table Formatting'
      renderHeaderName={(item) => item.field}
      idKey='field'
    >
      {(rowProps) => (
        <>
          <SettingsRow {...rowProps} title='Pinning' renderField={Pinning} />
          <SettingsRow {...rowProps} title='Broadcast' renderField={Broadcast} />
          <SettingsRow {...rowProps} title='Heatmap' renderField={Heatmap} />
        </>
      )}
    </SettingsTable>
  )
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import { queryErrorToast, querySuccessToast } from 'queries/query-toasts'
import axiosServices from 'utils/axios'

export const useUpdateHierarchyNodeTags = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ nodeId, tags }: { nodeId: number; tags: Record<string, any> }) => {
      return axiosServices.post(
        `/businesses/${user?.business_id}/general_ledger_account_hierarchy_nodes/${nodeId}/update_tags`,
        {
          general_ledger_account_hierarchy_node: { tags }
        }
      )
    },
    onSuccess: (data, variables, context) => {
      querySuccessToast('Node Tags Updated Successfully')
      queryClient.invalidateQueries({
        queryKey: ['fetch-hierarchy-builder-nodes', user?.business_id]
      })
    },
    onError: (error, variables) => {
      queryErrorToast(`Error Tagging Hierarchy Node. \n ${error?.message}`)
    }
  })
}

import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'

import axiosServices from '@utils/axios'
import { camelCaseKeys } from '@utils/case-conversion'

import { EventHandlerStatus, IEventHandlerResponse } from '../types'

export interface ICheckConnectionButtonProps {
  businessId: number | undefined
  cssId: number | undefined
  eventKey: string
}

export const useCheckConnection = ({
  businessId,
  cssId,
  eventKey
}: ICheckConnectionButtonProps) => {
  const MAX_RETRY_DURATION = 150 * 1000 // 150 seconds in milliseconds
  const POLL_INTERVAL = 10000 // 10 seconds
  const maxRetries = Math.floor(MAX_RETRY_DURATION / POLL_INTERVAL)
  const [startTime, setStartTime] = useState<number | null>(null)

  const [attemptExceeded, setAttemptExceeded] = useState(false)

  const query = useQuery({
    queryKey: ['check-status', eventKey, cssId],
    queryFn: async () => {
      try {
        const response = await axiosServices.get(
          `/businesses/${businessId}/events/check_status?event_key=${eventKey}`
        )
        return camelCaseKeys(response.data)
      } catch (e) {
        const error = camelCaseKeys(e) as IEventHandlerResponse
        if (error && error.status && error.cachedData) {
          return error
        }
        throw new Error('An unexpected error occurred')
      }
    },
    enabled: !!eventKey, // Only run if eventKey is available
    refetchInterval: (data: any) => {
      if (startTime === null) return false

      const currentTime = Date.now()
      const elapsedTime = currentTime - startTime

      if (elapsedTime >= MAX_RETRY_DURATION) {
        setAttemptExceeded(true)
        return false
      }

      // Continue polling until status is 'failed' or 'completed'
      if (
        data &&
        data.cachedData &&
        [EventHandlerStatus.Failed, EventHandlerStatus.Completed].includes(data.cachedData.status)
      ) {
        return false
      }
      return POLL_INTERVAL
    },
    refetchOnWindowFocus: false,
    retry: maxRetries,
    retryDelay: (attemptIndex) => {
      return POLL_INTERVAL
    }
  })

  const resetTracking = () => {
    setAttemptExceeded(false)
    setStartTime(Date.now())
  }

  return {
    ...query,
    attemptExceeded,
    resetTracking
  }
}

// store/slices/grid-state.ts
import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { ColumnState, FilterModel } from 'ag-grid-community'

import { WithID } from '@store/page-util'

import { HANDLE_BUSINESS_SELECTOR_CHANGE, LOGIN } from 'store/reducers/actions'
import { AuthActionProps } from 'types/auth'

import { rehydrateStore } from '../actions/rehydrate-store'
import { RootState } from '../index'

interface GridState {
  columnState?: ColumnState[] // Ensure this is ColumnState[]
  filterState?: FilterModel
}

const gridStateAdapter = createEntityAdapter<WithID<GridState>>()

const initialState = gridStateAdapter.getInitialState<{ default: GridState }>({
  default: {}
})

const findOrCreateEntity = (state: typeof initialState, id: string) => {
  if (!state.entities[id]) {
    gridStateAdapter.addOne(state, _.assign({ id }, _.cloneDeep(state.default)))
  }
}

const gridStateSlice = createSlice({
  name: '@GRID_STATE',
  initialState,
  reducers: {
    setGridState: (state, action: PayloadAction<WithID<GridState>>) => {
      const { id, columnState, filterState } = action.payload
      findOrCreateEntity(state, id)

      const changes: Partial<GridState> = {}

      if (!_.eq(columnState, undefined)) {
        changes.columnState = columnState
      }

      if (!_.eq(filterState, undefined)) {
        changes.filterState = filterState
      }

      gridStateAdapter.updateOne(state, {
        id,
        changes
      })
    },
    clearGridState: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload
      gridStateAdapter.removeOne(state, id)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrateStore, (_state, action) => {
      return { ...initialState, ...action.payload.gridState }
    })
    builder.addMatcher(
      (action) => [LOGIN, HANDLE_BUSINESS_SELECTOR_CHANGE].includes(action.type),
      (state, _action: AuthActionProps) => {
        state.ids.forEach((id) => {
          gridStateAdapter.removeOne(state, id as string)
        })
      }
    )
  }
})

// Actions
export const { setGridState, clearGridState } = gridStateSlice.actions

// Reducer
export const gridStateReducer = gridStateSlice.reducer

// Selectors
export const selectGridState = (id: string) => (state: RootState) =>
  _.get(state, ['gridState', 'entities', id], state.gridState.default)

// Selectors
export const selectExpandedGroups = (id: string) => (state: RootState) =>
  _.get(state, ['expandedGroups', 'entities', id], state.expandedGroups.default.expandedGroups)

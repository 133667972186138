import { useEffect, useState } from 'react'

import { Label } from '@core/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@core/select'
import { Text } from '@core/text'

import { useSelector } from '@store/index'
import { selectTitle } from '@store/slices/component/basic-config'
import { selectChartConfig } from '@store/slices/component/chart-config'

import ErrorBoundary from 'components/ErrorBoundary'
import { ChartErrorWidget, ChartLoadingWidget } from 'components/chart/chart-container'
import useChangeDetection from 'hooks/use-change-detection'

import DynamicChart from '../common/dynamic-chart'
import { useQueryTransformedResultContext } from '../contexts/query-transformed-result-context'
import useChartPropsRetriever from './useChartPropsRetriever'

const MIN_WIDTH = '480px'
const MAX_WIDTH = '720px'
const FULL_WIDTH = '100%'
const MIN_HEIGHT = '270px'
const MAX_HEIGHT = '510px'

function ChartPreview({ width, height }: { width: string; height: string }) {
  const title = useSelector(selectTitle)
  const chartConfig = useSelector(selectChartConfig)
  const { data, metadata, drilldowns, isFetching, errorMessage } =
    useQueryTransformedResultContext()
  const [chartProps, chartError] = useChartPropsRetriever({ data, metadata, config: chartConfig })!
  const [chartKey, triggerChange] = useChangeDetection()
  useEffect(() => triggerChange(), [chartProps, triggerChange])

  return (
    <div className='max-w-full rounded-md shadow-brand' style={{ width, height }}>
      {errorMessage || chartError ? (
        <ErrorFallback title={title} message={errorMessage || chartError} />
      ) : isFetching || !chartProps ? (
        <ChartLoadingWidget title={title} />
      ) : (
        <DynamicChart key={chartKey} {...chartProps} title={title} drilldowns={drilldowns} />
      )}
    </div>
  )
}

const ErrorFallback = ({
  message = 'Error creating chart',
  title
}: {
  message?: string | null
  title: string
}) => {
  return (
    <div className='size-full'>
      <ChartErrorWidget
        title={title}
        errorMessage={
          <div>
            <Text variant='body'>{message}</Text>
          </div>
        }
      />
    </div>
  )
}

export default function ChartWrapper() {
  const title = useSelector(selectTitle)
  const [width, setWidth] = useState(MAX_WIDTH)
  const [height, setHeight] = useState(MIN_HEIGHT)

  return (
    <div className='w-full space-y-4 pt-4'>
      <div className='flex justify-center space-x-4'>
        <div className='flex flex-col items-center'>
          <Label htmlFor='simple-select' className='font-semibold'>
            Width
          </Label>
          <Select value={width} onValueChange={(width) => setWidth(width)}>
            <SelectTrigger className='w-[90px]'>
              <SelectValue placeholder='Width' />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={MAX_WIDTH}>MAX</SelectItem>
              <SelectItem value={MIN_WIDTH}>MIN</SelectItem>
              <SelectItem value={FULL_WIDTH}>{FULL_WIDTH}</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className='flex flex-col items-center'>
          <Label htmlFor='simple-select' className='font-semibold'>
            Height
          </Label>
          <Select value={height} onValueChange={(height) => setHeight(height)}>
            <SelectTrigger className='w-[90px]'>
              <SelectValue placeholder='Height' />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={MAX_HEIGHT}>MAX</SelectItem>
              <SelectItem value={MIN_HEIGHT}>MIN</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className='flex justify-center'>
        <ErrorBoundary
          fallback={(message) => {
            return <ErrorFallback message={message} title={title} />
          }}
        >
          <ChartPreview width={width} height={height} />
        </ErrorBoundary>
      </div>
    </div>
  )
}

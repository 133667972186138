import { ChartWidget, useChartSelector } from 'components/chart/chart-container'
import {
  IGlobalOptions,
  IModuleDefinitions,
  ITriggerReinit
} from 'components/chart/types/module-types'
import useChart from 'components/chart/useChart'
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuLabel,
  ContextMenuTrigger
} from 'components/core/context-menu'
import {
  useActiveDrilldownDimensions,
  useCollapseDrilldownDimension,
  useDrilldownContext,
  useExpandDrilldownDimension,
  useFilteredDrilldownDimensions
} from 'contexts/drilldown-context'
import { isNotEmpty } from 'utils/lodash'
import { humanizeFieldName } from 'utils/string-utils'
import { cn } from 'utils/style-utils'

import { IDrilldowns } from '../types/component-types'
import { IResultRow } from '../types/shared-types'
import { ITreemapData } from '../types/treemap-builder-types'

function DrillDownNavigation() {
  const collapseDrilldownDimension = useCollapseDrilldownDimension()
  const activeDrilldownDimensions = useActiveDrilldownDimensions()

  if (_.lt(_.size(activeDrilldownDimensions), 2)) return null

  return (
    <div className='flex overflow-hidden whitespace-nowrap'>
      {_.map(activeDrilldownDimensions, ({ key, filter }, index) => (
        <div
          key={key}
          className={cn([
            'mr-1 truncate text-[0.75rem]',
            _.lt(index + 1, _.size(activeDrilldownDimensions)) ? 'cursor-pointer' : 'cursor-default'
          ])}
          onClick={() => collapseDrilldownDimension(key)}
        >
          {_.gt(index, 0) && <span className='mr-1'>/</span>}
          {humanizeFieldName(key)}
          {filter && <span className='ml-1'>({filter})</span>}
        </div>
      ))}
    </div>
  )
}

export default function DynamicChart({
  modules,
  options,
  data,
  title,
  refetching = false,
  triggerReinit,
  drilldowns
}: {
  modules: IModuleDefinitions
  options: IGlobalOptions
  data: IResultRow[] | ITreemapData[]
  title: string
  refetching?: boolean
  triggerReinit?: ITriggerReinit
  drilldowns: IDrilldowns
}) {
  const selector = useChartSelector()
  const { contextMenuTriggerRef } = useDrilldownContext()
  const filteredDrilldownDimensions = useFilteredDrilldownDimensions(drilldowns)
  const expandDrilldownDimension = useExpandDrilldownDimension()

  useChart({
    selector,
    data,
    options,
    modules,
    triggerReinit
  })

  return (
    <>
      <ChartWidget
        title={title}
        subtitle={<DrillDownNavigation />}
        refetching={refetching}
        selector={selector}
      />
      {isNotEmpty(filteredDrilldownDimensions) && (
        <ContextMenu>
          <ContextMenuTrigger ref={contextMenuTriggerRef}></ContextMenuTrigger>
          <ContextMenuContent>
            <ContextMenuLabel>Drill down</ContextMenuLabel>
            {_.map(filteredDrilldownDimensions, (drilldownDimension) => (
              <ContextMenuItem
                key={drilldownDimension.value}
                onClick={() => {
                  if (!expandDrilldownDimension) return

                  expandDrilldownDimension(drilldownDimension.value)
                }}
              >
                {drilldownDimension.label}
              </ContextMenuItem>
            ))}
          </ContextMenuContent>
        </ContextMenu>
      )}
    </>
  )
}

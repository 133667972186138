import { ITableColumn } from '../types/table-builder-types'

export const whenPivotColumnIsValueOrPivot = (
  currentColumn: ITableColumn,
  columns: ITableColumn[]
) => {
  return (
    _.some(columns, (column) => column.pivot) && (!!currentColumn.aggFunc || currentColumn.pivot)
  )
}

import { PayloadAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'

import { getInitialDateFilterInfo, getRangeInfoFromDateFilterInfo } from '@utils/date-filter-utils'
import { getMonthFromIndex } from '@utils/date-utils'

import { WithID, createPageAction } from '@store/page-util'

import { endOfMonth, format, getYear, startOfMonth, subMonths } from 'date-fns'
import { HANDLE_BUSINESS_SELECTOR_CHANGE, LOGIN } from 'store/reducers/actions'
import { AuthActionProps } from 'types/auth'
import { DateFilterInfo, DateRangeType, ExpandedDateFilterInfo, Frequency } from 'types/filter'

import {
  DateFrequency,
  DateFrequencyEnum,
  TimePeriodEnum,
  TimePeriodType
} from '../../components/control-panel/types'
import { rehydrateStore } from '../actions/rehydrate-store'
import { RootState } from '../index'

interface DateTimeFilterState {
  dateFrequency: DateFrequency
  timePeriod: TimePeriodType
  dateFilter: {
    active: true
    filterInfo: DateFilterInfo
  }
  expandedDateFilterInfo: ExpandedDateFilterInfo
}

const dateTimeFiltersAdapter = createEntityAdapter<WithID<DateTimeFilterState>>()

const now = new Date()
const trailingTwelveMonths = subMonths(now, 12)

const initialDateFilterInfo: DateFilterInfo = {
  frequency: Frequency.Monthly,
  fiscalYearStart: undefined,
  rangeType: DateRangeType.Range,
  startDate: {
    year: getYear(trailingTwelveMonths),
    month: getMonthFromIndex(trailingTwelveMonths.getMonth())
  },
  endDate: {
    year: getYear(now),
    month: getMonthFromIndex(now.getMonth())
  }
}

const initialState = dateTimeFiltersAdapter.getInitialState<{ default: DateTimeFilterState }>({
  default: {
    dateFrequency: DateFrequencyEnum.M,
    timePeriod: TimePeriodEnum.Today,
    dateFilter: { active: true, filterInfo: initialDateFilterInfo },
    expandedDateFilterInfo: {
      rangeType: initialDateFilterInfo.rangeType,
      startDate: format(startOfMonth(trailingTwelveMonths), 'yyyy-MM-dd'),
      endDate: format(endOfMonth(now), 'yyyy-MM-dd'),
      frequency: initialDateFilterInfo.frequency,
      originalInfo: initialDateFilterInfo
    }
  }
})

const findOrCreateEntity = (state: typeof initialState, id: string) => {
  let entity = state.entities[id]
  if (!entity) {
    dateTimeFiltersAdapter.addOne(state, _.assign({ id }, _.cloneDeep(state.default)))
    entity = state.entities[id]
  }
  return entity
}

const dateTimeFilterSlice = createSlice({
  name: '@DATE_TIME_FILTER',
  initialState,
  reducers: {
    updateDateFilter: (state, action: PayloadAction<WithID<DateFilterInfo>>) => {
      const { id, ...dateFilter } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.dateFilter = { active: true, filterInfo: dateFilter }
      entity.expandedDateFilterInfo = getRangeInfoFromDateFilterInfo(dateFilter)
    },
    updateTimePeriod: (state, action: PayloadAction<WithID<{ timePeriod: TimePeriodType }>>) => {
      const { id, timePeriod } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.timePeriod = timePeriod
    },
    updateDateFrequency: (
      state,
      action: PayloadAction<WithID<{ dateFrequency: DateFrequency }>>
    ) => {
      const { id, dateFrequency } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.dateFrequency = dateFrequency
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrateStore, (_state, action) => {
      return { ...initialState, ...action.payload.dateTimeFilter }
    })
    builder.addMatcher(
      (action) => [LOGIN, HANDLE_BUSINESS_SELECTOR_CHANGE].includes(action.type),
      (state, action: AuthActionProps) => {
        const defaultValues = state.default
        defaultValues.dateFilter = {
          active: true,
          filterInfo: getInitialDateFilterInfo(action.payload?.user?.business_fiscal_year_start)
        }
        defaultValues.expandedDateFilterInfo = getRangeInfoFromDateFilterInfo(
          defaultValues.dateFilter.filterInfo
        )
      }
    )
  }
})

// Actions
export const updateDateFilter = createPageAction(dateTimeFilterSlice.actions.updateDateFilter)
export const updateTimePeriod = createPageAction(dateTimeFilterSlice.actions.updateTimePeriod)
export const updateDateFrequency = createPageAction(dateTimeFilterSlice.actions.updateDateFrequency)

// Reducer
export const dateTimeFilterReducer = dateTimeFilterSlice.reducer

// Selectors
const getDateTimeFilterState = (id: string) => (state: RootState) =>
  _.get(state, ['dateTimeFilter', 'entities', id], state.dateTimeFilter.default)

export const selectDateFilter = (id: string) =>
  createSelector(getDateTimeFilterState(id), (dateTimeFilter) =>
    _.get(dateTimeFilter, ['dateFilter'])
  )

export const selectExpandedDateFilterInfo = (id: string) =>
  createSelector(getDateTimeFilterState(id), (dateTimeFilter) =>
    _.get(dateTimeFilter, ['expandedDateFilterInfo'])
  )

export const selectDateFrequency = (id: string) =>
  createSelector(getDateTimeFilterState(id), (dateTimeFilter) =>
    _.get(dateTimeFilter, ['dateFrequency'])
  )

export const selectTimePeriod = (id: string) =>
  createSelector(getDateTimeFilterState(id), (dateTimeFilter) =>
    _.get(dateTimeFilter, ['timePeriod'])
  )

import { ReactElement, Suspense } from 'react'

import ErrorBoundary from './ErrorBoundary'
import Loader from './Loader'
import { ComponentCrashed } from './fallback/component-crashed'

const Loadable =
  <T extends {}>(
    Component: (props: T) => ReactElement,
    CustomLoader = Loader,
    CustomFallBack = ComponentCrashed
  ) =>
  (props: T): ReactElement => (
    <ErrorBoundary fallback={<CustomFallBack />}>
      <Suspense fallback={<CustomLoader />}>
        <Component {...props} />
      </Suspense>
    </ErrorBoundary>
  )

export default Loadable

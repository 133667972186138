import { useState } from 'react'

import { Box, Tab, Tabs } from '@mui/material'

import { useSelector } from '@store/index'
import { selectFlipAxis } from '@store/slices/component/chart-config'

import CustomTabPanel from '../common/custom-tab-panel'
import a11yProps from '../utils/a11y-props'
import ChartSettings from './chart-settings'
import ChartXAxis from './chart-x-axis'
import ChartYAxis from './chart-y-axis'

export default function ChartConfig() {
  const [value, setValue] = useState(0)
  const flipAxis = useSelector(selectFlipAxis)

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label='chart config' variant='fullWidth'>
          <Tab label='Settings' {...a11yProps(0)} />
          <Tab label={flipAxis ? 'X-Axis' : 'Y-Axis'} {...a11yProps(1)} />
          <Tab label={flipAxis ? 'Y-Axis' : 'X-Axis'} {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ChartSettings />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ChartYAxis />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ChartXAxis />
      </CustomTabPanel>
    </Box>
  )
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'
import { errorSnack } from 'utils/snackbar-utils'

export const useReorder = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      hierarchyId,
      parentNodeId,
      sortedNodeIds,
      groupType = 'heading_nodes'
    }: {
      hierarchyId: number
      parentNodeId?: number
      sortedNodeIds?: Array<number>
      groupType?: 'gl_account_nodes' | 'heading_nodes'
    }) => {
      return axiosServices.post(
        `/businesses/${user?.business_id}/general_ledger_account_hierarchy_nodes/reorder`,
        {
          general_ledger_account_hierarchy_node: {
            parent_node_id: parentNodeId,
            hierarchy_id: hierarchyId,
            sorted_node_ids: sortedNodeIds
          }
        }
      )
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: ['fetch-all-hierarchies-with-nodes', user?.business_id]
      })

      variables.groupType === 'gl_account_nodes' &&
        queryClient.invalidateQueries({
          queryKey: ['fetch-gl-account-nodes', user?.business_id, variables.parentNodeId]
        })
      variables.groupType === 'heading_nodes' &&
        queryClient.invalidateQueries({
          queryKey: ['fetch-hierarchy-builder-nodes', user?.business_id, variables.hierarchyId]
        })

      queryClient.invalidateQueries({
        queryKey: ['fetch-report-tree', user?.business_id, variables.hierarchyId]
      })
    },
    onError: (err, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['fetch-hierarchy-builder-nodes', user?.business_id, variables.hierarchyId]
      })

      errorSnack(`Error Reordering GL Hierarchy Nodes. \n ${err?.message}`)
    }
  })
}

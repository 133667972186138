import { useSelector } from 'react-redux'

import { Box, List, Typography } from '@mui/material'

import { isLastOrUniqueNavItem } from '@utils/obj-utils'

import { selectDrawerOpen } from '@store/slices/navigation'

import {
  MenuItemType,
  NavItemType,
  isMenuItemTypeCollapse,
  isMenuItemTypeNavigation
} from 'types/menu'

import NavCollapse from './NavCollapse'
import NavItem from './NavItem'

interface Props {
  parent: MenuItemType
  item: NavItemType
  level: number
  searchTerm?: string
}

const NavGroup = ({ parent, searchTerm = '', item, level }: Props) => {
  const drawerOpen = useSelector(selectDrawerOpen)

  const children = _.get(item, 'children', [])
  const navCollapse = _.map(Array.from(children), (menuItem, index, array) => {
    switch (menuItem.type) {
      case MenuItemType.collapse:
        return (
          <NavCollapse
            parent={MenuItemType.group}
            searchTerm={searchTerm}
            key={menuItem.id}
            menu={menuItem}
            level={level + 1}
          />
        )
      case MenuItemType.item:
        return (
          <NavItem
            parent={MenuItemType.group}
            searchTerm={searchTerm}
            key={menuItem.id}
            item={menuItem}
            level={level + 1}
            isLastItem={isLastOrUniqueNavItem(menuItem, index, array)}
          />
        )
      default:
        return (
          <NavGroup
            parent={MenuItemType.group}
            searchTerm={searchTerm}
            key={item.id}
            item={menuItem}
            level={level + 1}
          />
        )
    }
  })

  let plMultiplier = 8
  if (isMenuItemTypeNavigation(parent)) {
    plMultiplier = 8
  } else if (isMenuItemTypeCollapse(parent)) {
    plMultiplier = 2
  } else {
    plMultiplier = 4
  }

  return (
    <List
      subheader={
        item.title &&
        drawerOpen && (
          <Box className='nav-group-box' sx={{ pl: `${level * plMultiplier}px`, mb: 0.5 }}>
            <Typography className='nav-group-title' variant='menuCategory'>
              {item.title}
            </Typography>
            {item.caption && (
              <Typography variant='caption' color='secondary'>
                {item.caption}
              </Typography>
            )}
          </Box>
        )
      }
      sx={{ mt: drawerOpen && item.title ? 0.5 : 0, py: 0, zIndex: 0 }}
    >
      {navCollapse}
    </List>
  )
}

export default NavGroup

import { Theme } from '@mui/material/styles'

export default function AppBar(theme: Theme) {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
          marginLeft: '0rem',
          width: '100%'
        }
      }
    }
  }
}

import { useMemo } from 'react'

import { Divider, Grid, SelectChangeEvent, Typography } from '@mui/material'

import { useDispatch } from '@store/index'
import { useSelector } from '@store/index'
import { selectXAxes, updateYAxisData } from '@store/slices/component/chart-config'

import MuiSelect from 'components/form/mui-select'

import { useQueryResultContext } from '../contexts/query-result-context'
import { IAxis, StackingType, isSeriesTypeWaterfall } from '../types/chart-builder-types'

export default function Breakdown({ selected }: { selected: IAxis }) {
  const { dimensions, dates } = useQueryResultContext()
  const dispatch = useDispatch()
  const xAxes = useSelector(selectXAxes)

  const breakdownOptions = useMemo(
    () =>
      [
        { value: '-', label: 'None' },
        ...dimensions.map((dim) => ({ value: dim.name, label: dim.name })),
        ...dates.map((date) => ({ value: date.name, label: date.name }))
      ].filter((dim) => !xAxes.map((x) => x.name).includes(dim.label)),
    [dimensions, dates, xAxes]
  )

  const isWaterfallSeries = isSeriesTypeWaterfall(selected)

  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h5'>Breakdown</Typography>
        <Typography variant='details' gutterBottom>
          Breakdown your chart by sub category
        </Typography>
        <Divider />
      </Grid>
      <Grid item xs={isWaterfallSeries ? 12 : 6}>
        <MuiSelect
          options={breakdownOptions}
          value={selected.breakdown || '-'}
          onChange={(e: SelectChangeEvent<string>) => {
            dispatch(updateYAxisData({ id: selected.id, breakdown: e.target.value }))
          }}
          label='Breakdown'
        />
      </Grid>
      {!isWaterfallSeries && (
        <Grid item xs={6}>
          <MuiSelect
            options={[
              { value: StackingType.STACKED, label: 'Stacked' },
              { value: StackingType.NONE, label: 'None' },
              { value: StackingType.LAYERED, label: 'Layered' }
            ]}
            value={selected.stacking || StackingType.STACKED}
            onChange={(e: SelectChangeEvent<string>) => {
              dispatch(
                updateYAxisData({ id: selected.id, stacking: e.target.value as StackingType })
              )
            }}
            label='Stacking'
          />
        </Grid>
      )}
    </>
  )
}

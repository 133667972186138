import { useEffect, useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'

import { useDroppable } from '@dnd-kit/core'

import { useLayoutContext } from 'contexts/LayoutContext'

import { COMP } from '../../../data'
import { extractLayoutAttributes } from '../../../helpers'
import { ViewMode, isViewModeEdit, isViewModeParentView } from '../../../types'
import LayoutComponent from '../layout-component'

import './freestyle-section.css'

const ResponsiveReactGridLayout = WidthProvider(Responsive)
const findWidgetById = (id: any, children: any) => {
  for (const child of children) {
    if (child.id === id && child.category === COMP.WIDGET.CATEGORY) {
      return child
    } else if (child.children) {
      const result: any = findWidgetById(id, child.children)
      if (result) {
        return result
      }
    }
  }
  return null
}

interface FreeStyleSectionProps {
  id: number
  gridLine: boolean
  devMode: boolean
  path: string
  section: any
  onLayoutChange?: (layout: any, layouts: any, props: any) => void
  onComponentRemove?: (path: string, layout_item: any) => void
  className?: string
  rowHeight?: number
  mode: ViewMode
  cols?: { [key: string]: number }
  relative_layout_structure?: any
  isDraggable?: boolean
  isResizable?: boolean
}

export interface LayoutItem {
  x: number
  y: number
  w: number
  h: number
  i: string
  static: boolean
}

export function FreeStyleSection(props: FreeStyleSectionProps) {
  const { mode, gridLine, devMode, path, section, cols, relative_layout_structure = [] } = props
  const { children: columns } = section

  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
    data: {
      ...section,
      path: path,
      cols: cols
    }
  })

  const layoutData = extractLayoutAttributes(section)

  // TODO: Figure out diff between lg, md, sm
  const compactType = 'vertical'
  const [currentBreakpoint, setCurrentBreakpoint] = useState('lg')
  const [mounted, setMounted] = useState(false)
  const [layouts, setLayouts] = useState<{ [key: string]: LayoutItem[] }>({
    lg: layoutData || []
  })

  const { setSelectedWidget, selectedWidgets, setSelectedWidgets } = useLayoutContext()

  const onBreakpointChange = (breakpoint: string) => {
    setCurrentBreakpoint(breakpoint)
  }

  const onLayoutChange = (layout: any, layouts: any) => {
    if (props.onLayoutChange) {
      props.onLayoutChange(layout, layouts, props)
    }
  }

  const onRemoveItem = (layout_item: LayoutItem) => {
    const newLayouts = layouts.lg.filter((widget) => widget.i !== layout_item.i)
    setLayouts({
      ...layouts,
      lg: newLayouts
    })

    if (props.onComponentRemove) {
      props.onComponentRemove(path, layout_item)
    }
  }

  useEffect(() => {
    if (layoutData.length > 0 && layouts.lg.length !== layoutData.length) {
      setLayouts((prevLayouts: any) => ({
        ...prevLayouts,
        lg: layoutData || []
      }))
    }
  }, [layoutData, layoutData.length, layouts.lg.length])

  useEffect(() => {
    setMounted(true)
  }, [])

  const onWidthChange = (a: any, b: any, c: any) => {
    // console.log('Width change', a, b, c);
  }

  const onWidgetClick = (widget: any, componentViewMode: ViewMode) => {
    if (isViewModeEdit(componentViewMode)) {
      setSelectedWidget(widget)
    } else if (isViewModeParentView(componentViewMode)) {
      const isComponentSelected = selectedWidgets.some((selected) => selected.id === widget.id)

      if (isComponentSelected) {
        setSelectedWidgets((prevSelected) =>
          prevSelected.filter((selected) => selected.id !== widget.id)
        )
      } else {
        setSelectedWidgets((prevSelected) => [...prevSelected, widget])
      }
    }
  }

  return (
    <>
      <div ref={setNodeRef}>
        <div>
          {devMode && (
            <>
              <div>
                Current Breakpoint: {currentBreakpoint} ({props.cols?.[currentBreakpoint] || 12}{' '}
                columns)
              </div>
              <div className='layout-log'>
                Displayed as <code>[x, y, w, h]</code>:
                <div className='layout-items-container'>
                  {layouts.lg.map((l: any) => (
                    <div className='layout-item-container' key={l.i}>
                      <label className='layout-item-key'>{l.i}</label>:{' '}
                      <span className='layout-item-value'>
                        [{l.x}, {l.y}, {l.w}, {l.h}]
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
        <div>
          <ResponsiveReactGridLayout
            className={` ${gridLine ? 'layout-grid' : ''} layout-mode-${mode} ${mode !== ViewMode.edit ? 'layout-view' : ''}`}
            style={{
              backgroundColor: isOver ? 'rgb(9, 113, 241)' : ''
            }}
            {...props}
            layouts={layouts}
            onBreakpointChange={onBreakpointChange}
            onLayoutChange={onLayoutChange}
            onWidthChange={onWidthChange}
            measureBeforeMount={false}
            useCSSTransforms={mounted}
            compactType={compactType}
            preventCollision={!compactType}
            draggableHandle='.layout-title-bar' // Remove this to make it full body draggable
            containerPadding={[0, 0]}
            margin={[16, 16]}
          >
            {_.map(layouts.lg, (layout_item, item_index) => {
              const widget = findWidgetById(layout_item.i, columns)

              let componentMatch = false
              if (isViewModeParentView(mode)) {
                const similarWidget = findWidgetById(
                  layout_item.i,
                  relative_layout_structure[0].children
                )
                if (similarWidget) {
                  componentMatch = true
                }
              }

              const isWidgetInArray = selectedWidgets.some((item) => item.id === widget.id)
              // columns: relative_layout_structure[0].children
              return (
                <div key={layout_item.i} className={layout_item.static ? 'static' : ''}>
                  <LayoutComponent
                    layout_item={layout_item}
                    widget={widget}
                    item_index={item_index}
                    rowHeight={props.rowHeight}
                    mode={mode}
                    onRemoveItem={onRemoveItem}
                    parentHasComponent={componentMatch}
                    onWidgetClick={onWidgetClick}
                    selected={isWidgetInArray}
                  />
                </div>
              )
            })}
          </ResponsiveReactGridLayout>
        </div>
      </div>
    </>
  )
}

FreeStyleSection.defaultProps = {
  rowHeight: 60,
  cols: { lg: 12, md: 9, sm: 6, xs: 3, xxs: 3 }
}

export default FreeStyleSection

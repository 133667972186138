import { humanizeFieldName } from '@utils/string-utils'

import { DataPill } from '@components/component-management/DataPill'
import { Separator } from '@components/core/separator'
import { Text } from '@components/core/text'

import { useSelector } from '@store/index'
import { selectFormulas } from '@store/slices/component/query-config'

import { EditFormulaPopover } from './formula-form'
import { ParameterizedFieldConfig } from './parameterized-field-config'

export function FormulaSection() {
  const formulas = useSelector(selectFormulas)

  if (_.isEmpty(formulas)) return null

  return (
    <div className='mb-2'>
      <Text variant='button' weight='semibold'>
        Custom Formulas
      </Text>
      <Separator className='my-2' />
      <div className='flex flex-col gap-2'>
        {formulas.map((formula) => (
          <div key={formula.name} className='flex items-center justify-between'>
            <DataPill label={humanizeFieldName(formula.name)} dataType={formula.data_type} />
            <EditFormulaPopover
              formulaName={formula.name}
              controls={<ParameterizedFieldConfig headerName={formula.name} inline />}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { AgGridReact } from 'ag-grid-react'

import { isBlank } from '@utils/lodash'

export type AgGridRef = React.RefObject<AgGridReact<any> | undefined>

type GridRefContextType = {
  registeredRefs: { [key: string]: AgGridRef[] }
  registerGridRef: (type: string, gridRef: AgGridRef) => void
  unregisterGridRef: (type: string, gridRef: AgGridRef) => void
}

const AgGridRefContext = createContext<GridRefContextType | undefined>(undefined)

export const useGetRegisteredAgGridRefs = (type: string = 'income-statement') => {
  const context = useContext(AgGridRefContext)
  if (!context) {
    throw new Error('useGetRegisteredAgGridRefs must be used within an AgGridRefProvider')
  }
  const { registeredRefs } = context
  return registeredRefs[type] || []
}

export const useRegisterAgGridRefEffect = (
  gridRef: AgGridRef,
  type: string = 'income-statement'
) => {
  const context = useContext(AgGridRefContext)
  if (!context) {
    throw new Error('useRegisterAgGridRefEffect must be used within an AgGridRefProvider')
  }
  const { registerGridRef, unregisterGridRef } = context

  useEffect(() => {
    if (isBlank(gridRef.current)) return

    registerGridRef(type, gridRef)

    return () => {
      unregisterGridRef(type, gridRef)
    }
  }, [gridRef, type, registerGridRef, unregisterGridRef])
}

export const AgGridRefProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [registeredRefs, setRegisteredRefs] = useState<{ [key: string]: AgGridRef[] }>({})

  const registerGridRef = useCallback((type: string, gridRef: AgGridRef) => {
    setRegisteredRefs((prevRefs) => {
      const refs = prevRefs[type] || []
      return {
        ...prevRefs,
        [type]: [...refs, gridRef]
      }
    })
  }, [])

  const unregisterGridRef = useCallback((type: string, gridRef: AgGridRef) => {
    setRegisteredRefs((prevRefs) => {
      const refs = prevRefs[type] || []
      const newRefs = refs.filter((ref) => ref !== gridRef)
      if (newRefs.length === 0) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [type]: _, ...rest } = prevRefs
        return rest
      } else {
        return {
          ...prevRefs,
          [type]: newRefs
        }
      }
    })
  }, [])

  return (
    <AgGridRefContext.Provider value={{ registeredRefs, registerGridRef, unregisterGridRef }}>
      {children}
    </AgGridRefContext.Provider>
  )
}

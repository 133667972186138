import { useCallback } from 'react'

import { ValueGetterParams } from 'ag-grid-community'

import { FinancialHierarchyNode } from '@components/financial/types'
import { financialValueGetter, getDivideBy } from '@components/financial/utils/formatting-utils'

import { usePageSelector } from '@store/index'
import { selectDollarUnit } from '@store/slices/action-bar'

export const useFinancialValueGetter = () => {
  const dollarUnit = usePageSelector(selectDollarUnit)
  const divideBy = getDivideBy(dollarUnit)

  return useCallback(
    (params: ValueGetterParams) => {
      // e.g. of params.colDef.field =>  data.2023-01-01 | data.2023-01-01.debit for TrialBalance
      const dataPath = params.colDef.field as string
      const data = params.node?.data as FinancialHierarchyNode
      return financialValueGetter(dataPath, data, divideBy)
    },
    [divideBy]
  )
}

import { LinearProgress, Stack } from '@mui/material'

import { ICellRendererParams } from 'ag-grid-community'

export const generalCellRenderer =
  ({ main, options }: { main: boolean; options: { value: string; label: string }[] }) =>
  (props: ICellRendererParams) => {
    if (!props.value || props.value === '(Select All)') {
      return props.value
    }

    const cellValue = props.value?.replace('__editing', '')
    const option = options.find((option) => option.value === cellValue)
    const chosen = option?.label || cellValue

    if (main) {
      const editing = props.value?.includes('__editing')
      return (
        <Stack direction='column' justifyContent='space-between' height='100%'>
          {chosen}
          {!!editing && <LinearProgress color='primary' />}
        </Stack>
      )
    } else {
      return chosen
    }
  }

export function getValues<T extends { value: string }>(options: T[]) {
  return options.map((option) => option.value)
}

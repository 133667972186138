import { Theme } from '@mui/material/styles'

export default function Stack(theme: Theme) {
  return {
    MuiStack: {
      defaultProps: {
        spacing: 3
      },
      styleOverrides: {}
    }
  }
}

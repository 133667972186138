import {
  IAssignRefProps,
  IGetRefProps,
  IModuleIdReferences,
  ModuleName
} from '../types/module-types'

export function assignRef<TProps extends IAssignRefProps>({
  moduleName,
  defn,
  amModulesRef,
  item
}: TProps) {
  const amModules = amModulesRef.current
  if (!amModules[moduleName]) {
    amModules[moduleName] = {}
  }
  amModules[moduleName]![defn?.id || 'main'] = item
}

export function getRef<
  TProps extends IGetRefProps & { fromRef?: boolean; referenceIndex?: number }
>({ amModulesRef, moduleName, defn, referenceIndex = 0, fromRef = true }: TProps) {
  const amModules = amModulesRef.current
  const moduleNames = typeof moduleName === 'string' ? [moduleName] : moduleName

  let id = defn?.id || 'main'
  let ref = null
  for (const currentModuleName of moduleNames) {
    try {
      if (fromRef) {
        id =
          getIdFromReferences({
            references: defn?.references,
            moduleName: currentModuleName,
            index: referenceIndex
          }) || id
      }

      ref = amModules[currentModuleName]![id]
    } catch (e) {
      continue
    }
  }

  return ref
}

export function getSelfRef<TProps extends IGetRefProps & { referenceIndex?: number }>(
  props: TProps
) {
  return getRef({ ...props, fromRef: false })
}

function getIdFromReferences({
  references,
  moduleName,
  index
}: {
  references?: IModuleIdReferences
  moduleName: ModuleName
  index?: number
}) {
  let id: string = 'main'
  if (references?.[moduleName]) {
    if (typeof references[moduleName] === 'string') {
      id = references[moduleName] as string
    } else {
      id = (references[moduleName] as string[])[index || 0]
    }
  }
  return id
}

import React, { useRef } from 'react'
import { MultiValue } from 'react-select'

import { ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { AgDataTable } from '@components/ag-data-table'
import SwitchCellRenderer from '@components/ag-grid/custom-cell-renderer/switch-cell-renderer'
import { MultiSelect } from '@components/core/multi-select'

import { useBusinessFetchAlertConfigs } from './queries/fetch-business-alert-configs'
import { useFetchUserGroups } from './queries/fetch-user-groups'
import { useUpdateBusinessAlertCategoryConfig } from './queries/update-business-alert-category-config'
import { IConnectedSourceSystem } from './types'

const CATEGORY_TYPE = 'Connected Source System'

export function AlertsList({
  connectedSourceSystem
}: {
  connectedSourceSystem?: IConnectedSourceSystem
  isPending?: boolean
}) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const gridRef = useRef<AgGridReact>(null)

  const { data: alertConfigs, isLoading } = useBusinessFetchAlertConfigs({
    categoryType: CATEGORY_TYPE,
    businessId: connectedSourceSystem?.businessId
  })

  const { data: userGroups } = useFetchUserGroups({ businessId: connectedSourceSystem?.businessId })

  const updateAlertConfig = useUpdateBusinessAlertCategoryConfig(connectedSourceSystem?.businessId)

  const handleFieldChange = (
    updatedFields: Partial<{
      enabled: boolean
      emailNotificationsEnabled: boolean
      user_groups: number[]
    }>,
    id: number
  ) => {
    if (!alertConfigs) {
      return
    }

    const currentConfig = alertConfigs.find((config) => config.id === id)
    if (!currentConfig) return

    updateAlertConfig.mutate({
      id,
      ...updatedFields
    })
  }

  const options =
    userGroups?.map((group) => ({
      label: group.label,
      value: group.id!.toString()
    })) || []

  const onSwitchChange = (params: ICellRendererParams, recentValue: boolean) => {
    type ValidField = 'enabled' | 'emailNotificationsEnabled'
    const fieldName = params.colDef?.field as ValidField
    const updatedFields = { [fieldName]: recentValue }
    handleFieldChange(updatedFields, params.data.id)
  }

  const columnDefs = [
    {
      headerName: 'Enabled',
      field: 'enabled',
      cellRenderer: 'switchCellRenderer',
      cellRendererParams: {
        onSwitchChange
      },
      minWidth: 100,
      cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' }
    },
    {
      headerName: 'Alert Type',
      field: 'alertType',
      minWidth: 500,
      valueGetter: (params: ValueGetterParams) =>
        _.startCase(_.toLower(params.data.alertType.replace(/_/g, ' ')))
    },
    {
      headerName: 'Email Notification',
      field: 'emailNotificationsEnabled',
      cellRenderer: 'switchCellRenderer',
      cellRendererParams: {
        onSwitchChange
      },
      minWidth: 100,
      cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' }
    },
    {
      headerName: 'User Groups',
      field: 'userGroups',
      cellEditor: 'agSelectCellEditor',
      cellRenderer: (params: ICellRendererParams) => {
        const handleChange = (
          selectedOptions: MultiValue<{ label: string; value: string | number }>
        ) => {
          const selectedValues = selectedOptions.map((option) => option.value)

          if (params.setValue) {
            params.setValue(selectedValues)
          }

          const updatedFields = { user_groups: _.map(selectedValues, Number) }
          handleFieldChange(updatedFields, params.data.id)
        }

        return (
          <MultiSelect
            options={options}
            defaultSelectedValues={_.map(params.value, String)}
            onChange={handleChange}
          />
        )
      },
      minWidth: 400
    }
  ]

  return (
    <div className='h-[calc(100vh-11rem)] w-full' ref={wrapperRef}>
      <AgDataTable
        rowHeight={100}
        ref={gridRef}
        rowData={alertConfigs || []}
        columnDefs={columnDefs}
        gridOptions={{
          components: {
            switchCellRenderer: SwitchCellRenderer
          }
        }}
        enableRangeSelection={false}
        defaultColDef={{
          resizable: true,
          filter: false,
          floatingFilter: false,
          filterParams: {
            buttons: ['clear']
          }
        }}
        loading={isLoading}
        editType={'fullRow'}
        enableCellTextSelection={true}
        suppressRowClickSelection
        autoSizeStrategy={{
          type: 'fitCellContents'
        }}
      />
    </div>
  )
}

import * as am5 from '@amcharts/amcharts5'

import { useLatestRef } from 'hooks/useLatestRef'

import useSetTooltipLabel from '../hooks/useSetTooltipLabel'
import { IModule, IModuleAttributes, IModuleUseInstance, ModuleName } from '../types/module-types'
import { assignRef, getRef } from '../utils/module-utils'
import { disposeTooltipOverlay, initTooltipOverlay } from '../utils/overlay-tooltip'

const moduleName = ModuleName.tooltip
const tooltipModule: IModule<ModuleName.tooltip> = {
  name: moduleName
}

tooltipModule.init = (props) => {
  const root: am5.Root = getRef({ ...props, moduleName: ModuleName.root })

  const { selector } = props

  const tooltip = am5.Tooltip.new(root, {
    getFillFromSprite: false,
    getStrokeFromSprite: false,
    autoTextColor: false,
    getLabelFillFromSprite: false,
    paddingBottom: 4,
    paddingTop: 4,
    paddingRight: 4,
    paddingLeft: 4
  })

  ;(tooltip.get('background') as am5.PointedRectangle)?.setAll({
    fill: am5.color(0xffffff),
    fillOpacity: 1,
    stroke: am5.color(0x000000),
    strokeWidth: 1,
    strokeOpacity: 0.05,
    shadowColor: am5.color(0x000000),
    shadowOpacity: 0.05,
    shadowOffsetX: 4,
    shadowOffsetY: 4,
    cornerRadius: 4
  })

  initTooltipOverlay({ selector })

  assignRef({ ...props, moduleName, item: tooltip })
}

const useInstance: IModuleUseInstance<ModuleName.tooltip> = (instance, props) => {
  const propsRef = useLatestRef(props)

  useSetTooltipLabel({ propsRef, moduleName, props })
}

tooltipModule.useInstance = useInstance

tooltipModule.dispose = (props) => {
  disposeTooltipOverlay({ selector: props.selector })
}

export const tooltipAttributes: IModuleAttributes<ModuleName.tooltip> = (p) => ({
  module: tooltipModule,
  ...p
})

export default tooltipModule

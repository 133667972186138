import { Divider, Grid, Typography } from '@mui/material'

import ModelFieldsSelector from './model-fields-selector'
import RunQueryDetail from './run-query-detail'

export default function QueryBuilder() {
  return (
    <Grid container>
      <Grid item xs={12} md={4} pt={1}>
        <ModelFieldsSelector />
      </Grid>
      <Divider orientation='vertical' flexItem sx={{ mr: '-1px' }} />
      <Grid item xs={12} md={8} pt={1} pl={2}>
        <Typography variant='h5' component='h2'>
          All Available Underlying Data dimensions and metrics for the Component
        </Typography>
        <Typography variant='subtitle2'>
          This is the data that populates your component, built from your selected Systems and
          Tables.
        </Typography>
        <RunQueryDetail />
      </Grid>
    </Grid>
  )
}

import { Input } from '@components/core/input'

import useDelayedUpdate from '@hooks/use-delayed-update'

import { useDispatch } from '@store/index'
import { updateYAxisData } from '@store/slices/component/chart-config'

import { IAxis } from '../types/chart-builder-types'

export function AxisLimit({ item: data }: { item: IAxis }) {
  const { id, axis_limit_min = '', axis_limit_max = '' } = data
  const dispatch = useDispatch()

  const [min, setMin] = useDelayedUpdate(axis_limit_min, (value) =>
    dispatch(updateYAxisData({ axis_limit_min: value as number, id }))
  )
  const [max, setMax] = useDelayedUpdate(axis_limit_max, (value) =>
    dispatch(updateYAxisData({ axis_limit_max: value as number, id }))
  )

  return (
    <>
      <Input value={min} onChange={(e) => setMin(e.target.value)} type='number' placeholder='Min' />
      <Input
        value={max}
        onChange={(e) => setMax(e.target.value)}
        type='number'
        placeholder='Max'
        className='mt-2'
      />
    </>
  )
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { nanoid } from 'nanoid'

import { RootState } from '../index'
import { AppDispatch } from '../index'

const TOAST_LIMIT = 1

export interface ToasterToast {
  id?: string
  title?: string
  variant?: 'info' | 'success' | 'warning' | 'error'
  description?: string | string[]
  duration?: number
  action?: {
    onClickType: string
    text: string
    altText?: string
  }
  showCloseButton?: boolean // Add this line
}

interface ToastState {
  headerHeight: number
  lastHeight: number
  toasts: ToasterToast[]
}

const initialState: ToastState = {
  headerHeight: 0,
  lastHeight: 0,
  toasts: []
}

export const handleToast = (toastData: ToasterToast) => (dispatch: AppDispatch) => {
  dispatch(addToast(toastData))
}

const toastSlice = createSlice({
  name: '@TOAST',
  initialState,
  reducers: {
    setHeaderHeight: (state, action: PayloadAction<number>) => {
      state.headerHeight = action.payload
    },
    setLastHeight: (state, action: PayloadAction<number>) => {
      state.lastHeight = action.payload
    },
    addToast: (state, action: PayloadAction<ToasterToast>) => {
      const newToast = {
        ...action.payload,
        id: action.payload.id || nanoid() // Append timestamp to the ID to ensure uniqueness
      }
      state.toasts = [newToast, ...state.toasts].slice(0, TOAST_LIMIT)
    },
    removeToast: (state) => {
      state.toasts = []
    }
  }
})

// Export actions
export const { setHeaderHeight, setLastHeight, addToast, removeToast } = toastSlice.actions

// Reducer
export const toastReducer = toastSlice.reducer

// Selectors
export const selectHeaderHeight = (state: RootState) => state.toasts.headerHeight
export const selectLastHeight = (state: RootState) => state.toasts.lastHeight
export const selectToasts = (state: RootState) => state.toasts.toasts

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { queryErrorToast, querySuccessToast } from 'queries/query-toasts'
import axiosServices from 'utils/axios'

export const useFlushConnections = ({ onSuccess }: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ businessId, id }: { businessId?: number; id?: number }) => {
      return axiosServices.post(
        `/businesses/${businessId}/connected_source_systems/${id}/flush_connections`
      )
    },
    onSuccess: (_response, variables) => {
      queryClient.invalidateQueries({ queryKey: ['fetch-active-connections', variables.id] })
      querySuccessToast('Connections flushed successfully')
      onSuccess?.()
    },
    onError: (error: any) => {
      queryErrorToast(error?.message || 'Failed to flush connections')
    }
  })
}

const whiteDefault = '#FFFFFF'
const transparent = 'transparent'

// Red color constants
const redDark = '#8C0000'
const redMedium = '#D24532'
const redLight = '#F59C91'
const redLight25 = 'rgba(227, 111, 96, 0.25)'

// Blue color constants
const blueDark = '#303D4E'
const blueMedium = '#2170BA'
const blueLight = '#4EADEA'
const blueLight25 = 'rgba(103, 182, 249, 0.25)'
const blueHm1 = '#F0F9FF'
const blueHm2 = '#C7E1FA'
const blueHm3 = '#8FC3F4'
const blueHm4 = '#5FADF7'
const blueHm5 = '#2170BA'
const blueHm6 = '#0E4F8C'
const blueHm7 = blueDark
const blueLightChart = '#B3BADA'
const blueChart = '#ACC5E0'

// Green color constants
const greenDark = '#126245'
const greenMedium = '#128259'
const greenLight = '#5BE1B1'
const greenLight25 = 'rgba(51, 211, 154, 0.25)'
const greenHm11 = '#D7FFF5'
const greenHm12 = '#90F9E0'
const greenHm13 = '#5FF7C9'

// Orange color constants
const orangeHm10 = '#F7954B'
const orangeHm9 = '#F7B07B'
const orangeHm8 = '#F7CEAF'
const orangeChart = '#FDB462'

// Purple color constants
const purpleChart = '#BC80BD'

// Yellow color constants
const yellowChart = '#FFED6F'

// Teal color constants
const tealChart = '#8DD3C7'

// Pink color constants
const pinkLightChart = '#FCCDE5'

// Grey color constants
const greyBackgroundFB = '#fbfbfb'
const greyLightest = '#fafafa'
const greyBackground = '#f9f9f9'
const greyLighter = '#F2F2F2'
const greyLight = '#D5D4DF'
const greyDefault = '#C6C6C6'
const greyLightDark = '#9A9A9A'
const greyDark = '#757575'
const greyDarker = '#505050'
const greyDetails = '#757575'
const greyChart = '#D9D9D9'
const greyMuted = 'rgba(38, 38, 38, 0.11)'

// Black color constants
const blackLight = '#292929'
const blackDefault = '#222'
const blackDark = '#000000'

const colorConstants = {
  red: {
    dark: redDark,
    medium: redMedium,
    DEFAULT: redMedium,
    light: redLight,
    light25: redLight25
  },
  blue: {
    dark: blueDark,
    medium: blueMedium,
    DEFAULT: blueMedium,
    light: blueLight,
    light25: blueLight25,
    hm1: blueHm1,
    hm2: blueHm2,
    hm3: blueHm3,
    hm4: blueHm4,
    hm5: blueHm5,
    hm6: blueHm6,
    hm7: blueHm7,
    chart: blueLightChart
  },
  green: {
    dark: greenDark,
    medium: greenMedium,
    DEFAULT: greenMedium,
    light: greenLight,
    light25: greenLight25,
    hm11: greenHm11,
    hm12: greenHm12,
    hm13: greenHm13
  },
  orange: {
    hm10: orangeHm10,
    hm9: orangeHm9,
    hm8: orangeHm8,
    DEFAULT: orangeChart
  },
  purple: {
    DEFAULT: purpleChart
  },
  yellow: {
    DEFAULT: yellowChart
  },
  teal: {
    DEFAULT: tealChart
  },
  pink: {
    DEFAULT: pinkLightChart
  },
  grey: {
    backgroundfb: greyBackgroundFB,
    lightest: greyLightest,
    background: greyBackground,
    lighter: greyLighter,
    light: greyLight,
    DEFAULT: greyDefault,
    'light-dark': greyLightDark,
    dark: greyDark,
    darker: greyDarker,
    details: greyDetails,
    chart: greyChart
  },
  gray: {
    backgroundfb: greyBackgroundFB,
    lightest: greyLightest,
    background: greyBackground,
    lighter: greyLighter,
    light: greyLight,
    DEFAULT: greyDefault,
    'light-dark': greyLightDark,
    dark: greyDark,
    darker: greyDarker,
    details: greyDetails,
    chart: greyChart
  },
  black: {
    light: blackLight,
    DEFAULT: blackDefault,
    dark: blackDark
  },
  white: {
    DEFAULT: whiteDefault
  },
  transparent: {
    DEFAULT: transparent
  }
}

export const chargerColors = {
  primary: {
    lighter: blueLight25,
    light: blueLight,
    DEFAULT: blueMedium,
    dark: '#24528E',
    darker: blueDark
  },
  secondary: {},
  error: {
    lighter: '#fff1f0',
    light: redLight,
    DEFAULT: redMedium,
    dark: redDark,
    darker: '#820014'
  },
  inherit: 'inherit',
  warning: {
    lighter: '#fffbe6',
    light: '#ffe58f',
    DEFAULT: '#ffc53d',
    dark: '#d48806',
    darker: '#874d00'
  },
  info: {
    lighter: '#e6fffb',
    light: '#87e8de',
    DEFAULT: '#36cfc9',
    dark: '#08979c',
    darker: '#00474f'
  },
  success: {
    lighter: '#f6ffed',
    light: '#b7eb8f',
    DEFAULT: '#73d13d',
    dark: '#389e0d',
    darker: '#135200'
  },
  muted: {
    DEFAULT: greyMuted
  },
  charts: {
    black: blackLight,
    negative: redLight,
    positive: greenLight,
    single: {
      color0: blueMedium
    },
    two: {
      color0: blueMedium,
      color1: blueLight
    },
    three: {
      color0: blueLight,
      color1: purpleChart,
      color2: yellowChart
    },
    many: {
      color0: blueChart,
      color1: purpleChart,
      color2: blueLightChart,
      color3: yellowChart,
      color4: tealChart,
      color5: pinkLightChart,
      color6: orangeChart,
      color7: greyChart
    }
  },
  heatmap: {
    single: {
      neg3: blueHm7,
      neg2: blueHm6,
      neg1: blueHm5,
      DEFAULT: blueHm4,
      pos1: blueHm3,
      pos2: blueHm2,
      pos3: blueHm1
    },
    divergent: {
      neg3: orangeHm10,
      neg2: orangeHm9,
      neg1: orangeHm8,
      DEFAULT: whiteDefault,
      pos1: blueHm2,
      pos2: blueHm3,
      pos3: blueHm4
    }
  },
  ...colorConstants
}

export type ChargerColors = typeof chargerColors

export type ChartColorsKeys = keyof ChargerColors['charts']

import { useEffect } from 'react'

import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'

import ChartContainer, { useChartSelector } from 'components/chart/chart-container'

interface ChartProps {
  layout_item: any
  widget: any
  item_index: number
}
const Index = ({ widget }: ChartProps) => {
  const selector = useChartSelector()

  useEffect(() => {
    /**
     * ---------------------------------------
     * This demo was created using amCharts 5.
     *
     * For more information visit:
     * https://www.amcharts.com/
     *
     * Documentation is available at:
     * https://www.amcharts.com/docs/v5/
     * ---------------------------------------
     */
    const scale = 1
    const root = am5.Root.new(selector, {
      calculateSize: function (dimensions) {
        return {
          width: dimensions.width * scale,
          height: dimensions.height * scale
        }
      }
    })

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)])

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true
      })
    )

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'none'
      })
    )
    cursor.lineY.set('visible', false)

    // Generate random data
    const date = new Date()
    date.setHours(0, 0, 0, 0)
    let value = 100

    function generateData() {
      value = Math.round(Math.random() * 10 - 5 + value)
      am5.time.add(date, 'day', 1)
      return {
        date: date.getTime(),
        value: value
      }
    }

    function generateDatas(count: any) {
      const data = []
      for (let i = 0; i < count; ++i) {
        data.push(generateData())
      }
      return data
    }

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.2,
        baseInterval: {
          timeUnit: 'day',
          count: 1
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {})
      })
    )

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          pan: 'zoom'
        })
      })
    )

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Series',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        valueXField: 'date',
        tooltip: am5.Tooltip.new(root, {
          labelText: '{valueY}'
        })
      })
    )

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    chart.set(
      'scrollbarX',
      am5.Scrollbar.new(root, {
        orientation: 'horizontal'
      })
    )

    // Set data
    const data = generateDatas(1200)
    series.data.setAll(data)

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000)
    chart.appear(1000, 100)
    // Note: If you are working with this component, please fix react-hooks/exhaustive-deps warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='layout-widget-item layout-widget-chart'>
      <ChartContainer selector={selector} />
    </div>
  )
}

export default Index

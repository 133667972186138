export const DataLabelVisibilityOff = () => (
  <>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.5 11.0869V12.4425L5.52675 11.3476C5.83985 11.2334 6.1651 11.1457 6.5 11.0869ZM13.938 14.5H18.6765L20.2955 11.5L18.6764 8.5H6.5V10.0736C5.97827 10.1511 5.4761 10.2882 5 10.4778V8C5 7.44772 5.44772 7 6 7H18.9748C19.3423 7 19.6802 7.20162 19.8548 7.52507L21.7437 11.0251C21.9037 11.3215 21.9037 11.6785 21.7437 11.9749L19.8548 15.4749C19.6802 15.7984 19.3423 16 18.9748 16H13.574C13.7096 15.745 13.8303 15.4806 13.9348 15.2083L14.0723 14.85L13.938 14.5ZM12.8543 14.5H9.97811C9.99333 14.6144 10.0012 14.7313 10.0012 14.85C10.0012 15.2634 9.90585 15.654 9.73653 16H12.4143C12.6477 15.6417 12.8451 15.2566 13.0012 14.85C12.9557 14.7315 12.9067 14.6148 12.8543 14.5ZM8.96015 15.2102C8.98699 15.0947 9.00119 14.9741 9.00119 14.85C9.00119 14.7296 8.98781 14.6124 8.96248 14.5H8.32889L8.96015 15.2102ZM6.05751 14.4296L7.45346 16H6.50228C6.19458 15.7182 6.00119 15.3078 6.00119 14.85C6.00119 14.7042 6.02081 14.5631 6.05751 14.4296ZM5.31851 13.5982C5.11642 13.9686 5.00119 14.3956 5.00119 14.85C5.00119 14.9858 5.01147 15.1191 5.03128 15.2491C5.01086 15.1695 5 15.086 5 15V13.2399L5.31851 13.5982Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.6383 19.5825L2.76607 10.7262L3.40503 10.1582L11.2773 19.0145L10.6383 19.5825Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.0057 17.5152C11.8922 16.842 12.5893 15.9201 13 14.85C12.135 12.5965 10 11 7.5 11C6.80681 11 6.14169 11.1227 5.52391 11.3482L6.53249 12.4828C6.83013 12.3544 7.15704 12.2833 7.5 12.2833C8.88 12.2833 10 13.4332 10 14.85C10 15.2854 9.89423 15.6956 9.70766 16.0549L11.0057 17.5152ZM8.95851 15.2121C8.98564 15.096 9 14.9748 9 14.85C9 13.9979 8.33 13.31 7.5 13.31C7.42594 13.31 7.35316 13.3155 7.28201 13.3261L8.95851 15.2121ZM6.0558 14.4314L7.77408 16.3645C7.68524 16.3812 7.59364 16.39 7.5 16.39C6.67 16.39 6 15.7021 6 14.85C6 14.7048 6.01944 14.5645 6.0558 14.4314ZM5.31649 13.5997C5.11492 13.9697 5 14.3962 5 14.85C5 16.2668 6.12 17.4167 7.5 17.4167C7.86082 17.4167 8.20386 17.3381 8.51376 17.1966L9.52462 18.3338C8.8932 18.5707 8.21138 18.7 7.5 18.7C5 18.7 2.865 17.1035 2 14.85C2.41668 13.7644 3.12806 12.8314 4.03298 12.1558L5.31649 13.5997Z'
      fill='currentColor'
    />
  </>
)

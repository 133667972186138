import { Theme } from '@mui/material/styles'

export default function FormControlLabel(theme: Theme) {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: '-0.25rem'
        },
        sizeActionBar: {
          height: '1.75rem'
        }
      }
    }
  }
}

import BuilderLayout from '../common/builder-layout'
import DataPreview from '../common/data-preview'
import ChartConfig from './chart-config'
import ChartPreview from './chart-preview'

export default function ChartBuilder() {
  return (
    <BuilderLayout
      Preview={<ChartPreview />}
      Config={<ChartConfig />}
      DataPreview={<DataPreview />}
    />
  )
}

import {
  IAssociationData,
  IColumnData,
  QueryBuilderPlacement
} from '../../types/query-builder-types'
import Associations from './associations'
import NormalFields from './normal-fields'

export default function FieldsChooser({
  modelName,
  columns = [],
  associations = [],
  parent = '',
  placement
}: {
  modelName: string
  columns?: IColumnData[]
  associations?: IAssociationData[]
  parent?: string
  placement?: QueryBuilderPlacement
}) {
  return (
    <div>
      {!!columns.length && (
        <NormalFields modelName={modelName} data={columns} parent={parent} placement={placement} />
      )}
      {!!associations.length && (
        <Associations data={associations} parent={parent} placement={placement} />
      )}
    </div>
  )
}

import { useMemo } from 'react'

import { IRowNode, ValueGetterParams } from 'ag-grid-community'

export const useRowNumberColumnDefinition = () => {
  return useMemo(() => {
    return {
      headerName: '',
      pinned: true,
      field: 'rowNumber',
      width: 64,
      // NOTE: if we use cell renderer, the value won't be available in excel download
      valueGetter: (params: ValueGetterParams) => {
        let node: IRowNode<any> | null = params.node
        let rowNumber = ''

        while (node && (node.id !== 'ROOT_NODE_ID' || node.level !== -1)) {
          if (node?.data?.identifier === 'total') {
            rowNumber = `${rowNumber}T`
          } else {
            rowNumber = rowNumber
              ? `${node.childIndex + 1}.${rowNumber}`
              : (node.childIndex + 1).toString()
          }

          node = node.parent
        }

        return rowNumber
      },
      cellStyle: {
        borderBottom: '1px solid #babfc7',
        borderRight: '1px solid #babfc7',
        textAlign: 'center'
      }
    }
  }, [])
}

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

import { useLatestRef } from '@hooks/useLatestRef'

import { useSetAxisName, useSetInstanceData } from '../hooks'
import useToggleDataLabels from '../hooks/useToggleDataLabels'
import { DATA_LABEL_BULLETS, Display, Position } from '../types/data-types'
import { IModule, IModuleAttributes, IModuleUseInstance, ModuleName } from '../types/module-types'
import { getDefaultPrimaryColor } from '../utils/color-utils'
import { layeredSeriesThickness } from '../utils/layered-series-thickness'
import { assignRef, getRef } from '../utils/module-utils'

const moduleName = ModuleName.barSeries
const barSeriesModule: IModule<ModuleName.barSeries> = {
  name: moduleName
}

barSeriesModule.init = (props) => {
  const root: am5.Root = getRef({ ...props, moduleName: ModuleName.root })
  const chart: am5xy.XYChart = getRef({ ...props, moduleName: ModuleName.xyChart })
  const xAxis: am5xy.ValueAxis<am5xy.AxisRenderer> = getRef({
    ...props,
    moduleName: ModuleName.valueXAxis
  })
  const yAxis: am5xy.CategoryAxis<am5xy.AxisRenderer> | am5xy.DateAxis<am5xy.AxisRenderer> = getRef(
    {
      ...props,
      moduleName: [ModuleName.categoryYAxis, ModuleName.dateYAxis]
    }
  )
  const legend: am5.Legend = getRef({ ...props, moduleName: ModuleName.legend })
  const externalLegend: am5.Legend = getRef({ ...props, moduleName: ModuleName.externalLegend })
  const dataProcessor: am5.DataProcessor = getRef({
    ...props,
    moduleName: ModuleName.dataProcessor
  })

  const {
    options: {
      stacked = false,
      layered = false,
      xAxisKey,
      yAxisKey,
      name,
      color,
      handleNodeContextMenuOpen,
      // global options
      clusteredSeries = false
    }
  } = props

  const categoryYAxis = yAxis instanceof am5xy.CategoryAxis

  const seriesSettings: am5xy.IColumnSeriesSettings = {
    name,
    xAxis: xAxis,
    yAxis: yAxis,
    valueXField: xAxisKey,
    [categoryYAxis ? 'categoryYField' : 'valueYField']: yAxisKey,
    fill: am5.color(color || getDefaultPrimaryColor()),
    stacked
  }

  if (layered) {
    seriesSettings.clustered = false
  }

  const series = chart.series.push(am5xy.ColumnSeries.new(root, seriesSettings))

  const borderRadius = stacked ? 0 : 4

  series.columns.template.setAll({
    strokeWidth: 1,
    cornerRadiusBR: borderRadius,
    cornerRadiusTR: borderRadius
  })

  if (clusteredSeries) {
    series.columns.template.set('height', am5.p100)
  } else {
    series.set('stroke', am5.color(color || getDefaultPrimaryColor()))
  }

  if (layered) {
    series.columns.template.set(
      'height',
      am5.percent(layeredSeriesThickness(_.size(chart.series) - 1))
    )
  }

  if (dataProcessor) {
    series.data.processor = dataProcessor
  }

  if (legend) {
    legend.data.push(series)
  } else if (externalLegend) {
    externalLegend.data.push(series)
  }

  series.columns.template.onPrivate('width', function (width, target) {
    if (!width) return

    _.each(_.get(target, 'dataItem.bullets', []), function (bullet) {
      if (!_.isEqual(bullet.get('userData'), DATA_LABEL_BULLETS)) return
      if (width > 80) {
        bullet.get('sprite').show()
      } else {
        bullet.get('sprite').hide()
      }
    })
  })

  series.columns.template.events.on('rightclick', function (event) {
    const dataContext = _.get(event, 'target.dataItem.dataContext', {}) as Record<string, any>
    const { clientX, clientY } = event.originalEvent
    if (handleNodeContextMenuOpen) handleNodeContextMenuOpen(dataContext, { clientX, clientY })
  })

  assignRef({ ...props, moduleName, item: series })
}

const useInstance: IModuleUseInstance = (instance, props) => {
  const propsRef = useLatestRef(props)

  const {
    options: { xAxisKey, stacked = false, showDataLabels = false }
  } = props

  useSetInstanceData({ propsRef, moduleName, instance })
  useSetAxisName({ propsRef, moduleName, props })
  useToggleDataLabels({
    propsRef,
    moduleName,
    props,
    axisKey: xAxisKey,
    showDataLabels: !stacked && showDataLabels,
    display: Display.HORIZONTAL,
    position: Position.INSIDE,
    color: '#fff'
  })
}

barSeriesModule.useInstance = useInstance

export const barSeriesAttributes: IModuleAttributes<ModuleName.barSeries> = (p) => ({
  module: barSeriesModule,
  ...p
})

export default barSeriesModule

export const ABC = () => (
  <>
    <path
      d='M18.8068 14.7602C17.2468 14.7602 16.0068 13.6402 16.0068 12.0002C16.0068 10.3602 17.2468 9.24023 18.8068 9.24023C20.0228 9.24023 20.7028 9.88023 21.0788 10.5442L20.1028 11.0242C19.8788 10.5922 19.3988 10.2482 18.8068 10.2482C17.8788 10.2482 17.1748 10.9922 17.1748 12.0002C17.1748 13.0082 17.8788 13.7522 18.8068 13.7522C19.3988 13.7522 19.8788 13.4162 20.1028 12.9762L21.0788 13.4482C20.6948 14.1122 20.0228 14.7602 18.8068 14.7602Z'
      fill='currentColor'
    />
    <path
      d='M9.95825 14.6641V9.32812H12.7663C13.7823 9.32812 14.3023 9.97613 14.3023 10.6881C14.3023 11.3601 13.8863 11.8081 13.3823 11.9121C13.9503 12.0001 14.4063 12.5521 14.4063 13.2241C14.4063 14.0241 13.8703 14.6641 12.8543 14.6641H9.95825ZM11.0943 11.4721H12.5183C12.9023 11.4721 13.1423 11.2241 13.1423 10.8801C13.1423 10.5521 12.9023 10.2961 12.5183 10.2961H11.0943V11.4721ZM11.0943 13.6961H12.5583C12.9903 13.6961 13.2463 13.4481 13.2463 13.0641C13.2463 12.7281 13.0063 12.4401 12.5583 12.4401H11.0943V13.6961Z'
      fill='currentColor'
    />
    <path
      d='M7.22539 14.6641L6.88939 13.7441H4.50539L4.16939 14.6641H2.92139L4.98539 9.32812H6.40939L8.46539 14.6641H7.22539ZM5.69739 10.3121L4.80939 12.7601H6.57739L5.69739 10.3121Z'
      fill='currentColor'
    />
  </>
)

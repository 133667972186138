import { IconButton } from '@components/core/icon-button'
import { Popover, PopoverContent, PopoverTrigger } from '@components/core/popover'
import { DotsVertical, Icon } from '@components/icons'

export function OverflowMenu({ children }: { children: React.ReactNode }) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <IconButton variant='ghost' className='rounded-md border-none focus:text-primary-darker'>
          <Icon icon={<DotsVertical />} />
        </IconButton>
      </PopoverTrigger>
      <PopoverContent className='mt-1 w-64 p-4' align='end'>
        {children}
      </PopoverContent>
    </Popover>
  )
}

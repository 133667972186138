import { AdvancedFilterModel, FilterModel, SortModelItem } from 'ag-grid-community'

import { camelCaseKeys } from '@utils/case-conversion'

import axiosServices from 'utils/axios'

import { IDataIntegrityJob } from '../types'

const mapColIdToServerField = (colId: string): string => {
  const lookup: { [key: string]: string } = {
    stream: 'stream',
    namespace: 'namespace',
    diffCheckType: 'diff_check_type',
    uuid: 'uuid',
    mismatchFound: 'mismatch_found',
    startedAt: 'started_at',
    endedAt: 'ended_at'
  }

  return lookup[colId] || colId
}

export type FetchDataIntegrityJobsResponse = {
  records: IDataIntegrityJob[]
  count: number
}

export const fetchDataIntegrityJobs = async (
  businessId?: number,
  id?: string,
  options?: {
    page: number
    limit: number
    filterModel: FilterModel | AdvancedFilterModel | null
    sortModel: SortModelItem[]
    includeDeleted?: boolean
  }
): Promise<FetchDataIntegrityJobsResponse> => {
  const mappedSortModel = options?.sortModel.map((sortItem) => ({
    ...sortItem,
    colId: mapColIdToServerField(sortItem.colId)
  }))
  const mappedFilterModel = options?.filterModel
    ? _.mapKeys(options.filterModel, (value, key) => mapColIdToServerField(key))
    : null

  const response = await axiosServices.post(
    `/businesses/${businessId}/connected_source_systems/${id}/data_integrity_jobs`,
    {
      page: options?.page,
      limit: options?.limit,
      filterModel: mappedFilterModel,
      sortModel: mappedSortModel
    }
  )

  const recordsCamelCased = response.data.records.map((record: any) => {
    const camelCasedRecord = camelCaseKeys(record)
    if (camelCasedRecord.metadata) {
      camelCasedRecord.metadata = camelCaseKeys(camelCasedRecord.metadata)
    }
    return camelCasedRecord as IDataIntegrityJob
  })

  return {
    ...camelCaseKeys(response.data),
    records: recordsCamelCased
  } as FetchDataIntegrityJobsResponse
}

import { Fragment, SyntheticEvent } from 'react'

// material-ui
import { Alert, Button, Fade, Grow, Slide, SlideProps } from '@mui/material'
import MuiSnackbar from '@mui/material/Snackbar'

// assets
import { CloseOutlined } from '@ant-design/icons'

import { useDispatch, useSelector } from 'store'
import { closeSnackbar } from 'store/reducers/snackbar'
import { KeyedObject } from 'types/root'

// project-import
import IconButton from './IconButton'

// animation function
function TransitionSlideLeft(props: SlideProps) {
  return <Slide {...props} direction='left' />
}

function TransitionSlideUp(props: SlideProps) {
  return <Slide {...props} direction='up' />
}

function TransitionSlideRight(props: SlideProps) {
  return <Slide {...props} direction='right' />
}

function TransitionSlideDown(props: SlideProps) {
  return <Slide {...props} direction='down' />
}

function GrowTransition(props: SlideProps) {
  return <Grow {...props} />
}

// animation options
const animation: KeyedObject = {
  SlideLeft: TransitionSlideLeft,
  SlideUp: TransitionSlideUp,
  SlideRight: TransitionSlideRight,
  SlideDown: TransitionSlideDown,
  Grow: GrowTransition,
  Fade
}

// ==============================|| SNACKBAR ||============================== //

const Snackbar = () => {
  const dispatch = useDispatch()
  const snackbar = useSelector((state) => state.snackbar)
  const { actionButton, anchorOrigin, alert, close, message, open, transition } = snackbar

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(closeSnackbar())
  }

  return (
    <MuiSnackbar
      TransitionComponent={animation[transition]}
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        severity={alert.severity}
        variant={alert.variant}
        color={alert.color}
        action={
          <>
            {actionButton !== false && (
              <Button color={alert.color} size='small' onClick={handleClose}>
                UNDO
              </Button>
            )}
            {close !== false && (
              <IconButton
                size='small'
                aria-label='close'
                variant='contained'
                color={alert.color}
                onClick={handleClose}
              >
                <CloseOutlined />
              </IconButton>
            )}
          </>
        }
        sx={{
          alignItems: 'center',
          '& .MuiAlert-icon': {
            fontSize: '24px'
          },
          '& .MuiAlert-message': {
            flex: 1,
            padding: '8px'
          },
          ...(alert.variant === 'outlined' && {
            bgcolor: 'grey.0'
          })
        }}
      >
        {message.split('\n').map((line, index) => (
          <Fragment key={index}>
            {line}
            <br />
          </Fragment>
        ))}
      </Alert>
    </MuiSnackbar>
  )
}

export default Snackbar

import { useToggle } from '@uidotdev/usehooks'

import Button from '@components/core/button'
import { ChevronDown, ChevronUp, Icon } from '@components/icons'

import { Panel } from '../common/panel'
import { DataPreviewPanel } from './data-preview-panel'

export const UnderlyingDataPanel = () => {
  const [visible, toggleVisible] = useToggle(true)

  if (!visible) {
    return (
      <Panel
        className='shrink-0'
        title='Underlying Data'
        secondary={
          <Button variant='outline' onClick={() => toggleVisible()} className='pr-1'>
            Show <Icon icon={<ChevronUp />} />
          </Button>
        }
      />
    )
  }

  return (
    <div className='flex max-h-64 min-h-64 flex-1'>
      <DataPreviewPanel
        title='Underlying Data'
        secondary={
          <Button variant='outline' onClick={() => toggleVisible()} className='pr-1'>
            Hide <Icon icon={<ChevronDown />} />
          </Button>
        }
      />
    </div>
  )
}

import { useMemo } from 'react'

import { cn } from '@utils/style-utils'

export function Spinner({
  variant = 'default',
  className = ''
}: {
  variant?: 'default' | 'button'
  className?: string
}) {
  const variantClasses = useMemo(
    () => ({
      default: 'size-6',
      button: 'size-4'
    }),
    []
  )

  return (
    <div
      className={cn(
        'rounded-full border-2 border-solid border-white border-t-black',
        variantClasses[variant],
        className
      )}
      role='status'
      style={{ animation: 'spin 0.5s linear infinite' }}
    >
      <span className='sr-only'>Loading...</span>
    </div>
  )
}

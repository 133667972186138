import { Fragment } from 'react'

import { humanizeFieldName } from '@utils/string-utils'

import { DataPill } from '@components/component-management/DataPill'
import { Separator } from '@components/core/separator'
import { EyeOff, Icon } from '@components/icons'
import PinIcon from '@components/icons/pin.svg?react'

import { useSelector } from '@store/index'
import { selectPending } from '@store/slices/component/basic-config'
import { selectColumns } from '@store/slices/component/table-config'

import { PanelSkeleton } from '../panel-skeleton'

export function TablePanelBody() {
  const columns = useSelector(selectColumns)
  const isPendingComponentData = useSelector(selectPending)

  return (
    <div>
      {isPendingComponentData ? (
        <PanelSkeleton />
      ) : (
        <div className='flex flex-col gap-2'>
          {_.map(columns, (column) => (
            <Fragment key={column.field}>
              <div key={column.field} className='flex items-center justify-between'>
                <DataPill label={humanizeFieldName(column.field)} dataType={column.cellDataType} />
                <div className='flex items-center gap-2'>
                  {column.pinned && <PinIcon />}
                  {column.hide && <Icon icon={<EyeOff />} />}
                </div>
              </div>
              <Separator />
            </Fragment>
          ))}
        </div>
      )}
    </div>
  )
}

import axiosServices from 'utils/axios';
import { useMutation } from '@tanstack/react-query';
import { queryErrorToast, querySuccessToast } from '../../../../queries/query-toasts';
import { snakeCaseKeys } from '@utils/case-conversion';

interface IUpdateAlertConfigParams {
  id: number;
  enabled?: boolean;
  emailNotificationsEnabled?: boolean;
  user_groups?: number[];
}

const updateAlertConfig = async (
  businessId: number,
  updatedConfig: IUpdateAlertConfigParams) => {
  const response = await axiosServices.patch(
    `/businesses/${businessId}/alerts/update_business_alert_category_config`,
    {
      business_id: businessId,
      business_alert_category_config: snakeCaseKeys(updatedConfig)
    }
  );
  return response.data;
};

export const useUpdateBusinessAlertCategoryConfig = (businessId: number | undefined) => {
  return useMutation({
    mutationFn: (updatedConfig: IUpdateAlertConfigParams) => updateAlertConfig(businessId!, updatedConfig),
    onSuccess: () => {
      querySuccessToast('Configuration updated.');
    },
    onError: (error) => {
      queryErrorToast(error.message);
    }
  });
};

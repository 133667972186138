import { chargerColors } from 'themes/theme/charger-colors-tw'

export function getDefaultPrimaryColor() {
  return chargerColors.charts.single.color0
}

export function getWaterfallEdgeColor() {
  return chargerColors.charts.two.color1
}

export function getPositiveColor() {
  return chargerColors.charts.positive
}

export function getNegativeColor() {
  return chargerColors.charts.negative
}

export function getBlackColor() {
  return chargerColors.charts.black
}

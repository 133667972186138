import { ColDef } from 'ag-grid-community'

import * as yup from 'yup'

export interface ValidateableColDef extends ColDef {
  validationSchema?: yup.AnySchema
}

export enum ColumnTypes {
  RIGHT_ALIGNED = 'rightAligned',
  NUMERIC = 'numeric'
}

export const AG_RIGHT_ALIGNED_CELL = 'ag-right-aligned-cell'

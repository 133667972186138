import { Frequency } from 'types/filter'

export enum ModuleName {
  root = 'root',
  xyChart = 'xyChart',
  headingContainer = 'headingContainer',
  titleLabel = 'titleLabel',
  categoryXAxis = 'categoryXAxis',
  categoryYAxis = 'categoryYAxis',
  valueXAxis = 'valueXAxis',
  valueYAxis = 'valueYAxis',
  cursor = 'cursor',
  tooltip = 'tooltip',
  freeFloatingTooltip = 'freeFloatingTooltip',
  waterfallSeries = 'waterfallSeries',
  waterfallStepSeries = 'waterfallStepSeries',
  lineSeries = 'lineSeries',
  lineSeriesVertical = 'lineSeriesVertical',
  columnSeries = 'columnSeries',
  barSeries = 'barSeries',
  treeMap = 'treeMap',
  legend = 'legend',
  externalLegend = 'externalLegend',
  externalLegendRoot = 'externalLegendRoot',
  dateXAxis = 'dateXAxis',
  dateYAxis = 'dateYAxis',
  dataProcessor = 'dataProcessor'
}

export type $AM5Ref = any
export type $Data = any

export type AmModules = Partial<{ [key in ModuleName]?: { [id: string]: $AM5Ref } }>

export type AmModulesRef = React.MutableRefObject<AmModules>

export type IChartInstance = { data: any[]; initialized: boolean }

export type IModuleIdReferences = { [key in ModuleName]?: string[] | string }

export type ITooltipPropsData = {
  titleKey?: string
  isDateTitle?: boolean
  title?: string
  valueKey?: string
  valueFormat?: string
  color?: string
}

export type IModuleOptions = {
  [ModuleName.xyChart]: {
    areAxesStacked?: boolean
    flipAxis?: boolean
  }
  [ModuleName.barSeries]: {
    xAxisKey: string
    yAxisKey: string
    name: string
    color?: string
    stacked?: boolean
    layered?: boolean
    numberFormat?: string
    showDataLabels?: boolean
    handleNodeContextMenuOpen?: any
  }
  [ModuleName.categoryXAxis]: {
    xAxisKey: string
    inversed?: boolean
  }
  [ModuleName.categoryYAxis]: {
    yAxisKey: string
    inversed?: boolean
    opposite?: boolean
  }
  [ModuleName.columnSeries]: {
    xAxisKey: string
    yAxisKey: string
    name: string
    color?: string
    stacked?: boolean
    layered?: boolean
    numberFormat?: string
    showDataLabels?: boolean
  }
  [ModuleName.cursor]: {
    flippedAxis?: boolean
  }
  [ModuleName.dateXAxis]: {
    xAxisLabel?: string
    frequency?: Frequency
  }
  [ModuleName.dateYAxis]: {
    yAxisLabel?: string
    frequency?: Frequency
    opposite?: boolean
  }
  [ModuleName.lineSeriesVertical]: {
    xAxisKey: string
    yAxisKey: string
    name: string
    color?: string
    numberFormat?: string
    showDataLabels?: boolean
  }
  [ModuleName.lineSeries]: {
    xAxisKey: string
    yAxisKey: string
    area?: boolean
    name: string
    stacked?: boolean
    color?: string
    numberFormat?: string
    showDataLabels?: boolean
  }
  [ModuleName.tooltip]: {
    data?: ITooltipPropsData[]
    headerKey?: string
    header?: string
    isHeaderHidden?: boolean
    isDateHeader?: boolean
    flippedAxis?: boolean
    frequency?: Frequency
  }
  [ModuleName.freeFloatingTooltip]: {
    data?: ITooltipPropsData[]
    headerKey?: string
    header?: string
    isHeaderHidden?: boolean
    isDateHeader?: boolean
    flippedAxis?: boolean
    frequency?: Frequency
  }
  [ModuleName.valueXAxis]: {
    numberFormat?: string
    xAxisLabel?: string
    opposite?: boolean
    hideGrid?: boolean
    min?: number | null
    max?: number | null
    marginRight?: number
  }
  [ModuleName.valueYAxis]: {
    numberFormat?: string
    yAxisLabel?: string
    opposite?: boolean
    hideGrid?: boolean
    min?: number | null
    max?: number | null
    marginTop?: number
  }
  [ModuleName.waterfallSeries]: {
    xAxisKey: string
    yAxisKey: string
    name?: string
    numberFormat?: string
    stacked?: boolean
    totalLabelKey?: string
    hideTotal?: boolean
  }
  [ModuleName.headingContainer]: {
    title?: string
  }
}

export type IOptions<T = any> = T extends keyof IModuleOptions ? IModuleOptions[T] : any

export type IGlobalOptions = {
  clusteredSeries?: boolean
}

export interface IModuleDefinitionProps {
  id?: string
  references?: IModuleIdReferences
  options?: IOptions
}

export interface IModuleInitProps<T = any> {
  amModulesRef: AmModulesRef
  selector: string
  defn?: IModuleDefinitionProps
  options: IOptions<T> & IGlobalOptions
}

export type IModuleInstanceProps<T = any> = IModuleInitProps<T>
export type IModuleDisposeProps<T = any> = IModuleInitProps<T>

export interface IGlobalInstanceProps {
  amModulesRef: AmModulesRef
}

export type IModuleUseInstance<T = any> = (
  instance: IChartInstance,
  props: IModuleInstanceProps<T>
) => void

export type IGlobalUseInstance = (instance: IChartInstance, props: IGlobalInstanceProps) => void

export interface IModule<T = any> {
  name: ModuleName
  init?: (props: IModuleInitProps<T>) => void
  dispose?: (props: IModuleDisposeProps<T>) => void
  useInstance?: IModuleUseInstance<T>
}

export interface IModuleDefinition extends IModuleDefinitionProps {
  module: IModule
}

export type IModuleDefinitions = Array<IModuleDefinition>

export type ITriggerReinit = () => void

export interface IBaseChartProps {
  data: any[]
  selector: string
  modules: IModuleDefinitions
  options?: IGlobalOptions
  triggerReinit?: ITriggerReinit
}

type IChartPropsWithoutModules = Omit<IBaseChartProps, 'modules'>

export type IGetRefProps = IModuleInstanceProps & { moduleName: ModuleName | ModuleName[] }

export type IAssignRefProps = IModuleInstanceProps & { moduleName: ModuleName; item: $AM5Ref }

export interface IEffectProps {
  propsRef: React.MutableRefObject<IModuleInstanceProps>
  moduleName: ModuleName
}

export interface IInstanceEffectProps extends IEffectProps {
  instance: IChartInstance
}

export interface IPropsEffectProps extends IEffectProps {
  props: IModuleInstanceProps
}

export type IModuleAttributes<T = any> = (props?: {
  options?: IOptions<T>
  id?: string
  references?: IModuleIdReferences
}) => IModuleDefinition

export type IChartPropsWithAnyOptions = IChartPropsWithoutModules & { options: any }

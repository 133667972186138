import { useMemo, useState } from 'react'

import { getRangeInfoFromDateFilterInfo } from '@utils/date-filter-utils'
import {
  getLastMonth,
  getPriorYear,
  getPropsRange,
  getTrailing12Months,
  getYearToDateRange,
  handleFrequencyChange
} from '@utils/date-frequency-utils'
import { enumToOptions } from '@utils/obj-utils'

import Button from '@components/core/button'
import { Separator } from '@components/core/separator'
import { Tabs, TabsList, TabsTrigger } from '@components/core/tab'
import { Text } from '@components/core/text'

import { usePageSelector } from '@store/index'
import { selectDateFilter } from '@store/slices/date-time-filter'

import {
  DateFilterInfo,
  DateInfo,
  DateRange,
  DateRangeType,
  Frequency,
  RangeType,
  YearInfo,
  YearMonthDayInfo,
  YearMonthInfo,
  YearQuarterInfo,
  YearWeekInfo
} from 'types/filter'
import { Month } from 'utils/date-utils'

import DayPicker from './day-picker'
import MonthlyPicker from './monthly-picker'
import QuarterPicker from './quarter-picker'
import WeeklyPicker from './weekly-picker'
import { YearPicker } from './year-picker'

interface CalendarFilterPopoverProps {
  title?: React.ReactNode
  defaultFrequency?: Frequency
  rangeType?: DateRangeType
  fiscalYearStart?: Month
  dateFilterInfo?: DateFilterInfo
  isListMode?: boolean
  onClose: () => void
  onApply: (dateFilterInfo: DateFilterInfo) => void
  frequenciesToOmit?: Frequency[]
  minAllowed?: YearMonthDayInfo
  maxAllowed?: YearMonthDayInfo
}

type DateConfig = Partial<{
  [Frequency.Yearly]: RangeType<YearInfo>
  [Frequency.Monthly]: RangeType<YearMonthInfo>
  [Frequency.Weekly]: RangeType<YearWeekInfo>
  [Frequency.Quarterly]: RangeType<YearQuarterInfo>
  [Frequency.Daily]: RangeType<YearMonthInfo>
}>

const ComponentMap: Record<Frequency, React.FC<any>> = {
  [Frequency.Yearly]: YearPicker,
  [Frequency.Quarterly]: QuarterPicker,
  [Frequency.Monthly]: MonthlyPicker,
  [Frequency.Weekly]: WeeklyPicker,
  [Frequency.Daily]: DayPicker
}

const ComponentRenderMap = {
  [Frequency.Yearly]: (info: YearInfo) => `FY ${info.year}`,
  [Frequency.Quarterly]: (info: YearQuarterInfo) =>
    `${info.quarter}`.toUpperCase() + `, FY ${info.year} `,
  [Frequency.Monthly]: (info: YearMonthInfo) => `${info.month}, FY ${info.year} `,
  [Frequency.Daily]: (info: YearMonthDayInfo) => `${info.month} ${info.day}, FY ${info.year} `
}

interface DateComponentProps {
  frequency: Frequency
  fiscalYearStart?: Month
  value?: DateInfo
  onChange: (value: DateInfo) => void
  render?: (value: DateInfo) => React.ReactNode
}

const DateComponent = (props: DateComponentProps) => {
  const { frequency = Frequency.Monthly, ...rest } = props
  const Component = ComponentMap[frequency]
  return <Component {...rest} />
}

export const CalendarFilterPopover = (props: CalendarFilterPopoverProps) => {
  const {
    dateFilterInfo,
    onApply,
    onClose,
    rangeType,
    isListMode = false,
    frequenciesToOmit = [Frequency.Daily, Frequency.Weekly]
  } = props

  const globalFilter = usePageSelector(selectDateFilter)
  const dateFilter = dateFilterInfo ?? globalFilter?.filterInfo

  const fiscalYearStart = useMemo(() => {
    if (!dateFilter) return
    return props.fiscalYearStart || dateFilter.fiscalYearStart
  }, [dateFilter, props.fiscalYearStart])

  const asOfDate = useMemo(() => {
    if (!dateFilter) return

    return rangeType === DateRangeType.AsOfDate || dateFilter.rangeType === DateRangeType.AsOfDate
  }, [rangeType, dateFilter])

  const [frequency, setFrequency] = useState(dateFilter ? dateFilter.frequency : Frequency.Monthly)

  const [date, setDate] = useState<DateConfig>(() => {
    if (!dateFilter) {
      return {}
    }

    const filterInfo = dateFilter

    if (filterInfo.rangeType === DateRangeType.AsOfDate) {
      return {
        [frequency]: {
          endDate: filterInfo.endDate
        }
      }
    }

    return {
      [frequency]: {
        startDate: filterInfo.startDate,
        endDate: filterInfo.endDate
      }
    }
  })

  const updatePickerDate = (
    frequency: Frequency,
    field: 'startDate' | 'endDate',
    newDate: DateInfo
  ) => {
    setDate({
      ...date,
      [frequency]: {
        ...date[frequency],
        [field]: newDate
      }
    })
  }

  const updateFrequencyDateRange = (frequency: Frequency, newDateRange: DateRange) => {
    setDate({
      ...date,
      [frequency]: newDateRange
    })
  }

  const setYearToDateForFreq = (freq: Frequency) => {
    updateFrequencyDateRange(freq, getYearToDateRange(freq, fiscalYearStart))
  }

  const handleFreqChange = (
    freq: Frequency,
    oldRange: { startDate: DateInfo; endDate: DateInfo; frequency: Frequency }
  ) => {
    const propsRange = getPropsRange(freq, props.minAllowed, props.maxAllowed, fiscalYearStart)
    const newRange = handleFrequencyChange(oldRange, freq, propsRange, fiscalYearStart)
    updateFrequencyDateRange(freq, newRange)
    setFrequency(freq)
  }

  const handleYearToDate = () => {
    const newFreq = Frequency.Monthly
    setYearToDateForFreq(newFreq)
    setFrequency(newFreq)
  }

  const handlePriorYear = () => {
    const newFreq = Frequency.Monthly
    updateFrequencyDateRange(newFreq, getPriorYear(newFreq, fiscalYearStart))
    setFrequency(newFreq)
  }

  const handleTrailing12Months = () => {
    const newFreq = Frequency.Monthly
    updateFrequencyDateRange(newFreq, getTrailing12Months(fiscalYearStart))
    setFrequency(newFreq)
  }

  const handleLastMonth = () => {
    const newFreq = Frequency.Monthly
    updateFrequencyDateRange(newFreq, getLastMonth(fiscalYearStart))
    setFrequency(newFreq)
  }

  const freqDate =
    date[frequency] ||
    (frequency === Frequency.Monthly
      ? getTrailing12Months(fiscalYearStart)
      : getYearToDateRange(frequency, fiscalYearStart))

  const filter: DateFilterInfo = asOfDate
    ? {
        frequency,
        fiscalYearStart: fiscalYearStart,
        rangeType: DateRangeType.AsOfDate,
        endDate: freqDate.endDate
      }
    : {
        frequency,
        fiscalYearStart: fiscalYearStart,
        rangeType: DateRangeType.Range,
        startDate: freqDate.startDate,
        endDate: freqDate.endDate
      }

  const dateRange = getRangeInfoFromDateFilterInfo(filter)
  const handleApply = () => {
    onApply(filter)
  }

  const filteredFrequencies = enumToOptions(Frequency, frequenciesToOmit)

  return (
    <div className='flex min-h-56 w-full flex-1'>
      {!isListMode && (
        <div className='flex basis-44 flex-col bg-grey-background p-4'>
          <div className='flex flex-1 flex-col items-center'>
            <Button variant='outline' className='mb-2 h-7 w-28' onClick={handleYearToDate}>
              Year To Date
            </Button>

            <Button variant='outline' className='mb-2 h-7 w-28' onClick={handlePriorYear}>
              Previous Year
            </Button>
            <Button variant='outline' className='mb-2 h-7 w-28' onClick={handleTrailing12Months}>
              TTM
            </Button>
            <Button variant='outline' className='mb-2 h-7 w-28' onClick={handleLastMonth}>
              Last Month
            </Button>
          </div>
          <div className='flex flex-col items-center'>
            <Separator className='my-2 bg-grey'></Separator>
            <Text variant='details'>Quick Actions</Text>
          </div>
        </div>
      )}
      <div className='flex flex-1 flex-col p-4'>
        <Text variant='h5'>Date Filter</Text>
        <Separator className='my-2 bg-grey-lighter'></Separator>

        <Tabs
          id='date-filter-tabs'
          defaultValue={Frequency.Monthly.toString()}
          className='w-full'
          onValueChange={(freq) => {
            const originalInfo = dateRange.originalInfo
            if (originalInfo.rangeType === DateRangeType.Range) {
              handleFreqChange(Frequency[freq as Frequency], {
                startDate: originalInfo.startDate,
                endDate: originalInfo.endDate,
                frequency: originalInfo.frequency
              })
            } else {
              throw new Error('Unsupported range')
            }
          }}
          value={frequency.toString()}
        >
          <div className='flex w-full justify-between p-2'>
            <div className='flex-1'>
              <TabsList
                className='grid w-full bg-grey-background'
                style={{
                  gridTemplateColumns: `repeat(${filteredFrequencies.length}, minmax(0, 1fr))`
                }}
              >
                {filteredFrequencies.map((freq) => (
                  <TabsTrigger key={freq.value} value={freq.value.toString()}>
                    {freq.value.toString()}
                  </TabsTrigger>
                ))}
              </TabsList>
            </div>
          </div>
        </Tabs>
        <Separator className='my-2 bg-grey-lighter'></Separator>
        <div className='flex items-center justify-between'>
          <Text variant='tableValue' className='flex-1 text-grey-details'>
            Date Range
          </Text>

          {['startDate', 'endDate']
            .filter((dateType) => dateType !== 'startDate' || !asOfDate)
            .flatMap((elem, index) => (index ? ['-', elem] : [elem]))
            .map((dateType) => {
              if (dateType === '-') {
                return (
                  <div className='mx-2 flex items-center' key={dateType}>
                    <span className='text-h5 text-grey-details'>-</span>
                  </div>
                )
              }

              const propsRange = getPropsRange(frequency)

              const commonProps = {
                frequency: frequency,
                fiscalYearStart,
                value: date[frequency]?.[dateType as 'startDate' | 'endDate'],
                minAllowed:
                  dateType === 'endDate' ? date[frequency]?.startDate : propsRange.minAllowed,
                maxAllowed:
                  dateType === 'startDate' ? date[frequency]?.endDate : propsRange.maxAllowed,
                onChange: (info: DateInfo) => updatePickerDate(frequency, dateType as any, info),
                render: fiscalYearStart
                  ? ((ComponentRenderMap as any)[frequency] as (info: DateInfo) => React.ReactNode)
                  : void 0
              }

              return (
                <div key={dateType}>
                  <DateComponent key={dateType} {...commonProps} />
                </div>
              )
            })}
        </div>
        <Separator className='my-2 bg-grey-lighter'></Separator>
        <div className='flex items-center justify-between'>
          <Button variant='outline' onClick={onClose}>
            Cancel
          </Button>
          <Button variant='primary' onClick={handleApply}>
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

import { LayoutProvider } from 'contexts/LayoutContext'

import { LayoutViewProps } from '../../layout-view/types'
import LayoutBuilder from './layout-builder'

const Index = ({ pageSettings }: LayoutViewProps) => {
  return (
    <LayoutProvider>
      <LayoutBuilder pageSettings={pageSettings} />
    </LayoutProvider>
  )
}

export default Index

import { HeaderCellCtrl, HeaderFilterCellCtrl, HeaderGroupCellCtrl } from 'ag-grid-community'

const patch = (cls, method, predicate) => {
  if (cls.prototype[method].charger_MonkeyPatched) {
    return
  }
  const originalFxn = cls.prototype[method]
  cls.prototype[method] = function (...args) {
    if (!predicate(this)) {
      return
    }
    return originalFxn.apply(this, args)
  }
  cls.prototype[method].charger_MonkeyPatched = true
}

patch(HeaderGroupCellCtrl, 'isSuppressMoving', (obj) => !obj.destroyed)
patch(HeaderGroupCellCtrl, 'setDragSource', (obj) => {
  if (obj.destroyed) {
    return false
  }
  return true
})
patch(HeaderCellCtrl, 'getColId', (obj) => !obj.destroyed)
patch(HeaderFilterCellCtrl, 'updateCompDetails', (obj) => !obj.destroyed)

export {}

// returns nearest Monday when the calendar is laid out such that week day starts from Monday
import { YearWeekInfo } from '../types/filter'
import {
  Month,
  addDaysToDate,
  getCalendarYearFromFiscalYear,
  getDaysInPreviousMonth,
  getFiscalYearFromCalendarYear,
  getIndexFromMonth,
  getMonthFromIndex,
  getPreviousMonth
} from './date-utils'

export const convertYMDToUTC = (year: number, month: Month, day: number) =>
  new Date(Date.UTC(year, getIndexFromMonth(month), day))

export const convertUTCDateToYMD = (date: Date) => {
  return {
    year: date.getUTCFullYear(),
    month: getMonthFromIndex(date.getUTCMonth()),
    day: date.getUTCDate()
  }
}

export const nearestThursdayUTC = (year: number, month: Month, day: number) => {
  const date = convertYMDToUTC(year, month, day)
  const dayOfWeek = date.getUTCDay()
  // m t w t f s s
  const adjustedDayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1
  return addDaysToDate(date, 3 - adjustedDayOfWeek)
}

export const getFirstWeek = (year: number, fiscalYearStart?: Month) => {
  const firstMonth = fiscalYearStart || Month.Jan
  const calendarYear = getCalendarYearFromFiscalYear(year, firstMonth, fiscalYearStart)
  const thursday = nearestThursdayUTC(calendarYear, firstMonth, 1)
  if (getIndexFromMonth(firstMonth) !== thursday.getUTCMonth()) {
    return addDaysToDate(thursday, 7)
  }

  return thursday
}

export const getWeekNumber = (year: number, month: Month, day: number, fiscalYearStart?: Month) => {
  // Parse the date as UTC
  const calendarYear = getCalendarYearFromFiscalYear(year, month, fiscalYearStart)

  const thursday = nearestThursdayUTC(calendarYear, month, day)
  const idYear = getFiscalYearFromCalendarYear(
    thursday.getUTCFullYear(),
    getMonthFromIndex(thursday.getUTCMonth()),
    fiscalYearStart
  )
  const week1 = getFirstWeek(idYear, fiscalYearStart)

  return {
    week: Math.ceil(((thursday.getTime() - week1.getTime()) / 86400000 + 1) / 7),
    year: idYear
  }
}

// returns the maximum week in a given year or fiscal year
export const getMaxWeek = (year: number, fiscalYearStart?: Month) => {
  const lastMonthOfYear = getPreviousMonth(fiscalYearStart || Month.Jan)
  const day = getDaysInPreviousMonth(year, lastMonthOfYear, fiscalYearStart)
  const week = getWeekNumber(year, lastMonthOfYear, day, fiscalYearStart)
  const id = isoWeekId(week.year, week.week, fiscalYearStart)
  if (id.month === lastMonthOfYear) {
    return week
  }
  return getWeekNumber(
    getFiscalYearFromCalendarYear(id.year, id.month, fiscalYearStart),
    id.month,
    id.day - 7,
    fiscalYearStart
  )
}

// this function normalizes all the days to the nearest thursday, where a week starts from Monday
export const isoWeekId = (yearNum: number, weekNum: number, fiscalYearStart?: Month) => {
  const firstWeek = getFirstWeek(yearNum, fiscalYearStart)
  const daysToAdd = (weekNum - 1) * 7
  const resultDay = addDaysToDate(firstWeek, daysToAdd)
  return convertUTCDateToYMD(resultDay)
}

export const isoWeekToCalendarDate = (
  yearNum: number,
  weekNum: number,
  fiscalYearStart?: Month
) => {
  if (weekNum < 0) {
    throw new Error(`Week num cannot be less than zero ${weekNum}`)
  }

  const id = isoWeekId(yearNum, weekNum, fiscalYearStart)
  const resultDay = addDaysToDate(convertYMDToUTC(id.year, id.month, id.day), -3)

  return {
    id,
    ...convertUTCDateToYMD(resultDay)
  }
}

export const isoWeekToCalendarEndDate = (
  yearNum: number,
  weekNum: number,
  fiscalYearStart?: Month
) => {
  const weekStart = isoWeekToCalendarDate(yearNum, weekNum, fiscalYearStart)
  const endResult = addDaysToDate(
    convertYMDToUTC(weekStart.year, weekStart.month, weekStart.day),
    6
  )
  return convertUTCDateToYMD(endResult)
}

export const cmpInfo = (info1: YearWeekInfo, info2: YearWeekInfo) => {
  if (info1.year !== info2.year) return info1.year - info2.year
  return info1.week - info2.week
}

export const getNextWeek = (year: number, week: number, fiscalYearStart?: Month) => {
  if (cmpInfo({ year, week }, getMaxWeek(year, fiscalYearStart)) === 0) {
    return { year: year + 1, week: 1 }
  }
  return { year, week: week + 1 }
}

export const getPrevWeek = (yearNum: number, weekNum: number, fiscalYearStart?: Month) => {
  if (weekNum === 1) {
    return getMaxWeek(yearNum - 1, fiscalYearStart)
  } else {
    return { year: yearNum, week: weekNum - 1 }
  }
}

export const getLastWeekOfPreviousMonth = (
  yearNum: number,
  weekNum: number,
  fiscalYearStart?: Month
) => {
  const valueMonth = isoWeekToCalendarDate(yearNum, weekNum, fiscalYearStart).id.month
  let week = { year: yearNum, week: weekNum }
  let continueLoop = true

  while (continueLoop) {
    week = getPrevWeek(week.year, week.week, fiscalYearStart)
    const weekVal = isoWeekToCalendarDate(week.year, week.week, fiscalYearStart)
    if (weekVal.id.month !== valueMonth) {
      continueLoop = false
      return week
    }
  }
  // eslint-disable-next-line no-unreachable
  throw new Error('Unreachable')
}

export const getFirstWeekOfNextMonth = (
  yearNum: number,
  weekNum: number,
  fiscalYearStart?: Month
) => {
  const valueMonth = isoWeekToCalendarDate(yearNum, weekNum, fiscalYearStart).id.month
  let week = { year: yearNum, week: weekNum }
  let continueLoop = true

  while (continueLoop) {
    week = getNextWeek(week.year, week.week, fiscalYearStart)
    const weekVal = isoWeekToCalendarDate(week.year, week.week, fiscalYearStart)
    if (weekVal.id.month !== valueMonth) {
      continueLoop = false
      return week
    }
  }
  // eslint-disable-next-line no-unreachable
  throw new Error('Unreachable')
}

export const getWeekColumnLabel = (date_string: string) => {
  if (!date_string) {
    return ''
  }

  const [year, month, day] = date_string.split('-')

  const weekNo = getWeekNumber(+year, getMonthFromIndex(+month - 1), +day)

  return `${weekNo.year % 100}-W${weekNo.week}`
}

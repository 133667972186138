import React, { ReactNode } from 'react'

import {
  DndContext,
  DragEndEvent,
  DraggableAttributes,
  Modifier,
  closestCenter
} from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'

import useDefaultSensors from 'components/tree/useDefaultSensors'

import SortableItem from './sortable-item'

export type RenderItemParams<T> = {
  draggableProps: DraggableAttributes | (SyntheticListenerMap | undefined)
  setNodeRef: any
  style: any
  id: string | number
  item: T
}
export type RenderItem<T> = (params: RenderItemParams<T>) => React.JSX.Element

const DefaultWrapperComponent = ({ children }: { children: ReactNode }) => (
  <div className='flex flex-col gap-2'>{children}</div>
)

interface Props<T> {
  items: T[]
  setItems: (items: T[]) => void
  vertical?: boolean
  renderItem: RenderItem<T>
  WrapperComponent?: ({ children }: { children: ReactNode }) => React.JSX.Element
  idKey?: keyof T
  dndContextModifers?: Modifier[]
}

export function SortableList<T extends { [key in keyof T]: any }>({
  items,
  setItems,
  vertical = true,
  renderItem,
  WrapperComponent = DefaultWrapperComponent,
  idKey = 'id' as keyof T, // Default value for idKey
  dndContextModifers = [] as Modifier[]
}: Props<T>) {
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (over && active.id !== over.id) {
      const itemsCopy = [...items]
      const oldIndex = itemsCopy.findIndex((x: any) => x[idKey] === active.id)
      const newIndex = itemsCopy.findIndex((x: any) => x[idKey] === over.id)
      setItems(arrayMove(itemsCopy, oldIndex, newIndex))
    }
  }

  const sensors = useDefaultSensors(sortableKeyboardCoordinates)

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={dndContextModifers}
    >
      <SortableContext
        items={items.map((item) => item[idKey])}
        strategy={vertical ? verticalListSortingStrategy : horizontalListSortingStrategy}
      >
        <WrapperComponent>
          {items.map((item) => (
            <SortableItem<T>
              key={item[idKey]}
              item={item}
              renderItem={renderItem}
              id={item[idKey]}
            />
          ))}
        </WrapperComponent>
      </SortableContext>
    </DndContext>
  )
}

import { useEffect } from 'react'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

import { useLatestRef } from 'hooks/useLatestRef'

import { IModule, IModuleAttributes, IModuleUseInstance, ModuleName } from '../types/module-types'
import {
  getBlackColor,
  getNegativeColor,
  getPositiveColor,
  getWaterfallEdgeColor
} from '../utils/color-utils'
import { assignRef, getRef, getSelfRef } from '../utils/module-utils'

const moduleName = ModuleName.waterfallSeries
const waterfallSeriesModule: IModule<ModuleName.waterfallSeries> = {
  name: moduleName
}

waterfallSeriesModule.init = (props) => {
  const root: am5.Root = getRef({ ...props, moduleName: ModuleName.root })
  const chart: am5xy.XYChart = getRef({ ...props, moduleName: ModuleName.xyChart })
  const xAxis: am5xy.CategoryAxis<am5xy.AxisRenderer> = getRef({
    ...props,
    moduleName: ModuleName.categoryXAxis
  })
  const yAxis: am5xy.ValueAxis<am5xy.AxisRenderer> = getRef({
    ...props,
    moduleName: ModuleName.valueYAxis
  })

  const {
    options: {
      xAxisKey,
      yAxisKey,
      name,
      numberFormat = '#.#a',
      stacked = false,
      totalLabelKey,
      hideTotal = false
    }
  } = props

  const colorEdge = getWaterfallEdgeColor()
  const colorRed = getNegativeColor()
  const colorGreen = getPositiveColor()

  const openKey = `${yAxisKey}_open`
  const closeKey = `${yAxisKey}_close`
  const stepKey = `${yAxisKey}_step`

  const totalKey = totalLabelKey || yAxisKey
  const totalOpenKey = `${totalKey}_open`
  const totalCloseKey = `${totalKey}_close`

  const series = chart.series.push(
    am5xy.ColumnSeries.new(root, {
      name,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: closeKey,
      openValueYField: openKey,
      categoryXField: xAxisKey,
      interpolationDuration: 2000,
      interpolationEasing: am5.ease.inOut(am5.ease.elastic),
      clustered: !stacked,
      maskBullets: false
    })
  )

  series.columns.template.setAll({
    fill: am5.color(colorGreen),
    fillOpacity: stacked ? 0.4 : 1
  })

  series.columns.template.adapters.add('fill', function (fill, target: any) {
    const diff = target.dataItem.get('valueY') - target.dataItem.get('openValueY')
    const isEdgeLabels = ['begin', 'end'].includes(target.dataItem.dataContext['identifier'])

    if (isEdgeLabels) {
      return am5.color(colorEdge)
    } else if (diff < 0) {
      return am5.color(colorRed)
    } else {
      return fill
    }
  })

  if (!hideTotal) {
    series.bullets.push(function (_root, _series, dataItem: any) {
      const diff =
        (dataItem.dataContext[totalCloseKey] || 0) - (dataItem.dataContext[totalOpenKey] || 0)
      // custom formatter
      return am5.Bullet.new(root, {
        locationY: _.lt(diff, 0) ? 0 : 1,
        sprite: am5.Label.new(root, {
          text: root.numberFormatter.format(diff!, numberFormat),
          centerY: am5.p50,
          centerX: am5.p50,
          populateText: true,
          dy: -15,
          fontSize: '11px'
        })
      })
    })
  }

  series.columns.template.adapters.add('stroke', function (stroke, target: any) {
    const diff = target.dataItem.get('valueY') - target.dataItem.get('openValueY')
    const isEdgeLabels = ['begin', 'end'].includes(target.dataItem.dataContext['identifier'])

    if (isEdgeLabels) {
      return am5.color(colorEdge)
    } else if (diff < 0) {
      return am5.color(colorRed)
    } else {
      return am5.color(colorGreen)
    }
  })

  const stepSeries = chart.series.push(
    am5xy.StepLineSeries.new(root, {
      visible: !hideTotal,
      name,
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: stepKey,
      categoryXField: xAxisKey,
      noRisers: true,
      locationX: 0.65,
      stroke: am5.color(getBlackColor()),
      interpolationDuration: 2000,
      interpolationEasing: am5.ease.inOut(am5.ease.elastic)
    })
  )

  stepSeries.strokes.template.setAll({
    strokeOpacity: 0.5
  })

  assignRef({ ...props, moduleName, item: series })
  assignRef({ ...props, moduleName: ModuleName.waterfallStepSeries, item: stepSeries })
}

const useInstance: IModuleUseInstance<ModuleName.waterfallSeries> = (instance, props) => {
  const propsRef = useLatestRef(props)

  useEffect(() => {
    const series = getSelfRef({ ...propsRef.current, moduleName })
    const stepSeries = getSelfRef({
      ...propsRef.current,
      moduleName: ModuleName.waterfallStepSeries
    })
    series.data.setAll(instance.data)
    stepSeries.data.setAll(instance.data)
  }, [instance.data, propsRef])
}

waterfallSeriesModule.useInstance = useInstance

export const waterfallSeriesAttributes: IModuleAttributes<ModuleName.waterfallSeries> = (p) => ({
  module: waterfallSeriesModule,
  ...p
})

export default waterfallSeriesModule

import { ReactNode } from 'react'

import { cn } from '@utils/style-utils'

export function Wrapper({
  children,
  scrollable = false,
  noPadding = false
}: {
  children: ReactNode
  scrollable?: boolean
  noPadding?: boolean
}) {
  const renderChildren = () => {
    if (scrollable) {
      return <div className={cn('size-full overflow-auto', !noPadding && 'p-4')}>{children}</div>
    }
    return children
  }

  return (
    <div className={cn('size-full', !scrollable && !noPadding && 'p-4')}>{renderChildren()}</div>
  )
}

import { RefObject, useCallback, useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import { useParams } from 'react-router-dom'

import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormHelperText,
  Grid,
  InputLabel,
  Stack,
  Switch,
  TextField
} from '@mui/material'

import { CloseOutlined } from '@ant-design/icons'

import axiosServices from '../../../utils/axios'

function useInputRef() {
  return useOutletContext<RefObject<HTMLInputElement>>()
}

const FeatureForm = ({
  errors,
  setValues,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  touched,
  values,
  action
}: any) => {
  const { id } = useParams()
  const [availableUsers, setAvailableUsers] = useState<any>([])
  const [allowedUsers, setAllowedUsers] = useState<any>([])

  const inputRef = useInputRef()

  const handleEnableChanged = useCallback(
    (currentValue: any) => {
      setFieldValue('enabled', !currentValue)
    },
    [setFieldValue]
  )

  const handleUserSelectionChange = (event: any, newValue: any) => {
    const usersString = newValue.join(',')
    setAllowedUsers(newValue)
    setFieldValue('allowed_users', usersString)
  }

  useEffect(() => {
    const initialValues = {
      id: '',
      name: '',
      description: '',
      allowed_users: '',
      enabled: false,
      submit: null
    }

    axiosServices
      .get(`/users.json?page=1&page_size=100`)
      .then((response) => {
        setAvailableUsers(response.data.map((item: { email: any }) => item.email))
      })
      .catch((error) => {})

    if (id) {
      axiosServices
        .get(`features/${id}.json`)
        .then((response: any) => {
          const usersArray = response.data.feature.allowed_users.split(',')
          setAllowedUsers(usersArray)

          setValues({ ...initialValues, ...response.data.feature })
        })
        .catch((error) => {
          setValues(initialValues)
        })
    }
  }, [id, setValues])

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Box sx={{ p: 2.5 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor='feature-name'>Feature Name</InputLabel>
              <TextField
                fullWidth
                id='feature-name'
                value={values.name}
                name='name'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder='Name your feature'
                autoFocus
                inputRef={inputRef}
              />
              {touched.name && errors.name && (
                <FormHelperText error id='feature-name-helper'>
                  {errors.name}
                </FormHelperText>
              )}
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sx={{ marginTop: '20px' }}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor='feature-desc'>Description</InputLabel>
              <TextField
                fullWidth
                id='feature-desc'
                value={values.description ?? ''}
                name='description'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder='This feature does xyz'
              />
              {touched.description && errors.description && (
                <FormHelperText error id='feature-desc-helper'>
                  {errors.description}
                </FormHelperText>
              )}
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sx={{ marginTop: '20px' }}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor='feature-enabled'>Enable</InputLabel>
              <Switch
                id='feature-enabled'
                checked={values.enabled}
                onChange={() => handleEnableChanged(values.enabled)}
                name='enable'
                color='primary'
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sx={{ marginTop: '20px' }}>
            <Stack spacing={1.25}>
              <InputLabel htmlFor='select-users'>
                Allowed Users ( Leave Blank to Allow All ){' '}
              </InputLabel>
              <Autocomplete
                multiple
                id='select-users'
                options={availableUsers}
                value={allowedUsers}
                onBlur={handleBlur}
                getOptionLabel={(label) => label}
                onChange={handleUserSelectionChange}
                renderInput={(params) => (
                  <TextField {...params} name='user' placeholder='Add User' />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      variant='combined'
                      label={option}
                      deleteIcon={<CloseOutlined style={{ fontSize: '0.75rem' }} />}
                      sx={{ color: 'text.primary' }}
                    />
                  ))
                }
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ pt: 2.5 }}>
          <Grid item xs={12} md={3} sx={{ marginTop: '20px' }}>
            <Stack spacing={1.25}>
              <Button
                fullWidth={true}
                disabled={isSubmitting || Object.keys(errors).length !== 0}
                type='submit'
                variant='contained'
              >
                Save
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </form>
  )
}

export default FeatureForm

// material-ui
import { Theme } from '@mui/material/styles'

// ==============================|| OVERRIDES - ICON BUTTON ||============================== //

export default function IconButton(theme: Theme) {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          border: '1px solid ' + theme.palette.grey[200],
          borderRadius: 4,
          padding: '0.38rem 0.5rem',
          color: theme.palette.primary.darker,
          '&.MuiIconButton-colorPrimary': {
            color: theme.palette.primary.main
          },
          '& .MuiSvgIcon-root': {
            color: 'inherit'
          },
          '&:hover': {
            background: theme.palette.grey[200],
            border: '1px solid ' + theme.palette.primary.darker
          },
          '&:focus': {
            color: '#fff',
            border: '1px solid ' + theme.palette.primary.darker,
            background: theme.palette.primary.main,
            boxShadow: '0 0 0 1px ' + theme.palette.primary.darker + ' inset'
          },
          '&.Mui-focused': {
            color: '#fff',
            border: '1px solid ' + theme.palette.primary.darker,
            background: theme.palette.primary.main,
            boxShadow: '0 0 0 1px ' + theme.palette.primary.darker + ' inset'
          },
          '&:active:focus': {
            color: '#fff',
            background: theme.palette.primary.darker,
            border: '1px solid ' + theme.palette.primary.darker
          }
        },
        sizeLarge: {
          width: theme.spacing(5.5),
          height: theme.spacing(5.5),
          fontSize: '1.25rem'
        },
        sizeMedium: {
          width: '2.25rem',
          height: '2.25rem',
          fontSize: '1rem'
        },
        sizeSmall: {
          width: theme.spacing(3.75),
          height: theme.spacing(3.75),
          fontSize: '1.25rem'
        },
        sizeExtraSmall: {
          width: '1.25rem',
          height: '1.25rem',
          fontSize: '1rem'
        },
        sizeActionBar: {
          width: '1.75rem',
          height: '1.75rem',
          fontSize: '1.5rem'
        },
        sizeActionBarNoSelection: {
          width: '1.75rem',
          height: '1.75rem',
          fontSize: '1.5rem',
          '&:focus': {
            border: 'none !important',
            color: theme.palette.primary.darker,
            background: 'transparent',
            boxShadow: '0 0 0 1px ' + theme.palette.grey[200] + ' inset'
          }
        },
        sizeSideBarSelection: {
          width: '1.5rem',
          height: '1.5rem',
          fontSize: '1rem',
          color: theme.palette.primary.main,
          '&:focus': {
            border: 'none !important',
            color: theme.palette.primary.main,
            background: 'transparent',
            boxShadow: '0 0 0 1px ' + theme.palette.grey[200] + ' inset'
          }
        }
      }
    }
  }
}

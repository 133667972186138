import { IDataType } from './component-types'
import { IResultRow } from './shared-types'

export enum SeriesType {
  Line = 'Line',
  Bar = 'Bar',
  Area = 'Area',
  Waterfall = 'Waterfall'
}

export const isSeriesTypeLine = (series: Partial<IAxis>) =>
  _.isEqual(series.seriesType, SeriesType.Line)
export const isSeriesTypeBar = (series: Partial<IAxis>) =>
  _.isEqual(series.seriesType, SeriesType.Bar)
export const isSeriesTypeArea = (series: Partial<IAxis>) =>
  _.isEqual(series.seriesType, SeriesType.Area)
export const isSeriesTypeWaterfall = (series: Partial<IAxis>) =>
  _.isEqual(series.seriesType, SeriesType.Waterfall)

export enum AxisPosition {
  Normal = 'Normal',
  Opposite = 'Opposite'
}

export enum ValueFormattingType {
  Amount = 'Amount',
  Percent = 'Percent',
  Custom = 'Custom'
}

export enum TooltipHeadingType {
  Hidden = 'Hidden',
  FromBaseAxis = 'FromBaseAxis',
  Custom = 'Custom'
}

export enum TooltipTitleType {
  FromSeries = 'FromSeries',
  FromBaseAxis = 'FromBaseAxis',
  Custom = 'Custom'
}

export enum ChartSubtitleType {
  UsePeriodLabel = 'UsePeriodLabel',
  UseTwoPeriodLabel = 'UseTwoPeriodLabel',
  Hidden = 'Hidden',
  Custom = 'Custom'
}

export enum BucketLabelsType {
  FromData = 'From Data',
  CustomDefinition = 'Custom Definition'
}

export enum BucketLabelType {
  UsePeriodLabel = 'Use Period Label',
  Formula = 'Formula',
  FromSelectedKPI = 'From Selected KPI'
}

export enum BucketValueType {
  KPINode = 'KPI Node',
  Formula = 'Formula'
}

export enum StackingType {
  NONE = 'None',
  STACKED = 'Stacked',
  LAYERED = 'Layered'
}

export const isStackingTypeStacked = (type: StackingType) => _.isEqual(type, StackingType.STACKED)
export const isStackingTypeNone = (type: StackingType) => _.isEqual(type, StackingType.NONE)
export const isStackingTypeLayered = (type: StackingType) => _.isEqual(type, StackingType.LAYERED)

export enum LegendType {
  FromSeries = 'From Series',
  FullPeriod = 'Full Period',
  FuturePeriod = 'Future Period',
  PastPeriod = 'Past Period',
  Custom = 'Custom'
}

export interface IAxisBase {
  id: string
  label: string
  data_type?: IDataType
  name?: string
  legendType?: LegendType
  legendNameCustom?: string
  previous_period?: boolean
  formula?: boolean
  is_hidden?: boolean
}

export interface BucketEdgeNodeType {
  label_type?: BucketLabelType
  label_formula?: string
  label_formula_data_type?: IDataType
  value_type?: BucketValueType
  value_formula?: string
  value_formula_data_type?: IDataType
  value_kpi_node?: string
}

export interface BucketIntermediateNodeType {
  id?: string
  name?: string
  value?: string
  label_formula?: string
  label_formula_data_type?: IDataType
  value_formula?: string
  value_formula_data_type?: IDataType
}

interface WaterfallBucketConfig {
  begin?: BucketEdgeNodeType
  intermediate_nodes_type?: BucketLabelsType
  intermediate_nodes?: BucketIntermediateNodeType[]
  end?: BucketEdgeNodeType
}

export interface IAxisConfig extends WaterfallBucketConfig {
  seriesType?: SeriesType | ''
  axisPosition?: string
  stackedAxisPosition?: string
  breakdown?: string
  stacking?: StackingType
  aggregate?: string
  value_formatting_type?: ValueFormattingType
  custom_value_short?: string
  custom_value_long?: string
  axis_limit_min?: number
  axis_limit_max?: number
  tooltip_override?: boolean
  tooltip_title_type?: TooltipTitleType
  tooltip_title_custom?: string
  data_labels_override?: boolean
  show_data_labels?: boolean
  data_labels_hook?: boolean
}

export type IAxis = IAxisBase & IAxisConfig

export type IChartSettings = {
  chart_subtitle_type?: ChartSubtitleType
  chart_subtitle_custom?: string
  tooltipFreeFloating?: boolean
  tooltip_heading_type?: TooltipHeadingType
  tooltip_heading_custom?: string
  tooltip_title_type?: TooltipTitleType
  tooltip_title_custom?: string
  show_data_labels?: boolean
  data_labels_hook?: boolean
  stackAxes?: boolean
  seriesStacking?: StackingType
  reverseStackingOrder?: boolean
}

export interface IChartConfig {
  xAxes: IAxis[]
  yAxes: IAxis[]
  flipAxis: boolean
  settings: IChartSettings
}

export interface IChartActions {
  openChartNodeContextMenu: (
    key: string,
    data: IResultRow,
    mousePosition: {
      clientX: number
      clientY: number
    }
  ) => void
}

export type IChartAdditionalMetadata = Partial<{
  showDataLabels: boolean
  showChartGridLines: boolean
}>

import React, { HTMLAttributes, forwardRef, useContext, useMemo, useState } from 'react'

import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

import { UniqueIdentifier } from '@dnd-kit/core'

import Button from '@components/core/button'
import { Switch } from '@components/core/switch'
import Tooltip from '@components/core/tooltip'
import { ConfigureTrialBalanceContext } from '@components/financial/gl-mappings/components/working-hierarchy'
import { useUpdateHierarchyNodeTags } from '@components/financial/shared/queries/update-hierarchy-node-tags'
import { ChevronDown, ChevronUp, Icon, Pencil, Plus } from '@components/icons'

import clsx from 'clsx'
import {
  ActionTypeEnum,
  TreeItemIdentifier,
  TreeItem as TreeItemType,
  TreeSource,
  TreeSourceEnum
} from 'components/tree/types'

import DeleteConfirmation from '../delete-confirmation'
import { TreeDeletePopover } from './tree-delete-popover'
import { TreeFormPopover } from './tree-form-popover'
import { TreeItemForm } from './tree-item-form'

import styles from './TreeItem.module.css'

export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, 'id'> {
  item: TreeItemType
  source?: TreeSource
  identifier?: TreeItemIdentifier
  id: UniqueIdentifier
  childCount?: number
  clone?: boolean
  collapsed?: boolean
  depth: number
  disableInteraction?: boolean
  disableSelection?: boolean
  ghost?: boolean
  handleProps?: any
  indicator?: boolean
  indentationWidth: number
  value: string
  onCollapse?(): void
  wrapperRef?(node: HTMLLIElement): void
  editable?: boolean
  sortable?: boolean
  showEdit?: boolean
  onItemClick?: (id: UniqueIdentifier) => void
  onNodeEdit?: (nodeId: number, item: TreeItemType) => void
  onNodeCreate?: ({ parentNodeId, item }: { parentNodeId?: number; item: TreeItemType }) => void
  onNodeDelete?: (nodeId: number) => void
  selectedNodeId?: UniqueIdentifier
  treeItemSecondaryUtils?: (item: TreeItemType, source: TreeSource | undefined) => any
  treeItemClasses?: (item: TreeItemType) => any
}

export const TreeItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      item,
      source,
      identifier,
      id,
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      collapsed,
      onCollapse,
      style,
      value,
      wrapperRef,
      editable,
      showEdit = true,
      sortable,
      onItemClick,
      onNodeEdit,
      onNodeDelete,
      onNodeCreate,
      selectedNodeId,
      treeItemClasses,
      treeItemSecondaryUtils,
      ...props
    },
    ref
  ) => {
    const [isEditing, setIsEditing] = useState(false)
    const [isAdding, setIsAdding] = useState(false)
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)

    const { editingTrialBalance } = useContext(ConfigureTrialBalanceContext)
    const { mutate: updateTags } = useUpdateHierarchyNodeTags()

    const canAddChild = useMemo(() => {
      if (!source || !identifier) return false

      switch (source) {
        case TreeSourceEnum.FinancialReport:
        case TreeSourceEnum.GeneralLedgerAccount:
          return !['spacer', 'formula'].includes(identifier)
        case TreeSourceEnum.Page:
          return !['page', 'static_page'].includes(identifier)
      }
    }, [source, identifier])

    const onLocalItemClick = () => {
      if (onItemClick || selectedNodeId) {
        if (isEditing || isAdding || editingTrialBalance) return

        onItemClick && onItemClick(id)
      }
    }

    const SecondaryUtils =
      !isEditing && treeItemSecondaryUtils ? treeItemSecondaryUtils(item, source) : null

    return (
      <div className='min-w-full'>
        <li
          onClick={() => onLocalItemClick()}
          className={clsx(
            styles.Wrapper,
            clone && styles.clone,
            ghost && styles.ghost,
            indicator && styles.indicator,
            disableSelection && styles.disableSelection,
            disableInteraction && styles.disableInteraction
          )}
          ref={wrapperRef}
          style={
            {
              '--spacing': `${indentationWidth * depth}px`
            } as React.CSSProperties
          }
          {...props}
        >
          <div
            className={clsx(styles.TreeItem, treeItemClasses && treeItemClasses(item))}
            ref={ref}
            style={{ ...style, height: isEditing ? '64px' : '30px' }}
          >
            {sortable && !isEditing && (
              <Button
                {...handleProps}
                variant='text'
                size='extra-small'
                className='rounded-[4px] p-1 focus:border-hidden'
              >
                <Icon icon={<DragIndicatorIcon />} className='text-grey'></Icon>
              </Button>
            )}
            {onCollapse && !isEditing && (
              <Button
                size='extra-small'
                variant='outline'
                className='border-none bg-transparent p-0 text-primary focus:border-hidden'
                onClick={(event) => {
                  event?.stopPropagation()
                  onCollapse()
                }}
              >
                <Icon icon={collapsed ? <ChevronDown /> : <ChevronUp />} />
              </Button>
            )}

            {!isEditing && <span className={styles.Text}>{value}</span>}

            {editable && !editingTrialBalance && (
              <div className='flex items-center gap-2'>
                <>{SecondaryUtils}</>

                {editable && showEdit && !isEditing && (
                  <Tooltip title='Edit'>
                    <Button
                      variant='ghost'
                      className='rounded-[4px] p-0 focus:border-hidden'
                      size='extra-small'
                      onClick={(event) => {
                        event?.stopPropagation()
                        setIsEditing(true)
                      }}
                    >
                      <Icon icon={<Pencil />} className='text-primary-darker' />
                    </Button>
                  </Tooltip>
                )}

                {editable && !isEditing && (
                  <TreeFormPopover
                    item={item}
                    canAddChild={canAddChild}
                    isAdding={isAdding}
                    source={source}
                    onCreate={onNodeCreate}
                    onEdit={onNodeEdit}
                    action={ActionTypeEnum.createChildNode}
                    afterSubmit={() => setIsAdding(false)}
                    onCancel={() => setIsAdding(false)}
                  >
                    <Button
                      variant='text'
                      className='size-6 rounded-[4px] p-0 hover:bg-transparent focus:border-grey'
                      disabled={!canAddChild}
                      onClick={(event) => {
                        event.stopPropagation()
                        event.preventDefault()
                      }}
                    >
                      <Tooltip title='Add Child'>
                        <Icon
                          icon={<Plus />}
                          className={canAddChild && !isAdding ? 'text-primary-darker' : 'text-grey'}
                        />
                      </Tooltip>
                    </Button>
                  </TreeFormPopover>
                )}

                {!clone && !isEditing && onNodeDelete && (
                  <TreeDeletePopover nodeId={id} label={value} onConfirm={onNodeDelete} />
                )}
              </div>
            )}

            {editingTrialBalance && (
              <Switch
                checked={!item.tags?.exclude_from_trial_balance}
                onCheckedChange={(checked: boolean) =>
                  updateTags({
                    nodeId: item.id as number,
                    tags: { exclude_from_trial_balance: !checked }
                  })
                }
              ></Switch>
            )}

            <div className='flex'>
              {isEditing && (
                <TreeItemForm
                  item={item}
                  action={ActionTypeEnum.editNode}
                  source={source}
                  onCancel={() => setIsEditing(false)}
                  onEdit={onNodeEdit}
                  afterSubmit={() => setIsEditing(false)}
                />
              )}
            </div>

            {clone && childCount && childCount > 1 ? (
              <span className={styles.Count}>{childCount}</span>
            ) : null}
          </div>

          {isAdding && (
            <div
              style={{
                marginLeft: `${indentationWidth}px`
              }}
            >
              <TreeItemForm
                item={item}
                source={source}
                action={ActionTypeEnum.createChildNode}
                onCancel={() => setIsAdding(false)}
                onCreate={onNodeCreate}
                afterSubmit={() => setIsAdding(false)}
              />
            </div>
          )}
        </li>
        {onNodeDelete && (
          <DeleteConfirmation
            open={openDeleteConfirmation}
            setOpen={setOpenDeleteConfirmation}
            nodeId={id}
            label={value}
            onConfirm={onNodeDelete}
          />
        )}
      </div>
    )
  }
)

import AgGridSearchInput from '@components/control-panel/ag-grid-search-input'

import { Separator } from 'components/core/separator'

import { AddApplicationConnectionForm } from './add-application-connection-form'
import { AddApplicationForm } from './add-application-form'
import { ConnectedSourceSystemAllBusinessesList } from './connected-source-system-all-businesses-list'

export const ChargerOpsConnectionDashboard = () => {
  return (
    <div className='flex flex-col gap-2'>
      <div className='flex min-h-11 w-full items-center space-x-2'>
        <AgGridSearchInput inputSize={'extra-small'} />
        <Separator orientation='vertical' className='h-10 text-grey-lighter' />
        <AddApplicationForm />
        <AddApplicationConnectionForm />
      </div>
      <Separator className='w-full' />
      <ConnectedSourceSystemAllBusinessesList />
    </div>
  )
}

import { IModuleDefinition } from '@components/chart/types/module-types'

import { categoryXAxisAttributes } from 'components/chart/modules/category-x-axis'
import { categoryYAxisAttributes } from 'components/chart/modules/category-y-axis'
import { dateXAxisAttributes } from 'components/chart/modules/date-x-axis'
import { dateYAxisAttributes } from 'components/chart/modules/date-y-axis'
import { valueXAxisAttributes } from 'components/chart/modules/value-x-axis'
import { valueYAxisAttributes } from 'components/chart/modules/value-y-axis'
import { IDataType } from 'pages/component-management/types/component-types'
import { Frequency } from 'types/filter'
import { _excludes } from 'utils/lodash'

import { AxisPosition, IAxis, IChartSettings } from '../../types/chart-builder-types'
import { areAxesStacked as areAxesStackedUtil, getValueFormatString } from './utils'

const STACK_AXES_MARGIN = 40

class BaseAxisModule {
  public moduleData: IModuleDefinition | undefined

  constructor(
    public axis: IAxis,
    public flipAxis: boolean,
    public axisIndex: number,
    public frequency: Frequency,
    public showChartGridLines: boolean,
    public settings: IChartSettings
  ) {
    this.moduleData = this._makeModuleData()
  }

  _makeModuleData(): IModuleDefinition | undefined {
    throw new Error('Not implemented')
  }

  get label() {
    return this.axis.label
  }

  get axisId() {
    return _.get(this.moduleData, 'id', '')
  }

  get moduleName() {
    return _.get(this.moduleData, ['module', 'name'], '')
  }

  get originalId() {
    return this.axis.id
  }

  get axisDataType() {
    return this.axis.data_type
  }

  get axisLimitMin() {
    if (!this.axis.axis_limit_min && this.axis.axis_limit_min !== 0) {
      return null
    }
    return +this.axis.axis_limit_min
  }

  get axisLimitMax() {
    if (!this.axis.axis_limit_max && this.axis.axis_limit_max !== 0) {
      return null
    }
    return +this.axis.axis_limit_max
  }

  get valueFormatting() {
    return getValueFormatString({
      type: this.axis.value_formatting_type,
      customShort: this.axis.custom_value_short,
      customLong: this.axis.custom_value_long
    })
  }

  get areAxesStacked() {
    return areAxesStackedUtil(this.settings)
  }

  get hideGrid() {
    if (!this.showChartGridLines) {
      return true
    }

    if (this.areAxesStacked) {
      return false
    }

    return this.axisIndex > 0
  }

  get stackMarginGap() {
    if (!this.areAxesStacked || _.isEqual(this.axisIndex, 0)) {
      return 0
    }

    return STACK_AXES_MARGIN
  }

  get axisPosition() {
    return BaseAxisModule.resolveAxisPosition(this.axis, this.settings)
  }

  static resolveAxisPosition(axis: IAxis, settings: IChartSettings) {
    if (_.get(settings, 'stackAxes', false)) {
      return _.get(axis, 'stackedAxisPosition', AxisPosition.Normal)
    }
    return _.get(axis, 'axisPosition', AxisPosition.Normal)
  }
}

export class YAxisModule extends BaseAxisModule {
  _makeModuleData() {
    if (_excludes(AxisPosition, this.axisPosition)) {
      return
    }

    if (this.flipAxis) {
      switch (this.axis.data_type) {
        case IDataType.NUMBER: {
          return valueXAxisAttributes({
            id: YAxisModule.id(this.axis),
            options: {
              opposite: this.axisPosition === AxisPosition.Opposite,
              numberFormat: this.valueFormatting.short,
              hideGrid: this.hideGrid,
              min: this.axisLimitMin,
              max: this.axisLimitMax,
              marginRight: this.stackMarginGap
            }
          })
        }
        default:
          throw new Error(`Unsupported axis config for ${this.axis.label}`)
      }
    } else {
      switch (this.axis.data_type) {
        case IDataType.NUMBER: {
          return valueYAxisAttributes({
            id: YAxisModule.id(this.axis),
            options: {
              opposite: this.axisPosition === AxisPosition.Opposite,
              numberFormat: this.valueFormatting.short,
              hideGrid: this.hideGrid,
              min: this.axisLimitMin,
              max: this.axisLimitMax,
              marginTop: this.stackMarginGap
            }
          })
        }
        default:
          throw new Error(`Unsupported axis config for ${this.axis.label}`)
      }
    }
  }

  static id(axis: IAxis) {
    return `y-axis-${axis.id}`
  }
}

export class XAxisModule extends BaseAxisModule {
  _makeModuleData() {
    if (this.flipAxis) {
      switch (this.axis.data_type) {
        case IDataType.DATE_STRING: {
          return dateYAxisAttributes({
            id: XAxisModule.axisId(this.axis),
            options: { frequency: this.frequency }
          })
        }
        case IDataType.TEXT:
        case IDataType.BOOLEAN: {
          return categoryYAxisAttributes({
            id: XAxisModule.axisId(this.axis),
            options: { inversed: true, yAxisKey: this.axis.label }
          })
        }
        default:
          throw new Error(`Unsupported axis config for ${this.axis.label}`)
      }
    } else {
      switch (this.axis.data_type) {
        case IDataType.DATE_STRING: {
          return dateXAxisAttributes({
            id: XAxisModule.axisId(this.axis),
            options: { frequency: this.frequency }
          })
        }
        case IDataType.TEXT:
        case IDataType.BOOLEAN: {
          return categoryXAxisAttributes({
            id: XAxisModule.axisId(this.axis),
            options: { xAxisKey: this.axis.label }
          })
        }
        default:
          throw new Error(`Unsupported axis config for ${this.axis.label}`)
      }
    }
  }

  static axisId(axis: IAxis) {
    return `x-axis-${axis.id}`
  }
}

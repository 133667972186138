import { useQuery } from '@tanstack/react-query'

import axiosServices from '@utils/axios'
import { camelCaseKeys } from '@utils/case-conversion'

import useAuth from '@hooks/useAuth'

import { QUERY_STALE_TIME_DEFAULT } from 'queries/constants'

export interface IJobSchedule {
  id: number
  name: string
  replicationJobId?: number
  awsEventbridgeScheduleArn: string
  awsEventbridgeTargetArn: string
  connectedSourceSystemId?: number
  groupName: string
  scheduleExpression: string
  scheduleExpressionTimezone: string
  state: string
  businessTimezone: string
}

const fetchJobSchedules = async (businessId: number | undefined): Promise<IJobSchedule[]> => {
  const response = await axiosServices.get(`/businesses/${businessId}/data_replication_schedule`)

  return camelCaseKeys(response.data) as IJobSchedule[]
}

export const useFetchJobSchedules = () => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-job-schedules', user?.business_id],
    enabled: !!user?.business_id,
    queryFn: () => fetchJobSchedules(user?.business_id),
    staleTime: QUERY_STALE_TIME_DEFAULT
  })
}

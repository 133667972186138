import * as React from 'react'

import * as SelectPrimitive from '@radix-ui/react-select'

import { Check, ChevronDown, ChevronUp, Icon } from '@components/icons'
import { ArrowDropdown } from '@components/icons/arrow-dropdown'

import { cn } from 'utils/style-utils'

// const Select = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group

const SelectValue = SelectPrimitive.Value
export interface SelectProps extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Root> {
  id?: string
  className?: string
}

const Select: React.FC<SelectProps> = (props) => {
  return <SelectPrimitive.Root {...props} />
}

export interface SelectTriggerProps
  extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> {
  size?: 'default' | 'small'
  variant?: 'default' | 'outline'
  layout?: 'inline' | 'fullWidth' | 'popover' | 'default'
  failedValidation?: boolean
}

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  SelectTriggerProps
>(
  (
    {
      className,
      children,
      size = 'default',
      variant = 'default',
      layout = 'default',
      failedValidation,
      ...props
    },
    ref
  ) => {
    const sizeClasses = React.useMemo(
      () => ({
        small: 'py-1 pl-2 pr-1 h-7',
        default: 'pl-3 pr-1 py-2 h-9'
      }),
      []
    )

    const variantClasses = React.useMemo(
      () => ({
        default: `bg-white shadow-sm
                  border border-solid border-grey-light`,
        outline: `bg-white
                  border border-solid border-grey-lighter hover:border-grey-light`
      }),
      []
    )

    return (
      <SelectPrimitive.Trigger
        ref={ref}
        className={cn([
          `group twp flex w-full cursor-pointer items-center justify-between whitespace-nowrap rounded-md font-sans text-button focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1`,
          sizeClasses[size],
          variantClasses[variant],
          failedValidation ? 'border-red' : '',
          className
        ])}
        {...props}
      >
        {children}
        <SelectPrimitive.Icon asChild>
          <Icon
            icon={<ArrowDropdown />}
            className='transition duration-300 ease-in-out group-aria-expanded:rotate-180'
          />
        </SelectPrimitive.Icon>
      </SelectPrimitive.Trigger>
    )
  }
)
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName

const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollUpButton
    ref={ref}
    className={cn('flex cursor-default items-center justify-center py-1', className)}
    {...props}
  >
    <Icon icon={<ChevronUp />} />
  </SelectPrimitive.ScrollUpButton>
))
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName

const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollDownButton
    ref={ref}
    className={cn('flex cursor-default items-center justify-center py-1', className)}
    {...props}
  >
    <Icon icon={<ChevronDown />} />
  </SelectPrimitive.ScrollDownButton>
))
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        'relative z-50 max-h-96 min-w-[8rem] overflow-hidden rounded-md rounded-tl-none border border-solid border-grey bg-white shadow-brand data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        position === 'popper' &&
          'data-[side=bottom]:translate-y-0.5 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
        className
      )}
      position={position}
      {...props}
    >
      <SelectScrollUpButton />
      <SelectPrimitive.Viewport
        className={cn(
          position === 'popper' &&
            'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]'
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
      <SelectScrollDownButton />
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
))
SelectContent.displayName = SelectPrimitive.Content.displayName

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn('p-3 text-button font-semibold text-grey-dark', className)}
    {...props}
  />
))
SelectLabel.displayName = SelectPrimitive.Label.displayName

type CustomSelectedItemProps = { checkIconClassName?: string }

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & CustomSelectedItemProps
>(({ className, children, checkIconClassName, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      `relative grid w-full cursor-pointer select-none grid-cols-[1fr_auto] items-center rounded-sm px-3 py-2 text-button font-semibold text-blue-medium outline-none focus:bg-grey-lighter focus:outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50`,
      className
    )}
    {...props}
  >
    <SelectPrimitive.ItemText className='px-0'>{children}</SelectPrimitive.ItemText>
    <span className='flex items-center justify-center [&>span]:flex [&>span]:items-center'>
      <SelectPrimitive.ItemIndicator>
        <Icon icon={<Check />} className={cn('text-blue-dark', checkIconClassName)} />
      </SelectPrimitive.ItemIndicator>
    </span>
  </SelectPrimitive.Item>
))
SelectItem.displayName = SelectPrimitive.Item.displayName

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn('h-px bg-grey-lighter', className)}
    {...props}
  />
))
SelectSeparator.displayName = SelectPrimitive.Separator.displayName

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
  SelectScrollUpButton,
  SelectScrollDownButton
}

import { enumToOptions } from '@utils/obj-utils'

import { Select } from '@components/form/select'

import { useDispatch, useSelector } from '@store/index'
import { changeSeriesType, selectYAxes } from '@store/slices/component/chart-config'

import { SettingsRow, SettingsTable } from '../common/settings-table'
import { SeriesType } from '../types/chart-builder-types'
import { Aggregation } from './aggregation'
import { AxisAlignment } from './axis-alignment'
import { AxisLimit } from './axis-limit'
import { Breakdown, Stacking } from './breakdown'
import { AxisDataLabelsSettings } from './data-labels-settings'
import { LegendSettings } from './legend-settings'
import NumberFormatting from './number-formatting'
import { AxisTooltipOverrideSettings, AxisTooltipOverrideTitle } from './tooltip-settings'

const seriesTypes = enumToOptions(SeriesType)

export const ChartYAxis = () => {
  const yAxes = useSelector(selectYAxes)
  const dispatch = useDispatch()

  const tooltipOverridePresent = _.some(yAxes, (yAxis) => yAxis.tooltip_override)

  return (
    <SettingsTable
      data={yAxes}
      titleColumnLabel='Axis Settings'
      renderHeaderName={(item) => item.label}
    >
      {(rowProps) => (
        <>
          <SettingsRow
            title='Chart Type'
            {...rowProps}
            renderField={({ item }) => (
              <Select
                options={seriesTypes}
                value={item.seriesType || ''}
                onChange={(value) =>
                  dispatch(changeSeriesType({ id: item.id, seriesType: value as SeriesType }))
                }
              />
            )}
          />

          <SettingsRow subtitle='Axis Alignment' {...rowProps} renderField={AxisAlignment} />

          <SettingsRow title='Aggregate' {...rowProps} renderField={Aggregation} />

          <SettingsRow title='Legend' {...rowProps} renderField={LegendSettings} />

          <SettingsRow title='Breakdown' {...rowProps} hideSeparator renderField={Breakdown} />
          <SettingsRow subtitle='Stacking' {...rowProps} renderField={Stacking} />

          <SettingsRow title='Number Formatting' {...rowProps} renderField={NumberFormatting} />

          <SettingsRow title='Data Labels' {...rowProps} renderField={AxisDataLabelsSettings} />

          <SettingsRow title='Axis Limits' {...rowProps} renderField={AxisLimit} />

          <SettingsRow
            title='Tooltip'
            {...rowProps}
            hideSeparator={tooltipOverridePresent}
            renderField={AxisTooltipOverrideSettings}
          />
          {tooltipOverridePresent && (
            <SettingsRow
              subtitle='Title Label'
              {...rowProps}
              renderField={AxisTooltipOverrideTitle}
            />
          )}
        </>
      )}
    </SettingsTable>
  )
}

import {
  IAssociation,
  IAssociationData,
  IColumnData,
  IModelDetail,
  IQueryData
} from '../types/query-builder-types'

/**
 * Filter model data based on search text
 */
export default function filterModelData(
  searchText: string,
  showSelected: boolean,
  query: IQueryData | IAssociation,
  data?: IModelDetail
): IModelDetail | undefined {
  if (!showSelected && !searchText) return data
  if (!data) return data

  let columns: IColumnData[] = []

  if (data.columns) {
    columns = data.columns.filter((field) => {
      let selected = true
      if (showSelected) {
        selected &&= !!query.fields?.some((queryField) => queryField.name === field.name)
      }
      if (searchText) {
        selected &&= field.name.toLowerCase().includes(searchText.toLowerCase())
      }
      return selected
    })
  }

  let associations: IAssociationData[] = []

  if (data.associations) {
    associations = data.associations.reduce((acc, association) => {
      const aData = filterModelData(
        searchText,
        showSelected,
        query.associations?.[association.name] || {},
        association
      )
      if (!aData || (!aData.columns?.length && !aData.associations?.length)) {
        return acc
      } else {
        return [...acc, aData] as IAssociationData[]
      }
    }, [] as IAssociationData[])
  }

  return { ...data, columns, associations }
}

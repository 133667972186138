import React from 'react'

import { useToggle } from '@uidotdev/usehooks'

import { Input, InputProps } from '@core/input'

import { Icon } from '@components/icons'
import { Eye } from '@components/icons/eye'
import { EyeOff } from '@components/icons/eye-off'

export const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const [showPassword, toggleShowPassword] = useToggle(false)

  return (
    <Input
      layout='inline'
      {...props}
      style={showPassword ? {} : { fontFamily: 'text-security-disc' }}
      ref={ref}
      trailingIcon={
        <Icon icon={showPassword ? <Eye /> : <EyeOff />} onClick={() => toggleShowPassword()} />
      }
    />
  )
})

import BuilderLayout from '../common/builder-layout'
import DataPreview from '../common/data-preview'
import TableConfig from './table-config'
import TablePreview from './table-preview'

export default function TableBuilder() {
  return (
    <BuilderLayout
      Preview={<TablePreview />}
      Config={<TableConfig />}
      DataPreview={<DataPreview />}
    />
  )
}

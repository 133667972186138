import { UniqueIdentifier } from '@dnd-kit/core'

import Tooltip from '@components/core/tooltip'
import { Filter, HiddenNorthEast, Icon, NorthEast } from '@components/icons'
import { SortableTree } from '@components/tree/sortable-tree'

import { FinancialHierarchyNode } from 'components/financial/types'
import {
  TreeItem,
  TreeItem as TreeItemType,
  TreeSource,
  TreeSourceEnum
} from 'components/tree/types'
import useAuth from 'hooks/useAuth'

import { FormulaFx } from '../../../icons/formula-fx'
import { useCreateNode } from '../queries/create-node'
import { useDeleteNode } from '../queries/delete-node'
import { useEditNode } from '../queries/edit-node'
import { useReorder } from '../queries/reorder'

export const HierarchyBuilderTree = ({
  source = TreeSourceEnum.GeneralLedgerAccount,
  hierarchyId,
  isEditMode,
  nodes,
  selectedNodeId,
  onNodeClick,
  showSearchInput = false,
  showNodeReferenceId = false,
  showEdit = true
}: {
  source?: TreeSourceEnum.GeneralLedgerAccount | TreeSourceEnum.FinancialReport
  hierarchyId: number
  isEditMode: boolean
  nodes: FinancialHierarchyNode[]
  selectedNodeId: number
  onNodeClick: (id: UniqueIdentifier) => void
  showSearchInput?: boolean
  showNodeReferenceId?: boolean
  showEdit?: boolean
}) => {
  const { user } = useAuth()
  const { mutate: editNode } = useEditNode()
  const { mutate: createNode } = useCreateNode()
  const { mutate: reorder } = useReorder()
  const { mutate: deleteNode } = useDeleteNode()

  const onNodeEdit = (nodeId: number, item: TreeItem) =>
    editNode({ nodeId: nodeId, item: item, hierarchyId: hierarchyId })

  const onNodeCreate = ({ parentNodeId, item }: { parentNodeId?: number; item: TreeItem }) =>
    createNode({ parentNodeId: parentNodeId, item: item, hierarchyId: hierarchyId })

  const onNodeDelete = (nodeId: number, setErrorMessage?: (message: string) => void) => {
    deleteNode({ hierarchyId, nodeId, setErrorMessage })
  }

  const onDragEnd = ({
    parentNodeId,
    sortedNodeIds
  }: {
    parentNodeId?: number
    sortedNodeIds?: number[]
  }) =>
    reorder({
      hierarchyId: hierarchyId,
      parentNodeId: parentNodeId,
      sortedNodeIds: sortedNodeIds,
      groupType: 'heading_nodes'
    })

  return (
    <SortableTree
      collapsible
      indicator
      source={source}
      sortable={isEditMode}
      editable={isEditMode}
      showEdit={showEdit}
      defaultItems={nodes}
      showSearchInput={showSearchInput}
      onItemClick={onNodeClick}
      onNodeEdit={onNodeEdit}
      onNodeCreate={onNodeCreate}
      onNodeDelete={onNodeDelete}
      onDragEnd={onDragEnd}
      selectedNodeId={selectedNodeId}
      persistedCollapsedIdsKey={`gl-hierarchy-builder-${hierarchyId}-${user?.business_id}`}
      treeItemSecondaryUtils={(item: TreeItemType, source: TreeSource | undefined) => {
        const hasDimensionFilters =
          item.dimension_filters &&
          _.some(item.dimension_filters, (value: Array<string>) => !_.isEmpty(value))

        return (
          <div className='flex items-center gap-2'>
            <div className='mr-2 size-6'>
              {item.identifier === 'pointer' && showNodeReferenceId && (
                <Tooltip title='Referenced Node ID'>
                  <span className='font-semibold'>{item.referencedNodeId}</span>
                </Tooltip>
              )}
            </div>

            <div className='size-6'>
              {hasDimensionFilters && (
                <Tooltip title='Dimension Filters Applied'>
                  <Icon className='text-grey' icon={<Filter />} />
                </Tooltip>
              )}
            </div>

            <div className='size-6'>
              {item.identifier === 'formula' && (
                <Tooltip title='Formula Node'>
                  <Icon className='text-grey' icon={<FormulaFx />} />
                </Tooltip>
              )}

              {item.identifier === 'pointer' && (
                <Tooltip title={item.showOnReport ? 'Pointer Node' : 'Hidden Pointer Node'}>
                  <Icon
                    className='text-grey'
                    icon={item.showOnReport ? <NorthEast /> : <HiddenNorthEast />}
                  />
                </Tooltip>
              )}
            </div>
          </div>
        )
      }}
    />
  )
}

import { useCallback, useMemo } from 'react'

import { GridOptions } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { isBlank, isNotBlank } from '@utils/lodash'

import { autoselectRowOnNavigateToNextCell } from '@components/ag-grid/utils'

import { usePageDispatch, usePageSelector } from '@store/index'
import {
  removeParameterizedFilters,
  selectParameterizedFilters,
  setParameterizedFilters
} from '@store/slices/component/parameterized-filters'

import { IParameterizedFiltersParameters } from 'pages/component-management/types/component-types'
import { ITableColumn } from 'pages/component-management/types/table-builder-types'

export function useBroadcastValue({
  columns,
  gridRef
}: {
  columns: ITableColumn[]
  gridRef: React.MutableRefObject<AgGridReact | null>
}): {
  getBroadcastGridOptions: () => Partial<GridOptions>
  broadcastClassNames: string
  selectRowOnDataRefreshed: () => void
} {
  const broadcastColumns = useMemo(
    () => columns.filter((column) => !!column.customData?.broadcastValue),
    [columns]
  )
  const broadcastPresent = broadcastColumns.length > 0
  const pageDispatch = usePageDispatch()
  const parameterizedFilters = usePageSelector(selectParameterizedFilters)

  const onRowSelected: GridOptions['onRowSelected'] = useCallback(() => {
    if (!gridRef.current?.api) return
    const selectedRows = gridRef.current.api.getSelectedRows()
    if (selectedRows.length) {
      pageDispatch(
        setParameterizedFilters({
          parameterValues: _.transform(
            broadcastColumns,
            (acc, column) => {
              acc[column.customData?.broadcastValue as string] =
                selectedRows[0][column.field as string]
            },
            {} as IParameterizedFiltersParameters
          )
        })
      )
    } else {
      // clear broadcast values
      pageDispatch(
        removeParameterizedFilters({
          keys: broadcastColumns.map((column) => column.customData?.broadcastValue as string)
        })
      )
    }
  }, [broadcastColumns, gridRef, pageDispatch])

  const selectRowOnDataRefreshed = useCallback(() => {
    if (isBlank(gridRef.current?.api) || isBlank(parameterizedFilters) || !broadcastColumns.length)
      return

    gridRef.current!.api.forEachNode((node) => {
      const selected =
        isNotBlank(node.data) &&
        _.every(broadcastColumns, (column) => {
          const broadcastColumn = _.get(column, ['customData', 'broadcastValue'], '')
          const columnField = _.get(column, 'field', '')
          return _.isEqual(node.data[columnField], parameterizedFilters[broadcastColumn])
        })

      if (selected && !node.isSelected()) node.setSelected(true)
    })
  }, [parameterizedFilters, broadcastColumns, gridRef])

  return {
    getBroadcastGridOptions: () =>
      broadcastPresent
        ? {
            rowSelection: 'single',
            onRowSelected,
            navigateToNextCell: autoselectRowOnNavigateToNextCell
          }
        : {},
    broadcastClassNames: broadcastPresent ? 'with-row-selection' : '',
    selectRowOnDataRefreshed
  }
}

import { Separator } from '@core/separator'

import AgGridSearchInput from '@components/control-panel/ag-grid-search-input'
import Button from '@components/core/button'
import { Icon } from '@components/icons'
import { Refresh } from '@components/icons/refresh'

import { useManualRefetchStatus } from 'queries/hooks/use-manual-refetch-status'

import { ActiveConnectionsList } from './active-connections-list'
import { FlushConnections } from './flush-connections'
import { useFetchActiveConnections } from './queries/fetch-active-connections'
import { DataConnectionMode, IConnectedSourceSystem } from './types'

export function ActiveConnectionsTab({
  connectedSourceSystem,
  isPending,
  mode
}: {
  connectedSourceSystem?: IConnectedSourceSystem
  isPending: boolean
  mode: DataConnectionMode
}) {
  const {
    isPending: isPendingConnections,
    data,
    refetch: refetchActiveConnections,
    isRefetching
  } = useFetchActiveConnections({
    businessId: connectedSourceSystem?.businessId,
    id: connectedSourceSystem?.id,
    pulseSupported: connectedSourceSystem?.pulseSupported
  })
  const [isManualRefetching, startManualRefetching] = useManualRefetchStatus({ isRefetching })

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex min-h-11 w-full items-center space-x-2'>
        <AgGridSearchInput inputSize={'extra-small'} />
        <Separator orientation='vertical' className='h-10 text-grey-lighter' />
        {mode === DataConnectionMode.CHARGER_OPS && (
          <>
            <FlushConnections connectedSourceSystem={connectedSourceSystem} isPending={isPending} />
            <Separator orientation='vertical' className='h-10 text-grey-lighter' />
          </>
        )}
        <Button
          variant='primary'
          size='small'
          disabled={isPending || isPendingConnections || isRefetching}
          onClick={() => {
            startManualRefetching()
            refetchActiveConnections()
          }}
        >
          <Icon icon={<Refresh />} className='mx-0 h-5' />
          Refresh
        </Button>
      </div>
      <Separator className='w-full' />
      <div />
      {!isPending && (
        <ActiveConnectionsList
          data={data}
          isPending={isPending || isPendingConnections || isManualRefetching}
          tokenProvider={connectedSourceSystem?.tokenProvider}
        />
      )}
    </div>
  )
}

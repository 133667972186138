import { AggregateType, IMetadata } from '../types/component-types'
import { IResultRow } from '../types/shared-types'
import { ITreemapData } from '../types/treemap-builder-types'

function breakdownDataByLabels(
  data: IResultRow[],
  dimensions: string[],
  measure: string,
  aggregate?: string
) {
  const [label, ...restLabels] = dimensions

  const currentLabelValues = _.groupBy(data, label)

  return _.reduce(
    currentLabelValues,
    (acc, value, key): ITreemapData[] => {
      if (restLabels.length === 0) {
        // const _value = aggregateData(value, measure, aggregate) || 0;
        const _value = 0
        return [
          ...acc,
          {
            name: key,
            value: Math.abs(_value),
            actualValue: _value
          }
        ]
      } else {
        return [
          ...acc,
          {
            name: key,
            children: breakdownDataByLabels(value, restLabels, measure, aggregate)
          }
        ]
      }
    },
    [] as ITreemapData[]
  )
}

export default function dataTransformer({
  data,
  dimensions,
  measures,
  aggregate,
  metadata: _metadata
}: {
  data: IResultRow[]
  dimensions: { id: string; label: string }[]
  measures: { id: string; label: string }[]
  metadata: IMetadata
  aggregate?: AggregateType
}) {
  if (measures.length > 1) {
    throw new Error('Treemap only supports one measure')
  }

  const store = {}

  const noDimensionsOrMeasures = dimensions.length === 0 || measures.length === 0
  if (noDimensionsOrMeasures) {
    return {
      transformedData: [
        {
          name: 'root',
          children: []
        }
      ],
      store
    }
  }

  const filteredMeasure = measures[0].label
  const filteredDimensions = dimensions.map((d) => d.label)

  const transformedData = [
    {
      name: 'root',
      children: breakdownDataByLabels(data, filteredDimensions, filteredMeasure, aggregate)
    }
  ]

  return {
    transformedData,
    store
  }
}

import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

export type OnlyID = { id: string }
export type WithID<T> = T & OnlyID

export const createPageAction = <P>(actionCreator: ActionCreatorWithPayload<P>) => {
  return (payload: Omit<P, 'id'>) => {
    return (id: string) => actionCreator({ ...payload, id } as P)
  }
}

export type PageActionPayload = ReturnType<ReturnType<typeof createPageAction>>

// TODO: Filter pages by business to display business specific available pages, May be move these to backend or dynamically populate pages by business folder
import { FinancialStatementTypeEnum } from '../../../financial-statements/types'

export const STATIC_PAGES = [
  { title: 'Audit Invoices', path: 'pages/audit/invoices' },
  { title: 'Chat', path: 'pages/chat' },
  { title: 'Data Connections', path: 'pages/link-services' },
  { title: 'Expenses', path: 'pages/dashboard/expenses-dashboard' },
  {
    title: 'Financial Statement',
    path: 'pages/financial-statements/financial-statement',
    type: FinancialStatementTypeEnum
  },
  { title: 'General Ledger Accounts', path: 'pages/general-ledger-account/index' },
  { title: 'GL Transactions', path: 'pages/dashboard/gl-transactions' },
  { title: 'Invoices', path: 'pages/email-processing/invoices-ag' },
  { title: 'Insights', path: 'pages/dashboard/insights/alerts' },
  { title: 'Locations', path: 'pages/location/index' },
  { title: 'Materials', path: 'pages/dashboard/materials/item-list' },
  { title: 'Materials Costs', path: 'pages/dashboard/materials/cost-dashboard' },
  { title: 'Model Plus Actual', path: 'pages/report/model-plus-actual' },
  { title: 'Model To Actual', path: 'pages/report/model-to-actual' },
  { title: 'Models', path: 'pages/report/models' },
  { title: 'Trial Balance Upload', path: 'pages/report/trial-balance-upload' },
  { title: 'None', path: 'pages/maintenance/404.tsx' },
  { title: 'Onboard External Entity', path: 'pages/onboarding/external-entity' },
  { title: 'Recipe', path: 'pages/recipe/list-recipe' },
  { title: 'Recipes Cost Breakdown', path: 'pages/recipe/recipes-cost' },
  { title: 'Sales', path: 'pages/dashboard/sales-dashboard' },
  { title: 'Sales Deep Dive', path: 'pages/sales-deep-dive/sales-deep-dive' },
  { title: 'Sales Forecast', path: 'pages/dashboard/sales/forecast' },
  { title: 'Schema Renaming', path: 'pages/configuration/schema-renaming' },
  { title: 'Static Pages', path: 'pages/admin/layouts/data/static-pages' },
  { title: 'Trial Balance', path: 'pages/financials/trial-balance' },
  { title: 'Chart of Accounts', path: 'pages/financials/gl-mappings' },
  { title: 'Data Renaming', path: 'pages/property-renaming/index' },
  { title: 'Custom KPIs', path: 'pages/report/custom-kpis' }
]

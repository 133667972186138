import { Box } from '@mui/material'

import { useSelector } from '@store/index'
import { selectGenre } from '@store/slices/component/basic-config'

import ChartBuilder from './chart-builder'
import GenreDropdown from './common/genre-dropdown'
import TableBuilder from './table-builder'
import TreemapBuilder from './treemap-builder'
import { ComponentGenre } from './types/component-types'

export default function Builder() {
  const genre = useSelector(selectGenre)

  switch (genre) {
    case ComponentGenre.chart:
      return <ChartBuilder />
    case ComponentGenre.table:
      return <TableBuilder />
    case ComponentGenre.treemap:
      return <TreemapBuilder />
    default:
      return (
        <Box>
          <Box>TODO: Not implemented for {genre}</Box>
          <GenreDropdown />
        </Box>
      )
  }
}

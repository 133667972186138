export const DataLabelVisibility = () => (
  <>
    <path
      d='M7.5 11C5 11 2.865 12.5965 2 14.85C2.865 17.1035 5 18.7 7.5 18.7C10 18.7 12.135 17.1035 13 14.85C12.135 12.5965 10 11 7.5 11ZM7.5 17.4167C6.12 17.4167 5 16.2668 5 14.85C5 13.4332 6.12 12.2833 7.5 12.2833C8.88 12.2833 10 13.4332 10 14.85C10 16.2668 8.88 17.4167 7.5 17.4167ZM7.5 13.31C6.67 13.31 6 13.9979 6 14.85C6 15.7021 6.67 16.39 7.5 16.39C8.33 16.39 9 15.7021 9 14.85C9 13.9979 8.33 13.31 7.5 13.31Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.5 10.0736V8.5H18.6764L20.2955 11.5L18.6765 14.5H13.938L14.0723 14.85L13.9348 15.2083C13.8303 15.4806 13.7096 15.745 13.574 16H18.9748C19.3423 16 19.6802 15.7984 19.8548 15.4749L21.7437 11.9749C21.9037 11.6785 21.9037 11.3215 21.7437 11.0251L19.8548 7.52507C19.6802 7.20162 19.3423 7 18.9748 7H6C5.44772 7 5 7.44772 5 8V10.4778C5.4761 10.2882 5.97827 10.1511 6.5 10.0736ZM6.5 11.0869C5.97284 11.1794 5.46957 11.3438 5 11.5698V15C5 15.086 5.01086 15.1695 5.03128 15.2491C5.01147 15.1191 5.00119 14.9858 5.00119 14.85C5.00119 13.7986 5.61793 12.8943 6.5 12.4977V11.0869ZM6.5 13.7021V14.5H8.96248C8.98781 14.6124 9.00119 14.7296 9.00119 14.85C9.00119 15.3078 8.8078 15.7182 8.50011 16H6.50228C6.19458 15.7182 6.00119 15.3078 6.00119 14.85C6.00119 14.3933 6.19363 13.9838 6.5 13.7021ZM9.73653 16C9.90585 15.654 10.0012 15.2634 10.0012 14.85C10.0012 14.7313 9.99333 14.6144 9.97811 14.5H12.8543C12.9067 14.6148 12.9557 14.7315 13.0012 14.85C12.8451 15.2566 12.6477 15.6417 12.4143 16H9.73653Z'
      fill='currentColor'
    />
  </>
)

import { ColDef } from 'ag-grid-community'

import { IHeader } from '../types/component-types'
import { PREVIOUS_PERIOD_SUFFIX } from '../types/shared-types'

export function numberHeader(header: ColDef) {
  return _.isEqual(header.cellDataType, 'number')
}

export function booleanHeader(header: ColDef) {
  return _.isEqual(header.cellDataType, 'boolean')
}

export function dateHeader(header: ColDef) {
  return _.isEqual(header.cellDataType, 'dateString')
}

export function previousPeriodHeaderName(headerName: string) {
  return `${headerName}${PREVIOUS_PERIOD_SUFFIX}`
}

export function findPreviousPeriodHeader(headerName: string, headers: IHeader[]) {
  return _.find(
    headers,
    (curr) => curr.previous_period && _.eq(curr.name, previousPeriodHeaderName(headerName))
  )
}

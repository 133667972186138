import { isNotEqual } from '@utils/lodash'

import { Business, CHARGER_FRIENDLY_NAME } from './auth'

export enum BusinessType {
  CLIENT = 'client',
  PRIVATE_EQUITY = 'private_equity'
}

export const isCharger = (businessFriendlyName?: string) => {
  return _.isEqual(businessFriendlyName, CHARGER_FRIENDLY_NAME)
}

export const isClient = (business: Business) => _.isEqual(business.type, BusinessType.CLIENT)
export const isExternalEntity = (business: Business) =>
  isNotEqual(business.type, BusinessType.CLIENT)

export const PULSEFLOW_DYNAMICS_FRIENDLY_NAME = 'pulseflow-dynamics'
export const TRACKAHEAD_FRIENDLY_NAME = 'trackahead'
export const ENERWAVE_FRIENDLY_NAME = 'enerwave'

export const DEMO_BUSINESSES = [
  PULSEFLOW_DYNAMICS_FRIENDLY_NAME,
  TRACKAHEAD_FRIENDLY_NAME,
  ENERWAVE_FRIENDLY_NAME
]

export const isDemoBusiness = (businessFriendlyName?: string) =>
  businessFriendlyName ? _.includes(DEMO_BUSINESSES, businessFriendlyName) : false

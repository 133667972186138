import { dispatch } from 'store'
import { openSnackbar } from 'store/reducers/snackbar'

export const successSnack = (message: string, close = true) => {
  return dispatch(
    openSnackbar({
      open: true,
      message: message,
      variant: 'alert',
      alert: { color: 'success', severity: 'success' },
      close: close
    })
  )
}

export const errorSnack = (message: string, close = true) => {
  return dispatch(
    openSnackbar({
      open: true,
      message: message,
      variant: 'alert',
      alert: { color: 'error', severity: 'error' },
      close: close
    })
  )
}

export const infoSnack = (message: string, close = true) => {
  return dispatch(
    openSnackbar({
      open: true,
      message: message,
      variant: 'alert',
      alert: { color: 'info', severity: 'info' },
      close: close
    })
  )
}

export const warningSnack = (message: string, close = true) => {
  return dispatch(
    openSnackbar({
      open: true,
      message: message,
      variant: 'alert',
      alert: { color: 'warning', severity: 'warning' },
      close: close
    })
  )
}

import { Select } from '@components/form/select'

import { useDispatch, useSelector } from '@store/index'
import { selectGenre, updateGenre } from '@store/slices/component/basic-config'

import { ComponentGenre } from '../types/component-types'

export default function GenreDropdown({ className = '' }: { className?: string }) {
  const genre = useSelector(selectGenre)
  const dispatch = useDispatch()
  return (
    <Select
      options={[
        { value: ComponentGenre.chart, label: 'Chart' },
        { value: ComponentGenre.table, label: 'Table' },
        { value: ComponentGenre.kpi, label: 'KPI' },
        { value: ComponentGenre.treemap, label: 'TreeMap' }
      ]}
      value={genre}
      onChange={(value) => dispatch(updateGenre(value as ComponentGenre))}
      className={className}
    />
  )
}

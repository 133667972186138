import { useState } from 'react'

import DimensionExplorer from '@components/control-panel/dimensions/dimension-explorer'

import { ActionBarControls } from './action-bar-controls'
import { DEFAULT_SNAP_POINTS, ResizePlaceholder, Resizer } from './resizer'

export const VisualizeLayout = ({
  Preview,
  FullSettingsView,
  RightSettingsView
}: {
  Preview: React.ReactNode
  FullSettingsView: React.ReactNode
  RightSettingsView: React.ReactNode
}) => {
  const [position, setPosition] = useState(DEFAULT_SNAP_POINTS[1])
  const [dimensionFilterOpen, setDimensionFilterOpen] = useState(false)

  const onPositionChange = (position: number, snappedPosition: number) => {
    if (dimensionFilterOpen && _.includes(DEFAULT_SNAP_POINTS.slice(0, 2), snappedPosition)) {
      setDimensionFilterOpen(false)
    }
    setPosition(position)
  }

  return (
    <Resizer
      position={position}
      onPositionChange={onPositionChange}
      dimensionFilterOpen={dimensionFilterOpen}
    >
      {({ currentSnapIndex, snapComponentStyles }) => (
        <div className='h-full' style={snapComponentStyles.parent}>
          <div style={snapComponentStyles.first} className='flex size-full overflow-hidden'>
            <div className='flex size-full flex-col overflow-auto pr-2'>
              <ActionBarControls
                dimensionFilterOpen={dimensionFilterOpen}
                onToggleDimensionFilters={() => {
                  const newDimensionFilterOpen = !dimensionFilterOpen
                  setDimensionFilterOpen(newDimensionFilterOpen)
                  setPosition(DEFAULT_SNAP_POINTS[newDimensionFilterOpen ? 2 : 1])
                }}
              />
              <div className='flex-1'>{Preview}</div>
            </div>
          </div>
          {dimensionFilterOpen && (
            <div className='size-full overflow-hidden pl-2 pr-4'>
              <div className='size-full rounded-xl rounded-r-none border border-solid border-grey'>
                <DimensionExplorer
                  classNames='size-full overflow-scroll m-0'
                  onClose={() => {
                    setPosition(DEFAULT_SNAP_POINTS[1])
                    setDimensionFilterOpen(false)
                  }}
                />
              </div>
            </div>
          )}
          <ResizePlaceholder />
          <div style={snapComponentStyles.second} className='size-full overflow-hidden pl-2'>
            {currentSnapIndex === 0 && FullSettingsView}
            {currentSnapIndex === 1 && RightSettingsView}
          </div>
        </div>
      )}
    </Resizer>
  )
}

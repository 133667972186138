import { useEffect } from 'react'

import { Dialog, DialogTitle, Grid, IconButton } from '@mui/material'

import { useDispatch } from '@store/index'
import { initQueryData, setQueryData } from '@store/slices/component/query-config'

import { useFetchCustomField } from '../../queries/fetch-custom-field'
import { CustomFieldType, IColumnData } from '../../types/query-builder-types'
import Form from './form'
import LoadingSkeleton from './loading-skeleton'

export default function AddOrConfigureField({
  open,
  handleClose,
  modelName,
  selected,
  setSelected
}: {
  open: boolean
  handleClose: () => void
  modelName: string
  selected: IColumnData | null
  setSelected: (data: IColumnData | null) => void
}) {
  const { data: customFieldData, isLoading } = useFetchCustomField(selected?.id!)
  const dispatch = useDispatch()

  const initialValues = {
    name: _.get(customFieldData, 'name', ''),
    field_type: _.get(customFieldData, 'field_type', CustomFieldType.TEXT),
    formula: _.get(customFieldData, 'formula', '')
  }

  const isAdd = !selected

  useEffect(() => {
    if (!isAdd && customFieldData?.path) {
      dispatch(setQueryData({ queryData: customFieldData?.path, placement: 'custom-field' }))
    }
  }, [customFieldData?.path, dispatch, isAdd])

  const onClose = () => {
    setSelected(null)
    dispatch(initQueryData({ placement: 'custom-field' }))
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll='paper'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      maxWidth='xl'
      fullWidth
    >
      <Grid container spacing={2} justifyContent='space-between' alignItems='center'>
        <Grid item>
          {isAdd && <DialogTitle>Add New Field</DialogTitle>}
          {!isAdd && <DialogTitle>Update Field {selected?.name}</DialogTitle>}
        </Grid>
        <Grid item sx={{ mr: '2.5rem' }}>
          <IconButton color='secondary' onClick={onClose}>
            X
          </IconButton>
        </Grid>
      </Grid>
      {isLoading ? (
        <LoadingSkeleton modelName={selected?.name!} />
      ) : (
        <Form
          modelName={modelName}
          initialValues={initialValues}
          handleClose={onClose}
          selectedId={selected?.id}
        />
      )}
    </Dialog>
  )
}

import * as am5 from '@amcharts/amcharts5'

import { Frequency } from 'types/filter'

import { CHART_FONT_FAMILY } from '../types/data-types'
import { IModuleOptions, ModuleName } from '../types/module-types'
import { dateIntervalsAndFormattings } from './date-axis-utils'

export default function tooltipHtmlString(
  {
    data = [],
    headerKey,
    header,
    isHeaderHidden,
    isDateHeader,
    frequency = Frequency.Monthly
  }: IModuleOptions[ModuleName.tooltip],
  context: any = {},
  root: am5.Root
) {
  const dateFormat = dateIntervalsAndFormattings(frequency).dateFormat
  let htmlString = `<table style="width: 100%; border-collapse: collapse; background: white; font-size: 12px; line-height: 1;
                                  margin-top: 4px; margin-bottom: 4px; font-family: ${CHART_FONT_FAMILY}; max-width: 300px;">`

  // header
  if (!isHeaderHidden) {
    let formattedHeader: string = ''

    if (header !== undefined) {
      // header may be an empty string or null
      formattedHeader = header
    } else if (headerKey !== undefined) {
      // headerKey may be an empty string or null
      if (isDateHeader) {
        if (!context[headerKey]) {
          formattedHeader = 'Undefined Date'
        } else if (typeof context[headerKey] !== 'number') {
          formattedHeader = context[headerKey]
        } else {
          // eslint-disable-next-line no-date-parsing/no-date-parsing -- date parsing is done by amcharts dataProcessor
          formattedHeader = root.dateFormatter.format(new Date(context[headerKey]), dateFormat)
        }
      } else {
        formattedHeader = context[headerKey]
      }
    }

    htmlString += `
    <tr>
      <td colspan="3" style="text-align: center; padding: 0px 10px 5px;">
        <em>${formattedHeader}</em>
      </td>
    </tr>
    `
  }

  data.forEach(({ title, titleKey, isDateTitle, valueKey, valueFormat, color }) => {
    // title
    let formattedTitle: string = ''
    if (title !== undefined) {
      // title may be an empty string or null
      formattedTitle = title
    } else if (titleKey !== undefined) {
      // titleKey may be an empty string or null
      if (isDateTitle) {
        if (!context[titleKey]) {
          formattedTitle = 'Undefined Date'
        } else if (typeof context[titleKey] !== 'number') {
          formattedTitle = context[titleKey]
        } else {
          // eslint-disable-next-line no-date-parsing/no-date-parsing -- date parsing is done by amcharts dataProcessor
          formattedTitle = root.dateFormatter.format(new Date(context[titleKey]), dateFormat)
        }
      } else {
        formattedTitle = context[titleKey]
      }
    }

    // value
    let formattedValue: string = ''
    if (valueKey !== undefined) {
      // valueKey may be an empty string or null
      formattedValue = root.numberFormatter.format(context[valueKey || ''] || 0, valueFormat)
    }

    let legendBullet = ''
    if (color) {
      legendBullet = `<td style="padding: 0px 2px 2px; color: ${color}; font-size: 12px;">●</td>`
    }

    htmlString += `
    <tr>
      ${legendBullet}
      <td style="padding: 0px 2px; font-weight: 600;">
        ${formattedTitle}
      </td>
      <td style="padding: 0px 2px; text-align: right;">
        ${formattedValue}
      </td>
    </tr>
    `
  })

  htmlString += `</table>`

  return htmlString
}

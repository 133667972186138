import {
  addMonths,
  addYears,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInSeconds,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  parseISO,
  subDays
} from 'date-fns'

import {
  DateInfo,
  Frequency,
  Quarter,
  YearMonthDayInfo,
  YearMonthInfo,
  YearQuarterInfo,
  YearWeekInfo
} from '../types/filter'
import {
  convertUTCDateToYMD,
  getFirstWeek,
  getWeekColumnLabel,
  getWeekNumber
} from './date-week-utils'
import { capitalizeFirstLetter } from './string-utils'

export const defaultFromDate = () => {
  return new Date()
}

export const defaultToDate = () => {
  return getDateTimeAgo(3, 'month')
}

export function getQuarterFromDate(dateString: string) {
  const [year, month] = dateString.split('-')
  const quarter = Math.ceil(+month / 3)
  return `${year}-Q${quarter}`
}

export const formatDateWithFilter = (dateString: string, filterBy: string) => {
  switch (filterBy) {
    case 'week':
      return dateString
    case 'month':
      return dateString.substring(0, 7)
    case 'quarter':
      return getQuarterFromDate(dateString)
    default:
      return dateString
  }
}

export const toUTCDateTime = (dateString: string) => {
  // eslint-disable-next-line no-date-parsing/no-date-parsing
  return new Date(`${dateString}T00:00:00Z`)
}

export enum Month {
  Jan = 'JAN',
  Feb = 'FEB',
  Mar = 'MAR',
  Apr = 'APR',
  May = 'MAY',
  Jun = 'JUN',
  Jul = 'JUL',
  Aug = 'AUG',
  Sep = 'SEP',
  Oct = 'OCT',
  Nov = 'NOV',
  Dec = 'DEC'
}

export enum Day {
  Sun = 'SUNDAY',
  Mon = 'MONDAY',
  Tue = 'TUESDAY',
  Wed = 'WEDNESDAY',
  Thu = 'THURDAY',
  Fri = 'FRIDAY',
  Sat = 'SATURDAY'
}

export const WeekDays = Object.values(Day)
export const Months = Object.values(Month)
export const MonthKeys = Object.keys(Month)

export const getWeekDays = (day: Day) => {
  if (day === Day.Sun) {
    return WeekDays
  }
  const dayIdx = getIndexFromDay(day)
  return WeekDays.slice(dayIdx).concat(WeekDays.slice(0, dayIdx))
}

export const getIndexFromDay = (day: Day) => WeekDays.indexOf(day)
export const getMonthFromIndex = (monthIdx: number) => Months[monthIdx]
export const getIndexFromMonth = (month: Month) => Months.indexOf(month)
export const getPreviousMonth = (month: Month) =>
  Months[(Months.indexOf(month) + Months.length - 1) % Months.length]
export const getNextMonth = (month: Month) => Months[(Months.indexOf(month) + 1) % Months.length]

export const getPaddedIndexFromMonth = (month: Month): string => {
  const index = getIndexFromMonth(month)
  return padNum(index + 1)
}

/* eslint-disable no-case-declarations */
export const getEndOfPeriod = (
  date: Date,
  frequency: Frequency,
  fiscalYearStart = Month.Jan
): Date => {
  const fiscalIdx = getIndexFromMonth(fiscalYearStart)
  const fiscalYearStartDate = new Date(date.getFullYear(), fiscalIdx, 1)

  // Adjust fiscalYearStartDate if date is before fiscal year starts
  if (date < fiscalYearStartDate) {
    fiscalYearStartDate.setFullYear(fiscalYearStartDate.getFullYear() - 1)
  }

  switch (frequency) {
    case Frequency.Monthly:
      return endOfMonth(date)
    case Frequency.Weekly:
      return endOfWeek(date)
    case Frequency.Quarterly:
      // Determine quarter start
      const currentMonthIndex = date.getMonth()
      let quarterStartMonthIndex =
        Math.floor(((currentMonthIndex - fiscalIdx + 12) % 12) / 3) * 3 + fiscalIdx

      // Ensure that the calculated quarter start index wraps around the year correctly
      if (quarterStartMonthIndex >= 12) {
        quarterStartMonthIndex -= 12
      }

      const quarterStartDate = new Date(date.getFullYear(), quarterStartMonthIndex, 1)
      return endOfMonth(addMonths(quarterStartDate, 2))
    case Frequency.Yearly:
      return subDays(addYears(fiscalYearStartDate, 1), 1)
    default:
      return endOfDay(date)
  }
}
/* eslint-enable no-case-declarations */

export const getDaysInMonth = (year: number, month: Month, fiscalYearStart?: Month) => {
  const calendarYear = getCalendarYearFromFiscalYear(year, month, fiscalYearStart)
  return new Date(calendarYear, getIndexFromMonth(month) + 1, 0).getDate()
}

export const getCalendarYearFromFiscalYear = (
  year: number,
  month: Month,
  fiscalYearStart?: Month
) => {
  if (fiscalYearStart == null || fiscalYearStart === Month.Jan) {
    return year
  }

  const fiscalIdx = getIndexFromMonth(fiscalYearStart)
  const monthIdx = getIndexFromMonth(month)

  if (monthIdx >= fiscalIdx) {
    return year - 1
  }

  return year
}

export const getFiscalYearFromCalendarYear = (
  year: number,
  month: Month,
  fiscalYearStart?: Month
) => {
  if (!fiscalYearStart || fiscalYearStart === Month.Jan) {
    return year
  }

  // if month is past the fiscal year month, increase the year
  const fiscalIdx = getIndexFromMonth(fiscalYearStart)

  if (getIndexFromMonth(month) >= fiscalIdx) {
    return year + 1
  }

  return year
}

export const getFiscalYear = (date: Date, fiscalYearStart?: Month) => {
  return getFiscalYearFromCalendarYear(
    date.getFullYear(),
    getMonthFromIndex(date.getMonth()),
    fiscalYearStart
  )
}

export const getFiscalMonth = (date: Date, fiscalYearStart?: Month) => {
  return getFiscalYearFromCalendarYear(
    date.getFullYear(),
    getMonthFromIndex(date.getMonth()),
    fiscalYearStart
  )
}

export const Quarters = [Quarter.Q1, Quarter.Q2, Quarter.Q3, Quarter.Q4]
const QUARTER_DURATION = Months.length / Quarters.length

export const getQuarterStartMonth = (quarter: Quarter, fiscalYearStart?: Month) => {
  const quarterIndex = Quarters.indexOf(quarter)
  return Months[
    (Months.indexOf(fiscalYearStart || Month.Jan) + QUARTER_DURATION * quarterIndex) % Months.length
  ]
}

export const getQuarterEndMonth = (quarter: Quarter, fiscalYearStart?: Month) => {
  const quarterIndex = Quarters.indexOf(quarter)
  return Months[
    (Months.indexOf(fiscalYearStart || Month.Jan) +
      QUARTER_DURATION * quarterIndex +
      QUARTER_DURATION -
      1) %
      Months.length
  ]
}

// if fiscalYearStart is present, year and quarter refer to the fiscal year
export const getQuarterStartDate = (year: number, quarter: Quarter, fiscalYearStart?: Month) => {
  const quarterStartMonth = getQuarterStartMonth(quarter, fiscalYearStart)

  const startYear = getCalendarYearFromFiscalYear(year, quarterStartMonth, fiscalYearStart)

  return { year: startYear, month: quarterStartMonth, day: 1 }
}

// if fiscalYearStart is present, year and quarter refer to the fiscal year
export const getQuarterEndDate = (year: number, quarter: Quarter, fiscalYearStart?: Month) => {
  const quarterEndMonth = getQuarterEndMonth(quarter, fiscalYearStart)

  const calendarYear = getCalendarYearFromFiscalYear(year, quarterEndMonth, fiscalYearStart)

  return { year: calendarYear, month: quarterEndMonth, day: getDaysInMonth(year, quarterEndMonth) }
}

export const getQuarter = (month: Month, fiscalYear?: Month) => {
  let monthIndex = getIndexFromMonth(month)

  // 000111222333

  if (fiscalYear) {
    monthIndex -= getIndexFromMonth(fiscalYear)
    monthIndex = (monthIndex + Months.length) % Months.length
  }

  const quarterIndex = Math.floor(monthIndex / QUARTER_DURATION)

  return Quarters[quarterIndex]
}

export const getMonthsForFiscalYear = (fiscalYear?: Month) => {
  if (!fiscalYear) {
    return Months
  }
  const monthIndex = getIndexFromMonth(fiscalYear)
  const allMonths = Object.values(Month)
  return allMonths.slice(monthIndex).concat(allMonths.slice(0, monthIndex))
}

export const getYearColumnLabel = (date_string: string) => {
  if (!date_string) {
    return ''
  }

  return +date_string.slice(0, 4)
}

export const getQuarterColumnLabel = (date_string: string) => {
  if (!date_string) {
    return ''
  }

  return getQuarterFromDate(date_string)
}

export const getPreviousMonthInfo = (year: number, month: Month, fiscalYearStart?: Month) => {
  const previousMonth = getPreviousMonth(month)
  const firstMonthOfYear = fiscalYearStart || Month.Jan
  return {
    year: month === firstMonthOfYear ? year - 1 : year,
    month: previousMonth
  }
}

export const getNextMonthInfo = (year: number, month: Month, fiscalYearStart?: Month) => {
  const nextMonth = getNextMonth(month)
  const firstMonthOfYear = fiscalYearStart || Month.Jan
  return {
    year: nextMonth === firstMonthOfYear ? year + 1 : year,
    month: nextMonth
  }
}

export const getDaysInPreviousMonth = (year: number, month: Month, fiscalYearStart?: Month) => {
  const prevMonthInfo = getPreviousMonthInfo(year, month, fiscalYearStart)
  return getDaysInMonth(prevMonthInfo.year, prevMonthInfo.month, fiscalYearStart)
}

export const getMonthColumnLabel = (date_string: string, format: string = 'y-m') => {
  if (!date_string) {
    return ''
  }

  const [year, month] = date_string.split('-')
  const monthAbbreviation = MonthKeys[+month - 1]

  if (format === 'y-m') {
    return `${year}-${monthAbbreviation}`
  } else {
    return `${monthAbbreviation} ${year}`
  }
}

// Get date from year and month
export const dateFromYearMonth = (year: number = 0, month: string = '', end: boolean = false) => {
  // eslint-disable-next-line no-date-parsing/no-date-parsing
  const parsedDate = new Date(`${year} ${month} 1`)

  if (end) {
    const endOfMonthDate = endOfMonth(parsedDate)
    return format(endOfMonthDate, 'yyyy-MM-dd')
  }

  return format(parsedDate, 'yyyy-MM-dd')
}

// These assume that the values are already adjusted for fiscal year and just need a prefix
// START
export const getWeekHeading = (year: number, week: number, fiscalYearStart?: Month) => {
  const prefix = fiscalYearStart ? `FY${year}` : `${year}`
  const paddedWeek = week.toString().padStart(2, '0') // Ensures the week number is always two digits
  return `${prefix}-W${paddedWeek}`
}

export const getMonthHeading = (year: number, month: Month, fiscalYearStart?: Month) => {
  const prefix = fiscalYearStart ? 'FY' : ''
  return `${prefix}${year}-${getPaddedIndexFromMonth(month)}`
}

export const getQuarterHeading = (year: number, quarter: string, fiscalYearStart?: Month) => {
  const prefix = fiscalYearStart ? 'FY' : ''
  return `${prefix}${year}-${quarter}`
}

export const getYearHeading = (year: number, fiscalYearStart?: Month) => {
  const prefix = fiscalYearStart ? 'FY' : ''
  return `${prefix}${year}`
}
// END

export const getHeadingFromDate = (
  frequency: Frequency,
  date: Date,
  fiscalYearStart?: Month
): string => {
  switch (frequency) {
    case Frequency.Weekly:
      return getFiscalWeekHeadingFromDate(date, fiscalYearStart)
    case Frequency.Monthly:
      return getFiscalMonthHeadingFromDate(date, fiscalYearStart)
    case Frequency.Quarterly:
      return getFiscalQuarterHeadingFromDate(date, fiscalYearStart)
    case Frequency.Yearly:
      return getFiscalYearHeadingFromDate(date, fiscalYearStart)
    default:
      return formatDateLongLocal(date)
  }
}

export const getFiscalWeekHeadingFromDate = (date: Date, fiscalYearStart?: Month) => {
  // Convert the date to UTC to ensure consistent date handling across time zones
  const dateYMD = convertUTCDateToYMD(date)

  // Determine the fiscal year based on the date and fiscal start month
  const fiscalYear = getFiscalYearFromCalendarYear(dateYMD.year, dateYMD.month, fiscalYearStart)

  // Get the first day of the fiscal year, adjusted to the nearest Monday if necessary
  let firstWeekStartDate = getFirstWeek(fiscalYear, fiscalYearStart)
  if (firstWeekStartDate.getUTCDay() !== 1) {
    // Adjust to the nearest Monday
    firstWeekStartDate = addDaysToDate(firstWeekStartDate, 1 - firstWeekStartDate.getUTCDay())
  }

  // Calculate the week number from the start of the fiscal year
  const weekInfo = getWeekNumber(dateYMD.year, dateYMD.month, dateYMD.day, fiscalYearStart)

  // Check if the fiscal year should be adjusted based on the fiscal start month
  const monthIndex = getIndexFromMonth(dateYMD.month)
  const fiscalStartIndex = fiscalYearStart ? getIndexFromMonth(fiscalYearStart) : 0
  const correctedYear = monthIndex < fiscalStartIndex ? fiscalYear - 1 : fiscalYear

  // Construct the week heading with the corrected fiscal year and the calculated week number
  const weekHeading = `${fiscalYearStart ? 'FY' : ''}${correctedYear}-W${weekInfo.week.toString().padStart(2, '0')}`

  return weekHeading
}

export const getFiscalMonthHeadingFromDate = (date: Date, fiscalYearStart?: Month) => {
  const year = date.getFullYear()
  const monthIndex = date.getMonth()
  const fiscalYear = getFiscalYearFromCalendarYear(
    year,
    getMonthFromIndex(monthIndex),
    fiscalYearStart
  )

  // Adjust how we get the month index based on the fiscal start month
  const monthStartIdx = fiscalYearStart ? getIndexFromMonth(fiscalYearStart) : 0
  const adjustedMonthIndex = (monthIndex - monthStartIdx + 12) % 12

  // Formatting month as '08' instead of '8'
  const paddedMonth = padNum(adjustedMonthIndex + 1)

  return `${fiscalYearStart ? 'FY' : ''}${fiscalYear}-${paddedMonth}`
}

export const getFiscalQuarterHeadingFromDate = (date: Date, fiscalYearStart?: Month) => {
  const year = date.getFullYear()
  const monthIndex = date.getMonth()
  const month = Months[monthIndex] // Retrieve month enumeration from index
  const fiscalYear = getFiscalYearFromCalendarYear(year, month, fiscalYearStart)
  const quarter = getQuarter(month, fiscalYearStart) // Using the getQuarter function to determine the correct quarter based on fiscal start

  const prefix = fiscalYearStart ? `FY${fiscalYear}` : fiscalYear
  return `${prefix}-${quarter}` // Properly formatted fiscal quarter heading
}

export const getFiscalYearHeadingFromDate = (date: Date, fiscalYearStart?: Month) => {
  const year = date.getFullYear()
  const month = Months[date.getMonth()] // Access month from Months array using index
  const fiscalYear = getFiscalYearFromCalendarYear(year, month, fiscalYearStart) // Calculate fiscal year based on start month

  // Prefix with 'FY' if fiscalYearStart is specified
  const prefixedYear = fiscalYearStart ? `FY${fiscalYear}` : `${fiscalYear}`
  return prefixedYear
}

export const pivotComparatorMonthDesc = (a: any, b: any) => {
  return monthDiff(a, b, true)
}

export const monthDiff = (a: any, b: any, desc: boolean = true) => {
  const [yearA, monthA] = a.split('-')
  const [yearB, monthB] = b.split('-')

  const yearDiff = intDiff(yearA, yearB, desc) // parseInt(yearB, 10) - parseInt(yearA, 10);
  const monthDiff = monthsDiff(MonthKeys, monthA, monthB, desc) // months.indexOf(monthB) - months.indexOf(monthA);

  if (yearDiff !== 0) {
    return yearDiff
  } else {
    return monthDiff
  }
}

export const monthsDiff = (months: string[], valueA: any, valueB: any, desc: boolean = true) => {
  if (desc) {
    return months.indexOf(valueB) - months.indexOf(valueA)
  } else {
    return months.indexOf(valueA) - months.indexOf(valueB)
  }
}

export const pivotComparatorYearDesc = (a: any, b: any) => {
  return intDiff(a, b, true)
}

// Quarter
export const pivotComparatorQuarterDesc = (a: any, b: any) => {
  return pivotComparatorQuarter(a, b, false)
}

export const pivotComparatorQuarter = (a: any, b: any, ascending: boolean) => {
  const orderMultiplier = ascending ? 1 : -1

  const yearA = parseInt(a.split('-Q')[0], 10)
  const quarterA = parseInt(a.split('-Q')[1], 10)

  const yearB = parseInt(b.split('-Q')[0], 10)
  const quarterB = parseInt(b.split('-Q')[1], 10)

  if (yearA !== yearB) {
    return (yearA - yearB) * orderMultiplier
  } else {
    return (quarterA - quarterB) * orderMultiplier
  }
}

export const pivotComparatorWeeklyDesc = (a: any, b: any) => {
  return weeklyDiff(a, b, true)
}

export const weeklyDiff = (a: any, b: any, desc: boolean = true) => {
  const [yearA, weekA] = a.split('-W')
  const [yearB, weekB] = b.split('-W')

  const yearDiff = intDiff(yearA, yearB, desc) // parseInt(yearB, 10) - parseInt(yearA, 10);
  const weekDiff = intDiff(weekA, weekB, desc) // parseInt(weekB, 10) - parseInt(weekA, 10);

  if (yearDiff !== 0) {
    return yearDiff
  } else {
    return weekDiff
  }
}

export const getDateTimeAgo = (count: number, type: 'day' | 'month' | 'year'): Date => {
  switch (type) {
    case 'day':
      return getDateDaysAgo(count)
    case 'month':
      return getDateMonthsAgo(count)
    case 'year':
      return getDateYearsAgo(count)
    default:
      throw new Error('Invalid time unit provided. Must be "day," "month," or "year."')
  }
}

export const getDateMonthsAgo = (months: number): Date => {
  const currentDate = new Date()
  currentDate.setMonth(currentDate.getMonth() - months)
  return currentDate
}

export const getDateYearsAgo = (years: number): Date => {
  const currentDate = new Date()
  currentDate.setFullYear(currentDate.getFullYear() - years)
  return currentDate
}

export const getDateDaysAgo = (days: number): Date => {
  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() - days)
  return currentDate
}

export const intDiff = (valueA: any, valueB: any, desc: boolean = true) => {
  if (desc) {
    return parseInt(valueB, 10) - parseInt(valueA, 10)
  } else {
    return parseInt(valueA, 10) - parseInt(valueB, 10)
  }
}

const padNum = (num: number) => `${num}`.padStart(2, '0')

export enum DateFormatterKeys {
  Jan1924 = 'Jan-19-24',
  YearMonthDay = '2024-01-24',
  YearMonth = '2024-01',
  MonthYear = 'Jan 2024',
  Month = 'Jan'
}

export const DateFormatters = {
  [DateFormatterKeys.Jan1924]: (year: number, month: Month, day: number) => {
    const monthAbbreviation = _.capitalize(month.toLowerCase())
    return `${monthAbbreviation}-${padNum(day)}-${year % 100}`
  },
  [DateFormatterKeys.YearMonthDay]: (year: number, month: Month, day: number) => {
    return `${year}-${padNum(getIndexFromMonth(month) + 1)}-${padNum(day)}`
  },
  [DateFormatterKeys.YearMonth]: (year: number, month: Month) => {
    return `${year}-${padNum(getIndexFromMonth(month) + 1)}`
  },
  [DateFormatterKeys.MonthYear]: (year: number, month: Month) => {
    const monthAbbreviation = _.capitalize(month.toLowerCase())
    return `${monthAbbreviation} ${year}`
  },
  [DateFormatterKeys.Month]: (month: Month) => {
    return _.capitalize(month.toLowerCase())
  }
}

export const formatYearMonthDay = (year: number, month: Month, day: number) =>
  `${year}-${padNum(getIndexFromMonth(month) + 1)}-${padNum(day)}`

export const dateStringToLocalDate = (date: string, type: 'startOfDay' | 'endOfDay') => {
  const [year, month, day] = date.split('-')
  const result = new Date(+year, +month - 1, +day)

  if (type === 'startOfDay') {
    result.setHours(0, 0, 0, 0) // Set the time to the start of the day (00:00:00.000)
  } else if (type === 'endOfDay') {
    result.setHours(23, 59, 59, 999) // Set the time to the end of the day (23:59:59.999)
  }

  return result
}

export function getPreviousPeriodDate(date: string) {
  const [year, ...rest] = date.split('-')
  const previousYear = parseInt(year) - 1
  return `${previousYear}-${rest.join('-')}`
}

function dateString(date: DateInfo, dateFrequency: Frequency, previous_period: boolean) {
  let year = date.year
  if (previous_period) {
    year -= 1
  }
  switch (dateFrequency) {
    case Frequency.Yearly:
      return `${year}`
    case Frequency.Quarterly:
      return `${(date as YearQuarterInfo).quarter} ${year}`
    case Frequency.Weekly:
      return `W${(date as YearWeekInfo).week} ${year}`
    case Frequency.Daily:
      return `${(date as YearMonthDayInfo).day} ${capitalizeFirstLetter((date as YearMonthDayInfo).month, true)} ${year}`
    case Frequency.Monthly:
    default:
      return `${capitalizeFirstLetter((date as YearMonthInfo).month, true)} ${year}`
  }
}

export function formatDateRangeToLabel({
  startDate,
  endDate,
  frequency = Frequency.Monthly,
  previousPeriod = false
}: {
  startDate?: DateInfo | null
  endDate?: DateInfo
  frequency?: Frequency
  previousPeriod?: boolean
}) {
  if (!startDate || !endDate) return ''

  let startLabel = ''
  if (startDate) {
    startLabel += dateString(startDate, frequency, previousPeriod)
  } else {
    startLabel += ''
  }

  let endLabel = ''

  if (endDate) {
    endLabel += dateString(endDate, frequency, previousPeriod)
  } else {
    endLabel += ''
  }

  if (startLabel === endLabel) {
    return startLabel
  } else {
    return `${startLabel} - ${endLabel}`
  }
}

export function dateFilterFrequencyForPayload(frequency: Frequency) {
  const frequencyMap = {
    [Frequency.Yearly]: 'year',
    [Frequency.Monthly]: 'month',
    [Frequency.Weekly]: 'week',
    [Frequency.Quarterly]: 'quarter',
    [Frequency.Daily]: 'day'
  }

  return frequencyMap[frequency]
}

export const DaysInMonth = {
  [Month.Jan]: 31,
  [Month.Feb]: [28, 29],
  [Month.Mar]: 31,
  [Month.Apr]: 30,
  [Month.May]: 31,
  [Month.Jun]: 30,
  [Month.Jul]: 31,
  [Month.Aug]: 31,
  [Month.Sep]: 30,
  [Month.Oct]: 31,
  [Month.Nov]: 30,
  [Month.Dec]: 31
}

// converts 2015-08-31 to 31 Aug, 2015

const dateLongFormatter = new Intl.DateTimeFormat('en-us', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  timeZone: 'UTC'
})

// eslint-disable-next-line no-date-parsing/no-date-parsing
export const formatDateLong = (dateString: string) => {
  const date = toUTCDateTime(dateString)
  if (isNaN(date.getTime())) {
    throw new Error(`Invalid date: ${dateString}`)
  }
  return dateLongFormatter.format(date)
}

const dateLongFormatterLocal = new Intl.DateTimeFormat('en-us', {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
})
export const formatDateLongLocal = (date: Date) => {
  return dateLongFormatterLocal.format(date)
}

export const addDaysToDate = (date: Date, days: number) => {
  const newDate = new Date(date.getTime())
  newDate.setUTCDate(newDate.getUTCDate() + days)
  return newDate
}

export const MonthAbbreviations = {
  [Month.Jan]: 'January',
  [Month.Feb]: 'February',
  [Month.Mar]: 'March',
  [Month.Apr]: 'April',
  [Month.May]: 'May',
  [Month.Jun]: 'June',
  [Month.Jul]: 'July',
  [Month.Aug]: 'August',
  [Month.Sep]: 'September',
  [Month.Oct]: 'October',
  [Month.Nov]: 'November',
  [Month.Dec]: 'December'
}

export const convertCalendarInfoToFiscalInfo = (
  date: YearMonthDayInfo,
  fiscalYearStart: Month | undefined
) => {
  const fiscalYear = getFiscalYearFromCalendarYear(date.year, date.month, fiscalYearStart)
  return {
    year: fiscalYear,
    month: date.month,
    day: date.day
  }
}

export const convertYearMonthDayInfoToCalendarDate = (
  date: YearMonthDayInfo,
  fiscalYearStart: Month | undefined
) => {
  const year = getCalendarYearFromFiscalYear(date.year, date.month, fiscalYearStart)
  return { year: year, month: date.month, day: date.day }
}

export const yearStartYMD = (year: number, fiscalYearStart: Month | undefined) => {
  return {
    year: year,
    month: fiscalYearStart || Month.Jan,
    day: 1
  }
}

export const yearEndYMD = (year: number, fiscalYearStart: Month | undefined) => {
  const endMonth = getPreviousMonth(fiscalYearStart || Month.Jan)
  const days = getDaysInMonth(year, endMonth, fiscalYearStart)
  return { year: year, month: endMonth, day: days }
}

export const todayYMD = (fiscalYearStart: Month | undefined) => {
  const date = new Date()
  const currentFiscalYear = getFiscalYearFromCalendarYear(
    date.getFullYear(),
    getMonthFromIndex(date.getMonth()),
    fiscalYearStart
  )

  return {
    year: currentFiscalYear,
    month: getMonthFromIndex(date.getMonth()),
    day: date.getDate()
  }
}

export { getWeekColumnLabel }

export const formatDateInfo = (dateInfo: DateInfo) => {
  if ('month' in dateInfo) {
    return `${dateInfo.year}-${dateInfo.month}`
  } else if ('week' in dateInfo) {
    return `${dateInfo.year}-W${dateInfo.week}`
  } else if ('quarter' in dateInfo) {
    return `${dateInfo.year}-${dateInfo.quarter}`
  } else if ('day' in dateInfo) {
    // @ts-ignore
    return `${dateInfo.year}-${dateInfo.month}-${dateInfo.day}`
  } else if ('year' in dateInfo) {
    return `${dateInfo.year}`
  }
}

export const displayDuration = (startTime: string, endTime: string) => {
  if (!startTime || !endTime) return ''

  const start = parseISO(startTime)
  const end = parseISO(endTime)

  const milliseconds = differenceInMilliseconds(end, start)
  const seconds = differenceInSeconds(end, start)
  const minutes = differenceInMinutes(end, start)
  const hours = differenceInHours(end, start)

  const formatUnit = (value: number, unit: string) => `${value.toString().padStart(2, ' ')}${unit}`

  if (hours > 0) {
    const remainingMinutes = minutes % 60
    return remainingMinutes > 0
      ? `${formatUnit(hours, 'h')} ${formatUnit(remainingMinutes, 'm')}`
      : formatUnit(hours, 'h')
  } else if (minutes > 0) {
    const remainingSeconds = seconds % 60
    return remainingSeconds > 0
      ? `${formatUnit(minutes, 'm')} ${formatUnit(remainingSeconds, 's')}`
      : formatUnit(minutes, 'm')
  } else if (seconds > 0) {
    return formatUnit(seconds, 's')
  } else {
    return `${formatUnit(milliseconds, 'ms')}`
  }
}

export const localTimezone = () => Intl.DateTimeFormat('en-US').resolvedOptions().timeZone

export function generateDateList(
  startDate?: string,
  endDate?: string,
  frequency: Frequency = Frequency.Monthly
) {
  if (!startDate || !endDate || !frequency) {
    return []
  }

  const dates = []

  const [startYear, startMonth] = startDate.split('-').map(Number)
  const [endYear, endMonth] = endDate.split('-').map(Number)

  if (startYear > endYear || (startYear === endYear && startMonth > endMonth)) {
    return []
  }

  if (frequency === Frequency.Monthly) {
    for (let year = startYear; year <= endYear; year++) {
      const currentStartMonth = year === startYear ? startMonth : 1
      const currentEndMonth = year === endYear ? endMonth : 12

      for (let month = currentStartMonth; month <= currentEndMonth; month++) {
        dates.push(`${year}-${padNum(month)}-01`)
      }
    }
  } else if (frequency === Frequency.Quarterly) {
    let currentYear = startYear
    let currentMonth = startMonth

    while (currentYear < endYear || (currentYear === endYear && currentMonth <= endMonth)) {
      dates.push(`${currentYear}-${padNum(currentMonth)}-01`)
      currentMonth += 3
      if (currentMonth > 12) {
        currentMonth = currentMonth % 12
        currentYear++
      }
    }
  } else if (frequency === Frequency.Yearly) {
    for (let year = startYear; year <= endYear; year++) {
      dates.push(`${year}-01-01`)
    }
  }

  return dates
}

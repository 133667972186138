import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// material-ui
import { Grid, Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'

import axiosServices from 'utils/axios'

// assets
import useAuth from '../../hooks/useAuth'

const ExportedReport = () => {
  const { key } = useParams()
  const { isLoggedIn, user } = useAuth()
  const navigate = useNavigate()

  const downloadFile = useCallback(async () => {
    try {
      const file_key = `${user?.id}/${key}`
      const response = await axiosServices.get(`/exported-reports?key=${file_key}`)
      const download_url = response.data.download_url

      window.location.href = download_url
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  }, [key, user?.id])

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login')
    } else {
      downloadFile()
    }
  }, [isLoggedIn, navigate, downloadFile])

  const reportDownload = (
    <>
      <Grid
        container
        spacing={10}
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{ minHeight: '100vh', pt: 1.5, pb: 1, overflow: 'hidden' }}
      >
        <Grid item xs={12}>
          <Stack spacing={2} justifyContent='center' alignItems='center'>
            <Typography variant='h4'>Starting Download...</Typography>
            <Typography
              variant='h5'
              color='textSecondary'
              align='center'
              sx={{ width: { xs: '73%', sm: '61%' } }}
            >
              The file will be downloaded shortly. If it doesn&apos;t start automatically, please
              click the button below.
            </Typography>
            <Button onClick={downloadFile}>Download Report</Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  )

  return isLoggedIn ? reportDownload : null
}

export default ExportedReport

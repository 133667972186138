import { cn } from '@utils/style-utils'

import { Separator } from '@components/core/separator'
import { Text } from '@components/core/text'

export function Panel({
  children,
  title,
  secondary,
  className
}: {
  children?: React.ReactNode
  title?: string
  secondary?: React.ReactNode
  className?: string
}) {
  return (
    <div
      className={cn(
        'flex flex-col rounded-lg border border-solid border-grey-light bg-white p-2 shadow-brand',
        className
      )}
    >
      {title && (
        <div className='flex items-center justify-between'>
          <Text variant='button' weight='semibold' className='leading-6'>
            {title}
          </Text>
          <div>{secondary}</div>
        </div>
      )}
      {!!title && !!children && <Separator className='my-2' />}
      {!!children && <div className='flex-1 overflow-scroll'>{children}</div>}
    </div>
  )
}

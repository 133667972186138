import { useCallback, useRef, useState } from 'react'

export const useScrollPosition = <T extends HTMLElement>() => {
  const [scrollX, setScrollX] = useState<number>(0)
  const [scrollY, setScrollY] = useState<number>(0)

  const previousNode = useRef<T | null>(null)

  const handleScroll = useCallback((event: Event) => {
    const target = event.target as T
    setScrollX(target.scrollLeft)
    setScrollY(target.scrollTop)
  }, [])

  const customRef = useCallback(
    (node: T | null) => {
      if (previousNode.current) {
        previousNode.current.removeEventListener('scroll', handleScroll)
      }

      if (node) {
        node.addEventListener('scroll', handleScroll)
      }

      previousNode.current = node
    },
    [handleScroll]
  )

  return [customRef, { scrollX, scrollY }] as [
    typeof customRef,
    { scrollX: number; scrollY: number }
  ]
}

import {
  AggregateOptions,
  IFieldAttribute,
  IModelFieldDataType,
  NoneType,
  isDataTypeBoolean,
  isDataTypeString
} from '../types/query-builder-types'

export const fieldSelected = (value: string) => !!value && value !== NoneType.NONE

export const clearableFields: Partial<Record<keyof IFieldAttribute, Array<keyof IFieldAttribute>>> =
  {
    is_date_hook: ['truncate', 'is_year_over_year', 'cutoffDate'],
    cutoffDate: ['is_date_hook', 'truncate', 'is_year_over_year', 'as_of_date'],
    presence: [
      'aggregate',
      'truncate',
      'is_date_hook',
      'is_year_over_year',
      'as_of_date',
      'expand_level',
      'show_total',
      'drilldown_field',
      'cutoffDate'
    ],
    drilldown_field: ['is_hidden', 'aggregate']
  }

export const aggregateOptionsForField = (fieldType: IModelFieldDataType) => {
  if (isDataTypeString(fieldType)) {
    return [
      { value: NoneType.NONE, label: 'None' },
      { value: AggregateOptions.COUNT, label: 'Count' },
      { value: AggregateOptions.COUNT_DISTINCT, label: 'Count Distinct' },
      { value: AggregateOptions.MIN, label: 'Min' },
      { value: AggregateOptions.MAX, label: 'Max' }
    ]
  }
  if (isDataTypeBoolean(fieldType)) {
    return [
      { value: NoneType.NONE, label: 'None' },
      { value: AggregateOptions.COUNT, label: 'Count' },
      { value: AggregateOptions.COUNT_DISTINCT, label: 'Count Distinct' }
    ]
  }

  return [
    { value: NoneType.NONE, label: 'None' },
    { value: AggregateOptions.SUM, label: 'Sum' },
    { value: AggregateOptions.AVG, label: 'Avg' },
    { value: AggregateOptions.COUNT, label: 'Count' },
    { value: AggregateOptions.COUNT_DISTINCT, label: 'Count Distinct' },
    { value: AggregateOptions.MIN, label: 'Min' },
    { value: AggregateOptions.MAX, label: 'Max' }
  ]
}

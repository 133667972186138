import { IGlobalOptions, IModuleDefinitions } from '@components/chart/types/module-types'

import { ComponentGenre } from '../../types/component-types'
import TreemapModules, { ITreemapModulesProps } from './treemap-modules'
import XYChartModules, { XYChartModulesProps } from './xy-chart-modules'

type ChartTypeSpecificProps =
  | ({ chartType: ComponentGenre.treemap } & ITreemapModulesProps)
  | ({ chartType?: Exclude<ComponentGenre, ComponentGenre.treemap> } & XYChartModulesProps)

export type ChartFactoryReturnType = { modules: IModuleDefinitions; options: IGlobalOptions }

export default class ChartFactory {
  static create({ chartType, ...rest }: ChartTypeSpecificProps): ChartFactoryReturnType {
    switch (chartType) {
      case ComponentGenre.treemap:
        return TreemapModules.create(rest as ITreemapModulesProps)
      default:
        return XYChartModules.create(rest as XYChartModulesProps)
    }
  }
}

import { ReactNode } from 'react'

import { Divider, Grid } from '@mui/material'

export default function BuilderLayout({
  Preview,
  Config,
  DataPreview
}: {
  Preview: ReactNode
  Config: ReactNode
  DataPreview: ReactNode
}) {
  return (
    <>
      <Grid container spacing={2} sx={{ minHeight: '70vh' }}>
        <Grid item xs={12} md={6}>
          {Preview}
        </Grid>
        <Grid item xs={12} md={6}>
          {Config}
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {DataPreview}
        </Grid>
      </Grid>
    </>
  )
}

export const Check = () => (
  <g>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.6936 6.27968C20.0915 6.66277 20.1034 7.29582 19.7203 7.69364L10.3333 17.4417L5.27968 12.1936C4.89659 11.7958 4.90854 11.1628 5.30636 10.7797C5.70418 10.3966 6.33723 10.4085 6.72032 10.8064L10.3333 14.5583L18.2797 6.30636C18.6628 5.90854 19.2958 5.89659 19.6936 6.27968Z'
      fill='currentColor'
    />
  </g>
)

import { useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router'

import { useDebounce } from '@uidotdev/usehooks'

import { Skeleton } from '@core/skeleton'
import { Tabs, TabsList, TabsTrigger } from '@core/tab'
import { Text } from '@core/text'

import Button from '@components/core/button'
import { SearchInput } from '@components/core/search-input'

import { useCheckPathSelected } from '@hooks/use-check-path-selected'

import { initComponentManagement } from '@store/actions/component-management'
import { useDispatch, useSelector } from '@store/index'
import { selectTitle } from '@store/slices/component/basic-config'
import { setSearchText } from '@store/slices/component/query-config'
import { selectSearchText } from '@store/slices/component/query-config'

import { DrilldownProvider } from 'contexts/drilldown-context'

import { QueryResultProvider } from '../contexts/query-result-context'
import { QueryTransformedResultProvider } from '../contexts/query-transformed-result-context'
import { useFetchComponent } from '../queries/fetch-component'
import { SaveComponent } from './save-component'
import { TitleDescriptionForm } from './title-description-form'

enum EditFlowTabs {
  DATA_SELECTION = 'data-selection',
  DATA_PREVIEW = 'data-preview',
  VISUALIZE = 'visualize'
}

export function EditFlow() {
  const { id } = useParams<{ id: string }>()
  const { isPending, data } = useFetchComponent(id)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // selected tab
  const dataSelectionSelected = useCheckPathSelected(EditFlowTabs.DATA_SELECTION)
  const dataPreviewSelected = useCheckPathSelected(EditFlowTabs.DATA_PREVIEW)
  const visualizeSelected = useCheckPathSelected(EditFlowTabs.VISUALIZE)
  let tabValue: string | undefined = undefined
  if (dataSelectionSelected) tabValue = EditFlowTabs.DATA_SELECTION
  if (dataPreviewSelected) tabValue = EditFlowTabs.DATA_PREVIEW
  if (visualizeSelected) tabValue = EditFlowTabs.VISUALIZE

  // basic config
  const title = useSelector(selectTitle)

  useEffect(() => {
    dispatch(initComponentManagement(data))
  }, [data, dispatch])

  // search bar
  const searchText = useSelector(selectSearchText)
  const [searchInputValue, setSearchInputValue] = useState(searchText)
  const debouncedSearchValue = useDebounce(searchInputValue, 300) // 300ms delay

  useEffect(() => {
    dispatch(setSearchText(debouncedSearchValue))
  }, [debouncedSearchValue, dispatch])

  return (
    <DrilldownProvider componentId={`component_${id}`}>
      <QueryResultProvider>
        <QueryTransformedResultProvider>
          <div className='size-full'>
            <div className='grid grid-cols-3 items-center border-0 border-b border-solid border-grey-light bg-white px-4 py-1'>
              <div className='flex flex-row items-center gap-2 overflow-hidden'>
                <div className='flex min-w-0 shrink flex-col justify-center'>
                  {isPending ? (
                    <Skeleton className='h-6 w-48' />
                  ) : (
                    <Text variant='inlineFormTitle' className='truncate'>
                      {title}
                    </Text>
                  )}
                </div>
                <div className='flex shrink-0 items-center'>
                  {isPending ? <Skeleton className='h-8 w-4' /> : <TitleDescriptionForm />}
                </div>
                {tabValue === EditFlowTabs.DATA_SELECTION && (
                  <div className='mr-2 flex max-w-[300px] grow items-center border-0 border-l border-solid border-grey-lighter pl-2'>
                    <SearchInput
                      className='mt-px h-[30px]'
                      value={searchInputValue}
                      onChange={(value) => setSearchInputValue(value)}
                      clearable
                      fullWidth
                    />
                  </div>
                )}
              </div>
              <div className='-mb-px flex justify-center'>
                <Tabs id='component-management-edit-nav' value={tabValue} onValueChange={navigate}>
                  <TabsList className='grid grid-cols-3 gap-2'>
                    <TabsTrigger value='data-selection'>Data Selection</TabsTrigger>
                    <TabsTrigger value='data-preview'>Data Preview</TabsTrigger>
                    <TabsTrigger value='visualize'>Visualize</TabsTrigger>
                  </TabsList>
                </Tabs>
              </div>
              <div className='flex items-center justify-end gap-2'>
                <Button variant='outline' size='small' onClick={() => navigate(`../v1/${id}`)}>
                  V1
                </Button>
                <Button variant='outline' size='small' onClick={() => navigate('../..')}>
                  Back
                </Button>
                <SaveComponent />
              </div>
            </div>
            <div className='h-[calc(100vh-80px)] overflow-hidden bg-grey-backgroundfb'>
              <Outlet />
            </div>
          </div>
        </QueryTransformedResultProvider>
      </QueryResultProvider>
    </DrilldownProvider>
  )
}

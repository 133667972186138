import { useQuery } from '@tanstack/react-query'

import { camelCaseKeys } from '@utils/case-conversion'

import useAuth from 'hooks/useAuth'
import { QUERY_STALE_TIME_DEFAULT } from 'queries/constants'
import axiosServices from 'utils/axios'

import { IConnectedSourceSystem } from '../types'

const fetchConnectedSourceSystems = async (
  businessId: number | undefined
): Promise<IConnectedSourceSystem[]> => {
  const response = await axiosServices.get(`/businesses/${businessId}/connected_source_systems`)
  const connectedSourceSystems = camelCaseKeys(response.data, [
    'connector.connector_config',
    'connector_spec'
  ]) as IConnectedSourceSystem[]
  return connectedSourceSystems
}

export const useFetchConnectedSourceSystems = () => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-connected-source-systems', user?.business_id],
    enabled: !!user?.business_id,
    queryFn: () => fetchConnectedSourceSystems(user?.business_id),
    staleTime: QUERY_STALE_TIME_DEFAULT
  })
}

import { useQuery } from '@tanstack/react-query'

import { camelCaseKeys } from '@utils/case-conversion'

import axiosServices from 'utils/axios'

export interface DataHealthCheck {
  streamName: string
  streamNamespace: string
  syncMode: string
  primaryKey: string
  cursorField: string
  stateType: string
  cursor: string
  rowCountDiff: number
  sourceRowCount: number
  destinationRowCount: number
  sourceCursorValues: number
  destCursorValues: number
  rowsAdded: number
  rowsUpdated: number
  rowsDeleted: number
}

const fetchDataHealthChecks = async (
  businessId: number,
  id: string
): Promise<DataHealthCheck[]> => {
  const response = await axiosServices.get(
    `/businesses/${businessId}/connected_source_systems/${id}/data_health_checks`
  )
  return camelCaseKeys(response.data)
}

export const useFetchDataHealthChecks = ({
  id,
  businessId
}: {
  id?: string
  businessId?: number
}) => {
  return useQuery<DataHealthCheck[]>({
    queryKey: ['fetch-css-data-health-checks', id],
    enabled: !!businessId && !!id,
    queryFn: () => fetchDataHealthChecks(businessId!, id!)
  })
}

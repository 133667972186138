import { Page } from 'types/page'
import { FILTER_IDENTIFIER, FILTER_OPTIONS } from 'types/page-filters'

export function resolveInternalPageSettings(
  page: Page,
  { addDimensionFilters }: { addDimensionFilters: boolean } = { addDimensionFilters: false }
) {
  let searchFilters = _.get(page, 'settings.searchFilters')
  const className = _.get(page, 'settings.className', '')
  if (addDimensionFilters) {
    searchFilters = searchFilters || {}
    searchFilters[_.camelCase(FILTER_IDENTIFIER.DIMENSION_FILTERS)] = _.cloneDeep(
      FILTER_OPTIONS[FILTER_IDENTIFIER.DIMENSION_FILTERS]
    )
  }
  return {
    id: page.id,
    title: page.title,
    layout_type: page.layoutType,
    settings: { search_filters: searchFilters, className }
  }
}

const COMPONENT_MANAGEMENT_ROUTE = '/component-management'
const PAGE_BUILDER_ROUTE = '/page-builder'
const FEATURE_FLAGS_ROUTE = '/feature-flags'
const CHARGER_DATA_CONNECTIONS_ROUTE = '/charger-data-connections'
const CLIENT_DATA_CONNECTIONS_ROUTE = '/data-connections'

export function isComponentManagementPage(page: Page) {
  return _.isEqual(page.pageRoute, COMPONENT_MANAGEMENT_ROUTE)
}

export function findComponentManagementPage(pages: Page[]) {
  return _.find(pages, (page: Page) => isComponentManagementPage(page))
}

export function isPageBuilderPage(page: Page) {
  return _.isEqual(page.pageRoute, PAGE_BUILDER_ROUTE)
}

export function findPageBuilderPage(pages: Page[]) {
  return _.find(pages, (page: Page) => isPageBuilderPage(page))
}

export function isFeatureFlagsPage(page: Page) {
  return _.isEqual(page.pageRoute, FEATURE_FLAGS_ROUTE)
}

export function findFeatureFlagsPage(pages: Page[]) {
  return _.find(pages, (page: Page) => isFeatureFlagsPage(page))
}

export function isChargerDataConnectionsPage(page: Page) {
  return _.isEqual(page.pageRoute, CHARGER_DATA_CONNECTIONS_ROUTE)
}

export function findChargerDataConnectionsPage(pages: Page[]) {
  return _.find(pages, (page: Page) => isChargerDataConnectionsPage(page))
}

export function isClientDataConnectionsPage(page: Page) {
  return _.isEqual(page.pageRoute, CLIENT_DATA_CONNECTIONS_ROUTE)
}

export function findClientDataConnectionsPage(pages: Page[]) {
  return _.find(pages, (page: Page) => isClientDataConnectionsPage(page))
}

import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { InputSize } from '@core/input'

import { SearchInput } from '@components/core/search-input'

import { RootState } from 'store'
import { clearGridSearchTerm, selectGridSearchTerm, updateGridSearchTerm } from 'store/slices/grid'

import { AgGridRef, useGetRegisteredAgGridRefs } from '../../contexts/grid-ref-context'

interface AgGridSearchInputProps {
  id?: string
  gridRef?: AgGridRef
  onSearchChange?: (searchTerm: string) => void
  className?: string
  inputSize?: InputSize
}

const AgGridSearchInput: React.FC<AgGridSearchInputProps> = ({
  id,
  gridRef,
  onSearchChange,
  className,
  inputSize = 'default'
}) => {
  const dispatch = useDispatch()
  const searchTerm = useSelector((state: RootState) => selectGridSearchTerm(state, id))

  const globalRefs = useGetRegisteredAgGridRefs()

  const handleSearchChange = (value: string) => {
    dispatch(updateGridSearchTerm({ id, searchTerm: value }))
    if (onSearchChange) {
      onSearchChange(value)
    }

    searchData()
  }

  const applySearch = useCallback(
    (ref: AgGridRef) => {
      if (ref?.current?.api?.setGridOption) {
        ref.current!.api.setGridOption('quickFilterText', searchTerm)
      }
    },
    [searchTerm]
  )

  const searchData = useCallback(() => {
    if (gridRef) {
      applySearch(gridRef)
    } else {
      globalRefs?.forEach((ref) => {
        applySearch(ref)
      })
    }
  }, [gridRef, globalRefs, applySearch])

  useEffect(() => {
    searchData()
  }, [searchData, searchTerm])

  useEffect(() => {
    return () => {
      dispatch(clearGridSearchTerm({ id }))
    }
  }, [dispatch, id])

  return (
    <SearchInput
      value={searchTerm}
      onChange={handleSearchChange}
      clearable={!!searchTerm}
      className={className}
      inputSize={inputSize}
    />
  )
}

export default AgGridSearchInput

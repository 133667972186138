import { useEffect } from 'react'

const useGridLoadingOverlay = (gridRef: any, loading: boolean) => {
  useEffect(() => {
    if (!gridRef.current || !gridRef.current.api) return

    gridRef.current.api.setGridOption('loading', loading)
  }, [gridRef, loading])
}

export default useGridLoadingOverlay

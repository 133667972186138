import { useEffect, useState } from 'react'

import { PageSettingID } from '../pages/layout-view/types'
import axiosServices from '../utils/axios'
import useAuth from './useAuth'

const usePageData = (pageID: PageSettingID, defaultLayoutStructure = []) => {
  const [page, setPage] = useState<any>({ layout_structure: defaultLayoutStructure })

  const { user } = useAuth()

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        if (pageID) {
          const response = await axiosServices.get(
            `/businesses/${user?.business_id}/pages/${pageID}`
          )
          setPage(response.data)
        }
      } catch (error) {
        console.error('Error fetching page:', error)
      }
    }

    fetchPageData().then(() => console.log('Page updated.'))
  }, [pageID, user?.business_id])

  return { page, layout_structure: page.layout_structure }
}

export default usePageData

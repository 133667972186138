import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

/**
 * Adjusts title position with respect to y-axis;
 * sets titles max width
 */
export function titlePositionAdjustment({
  chart,
  headingContainer,
  yAxis
}: {
  chart: am5xy.XYChart
  headingContainer: am5.Container
  yAxis:
    | am5xy.ValueAxis<am5xy.AxisRenderer>
    | am5xy.CategoryAxis<am5xy.AxisRenderer>
    | am5xy.DateAxis<am5xy.AxisRenderer>
}) {
  chart.plotContainer.events.on('boundschanged', function () {
    // full width
    const fullWidth = headingContainer.width()

    // right width
    let rightLegendWidth = 0
    const legendContainer: am5.Container = headingContainer.children.getIndex(1)! as am5.Container
    if (legendContainer) {
      const legend = legendContainer.children.getIndex(0)! as am5.Legend
      if (legend) {
        rightLegendWidth = legend.width()
      }
    }

    // title width
    const titleContainer: am5.Container = headingContainer.children.getIndex(0)! as am5.Container
    let titleTextWidth = 0
    if (!titleContainer) return
    const titleLabel: am5.Label = titleContainer.children.getIndex(0)! as am5.Label
    if (!titleLabel) return

    if (titleLabel) {
      titleTextWidth = titleLabel.text?.width() || 0
    }
    if (!titleTextWidth) return

    const yAxisWidth = yAxis.width()
    const allowPaddingLeft =
      yAxisWidth /* yaxis spacing */ +
        titleTextWidth /* title spacing */ +
        -20 /* gap; ideally it should be positive, but seems like calculation is off */ +
        rightLegendWidth /* legend width, when present */ <
      fullWidth

    // setMaxWidth
    titleLabel.set('maxWidth', fullWidth - rightLegendWidth + (allowPaddingLeft ? yAxisWidth : 0))

    // calculation
    if (allowPaddingLeft) {
      titleLabel.set('paddingLeft', yAxisWidth)
    } else {
      titleLabel.set('paddingLeft', 0)
    }
  })
}

import React from 'react'

import { ViewMode } from '../../types'
import FreeStyleSection from '../container_components/freestyle-section'

const LayoutViewComp = ({ layoutStructure }: { layoutStructure: any }) => {
  return (
    <div>
      {layoutStructure.map((row: any, index: any) => {
        const currentPath = `${index}`
        return (
          <React.Fragment key={row.id}>
            <FreeStyleSection
              mode={ViewMode.view}
              isDraggable={false}
              isResizable={false}
              gridLine={false}
              devMode={false}
              path={currentPath}
              key={row.id}
              id={row.id}
              section={row}
            />
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default LayoutViewComp

import { useMemo } from 'react'

import { ICellRendererParams } from 'ag-grid-community'

import { format } from 'date-fns'

export const DateCellRenderer = (props: ICellRendererParams & { format: string }) => {
  const formattedDate = useMemo(() => {
    if (!props.value) return ''

    try {
      // eslint-disable-next-line no-date-parsing/no-date-parsing -- params.value contains full date and time
      const dateInUserTimezone = new Date(props.value)
      const formatString = props.format || 'yyyy-MM-dd HH:mm'
      return format(dateInUserTimezone, formatString)
    } catch (_e) {
      return props.value
    }
  }, [props.value, props.format])

  return <>{formattedDate}</>
}

import { ReactNode, useEffect, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { RemoveScroll } from 'react-remove-scroll'
import { Link as RouterLink } from 'react-router-dom'

import { Hamburger, Icon } from '@components/icons'

import { BusinessSelector } from 'components/business-selector'
import { Separator } from 'components/core/separator'
import { Text } from 'components/core/text'
import useAuth from 'hooks/useAuth'
// https://github.com/radix-ui/primitives/discussions/1230
import { selectLastHeight, setHeaderHeight, setLastHeight } from 'store/slices/toast'
import { PageType } from 'types/page'
import { cn } from 'utils/style-utils'

import { selectCurrentPage, selectHeaderCollapsed } from '../../../store/slices/navigation'
import HeaderContent from './HeaderContent'

interface HeaderProps {
  open: boolean
  handleDrawerToggle: () => void
}

const Header = ({ open, handleDrawerToggle }: HeaderProps) => {
  const headerContent = useMemo(() => <HeaderContent />, [])
  const currentPage = useSelector(selectCurrentPage)
  const headerCollapsed = useSelector(selectHeaderCollapsed)
  const lastHeight = useSelector(selectLastHeight)
  const titleToDisplay = currentPage?.pageSettings?.title ?? 'Charger'

  useEffect(() => {
    document.title = `${titleToDisplay}`
  }, [titleToDisplay])

  const { userIsSuperAdmin } = useAuth() // TODO: This should be replaced with isChargerUserWithOmniAccess
  const isEditable = () => {
    return (
      userIsSuperAdmin() &&
      currentPage &&
      currentPage?.pageSettings?.layout_type !== PageType.INTERNAL
    )
  }
  const dispatch = useDispatch()
  const headerRef = useRef<HTMLDivElement>(null)
  const HEADER_Z_INDEX = 1202
  const HEADER_CHECKS_INTERVAL = 10
  const HEADER_STOP_CHECK_INTERVAL = 500

  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let intervalId: number
    // eslint-disable-next-line prefer-const
    let timeoutId: number

    const checkAndUpdateHeaderHeight = () => {
      const currentHeight = headerRef.current?.offsetHeight || 0
      if (currentHeight !== lastHeight) {
        dispatch(setHeaderHeight(currentHeight))
        dispatch(setLastHeight(currentHeight))
      }
    }

    // Function to clear interval and timeout
    const clearChecks = () => {
      if (intervalId) clearInterval(intervalId)
      if (timeoutId) clearTimeout(timeoutId)
    }

    // Start or restart the interval when headerCollapsed changes
    intervalId = setInterval(
      checkAndUpdateHeaderHeight,
      HEADER_CHECKS_INTERVAL
    ) as unknown as number

    // Stop the interval after 2000ms of no changes
    timeoutId = setTimeout(() => {
      clearInterval(intervalId)
    }, HEADER_STOP_CHECK_INTERVAL) as unknown as number

    // Cleanup function to clear interval and timeout when component unmounts or headerCollapsed changes
    return () => clearChecks()
  }, [dispatch, lastHeight, headerCollapsed])

  const mainHeader: ReactNode = (
    <div className='flex items-center justify-between'>
      <div className='flex items-center'>
        <div className='flex items-center'>
          <button
            aria-label={open ? 'close drawer' : 'open drawer'}
            onClick={handleDrawerToggle}
            className={`mx-4 flex cursor-pointer border-none bg-transparent px-0 hover:bg-transparent hover:text-primary focus:bg-transparent focus:text-primary focus:outline-none focus-visible:outline-offset-2 focus-visible:outline-primary-dark`}
          >
            <Icon icon={<Hamburger />} />
          </button>
        </div>
        <div className='flex items-center space-x-4'>
          <Separator orientation='vertical' className='h-10' />
          <Text variant='button' weight='semibold' className='text-nowrap text-primary'>
            {isEditable() ? (
              <span className='tooltip' data-tip='Open in Layout Editor'>
                <RouterLink to={`/pages/${currentPage?.pageSettings?.id}/edit`} className='link'>
                  {titleToDisplay}
                </RouterLink>
              </span>
            ) : (
              titleToDisplay
            )}
          </Text>

          <div className='hidden h-full items-center md:flex'>
            <Separator orientation='vertical' className='h-10' />
            <BusinessSelector />
            <Separator orientation='vertical' className='h-10' />
          </div>
        </div>
      </div>
      {headerContent}
    </div>
  )

  return (
    <div
      id='main-header-wrapper'
      ref={headerRef}
      style={{ zIndex: HEADER_Z_INDEX }}
      className={cn([
        ` ${RemoveScroll.classNames.zeroRight} fixed inset-x-0 top-0 z-10 h-[41px] border-0 border-b border-solid border-grey bg-white pr-4 transition-height`,
        headerCollapsed && 'h-0 overflow-hidden border-b-0'
      ])}
    >
      {mainHeader}
    </div>
  )
}

export default Header

import { Menu, MenuItem } from '@mui/material'

import { Icon, Plus } from '@components/icons'

import DropDownButton, { useAnchor } from './DropDownButton'

interface DropdownListProps {
  label: string
  onChange: (value: Record<string, any>) => void
  options: Record<string, any>[]
  idKey?: string
  labelKey?: string
}

const DropdownList = ({
  label,
  onChange,
  options,
  idKey = 'id',
  labelKey = 'label'
}: DropdownListProps) => {
  const { anchorEl, open, onClick, onClose } = useAnchor()

  return (
    <>
      <DropDownButton
        variant='outlined'
        open={open}
        onClick={onClick}
        endIcon={<Icon icon={<Plus />} />}
      >
        {label}
      </DropDownButton>
      <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
        {options.map((option) => (
          <MenuItem
            key={option[idKey]}
            onClick={() => {
              onChange(option)
              onClose()
            }}
          >
            {option[labelKey]}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default DropdownList

export const NorthEast = () => {
  return (
    <g>
      <path
        id='Vector 18'
        d='M7 17L17 7M17 7V13.25M17 7H10.75'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </g>
  )
}

import { AdvancedFilterModel, FilterModel, SortModelItem } from 'ag-grid-community'

import { SelectedDimensions } from '@components/control-panel/types'

import { Frequency } from 'types/filter'

import { IAxis } from './chart-builder-types'
import { IResultValue } from './shared-types'

export enum AggregateType {
  sum = 'sum',
  avg = 'avg',
  count = 'count',
  first = 'first',
  min = 'min',
  max = 'max'
}

export enum ComponentSource {
  external = 'external',
  static = 'static'
}

export enum ComponentScope {
  businessSpecific = 'business-specific',
  general = 'general'
}

export enum ComponentGenre {
  table = 'table',
  chart = 'chart',
  kpi = 'kpi',
  embedding = 'embedding',
  treemap = 'treemap'
}

export enum ComponentStatus {
  draft = 'draft',
  published = 'published'
}

export enum ComponentCategory {
  widget = 'widget'
}

export interface IBusiness {
  id: number
  name: string
}

export interface IUser {
  id: number
  email: string
  first_name: string
  last_name: string
}

export enum IDataType {
  NUMBER = 'number',
  TEXT = 'text',
  DATE_STRING = 'dateString',
  BOOLEAN = 'boolean'
}

export interface IHeader {
  name: string
  data_type: IDataType
  formula: boolean
  is_hidden: boolean
  is_aggregate: boolean
  is_date_hook: boolean | null
  previous_period: boolean
  new_header: boolean
}

export enum DateTruncationType {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year'
}

export type IMetadataBreakdowns = Record<string, string[]>

export interface IMetadata {
  current_drilldown_key: null | string
  pp_start_label: string
  pp_end_label: string
  cp_start_label: string
  cp_end_label: string
  full_period_label: string
  date_truncation: DateTruncationType
  drilldown_configs: Record<string, IAxis> // currently only supports drilldowns in charts
  kpi_mapping?: Record<string, number | null>
  breakdowns?: IMetadataBreakdowns
  sql?: string
}

export interface IActiveDrilldown {
  key: string
  filter?: IResultValue
}

export type IParameterizedFiltersParameters = Record<string, string | number | boolean>

export interface IServerComponentParamaters {
  start_date: string
  end_date: string
  active_drilldowns?: IActiveDrilldown[]
  date_truncation: DateTruncationType
  dimension_filters?: SelectedDimensions
  parameterized_filters?: IParameterizedFiltersParameters
  filter_model?: IComponentFilters
  sort_model?: IComponentSorts
}

export type IClientComponentParameters = Omit<IServerComponentParamaters, 'date_truncation'> & {
  date_truncation?: Frequency
}

export type IComponentAdvancedFilters = AdvancedFilterModel | null
export type IComponentHiddenFilters = FilterModel | null
export type IComponentFilters = IComponentAdvancedFilters | IComponentHiddenFilters | null
export type IComponentSortItem = SortModelItem
export type IComponentSorts = IComponentSortItem[] | null
export interface IComponentFormula {
  name: string
  data_type: IDataType
  formula: string
}

export type IDrilldowns = string[]

import { Box, DialogActions, DialogContent, Divider, Grid, Skeleton } from '@mui/material'

import SidebarContainer from './sidebar-container'

export default function LoadingSkeleton({ modelName }: { modelName: string }) {
  return (
    <>
      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Box sx={{ width: '100%', height: '100%' }}>
              <SidebarContainer modelName={modelName}>
                <Grid container spacing={2} direction='column' sx={{ pr: 2 }}>
                  {Array.from({ length: 20 }).map((_, index) => (
                    <Grid key={index} item>
                      <Grid
                        container
                        spacing={2}
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Grid item>
                          <Skeleton
                            variant='rectangular'
                            width={100 + Math.floor(Math.random() * 100)}
                          />
                        </Grid>
                        <Grid item sx={{ pt: 2 }}>
                          <Skeleton variant='circular' width={20} height={20} />
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </SidebarContainer>
            </Box>
          </Grid>
          <Divider orientation='vertical' flexItem sx={{ mr: '-1px' }} />
          <Grid item xs={12} md={8}>
            <div>Loading...</div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Skeleton variant='rectangular' width={100} height={35} />
        <Skeleton variant='rectangular' width={100} height={35} />
      </DialogActions>
    </>
  )
}

import { PayloadAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'

import { WithID } from '@store/page-util'

import { HANDLE_BUSINESS_SELECTOR_CHANGE, LOGIN } from 'store/reducers/actions'
import { AuthActionProps } from 'types/auth'

import { rehydrateStore } from '../actions/rehydrate-store'
import { RootState } from '../index'

interface ExpandedGroupState {
  expandedGroups: string[]
}

const expandedGroupsAdapter = createEntityAdapter<WithID<ExpandedGroupState>>()

const initialState = expandedGroupsAdapter.getInitialState<{ default: ExpandedGroupState }>({
  default: {
    expandedGroups: []
  }
})

const findOrCreateEntity = (state: typeof initialState, id: string) => {
  let entity = state.entities[id]
  if (!entity) {
    expandedGroupsAdapter.addOne(state, _.assign({ id }, _.cloneDeep(state.default)))
    entity = state.entities[id]
  }
  return entity
}

const expandedGroupsSlice = createSlice({
  name: '@EXPANDED_GROUPS',
  initialState,
  reducers: {
    addExpandedGroup: (state, action: PayloadAction<WithID<{ group: string }>>) => {
      const { id, group } = action.payload
      const entity = findOrCreateEntity(state, id)
      if (!entity.expandedGroups.includes(group)) {
        entity.expandedGroups.push(group)
      }
    },
    removeExpandedGroup: (state, action: PayloadAction<WithID<{ group: string }>>) => {
      const { id, group } = action.payload
      const entity = findOrCreateEntity(state, id)
      entity.expandedGroups = entity.expandedGroups.filter((g) => g !== group)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrateStore, (_state, action) => {
      return { ...initialState, ...action.payload.expandedGroups }
    })
    builder.addMatcher(
      (action) => [LOGIN, HANDLE_BUSINESS_SELECTOR_CHANGE].includes(action.type),
      (state, action: AuthActionProps) => {
        const defaultValues = state.default
        defaultValues.expandedGroups = []
      }
    )
  }
})

// Actions
export const { addExpandedGroup, removeExpandedGroup } = expandedGroupsSlice.actions

// Reducer
export const expandedGroupsReducer = expandedGroupsSlice.reducer

// Selectors
const getExpandedGroupsState = (id: string) => (state: RootState) =>
  _.get(state, ['expandedGroups', 'entities', id], state.expandedGroups.default)

export const selectExpandedGroups = (id: string) =>
  createSelector(getExpandedGroupsState(id), (expandedGroupsState) =>
    _.get(expandedGroupsState, ['expandedGroups'])
  )

import { AxiosResponse } from 'axios'
import axiosServices from 'utils/axios'
import { errorSnack } from 'utils/snackbar-utils'

import { ExportParams } from './types'

const exportExcelFile = async ({
  params,
  endpoint
}: {
  params: ExportParams
  endpoint: string
}): Promise<AxiosResponse<Blob>> => {
  const response = await axiosServices.post(endpoint, params, {
    responseType: 'blob',
    headers: {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  })

  return response
}

export const exportExcelByBackendSync = async ({
  endpoint,
  params,
  fileName
}: {
  endpoint: string
  params: ExportParams
  fileName: string
}): Promise<any> => {
  const data = await exportExcelFile({ params, endpoint })

  if (data === undefined) {
    errorSnack('Error while exporting to Excel file. Problem while processing the response.')
    return
  }

  const url = window.URL.createObjectURL(new Blob([data.data]))
  const a = document.createElement('a')
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url) // Clean up the URL object
  a.remove()
}

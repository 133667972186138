import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'

import { cn } from '@utils/style-utils'

interface ResizablePanelProps {
  direction: 'vertical' | 'horizontal'
  primaryContent: React.ReactNode
  secondaryContent?: React.ReactNode
  secondaryDefaultSize?: number // out of 100 (ex. 50 would be half/half split)
  minSize?: number // out of 100
}

export function ResizablePanel({
  direction,
  secondaryDefaultSize = 50,
  minSize = 10,
  primaryContent,
  secondaryContent
}: ResizablePanelProps) {
  const secondarySize = secondaryDefaultSize
  const primarySize = secondaryContent ? 100 - secondarySize : 100

  return (
    <PanelGroup direction={direction}>
      {/* Primary Panel */}
      <Panel id='primary-panel' order={1} defaultSize={primarySize} minSize={minSize}>
        {primaryContent}
      </Panel>

      {secondaryContent && (
        <>
          {/* Resize Handle */}
          <PanelResizeHandle
            className={cn(
              'bg-grey hover:bg-grey-dark',
              direction === 'vertical'
                ? 'h-[1px] w-full cursor-row-resize'
                : 'h-full w-[1px] cursor-col-resize'
            )}
          />

          {/* Secondary Panel */}
          <Panel id='secondary-panel' order={2} defaultSize={secondarySize} minSize={minSize}>
            {secondaryContent}
          </Panel>
        </>
      )}
    </PanelGroup>
  )
}

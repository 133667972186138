import { useEffect } from 'react'

import { AgGridRef } from 'contexts/grid-ref-context'

import { autoFitColumnsWithAPI } from '../utils'

export function useAutoFitOnDataLoaded({
  isLoading,
  gridRef,
  enabled = true
}: {
  isLoading?: boolean
  gridRef?: AgGridRef
  enabled?: boolean
}) {
  useEffect(() => {
    if (!enabled || !gridRef || !gridRef.current?.api) return
    autoFitColumnsWithAPI(gridRef.current.api)
  }, [isLoading, gridRef, enabled])
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import { queryErrorToast, querySuccessToast } from 'queries/query-toasts'
import axiosServices from 'utils/axios'

export const useTriggerAutomap = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      coaType
    }: {
      coaType: 'Income Statement' | 'Balance Sheet'
      hierarchyId?: number
    }) => {
      return axiosServices.post(
        `/businesses/${user?.business_id}/general_ledger_accounts/automap`,
        {
          coa_type: coaType
        }
      )
    },
    onSuccess: (_data, { hierarchyId = null }) => {
      querySuccessToast('Automapped Successfully!')
      queryClient.invalidateQueries({ queryKey: ['fetch-auto-mapped'] })
      queryClient.invalidateQueries({
        queryKey: ['fetch-unmapped', user?.business_id, hierarchyId]
      })
      queryClient.invalidateQueries({
        queryKey: ['fetch-gl-accounts-in-hierarchy', user?.business_id]
      })
      queryClient.invalidateQueries({
        queryKey: ['fetch-all-hierarchies-with-nodes', user?.business_id]
      })
    },
    onError: (error) => {
      queryErrorToast(error.message || 'Automap failed!')
      queryClient.invalidateQueries({ queryKey: ['fetch-auto-mapped'] })
      queryClient.invalidateQueries({ queryKey: ['fetch-unmapped'] })
    }
  })
}

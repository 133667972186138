import { useState } from 'react'
import { useNavigate } from 'react-router'

import Button from '@components/core/button'
import { Separator } from '@components/core/separator'
import { Text } from '@components/core/text'
import { Check, Icon } from '@components/icons'

import AuthWrapper from 'sections/auth/AuthWrapper'
import { CreateNewPassword } from 'sections/auth/auth-forms/CreateNewPassword'

export enum CreatePasswordType {
  RESET_PASSWORD = 'RESET_PASSWORD',
  ACCEPT_INVITE = 'ACCEPT_INVITE'
}
const isResetPassword = (createPasswordType: CreatePasswordType): boolean =>
  _.isEqual(createPasswordType, CreatePasswordType.RESET_PASSWORD)
const isAcceptInvite = (createPasswordType: CreatePasswordType): boolean =>
  _.isEqual(createPasswordType, CreatePasswordType.ACCEPT_INVITE)

const CreatePassword = ({ type }: { type: CreatePasswordType }) => {
  const [success, setSuccess] = useState(false)
  const navigate = useNavigate()

  if (success) {
    return (
      <AuthWrapper cardClassName='p-4'>
        <div className='flex flex-col items-center gap-4 p-0'>
          <div className='flex items-center gap-2'>
            <Text variant='h5'>Success</Text>
            <Icon icon={<Check />} className='text-primary' />
          </div>
          <Separator />
          {isResetPassword(type) && (
            <Text variant='body'>Your password has been reset successfully</Text>
          )}
          {isAcceptInvite(type) && (
            <Text variant='body'>Your password has been set successfully</Text>
          )}
          <Separator />
          <Button className='ml-auto mt-2' size='extra-small' onClick={() => navigate('/login')}>
            Proceed To Login
          </Button>
        </div>
      </AuthWrapper>
    )
  }
  return (
    <AuthWrapper cardClassName='p-4'>
      <div className='flex flex-col gap-4 p-0'>
        <Text variant='h5'>Set Password</Text>
        <Separator />
        <CreateNewPassword
          onSuccess={() => setSuccess(true)}
          apiUrl={isAcceptInvite(type) ? '/users/accept_invitation' : '/password'}
        />
      </div>
    </AuthWrapper>
  )
}

export default CreatePassword

import { useQuery } from '@tanstack/react-query'

import { QUERY_STALE_TIME_DEFAULT } from 'queries/constants'
import axiosServices from 'utils/axios'
import { camelCaseKeys } from 'utils/case-conversion'

import { ISourceApplicationType } from '../types'

const fetchSourceApplications = async (): Promise<ISourceApplicationType[]> => {
  const response = await axiosServices.get(`/source_applications`)
  return camelCaseKeys(response.data)
}

export const useFetchSourceApplications = () => {
  return useQuery({
    queryKey: ['fetch-application-types'],
    enabled: true,
    queryFn: () => fetchSourceApplications(),
    staleTime: QUERY_STALE_TIME_DEFAULT
  })
}

import { Skeleton } from '@components/core/skeleton'

import LazyComponent from 'components/core/LazyComponent'

export const DEFAULT_EDITOR_MAX_HEIGHT = '300px'

const LoadingComponent = () => (
  <div className='flex flex-col gap-y-1'>
    <Skeleton className='h-6 w-full' />
    <Skeleton className='h-6 w-full' />
    <Skeleton className='h-6 w-full' />
    <Skeleton className='h-6 w-full' />
  </div>
)

const RubyEditor = LazyComponent(() => import('./ruby-editor'), LoadingComponent)

const SQLEditor = LazyComponent(() => import('./sql-editor'), LoadingComponent)

const JSONEditor = LazyComponent(() => import('./json-editor'), LoadingComponent)

const PythonEditor = LazyComponent(() => import('./python-editor'), LoadingComponent)

export { RubyEditor, SQLEditor, JSONEditor, PythonEditor }

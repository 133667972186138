import { useEffect } from 'react'

import { IInstanceEffectProps } from '../types/module-types'
import { getSelfRef } from '../utils/module-utils'

export default function useSetInstanceData({
  propsRef,
  moduleName,
  instance
}: IInstanceEffectProps) {
  useEffect(() => {
    const series = getSelfRef({ ...propsRef.current, moduleName })
    series.data.setAll(instance.data)
  }, [instance.data, propsRef, moduleName])
}

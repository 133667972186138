import { useMemo, useState } from 'react'

import { EditorView } from '@uiw/react-codemirror'

export function useCursorTrackerExtension() {
  const [cursorPosition, setCursorPosition] = useState(0)

  const cursorTracker = useMemo(
    () =>
      EditorView.updateListener.of((update) => {
        if (update.selectionSet) {
          const cursorPosition = update.state.selection.main.from
          setCursorPosition(cursorPosition)
        }
      }),
    []
  )

  return { cursorPosition, cursorTracker }
}

import { ColDef } from 'ag-grid-community'

import { sourceSystemAccountIdCellRenderer } from '@components/financial/utils/render-utils'

export const sourceSystemAccountIdColDef: ColDef = {
  field: 'source_system_account_id',
  headerName: '',
  pinned: true,
  resizable: true,
  type: 'rightAligned',
  cellRenderer: sourceSystemAccountIdCellRenderer,
  filter: 'agTextColumnFilter',
  minWidth: 100
}

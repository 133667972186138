import * as React from 'react'
import { useMemo } from 'react'

import { cn } from '@utils/style-utils'

interface TextProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
  variant:
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'button'
    | 'body'
    | 'description'
    | 'details'
    | 'submenu'
    | 'cardValue'
    | 'tableValue'
    | 'inlineFormTitle'
    | 'tabDescription'
  weight?: 'light' | 'normal' | 'bold' | 'semibold' | 'extrabold'
}

export const Text = React.forwardRef<HTMLHeadingElement, TextProps>(
  ({ className, variant, weight, children, ...props }, ref) => {
    const fontWeightClass = useMemo(
      () => ({
        light: 'font-light',
        normal: 'font-normal',
        bold: 'font-bold',
        semibold: 'font-semibold',
        extrabold: 'font-extrabold'
      }),
      []
    )

    const variantClasses = useMemo(
      () => ({
        h3: 'text-h3',
        h4: 'text-h4',
        h5: 'text-h5',
        h6: 'text-h6',
        button: 'button',
        body: 'text-body',
        description: 'text-description',
        details: 'text-details italic text-grey-details',
        submenu: 'text-submenu',
        cardValue: 'text-card-value',
        tableValue: 'text-table-value',
        inlineFormTitle: 'text-inline-form-title',
        tabDescription: 'text-tab-description text-grey-dark'
      }),
      []
    )

    const classNames = useMemo(() => {
      return cn([
        'twp text-black m-0 p-0',
        variantClasses[variant],
        weight && fontWeightClass[weight],
        className
      ])
    }, [className, fontWeightClass, variant, variantClasses, weight])

    const tagMap = {
      h3: 'h3',
      h4: 'h4',
      h5: 'h5',
      h6: 'h6',
      body: 'p',
      button: 'span',
      description: 'p',
      details: 'p',
      submenu: 'p',
      cardValue: 'h3',
      tableValue: 'p',
      inlineFormTitle: 'h6',
      tabDescription: 'p'
    }

    return React.createElement(
      tagMap[variant],
      {
        className: classNames,
        ref: ref,
        ...props
      },
      children
    )
  }
)

import { Input } from '@components/core/input'
import { Select } from '@components/form/select'

import useDelayedUpdate from '@hooks/use-delayed-update'

import { useDispatch } from '@store/index'
import { updateYAxisData } from '@store/slices/component/chart-config'

import { IAxis, LegendType } from '../types/chart-builder-types'

export function LegendSettings({ item: data }: { item: IAxis }) {
  const dispatch = useDispatch()

  const legendTypeValue = _.get(data, 'legendType', LegendType.FromSeries)
  const [legendNameCustomValue, setLegendNameCustomValue] = useDelayedUpdate(
    _.get(data, 'legendNameCustom', ''),
    (value) => {
      dispatch(updateYAxisData({ id: data.id, legendNameCustom: value as string }))
    }
  )

  return (
    <div className='flex w-full flex-col gap-2'>
      <Select
        value={legendTypeValue}
        onChange={(value) => {
          dispatch(updateYAxisData({ id: data.id, legendType: value as LegendType }))
        }}
        options={[
          { value: LegendType.FromSeries, label: 'From Series' },
          { value: LegendType.FullPeriod, label: 'Full Period' },
          { value: LegendType.FuturePeriod, label: 'Future Period' },
          { value: LegendType.PastPeriod, label: 'Past Period' },
          { value: LegendType.Custom, label: 'Custom' }
        ]}
      />
      {legendTypeValue === LegendType.Custom && (
        <Input
          placeholder='Custom Legend Label'
          value={legendNameCustomValue}
          onChange={(e) => setLegendNameCustomValue(e.target.value)}
        />
      )}
    </div>
  )
}

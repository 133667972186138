import { Theme } from '@mui/material/styles'

export default function Menu(theme: Theme) {
  return {
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.25)'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.darker,
          fontWeight: 700,
          '&:hover': {
            backgroundColor: theme.palette.primary.A100,
            color: theme.palette.primary.darker
          },
          '&.MuiButtonBase-root.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff'
          },
          '& > .Mui-checked.MuiCheckbox-root': {
            color: 'inherit'
          }
        }
      }
    }
  }
}

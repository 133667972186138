import React, { useCallback } from 'react'

import { cn } from '@utils/style-utils'

import Button from '@components/core/button'
import { Text } from '@components/core/text'

export interface GridMenuButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  selected?: boolean
  disabled?: boolean
  className?: string
  children: React.ReactNode
}

export const GridMenuButton = (props: GridMenuButtonProps) => {
  const { children, disabled, selected, onClick, className = '', ...otherProps } = props

  return (
    <Button
      asChild
      aria-selected={selected}
      aria-disabled={disabled}
      disabled={disabled}
      className={cn(
        'flex cursor-pointer items-center justify-center border border-grey-light text-black',
        'aria-disabled:pointer-events-none aria-disabled:cursor-default aria-disabled:bg-grey-lighter aria-disabled:text-grey-dark',
        selected ? 'bg-primary text-white' : 'bg-white',
        className
      )}
      onClick={(e) => {
        if (disabled || !onClick) return
        onClick(e)
      }}
      {...otherProps}
    >
      <Text variant='button'>{children}</Text>
    </Button>
  )
}

interface Props<T> {
  data: T[]
  numColumns: number
  selectedItem?: number | string
  disabled?: (option: T) => boolean
  cellClassNames?: string
  keySelector: (option: T) => number | string
  render?: (option: T, idx: number) => React.ReactNode
  onChange: (option: T) => void
  className?: string
}

const GridMenuRenderHelper = <T,>(props: Props<T>) => {
  const {
    cellClassNames,
    render,
    keySelector,
    selectedItem,
    data,
    numColumns,
    onChange,
    disabled,
    className
  } = props

  const handleRef = useCallback((node: HTMLDivElement) => {
    if (!node) {
      return
    }
    const el = node.querySelector('[data-selected=true]')
    el?.scrollIntoView()
  }, [])

  return (
    <div
      ref={handleRef}
      style={{
        gridTemplateColumns: `repeat(${numColumns}, 1fr)`
      }}
      className={cn('grid', className)}
    >
      {data.map((cell, idx) => {
        return (
          <GridMenuButton
            key={keySelector(cell)}
            data-selected={keySelector(cell) === selectedItem}
            selected={keySelector(cell) === selectedItem}
            disabled={disabled?.(cell)}
            onClick={() => {
              onChange(cell)
            }}
            className={cellClassNames}
          >
            {!render ? `${cell}` : render(cell, idx)}
          </GridMenuButton>
        )
      })}
    </div>
  )
}

export default GridMenuRenderHelper

import { useEffect, useMemo, useRef } from 'react'

import { ColDef, FilterModel, IServerSideGetRowsParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { toClassName } from '@utils/string-utils'

import useChangeDetection from '@hooks/use-change-detection'

import { useDispatch, useSelector } from '@store/index'
import {
  modifyFilterModel,
  modifySortModel,
  selectSortModel
} from '@store/slices/component/table-parameters'
import { selectFilterModel } from '@store/slices/component/table-parameters'

import AgGrid from 'components/ag-grid'
import ComponentWidget from 'components/cards/component-widget'

import { IComponentSorts } from '../types/component-types'
import { IResultRow } from '../types/shared-types'
import { ITableColumn, ITableConfig } from '../types/table-builder-types'
import { ComponentPreviewProps } from '../viewer/types'
import { useBroadcastValue } from './hooks/use-broadcast-value'
import { useColDefs } from './hooks/use-col-defs'

type SuccessCallback = IServerSideGetRowsParams['success']
class ServerSideDatasource {
  firstLoad = true

  constructor(
    private registerSuccessCallback: (successCallback: SuccessCallback) => void,
    private initialFilterModel: FilterModel | null,
    private initialSortModel: IComponentSorts
  ) {}

  getRows(params: IServerSideGetRowsParams) {
    this.registerSuccessCallback(params.success)

    if (this.firstLoad) {
      params.api.setFilterModel(this.initialFilterModel)
      params.api.applyColumnState({
        state: this.initialSortModel || [],
        defaultState: { sort: null }
      })
      this.firstLoad = false
    }
  }
}

export function ServerSideTable(props: ComponentPreviewProps) {
  const { data, title, isFetching, gridOptions } = props
  const dispatch = useDispatch()
  const parametersFilters = useSelector(selectFilterModel(`${props.componentId}`))
  const parametersSorts = useSelector(selectSortModel(`${props.componentId}`))
  const gridRef = useRef<AgGridReact | null>(null)
  const config = useMemo(() => _.get(data, 'config') as ITableConfig, [data])

  const columns = useMemo(() => _.get(config, 'columns', []) as ITableColumn[], [config])
  const results = useMemo(() => _.get(data, 'results', []) as IResultRow[], [data])
  const columnDefs = useColDefs({ columns, gridRef, externalFiltersChanged: 0, showFilters: true })

  const { getBroadcastGridOptions, broadcastClassNames, selectRowOnDataRefreshed } =
    useBroadcastValue({ columns, gridRef })
  const successCallbackRef = useRef<SuccessCallback | null>(null)
  const [callbackRegistered, triggerCallbackRegistered] = useChangeDetection()
  const [dataRefreshed, triggerDataRefreshed] = useChangeDetection()

  useEffect(() => {
    if (!successCallbackRef.current || isFetching) return

    successCallbackRef.current({ rowData: results, rowCount: results.length })
    successCallbackRef.current = null
  }, [results, isFetching, callbackRegistered])

  useEffect(() => {
    if (isFetching) {
      gridRef.current?.api?.refreshServerSide({ purge: true })
    }
  }, [isFetching])

  useEffect(() => triggerDataRefreshed(), [results, triggerDataRefreshed])

  useEffect(() => {
    selectRowOnDataRefreshed()
  }, [dataRefreshed, selectRowOnDataRefreshed])

  const defaultColDef = useMemo<ColDef>(
    () => ({
      filter: true,
      floatingFilter: true,
      sortable: true,
      suppressHeaderMenuButton: true
    }),
    []
  )

  return (
    <ComponentWidget loading={isFetching} title={title}>
      <div className='size-full'>
        <AgGrid
          id={toClassName(`${props.componentId}-${title}`)}
          className={broadcastClassNames}
          loading={isFetching}
          style={{ height: '100%' }}
          ref={gridRef}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          removePivotHeaderRowWhenSingleValueColumn
          rowModelType='serverSide'
          rowBuffer={0}
          cacheBlockSize={5000}
          cacheOverflowSize={5}
          maxConcurrentDatasourceRequests={1}
          infiniteInitialRowCount={5000}
          onGridReady={(event) => {
            const dataSource = new ServerSideDatasource(
              (successCallback: SuccessCallback) => {
                successCallbackRef.current = successCallback
                triggerCallbackRegistered()
              },
              parametersFilters,
              parametersSorts
            )
            event.api.setGridOption('serverSideDatasource', dataSource)
          }}
          onFilterChanged={(event) => {
            dispatch(
              modifyFilterModel({
                filterModel: event.api.getFilterModel(),
                componentId: `${props.componentId}`
              })
            )
          }}
          onSortChanged={(event) => {
            const sortModel = _.map(
              _.filter(event.api.getColumnState(), (s) => !!s.sort),
              (s) => _.pick(s, ['colId', 'sort', 'sortIndex'])
            ) as IComponentSorts
            dispatch(modifySortModel({ sortModel, componentId: `${props.componentId}` }))
          }}
          maxBlocksInCache={10}
          allowDragFromColumnsToolPanel
          suppressDragLeaveHidesColumns
          autoSizeStrategy={{ type: 'fitCellContents' }}
          persistColumnState
          {...gridOptions}
          {...getBroadcastGridOptions()}
        />
      </div>
    </ComponentWidget>
  )
}

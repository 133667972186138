import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'

import { AgGridReact, AgGridReactProps } from 'ag-grid-react'

import { cn } from '@utils/style-utils'

import { AUTO_FIT_TIMEOUT_MS } from '@components/ag-grid'
import { autoFitAllColumns } from '@components/ag-grid/utils'

import './styles.css'
import { GridOptions } from 'ag-grid-community'

interface AgDataTableProps extends AgGridReactProps {
  loading?: boolean
  suppressAutoFitColumns?: boolean
  gridOptions?: GridOptions
}

export const AgDataTable = forwardRef((props: AgDataTableProps, ref) => {
  const [gridReady, setGridReady] = useState(false)
  const gridRef = useRef<AgGridReact | null>(null)

  const captureRef = (node: AgGridReact) => {
    if (ref) {
      if (typeof ref === 'function') {
        ref(node)
      } else {
        ref.current = node
      }
    }
    gridRef.current = node
  }

  const { loading, suppressAutoFitColumns = false } = props

  const showOverlay = useCallback((show: boolean) => {
    if (show) {
      gridRef.current?.api.showLoadingOverlay()
    } else {
      gridRef.current?.api.hideOverlay()
    }
  }, [])

  useEffect(() => {
    if (!gridReady || loading == null) return
    showOverlay(loading)
  }, [gridReady, loading, showOverlay])

  return (
    // eslint-disable-next-line tailwindcss/no-custom-classname
    <div
      className={cn('ag-theme-alpine ag-data-table', props.rowSelection && 'with-row-selection')}
    >
      <AgGridReact
        ref={captureRef}
        autoSizeStrategy={{ type: suppressAutoFitColumns ? 'fitGridWidth' : 'fitCellContents' }}
        enableRangeSelection={false}
        suppressRowDrag
        suppressRowTransform
        suppressCellFocus
        suppressContextMenu
        suppressMovableColumns
        onGridReady={(e) => {
          setGridReady(true)
          props.onGridReady?.(e)
        }}
        loadingOverlayComponentParams={{
          top:
            props.loadingOverlayComponentParams?.showHeader &&
            props.loadingOverlayComponentParams?.topOffset
              ? `${props.loadingOverlayComponentParams.topOffset}`
              : void 0,
          ...props.loadingOverlayComponentParams
        }}
        defaultColDef={{
          autoHeight: true,
          suppressHeaderMenuButton: true,
          suppressHeaderContextMenu: true,
          resizable: false
        }}
        columnMenu='legacy'
        {...props}
        onRowDataUpdated={(e) => {
          props.onRowDataUpdated?.(e)
          if (!suppressAutoFitColumns) {
            setTimeout(() => {
              autoFitAllColumns(e)
            }, AUTO_FIT_TIMEOUT_MS)
          }
        }}
      />
    </div>
  )
})

export const TrueFalse = () => (
  <>
    <path
      d='M6.50864 13C6.18948 13 5.95203 12.705 6.02018 12.3932L7.11026 7.40586C7.17841 7.09407 6.94095 6.7991 6.62179 6.7991H5.62602C5.30527 6.7991 5.06748 6.50138 5.13839 6.18857L5.31952 5.38947C5.37115 5.16169 5.57359 5 5.80715 5H11.3226C11.642 5 11.8795 5.29545 11.8109 5.60743L11.6351 6.40653C11.5847 6.6358 11.3815 6.7991 11.1468 6.7991H9.70837C9.47336 6.7991 9.27008 6.96275 9.2199 7.19234L8.03647 12.6068C7.98629 12.8363 7.78301 13 7.54801 13H6.50864Z'
      fill='currentColor'
    />
    <path
      d='M14.9379 4.87891C14.7342 4.87891 14.5443 4.98181 14.433 5.15246L5.93018 18.1932C5.66875 18.5941 5.95646 19.1252 6.43511 19.1252C6.63883 19.1252 6.82877 19.0223 6.94004 18.8516L15.4429 5.81091C15.7043 5.40995 15.4166 4.87891 14.9379 4.87891Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0.25'
    />
    <path
      d='M11.6222 20C11.3027 20 11.0651 19.7044 11.1339 19.3924L12.6766 12.3924C12.7271 12.1632 12.9302 12 13.1649 12H18.0058C18.3254 12 18.5629 12.2957 18.4941 12.6078L18.3309 13.3469C18.2804 13.576 18.0773 13.7391 17.8427 13.7391H14.8457C14.6103 13.7391 14.4068 13.9034 14.357 14.1335L14.2854 14.4648C14.218 14.7763 14.4554 15.0705 14.7741 15.0705H17.2391C17.5584 15.0705 17.7959 15.3656 17.7275 15.6775L17.5629 16.4286C17.5126 16.6581 17.3094 16.8216 17.0745 16.8216H14.1715C13.9373 16.8216 13.7344 16.9843 13.6835 17.2129L13.1501 19.6087C13.0992 19.8373 12.8963 20 12.662 20H11.6222Z'
      fill='currentColor'
    />
  </>
)

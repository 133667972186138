import React, { useState } from 'react'

import InfoIcon from '@mui/icons-material/Info'
import { Link } from '@mui/material'

import { awsCronExpressionValidator, humanizeCron } from '@utils/cron-utils'
import { cn } from '@utils/style-utils'

import { Text } from '@core/text'

import { Button } from '@components/core/button'
import { Input } from '@components/core/input'

import useAuth from '@hooks/useAuth'

import { IJobSchedule } from '../../queries/fetch-job-schedules'
import { useUpdateJobSchedule } from '../../queries/update-job-schedule'

interface UpdateScheduleFormProps {
  data: IJobSchedule
  onSuccess: () => void
  onCancel: () => void
}

export const UpdateScheduleForm: React.FC<UpdateScheduleFormProps> = ({
  onSuccess,
  onCancel,
  data
}) => {
  const { user } = useAuth()
  const cronPart = data.scheduleExpression.match(/cron\((.*)\)/)?.[1]
  const [cronVal, setCronVal] = useState<string>(cronPart || '')
  const [error, setError] = useState<string>('')

  const { mutate: updateJobSchedule } = useUpdateJobSchedule({ onSuccess })

  const handleCronChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setCronVal(value)

    try {
      awsCronExpressionValidator(value)
      setError('')
    } catch (err) {
      setError((err as Error).message)
    }
  }

  const handleSave = () => {
    const payload = {
      id: data.id,
      scheduleExpression: `cron(${cronVal})`,
      timezone: user?.business_local_timezone || 'UTC'
    }

    updateJobSchedule(payload)
  }

  return (
    <div>
      <div className={cn('flex items-center gap-2', 'w-full justify-between')}>
        <Button onClick={onCancel} type='button' variant='outline' size={'default'}>
          {'Cancel'}
        </Button>
        <Button
          onClick={handleSave}
          disabled={!!error}
          type='button'
          variant={error ? 'disabled' : 'primary'}
          size='default'
        >
          Save
        </Button>
      </div>
      <div className='mt-4 flex flex-col items-stretch'>
        <div className='flex items-center justify-between'>
          <Text variant='cardValue'>Update Schedule</Text>
        </div>

        <div className='flex items-center justify-between'>
          <Text variant='h6'>{data.name}</Text>
        </div>

        <div className='mt-2 flex items-center justify-between'>
          <Text variant='description'>Timezone</Text>
        </div>
        <Input value={user?.business_local_timezone} type='text' className={'w-96'} disabled />

        <div className='mt-2 flex items-center justify-between'>
          <Text variant='description'>Cron Expression</Text>
          <Link
            href='https://docs.aws.amazon.com/eventbridge/latest/userguide/eb-scheduled-rule-pattern.html'
            target='_blank'
            rel='noopener noreferrer'
            display='flex'
            alignItems='center'
          >
            <InfoIcon fontSize='small' />
          </Link>
        </div>
        <Input
          value={cronVal}
          type='text'
          placeholder='0 23 * * ? *'
          className={'w-96'}
          onChange={handleCronChange}
        />

        {error && (
          <Text variant='details' className='mt-2 text-error-darker'>
            Error: {error}
          </Text>
        )}
        <Text variant='details' className='mt-2'>
          {humanizeCron(cronVal, '')}
        </Text>
      </div>
    </div>
  )
}

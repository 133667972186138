import { Quarter, YearQuarterInfo } from 'types/filter'
import { Quarters } from 'utils/date-utils'

const cmpQuarter = (quarter1: Quarter, quarter2: Quarter) =>
  Quarters.indexOf(quarter1) - Quarters.indexOf(quarter2)

export const quarterMin = (quarter1: Quarter, quarter2: Quarter) =>
  cmpQuarter(quarter1, quarter2) < 0 ? quarter1 : quarter2

export const quarterMax = (quarter1: Quarter, quarter2: Quarter) =>
  cmpQuarter(quarter1, quarter2) > 0 ? quarter1 : quarter2

const cmpInfo = <T extends YearQuarterInfo>(info1: T, info2: T) => {
  if (info1.year !== info2.year) return info1.year - info2.year
  return cmpQuarter(info1.quarter, info2.quarter)
}

export const minInfo = <T extends YearQuarterInfo>(info1?: T, info2?: T) => {
  if (!info1) return info2
  if (!info2) return info1
  return cmpInfo(info1, info2) < 0 ? info1 : info2
}

export const maxInfo = <T extends YearQuarterInfo>(info1?: T, info2?: T) => {
  if (!info1) return info2
  if (!info2) return info1
  return cmpInfo(info1, info2) > 0 ? info1 : info2
}

export const outOfBounds = <T extends YearQuarterInfo>(info: T, minAllowed?: T, maxAllowed?: T) =>
  (minAllowed != null && cmpInfo(info, minAllowed) < 0) ||
  (maxAllowed != null && cmpInfo(info, maxAllowed) > 0)

export const navigationClamp = <T extends YearQuarterInfo>(
  info: T,
  navigationMode: 'year' | 'quarter',
  minAllowed?: T,
  maxAllowed?: T
) => {
  if (navigationMode === 'quarter') {
    return info
  }
  if (minAllowed && info.year === minAllowed.year) {
    return maxInfo(info, minAllowed)!
  }

  if (maxAllowed && info.year === maxAllowed.year) {
    return minInfo(info, maxAllowed)!
  }

  return info
}

import { useEffect, useRef, useState } from 'react'

export default function useTimeAgo(dateTimeString: string) {
  // eslint-disable-next-line no-date-parsing/no-date-parsing
  const givenDate = useRef(new Date(dateTimeString))

  const [diff, setDiff] = useState('')

  useEffect(() => {
    // eslint-disable-next-line no-date-parsing/no-date-parsing
    givenDate.current = new Date(dateTimeString)
  }, [dateTimeString])

  useEffect(() => {
    function calculateRelativeTime() {
      const currentDate = new Date()
      const difference = +currentDate - +givenDate.current

      const minutes = Math.floor(difference / 60000)
      const hours = Math.floor(minutes / 60)
      const days = Math.floor(hours / 24)

      if (days > 0) {
        return `${days} days ago`
      } else if (hours > 0) {
        return `${hours} hours ago`
      } else if (minutes > 0) {
        return `${minutes} minutes ago`
      } else {
        return 'Just now'
      }
    }

    const intervalId = setInterval(() => {
      setDiff(calculateRelativeTime())
    }, 30)

    return () => clearInterval(intervalId)
  }, [])

  return diff
}

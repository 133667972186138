import { useMutation, useQueryClient } from '@tanstack/react-query'

import { queryErrorToast, querySuccessToast } from 'queries/query-toasts'
import axiosServices from 'utils/axios'

export interface IAddNewConnectionBody {
  applicationName: string
  applicationIdType: number
  businessId: number
}

export const useCreateConnectedSourceSystem = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (body: IAddNewConnectionBody) => {
      return axiosServices.post(`/businesses/${body.businessId}/connected_source_systems`, {
        connected_source_system: {
          name: body.applicationName,
          source_application_id: body.applicationIdType
        }
      })
    },
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['fetch-connected-source-systems', variables.businessId]
      })
      queryClient.invalidateQueries({ queryKey: ['fetch-all-businesses-connected-source-systems'] })
      querySuccessToast('Connection added successfully')
    },
    onError: (error: any, variables) => {
      const message = _.get(error, 'errors[0]') || error.message || 'Something went wrong'
      const finalMessage = message.toLowerCase().includes('record not unique')
        ? 'Please choose a unique connection name'
        : message
      queryErrorToast(finalMessage)
    }
  })
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import axiosServices from '@utils/axios'
import { snakeCaseKeys } from '@utils/case-conversion'

import useAuth from '@hooks/useAuth'

import { queryErrorToast, querySuccessToast } from 'queries/query-toasts'

export interface IUpdateJobScheduleBody {
  id: number
  scheduleExpression?: string
}

export interface IUpdateJobScheduleProps {
  onSuccess?: () => void
}

export const useUpdateJobSchedule = (props: IUpdateJobScheduleProps) => {
  const queryClient = useQueryClient()
  const { user } = useAuth()

  return useMutation({
    mutationFn: (body: IUpdateJobScheduleBody) => {
      const payload: any = { data_replication_schedule: {} }

      const { id, ...updateData } = body
      payload.data_replication_schedule = snakeCaseKeys(updateData)

      return axiosServices.patch(
        `/businesses/${user?.business_id}/data_replication_schedule/${id}/update_schedule`,
        payload
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['fetch-job-schedules', user?.business_id] })
      props.onSuccess?.()
      querySuccessToast('Schedule updated successfully')
    },
    onError: (error: any) => {
      queryErrorToast(error.message)
    }
  })
}

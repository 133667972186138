import { useMemo } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

import { findClientDataConnectionsPage } from '@utils/layouts/page-settings'

import { RedirectTo } from '@components/redirect-to'

import ExportedReport from 'pages/exported-report/ExportedReport'
import { FinancialStatement } from 'pages/financial-statements/financial-statement'
import { Page, PageType, isClientPage, isInternalPage } from 'types/page'

import LazyComponent from '../components/core/LazyComponent'
import useAuth from '../hooks/useAuth'
import MainLayout from '../layout/MainLayout'
import { FinancialStatementTypes } from '../pages/financial-statements/types'
import AuthGuard from '../utils/route-guard/AuthGuard'
import LoginRoutes from './LoginRoutes'
import { InternalRoutes } from './internal-routes'
import { createDataConnectionRoute } from './route-definitions/data-connections-route'

const LayoutView = LazyComponent(import('pages/layout-view'))

const UserProfile = LazyComponent(import('pages/profiles/UserProfile'))
const UserTabPassword = LazyComponent(import('pages/profiles/user/TabPassword'))
const UserDetail = LazyComponent(import('pages/profiles/user/UserDetail'))

export default function ThemeRoutes({
  pages = [],
  isFetchingPages
}: {
  pages?: Page[]
  isFetchingPages: boolean
}) {
  const { isLoggedIn, user } = useAuth()

  const clientPages = useMemo(() => pages.filter((page: Page) => isClientPage(page)), [pages])
  const internalPages = useMemo(() => pages.filter((page: Page) => isInternalPage(page)), [pages])

  // Tackling dynamic imports with variable paths: https://v3.vitejs.dev/guide/features.html#glob-import
  // This finds all the files and creates a separate chunk for those files in the production build so that they can be used with dynamic imports
  // TODO: not all tsx files within the pages folder has to be lazy loaded though,
  const modules: Record<string, any> = useMemo(() => import.meta.glob('../pages/**/*.tsx'), [])

  const customRoutes = useMemo(() => {
    const customRoutesConfig = clientPages?.map((page: Page) => {
      const commonProps: any = {
        path: page.pageRoute,
        id: page.id,
        title: page.title
      }

      const pageSettings = {
        id: page.id,
        title: page.title,
        layout_type: page.layoutType,
        layout_structure: page.layoutStructure,
        settings: {
          search_filters: page.settings?.searchFilters,
          className: page.settings?.className
        }
      }

      if (_.isEqual(page.layoutType, PageType.STATIC_PAGE)) {
        let component = <div className='text-h3 font-bold text-error-dark'>Not Found!</div>

        if (page.metaData?.componentPath) {
          // TODO:  Use <Component later on for income_statement and balance_sheet type as well
          // TODO: legacy hardcoded implementation, this needs cleanup
          if (
            page.metaData?.type &&
            FinancialStatementTypes.includes(page.metaData.type.replace('_', '-'))
          ) {
            component = (
              <FinancialStatement
                pageSettings={pageSettings}
                type={page.metaData.type.replace('_', '-')}
              />
            )
          } else {
            const configPath = `../${page.metaData.componentPath}.tsx`

            const Component = LazyComponent(modules[configPath])
            let compProps = {}
            if (page.metaData?.type) {
              compProps = {
                type: page.metaData?.type
              }
            }

            component = <Component pageSettings={pageSettings} {...compProps} />
          }
        }

        commonProps.element = component
      } else {
        commonProps.element = <LayoutView pageSettings={pageSettings} />
      }

      return commonProps
    })

    // return customRoutesConfig || [];
    // TODO: remove this after the client routes and internal routes are merged into one.
    const clientDataConnectionsRoutes = getClientDataConnectionsRoutes(clientPages)
    return [...(customRoutesConfig || []), ...clientDataConnectionsRoutes]
  }, [clientPages, modules])

  return useRoutes([
    {
      path: '/',
      element: <Navigate to='login' />
    },
    LoginRoutes,
    {
      path: '/',
      children: [
        {
          path: '/',
          element: (
            <AuthGuard>
              <MainLayout />
            </AuthGuard>
          ),
          children: [
            {
              path: 'profiles',
              children: [
                {
                  path: 'user',
                  element: <UserProfile />,
                  children: [
                    {
                      path: 'password',
                      element: <UserTabPassword />
                    },
                    {
                      path: 'detail',
                      element: <UserDetail />
                    }
                  ]
                }
              ]
            },
            {
              path: 'exported-report/:key',
              element: <ExportedReport />
            },
            ...customRoutes,
            InternalRoutes({ pages: internalPages, modules: modules })
          ]
        }
      ]
    },
    {
      path: '*',
      element: (
        <CatchAllRoute
          isLoggedIn={isLoggedIn}
          isFetchingPages={isFetchingPages}
          defaultUserRouteForBusiness={user?.business_default_route}
        />
      )
    }
  ])
}

export const getClientDataConnectionsRoutes = (pages: Page[]) => {
  if (pages.length === 0) return []

  const clientDataConnectionsPage = findClientDataConnectionsPage(pages)
  if (!clientDataConnectionsPage) return []

  return createDataConnectionRoute(clientDataConnectionsPage)
}

const CatchAllRoute = ({
  isLoggedIn,
  isFetchingPages,
  defaultUserRouteForBusiness
}: {
  isLoggedIn: boolean | undefined
  isFetchingPages: boolean
  defaultUserRouteForBusiness: string | undefined
}) => {
  if (!isLoggedIn) return <Navigate to='/login' />
  if (isFetchingPages) return <MainLayout />
  if (defaultUserRouteForBusiness) {
    return (
      <MainLayout>
        <RedirectTo path={defaultUserRouteForBusiness} />
      </MainLayout>
    )
  }

  return <MainLayout />
}

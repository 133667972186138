export enum Month {
  Jan = 'JAN',
  Feb = 'FEB',
  Mar = 'MAR',
  Apr = 'APR',
  May = 'MAY',
  Jun = 'JUN',
  Jul = 'JUL',
  Aug = 'AUG',
  Sep = 'SEP',
  Oct = 'OCT',
  Nov = 'NOV',
  Dec = 'DEC'
}

export const YMD_DATE_FORMAT = 'yyyy-MM-dd'
export const YM_DATE_FORMAT = 'yyyy-MM'

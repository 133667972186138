import { FeatureProps } from '../types/auth'
import { NavItemType } from '../types/menu'

const filterChildrenByFeatures = (
  features: FeatureProps[] | undefined,
  items: NavItemType,
  current_user_email: string | undefined
) => {
  if (!features) {
    return items
  }

  const allFeatures = features?.map((feature) => feature.name) ?? []
  const enabledFeatures =
    features?.filter((feature) => feature.enabled)?.map((feature) => feature.name) ?? []

  const updatedChildren = items.children?.filter((child) => {
    const { feature_name } = child
    if (typeof feature_name === 'undefined') {
      return true
    }

    if (!feature_name || !allFeatures.includes(feature_name)) {
      return true
    }

    const feature = features.find((feature) => feature.name === feature_name)
    if (feature?.enabled && feature?.allowed_users) {
      const allowedUsers = feature.allowed_users.split(',').map((email) => email.trim())
      return allowedUsers.includes(current_user_email as string)
    }

    return enabledFeatures.includes(feature_name)
  })

  return { ...items, children: updatedChildren }
}

export default filterChildrenByFeatures

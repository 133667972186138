import { createContext, useContext } from 'react'

import { CreateUserActivityRequest } from './types'

export interface LogActivityArguments {
  action: CreateUserActivityRequest['action']
}

export interface IActivityContext {
  logActivity: (params: LogActivityArguments) => Promise<any>
}

export const ActivityContext = createContext<IActivityContext>({
  logActivity: (params: LogActivityArguments) => Promise.resolve(undefined)
})

export const useActivityContext = () => {
  const context = useContext(ActivityContext)

  if (context === undefined) {
    throw new Error('useActivityContext must be used within an ActivityProvider')
  }

  return context
}

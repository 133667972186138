import { useMemo, useState } from 'react'

import { Download, Help, InsertLink } from '@mui/icons-material'
import { Box, Stack, Typography, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'

import filterChildrenByFeatures from '@utils/feature-utils'
import { isLastOrUniqueNavItem } from '@utils/obj-utils'

import Separator from '@components/Separator'
import { BusinessSelector } from '@components/business-selector'
import { SelectControlModeEnum } from '@components/control-panel/types'
import SearchBox from '@components/search-box'

import { borderColor } from 'config'
import useAuth from 'hooks/useAuth'
import menuItem from 'menu-items'
import {
  MenuItemType,
  isMenuItemTypeCollapse,
  isMenuItemTypeGroup,
  isMenuItemTypeItem
} from 'types/menu'

import ActionItem from '../../../Header/HeaderContent/ActionItem'
import Profile from '../../../Header/HeaderContent/Profile'
import NavGroup from './NavGroup'
import NavItem from './NavItem'

const AboveTextFieldContainer = ({ spacing = 0 }) => {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  // # TODO: Make reusable functionality when implementing the following
  // And re-use it here: src/layout/MainLayout/Header/HeaderContent/desktop-section.tsx
  const handleInsertLinkClick = () => {
    console.log('TODO: InsertLink button clicked')
  }

  const handleDownloadClick = () => {
    console.log('TODO: Download button clicked')
  }

  const handleHelpClick = () => {
    console.log('TODO: Help button clicked')
  }

  const lineDivider = <Separator color={borderColor} />

  return (
    <Box
      className='sidebar-action-bar'
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{
        marginTop: '8px',
        borderBottom: '1px solid rgba(255, 255, 255, 0.2)'
      }}
    >
      <Stack
        direction='row'
        spacing={!isSmallScreen ? undefined : spacing}
        alignItems={isSmallScreen ? 'center' : undefined}
        justifyContent={isSmallScreen ? 'space-evenly' : undefined}
      >
        <ActionItem
          iconColor='white'
          hoverColor='primary.dark'
          icon={InsertLink}
          onClick={handleInsertLinkClick}
        />
        {lineDivider}
        <ActionItem
          iconColor='white'
          hoverColor='primary.dark'
          icon={Download}
          onClick={handleDownloadClick}
        />
        {lineDivider}
        <ActionItem
          iconColor='white'
          hoverColor='primary.dark'
          icon={Help}
          onClick={handleHelpClick}
        />
        {lineDivider}
        <Profile iconColor='white' hoverColor='primary.dark' />
      </Stack>
    </Box>
  )
}

interface Props {
  closeDrawer?: () => void
}
const Navigation = (props: Props) => {
  const matchesSmallScreens = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const matchesSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const matchesSmOrLarger = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

  const { user, features, userIsSuperAdmin } = useAuth()

  const existingMenuItems = menuItem().items

  const [searchTerm, setSearchTerm] = useState('')

  const [businessSelectorExpanded, setBusinessSelectorExpanded] = useState(false)

  const handleSearchChange = (value: string) => {
    setSearchTerm(value)
  }

  const handleBusinessSelectorItemClick = () => {
    setBusinessSelectorExpanded((prevExpanded) => !prevExpanded)
  }

  const filteredMenuItems = useMemo(() => {
    const filterItem = (item: any): boolean => {
      const title = typeof item.title === 'string' ? item.title : ''
      const url = typeof item.url === 'string' ? item.url : ''

      return (
        isMenuItemTypeItem(item.type) &&
        (title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          url.toLowerCase().includes(searchTerm.toLowerCase()))
      )
    }

    const applySearchToMenu = (item: any): any => {
      if (isMenuItemTypeGroup(item.type) || isMenuItemTypeCollapse(item.type)) {
        const filteredChildren = item.children
          .map((child: any) => applySearchToMenu(child))
          .filter(Boolean)

        return filteredChildren.length > 0 ? { ...item, children: filteredChildren } : null
      } else if (isMenuItemTypeItem(item.type) && filterItem(item)) {
        return item
      }

      return null
    }

    return existingMenuItems.map((item) => applySearchToMenu(item)).filter(Boolean)
  }, [existingMenuItems, searchTerm])

  const navGroups = (filteredMenuItems || existingMenuItems).map((item, index, array) => {
    if (item.isSuperAdmin && !userIsSuperAdmin()) {
      return null
    }

    const updatedItem = filterChildrenByFeatures(features, item, user?.email)
    switch (item.type) {
      case MenuItemType.group:
        return (
          <NavGroup
            parent={MenuItemType.navigation}
            searchTerm={searchTerm}
            level={0}
            key={updatedItem.id}
            item={updatedItem}
          />
        )
      case MenuItemType.item:
        return (
          <NavItem
            parent={MenuItemType.navigation}
            searchTerm={searchTerm}
            key={item.id}
            item={item}
            level={0}
            isLastItem={isLastOrUniqueNavItem(item, index, array)}
          />
        )
      default:
        return (
          <Typography key={item.id} variant='h6' color='error' align='center'>
            Fix - Navigation Group
          </Typography>
        )
    }
  })

  if (businessSelectorExpanded) {
    return (
      <BusinessSelector
        onSelectItem={handleBusinessSelectorItemClick}
        mode={SelectControlModeEnum.list}
        fullWidth
      />
    )
  } else {
    return (
      <Box className='navigation' sx={{ '& > ul:first-of-type': { mt: 0 } }}>
        {matchesSmallScreens && (
          <>
            <AboveTextFieldContainer
              spacing={matchesXs ? 0 : matchesSmallScreens && matchesSM ? 2 : 0}
            />
            {!matchesSmOrLarger && (
              <BusinessSelector
                onSelectItem={handleBusinessSelectorItemClick}
                mode={SelectControlModeEnum.item}
                fullWidth
              />
            )}
          </>
        )}

        <SearchBox
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          onClearSearch={() => setSearchTerm('')}
        />
        {navGroups}
      </Box>
    )
  }
}

export default Navigation

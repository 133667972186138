import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

import { IModule, IModuleAttributes, ModuleName } from '../types/module-types'
import { assignRef, getRef } from '../utils/module-utils'

const moduleName = ModuleName.xyChart

const xyChartModule: IModule<ModuleName.xyChart> = {
  name: moduleName
}

xyChartModule.init = (props) => {
  const root: am5.Root = getRef({ ...props, moduleName: ModuleName.root })

  const {
    options: { areAxesStacked = false, flipAxis = false }
  } = props

  const chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      layout: root.verticalLayout,
      paddingTop: 8,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0
    })
  )

  if (areAxesStacked) {
    if (flipAxis) {
      chart.bottomAxesContainer.set('layout', root.horizontalLayout)
    } else {
      chart.leftAxesContainer.set('layout', root.verticalLayout)
    }
  }

  assignRef({ ...props, moduleName, item: chart })
}

export const xyChartAttributes: IModuleAttributes<ModuleName.xyChart> = (p) => ({
  module: xyChartModule,
  ...p
})

export default xyChartModule

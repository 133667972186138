import { useMemo } from 'react'

import { Select } from '@components/form/select'

import { useDispatch } from '@store/index'
import { useSelector } from '@store/index'
import { selectXAxes, updateYAxisData } from '@store/slices/component/chart-config'

import { useQueryResultContext } from '../contexts/query-result-context'
import { IAxis, StackingType } from '../types/chart-builder-types'

export function Breakdown({ item: data }: { item: IAxis }) {
  const { dimensions, dates } = useQueryResultContext()
  const dispatch = useDispatch()
  const xAxes = useSelector(selectXAxes)

  const breakdownOptions = useMemo(
    () =>
      [
        { value: '-', label: 'None' },
        ...dimensions.map((dim) => ({ value: dim.name, label: dim.name })),
        ...dates.map((date) => ({ value: date.name, label: date.name }))
      ].filter((dim) => !xAxes.map((x) => x.name).includes(dim.label)),
    [dimensions, dates, xAxes]
  )

  return (
    <Select
      options={breakdownOptions}
      value={data.breakdown || '-'}
      onChange={(value) => {
        dispatch(updateYAxisData({ id: data.id, breakdown: value }))
      }}
    />
  )
}

export function Stacking({ item: data }: { item: IAxis }) {
  const dispatch = useDispatch()

  return (
    <Select
      options={[
        { value: StackingType.STACKED, label: 'Stacked' },
        { value: StackingType.NONE, label: 'None' },
        { value: StackingType.LAYERED, label: 'Layered' }
      ]}
      value={data.stacking || StackingType.STACKED}
      onChange={(value) => {
        dispatch(updateYAxisData({ id: data.id, stacking: value as StackingType }))
      }}
    />
  )
}

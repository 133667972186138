import { Tabs, TabsContent, TabsList, TabsTrigger } from '@core/tab'

import { Separator } from '@components/core/separator'

import { VisualizeLayout } from '../common/visualize-layout'
import { ChartPreview } from './chart-preview'
import { ChartSettings } from './chart-settings'
import { ChartYAxis } from './chart-y-axis'

enum ChartDataTabs {
  SETTINGS = 'settings',
  Y_AXIS = 'y-axis'
}

export const ChartVisualize = () => {
  return (
    <VisualizeLayout
      Preview={<ChartPreview />}
      FullSettingsView={
        <div className='flex size-full'>
          <div className='h-full w-2/5 overflow-scroll'>
            <ChartSettings />
          </div>
          <Separator className='mx-4 h-full' orientation='vertical' />
          <div className='h-full w-3/5'>
            <ChartYAxis />
          </div>
        </div>
      }
      RightSettingsView={
        <div className='size-full'>
          <Tabs
            id='chart-visualize-data-tabs'
            className='flex size-full flex-col'
            defaultValue={ChartDataTabs.SETTINGS}
          >
            <TabsList className='grid w-full grid-cols-2 gap-2'>
              <TabsTrigger value={ChartDataTabs.SETTINGS}>Settings</TabsTrigger>
              <TabsTrigger value={ChartDataTabs.Y_AXIS}>Y-Axis</TabsTrigger>
            </TabsList>
            <div className='h-0 flex-1'>
              <TabsContent value={ChartDataTabs.SETTINGS} className='h-full overflow-scroll'>
                <ChartSettings />
              </TabsContent>
              <TabsContent value={ChartDataTabs.Y_AXIS} className='h-full'>
                <ChartYAxis />
              </TabsContent>
            </div>
          </Tabs>
        </div>
      }
    />
  )
}

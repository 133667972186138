import { useEffect, useState } from 'react'

import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'

import { STATIC_PAGES } from '../data/static-pages'

interface PageSelectorProps {
  meta_data?: any
  defaultSelected?: string
  onChange?: (field: string, value: string) => void
}

export const findPageByPath = (path: string) => {
  return STATIC_PAGES.find((page: any) => page.path === path)
}

const PageSelector = ({
  meta_data,
  defaultSelected = STATIC_PAGES[0].path,
  onChange
}: PageSelectorProps) => {
  const [selectedPage, setSelectedPage] = useState(defaultSelected)
  const [selectedType, setSelectedType] = useState('')
  const [subTypes, setSubTypes] = useState<any[]>([])

  const handleChange = (event: any) => {
    const selectedPath = event.target.value
    setSelectedPage(selectedPath)

    const selectedPageItem = findPageByPath(selectedPath)
    const availableSubType = selectedPageItem?.type || {}

    if (availableSubType) {
      initializeSubTypes(availableSubType)
    }

    if (onChange) {
      onChange('component_path', event.target.value)
      onChange('type', '')
    }
  }

  const initializeSubTypes = (availableSubType: any) => {
    const newSubTypes = Object.entries(availableSubType).map(([key, value]) => ({ key, value }))
    setSubTypes(newSubTypes)
  }

  const handleSubTypeChange = (event: any) => {
    const selectedSubType = event.target.value

    setSelectedType(selectedSubType)

    if (onChange) {
      onChange('type', selectedSubType)
    }
  }

  useEffect(() => {
    setSelectedPage(defaultSelected)

    const pageItem = findPageByPath(defaultSelected)

    if (meta_data?.type && pageItem?.type) {
      initializeSubTypes(pageItem.type)
      setSelectedType(meta_data?.type || '')
    }
  }, [defaultSelected, meta_data?.type])

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id='static-pages-label'>Select an Existing Page</InputLabel>
        <Select
          labelId='static-pages-label'
          id='static-pages-select'
          value={selectedPage}
          label='Select a Static Page'
          onChange={handleChange}
        >
          {STATIC_PAGES.map((page, index) => (
            <MenuItem key={index} value={page.path}>
              {page.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedPage && (
        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          <Typography variant='body2' color='textSecondary' style={{ fontSize: '15px' }}>
            <span style={{ fontWeight: 'bold' }}>Selected Page: </span> {selectedPage}
          </Typography>
        </div>
      )}

      {subTypes.length > 0 && (
        <FormControl fullWidth style={{ marginTop: '10px' }}>
          <InputLabel id='enum-values-label'>Select a Type</InputLabel>
          <Select
            labelId='enum-values-label'
            id='enum-values-select'
            value={selectedType}
            label='Select Sub-Type'
            onChange={handleSubTypeChange}
          >
            {subTypes.map((subType, index) => (
              <MenuItem key={index} value={subType.value}>
                {subType.key} ({subType.value})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  )
}

export default PageSelector

import { useState } from 'react'

import ClearIcon from '@mui/icons-material/Clear'
import { Chip, CircularProgress, Stack, TextField, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { Box } from '@mui/system'

import { useDebounce } from '@uidotdev/usehooks'

import grid1Col from 'assets/images/layout-builder/grid-1-cols.png'
import { useFetchComponents } from 'pages/component-management/queries/fetch-components'

import { COMP } from '../../data'

const transformToBuilderFormat = (componentsData: any) => {
  return componentsData?.results?.map((component: any) => ({
    id: `sidebar-comp-${component.id}`,
    component_id: component.id,
    uuid: component.uuid,
    scope: component.scope,
    category: COMP.WIDGET.CATEGORY,
    genre: component.genre,
    source: component.source,
    name: component.name,
    title: component.title,
    imgSrc: grid1Col,
    description: component.description
  }))
}

// @ts-ignore
const ComponentSearchControl = ({ onClose, onApply }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const [selectedComponents, setSelectedComponents] = useState<{ id: number }[]>([])

  const {
    data: componentsData,
    isLoading,
    isError
  } = useFetchComponents({
    search: debouncedSearchTerm
  })

  const handleClearSelections = () => {
    setSelectedComponents([])
  }

  const handleSearchTermChange = (e: any) => {
    setSearchTerm(e.target.value)
  }

  const handleComponentClick = (component: { id: number }) => {
    const isComponentSelected = selectedComponents.some((selected) => selected.id === component.id)

    if (isComponentSelected) {
      setSelectedComponents((prevSelected) =>
        prevSelected.filter((selected) => selected.id !== component.id)
      )
    } else {
      setSelectedComponents((prevSelected) => [...prevSelected, component])
    }
  }
  const handleApplyClick = () => {
    onApply && onApply(selectedComponents)
  }

  const dialogItems = transformToBuilderFormat(componentsData) || []

  return (
    <div>
      <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
        <TextField
          label='Search Widget'
          variant='outlined'
          value={searchTerm}
          onChange={handleSearchTermChange}
          style={{ width: '200px' }}
        />
        {isLoading && <CircularProgress size={20} style={{ marginLeft: '8px' }} />}
        {selectedComponents.length > 0 && (
          <>
            <Typography variant='caption' style={{ marginLeft: '8px' }}>
              Selected: {selectedComponents.length}
            </Typography>
            <IconButton onClick={handleClearSelections} style={{ marginLeft: '8px' }}>
              <ClearIcon />
            </IconButton>
          </>
        )}
      </div>

      {/* Scrollable Box */}
      <div style={{ overflowY: 'auto', minHeight: '400px', maxHeight: '400px' }}>
        <Box display='flex' flexWrap='wrap'>
          {isError && <p>Error loading components.</p>}
          {dialogItems.map((component: any) => (
            <Box
              key={component.id}
              mb={2}
              mx={1}
              flex='0 0 calc(33.33% - 16px)'
              minWidth='200px'
              style={{
                borderRadius: '8px',
                borderColor: '#ccc',
                // @ts-ignore
                border: selectedComponents.some((selected) => selected.id === component.id)
                  ? '1px solid blue'
                  : '1px solid #ccc',
                cursor: 'pointer'
              }}
              onClick={() => handleComponentClick(component)}
            >
              <Stack height={'100%'} minHeight={'100px'} spacing={1} p={1}>
                <Stack flexDirection='row' gap={1}>
                  <Stack justifyContent='center'>
                    <Typography variant='caption'>{component.title}</Typography>
                  </Stack>
                  <Chip
                    label={component.source}
                    variant='outlined'
                    size='small'
                    style={{ marginTop: '0px' }}
                  />
                </Stack>
                <Box flex={1} border={'1px solid #ccc'} borderRadius={'8px'} padding={'5px'}>
                  <Box
                    borderRadius={'8px'}
                    height={'100%'}
                    padding={'5px'}
                    sx={{ backgroundColor: '#e5e8ee' }}
                  >
                    <Typography variant='caption'>{component.description}</Typography>
                  </Box>
                </Box>
              </Stack>
            </Box>
          ))}
        </Box>
      </div>

      <div style={{ display: 'flex', gap: '8px', marginTop: '10px' }}>
        <Button variant='outlined' color='secondary' onClick={onClose}>
          Close
        </Button>
        <Button variant='contained' color='primary' onClick={handleApplyClick}>
          Apply ({selectedComponents.length})
        </Button>
      </div>
    </div>
  )
}

export default ComponentSearchControl

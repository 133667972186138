import { useMemo, useState } from 'react'

import { Box, CircularProgress, Grid, Stack } from '@mui/material'

import { DndContext, pointerWithin } from '@dnd-kit/core'
import { UniqueIdentifier } from '@dnd-kit/core'

import MainCard from '../../../components/MainCard'
import useDefaultSensors from '../../../components/tree/useDefaultSensors'
import { findItemDeep } from '../../../components/tree/utilities'
import { LayoutViewProps } from '../../layout-view/types'
import { DetailsPanel } from './components/manager/details-panel'
import { PagesTreePanel } from './components/manager/pages-tree-panel'
import { useFetchPagesTree } from './queries/fetch-pages-tree'

const LayoutManager = ({ pageSettings }: LayoutViewProps) => {
  const { isLoading: fetchingPages, data: pages } = useFetchPagesTree()
  const [selectedPageId, setSelectedPageId] = useState<UniqueIdentifier>()
  const sensors = useDefaultSensors()

  const selectedNode: any | undefined = useMemo(() => {
    if (!pages) return undefined

    return findItemDeep(pages, selectedPageId as UniqueIdentifier)
  }, [pages, selectedPageId])

  if (fetchingPages) {
    return <CircularProgress />
  }

  return (
    <MainCard
      pageSettings={pageSettings}
      content={true}
      title={' '}
      secondary={
        <Stack direction='row' spacing={2}>
          <Box display='flex' alignItems='center'>
            <Box width='20px' height='20px' bgcolor='white' border='1px solid #000' />
            <Box ml={1}>
              <span>Published</span>
            </Box>
          </Box>
          <Box display='flex' alignItems='center'>
            <Box width='20px' height='20px' bgcolor='whitesmoke' border='1px solid #000' />
            <Box ml={1}>
              <span>Draft</span>
            </Box>
          </Box>
          <Box display='flex' alignItems='center'>
            <Box width='20px' height='20px' bgcolor='#f5c6cb' border='1px solid #000' />
            <Box ml={1}>
              <span>Disabled</span>
            </Box>
          </Box>
        </Stack>
      }
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack p={1} direction='row'></Stack>

            <Box p={2}>
              <DndContext
                collisionDetection={pointerWithin}
                sensors={sensors}
                onDragStart={(event) => {
                  console.log('Drag Start on Working Hierarchy...', event)
                }}
                onDragEnd={(event) => {
                  console.log('do something', event)
                }}
              >
                {fetchingPages ? (
                  <CircularProgress />
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <div className='max-h-[calc(100vh-150px)] overflow-scroll rounded border-2 border-solid border-grey-light px-2 pb-[24px]'>
                        <PagesTreePanel
                          isEditMode={true}
                          pages={pages || []}
                          onPageClick={(id: UniqueIdentifier) => {
                            if (id === selectedPageId) {
                              setSelectedPageId(undefined)
                            } else {
                              setSelectedPageId(id)
                            }
                          }}
                          selectedPageId={selectedPageId as number}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={7}>
                      <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 64px)' }}>
                        {selectedPageId && <DetailsPanel selectedNode={selectedNode} />}
                      </div>
                    </Grid>
                  </Grid>
                )}
              </DndContext>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </MainCard>
  )
}

export default LayoutManager

import { useCallback } from 'react'

import { ValueFormatterParams } from 'ag-grid-community'

import { FinancialHierarchyNode } from '@components/financial/types'
import { financialValueFormatter } from '@components/financial/utils/formatting-utils'

export const useFinancialValueFormatter = () => {
  return useCallback((params: ValueFormatterParams) => {
    const data = params.node?.data as FinancialHierarchyNode
    return financialValueFormatter(params.value, data)
  }, [])
}

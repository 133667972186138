import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { uniquePageIdFromRoute } from '@utils/navigation'

const useRouteBasedID = () => {
  const location = useLocation()

  const routePath = location.pathname

  return useMemo(() => uniquePageIdFromRoute(routePath), [routePath])
}

export default useRouteBasedID

import { useDispatch } from 'react-redux'

import { useQuery } from '@tanstack/react-query'

import { camelCaseKeys } from '@utils/case-conversion'

import {
  fetchRenamingsFailure,
  fetchRenamingsStart,
  fetchRenamingsSuccess
} from '@store/slices/schema-renamings'

import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'
import { errorSnack } from 'utils/snackbar-utils'

export const fetchRenamings = async (business_id: number) => {
  return axiosServices
    .get(`/businesses/${business_id}/schema_renamings`)
    .then((response) => camelCaseKeys(response.data))
}

export const fetchRenamingsForBusinessId = async (businessId: number, dispatch: any) => {
  dispatch(fetchRenamingsStart())
  try {
    const data = await fetchRenamings(businessId)
    dispatch(fetchRenamingsSuccess(data))
    return data
  } catch (error) {
    dispatch(fetchRenamingsFailure((error as Error).message))
    errorSnack(`Error Fetching Renamings! \n ${(error as Error).message}`)
    throw error
  }
}

export const useFetchRenamings = (enabled: boolean) => {
  const { user } = useAuth()
  const dispatch = useDispatch()

  return useQuery({
    queryKey: ['fetch-renamings', user?.business_id],
    queryFn: () => {
      if (typeof user?.business_id === 'number') {
        return fetchRenamingsForBusinessId(user.business_id, dispatch)
      } else {
        return Promise.resolve([])
      }
    },
    enabled: !!user?.business_id && enabled
  })
}

import { useEffect, useState } from 'react'

import { usePageSelector } from '@store/index'
import { selectShowChartGridLines, selectShowDataLabels } from '@store/slices/action-bar'

import { useOpenChartNodeContextMenu } from 'contexts/drilldown-context'

import { IChartConfig } from '../types/chart-builder-types'
import { IMetadata } from '../types/component-types'
import { IResultRow } from '../types/shared-types'
import ChartFactory, { ChartFactoryReturnType } from './chart-modules-creator'

export default function useChartPropsRetriever({
  data,
  metadata,
  config
}: {
  data: IResultRow[]
  metadata: IMetadata
  config: IChartConfig
}) {
  const [chartProps, setChartProps] = useState<
    (ChartFactoryReturnType & { data: IResultRow[] }) | null
  >(null)
  const [chartError, setChartError] = useState<string | null>(null)
  const showDataLabels = usePageSelector(selectShowDataLabels)
  const showChartGridLines = usePageSelector(selectShowChartGridLines)
  const openChartNodeContextMenu = useOpenChartNodeContextMenu()

  useEffect(() => {
    const { xAxes, yAxes, flipAxis, settings } = config

    try {
      const factoryProps = ChartFactory.create({
        selectedXAxes: xAxes,
        selectedYAxes: yAxes,
        flipAxis,
        settings: settings,
        metadata: _.assign({}, metadata, { showDataLabels, showChartGridLines }),
        actions: { openChartNodeContextMenu }
      })

      setChartProps(_.assign({}, factoryProps, { data }))
      setChartError(null)
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Unknown error.'
      setChartError(message)
    }
  }, [config, data, metadata, showDataLabels, showChartGridLines, openChartNodeContextMenu])

  return [chartProps, chartError] as const
}

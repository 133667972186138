import { ReactNode, useMemo } from 'react'

// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import {
  Theme,
  ThemeOptions,
  ThemeProvider,
  TypographyVariantsOptions,
  createTheme
} from '@mui/material/styles'

// project import
import useConfig from 'hooks/useConfig'
// types
import { CustomShadowProps } from 'types/theme'

import componentsOverride from './overrides'
import Palette from './palette'
import CustomShadows from './shadows'
import { Font } from './theme'
import Typography from './typography'

// types
type ThemeCustomizationProps = {
  children: ReactNode
}

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

export default function ThemeCustomization({ children }: ThemeCustomizationProps) {
  const { themeDirection, mode, presetColor, fontFamily: defaultFontFamily } = useConfig()

  const theme: Theme = useMemo<Theme>(() => Palette(mode, presetColor), [mode, presetColor])

  const fontFamily = Font(presetColor) || defaultFontFamily

  const themeTypography: TypographyVariantsOptions = useMemo<TypographyVariantsOptions>(
    () => Typography(mode, fontFamily, theme),
    [mode, fontFamily, theme]
  )
  const themeCustomShadows: CustomShadowProps = useMemo<CustomShadowProps>(
    () => CustomShadows(theme),
    [theme]
  )

  const rootElement = document.getElementById('root')
  const themeOptions: ThemeOptions = useMemo(
    () => ({
      components: {
        MuiPopover: {
          defaultProps: {
            container: rootElement
          }
        },
        MuiPopper: {
          defaultProps: {
            container: rootElement
          }
        },
        MuiDialog: {
          defaultProps: {
            container: rootElement
          }
        },
        MuiModal: {
          defaultProps: {
            container: rootElement
          }
        }
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1440
        }
      },
      direction: themeDirection,
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8
        }
      },
      zIndex: {
        appBar: 1200,
        drawer: 1201
      },
      palette: theme.palette,
      customShadows: themeCustomShadows,
      typography: themeTypography
    }),
    [themeDirection, theme, themeTypography, themeCustomShadows, rootElement]
  )

  const themes: Theme = createTheme(themeOptions)
  themes.components = componentsOverride(themes)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

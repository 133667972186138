import React, { createContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { doesNotStartWith, isNotEqual } from '@utils/lodash'

import { REDUX_PERSIST_KEY_PREFIX } from '@store/constants'

import Loader from 'components/Loader'
import LogRocket from 'logrocket'
import { queryClient } from 'queries/query-client'
import {
  CurrentUser,
  CurrentUserServiceToken,
  HANDLE_BUSINESS_SELECTOR_CHANGE,
  LOGIN,
  LOGOUT,
  MASQUARADE,
  Masquarading,
  REVERSE_MASQUARADE,
  ServiceToken,
  User
} from 'store/reducers/actions'
import { AuthProps, JWTContextType, UserProfile, UserRole } from 'types/auth'
import { BusinessType, isCharger, isDemoBusiness } from 'types/business'
import axios from 'utils/axios'

import { dispatch, useDispatch, useSelector } from '../store'
import { LogGroup, log, logError } from '../store/actions/log'
import { errorSnack } from '../utils/snackbar-utils'

export const EVER_LOGGED_IN = 'CHARGER/EVER_LOGGGED_IN'

export const CURRENT_USER_URL = '/current_user'
export const LOGOUT_URL = '/logout'

export const everLoggedIn = () => localStorage.getItem(EVER_LOGGED_IN) === 'true'

export const setSession = (user: any, serviceToken?: string | null) => {
  const masquarading = localStorage.getItem(Masquarading) === 'true'
  if (serviceToken) {
    localStorage.setItem(ServiceToken, serviceToken)
    localStorage.setItem(User, JSON.stringify(user))
    if (!masquarading) {
      localStorage.setItem(CurrentUserServiceToken, serviceToken)
      localStorage.setItem(CurrentUser, JSON.stringify(user))
    }
  } else {
    localStorage.removeItem(ServiceToken)
    if (!masquarading) localStorage.removeItem(CurrentUserServiceToken)
    delete axios.defaults.headers.common.Authorization
  }
}

const setMasquaradeSession = (user: any, serviceToken?: string | null) => {
  if (serviceToken) {
    localStorage.setItem(ServiceToken, serviceToken)
    localStorage.setItem(User, JSON.stringify(user))
    localStorage.setItem(Masquarading, 'true')
  } else {
    localStorage.removeItem(ServiceToken)
    delete axios.defaults.headers.common.Authorization
  }
}

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null)

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  const state: AuthProps = useSelector((state) => state.auth as AuthProps)
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const identifyUserInLogRocket = (user: UserProfile) => {
    if (user && user.id && user.name && user.email) {
      LogRocket.identify(user.id, {
        name: user.name,
        email: user.email,
        primaryBusiness: user.business_friendly_name as string,
        primaryBusinessId: user.business_id as number
      })
    }
  }

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem(ServiceToken)
        if (serviceToken) {
          const response = await axios.get(CURRENT_USER_URL)

          const feature_response = await axios.get('/features')
          const features = feature_response.data
          const user = response.data
          setSession(user, serviceToken)

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: {
                ...user,
                ...selectedBusinessInfo(user)
              },
              features
            }
          })
          identifyUserInLogRocket(user)
        } else {
          dispatch({
            type: LOGOUT
          })
        }
      } catch (err: any) {
        dispatch(
          logError({
            group: LogGroup.AUTH,
            message: `exception fetching current user: ${err.message}`
          })
        )

        clearSession()
        dispatch({
          type: LOGOUT
        })
      }
    }

    init()
    // Note: If you are working with this component, please fix react-hooks/exhaustive-deps warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const login = async (email: string, password: string) => {
    const response = await axios.post('/login', { user: { email, password } })
    const serviceToken = response.headers.authorization
    const { user } = response.data
    setSession(user, serviceToken)
    localStorage.setItem(EVER_LOGGED_IN, 'true')

    dispatch({
      type: LOGIN,
      payload: {
        user: {
          ...user,
          ...selectedBusinessInfo(user)
        }
      }
    })
    identifyUserInLogRocket(user)
  }

  const register = async (
    email: string,
    password: string,
    first_name: string,
    last_name: string,
    business_name: string,
    local_timezone: string,
    business_industry: string
  ) => {
    // todo: this flow need to be recode as it not verified
    // const id = chance.bb_pin();
    await axios.post('/signup', {
      user: {
        email,
        password,
        first_name,
        last_name,
        business: { business_name, business_industry, local_timezone }
      }
    })
  }

  const logout = () => {
    axios
      .delete(LOGOUT_URL)
      .then(() => {
        clearSession()
        dispatch({ type: LOGOUT })
      })
      .catch((error) => {
        errorSnack(`Cannot perform logout with error ${error}`)
      })
  }

  const masquarade = (user: UserProfile, serviceToken: string) => {
    setMasquaradeSession(user, serviceToken)
    dispatch({
      type: MASQUARADE,
      payload: {
        user: user
      }
    })
  }
  const reverseMasquarade = () => {
    const currentUser = JSON.parse(localStorage.getItem(CurrentUser) || '{}')
    setMasquaradeSession(currentUser, localStorage.getItem(CurrentUserServiceToken))
    localStorage.removeItem(Masquarading)
    dispatch({
      type: REVERSE_MASQUARADE,
      payload: {
        user: currentUser
      }
    })
    navigate('/dashboard')
  }

  const userHasRole = (roles: Array<string>): boolean => {
    if (state.user?.roles?.includes(UserRole.chargerSuperUser)) return true
    if (state.user?.roles?.includes(UserRole.super_admin)) return true
    return roles.some((role) => state.user?.roles?.includes(role))
  }

  const businessFriendlyName = _.get(state, 'user.business_friendly_name', '')

  const userIsSuperAdmin = () => {
    return userHasRole([UserRole.super_admin]) || isChargerSuperUser()
  }

  const isChargerSuperUser = () => {
    return state.user?.roles?.includes(UserRole.chargerSuperUser) as boolean
  }

  const isChargerAccountsManager = () => {
    return state.user?.roles?.includes(UserRole.chargerAccountsManager) as boolean
  }

  const isPrimaryBusinessCharger = () => {
    return state.user?.primary_business_charger ?? false
  }

  const isCurrentBusinessCharger = () => {
    return isCharger(businessFriendlyName)
  }

  const isCurrentBusinessClient = () => {
    return _.isEqual(state.user?.business_type, BusinessType.CLIENT)
  }

  const isCurrentBusinessDemo = () => {
    return isDemoBusiness(businessFriendlyName)
  }

  const resetPassword = async (email: string) => {
    await axios.post('/password', {
      user: {
        email
      }
    })
  }

  const onBusinessSelectorChange = (user: UserProfile, selectedBusinessId: number) => {
    localStorage.setItem(`${user?.id}_SELECTED_BUSINESS_ID`, selectedBusinessId?.toString())
    queryClient.clear()

    dispatch({
      type: HANDLE_BUSINESS_SELECTOR_CHANGE,
      payload: { user: { ...user, ...selectedBusinessInfo(user) } }
    })
  }

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        userHasRole,
        resetPassword,
        masquarade,
        reverseMasquarade,
        onBusinessSelectorChange,
        userIsSuperAdmin,
        isChargerSuperUser,
        isChargerAccountsManager,
        isCurrentBusinessCharger,
        isCurrentBusinessClient,
        isCurrentBusinessDemo,
        isPrimaryBusinessCharger
      }}
    >
      {children}
    </JWTContext.Provider>
  )
}

const selectedBusinessInfo = (user: UserProfile) => {
  const persistedCurrentBusinessId = localStorage.getItem(
    `${user.id}_SELECTED_BUSINESS_ID`
  ) as string
  const userBusinesses = _.get(user, 'businesses', [])

  let currentBusiness = userBusinesses.find(
    (business) => business.id === parseInt(persistedCurrentBusinessId || '0')
  )

  if (_.isNil(currentBusiness)) {
    currentBusiness = _.head(userBusinesses)
  }

  if (_.isNil(currentBusiness)) {
    dispatch(
      log({
        group: LogGroup.AUTH,
        message: 'CRITICAL - Primary Business Not Associated With User!',
        tags: { user }
      })
    )
  }

  return {
    business_id: currentBusiness?.id,
    business_fiscal_year_start: currentBusiness?.fiscal_year,
    business_friendly_name: currentBusiness?.friendly_name,
    business_name: currentBusiness?.name,
    charger_customer_id: currentBusiness?.charger_customer_id,
    business_local_timezone: currentBusiness?.local_timezone,
    business_type: currentBusiness?.type,
    business_default_route: currentBusiness?.default_route,
    business_languages: currentBusiness?.languages,
    business_earliest_transaction_year: currentBusiness?.earliest_transaction_year,
    business_latest_transaction_year: currentBusiness?.latest_transaction_year,
    business_automap_gl_account: currentBusiness?.automap_gl_account
  }
}

export const clearSession = () => {
  const keys = Object.keys(localStorage)

  keys.forEach((key) => {
    if (isNotEqual(key, EVER_LOGGED_IN) && doesNotStartWith(key, REDUX_PERSIST_KEY_PREFIX)) {
      localStorage.removeItem(key)
    }
  })

  queryClient.clear()
}

export default JWTContext

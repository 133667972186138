import { rootAttributes } from 'components/chart/modules/root'
import { IModuleDefinition, IModuleDefinitions } from 'components/chart/types/module-types'
import { chargerColors } from 'themes/theme/charger-colors-tw'

import { IChartActions, IChartAdditionalMetadata } from '../../types/chart-builder-types'
import { IMetadata, IMetadataBreakdowns } from '../../types/component-types'
import { dateTruncationToFrequency } from '../../utils/frequency-date-trunc'

export default class ChartModules {
  public modules: IModuleDefinitions

  constructor(
    public metadata: IMetadata & IChartAdditionalMetadata,
    public actions?: IChartActions
  ) {
    this.modules = [rootAttributes()]
  }

  create() {
    throw new Error('Method not implemented.')
  }

  get ppStartLabel() {
    return _.get(this.metadata, 'pp_start_label', '')
  }

  get ppEndLabel() {
    return _.get(this.metadata, 'pp_end_label', '')
  }

  get cpStartLabel() {
    return _.get(this.metadata, 'cp_start_label', '')
  }

  get cpEndLabel() {
    return _.get(this.metadata, 'cp_end_label', '')
  }

  get ppLabel() {
    if (!this.ppStartLabel || !this.ppEndLabel) return 'Previous Period'

    return `${this.ppStartLabel} - ${this.ppEndLabel}`
  }

  get cpLabel() {
    if (!this.cpStartLabel || !this.cpEndLabel) return 'Current Period'

    return `${this.cpStartLabel} - ${this.cpEndLabel}`
  }

  get fullPeriodLabel() {
    return _.get(this.metadata, 'full_period_label', 'Two Period')
  }

  get dateLabels() {
    return {
      ppStartLabel: this.ppStartLabel,
      ppEndLabel: this.ppEndLabel,
      cpStartLabel: this.cpStartLabel,
      cpEndLabel: this.cpEndLabel,
      ppLabel: this.ppLabel,
      cpLabel: this.cpLabel,
      fullPeriodLabel: this.fullPeriodLabel
    }
  }

  get breakdowns() {
    return _.get(this.metadata, 'breakdowns', {} as IMetadataBreakdowns)
  }

  get dateFrequency() {
    return dateTruncationToFrequency(_.get(this.metadata, 'date_truncation'))
  }

  get showDataLabelsControls() {
    return _.get(this.metadata, 'showDataLabels', false)
  }

  get showChartGridLines() {
    return _.get(this.metadata, 'showChartGridLines', false)
  }

  get currentDrilldownKey() {
    return _.get(this.metadata, 'current_drilldown_key')
  }

  get currentDrilldownConfig() {
    return _.get(this.metadata, `drilldown_configs.${this.currentDrilldownKey}`, null)
  }

  get openChartNodeContextMenu() {
    return _.get(this.actions, 'openChartNodeContextMenu')
  }

  get colors() {
    return chargerColors.charts
  }

  periodLabel(previous_period: boolean) {
    return previous_period ? this.ppLabel : this.cpLabel
  }

  addToModules(value: IModuleDefinition | IModuleDefinitions | undefined | null) {
    if (value) this.modules.push(...(Array.isArray(value) ? value : [value]))
  }
}

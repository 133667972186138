import { cn } from '@utils/style-utils'

export const DragOverLine = ({ dragOverPosition }: { dragOverPosition: number | null }) => (
  <div
    className={cn(
      'absolute left-0 right-0 h-px border border-solid border-primary',
      _.gt(dragOverPosition, 0) ? '-top-1' : '-bottom-1'
    )}
  />
)

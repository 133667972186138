import { useEffect, useState } from 'react'

import { ICellRendererParams } from 'ag-grid-community'

import { Popover, PopoverContent, PopoverTrigger } from '@core/popover'

import { DEFAULT_EDITOR_MAX_HEIGHT, SQLEditor } from '@components/code-editors'

import { LogGroup, logError } from '@store/actions/log'
import { useDispatch } from '@store/index'

import { format as formatSql } from 'sql-formatter'

export const SQLCellRenderer = (params: ICellRendererParams) => {
  const [query, setQuery] = useState<string>('')
  const dispatch = useDispatch()

  useEffect(() => {
    try {
      const formattedSQL = formatSql(params.value || '', { language: 'postgresql' })
      setQuery(formattedSQL)
    } catch (error) {
      dispatch(
        logError({
          group: LogGroup.SQL_CELL_RENDERER,
          message: 'Error formatting SQL query',
          tags: {
            type: 'FORMAT_SQL',
            error: (error as Error)?.message || 'Unknown error',
            query: params.value
          }
        })
      )
      setQuery(params.value)
    }
  }, [params.value, dispatch])

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className='cursor-pointer truncate'>{query}</div>
      </PopoverTrigger>

      <PopoverContent className='w-128'>
        <SQLEditor value={query} readOnly maxHeight={DEFAULT_EDITOR_MAX_HEIGHT} />
      </PopoverContent>
    </Popover>
  )
}

import { Badge } from '@components/core/badge'
import { Icon } from '@components/icons'
import { DragIndicator } from '@components/icons/drag-indicator'

import { useSelector } from '@store/index'
import { useDispatch } from '@store/index'
import { replaceColumns, selectColumns } from '@store/slices/component/table-config'

import { IconButton } from 'components/core/icon-button'
import { Separator } from 'components/core/separator'
import { Text } from 'components/core/text'
import { RenderItem, SortableList } from 'components/sortable-list'

import { ITableColumn } from '../types/table-builder-types'

function ColumnBadge({ column }: { column: ITableColumn }) {
  if (column.pivot) return <Badge variant='outline'>Pivot</Badge>
  if (column.hide) return <Badge variant='outline'>Hidden</Badge>
  return null
}

const ColumnItem: RenderItem<ITableColumn> = ({ draggableProps, setNodeRef, style, item }) => {
  return (
    <div ref={setNodeRef} style={style} className='w-full'>
      <div className='flex w-full items-center justify-between rounded-md border-b border-grey-lighter bg-white py-1'>
        <div className='flex items-center gap-2'>
          <IconButton {...draggableProps} className='px-0'>
            <Icon icon={<DragIndicator />} />
          </IconButton>
          <Text variant='body'>{item.field}</Text>
        </div>
        <div className='flex gap-2'>
          <ColumnBadge column={item} />
        </div>
      </div>
      <Separator className='my-1 bg-grey-lighter' />
    </div>
  )
}

export function ColumnOrdering() {
  const columns = useSelector(selectColumns)
  const dispatch = useDispatch()

  return (
    <div className='w-full'>
      <div className='flex flex-row items-center justify-between'>
        <div className='flex flex-col space-y-0'>
          <Text variant='h5'>Column Data</Text>
          <Text variant='details'>Rearrange column order</Text>
        </div>
      </div>

      <SortableList
        items={columns}
        idKey='field'
        setItems={(values) => dispatch(replaceColumns(values))}
        renderItem={ColumnItem}
        WrapperComponent={({ children }) => <div className='w-full'>{children}</div>}
      />
    </div>
  )
}

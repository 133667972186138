import { EnumValueUnion } from '@utils/obj-utils'

import { GeneralLedgerAccountNodeIdentifier } from 'components/financial/types'

type SingleEntry = number

type DoubleEntry = {
  debit: number
  credit: number
  amount: number
}

export interface FinancialReport {
  id: number
  gl_source_system_account_id: string
  label: string
  identifier: GeneralLedgerAccountNodeIdentifier
  path: string
  sort_order: number
  data: Record<string, SingleEntry | DoubleEntry>
}

export enum FinancialStatementTypeEnum {
  IncomeStatement = 'income-statement',
  BalanceSheet = 'balance-sheet',
  CashFlow = 'cash-flow',
  Account = 'adjustment-account',
  Transaction = 'adjustment-transaction'
}

export const FinancialStatementTypes: string[] = Object.values(FinancialStatementTypeEnum)

// export type FinancialStatementType = 'income-statement' | 'balance-sheet' | 'cash-flow';
export type FinancialStatementType = EnumValueUnion<typeof FinancialStatementTypeEnum>

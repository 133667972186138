import { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import axiosServices from '@utils/axios'

import useAuth from '@hooks/useAuth'

import { ActivityContext, LogActivityArguments } from './context'
import { CreateUserActivityRequest } from './types'

interface Props {
  children: React.ReactElement | null
}

const recordActivity = (activityData: CreateUserActivityRequest) =>
  axiosServices
    .post(`/businesses/${activityData.business_id}/user_activities`, activityData)
    .catch((error) => {
      console.error('Error recording activity:', error)
    })

const ActivityLogger = (props: Props) => {
  const { isLoggedIn, user } = useAuth()

  const location = useLocation()

  const userId = user?.id
  const businessId = user?.business_id

  const logActivity = useCallback(
    (params: LogActivityArguments) => {
      if (!userId || !businessId) {
        console.error('Logging activity before proper initialization', userId, businessId)
        return Promise.resolve(undefined)
      }
      return recordActivity({
        ...params,
        user_id: userId,
        business_id: businessId,
        url: location.pathname
      })
    },
    [location.pathname, userId, businessId]
  )

  useEffect(() => {
    if (!isLoggedIn) return
    logActivity({
      action: 'page_change'
    })
  }, [isLoggedIn, logActivity])

  return (
    <ActivityContext.Provider value={{ logActivity }}>{props.children}</ActivityContext.Provider>
  )
}

export default ActivityLogger

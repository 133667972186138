import React from 'react'

import { Stack, Typography } from '@mui/material'

import Appsignal from '@appsignal/javascript'
import { ErrorBoundary } from '@appsignal/react'

const appsignal = new Appsignal({ key: import.meta.env.VITE_APPSIGNAL_API_KEY })

export const FallbackComponent = () => (
  <Stack spacing={1} direction='column'>
    <Typography variant='h4' color='error'>
      Application crashed. Developers have been notified.
    </Typography>
  </Stack>
)

export default function AppsignalErrorBoundary({
  tags,
  children
}: {
  tags?: Record<string, string>
  children: React.ReactNode
}) {
  return (
    <ErrorBoundary
      instance={appsignal}
      tags={tags || { tag: 'charger-default' }}
      fallback={() => <FallbackComponent />}
    >
      {children}
    </ErrorBoundary>
  )
}

import React, { useEffect, useRef, useState } from 'react'

import { isNotEqual } from '@utils/lodash'

import Button from '@components/core/button'
import { IconButton } from '@components/core/icon-button'
import { Input } from '@components/core/input'
import { Text } from '@components/core/text'
import { Select } from '@components/form/select'
import { Cancel, Icon, Plus } from '@components/icons'
import { DragIndicator } from '@components/icons/drag-indicator'
import { RenderItemParams, SortableList } from '@components/sortable-list'

import useDelayedUpdate from '@hooks/use-delayed-update'

import { useDispatch } from '@store/index'
import { updateColumn } from '@store/slices/component/table-config'

import { Separator } from 'components/core/separator'

import {
  ITableColumn,
  PivotComparatorConfigRow,
  PivotComparatorOrder,
  PivotComparatorType,
  isPivotComparatorTypeFixed,
  isPivotComparatorTypeRest
} from '../types/table-builder-types'

type PivotComparatorConfigRowWithIndex = PivotComparatorConfigRow & { index: string }

const ConfigRenderItem: (
  props: RenderItemParams<PivotComparatorConfigRowWithIndex> & {
    remove: (index: string) => void
    update: (index: string, values: Partial<PivotComparatorConfigRow>) => void
    setInputFocus: (index: string | null) => void
    inputFocus: string | null
  }
) => React.JSX.Element = ({
  draggableProps,
  setNodeRef,
  style,
  item,
  remove,
  update,
  setInputFocus,
  inputFocus
}) => {
  const [value, setValue] = useDelayedUpdate(
    item.value || '',
    (value) => update(item.index, { value }),
    500
  )

  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (isNotEqual(inputFocus, item.index) || !inputRef.current) return

    inputRef.current.focus()
  }, [inputFocus, item.index])

  return (
    <div ref={setNodeRef} style={style} className='w-full'>
      <div className='flex w-full items-center justify-between rounded-md border-b border-grey-lighter bg-white py-1'>
        <div className='flex items-center gap-2'>
          <IconButton {...draggableProps} className='px-0'>
            <Icon icon={<DragIndicator />} />
          </IconButton>
          <div className='flex items-center gap-2'>
            <Text variant='body'>{item.type}</Text>
            {isPivotComparatorTypeRest(item.type) && (
              <Select
                options={[
                  { label: 'Ascending', value: PivotComparatorOrder.ASC },
                  { label: 'Descending', value: PivotComparatorOrder.DESC }
                ]}
                value={item.order as PivotComparatorOrder}
                onChange={(value) => update(item.index, { order: value as PivotComparatorOrder })}
              />
            )}
            {isPivotComparatorTypeFixed(item.type) && (
              <Input
                ref={inputRef}
                value={value}
                onChange={(event) => setValue(event.target.value)}
                onFocus={() => setInputFocus(item.index)}
                onBlur={() => setInputFocus(null)}
              />
            )}
          </div>
        </div>
        <div className='flex gap-2'>
          {!isPivotComparatorTypeRest(item.type) && (
            <IconButton onClick={() => remove(item.index)}>
              <Icon icon={<Cancel />} />
            </IconButton>
          )}
        </div>
      </div>
      <Separator className='my-1 bg-grey-lighter' />
    </div>
  )
}

export function ColumnPivotSettings({ selected }: { selected: ITableColumn }) {
  const dispatch = useDispatch()
  const pivotComparatorConfig = _.get(
    selected,
    ['customData', 'pivotComparatorConfig'],
    [{ type: PivotComparatorType.REST, order: PivotComparatorOrder.ASC }]
  ) as PivotComparatorConfigRow[]

  const pivotComparatorConfigWithIndex = _.map(pivotComparatorConfig, (row, index) =>
    _.assign({ index: `${index + 1}` }, row)
  ) as PivotComparatorConfigRowWithIndex[]

  const addPivotComparatorConfig = () => {
    dispatch(
      updateColumn({
        field: selected.field,
        customData: {
          pivotComparatorConfig: _.concat(pivotComparatorConfig, {
            type: PivotComparatorType.FIXED,
            value: ''
          })
        }
      })
    )
  }

  const removePivotComparatorConfig = (index: string) => {
    dispatch(
      updateColumn({
        field: selected.field,
        customData: {
          pivotComparatorConfig: _.map(
            _.filter(pivotComparatorConfigWithIndex, (row) => isNotEqual(row.index, index)),
            (row) => _.omit(row, 'index')
          ) as PivotComparatorConfigRow[]
        }
      })
    )
  }

  const updatePivotComparatorConfig = (
    index: string,
    values: Partial<PivotComparatorConfigRow>
  ) => {
    dispatch(
      updateColumn({
        field: selected.field,
        customData: {
          pivotComparatorConfig: _.map(
            _.map(pivotComparatorConfigWithIndex, (row) =>
              row.index === index ? _.assign({}, row, values) : row
            ),
            (row) => _.omit(row, 'index')
          ) as PivotComparatorConfigRow[]
        }
      })
    )
  }

  const [inputFocus, setInputFocus] = useState<string | null>(null)

  return (
    <>
      <div className='mt-4 flex items-center gap-2'>
        <div className='flex-1'>
          <Text variant='h5'>Pivot Comparator</Text>
          <Text variant='details'>Choose ordering for pivot headers</Text>
        </div>
        <Button className='pr-1' variant='outline' onClick={() => addPivotComparatorConfig()}>
          Add <Icon icon={<Plus />} />
        </Button>
      </div>
      <div>
        <SortableList<PivotComparatorConfigRowWithIndex>
          items={pivotComparatorConfigWithIndex}
          idKey='index'
          renderItem={(props) => (
            <ConfigRenderItem
              {...props}
              remove={removePivotComparatorConfig}
              update={updatePivotComparatorConfig}
              setInputFocus={setInputFocus}
              inputFocus={inputFocus}
            />
          )}
          WrapperComponent={({ children }) => <div className='w-full'>{children}</div>}
          setItems={(values) =>
            dispatch(
              updateColumn({
                field: selected.field,
                customData: {
                  pivotComparatorConfig: _.map(values, (row) =>
                    _.omit(row, 'index')
                  ) as PivotComparatorConfigRow[]
                }
              })
            )
          }
        />
      </div>
    </>
  )
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'
import { errorSnack } from 'utils/snackbar-utils'

import {
  ComponentGenre,
  ComponentScope,
  ComponentSource,
  ComponentStatus
} from '../types/component-types'

interface CreateComponentArgs {
  body: {
    title?: string
    name?: string
    scope?: ComponentScope
    genre: ComponentGenre
    source?: ComponentSource
    status?: ComponentStatus
    enabled?: boolean
  }
}

export const useCreateComponent = () => {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const business_id = user?.business_id!

  return useMutation({
    mutationFn: ({ body }: CreateComponentArgs) => {
      return axiosServices.post(`/businesses/${business_id}/components`, body)
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(['fetch-component', business_id, data.id], data)
    },
    onError() {
      errorSnack('Error Creating Component')
    }
  })
}

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { removeToast, selectToasts } from 'store/slices/toast'

import {
  Toast,
  ToastAction,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastViewport
} from './toast'

export function Toaster() {
  const toasts = useSelector(selectToasts)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleAction = (type: string) => {
    if (type === 'sampleAlert') {
      alert('You clicked the toast action')
    } else if (type === 'navigateToReportBuilder') {
      navigate('/report-builder')
    }
    dispatch(removeToast())
  }

  const handleClose = (id?: string) => {
    dispatch(removeToast())
  }
  return (
    <ToastProvider>
      {toasts.map(({ id, description, action, showCloseButton = true, ...props }) => (
        <Toast key={id} {...props}>
          <div className='grid gap-1'>
            {Array.isArray(description) ? (
              description.map((desc, index) => (
                <ToastDescription key={index}>{desc}</ToastDescription>
              ))
            ) : (
              <ToastDescription>{description}</ToastDescription>
            )}
          </div>
          <div className='flex min-w-48 justify-end'>
            {action && (
              <ToastAction
                altText={action.altText || action.text}
                onClick={() => handleAction(action.onClickType)}
              >
                {action.text}
              </ToastAction>
            )}
            {showCloseButton && <ToastClose onClick={() => handleClose()} />}
          </div>
        </Toast>
      ))}
      <ToastViewport />
    </ToastProvider>
  )
}

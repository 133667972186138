import * as Yup from 'yup'

import {
  BasicFieldConfig,
  ChargerFormFieldConfig,
  OneOfFieldConfig,
  isFieldTypeOneOf
} from '../types'

export const getValidationSchema = (fieldsConfig: ChargerFormFieldConfig[]) => {
  return Yup.object(
    fieldsConfig.reduce<Record<string, Yup.AnySchema>>((acc, field) => {
      if (isFieldTypeOneOf(field.type)) {
        const oneOfField = field as OneOfFieldConfig
        let sectionValidation = Yup.object()
        oneOfField.sections.forEach((section) => {
          sectionValidation = sectionValidation.when(`value`, {
            is: section.value,
            then: getValidationSchema(section.formFields)
          })
        })
        acc[field.name] = Yup.object({
          value: oneOfField.validationSchema || Yup.string(),
          data: sectionValidation
        })
        return acc
      }

      const basicField = field as BasicFieldConfig

      if (basicField.validationSchema) {
        acc[basicField.name] = basicField.validationSchema
      }
      return acc
    }, {})
  )
}

import { useEffect } from 'react'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'

import { IPropsEffectProps, ModuleName } from '../types/module-types'
import { getRef, getSelfRef } from '../utils/module-utils'
import { manuallyUpdateTooltipHtml } from '../utils/overlay-tooltip'
import tooltipHtmlString from '../utils/tooltip-html-string'

export default function useSetTooltipLabel({ propsRef, moduleName, props }: IPropsEffectProps) {
  useEffect(() => {
    const tooltip: am5.Tooltip = getSelfRef({ ...propsRef.current, moduleName })
    const chart: am5xy.XYChart = getRef({ ...propsRef.current, moduleName: ModuleName.xyChart })
    const root: am5.Root = getRef({ ...propsRef.current, moduleName: ModuleName.root })

    const { data, headerKey, header, isHeaderHidden, isDateHeader, flippedAxis } =
      propsRef.current.options
    const frequency = props.options.frequency

    // Unable to add adapter to tooltip.label's html property, because it does not change
    // tooltip.label.adapter.add('html', (text, target) => {  <-- this does not work
    tooltip.adapters.add('labelHTML', (text, target) => {
      const context = chart.series.getIndex(0)?.get('tooltipDataItem')?.dataContext
      if (!context) return ''

      const htmlTooltip = tooltipHtmlString(
        {
          data,
          headerKey,
          header,
          isHeaderHidden,
          isDateHeader,
          flippedAxis,
          frequency
        },
        context,
        root
      )

      manuallyUpdateTooltipHtml({ selector: props.selector, html: htmlTooltip })

      return htmlTooltip
    })

    return () => {
      tooltip.adapters.remove('labelHTML')
    }
  }, [props.options.frequency, propsRef, moduleName, props.selector])
}

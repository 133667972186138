import { useState } from 'react'

import Button from '@components/core/button'

import { IFilter } from '../../types/table-builder-types'
import { Chooser } from './chooser'

export function ConfigureFilter({ filter, columnField }: { filter: IFilter; columnField: string }) {
  const [showModal, setShowModal_] = useState<{
    show: boolean
    editId?: string
  }>({
    show: false
  })

  const setShowModal = (value: boolean, editId?: string) => {
    setShowModal_({
      show: value,
      editId
    })
  }

  const closeModal = () => setShowModal(false)

  return (
    <div>
      <Button variant='outline' onClick={() => setShowModal(true)}>
        Configure
      </Button>
      {showModal.show && (
        <Chooser
          item={filter}
          columnField={columnField}
          onSubmit={closeModal}
          onClose={closeModal}
        />
      )}
    </div>
  )
}

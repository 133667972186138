import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootState } from '@store/index'

import { ITreemapConfig } from 'pages/component-management/types/treemap-builder-types'

const initialState: ITreemapConfig = {
  dimensions: [],
  measures: []
}

const treemapConfigSlice = createSlice({
  name: '@COMPONENT/TREEMAP_CONFIG',
  initialState,
  reducers: {
    addDimension: (state, action: PayloadAction<{ id: string; label: string }>) => {
      state.dimensions.push(action.payload)
    },
    replaceDimensions: (state, action: PayloadAction<ITreemapConfig['dimensions']>) => {
      state.dimensions = action.payload
    },
    removeDimension: (state, action: PayloadAction<string>) => {
      _.remove(state.dimensions, { id: action.payload })
    },
    addMeasure: (state, action: PayloadAction<{ id: string; label: string }>) => {
      state.measures.push(action.payload)
    },
    replaceMeasures: (state, action: PayloadAction<ITreemapConfig['measures']>) => {
      state.measures = action.payload
    },
    removeMeasure: (state, action: PayloadAction<string>) => {
      _.remove(state.measures, { id: action.payload })
    },
    changeConfig: (state, action: PayloadAction<Partial<ITreemapConfig>>) => {
      for (const key in action.payload) {
        // @ts-ignore
        state[key] = action.payload[key]
      }
    }
  }
})

// Actions
export const {
  addDimension,
  replaceDimensions,
  removeDimension,
  addMeasure,
  replaceMeasures,
  removeMeasure,
  changeConfig
} = treemapConfigSlice.actions

// Reducer
export const treemapConfigReducer = treemapConfigSlice.reducer

// Selectors
export const selectTreemapConfig = (state: RootState) => state.component.treemapConfig
